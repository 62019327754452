<div class="search-modal">
  <div class="full-page-modal">
    <div class="full-page-modal__container">
      <div></div>
      <div>
        <h5>Search for your {{ searchType }}</h5>
        <div class="search-bar">
          <search-input
            id="modal_search_input"
            (search)="search($event)">
          </search-input>
          <icon (click)="exit()" class="close-button" iconId="close"></icon>
        </div>
      </div>
      <div class="search-filters">
        <ng-container *ngIf="results.length">
          <h5>Filter by:</h5>
          <ng-container *ngFor="let filter of filters; let first = first;">
            <filter-section *ngIf="filterOptions[filter.field]?.length"
              [filter]="filter"
              [startOpen]="first"
              [options]="filterOptions[filter.field]"
              (filterChanged)="filterChanged($event)"></filter-section>
            </ng-container>
          </ng-container>
      </div>
      <div class="search-results">
        <div *ngIf="searchValue != null" class="flex flex--justify-content-between">
          <h5>{{ results.length }} results for "{{ searchValue }}"</h5>
          <div>Can't find your {{ searchType | lowercase }}? <a class="text-link" (click)="showAddModal()">Click here to add {{ searchType | lowercase }}</a></div>
        </div>
        <div class="search-results-list">
          <app-spinner *ngIf="isLoading" class="busy-spinner-overlay busy-spinner-overlay--alt busy-spinner-overlay--transparent" [isBusy]="true" [short]="true"></app-spinner>
          <div *ngFor="let result of results; let i = index;" class="search-result-item">
            <div>
              <a class="text-link result-title"
                [innerHtml]="result.title | emphasize:searchValue:true | safeHtml"
                (click)="selectResult(result.value)"></a>
              <div>
                <span *ngFor="let subtitle of result.subtitles" class="result-subtitle margin-right-xs">{{ subtitle.value }}</span>
              </div>
            </div>
            <div>
              <button
                id="qa_modal_select_{{i}}_button"
                class="action-button action-button--primary action-button--small icon--left"
                (click)="selectResult(result.value)">
                <icon iconId="plus"></icon>
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


