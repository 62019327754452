import {Component, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { ALL_RELEASES  } from '../../consts';

@Component({
  selector: 'po-release-notes',
  templateUrl: './release-notes.component.html',
})
export class ReleaseNotesComponent implements OnInit {

  releases: ReleaseNote[];

  constructor(private store: Store<State>) {
    this.releases = ALL_RELEASES;
  }

  ngOnInit() {
    this.store.dispatch(Actions.Layout.showNavBar(true));

    this.store.dispatch(Actions.Layout.setHeader({
      ...State.Layout.header,
      title: 'Release Notes',
    }));
  }
}

export interface ReleaseNote {
  date: string;
  version: string;
  features: string[];
  bugs: string[];
}
