<div class="card">
  <div>
    <div class="v2-styles card-header">
      <div class="card-title">ACTION ITEMS</div>
      <div class="show-hidden-toggle">
        <po-toggle labelText="Show hidden" [(ngModel)]="showDeleted" (toggleChange)="displayedActionItems.toggleHidden()"></po-toggle>
      </div>
    </div>
    <div class="action-items-filter">
      <ul>
        <li *ngFor="let tab of tabs">
          <input class="action-items-filter__input" type="radio" name="tab-group_action_items"
            id="qa_action_items_{{tab}}" (change)="displayedActionItems.selectTab(tab)" [checked]="selectedTab === tab">
          <label class="action-items-filter__label" for="qa_action_items_{{tab}}">
            {{tab}}
          </label>
        </li>
      </ul>
    </div>
  </div>


  <div class="action-items-container">
    <div *ngIf="isEmpty" class="action-items-empty">
      <img src="/assets/images/empty-actions.svg" height="100" alt="No Action Items" id="qa_actions_empty"/>
      <div class="action-items-empty__header">All caught up!</div>
      <div class="action-items-empty__label">There are no action items based on the <br>current filter selection</div>
    </div>
    <cdk-virtual-scroll-viewport class="action-items-list popup-scroll-container" [itemSize]="itemSize">
      <div class="action-item flex flex--align-items-center" [ngClass]="{ 'action-item--hidden': item.is_hidden }" *cdkVirtualFor="let item of displayedActionItems; trackBy: trackById">
        <icon class="visibility-status" iconId="visibility-off"></icon>
        <div class="action-item__container">
          <div class="action-item__title-container">
            <div class="text-ellipsis">
              <span class="action-item__action">{{ item.event.action }} </span>
              <ng-container [ngSwitch]="!!item.url">
                <a *ngSwitchCase="true" [routerLink]="item.url" class="action-item__project">{{ item.event.title }}</a>
                <span *ngSwitchCase="false">{{ item.event.title }}</span>
              </ng-container>
            </div>
            <div class="subtitle text-ellipsis">
              {{ item.task.proposal_id }} {{ item.proposal_title }}
            </div>
          </div>
          <div class="action-item__sub-title action-item__due-date" [ngClass]="{ 'action-item__due-date--past-due': isPastDue(item) }">
            <icon *ngIf="isPastDue(item)" iconId="alert"></icon>
            Due: {{ item.event_time | po_date }}
          </div>
        </div>
        <div>
          <options-menu [menuId]="item.id" [disableScroll]="true">
            <options-menu-item [optionText]="item.is_hidden ? 'Unhide' : 'Hide'" iconId="visibility-off" (doEmit)="hide(item)"></options-menu-item>
          </options-menu>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <app-spinner *ngIf="isLoading" class="busy-spinner-overlay busy-spinner-overlay--alt transparent-spinner" [isBusy]="true" [short]="true"></app-spinner>
  </div>
</div>