import { Component, Input, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, skipWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { ApiService } from '@app-services';
import { EnumErrorTypes, Profile } from '@app-models';
import { TASK_TYPES } from '@app-consts';
import { ValidatorsEx } from '@app-utilities';

@Component({
  selector: 'user-program-signup',
  templateUrl: './user-program-signup.component.html'
})
export class UserProgramSignupComponent implements OnInit, OnDestroy {
  @Input() taskType: string = TASK_TYPES.GENERIC_SIGNUP;
  @Input() programList: Array<Model.Fund>;
  @Input() selectionOptions$: Observable<Array<Model.SelectOption>>;
  @Input() optionProposalMap: { [optionValue: number]: Array<Model.ProposalBase> };
  @Input() proposalOptionFunc: (proposal) => Model.SelectOption = (proposal:Model.ProposalBase) => {
    return {
      label: proposal.title,
      value: proposal.id
    }
  };
  @Input() programName: string;
  @Input() selectionLabel: string = 'Institution';
  @Input() acknowledgement: { body: TemplateRef<any>, label: string };

  programSignupTask: Model.Task;
  programSignupForm: FormGroup;
  showSignupModal: boolean;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<State>,
    private apiService: ApiService,
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    this.store.select(Queries.Auth.getCurrentUser).pipe(
      skipWhile(user => !user),
      takeUntil(this.destroy$)
    ).subscribe((user: Profile) => {
      if (user && user.tasks) {
        this.programSignupTask = user.tasks.filter(task => !task.completed).find(task => {
          return !task.completed && (
            this.taskType === TASK_TYPES.GENERIC_SIGNUP
              ? this.programList.some(p => p.id === task.fund_id)
              : this.taskType === task.task_type);
        });

        if (!this.programSignupTask || !!this.programSignupTask.completed) {
          return;
        }

        this.programSignupForm = this._fb.group({
          selection: [undefined, [ValidatorsEx.requiredSelection]],
          acknowledgement_check: this.acknowledgement ? [false, [ValidatorsEx.requiredCheck]] : undefined,
          proposal_id: this.optionProposalMap ? [undefined, ValidatorsEx.requiredSelection] : undefined
        });

        this.showSignupModal = true;
      }
    });
  }

  async submitProgramSignup() {
    try {
      this.showSignupModal = false;
      this.store.dispatch(Actions.Layout.showBusySpinner(true));

      const {
        selection,
        proposal_id
      } = this.programSignupForm.value;

      const options = await this.selectionOptions$.pipe(take(1)).toPromise();
      const option = options.find(o => o.value === selection);

      await this.apiService.completeTask({ ...this.programSignupTask, ...option.extras, proposal_id }).toPromise();
      this.store.dispatch(Actions.Auth.login()); // Refresh the user & their tasks
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      this.store.dispatch(Actions.Layout.updateHeaderMessage('success'));
    } catch (err) {
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      this.store.dispatch(Actions.App.setError({
        location: this.constructor.name,
        type: EnumErrorTypes.api,
        raw: err,
        show: false,
        message: `There was an error completing your sign-up. Please try again.`
      }));
    }
  }

  get hasSelectedInstitution() {
    return !!this.programSignupForm.get('selection').value;
  }

  get proposalOptions(): Array<Model.SelectOption> {
    const selectedOption = this.programSignupForm.get('selection').value;
    const options = selectedOption
      ? this.optionProposalMap[selectedOption]
        .map(this.proposalOptionFunc)
      : [];
    return options;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
