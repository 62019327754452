import { Model } from '@app-ngrx-domains';
import { IEffortArea } from '../effort-area';

export interface IWorkplanStrategies extends IEffortArea {
  annual_plan_strategy: Model.EAAnnualPlanStrategies;
  annual_plan_strategy_id: number;
  program_areas: Array<Model.AttributeValue>;
  is_active: boolean;
}

export class WorkplanStrategies {
  /**
   * Returns target institution's target objective's workplan strategy.
   * @static
   * @param {number} target_institution_id
   * @param {number} target_duration_id
   * @param {number} target_strategy_id
   * @param {Array<Model.EAWorkplanStrategies>} workplanStrategies
   * @returns {*} WorkplanStrategies
   */
  static targetYearWorkplanStrategy(target_institution_id: number, target_duration_id: number, target_strategy_id: number, workplanStrategies: Array<Model.EAWorkplanStrategies>): any {
    const result = workplanStrategies.find(item =>
      item.institution_id === target_institution_id
      && item.duration_id === target_duration_id
      && item.annual_plan_strategy_id === target_strategy_id);
    return result;
  }

  /**
   * Returns target institution's target objective's workplan strategies.
   * @static
   * @param {number} target_institution_id
   * @param {number} target_duration_id
   * @param {Array<Model.EAWorkplanStrategies>} workplanStrategies
   * @returns {Array<Model.EAWorkplanStrategies>} WorkplanStrategies
   */
  static targetYearWorkplanStrategies(target_institution_id: number, target_duration_id: number, workplanStrategies: Array<Model.EAWorkplanStrategies>): any {
    const result = (workplanStrategies || []).filter(item =>
      item.institution_id === target_institution_id
      && item.duration_id === target_duration_id);
    return result;
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type EAWorkplanStrategies = IWorkplanStrategies;
  }
}