import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { collapsibleCardAnimation } from '@app-generic/animations';

@Component({
  selector: 'collapsible-card',
  templateUrl: './collapsible-card.component.html',
  animations: [collapsibleCardAnimation]
})
export class CollapsibleCardComponent implements OnInit {

  @Input() sectionName: string = 'Section';
  @Input() startCollapsed: boolean = false;
  @Input() isNested: boolean = false;
  @Input() level: string; // success | info | warning | error
  @Input() hideCollapseButton: boolean = false;

  isCollapsed: boolean;

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const forPrint = this.route.snapshot.queryParams['forPrint'];
    this.isCollapsed = !forPrint && this.startCollapsed;
  }
}
