<li class="site-nav__item" [ngClass]="{'open' : isOpenExpanded, 'last' : isLast}" [attr.aria-hidden]="!isVisible">
  <div class="site-nav__item-content" [ngClass]="{ 'group-active': isActive }">
    <div class="site-nav__list-header" #treeItem (click)="handleClick($event)" (keyup)="handleKeyUp($event)" (keypress)="handleKeyPress($event)" [tabindex]="isTabbable ? 0 : -1" role="treeitem" [attr.aria-controls]="itemText + '_item-list'" [attr.aria-expanded]="isOpen">
      <icon *ngIf="iconId" [iconId]="iconId" class="site-nav__item-icon"></icon>
      <span [attr.data-qa]="isVisible ? 'nav-item' : ''" class="site-nav__list-header-text"
            [class.site-nav__list-header-text--has-icon]="!!iconId">
        {{itemText}}
      </span>
      <icon iconId="disclosure" class="site-nav__list-header-arrow" [class.open]="isOpenExpanded"></icon>
    </div>
  </div>

  <ul class="site-nav__item-list" [class.open]="isOpenExpanded" [@openState]="isOpenExpanded ? 'open' : 'closed'" [id]="itemText + '_item-list'" role="group">
    <ng-content></ng-content>
  </ul>
</li>
