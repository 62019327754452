import { Injectable } from '@angular/core';
declare let ga: Function;

@Injectable()
export class AnalyticsService {

  constructor() { }

  logEvent(category: string, action: string, label?: string, value?: number) {

    const event = {
      hitType: 'event',
      eventCategory: category,
      eventAction: action
    };

    if (label) {
      event['eventLabel'] = label;
    }

    if (value) {
      event['eventValue'] = value;
    }

    ga('send', event);
  }
}

export const EVENT_CATEGORY = {
  table: 'table',
  link: 'link',
  button: 'button',
  chart: 'chart',
  comments: 'comments'
};

export const EVENT_ACTION = {
  change: 'change',
  edit: 'edit',
  delete: 'delete',
  filter: 'filter',
  sort: 'sort',
  view: 'view',
  create: 'create',
  reply: 'reply',
  resolve: 'resolve',
  cancel: 'cancel',
};
