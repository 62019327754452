import { Model } from '@app-ngrx-domains';
import { PROGRAM_KEYS } from './core.const';

export const WORKFLOW_TYPES = {
  CURRENT: '',

  AEBG_ANNUAL_PLAN: '/caep/annual_plan/',
  AEBG_CFAD: '/caep/cfad',
  AEBG_CFAD_AMENDMENT: '/caep/cfad-amendment',
  AEBG_MEMBER_PROJECT: '/caep/consortium/member/project',
  AEBG_MEMBER_PROGRAM_AREAS: '/caep/program-area-reports',
  AEBG_THREE_YEAR_PLAN: '/caep/three-year-plan',
  CAI: '/cai',
  EWD: 'EWD',
  GP: '/gp',
  IPLAN: '/iplan',
  LVG: '/lvg',
  NEP: '/nep',
  PERKINS_APPLICATION: 'PERKINS_APPLICATION',
  PERKINS_APPLICATION_FORMS: 'PERKINS_APPLICATION_FORMS',
  PERKINS_APPLICATION_CERTIFICATION: 'PERKINS_APPLICATION_CERTIFICATION',
  PERKINS_MODIFY_BUDGET: 'PERKINS_MODIFY_BUDGET',
  PERKINS_SUB_APPLICATIONS: 'PERKINS_SUB_APPLICATIONS',
  PERKINS_SUB_APPLICATIONS_TOP_CODES: 'PERKINS_SUB_APPLICATIONS_TOP_CODES',
  PERKINS_SUB_APPLICATIONS_ACROSS_CTE: 'PERKINS_SUB_APPLICATIONS_ACROSS_CTE',
  PERKINS_SUB_APPLICATIONS_ADMIN: 'PERKINS_SUB_APPLICATIONS_ADMIN',
  PERKINS_1B_PLAN: 'PERKINS_1B_PLAN',
  RCM: 'RCM',
  RCM_PLAN: 'RCM_PLAN',
  RCM_BUDGET_YEARS: 'RCM_BUDGET_YEARS',
  RCM_FORECAST_YEARS: 'RCM_FORECAST_YEARS',
  RCM_RFA: 'RCM_RFA',
  RCM_REPORTING: 'RCM_REPORTING',
  RSI: '/rsi',
  SEP: '/sep',
  SEP_ANNUAL_REPORT: '/sep/annual-report',
  SEP_ANNUAL_REPORT_METRICS: 'SEP_ANNUAL_REPORT_METRICS',
  SEP_MID_ALLOCATION: '/sep/mid-allocation',
  SEP_ALLOCATION_END: '/sep/allocation-end',
  SEP_v2: 'SEP_V2',
  SEP_METRIC_SUBWORKFLOW: 'SEP_METRIC_SUBWORKFLOW',
  SWP_K12_v1_LOI: '/swpk/v1/loi',
  SWP_K12_v1_APPLICATION: '/swpk/v1/application',
  SWP_K12_v1_PLAN: '/swpk/v1/plan',
  SWP_K12_v2: 'SWP_K12_v2',
  SWP_K12_TAPPC: 'SWP_K12_TAPPC',
  SWP_v1: 'SWP_v1',
  SWP_v2: 'SWP_v2',
  SWP_L: '/swpl',
  SWP_R: '/swpr',
  GRANTS_PERKINS_1B: 'GRANTS_PERKINS_1B',
  GRANTS_PERKINS_1C: 'GRANTS_PERKINS_1C',
  GRANTS_PERKINS_RESERVE: 'GRANTS_PERKINS_RESERVE',
  GRANTS_PERKINS_NTCT: 'GRANTS_PERKINS_NTCT',
  GRANTS_RFA: 'GRANTS_RFA',
  GRANTS_EWD: 'GRANTS_EWD',
  SP: 'SP',
  SP_SUB_REPORTING: 'SP_SUB_REPORTING',
  SP_RFA: 'SP_RFA',
  SP_BUDGET_SOURCES: 'SP_BUDGET_SOURCES',
  SP_FORECAST_SOURCES: 'SP_FORECAST_SOURCES'
};

export const WORKFLOW_STEPS = {
  ACROSS: 'across',
  ACTIVITIES: 'activities',
  ACTIVITIES_AND_OUTCOMES: 'activities-and-outcomes',
  ADDITIONAL: 'additional',
  ADMIN: 'admin',
  ALL: 'all',
  AGENCIES: 'agencies',
  ALIGNMENT: 'alignment',
  ALLOCATIONS: 'allocations',
  APPLICATION_PLAN_STEPS: 'application-plan-steps',
  ASSESSMENT: 'assessment',
  ASSURANCES: 'assurances',
  BUDGET: 'budget',
  BUDGET_SOURCES: 'budget_sources',
  CAPABILITY: 'capability',
  CARRYOVER_THRESHOLD: 'carryover-threshold',
  CERTIFICATION: 'certification',
  CERTIFIERS: 'certifiers',
  CHALLENGES: 'challenges',
  COLLEGES: 'colleges',
  COMPARE_CHANGES: 'compare-changes',
  COMPLETED_MATH_ENGLISH: 'completed-math-english',
  COMPLETION: 'completion',
  COMMENTING: 'commenting',
  CONCLUSION: 'conclusion',
  CONTACTS: 'contacts',
  CORE: 'core',
  CORE_INDICATOR_TARGETS: 'core-indicator-targets',
  CUSTOM: 'custom/:customTitle',
  DATES: 'dates',
  DECLARATION: 'declaration',
  DETAILS: 'details',
  DESCRIPTION: 'description',
  DISSEMINATION: 'dissemination',
  DOCUMENTS: 'documents',
  EXPENDITURES: 'expenditures',
  EQUITY_PLAN_REFLECTION: 'equity-plan-reflection',
  FEASIBILITY: 'feasibility',
  FISCAL_REPORTING: 'fiscal-reporting',
  FORECAST: 'forecast',
  FORECAST_SOURCES: 'forecast_sources',
  FORMS: 'forms',
  FUNDING: 'funding',
  FUNDING_SOURCES_ALLOCATIONS: 'funding-sources-allocations',
  FUNDS_EVALUATION: 'funds-evaluation',
  GOAL1: 'goal1',
  GOAL2: 'goal2',
  GOAL3: 'goal3',
  GOAL4: 'goal4',
  GOAL5: 'goal5',
  GOVERNANCE: 'governance',
  GUIDANCE: 'guidance',
  HEALTH: 'health',
  HISTORY: 'history',
  HOURS_OF_INSTRUCTION: 'hours-of-instruction',
  HOURLY_RATE: 'hourly-rate',
  LEAS: 'leas',
  LEAD_AGENCY: 'leadagency',
  LEVERAGED_FUNDS: 'leveraged-funds',
  LMI: 'lmi',
  MANAGEMENT: 'management',
  MAP: 'map',
  MATCH: 'match',
  MEMBERS: 'members',
  MESSAGING: 'messaging',
  METRICS: 'metrics',
  NEEDS: 'needs',
  OBJECTIVES: 'objectives',
  OUTCOMES: 'outcomes',
  PARTNERS: 'partners',
  PATHWAY: 'pathway',
  PILLAR1: 'pillar1',
  PILLAR2: 'pillar2',
  PILLAR3: 'pillar3',
  PILLAR4: 'pillar4',
  PLANNING: 'planning',
  POSITIVE_CONSIDERATIONS: 'positive-considerations',
  PREVIEW: 'preview',
  PROBLEM_STATEMENT: 'problem-statement',
  PROGRAM: 'program',
  PROGRAMS: 'programs',
  QUALIFICATIONS: 'qualifications',
  REPORTING: 'reporting',
  REPORTING_CATEGORIES: 'reporting-categories',
  REPORTING_PREREQUISITE: 'reporting-prerequisite',
  RESPONSE: 'response',
  RESPONSES: 'responses',
  RESPONSE_TO_PROBLEM: 'response-to-problem',
  RETENTION: 'retention',
  SECTORS: 'sectors',
  SOW: 'sow',
  SPENDING: 'spending',
  STUDENTS_EXPERIENCING_DI: 'students-experiencing-di',
  SUCCESS: 'success',
  SUCCESSFUL_ENROLLMENT: 'successful-enrollment',
  SUMMARY: 'summary',
  SURVEY: 'survey',
  TARGET: 'target',
  TOP: 'top',
  TRANSFER: 'transfer',
  RESERVE: 'reserve',
  WEDD_MEMO: 'wedd-memo',
  WORKPLAN: 'workplan',
  WORKPLAN1: 'workplan1',
  WORKPLAN2: 'workplan2',
  WORKPLAN3: 'workplan3',
  WORKPLAN4: 'workplan4',
  WORKPLAN5: 'workplan5',
};

export const WORKFLOW_STEP_FIELDS = {
  GUIDANCE: 'guidance',
};

export const DEFAULT_STEP_OPTIONS = {
  firstTouch: false,
  valid: false,
  showStatus: true,
  hide: false,
  disabled: false,
}

export const WORKFLOWS_CONFIG: { [kind: string]: Model.Workflow } = {
  // #####################  GP: Proposal Workflow  ###################
  [WORKFLOW_TYPES.GP]: {
    name: WORKFLOW_TYPES.GP,
    baseLink: `/gp/proposals/${PROGRAM_KEYS.GP_1}`,
    show: false,
    steps: [
      {
        route: WORKFLOW_STEPS.DESCRIPTION,
        title: 'Description',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.CONTACTS,
        title: 'Contacts',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.WORKPLAN,
        title: 'Inquiry',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.WORKPLAN1,
        title: 'Design',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.WORKPLAN2,
        title: 'Implementation',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.WORKPLAN3,
        title: 'Timeline',
        ...DEFAULT_STEP_OPTIONS,
        showStatus: false,
      },
      {
        route: WORKFLOW_STEPS.METRICS,
        title: 'Performance Indicators',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.CONCLUSION,
        title: 'Efforts & Support',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.BUDGET,
        title: 'Budget',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.PREVIEW,
        title: 'Preview',
        ...DEFAULT_STEP_OPTIONS,
        showStatus: false,
      },
    ]
  },

  // #####################  Integrated Planning: Proposal Workflow  ###################
  [WORKFLOW_TYPES.IPLAN]: {
    name: WORKFLOW_TYPES.IPLAN,
    baseLink: '/iplan/proposals',
    show: false,
    steps: [
      {
        route: WORKFLOW_STEPS.DESCRIPTION,
        title: 'Description',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.WORKPLAN1,
        title: 'Previous Efforts',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.WORKPLAN,
        title: 'Future Goals',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.WORKPLAN2,
        title: 'Integration & Coordination',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.BUDGET,
        title: 'Budget',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.CONCLUSION,
        title: 'Documents & Support',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.CONTACTS,
        title: 'Contacts',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.PREVIEW,
        title: 'Preview',
        ...DEFAULT_STEP_OPTIONS,
        showStatus: false,
      },
    ]
  },

  // #####################  AEBG: Cfad Workflow  ###################
  [WORKFLOW_TYPES.AEBG_CFAD]: {
    name: WORKFLOW_TYPES.AEBG_CFAD,
    title: 'CFAD',
    baseLink: undefined, // will be assigned by the builder
    show: false,
    steps: [
      {
        route: WORKFLOW_STEPS.DECLARATION,
        title: 'Fiscal Declaration',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.CERTIFIERS,
        title: 'Agencies & Certifiers',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.ALLOCATIONS,
        title: 'Member Allocations',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.CARRYOVER_THRESHOLD,
        title: 'Carryover Threshold',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.GOVERNANCE,
        title: 'Governance',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.PREVIEW,
        title: 'Preview',
        ...DEFAULT_STEP_OPTIONS,
        showStatus: false,
      }
    ]
  },

  // #####################  AEBG: Allocations Workflow  ###################
  [WORKFLOW_TYPES.AEBG_CFAD_AMENDMENT]: {
    name: WORKFLOW_TYPES.AEBG_CFAD_AMENDMENT,
    baseLink: undefined,
    show: false,
    steps: [
      {
        route: WORKFLOW_STEPS.CERTIFIERS,
        title: 'Agencies & Certifiers',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.ALLOCATIONS,
        title: 'Member Allocations',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.PREVIEW,
        title: 'Preview',
        ...DEFAULT_STEP_OPTIONS,
        showStatus: false,
      }
    ]
  },

  // #####################  AEBG: AnnualPlan Workflow  ###################
  [WORKFLOW_TYPES.AEBG_ANNUAL_PLAN]: {
    name: WORKFLOW_TYPES.AEBG_ANNUAL_PLAN,
    title: 'Annual Plan',
    baseLink: undefined,
    show: false,
    steps: [
      {
        route: WORKFLOW_STEPS.DESCRIPTION,
        title: 'Plans & Goals',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.OBJECTIVES,
        title: 'Objectives',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.WORKPLAN5,
        title: 'Fiscal Management',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.PREVIEW,
        title: 'Preview',
        ...DEFAULT_STEP_OPTIONS,
        showStatus: false,
      }
    ]
  },

  // #####################  AEBG: Member's Plan Workflow  ###################
  [WORKFLOW_TYPES.AEBG_MEMBER_PROJECT]: {
    name: WORKFLOW_TYPES.AEBG_MEMBER_PROJECT,
    title: 'Budget and Workplan',
    baseLink: undefined,
    show: false,
    steps: [
      {
        route: WORKFLOW_STEPS.WORKPLAN,
        title: 'Workplan',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.BUDGET,
        title: 'Budget',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.PREVIEW,
        title: 'Preview',
        ...DEFAULT_STEP_OPTIONS,
        showStatus: false,
      }
    ]
  },

  // #####################  AEBG: Member's Program Area Workflow  ###################
  [WORKFLOW_TYPES.AEBG_MEMBER_PROGRAM_AREAS]: {
    name: WORKFLOW_TYPES.AEBG_MEMBER_PROGRAM_AREAS,
    baseLink: undefined,
    show: false,
    steps: [
      {
        route: WORKFLOW_STEPS.HOURS_OF_INSTRUCTION,
        title: 'Program Area Hours',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.LEVERAGED_FUNDS,
        title: 'Leveraged Funds',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.PREVIEW,
        title: 'Preview',
        ...DEFAULT_STEP_OPTIONS,
        showStatus: false,
      }
    ]
  },

  // #####################  AEBG: Three-Year Plan Workflow  ###################
  [WORKFLOW_TYPES.AEBG_THREE_YEAR_PLAN]: {
    name: WORKFLOW_TYPES.AEBG_THREE_YEAR_PLAN,
    title: 'Three-Year Plan',
    baseLink: undefined,
    show: false,
    steps: [
      {
        route: WORKFLOW_STEPS.DETAILS,
        title: 'Details',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.ASSESSMENT,
        title: 'Assessment',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.METRICS,
        title: 'Metrics',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.OBJECTIVES,
        title: 'Objectives',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.ACTIVITIES_AND_OUTCOMES,
        title: 'Activities & Outcomes',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.FUNDS_EVALUATION,
        title: 'Funds Evaluation',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.PREVIEW,
        title: 'Preview & Submit',
        ...DEFAULT_STEP_OPTIONS,
        showStatus: false,
      }
    ]
  },

  // #####################  LVG: Local Vision Goals  ###################
  [WORKFLOW_TYPES.LVG]: {
    name: WORKFLOW_TYPES.LVG,
    baseLink: undefined,
    show: false,
    steps: [
      {
        route: WORKFLOW_STEPS.CONTACTS,
        title: 'Contacts',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.GOAL1,
        title: 'Goal 1: Completion',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.GOAL2,
        title: 'Goal 2: Transfer',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.GOAL3,
        title: 'Goal 3: Unit Accumulation',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.GOAL4,
        title: 'Goal 4: Workforce',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.GOAL5,
        title: 'Goal 5: Equity',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.ADDITIONAL,
        title: 'Additional Metrics',
        ...DEFAULT_STEP_OPTIONS,
      },
      {
        route: WORKFLOW_STEPS.PREVIEW,
        title: 'Preview',
        ...DEFAULT_STEP_OPTIONS,
        showStatus: false,
      }
    ]
  },
};

