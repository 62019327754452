import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'options-menu-item',
  templateUrl: './options-menu-item.component.html'
})
export class OptionsMenuItemComponent {

  @Input() optionText: string = '';
  @Input() iconId: string;
  @Input() url: string;
  @Input() queryParams: object;
  @Input() isVisible = true;
  @Input() danger: boolean = false;
  @Input() useContent: boolean = false;
  @Output() doEmit = new EventEmitter();
  @Output() doBlur = new EventEmitter();

  constructor() { }

  handleKeydown(event) {
    if (event.keyCode === 9 && event.shiftKey === false) {
      this.doBlur.emit();
    }
  }

  emit() {
    this.doEmit.emit();
  }

}
