export const OTHER_CATEGORY_ID = 27;
export const COLLEGE_TER_REPORTING_PERIOD = 'SEA Term-End Expenditure Report';

export const WORKFLOW_METRIC_STEPS = {
  SUCCESSFUL_ENROLLMENT: 'successful-enrollment',
  COMPLETED_MATH_ENGLISH: 'completed-math-english',
  RETENTION: 'retention',
  TRANSFER: 'transfer',
  COMPLETION: 'completion'
};

export const WORKFLOW_METRIC_LABELS = {
  [WORKFLOW_METRIC_STEPS.SUCCESSFUL_ENROLLMENT]: 'Successful Enrollment',
  [WORKFLOW_METRIC_STEPS.COMPLETED_MATH_ENGLISH]: 'Completed Transfer-Level Math & English',
  [WORKFLOW_METRIC_STEPS.RETENTION]: 'Persistence: First Primary Term to Secondary Term',
  [WORKFLOW_METRIC_STEPS.TRANSFER]: 'Transfer',
  [WORKFLOW_METRIC_STEPS.COMPLETION]: 'Completion'
};
export const WORKFLOW_METRICS = Object.values(WORKFLOW_METRIC_STEPS).map((value) => ({ label: WORKFLOW_METRIC_LABELS[value], value }));

export const getMetricLabel = (value) => {
  const metric = WORKFLOW_METRICS.find(m => m.value === value);
  if (metric) {
    return metric.label;
  }
  return 'Unknown Metric';
}

export const STRUCTURE_TYPES = [
  { label: 'Instruction', value: 'instruction' },
  { label: 'Wraparound Services (Counseling, Support Programs, Textbook Programs, etc.)', value: 'student_services' },
  { label: 'Budgeting and Administration (HR, Purchasing, Processes, etc.)', value: 'business_services' },
  { label: 'General Operations (A&R, Parking, Campus Policing, etc.)', value: 'general_operations' },
  { label: 'Other', value: 'other' }
];
