<full-page-modal
  small="true"
  heading="{{headerText}}"
  [button-cancel]="cancelButtonText"
  (cancel)="cancelAction.emit()"
  [button-action-disabled]="!isValid"
  button-action="Add Role"
  (action)="addUsersRoleScope()"
  (dismiss)="closeAction.emit()">

  <div class="help-text">
    <p>Can't find the user you're looking for? <a type="button" class="text-link" (click)="toggleInviteUser()">Invite them here</a></p>
  </div>
  <!-- Role Scope -->
  <form [formGroup]="formAddRole" autocomplete="off">
    <div class="columns columns--2">
      <div>
        <po-select id="qa_addUserRole_users_select"
          labelText="User(s)"
          placeholderText="Start typing Name or Email"
          [options]="contactList"
          [selectMultiple]="true"
          [isSearch]="true"
          [disabledOptions]="disabledContacts"
          (optionSelected)="runCollisionCheck()"
          (optionRemoved)="runCollisionCheck()"
          (search)="getContactList($event, 50)">
          <select inputRef class="po-select__select" formControlName="users" aria-required="true"></select>
        </po-select>
        <div *ngIf="collisionNames.length > 0" class="error-text">
          <p>The following user(s) have already been assigned this role: {{getCollisionNames()}}.</p>
          <p>Please remove user(s), or click Go Back to select a different role.</p>
        </div>
      </div>

      <po-select id="qa_addUserRole_fundType_select"
        *ngIf="collectFundScope"
        labelText="Fund"
        [options]="fundList"
        [canEdit]="!fundDisabled"
        (optionSelected)="runCollisionCheck()"
        [selectOnce]="true">
        <select inputRef formControlName="fund_type" class="po-select__select" aria-required="true"></select>
      </po-select>

      <ng-container *ngIf="collectInstitutionScope" [ngSwitch]="!!institutionOptions">
        <po-select *ngSwitchCase="false" id="qa_addUserRole_institutionId_select"
          labelText="Institution"
          placeholderText="Start typing organization or institution name"
          [options]="institutionsLookupFormatted"
          [isSearch]="true"
          (optionSelected)="runCollisionCheck()"
          (search)="updateInstitutionsList($event)">
          <select inputRef formControlName="institution" class="po-select__select" aria-required="true"></select>
        </po-select>

        <po-select *ngSwitchCase="true" id="qa_addUserRole_institutionId_select"
          labelText="Institution"
          placeholderText="Select an institution"
          [options]="institutionOptions"
          (optionSelected)="runCollisionCheck()"
          [selectOnce]="true">
          <select inputRef formControlName="institution" class="po-select__select" aria-required="true"></select>
        </po-select>
      </ng-container>

      <po-select id="qa_addUserRole_sector_select"
        *ngIf="collectSectorScope"
        labelText="Sector"
        [options]="sectorList"
        [selectOnce]="true">
        <select inputRef formControlName="sector_id" class="po-select__select" aria-required="true"></select>
      </po-select>
    </div>

    <div class="columns columns--2">
      <input-group headingText="Expires">
        <radio-check labelText="Never">
          <input inputRef id="qa_addUserRole_expiresNever_radio" type="radio" [value]="false" formControlName="role_expires">
        </radio-check>
        <radio-check labelText="On Date">
          <input inputRef #onDate id="qa_addUserRole_expiresOnDate_radio" type="radio" [value]="true" formControlName="role_expires">
        </radio-check>

        <po-date-picker *ngIf="onDate.checked"
          id="qa_addUserRole_expiration_date"
          labelText="Expiration Date"
          [labelHidden]="true"
          [minDateString]="minDate"
          [canEdit]="true"
          [required]="true"
          formControlName="expire_on">
        </po-date-picker>
      </input-group>
    </div>

    <hr>

    <!-- Permissions -->
    <div *ngIf="showPermissions">
      <h6 class="h6">Permissions</h6>
      <ng-container [ngSwitch]="roleData?.permissions?.length > 0">
        <ul class="unordered-list" *ngSwitchCase="true">
          <li *ngFor="let permission of roleData.permissions">
            <strong>{{permission.name}}</strong>: {{permission.actionsJoined}}
          </li>
        </ul>

        <p class="empty-state" *ngSwitchDefault>
          None have been assigned.
        </p>
      </ng-container>
    </div>
  </form>
</full-page-modal>

<app-invite-user *ngIf="showInviteUser"
  [contactOnly]="false"
  (cancelAction)="toggleInviteUser()"
  (closeAction)="toggleInviteUser()"
  (inviteSuccess)="inviteSuccess($event)">
</app-invite-user>
