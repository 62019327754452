import { WORKFLOW_STEPS } from '@app-consts';

export const REVIEW_WORKFLOW_CONFIG_v2 = {
  [WORKFLOW_STEPS.DESCRIPTION]: {
    showStatus: false,
    scoreCard: {
      title: 'Pathway Identification',
      collectScore: false,
      scoringCriteria: 'Please provide comments as your reference for the review process. Comments shall be optional for all items, even those requiring a score.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.AGENCIES]: {
    showStatus: false,
    scoreCard: {
      title: 'Lead & Partner Agencies',
      collectScore: false,
      scoringCriteria: 'Please provide comments as your reference for the review process. Comments shall be optional for all items, even those requiring a score.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.PARTNERS]: {
    showStatus: false,
    scoreCard: {
      title: 'Higher Ed. & Collaborative Partners',
      collectScore: false,
      scoringCriteria: 'Please provide comments as your reference for the review process. Comments shall be optional for all items, even those requiring a score.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.OBJECTIVES]: {
    showStatus: false,
    scoreCard: {
      title: 'Problem Statement & Project Objectives',
      collectScore: true,
      scoreCommentsOptional: true,
      score: null,
      minScore: 0,
      maxScore: 25,
      scoringCriteria: 'Please provide comments as your reference for the review process. Comments shall be optional for all items, even those requiring a score.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.PATHWAY]: {
    showStatus: false,
    scoreCard: {
      title: 'Industry Sectors & Pathways',
      collectScore: false,
      scoringCriteria: 'Please provide comments as your reference for the review process. Comments shall be optional for all items, even those requiring a score.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.WORKPLAN]: {
    showStatus: false,
    scoreCard: {
      title: 'Work Plan',
      collectScore: true,
      scoreCommentsOptional: true,
      score: null,
      minScore: 0,
      maxScore: 60,
      scoringCriteria: 'Please provide comments as your reference for the review process. Comments shall be optional for all items, even those requiring a score.',
      totalScore: 100,
    },
  },
  [WORKFLOW_STEPS.BUDGET]: {
    showStatus: false,
    scoreCard: {
      title: 'Budget & Match',
      collectScore: true,
      scoreCommentsOptional: true,
      score: null,
      minScore: 0,
      maxScore: 15,
      scoringCriteria: 'Please provide comments as your reference for the review process. Comments shall be optional for all items, even those requiring a score.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.DOCUMENTS]: {
    showStatus: false,
    scoreCard: {
      title: 'Supporting Documents',
      collectScore: false,
      scoringCriteria: 'Please provide comments as your reference for the review process. Comments shall be optional for all items, even those requiring a score.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.CONCLUSION]: {
    hide: false,
  }
};
