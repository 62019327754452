import { IInvoicesState, Model, Query, Queries, combineRootFactory } from '@app-ngrx-domains';

const fromRoot = combineRootFactory<IInvoicesState>('Invoices');

export interface IInvoicesQueries {
  get: Query<Array<Model.EAInvoice>>;
  getInvoice: Query<Model.EAInvoice>;
  isLoaded: Query<boolean>;
}

Queries.Invoices = {
  get: fromRoot(state => state.items),
  getInvoice: fromRoot(state => state.invoice),
  isLoaded: fromRoot(state => state.isLoaded),
};

declare module '@app-ngrx-domains' {
  interface Root {
    Invoices: Query<IInvoicesState>;
  }

  interface Queries {
    Invoices: IInvoicesQueries;
  }
}