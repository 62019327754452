import { DOCUMENT } from '@angular/common';
import { AfterContentInit, ChangeDetectorRef, Component, ContentChildren, ElementRef, forwardRef, Host, HostListener, Inject, Input, NgZone, OnDestroy, Optional, QueryList, Renderer2, ViewChild } from '@angular/core';
import { PopupAnchorDirective } from '@app/shared.generic/directives';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OptionsMenuItemComponent } from '../options-menu-item/options-menu-item.component';

@Component({
  selector: 'options-menu',
  templateUrl: './options-menu.component.html'
})
export class OptionsMenuComponent implements AfterContentInit, OnDestroy {

  @Input() buttonId = '';
  @Input() buttonClass = '';
  @Input() menuId = '';
  @Input() iconId = 'more';
  @Input() listClass = '';
  @Input() disableScroll = false;
  @ViewChild('dropdownHeader') dropdownHeader: ElementRef;
  @ViewChild('dropdownMenu') dropdownMenu: ElementRef;
  @ContentChildren(forwardRef(() => OptionsMenuItemComponent)) options: QueryList<OptionsMenuItemComponent>;
  previousMenuId = '';

  public showOptions = false;

  private scrollContainer: Element;
  private unlistenClicks: Function;
  private unlistenScroll: Function;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Optional() private popupAnchor: PopupAnchorDirective,
    private ngZone: NgZone
  ) {
    this.scrollContainer = document.querySelector('.content-body__container');
  }

  scroll = (event) => {
    if (this.showOptions) {
      // Virtual scroll recycles elements so track menuId to determine if this menu is no longer rendered
      if (this.menuId !== this.previousMenuId) {
        this.showOptions = false;
        this.previousMenuId = this.menuId;
      }
    }
  }

  click = (event) => {
    // If button or popup not found in path, close it.
    if (this.showOptions) {
      const path = event.composedPath ? event.composedPath() : event.path;
      const toggleInPath = path.find(e => e === this.dropdownHeader?.nativeElement);
      const menuInPath = path.find(e => e === this.dropdownMenu?.nativeElement);
      if (!toggleInPath && !menuInPath) { // If clicked outside of toggle/menu
        // Check if a different options menu was opened
        const otherMenuToggled = path.some(e => e.classList && e.classList.contains('options-menu__trigger'));
        this.toggleOptions(otherMenuToggled);
      }
    }
  }

  ngOnInit() {
    this.previousMenuId = this.menuId;
  }

  ngAfterContentInit() {
    if (this.options.last) {
      this.options.last.doBlur.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.toggleOptions();
      });
    }
  }

  toggleOptions(otherMenuToggled = false) {
    this.showOptions = !this.showOptions;

      if (this.showOptions) {
        this.unlistenClicks = this.renderer.listen('document', 'click', this.click);

        if (this.disableScroll) {
          this.popupAnchor.disableScroll();
        } else {
          this.unlistenScroll = this.renderer.listen(this.scrollContainer, 'scroll', this.scroll);
        }

        this.previousMenuId = this.menuId;
      } else {
        if (this.disableScroll) {
          // If a different options menu was opened, don't enable scroll yet
          if (!otherMenuToggled) {
            this.popupAnchor.enableScroll();
          }
        } else {
          this.unlisten();
        }
      }

      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
  }

  handleKeydown(event) {
    if (event.keyCode === 9 && event.shiftKey === true) {
      if (this.showOptions) {
        this.toggleOptions();
      }
    }
  }

  get contentBody() {
    return this.popupAnchor ? this.popupAnchor.element : this.document.documentElement;
  }

  unlisten() {
    if (this.unlistenClicks) {
      this.unlistenClicks();
      this.unlistenClicks = undefined;
    }
    if (this.unlistenScroll) {
      this.unlistenScroll();
      this.unlistenScroll = undefined;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.unlisten();
    if (this.dropdownMenu && this.dropdownMenu.nativeElement) {
      this.dropdownMenu.nativeElement.remove();
    }
  }
}
