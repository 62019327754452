import { trigger, style, transition, animate } from '@angular/animations';

export const collapsibleCardAnimation = trigger('collapsible', [
  transition(':enter', [
    style({ height: 0, opacity: 0, 'overflow-y': 'hidden' }),
    animate('250ms ease-out', style({ height: '*', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ height: '*', opacity: 1, 'overflow-y': 'hidden' }),
    animate('250ms ease-in', style({ height: 0, opacity: 0 }))
  ])
]);
