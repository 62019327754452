import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-term-description',
  templateUrl: './term-description.component.html'
})
export class TermDescriptionComponent {
  @Input() term: string;
  @Input() description: string;
  @Input() inDiv: boolean = true;
}
