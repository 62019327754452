import { WORKFLOW_STEPS } from '@app-consts';

export const REVIEW_WORKFLOW_CONFIG_v1 = {
  [WORKFLOW_STEPS.DESCRIPTION]: {
    showStatus: false,
    scoreCard: {
      title: 'Pathway Improvement Summary',
      collectScore: false,
      scoringCriteria: 'This section does not require scoring. Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.AGENCIES]: {
    showStatus: false,
    scoreCard: {
      title: 'Lead & Partner Agencies',
      collectScore: false,
      scoringCriteria: 'This section does not require scoring. Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.PARTNERS]: {
    showStatus: false,
    scoreCard: {
      title: 'Collaborative Partners',
      collectScore: true,
      score: null,
      minScore: 0,
      maxScore: 5,
      scoringCriteria: 'Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.NEEDS]: {
    showStatus: false,
    scoreCard: {
      title: 'Statement of Need',
      collectScore: true,
      score: null,
      minScore: 0,
      maxScore: 10,
      scoringCriteria: 'Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.TARGET]: {
    showStatus: false,
    scoreCard: {
      title: 'Target Pupil',
      collectScore: true,
      score: null,
      minScore: 0,
      maxScore: 10,
      scoringCriteria: 'Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.PATHWAY]: {
    showStatus: false,
    scoreCard: {
      title: 'Pathway Improvement Strategies',
      collectScore: true,
      score: null,
      minScore: 0,
      maxScore: 25,
      scoringCriteria: 'Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.SOW]: {
    showStatus: false,
    scoreCard: {
      title: 'Statement of Work',
      collectScore: true,
      score: null,
      minScore: 0,
      maxScore: 25,
      scoringCriteria: 'Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.CAPABILITY]: {
    showStatus: false,
    scoreCard: {
      title: 'Capability & Sustainability',
      collectScore: true,
      score: null,
      minScore: 0,
      maxScore: 15,
      scoringCriteria: 'Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.BUDGET]: {
    showStatus: false,
    scoreCard: {
      title: 'Budget',
      collectScore: true,
      score: null,
      minScore: 0,
      maxScore: 10,
      scoringCriteria: 'Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.DOCUMENTS]: {
    showStatus: false,
    scoreCard: {
      title: 'Supporting Documents',
      collectScore: false,
      scoringCriteria: 'This section does not require scoring. Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.CONCLUSION]: {
    hide: false,
  }
};
