import { IInvoicesState, State } from "@app-ngrx-domains";
import { ActionWithPayload } from "@app/libs";
import { INVOICES_ACTION_TYPES } from "./invoices.action";

export function InvoicesReducer(state: IInvoicesState = State.Invoices, action: ActionWithPayload<any>): IInvoicesState {
  switch (action.type) {
    case INVOICES_ACTION_TYPES.GET: {
      const { isRefresh } = action.payload;
      return isRefresh ? state : { ...State.Invoices };
    }

    case INVOICES_ACTION_TYPES.LOAD: {
      return {
        ...State.Invoices,
        items: action.payload,
        isLoaded: true
      };
    }

    case INVOICES_ACTION_TYPES.GET_INVOICE:
    case INVOICES_ACTION_TYPES.CREATE_INVOICE: {
      return {
        ...state,
        isLoaded: false,
      };
    }

    case INVOICES_ACTION_TYPES.CREATE_INVOICE_SUCCESS: {
      const item = action.payload;
      return {
        ...state,
        items: [...state.items, item],
        invoice: item
      };
    }

    case INVOICES_ACTION_TYPES.GET_INVOICE_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        invoice: action.payload
      };
    }

    // Update single invoice
    case INVOICES_ACTION_TYPES.UPDATE_INVOICE_SUCCESS: {
      return {
        ...state,
        invoice: action.payload
      };
    }

    // Update list
    case INVOICES_ACTION_TYPES.UPDATE_INVOICES_SUCCESS: {
      const item = action.payload;
      const items = state.items;
      const index = items.findIndex(invoice => invoice.id === item.id);
      if (index < 0) {
        // Invoice not found
        return state;
      } else {
        return {
          ...state,
          items: [
            ...items.slice(0, index),
            {
              ...items[index],
              ...item
            },
            ...items.slice(index + 1)
          ]
        };
      }
    }

    // Remove invoice from list
    case INVOICES_ACTION_TYPES.DELETE_INVOICE_SUCCESS: {
      const itemId = action.payload;
      const items = state.items;
      const index = items.findIndex(invoice => invoice.id === itemId);
      if (index < 0) {
        // Invoice not found
        return state;
      } else {
        return {
          ...state,
          items: [
            ...items.slice(0, index),
            ...items.slice(index + 1)
          ]
        };
      }
    }

    default:
      return state;
  }
}