import { Component, OnChanges, Input, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { Model } from '@app-ngrx-domains';
import { Profile } from '@app-models';
import { ApiService } from '@app-services';
import { ACTION_TASKMAN } from '@app/core/consts';


@Component({
  selector: 'app-approval-card-v2',
  templateUrl: './approval-card-v2.component.html'
})
export class ApprovalCardV2Component implements OnChanges {

  @ViewChild('rejectButtonTmpl', {static: true}) rejectButtonTmpl: TemplateRef<any>;
  @ViewChild('completeButtonTmpl', {static: true}) completeButtonTmpl: TemplateRef<any>;
  @ViewChild('actionButtonsTemplate', { static: true }) actionButtonsTemplate: TemplateRef<any>;
  @ViewChild('rejectButtonTemplate', { static: true }) rejectButtonTemplate: TemplateRef<any>;
  @ViewChild('reminderButtonTemplate', { static: true }) reminderButtonTemplate: TemplateRef<any>;
  @ViewChild('completedTemplate', { static: true }) completedTemplate: TemplateRef<any>;

  @Input() task: any;
  @Input() headerText: string;
  @Input() users: Model.User[];
  @Input() allowed = false; // Should be based on the permissions of the current user
  @Input() allowedRejectOnly = false; // Should be based on the permissions of the current user
  @Input() disableButton = false; // Optional, should be set on validity of form if you would like to disable buttons

  @Input() isSubmittal = false; // Change display logic to use 'Submit' verbs rather than 'Approve'
  @Input() useCertify = false; // Use the word 'Certify' instead of 'Approve' (GP)
  @Input() hideReminder = false; // Hide the 'Send Reminder' button
  @Input() disableActions = false;

  @Output() approve: EventEmitter<any> = new EventEmitter<any>();
  @Output() reject: EventEmitter<any> = new EventEmitter<any>();

  @Input() rejectButtonText: string = 'Reject'; // To just replace the text of the 'Reject' button
  @Input() rejectButton: TemplateRef<any>; // To replace the text and/or functionality of the button
  @Input() completeButton: TemplateRef<any>; // To replace the text and/or functionality of the button
  @Input() replaceStates: any;

  hideCard: boolean = false;
  completed: boolean = true;
  performedBy: string = '';

  states: { [name: string]: { class: string, action?: string, done?: string, await?: string } } = {
    submitted: { class: 'certification-card--approved', action: 'Submit', done: 'Submitted', await: 'Submittal' },
    approved:  { class: 'certification-card--approved', action: 'Approve', done: 'Approved', await: 'Approval' },
    rejected:  { class: 'certification-card--rejected', action: 'Reject', done: 'Rejected' },
    waiting:   { class: 'certification-card--waiting', await: 'Awaiting Preliminary Approval' },
    disabled:  { class: 'certification-card--disabled' }
  };

  cardState = {
    text_value: '',
    class_value: this.states.disabled.class,
    footerTemplate: undefined
  };

  reminderButtonStatus: string;
  private initialized: boolean = false;
  private doingLookup: boolean = false;

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnChanges() {
    this.initialize();

    this.completed = this.task && this.task.completed;
    if (this.completed) {
      if (this.task.performed_by) { // Use the user returned on the task
        this.performedBy = ` by ${Profile.contactFullName(this.task.performed_by)}`;
      } else if (this.task.performed_by_id && this.task.performed_by_id !== ACTION_TASKMAN) {
        const user = this.users.find(u => u.id === this.task.performed_by_id);
        if (user) { // Find the user in the list provided
          this.performedBy = ` by ${Profile.contactFullName(user)}`;
        } else if (!this.doingLookup) { // Lookup the user
          this.doingLookup = true;
          this.apiService.getProfileById(this.task.performed_by_id).subscribe(profile => {
            this.performedBy = ` by ${Profile.contactFullName(profile)}`;
            this.setCardState();
          });
        }
      }
    }

    this.setCardState();
  }

  private initialize() {
    if (this.initialized) {
      // already initialized.
      return;
    }

    this.initialized = true;

    // Replace templates if needed.
    if (!this.rejectButton) {
      // assign default template.
      this.rejectButton = this.rejectButtonTmpl;
    }
    if (!this.completeButton) {
      // assign default template.
      this.completeButton = this.completeButtonTmpl;
    }

    // Update texts.
    if (this.useCertify) {
      this.states.approved = {
        ...this.states.approved,
        action: 'Certify',
        done: 'Certified',
        await: 'Certification'
      };
    }

    if (this.replaceStates) {
      Object.keys(this.replaceStates).forEach(name => {
        if (this.states[name]) {
          this.states[name] = {
            ...this.states[name],
            ...this.replaceStates[name],
          }
        }
      })
    }
  }

  setCardState() {
    if (this.task && this.task.client_state.optionalApprover) {
      this.hideCard = this.task.completed
        ? !this.performedBy // hide the card if it wasn't completed by a user
        : !this.users.length; // hide the card if no users are assigned the role
    }

    if (!this.task) {
      this.cardState.class_value = this.states.disabled.class;
      this.cardState.text_value = this.awaitingText;
      this.cardState.footerTemplate = undefined;
    } else if (this.completed) {
      this.cardState.footerTemplate = this.completedTemplate;
      if (!this.isRejected) {
        this.cardState.class_value = this.states.approved.class;
        this.cardState.text_value = (this.isSubmittal
          ? this.states.submitted.done
          : this.states.approved.done) + this.performedBy;
      } else {
        this.cardState.class_value = this.states.rejected.class;
        this.cardState.text_value = this.states.rejected.done + this.performedBy;
      }
    } else if (this.disableActions) {
      this.cardState.class_value = this.states.waiting.class;
      this.cardState.text_value = this.states.waiting.await;
      this.cardState.footerTemplate = undefined;
    } else {
      this.cardState.class_value = this.states.waiting.class;
      this.cardState.text_value = this.awaitingText;
      this.cardState.footerTemplate = this.allowed
        ? this.allowedRejectOnly ? this.rejectButtonTemplate : this.actionButtonsTemplate
        : this.reminderButtonTemplate;
    }
  }

  get isRejected() {
    return this.task.store.rejected;
  }

  get canRemind() {
    return !this.hideReminder && this.users && this.users.length && this.task && !this.completed;
  }

  get awaitingText() {
    return (!this.task || this.isSubmittal)
      ? `Awaiting ${this.states.submitted.await}`
      : `Awaiting ${this.states.approved.await}`;
  }

  getUserFullName(user: Model.User) {
    return Profile.contactFullName(user);
  }

  completeTask(rejected: boolean) {
    rejected ? this.reject.emit() : this.approve.emit();
  }

  sendReminder() {
    this.reminderButtonStatus = 'loading';
    this.apiService.notifyTask(this.task).subscribe(() => {
      this.hideReminder = true;
      this.reminderButtonStatus = 'success';
    },
    (err) => {
      this.hideReminder = false;
      this.reminderButtonStatus = 'fail';
    });
  }

  get reminderText() {
    switch (this.reminderButtonStatus) {
      case 'loading': return 'Sending Reminder...';
      case 'success': return 'Reminder Sent';
      case 'fail': return 'Failure, please try again';
      default: return 'Send Reminder';
    }
  }

  get isSending() {
    return this.reminderButtonStatus === 'loading';
  }

  get isSuccess() {
    return this.reminderButtonStatus === 'success';
  }

  get isFailure() {
    return this.reminderButtonStatus === 'fail';
  }

  get completeVerb() {
    return this.isSubmittal ? this.states.submitted.action : this.states.approved.action;
  }

  get approvalDate() {
    return this.task.created_at;
  }
}
