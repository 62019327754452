import { withLatestFrom, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { ACTION_BUTTONS, PROGRAM_KEYS, PROJECT_ROLES, SYSTEM_ROLES, WORKFLOW_STEPS } from '@app-consts';
import { Profile, UserRoleScope } from '@app-models';
import { ApiService, LookupService, ProgramService } from '@app-services';
import { IContactItem } from './contact-role-item/contact-role-item.component';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
})
export class ContactsComponent implements OnInit, OnDestroy {
  canEdit = true;
  firstTouch = true;

  contacts: {
    [role_id: number]: IContactItem
  } = {};
  pocContactTypeId: number;
  alternatePoCContactTypeId: number;
  approverHeaderText = 'Approver Contacts';
  alternatePoCContactBtnText = 'Alternate Point of Contact';
  reporterContactBtnText = 'Fiscal Reporter';
  includeFiscalReporters = false;

  inviter = '';
  poc = {};
  alternatePoCList = [];
  fiscalReporterList = [];
  contactList = [];
  approverList = [];

  context: {
    fund_id: number,
    fund_key: string,
    is_legacy_fund: boolean,
    proposal_id: number,
    show_next_button: boolean,
  };

  private destroy$: Subject<boolean> = new Subject();
  private initialized = false;
  private currentWorkflowStep = WORKFLOW_STEPS.CONTACTS;
  private workflowStateName = `${this.currentWorkflowStep}_state`;
  private isStepDirty = true;

  constructor(
    private apiService: ApiService,
    private lookupService: LookupService,
    private programService: ProgramService,
    private router: Router,
    private store: Store<State>
  ) {
  }

  ngOnInit() {
    this.store.select(Queries.Contacts.get).pipe(
        withLatestFrom(
          this.store.select(Queries.CurrentProposal.getProposal),
          this.store.select(Queries.Auth.getCurrentUser)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        // initialize one time set up code.
        this.initialize(data);

        // make sure expected contacts are all there
        this.hydrateContacts(data[0]);

        // data has changed...run validation
        if (this.context.is_legacy_fund) {
          this.store.dispatch(Actions.CurrentWorkflow.validate(this.currentWorkflowStep));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  /**
   * Initializes the vars needed to listen for changes.
   * @private
   * @param {any} data
   * */
  private initialize(data: any) {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;

    const [contact_items, p, user] = data;
    this.inviter = Profile.contactFullName(user);

    // set up context based on the project's fund type.
    this.context = {
      proposal_id: p.id,
      fund_id: p.funds[0].id,
      fund_key: p.funds[0].key,
      is_legacy_fund: false,
      show_next_button: false,
    }
    this.context.is_legacy_fund = [PROGRAM_KEYS.LVG, PROGRAM_KEYS.GP, PROGRAM_KEYS.IPLAN].includes(this.context.fund_key);

    // configure contacts
    switch (this.context.fund_key) {
      case PROGRAM_KEYS.LVG: {
        this.configureLVGContacts(p);
        break;
      }

      case PROGRAM_KEYS.GP_1:
      case PROGRAM_KEYS.GP: {
        this.configureGPContacts();
        break;
      }

      case PROGRAM_KEYS.GP_2: {
        this.configureGP2Contacts();
        break;
      }

      case PROGRAM_KEYS.IPLAN: {
        this.configureIPlanContacts();
        break;
      }

      case PROGRAM_KEYS.SEP: {
        this.configureSEPContacts();
        break;
      }

      case PROGRAM_KEYS.NEP: {
        this.configureNEPContacts(p);
        break;
      }
    }

    // set up any legacy stuff
    if (this.context.is_legacy_fund) {
      if (this.context.fund_key === PROGRAM_KEYS.LVG) {
        this.firstTouch = !contact_items.every((c: Model.UserRoleScope) => c.id > 0);
      } else {
        this.firstTouch = p[this.workflowStateName] === 'pristine';
      }
      this.isStepDirty = !this.firstTouch;

      // put up 'next' button.
      this.store.dispatch(Actions.Layout.setActions([
        {
          id: 1,
          type: 'button',
          title: 'Next',
          route: ACTION_BUTTONS.NEXT,
          class: 'primary',
        },
      ]));

      // listen for button actions.
      this.store.select(Queries.Layout.getEmitState)
        .pipe(takeUntil(this.destroy$))
        .subscribe((emitted: { name: string }) => {
          if (emitted && emitted.name === ACTION_BUTTONS.NEXT) {
            this.store.dispatch(Actions.CurrentWorkflow.gotoNext(this.currentWorkflowStep));
          }
        });
    } else {
      this.firstTouch = !contact_items.every((c: Model.UserRoleScope) => c.id > 0);

      // modern versions should have workflow router that listens for the next button,
      // as well as perform continuous validation.
      this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));
    }
  }

  /**
   * Configures required contacts for iPlan.
   */
  private configureIPlanContacts() {
    // initialize contacts ui config.
    this.pocContactTypeId = PROJECT_ROLES.PROJECT_LEAD.ID;
    this.alternatePoCContactTypeId = PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID;
    this.alternatePoCContactBtnText = this.getRoleName(this.alternatePoCContactTypeId);

    this.contacts[this.pocContactTypeId] = {
      title: this.getRoleName(this.pocContactTypeId),
      contactOnly: false,
      required: true,
      canDelete: false,
    };

    this.contacts[this.alternatePoCContactTypeId] = {
      title: this.getRoleName(this.alternatePoCContactTypeId),
      contactOnly: false,
      required: true,
      canDelete: true,
    };

    // keep approver list in order, so they can be iterated.
    this.approverList = [];
    this.contacts[PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID]);

    this.contacts[PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID]);

    this.contacts[PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID]);

    this.contacts[PROJECT_ROLES.CHIEF_STUDENT_SERVICES_OFFICER.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHIEF_STUDENT_SERVICES_OFFICER.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.CHIEF_STUDENT_SERVICES_OFFICER.ID]);

    this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID] = {
      title: this.getRoleName(PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID]);
  }

  /**
   * Configures required contacts for GP.
   */
  private configureGPContacts() {
    // initialize contacts ui config.
    this.approverHeaderText = 'Certifying Contacts';
    this.pocContactTypeId = PROJECT_ROLES.PROJECT_LEAD.ID;
    this.alternatePoCContactTypeId = PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID;
    this.alternatePoCContactBtnText = this.getRoleName(this.alternatePoCContactTypeId);

    this.contacts[this.pocContactTypeId] = {
      title: this.getRoleName(this.pocContactTypeId),
      contactOnly: false,
      required: true,
      canDelete: false,
    };

    this.contacts[this.alternatePoCContactTypeId] = {
      title: this.getRoleName(this.alternatePoCContactTypeId),
      contactOnly: false,
      required: true,
      canDelete: true,
    };

    // keep approver list in order, so they can be iterated.
    this.approverList = [];
    this.contacts[PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID]);

    this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID] = {
      title: this.getRoleName(PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID]);
  }

  /**
   * Configures required contacts for GP 2.0.
   */
  private configureGP2Contacts() {
    // initialize contacts ui config.
    this.approverHeaderText = 'Approvers';
    this.pocContactTypeId = PROJECT_ROLES.PROJECT_LEAD.ID;
    this.alternatePoCContactTypeId = PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID;
    this.alternatePoCContactBtnText = 'Additional Contact';
    this.context.fund_id = this.programService.getParentProgramByKey(PROGRAM_KEYS.GP).id;

    this.contacts[this.pocContactTypeId] = {
      title: this.getRoleName(this.pocContactTypeId),
      contactOnly: false,
      required: true,
      canDelete: false,
    };

    this.contacts[this.alternatePoCContactTypeId] = {
      title: this.getRoleName(this.alternatePoCContactTypeId),
      contactOnly: false,
      required: true,
      canDelete: true,
    };

    // keep approver list in order, so they can be iterated.
    this.approverList = [];
    this.contacts[PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID),
      contactOnly: false,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID]);

    this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID] = {
      title: this.getRoleName(PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID),
      contactOnly: false,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID]);
  }

  private configureLVGContacts(p: Model.ProposalItem) {
    this.approverHeaderText = 'Approvers';
    this.pocContactTypeId = PROJECT_ROLES.PROJECT_LEAD.ID;
    this.alternatePoCContactTypeId = PROJECT_ROLES.CONTACT.ID;
    this.alternatePoCContactBtnText = this.getRoleName(this.alternatePoCContactTypeId);
    const leadInstitutionId = p.lead_institution ? p.lead_institution.id : undefined;
    const leadInstitutionName = p.lead_institution ? p.lead_institution.name : undefined;

    this.contacts[this.pocContactTypeId] = {
      title: `${this.getRoleName(PROJECT_ROLES.PROJECT_LEAD.ID)} - ${leadInstitutionName}`,
      contactOnly: false,
      required: true,
      canDelete: false,
    };

    this.contacts[this.alternatePoCContactTypeId] = {
      title: `${this.getRoleName(this.alternatePoCContactTypeId)} - ${leadInstitutionName}`,
      contactOnly: false,
      required: true,
      canDelete: true,
      institution_id: leadInstitutionId,
    };

    this.approverList = [];
    this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID] = {
      title: this.getRoleName(PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID]);

    this.contacts[PROJECT_ROLES.CEO.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CEO.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.CEO.ID]);

    this.contacts[PROJECT_ROLES.TRUSTEES_BOARD_PRESIDENT.ID] = {
      title: this.getRoleName(PROJECT_ROLES.TRUSTEES_BOARD_PRESIDENT.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.TRUSTEES_BOARD_PRESIDENT.ID])
  }

  /**
   * Configures required contacts for SEP.
   */
  private configureSEPContacts() {
    // see if we're on plan or reporting workflow.
    const reporting = this.router.url.includes('/reporting/');
    this.context.show_next_button = reporting; // show next button when in reporting workflow.

    // initialize contacts ui config.
    this.approverHeaderText = 'Approvers';
    this.pocContactTypeId = PROJECT_ROLES.PROJECT_LEAD.ID;
    this.alternatePoCContactTypeId = PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID;
    this.alternatePoCContactBtnText = this.getRoleName(this.alternatePoCContactTypeId);

    this.contacts[this.pocContactTypeId] = {
      title: this.getRoleName(this.pocContactTypeId),
      contactOnly: false,
      required: true,
      canDelete: false,
    };

    this.contacts[this.alternatePoCContactTypeId] = {
      title: this.getRoleName(this.alternatePoCContactTypeId),
      contactOnly: false,
      required: true,
      canDelete: true,
    };

    this.contacts[PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };

    this.contacts[PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };

    this.contacts[PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };

    this.contacts[PROJECT_ROLES.CHIEF_STUDENT_SERVICES_OFFICER.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHIEF_STUDENT_SERVICES_OFFICER.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };

    this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID] = {
      title: this.getRoleName(PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID),
      contactOnly: true,
      required: true,
      canDelete: false,
    };

    // fill out contact & approver lists.
    this.contactList = [];
    this.approverList = [];
    if (reporting) {
      // contacts
      this.contactList.push(this.contacts[PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID]);
      this.contactList.push(this.contacts[PROJECT_ROLES.CHIEF_STUDENT_SERVICES_OFFICER.ID]);
      this.contactList.push(this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID]);

      // allow designees.
      this.contacts[PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID].hasDesignee = true;
      this.approverList.push(this.contacts[PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID]);
      this.contacts[PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID].hasDesignee = true;
      this.approverList.push(this.contacts[PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID]);
    } else {
      // no contacts in plan workflow
      this.approverList.push(this.contacts[PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID]);
      this.approverList.push(this.contacts[PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID]);
      this.approverList.push(this.contacts[PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID]);
      this.approverList.push(this.contacts[PROJECT_ROLES.CHIEF_STUDENT_SERVICES_OFFICER.ID]);
      this.approverList.push(this.contacts[PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID]);
    }
  }

  /**
   * Configures required contacts for NEP.
   */
  private configureNEPContacts(p: Model.ProposalItem) {
    // initialize contacts ui config.
    this.approverHeaderText = 'Approvers';
    this.pocContactTypeId = PROJECT_ROLES.PROJECT_LEAD.ID;
    this.alternatePoCContactTypeId = PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID;
    this.alternatePoCContactBtnText = this.getRoleName(this.alternatePoCContactTypeId);
    const leadInstitutionId = p.lead_institution ? p.lead_institution.id : undefined;

    this.contacts[this.pocContactTypeId] = {
      title: this.getRoleName(this.pocContactTypeId),
      contactOnly: false,
      required: true,
      canDelete: false,
    };

    this.contacts[this.alternatePoCContactTypeId] = {
      title: this.getRoleName(this.alternatePoCContactTypeId),
      contactOnly: false,
      required: true,
      canDelete: true,
    };

    // fiscal reporter list
    this.includeFiscalReporters = true;
    const placeholder = this.generateEmptyContact(PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID);
    this.contacts[PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID] = {
      title: this.getRoleName(PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID),
      contactOnly: false,
      required: true,
      canDelete: true,
      institution_id: leadInstitutionId,
    }

    // contact list.
    this.contactList = [];
    this.contacts[PROJECT_ROLES.DISTRICT_SUP_PRES.ID] = {
      title: this.getRoleName(PROJECT_ROLES.DISTRICT_SUP_PRES.ID),
      contactOnly: false,
      required: true,
      canDelete: true,
    };
    this.contactList.push(this.contacts[PROJECT_ROLES.DISTRICT_SUP_PRES.ID]);

    this.contacts[PROJECT_ROLES.RESPONSIBLE_ADMIN.ID] = {
      title: this.getRoleName(PROJECT_ROLES.RESPONSIBLE_ADMIN.ID),
      contactOnly: false,
      required: true,
      canDelete: true,
    };
    this.contactList.push(this.contacts[PROJECT_ROLES.RESPONSIBLE_ADMIN.ID]);

    // keep approver list in order, so they can be iterated.
    this.approverList = [];
    this.contacts[PROJECT_ROLES.CEO.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CEO.ID),
      contactOnly: false,
      required: true,
      hasDesignee: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.CEO.ID]);

    this.contacts[PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID] = {
      title: this.getRoleName(PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID),
      contactOnly: false,
      required: true,
      hasDesignee: true,
      canDelete: false,
    };
    this.approverList.push(this.contacts[PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID]);

    this.contacts[SYSTEM_ROLES.FUND_MONITOR.ID] = {
      title: this.getRoleName(SYSTEM_ROLES.FUND_MONITOR.ID),
      contactOnly: false,
      required: true,
      canDelete: false,
      readOnly: true,
    };
    this.approverList.push(this.contacts[SYSTEM_ROLES.FUND_MONITOR.ID]);
    // fetch user info of the fund monitor.
    this.fetchMonitorUser(SYSTEM_ROLES.FUND_MONITOR.ID);
  }

  /**
   * Because this parent component need not be refreshed, the contacts are hydrated just once.
   * @param contactItems
   */
  private hydrateContacts(contactItems: Array<any>) {
    // Hold onto placeholder AlternatePoC contacts that haven't been saved yet. (They don't have a user_id associated.)
    const unsavedAlternateContacts = this.alternatePoCList.filter(item => (!item.pc.user_id));
    this.alternatePoCList = [];
    const unsavedFiscalReporters = this.fiscalReporterList.filter(item => (!item.pc.user_id));
    this.fiscalReporterList = [];
    Object.keys(this.contacts).forEach(key => {
      const contactTypeId = Number(key);
      this.initContactType(contactItems, contactTypeId);
    })
    this.alternatePoCList = [...this.alternatePoCList, ...unsavedAlternateContacts]; // Re-add unsaved contacts
    this.alternatePoCList = this.alternatePoCList.sort((a, b) => a.id - b.id);
    this.fiscalReporterList = [...this.fiscalReporterList, ...unsavedFiscalReporters]; // Re-add unsaved fiscal reporters
    // adds empty card in case of delete
    if (this.fiscalReporterList.length < 1 && this.includeFiscalReporters) {
      this.addFiscalReporter();
    }
    this.fiscalReporterList = this.fiscalReporterList.sort((a, b) => a.id - b.id);
  }

  /**
   * Performs processing for each contact type, and gets it matched up with the proposal contacts
   * that match the given the role ID. For example:
   * - If the contact type doesn't exist, create a placeholder.
   * - If the contact type can take a Designee, match the Designee to the Main contact (or create a placeholder if none exists).
   * - If contact type is Alternate PoCs, consolidate them into this.alternatePoCList.
   * @param proposalContacts
   * @param roleId
   */
  private initContactType(proposalContacts: Array<Model.UserRoleScope>, roleId: number) {
    const contactType = this.contacts[roleId];
    const setDesignee = !!contactType.hasDesignee;

    // Find any proposal contacts matching the given roleId.
    const matchingContacts = proposalContacts.filter(i => i.role_id === roleId);

    // Determine which matching contact is main contact vs designee.
    let existingContact;
    let designeeContact;
    matchingContacts.forEach((item: Model.UserRoleScope) => {
      if (item.designee && setDesignee) {
        designeeContact = item;
      } else {
        existingContact = item
      }
    });

    if (!existingContact) {
      // If we didn't find an existing contact of the given roleId, create a placeholder item
      contactType.pc = this.generateEmptyContact(roleId);
    } else { // Handle existing proposal contacts.
      if (roleId === this.alternatePoCContactTypeId) {
        // Build up Alternate PoC list
        matchingContacts.forEach(pc => {
          this.alternatePoCList.push({
            ...contactType,
            pc: pc,
          });
        });
      } else if (roleId === PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID && this.fiscalReporterList.length < 1) {
        matchingContacts.forEach(pc => {
          this.fiscalReporterList.push({
            ...contactType,
            pc: pc,
          });
        });
      } else {
        contactType.pc = { ...existingContact };
      }
    }

    if (setDesignee) {
      contactType.dc = designeeContact || this.generateEmptyContact(roleId, true);
    }
  }

  /**
   * Associates a new contact role to a user
   * @param {UserRoleScope} role_scope
   * @param {number} altIndex, index of alternatePoCList
   */
  onAssociateContact(role_scope: UserRoleScope, altIndex?: number) {
    // If we're associating an Alternate Contact, we need to remove an item from the temporary list
    if (role_scope.role_id === this.alternatePoCContactTypeId && (typeof altIndex !== 'undefined')) {
      this.alternatePoCList.splice(altIndex, 1);
    }

    // If we're associating a fiscal reporter, we need to remove an item from the temporary list
    if (role_scope.role_id === PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID && (typeof altIndex !== 'undefined')) {
      this.fiscalReporterList.splice(altIndex, 1);
    }

    // prepare scope.
    const scope = {
      user_id: role_scope.user_id,
      role_id: role_scope.role_id,
      designee: role_scope.designee,
      proposal_id: this.context.proposal_id
    }
    // if contact requires institution_id, then supply it.
    if (this.contacts[role_scope.role_id].institution_id) {
      scope['institution_id'] = this.contacts[role_scope.role_id].institution_id;
    }

    this.store.dispatch(Actions.Contacts.add({...scope, fund_id: this.context.fund_id }));
    this.markDirty();
  }

  /**
   * Removes given contact from the proposal.
   * @param {UserRoleScope} role_scope
   */
  onRemoveContact(role_scope: UserRoleScope) {
    if (role_scope.user_id && role_scope.role_id) {
      this.store.dispatch(Actions.Contacts.delete({
        ...role_scope,
        fund_id: this.context.fund_id
      }));
    } else {
      // remove the contact from the local list
      this.alternatePoCList = this.alternatePoCList.filter(contact => {
        return contact.pc !== role_scope;
      });
    }
  }

  /**
   * Track component's lifecycle by contact id.
   * @param index
   * @param item
   */
  trackById(index: number, item: any): number {
    if (item && item.pc) {
      return item.pc.id;
    }
  }

  addAlternatePointOfContact() {
    const placeholder = this.generateEmptyContact(this.alternatePoCContactTypeId);
    this.alternatePoCList.push({
      ...this.contacts[this.alternatePoCContactTypeId],
      pc: placeholder,
    });
  }

  addFiscalReporter() {
    const placeholder = this.generateEmptyContact(PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID);
    this.fiscalReporterList.push({
      ...this.contacts[PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID],
      pc: placeholder,
    });
  }

  private getRoleName(role_id: number): string {
    return this.lookupService.getRoleNameForFund(role_id, this.context.fund_key);
  }

  private generateEmptyContact(roleType: number, designee = false): UserRoleScope {
    return new UserRoleScope({
      role_id: roleType,
      proposal_id: this.context.proposal_id,
      user_id: null,
      institution_id: null,
      user: {},
      designee,
    });
  }

  private markDirty() {
    if (this.context.is_legacy_fund && !this.isStepDirty) {
      // change has been made, mark it as dirty.
      this.isStepDirty = true;
      if (this.context.fund_key !== PROGRAM_KEYS.LVG) {
        this.store.dispatch(Actions.CurrentProposal.setStateDirty(this.currentWorkflowStep));
      }
      // clear first touch
      this.store.dispatch(Actions.Workflow.clearFirstTouch(this.currentWorkflowStep));
    }
  }

  private fetchMonitorUser(roleId: number) {
    this.apiService.listProfiles({ role_ids: [roleId], fund_ids: [this.context.fund_id] })
      .subscribe(monitors => {
        if (monitors && monitors.count > 0) {
          this.contacts[roleId].pc = { user: monitors.users[0], designee: false };
        }
      });
  }

  gotoNext() {
    this.store.dispatch(Actions.CurrentWorkflow.gotoNext(this.currentWorkflowStep));
  }
}
