import { NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { DateTransform } from '../../pipes';
import { DATE_TIME_MOMENT_FORMAT_STRINGS } from '@app/core/consts';

/*
 * [date-transform] is a one-way transformation pipe directive. Applied to a text input, it will attempt to parse the
 * input value into a date, and then output a string of format 'YYYY-MM-DD'. Updates on blur'.
 */

@Directive({
  selector: '[date-transform]',
  providers: [DateTransform],
})
export class DateTransformerDirective implements OnInit {

  constructor(private control: NgControl,
              private el: ElementRef,
              private pipe: DateTransform) {}

  value: string | number = '';

  @HostListener('blur', ['$event']) onBlur(event) {
    this.transformValue(event.target.value);
    this.setFormValues();
  }
  ngOnInit() {
    const parent = this.el.nativeElement;

    const savedValue = parent.value;
    if (savedValue) {
      this.transformValue(savedValue);
      this.setFormValues();
    }
  }

  transformValue(value) { // happens on blur by default
    this.value = this.pipe.transform(value, 'YYYY-MM-DD');
  }

  setFormValues() {
    if (this.value === null) {
      this.control.control.setValue(undefined);
      this.control.valueAccessor.writeValue(null);
      return;
    }
    this.control.control.setValue(this.value);
  }
}
