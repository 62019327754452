import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, State, Queries, Model } from '@app-ngrx-domains';
import { ApiService, LookupService } from '@app-services';
import { Profile } from '@app-models';
import { AppUtils, ValidatorsEx } from '@app-utilities';
import { EnumErrorTypes } from '@app-models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html'
})
export class UserInfoComponent implements OnInit, OnDestroy {
  @Input() profile: Profile = new Profile({});
  @Input() disableEmail = false;
  @Output() saveInfoClicked: EventEmitter<Model.User> = new EventEmitter();

  form: FormGroup;
  errors: any = {};
  showStatus = false;
  statusMessage: Model.StatusMessage;

  jobCategories$: Observable<Array<Model.SelectOption>>;
  institutionsLookupFormatted: Array<Model.SelectOption> = [];

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private apiService: ApiService,
    private lookupService: LookupService,
    private _fb: FormBuilder,
    private store: Store<State>
  ) {
    this.jobCategories$ = this.store.select(Queries.LookupTables.getJobCategories);
  }

  ngOnInit() {
    const employer = this.profile.employer ? AppUtils.formatInstitution(this.profile.employer) : undefined;
    this.form = this._fb.group({
      prefix: [this.profile.prefix || ''],
      first_name: [this.profile.first_name || '', [Validators.required, Validators.minLength(1)]],
      last_name: [this.profile.last_name || '', [Validators.required, , Validators.minLength(1)]],
      suffix: [this.profile.suffix || ''],
      job_category_id: [this.profile.job_category_id || '', [Validators.required]],
      title: [this.profile.title || ''],
      employer: [employer, [Validators.required]],
      email_address: [this.profile.email_address || '', [Validators.required, ValidatorsEx.email]],
      phone: [this.profile.phone || '', [ValidatorsEx.phoneNumber, Validators.minLength(10)]],
      phone_extension: [this.profile.phone_extension || '', [ValidatorsEx.extension, Validators.maxLength(12)]],
      fax: [this.profile.fax || '', [ValidatorsEx.phoneNumber, Validators.minLength(10)]],
      fax_extension: [this.profile.fax_extension || '', [ValidatorsEx.extension, Validators.maxLength(12)]],
      address: [this.profile.address || ''],
      address_2: [this.profile.address_2 || ''],
      city: [this.profile.city || ''],
      zip: [this.profile.zip || '', [ValidatorsEx.zipCode]],
    });
    if (this.disableEmail) {
      this.form.controls.email_address.disable();
    }
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.showStatus = false;
    });
  }

  displayStatus(type: string, message: string) {
    this.showStatus = !!message;
    this.statusMessage = {
      message,
      type
    };
  }

  updateInstitutionsList(filterStr: string, limit = 30) {
    const filters = {
      match_strings: filterStr,
      limit
    };

    this.lookupService.formattedInstitutionList$(filters)
      .subscribe(
        (res) => {
          this.institutionsLookupFormatted = res;
        },
        (err) => {
          this.store.dispatch(Actions.App.setError({
            type: EnumErrorTypes.api,
            location: this.constructor.name,
            show: false,
            raw: err,
          }));
        }
      );
  }

  onSubmit() {

    this.errors = {};

    const formValue = this.form.value;
    const updatedProfile = {
      id: this.profile.id,
      prefix: formValue.prefix || '',
      first_name: formValue.first_name,
      last_name: formValue.last_name,
      suffix: formValue.suffix || '',
      job_category_id: formValue.job_category_id,
      title: formValue.title || '',
      institution_id: formValue.employer.value,
      email_address: formValue.email_address || this.profile.email_address,
      phone: formValue.phone || '',
      phone_extension: formValue.phone_extension || '',
      fax: formValue.fax || '',
      fax_extension: formValue.fax_extension || '',
      address: formValue.address || '',
      address_2: formValue.address_2 || '',
      city: formValue.city || '',
      zip: formValue.zip || '',
    };

    // now check and see if the email address is unique

    this.displayStatus('loading', 'Saving profile...');
    this.apiService.updateProfile(updatedProfile).subscribe(
      (res) => {
        this.displayStatus('success', 'Successfully saved profile.');
        this.saveInfoClicked.emit(updatedProfile);
      },
      (err) => {
        this.displayStatus('fail', err.message || 'Failed to save profile. Please try again.');
        this.store.dispatch(Actions.App.setError({
          type: EnumErrorTypes.api,
          location: this.constructor.name,
          show: false,
          raw: err,
        }));
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
