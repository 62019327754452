import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Proposal, IPlanProposal } from '@app-models';
import { AnalyticsService, EVENT_ACTION, EVENT_CATEGORY } from '@app-services';

/*
The project-table is similar to the project-filter. It takes an array of rows, and an array of columns. The columns
contain the table headers as well as the template for the table cells. If the column.sortable is true, it is important that
the column.prop field matches one of the fields on the rows.
There are some custom defined sorting functions, but generally it's accomplished by simply sorting on
`(a, b) => a[column.prop] > b[column.prop] ? 1 : -1`
 */

@Component({
  selector: 'project-table',
  templateUrl: 'project-table.component.html'
})
export class ProjectTableComponent implements OnInit, OnDestroy {
  @ViewChild('columnHeaderTmpl', {static: true}) columnHeaderTmpl: TemplateRef<any>;
  @ViewChild('columnHiddenHeaderTmpl', {static: true}) columnHiddenHeaderTmpl: TemplateRef<any>;
  @ViewChild('editTmpl', {static: true}) editTmpl: TemplateRef<any>;
  @ViewChild('delTmpl', {static: true}) delTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) actionsTmpl: TemplateRef<any>;
  @ViewChild('emptyTmpl', {static: true}) emptyTmpl: TemplateRef<any>;
  @ViewChild('footerTmpl', {static: true}) footerTmpl: TemplateRef<any>;

  @Input() projectLongName = 'Projects';
  @Input() projectShortName = this.projectLongName;
  @Input() showTableCaption = true;
  @Input() analyticsFundName: string;
  @Input() pageLimit: any = 50; // datatable number of rows to show per page
  @Input() currentPage = 1;
  @Input() resetScroll = true;
  @Input() actions: { edit: boolean, del: boolean };
  @Input() defaultSort: string;
  @Input() paginateTop = false;
  @Input() trackByFn = (index: number) => index;
  @Input() tableOverflow: boolean = true;
  @Input() customFooterTmpl: TemplateRef<any>;
  @Input() nestedRowsProp: string;
  @Input() tableActionsTmpl: TemplateRef<any>;

  filteredProposals: Proposal[];

  @Input() set rows(list) {
    if (list) {
      this.filteredProposals = list;
      this.sortState.column = this.defaultSort;
      this.sortList();
    }
  }
  displayList: Proposal[] = []; // the proposals for currentPage

  showDeleteAlert = false;
  proposalToDelete: any;
  proposalLink = Proposal.routerLink;

  @Input() columns: Array<any> = [];
  @Input() nestedColumns: Array<any> = [];
  @Input() empty: TemplateRef<any>;
  @Input() footer: TemplateRef<any>;

  @Output() deleteProposal: EventEmitter<any> = new EventEmitter();

  sortState: {
    column: string,
    descending: number
  };
  ariaState: {
    title?: string,
    institutions?: string,
    project_type?: string,
    project_state?: string,
    year?: string,
    fund?: string,
    status?: string
  };

  constructor(private analyticsService: AnalyticsService) {
    this.sortState = {
      column: 'none',
      descending: -1
    };
    this.ariaState = {
      title: 'none',
      institutions: 'none',
      project_type: 'none',
      project_state: 'none',
      year: 'none',
      fund: 'none',
      status: 'none'
    };
  }

  ngOnInit() {
    if (!this.projectShortName) {
      this.projectShortName = this.projectLongName;
    }
    if (this.defaultSort) {
      this.sortState.column = this.defaultSort;
    }
    this.sortList();
    if (this.actions) {
      this.columns.push({
        prop: 'can_edit', name: 'Actions', sortable: false,
        cellTemplate: this.actionsTmpl,
        headerCssClass: 'align-center col-6-rem',
        cellCssClass: 'align-center'
      })
    }
    if (!this.empty) {
      // assign default empty template.
      this.empty = this.emptyTmpl;
    }
    if (!this.footer) {
      // assign default footer template.
      this.footer = this.footerTmpl;
    }
  }

  ngOnDestroy() {}

  updatePage(increment: number) {
    this.currentPage += increment;
    const sliceStart = (this.currentPage - 1) * this.pageLimit;
    this.displayList = this.filteredProposals.slice(sliceStart, sliceStart + this.pageLimit);
  }

  sortStateStep(column) { // state is controlled by this.sortState
    if (column === this.sortState.column) {
      if (this.sortState.descending > 0) { // state 3: same column, no sort
        this.ariaState[column] = this.defaultSort;
        this.sortState.column = this.defaultSort;
        this.sortState.descending = -1;
        // return list to the way it came back from the database
        this.sortList();
      } else { // state 2: same column, sort descending
        this.ariaState[column] = 'descending';
        this.sortState.descending = 1;
        this.filteredProposals.reverse();
        this.currentPage = 1;
        this.updatePage(0);
      }
    } else { // state 1: new column, sort ascending
      this.ariaState[column] = 'ascending';
      this.sortState.column = column;
      this.sortState.descending = -1;
      this.sortList();
    }

    this.logSort(column);
  }

  sortList() {
    if (this.filteredProposals) {
      const desc = this.sortState.descending;
      let sorter;
      if (this.defaultSort === 'title') { // specifically for proposals
        this.filteredProposals.forEach(proposal => {
          if (!proposal.title || proposal.title === '') {
            proposal.title = 'Unnamed Project';
          }
        });
      }
      switch (this.sortState.column) {
        case 'title':
        case 'name':
          sorter = (a, b) => {
            // replace(/\W/g, '') is regex to strip out non-word characters
            const titleA = a[this.sortState.column].replace(/\W/g, '').trim().toLowerCase();
            const titleB = b[this.sortState.column].replace(/\W/g, '').trim().toLowerCase();
            return titleA > titleB ? -desc : desc;
          };
          break;
        case 'year':
          sorter = (a, b) => a[ 'year' ][ 'id' ] > b[ 'year' ][ 'id' ] ? -desc : desc;
          break;
        case 'institutions':
          sorter = (a, b) => {
            const leadInstA = a.lead_institution ? a.lead_institution.name.toLowerCase() : '';
            const leadInstB = b.lead_institution ? b.lead_institution.name.toLowerCase() : '';
            return leadInstA > leadInstB ? -desc : desc;
          };
          break;
        case 'type':
          sorter = (a, b) => {
            const typeA = a[this.sortState.column] ? a[this.sortState.column].toLowerCase() : '';
            const typeB = b[this.sortState.column] ? b[this.sortState.column].toLowerCase() : '';
            return typeA > typeB ? -desc : desc;
          };
          break;
        default:
          sorter = (a, b) => a[this.sortState.column] > b[this.sortState.column] ? -desc : desc;
      }
      if (sorter) {
        this.filteredProposals.sort(sorter);
      }
      this.currentPage = 1;
      this.updatePage(0);
    }
  }

  deleteProposalAlert(id: number) {
    this.proposalToDelete = id;
    this.showDeleteAlert = true;
  }

  public dismissDeleteAlert() {
    this.showDeleteAlert = false;
    this.proposalToDelete = null;
  }

  public confirmDelete() {
    this.deleteProposal.emit(this.proposalToDelete);
    this.showDeleteAlert = false;
    this.proposalToDelete = null;
  }

  logEdit() {
    this.logEvent(EVENT_CATEGORY.table, EVENT_ACTION.edit, 'action-button');
  }

  logDelete() {
    this.logEvent(EVENT_CATEGORY.table, EVENT_ACTION.delete, 'action-button');
  }

  private logSort(sortName: string) {
    this.logEvent(EVENT_CATEGORY.table, EVENT_ACTION.sort, sortName.toLowerCase());
  }

  private logEvent(category: string, action: string, label: string) {
    const fullAction = `${action}-proposals-${this.analyticsFundName}`;
    this.analyticsService.logEvent(category, fullAction, label);
  }

  canEdit(proposal: any) {
    let canEdit;

    if (proposal.statuses) {
      // statuses are set and used by iPlan oly...
      canEdit = IPlanProposal.isDraft(proposal)
    } else if (proposal.states) {
      canEdit = Proposal.projectIsDraft(proposal);
    } else {
      canEdit = Proposal.isDraftOrEdit(proposal.state_id);
    }
    return proposal.allowed && canEdit;
  }
}
