<div class="card {{ level ? 'collapsible-status-card status-card--' + level : '' }} project-card__collapsible"
  [ngClass]="{ 'project-card__collapsible--collapsed': isCollapsed, 'project-card__collapsible--open': !isCollapsed, 'card--nested': isNested }">

  <div class="flex flex--justify-content-between">
    <div class="card-title--collapsible"><ng-content select=".card-title"></ng-content></div>

    <div class="flex flex--align-items-center">
      <ng-content select=".card-title-right"></ng-content>
      <button *ngIf="!hideCollapseButton"
        class="no-print action-button action-button--tertiary project-card__collapsible__trigger"
        [attr.aria-controls]="sectionName"
        title="{{isCollapsed ? 'Show' : 'Hide'}} {{sectionName}}"
        (click)="isCollapsed = !isCollapsed">
        <icon iconId="disclosure-down"></icon>
      </button>
    </div>
  </div>

  <div *ngIf="!isCollapsed" [id]="sectionName" [@collapsible] class="project-card__collapsible">
    <ng-content></ng-content>
  </div>
</div>