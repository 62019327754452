import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, NgZone, OnDestroy, OnInit, Optional, Renderer2, ViewChild } from '@angular/core';
import { Actions, Model, State } from '@app-ngrx-domains';
import { ApiService, ProgramService } from '@app/core/services';
import { DashboardFilterEvent, DashboardFilterService } from '@app/core/services/dashboard-filter.service';
import { AppUtils } from '@app/core/utilities';
import { PopupAnchorDirective } from '@app/shared.generic/directives';
import { Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil, skip } from 'rxjs/operators';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
})

export class CalendarComponent implements OnInit, OnDestroy {

  @ViewChild('eventPopup') eventPopup: ElementRef;
  @ViewChild('eventListPopup') eventListPopup: ElementRef;

  monthLabel: string;
  today: moment.Moment;
  startDate: moment.Moment;
  endDate: moment.Moment;
  dateMarker: moment.Moment; // Keeps track of what day the moment() object is on
  activeMonth: moment.Moment;
  weeks: Array<Array<CalendarDay>> = [];
  days: Array<CalendarDay> = [];
  defaultColor: string = 'grey';

  displayedEvent: Model.Event;
  displayedDay: CalendarDay;
  maxDisplayedEvents: number = 2;
  eventPopupOpen: boolean = false;
  eventListPopupOpen: boolean = false;
  eventsByDate: { [timestamp: string]: Array<Model.Event> };
  programColors: { [programId: number]: string } = {};

  currentFilter: DashboardFilterEvent;

  showSyncModal: boolean;
  syncAll: boolean = true;
  syncStatuses: { [client: string]: Model.StatusMessage } = {};
  calendarClients: Array<Model.SelectOption> = [
    // {
    //   value: 'google',
    //   label: 'Google Calendar'
    // },
    // {
    //   value: 'outlook',
    //   label: 'Microsoft Outlook'
    // }, {
    //   value: 'apple',
    //   label: 'Apple Mail'
    // }
  ];
  private newWindowSettings: string = 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=700';
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<State>,
    private apiService: ApiService,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private programService: ProgramService,
    private dashboardFilterService: DashboardFilterService,
    @Inject(DOCUMENT) private document: Document,
    @Optional() private popupAnchor: PopupAnchorDirective,
  ) { }

  ngOnInit() {

    if (AppUtils.showWIPFeature('google_calendar')) {
      this.calendarClients = [{
        value: 'google',
        label: 'Google Calendar'
      }];
    } else {
      this.calendarClients = [];
    }

    window.addEventListener('message', this.messageSuccessListener);

    // Initialize marker to today
    this.today = moment().startOf('day');
    this.dateMarker = this.today.clone();
    this.buildCalendar(false);

    this.dashboardFilterService.filter$.pipe(skip(1), takeUntil(this.destroy$))
        .subscribe((filter) => {

        if (filter) {
          this.currentFilter = {
            program_ids: filter.program_ids,
            event_types: filter.event_types,
            event_names: [...filter.custom_events, ...filter.system_events]
          };
        } else {
          this.currentFilter = null;
        }

        this.getEvents(true);
      }
    );
  }

  buildCalendar(getEvents = true) {
    const dateMarker = this.dateMarker.startOf('month');  // Move marker to beginning of month
    this.activeMonth = dateMarker.clone();
    this.monthLabel = this.activeMonth.format('MMMM YYYY')

    dateMarker.day(0); // Always start on Sunday
    this.startDate = dateMarker.clone();
    this.endDate = this.activeMonth.clone().endOf('month').day(6);  // Always end on Saturday

    // Get the number of weeks the calendar will display
    const weekCount = (this.endDate.diff(dateMarker, 'days') + 1) / 7;
    this.maxDisplayedEvents = 7 - weekCount; // 7 is the magical number for the heights set in the css

    this.weeks = [];
    this.days = [];

    // Populate weeks
    for (let i = 0; i < weekCount; i++) {
      const days = [];

      // Populate days
      for (let d = 0; d < 7; d++) {
        // Grey out day if it isn't part of active month
        const disabled = !dateMarker.isSame(this.activeMonth, 'month');

        const timestamp = dateMarker.format();

        days.push({
          timestamp,
          date: dateMarker.date(),
          isToday: dateMarker.isSame(this.today, 'day'),
          dayOfWeek: { value: dateMarker.day(), label: dateMarker.format('ddd') },
          disabled,
          events: [],
          displayedEvents: []
          // TODO: also need url for "edit" button
        });

        dateMarker.add(1, 'days');
      }
      this.weeks.push(days);
      this.days.push(...days);
    }

    if (getEvents) {
      this.getEvents();
    }
  }

  getEvents(resetDays = false) {
    const options = {
      start_date: this.startDate.format(),
      end_date: this.endDate.format(),
      ...this.currentFilter || {}
    };

    this.apiService.getCalendarEvents(options).subscribe(response => {
      if (resetDays && this.eventsByDate) {
        // Clear out all days if filters were applied
       this.days.forEach(day => {
          day.events = [];
          day.displayedEvents = [];
        });
      }

      this.eventsByDate = groupBy(response, event => moment(event.event_time).startOf('day').format());

      // Add events to days
      Object.keys(this.eventsByDate).forEach(timestamp => {
        const day = this.days.find(d => d.timestamp === timestamp);
        if (day) {
          const events = this.eventsByDate[timestamp];

          events.forEach(event => {
            const fundId = event.fund_id;
            if (!this.programColors[fundId]) {
              let program = this.programService.getProgramById(fundId);
              let color;

              if (program) {
                color = program.program_settings.calendar_color;

                // If there's no color set, check the parent program's color
                if (!color) {
                  program = this.programService.getParentProgramById(fundId);

                  if (program) {
                    color = program.program_settings.calendar_color;
                  }
                }
              }


              // Set the color but if no color found, set to grey
              this.programColors[fundId] = color || this.defaultColor;
            }

            event.color = this.programColors[fundId];
          });

          day.events = events;
          day.displayedEvents = events.slice(0, this.maxDisplayedEvents);
        }
      });
    });
  }

  goToToday() {
    this.dateMarker.set(this.today.toObject());
    this.buildCalendar();
  }

  goToPreviousMonth() {
    this.dateMarker.set(this.activeMonth.toObject()).subtract(1, 'months');
    this.buildCalendar();
  }

  goToNextMonth() {
    this.dateMarker.set(this.activeMonth.toObject()).add(1, 'months');
    this.buildCalendar();
  }

  @HostListener('document:click', ['$event']) onClick(event) {
    this.closeAllPopups();
  }

  openPopup(clickEvent: any, popupToOpen?: any, getPositions?: any) {
    this.popupAnchor.disableScroll();

    this.renderer.setStyle(popupToOpen, 'display', 'flex');

    this.ngZone.runOutsideAngular(() => {
      this.popupAnchor.appendPopup(popupToOpen);

      const contentRect = this.popupAnchor.getBoundingClientRect();
      const maxX = contentRect.width;
      const maxY = contentRect.height;
      const popupHeight = popupToOpen.clientHeight;
      const popupWidth = popupToOpen.clientWidth;

      const positions = getPositions(clickEvent.target.getBoundingClientRect(), contentRect);
      let top = positions.top;
      let left = positions.left;
      const padding = 5;

      // Make sure popup doesn't get cut off
      if (left + popupWidth + padding > maxX) {
        left = maxX - popupWidth - 10;
      }

      if (top + popupHeight + padding > maxY) {
        top = maxY - popupHeight - 10;
      }

      this.renderer.setStyle(popupToOpen, 'top', `${top}px`);
      this.renderer.setStyle(popupToOpen, 'left', `${left}px`);
    });
  }

  openEventListPopup(clickEvent: any, day: any) {
    clickEvent.stopPropagation();

    this.eventListPopupOpen = true;
    this.closeEventPopup();

    this.displayedDay = day;


    setTimeout(() => {
      const eventPopupEl = this.eventListPopup.nativeElement;
      const getPositions = (eventRect, contentRect) => {
        return {
          top: eventRect.top + eventRect.height - (eventRect.width / 2) - (eventPopupEl.clientHeight / 2) - contentRect.top,
          left: eventRect.left + (eventRect.width / 2) - (eventPopupEl.clientWidth / 2) - contentRect.left
        };
      }

      this.openPopup(clickEvent, eventPopupEl, getPositions);
    });
  }

  openEventPopup(clickEvent: any, day: any, calendarEvent: any, fromList?: boolean) {
    clickEvent.stopPropagation();
    this.eventPopupOpen = true;
    if (!fromList) {
      this.closeEventListPopup();
    }

    this.displayedEvent = calendarEvent;

    setTimeout(() => {
      const eventPopupEl = this.eventPopup.nativeElement;
      const getPositions = (eventRect, contentRect) => {
        const top = eventRect.top + (eventRect.height / 2) - (eventPopupEl.clientHeight / 2) - contentRect.top;
        let left = eventRect.left + eventRect.width - contentRect.left;

        // If event is after Wednesday, show popup on left side of event
        if (day.dayOfWeek.value > 3) {
          left = eventRect.left - eventPopupEl.clientWidth - contentRect.left;
        }

        return {
          top,
          left
        };
      }

      this.openPopup(clickEvent, eventPopupEl, getPositions);
    });
  }

  closeAllPopups() {
    this.closeEventPopup();
    this.closeEventListPopup();
  }

  closeEventPopup(event?) {
    if (event) {
      event.stopPropagation();
    }

    if (this.eventPopupOpen) {
      this.eventPopupOpen = false;
      this.displayedEvent = null;

      this.setScroll();
    }
  }

  closeEventListPopup() {
    if (this.eventListPopupOpen) {
      this.eventListPopupOpen = false;
      this.displayedDay = null;

      this.setScroll();
    }
  }

  setScroll() {
    if (!this.eventPopupOpen && !this.eventListPopupOpen) {
      this.popupAnchor.enableScroll();
    }
  }

  toggleSyncModal() {
    this.showSyncModal = !this.showSyncModal;

    if (!this.showSyncModal) {
      this.syncStatuses = {};
    }
  }

  syncCalendar(client: string) {
    this.syncStatuses[client] = { type: 'loading', message: 'Syncing...' };
    this.apiService.syncCalendar(client).subscribe(() => {
      this.syncStatuses[client] = { type: 'success', message: 'Calendar synced!' };
    }, (err) => {
      if (err.status === 412) {
        // Redirect the user to authenticate first
        this.openAuthWindow();
        this.syncStatuses[client] = { type: 'loading', message: 'Authenticating...' };
      } else {
        this.syncStatuses[client] = { type: 'fail', message: 'Failed to sync calendar. Please try again.' };
      }
    });
  }

  openAuthWindow() {
    this.apiService.oAuthGoogle().subscribe((response) => {
      // Open authentication url in a new window
      let authWindow = window.open(response.url, 'googleAuthWindow', this.newWindowSettings);
      if (!authWindow) {
        // Popups may be blocked, alert the user
        this.store.dispatch(Actions.App.showInfo(`Your browser might have prevented our authentication window from openning.
          Please check your browser settings or try again.`));
      }
    });
  }

  private messageSuccessListener = (event: MessageEvent<any>) => {
    // TODO: Keep track of the client we're syncing somehow
    const client = 'google';
    if (event.data === 'oauth_success') {
      // Retry calendar sync
      this.syncCalendar(client);
    } else if (event.data === 'oauth_failed') {
      this.syncStatuses[client] = { type: 'fail', message: 'Failed to sync calendar. Please try again.' };
    }
  }

  downloadIcs() {
    this.syncStatuses['ics'] = { type: 'loading', message: 'Generating File...' };
    this.apiService.getCalendarICS().subscribe((file) => {
      const blob = new Blob([file], { type: 'text/calendar' });
      const downloadUrl = URL.createObjectURL(blob);
      const link = this.document.createElement('a');
      link.href = downloadUrl;
      link.download = 'nova_calendar.ics';
      link.click();
      this.syncStatuses['ics'] = { type: 'success', message: 'File downloaded!' };

    }, (err) => {
      console.log(err)
      this.syncStatuses['ics'] = { type: 'fail', message: 'Failed to download calendar file.' };
    });
  }

  ngOnDestroy() {
    this.closeAllPopups();

    // TODO: Improve popupAnchor to remove popups on route changes?
    this.eventPopup?.nativeElement.remove();
    this.eventListPopup?.nativeElement.remove();

    window.removeEventListener('message', this.messageSuccessListener);

    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

export interface CalendarDay {
  timestamp: string;
  date: Date;
  isToday: boolean;
  dayOfWeek: Model.SelectOption;
  disabled: boolean;
  events: Array<Model.Event>;
  displayedEvents: Array<Model.Event>;
}
