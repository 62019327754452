// Barrel all within this directory
export * from './admin-lookup-tables.resolver';
export * from './auth.guard';
export * from './can-deactivate.guard';
export * from './fund-version.guard';
export * from './fund.guard';
export * from './grant.guard';
export * from './health-check.guard';
export * from './institution.guard';

// List available core guards & resolvers.
import { AdminLookupTablesResolver } from './admin-lookup-tables.resolver';
import { AuthGuard } from './auth.guard';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { FundVersionGuard } from './fund-version.guard';
import { FundGuard } from './fund.guard';
import { GrantGuard } from './grant.guard';
import { HealthCheckGuard } from './health-check.guard';
import { InstitutionGuard } from './institution.guard';

export const CoreGuards = [
  AdminLookupTablesResolver,
  AuthGuard,
  CanDeactivateGuard,
  FundVersionGuard,
  FundGuard,
  GrantGuard,
  HealthCheckGuard,
  InstitutionGuard
];
