<div class="columns columns--2">
  <form *ngIf="form" [formGroup]="form" autocomplete="off" [ngClass]="{'first-touch': firstTouch}">
    <h4 *ngIf="!isPreview">Place {{addressIndex}} (optional)</h4>

    <div>
      <input-field *ngFor="let field of formFields;" [labelHidden]="false" [canEdit]="canEdit"
        [labelText]="field.fieldName" class="margin-bottom-sm">
          <input inputRef id="{{field.controlName}}_input_{{addressEA.id}}" formControlName="{{field.controlName}}"
            type="text" (change)="persistValue(field.controlName)" [required]="isTouched && !!field.validators" [attr.aria-required]="isTouched && !!field.validators"/>
      </input-field>
    </div>

    <div class="card-footer card-footer--right">
      <button *ngIf="canEdit && canDelete" id="delete_address_button_{{addressIndex}}" type="button" title="Delete Address"
        class="action-button action-button--secondary action-button--small icon--left"
        (click)="toggleDeleteAddress()">
        <icon iconId="delete"></icon>
        Delete Address
      </button>
    </div>
  </form>
</div>
<hr *ngIf="canEdit">

<alert *ngIf="showDeleteAddressAlert"
  button-action="Delete"
  button-dismiss="Cancel"
  level="warning"
  (action)="deleteAddress(addressEA)"
  (dismiss)="toggleDeleteAddress()">
  <p>
    Are you sure you want to delete <strong>Place {{addressIndex}}</strong>
  </p>
</alert>
