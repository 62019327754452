import {AfterViewInit, Component, ContentChildren, forwardRef, OnDestroy, OnInit, QueryList} from '@angular/core';
import { NavGroupComponent } from '../nav-group/nav-group.component';
import { NavItemComponent } from '../nav-item/nav-item.component';
import {TreeNodeDirective} from '@app-generic/directives/tree-node/tree-node.directive';
import {NavTreeEvent, NavTreeService} from './nav-tree.service';
import {filter, takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
  selector: 'po-nav-tree',
  templateUrl: './nav-tree.component.html',
  providers:[ NavTreeService ]
})
export class NavTreeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ContentChildren(forwardRef(() => NavGroupComponent), {descendants: true}) navGroups: QueryList<NavGroupComponent>;
  @ContentChildren(NavItemComponent, {descendants: true}) navItems: QueryList<NavItemComponent>;
  @ContentChildren(forwardRef(() => TreeNodeDirective), {descendants: true}) treeNodes: QueryList<TreeNodeDirective>;
  destroy$: Subject<boolean> = new Subject();
  isFirstLoad = true;
  currentIndex = 0;

  constructor(private navTreeService: NavTreeService, private router: Router) { }

  ngOnInit() {
    this.navTreeService.click$.pipe(
      filter(event => !!event)
    ).subscribe(event => {
      this.handleClick(event);
    });

    this.navTreeService.keyUp$.pipe(
      filter(event => !!event)
    ).subscribe(event => {
      this.handleKeyUp(event);
    });

    this.navTreeService.keypress$.pipe(
      filter(event => {
        if (!!event) {
          const wordRegexp = /([a-zA-Z]+)/g;
          const wordMatches = event.keyboardEvent.key.match(wordRegexp);
          return !!wordMatches && event.keyboardEvent.keyCode != 13;
        }
      })
    ).subscribe(event => {
      this.handleKeyPress(event);
    });
  }

  ngAfterViewInit() {
    this.initializeFocus();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      if (this.isFirstLoad) {
        this.initializeFocus();
        this.isFirstLoad = false;
      }
    });
  }

  private initializeFocus() {
    this.clearAllFocus();
    const activeNode = this.treeNodes
      .find(node => node.component.isActive);
    if (activeNode) {
      activeNode.component.setFocus();
    }
  }

  private handleKeyUp(event: NavTreeEvent) {
    const component = event.component;

    enum keys {
      Enter = 13,
      SpaceBar = 32,
      ArrowLeft = 37,
      ArrowUp = 38,
      ArrowRight = 39,
      ArrowDown = 40,
    }

    const item = this.treeNodes.find((node, index) => {
      this.currentIndex = index;
      return node.component.id === component.id
    });

    if (!item) {
      return;
    }

    switch (event.keyboardEvent.keyCode) {
      case keys.Enter:
        if (component instanceof NavGroupComponent) {
          component.toggleOpen();
        }
        break;
      case keys.SpaceBar:
        if (component instanceof NavGroupComponent) {
          component.toggleOpen();
        }
        break;
      case keys.ArrowLeft:
        if (component instanceof NavGroupComponent) {
          if (component.isOpen) {
            component.toggleOpen();
          } else {
            this.findPreviousGroup(component);
          }
        }
        if (component instanceof NavItemComponent) {
          this.findPreviousGroup(component);
        }
        break;
      case keys.ArrowUp:
        this.findPreviousItem();
        break;
      case keys.ArrowRight:
        if (component instanceof NavGroupComponent) {
          if (!component.isOpen) {
            component.toggleOpen();
          } else {
            this.findNextItem();
          }
        }
        break;
      case keys.ArrowDown:
        this.findNextItem();
        break;
      default:
        break;
    }
  }

  private handleKeyPress(event: NavTreeEvent) {
    const key = event.keyboardEvent.key.toLowerCase();
    const nextIndex = this.currentIndex + 1;

    if (nextIndex <= this.treeNodes.length) {

      const nextNode = this.treeNodes
        .filter((node, index) => index > this.currentIndex)
        .find(node => {
          const isVisible = node.component.isVisible;
          const startsWithKey = node.component.itemText.toLowerCase().startsWith(key);
          return isVisible && startsWithKey;
        });

      if (nextNode) {
        this.clearAllFocus();
        nextNode.component.setFocus();
      }
    }
  }

  private handleClick(event: NavTreeEvent) {
    this.clearAllFocus();
    if (event.component instanceof NavGroupComponent) {
      event.component.toggleOpen();
    }
    event.component.setFocus();
  }

  private findPreviousGroup(component) {
    const previousIndex = this.currentIndex - 1;
    if (previousIndex >= 0) {

      const prevGroups = this.treeNodes
        .filter((node, index) => index < this.currentIndex && node.component instanceof NavGroupComponent);

      const parentGroup = prevGroups.find(group => {
        const foundGroup = group.component.groupChildren.some(childGroup => childGroup.id === component.id);
        const foundItem = group.component.navItemChildren.some(childGroup => childGroup.id === component.id);
        return foundGroup || foundItem;
      });

      if (parentGroup) {
        this.clearAllFocus();
        parentGroup.component.setFocus();
      }
    }
  }

  private findPreviousItem() {
    const previousIndex = this.currentIndex - 1;
    if (previousIndex >= 0) {
      this.clearAllFocus();

      const prevNodes = this.treeNodes
        .filter((node, index) => index < this.currentIndex);

      const nextVisibleNode = _.findLast(prevNodes, node => node.component.isVisible);

      if (nextVisibleNode) {
        nextVisibleNode.component.setFocus();
      }
    }
  }

  private findNextItem() {
    const nextIndex = this.currentIndex + 1;

    if (nextIndex <= this.treeNodes.length) {
      this.clearAllFocus();

      const nextVisibleNode = this.treeNodes
        .filter((node, index) => index > this.currentIndex)
        .find(node => node.component.isVisible);

      if (nextVisibleNode) {
        nextVisibleNode.component.setFocus();
      }
    }
  }

  private clearAllFocus() {
    this.treeNodes.forEach(node => {
      node.component.clearFocus();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
