import { createDomain } from '@app-ngrx-domains';

/**
 * set up allocation ngrx domain
 */
import './allocations.state';
import './allocations.action';
import './allocations.query';
import { AllocationsEffects } from './allocations.effect';
import { AllocationsReducer } from './allocations.reducer';
createDomain('Allocations', AllocationsReducer);

import './program-allocations.state';
import './program-allocations.action';
import './program-allocations.query';
import { ProgramAllocationsEffects } from './program-allocations.effect';
import { ProgramAllocationsReducer } from './program-allocations.reducer';
createDomain('ProgramAllocations', ProgramAllocationsReducer);

/**
 * set up annual plan ngrx domain
 */
import './annual-plans.state';
import './annual-plans.action';
import './annual-plans.query';
import { AnnualPlansEffects } from './annual-plans.effect';
import { AnnualPlansReducer } from './annual-plans.reducer';
createDomain('AnnualPlans', AnnualPlansReducer);

/**
 * set up budget ngrx domain
 */
import './budget.state';
import './budget.action';
import './budget.query';
import { BudgetReducer } from './budget.reducer';
import { BudgetEffects } from './budget.effect';
createDomain('Budget', BudgetReducer);

/**
 * set up cfad ngrx domain
 */
import './cfad.state';
import './cfad.action';
import './cfad.query';
import { CfadEffects } from './cfad.effect';
import { CfadReducer } from './cfad.reducer';
createDomain('Cfad', CfadReducer);

/**
 * set up cfad amendment ngrx domain
 */
import './cfad-amendment.state';
import './cfad-amendment.action';
import './cfad-amendment.query';
import { CfadAmendmentEffects } from './cfad-amendment.effect';
import { CfadAmendmentReducer } from './cfad-amendment.reducer';
createDomain('CfadAmendment', CfadAmendmentReducer);

/**
 * set up CONTACTS ngrx domain
 */
import './contacts.state';
import './contacts.action';
import './contacts.query';
import { ContactsReducer } from './contacts.reducer';
import { ContactsEffects } from './contacts.effect';
createDomain('Contacts', ContactsReducer);

/**
 * set up current proposal ngrx domain
 */
import './current-proposal.state';
import './current-proposal.action';
import './current-proposal.query';
import { CurrentProposalReducer } from './current-proposal.reducer';
import { CurrentProposalEffects } from './current-proposal.effect';
import { CurrentProposalAttributesEffects } from './current-proposal-attributes.effect';
createDomain('CurrentProposal', CurrentProposalReducer);

/**
 * set up current workflow ngrx domain
 */
import './current-workflow.action';
import { CurrentWorkflowEffects } from './current-workflow.effect';

/**
 * set up entities ngrx domain
 */
import './entities.state';
import './entities.action';
import './entities.query';
import { EntitiesEffects } from './entities.effect';
import { EntitiesReducer } from './entities.reducer';
createDomain('Entities', EntitiesReducer);

/**
 * set up files ngrx domain
 */
import './files.state';
import './files.action';
import './files.query';
import { FilesEffects } from './files.effect';
import { FilesReducer } from './files.reducer';
createDomain('Files', FilesReducer);

/**
 * set up fiscalReports ngrx domain
 */
import './fiscal-reports.state';
import './fiscal-reports.action';
import './fiscal-reports.query';
import { FiscalReportsReducer } from './fiscal-reports.reducer';
import { FiscalReportsEffects } from './fiscal-reports.effect';
createDomain('FiscalReports', FiscalReportsReducer);

/**
 * set up Invoices ngrx domain
 */
 import './invoices.state';
 import './invoices.action';
 import './invoices.query';
 import { InvoicesReducer } from './invoices.reducer';
 import { InvoicesEffects } from './invoices.effect';
 createDomain('Invoices', InvoicesReducer);

// set up Launchboard Metrics ngrx domain
import './metrics.state';
import './metrics.action';
import './metrics.query';
import { MetricsReducer } from './metrics.reducer';
import { MetricsEffects } from './metrics.effect';
createDomain('Metrics', MetricsReducer);

// set up Metrics Ex ngrx domain
import './metrics-ex.state';
import './metrics-ex.action';
import './metrics-ex.query';
import { MetricsExReducer } from './metrics-ex.reducer';
import { MetricsExEffects } from './metrics-ex.effect';
createDomain('MetricsEx', MetricsExReducer);

/**
 * set up proposalReports ngrx domain
 */
import './proposal-reports.state';
import './proposal-reports.action';
import './proposal-reports.query';
import { ProposalReportsReducer } from './proposal-reports.reducer';
import { ProposalReportsEffects } from './proposal-reports.effect';
createDomain('ProposalReports', ProposalReportsReducer);

// set up ProposalReview ngrx domain
import './reviews.state';
import './reviews.action';
import './reviews.query';
import { ProposalReviewsReducer } from './reviews.reducer';
import { ProposalReviewsEffects } from './reviews.effect';
createDomain('ProposalReviews', ProposalReviewsReducer);

/**
 * set up proposals ngrx domain
 */
import './proposals.state';
import './proposals.action';
import './proposals.query';
import { ProposalsEffects } from './proposals.effect';
import { ProposalsReducer } from './proposals.reducer';
createDomain('Proposals', ProposalsReducer);

// set up Strategies ngrx domain
import './strategies.state';
import './strategies.action';
import './strategies.query';
import { StrategiesReducer } from './strategies.reducer';
import { StrategiesEffects } from './strategies.effect';
createDomain('Strategies', StrategiesReducer);

/**
 * set up workplan ngrx domain
 */
import './workplan.state';
import './workplan.action';
import './workplan.query';
import { WorkplanReducer } from './workplan.reducer';
import { WorkplanEffects } from './workplan.effect';
createDomain('Workplan', WorkplanReducer);

/*
 * Exports module level effects.
 */
export const ProjectEffects = [
  AllocationsEffects,
  AnnualPlansEffects,
  BudgetEffects,
  CfadEffects,
  CfadAmendmentEffects,
  ContactsEffects,
  CurrentProposalEffects,
  CurrentProposalAttributesEffects,
  CurrentWorkflowEffects,
  EntitiesEffects,
  FilesEffects,
  FiscalReportsEffects,
  InvoicesEffects,
  MetricsEffects,
  MetricsExEffects,
  ProgramAllocationsEffects,
  ProposalReportsEffects,
  ProposalReviewsEffects,
  ProposalsEffects,
  StrategiesEffects,
  WorkplanEffects,
];
