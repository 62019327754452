import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { EnumErrorTypes, Resource } from '@app-models';

/**
 * Proposal Reviews Action Types
 */
const ACTION_PREFIX = 'PROPOSAL_REVIEWS_';
export const PROPOSAL_REVIEWS_ACTION_TYPES = {
  GET: `${ACTION_PREFIX}GET`,
  GET_SUCCESS: `${ACTION_PREFIX}GET_SUCCESS`,
  GET_FAIL: `${ACTION_PREFIX}GET_FAIL`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  CREATE_TEMP: `${ACTION_PREFIX}CREATE_TEMP`,
  UPSERT: `${ACTION_PREFIX}UPSERT`,
  UPSERT_SUCCESS: `${ACTION_PREFIX}UPSERT_SUCCESS`,
  COMPLETE_TASK: `${ACTION_PREFIX}COMPLETE_TASK`,
  COMPLETE_TASK_SUCCESS: `${ACTION_PREFIX}COMPLETE_TASK_SUCCESS`,
};

/**
 * Proposal Reviews Action Class
 */
export class ProposalReviewsActions {

  get(filter: any): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REVIEWS_ACTION_TYPES.GET,
      payload: filter
    };
  }

  /**
   * Fetched data - send it along to reducer
   * @param notes
   */
  getSuccess(req: any, data: { task: Model.Task, comments: Model.Resource[] }): ActionWithPayload<any> {
    const state = {
      items: [],
      current_reviewer_id: req.user_id ? req.user_id : null,
      task: data.task,
      is_loading: false,
      error: null,
    };

    data.comments.forEach((s: Model.Resource) => {
      // just pick up review notes.
      if (s.note && s.note.type === 'review') {
        state.items.push(new Resource(s).iObject<Model.Resource>());
      }
    });

    return {
      type: PROPOSAL_REVIEWS_ACTION_TYPES.GET_SUCCESS,
      payload: state
    };
  }

  /**
   * Dispatches get failed action.
   */
  getFail(error: any): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REVIEWS_ACTION_TYPES.GET_FAIL,
      payload: error
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: PROPOSAL_REVIEWS_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  createTempResourceNotes(items: Array<any>): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REVIEWS_ACTION_TYPES.CREATE_TEMP,
      payload: items
    };
  }

  upsert(resource: Model.Resource, review: { score?: number, comment?: string }): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REVIEWS_ACTION_TYPES.UPSERT,
      payload: { resource, review }
    };
  }

  upsertSuccess(item: any): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REVIEWS_ACTION_TYPES.UPSERT_SUCCESS,
      payload: item
    };
  }

  completeTask(url: string): ActionWithPayload<string> {
    return {
      type: PROPOSAL_REVIEWS_ACTION_TYPES.COMPLETE_TASK,
      payload: url
    };
  }

  completeTaskSuccess(payload: Model.Task): ActionWithPayload<Model.Task> {
    return {
      type: PROPOSAL_REVIEWS_ACTION_TYPES.COMPLETE_TASK_SUCCESS,
      payload
    };
  }

}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.ProposalReviews = new ProposalReviewsActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    ProposalReviews: ProposalReviewsActions;
  }
}
