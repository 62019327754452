import { PROGRAM_KEYS } from './core.const';
import { AREAS } from './permissions.const';
import { WORKFLOW_STEPS } from './workflows-config.const';

export const AREA_NAMES = {
  [WORKFLOW_STEPS.ACTIVITIES]: WORKFLOW_STEPS.ACTIVITIES,
  [WORKFLOW_STEPS.ALIGNMENT]: WORKFLOW_STEPS.ALIGNMENT,
  [WORKFLOW_STEPS.AGENCIES]: WORKFLOW_STEPS.AGENCIES,
  [WORKFLOW_STEPS.BUDGET]: WORKFLOW_STEPS.BUDGET,
  [WORKFLOW_STEPS.CONTACTS]: WORKFLOW_STEPS.CONTACTS,
  [WORKFLOW_STEPS.CAPABILITY]: WORKFLOW_STEPS.CAPABILITY,
  [WORKFLOW_STEPS.DETAILS]: WORKFLOW_STEPS.DETAILS,
  [WORKFLOW_STEPS.DESCRIPTION]: WORKFLOW_STEPS.DESCRIPTION,
  [WORKFLOW_STEPS.DISSEMINATION]: WORKFLOW_STEPS.DISSEMINATION,
  [WORKFLOW_STEPS.DOCUMENTS]: WORKFLOW_STEPS.DOCUMENTS,
  [WORKFLOW_STEPS.FEASIBILITY]: WORKFLOW_STEPS.FEASIBILITY,
  [WORKFLOW_STEPS.MANAGEMENT]: WORKFLOW_STEPS.MANAGEMENT,
  [WORKFLOW_STEPS.METRICS]: WORKFLOW_STEPS.METRICS,
  [WORKFLOW_STEPS.NEEDS]: WORKFLOW_STEPS.NEEDS,
  [WORKFLOW_STEPS.OBJECTIVES]: WORKFLOW_STEPS.OBJECTIVES,
  [WORKFLOW_STEPS.OUTCOMES]: WORKFLOW_STEPS.OUTCOMES,
  [WORKFLOW_STEPS.PARTNERS]: WORKFLOW_STEPS.PARTNERS,
  [WORKFLOW_STEPS.PATHWAY]: WORKFLOW_STEPS.PATHWAY,
  [WORKFLOW_STEPS.PROBLEM_STATEMENT]: WORKFLOW_STEPS.PROBLEM_STATEMENT,
  [WORKFLOW_STEPS.QUALIFICATIONS]: WORKFLOW_STEPS.QUALIFICATIONS,
  [WORKFLOW_STEPS.RESPONSE]: WORKFLOW_STEPS.RESPONSE,
  [WORKFLOW_STEPS.RESPONSE_TO_PROBLEM]: WORKFLOW_STEPS.RESPONSE_TO_PROBLEM,
  [WORKFLOW_STEPS.SECTORS]: WORKFLOW_STEPS.SECTORS,
  [WORKFLOW_STEPS.SOW]: WORKFLOW_STEPS.SOW,
  [WORKFLOW_STEPS.TARGET]: WORKFLOW_STEPS.TARGET,
  [WORKFLOW_STEPS.WORKPLAN]: WORKFLOW_STEPS.WORKPLAN,
  ALLOCATION_BUDGETED: 'allocation-budgeted',
  ANALYTICS: 'analytics',
  ASSESSMENT: 'assessment',
  BUDGET_FOLLOWUP: 'budget-followup',
  CONSORTIUM: 'consortium',
  FIFO: 'fifo',
  MEMBER: 'member',
  PROGRAM: 'program',
  ROLLUP: 'rollup',
  SUMMARY: 'summary',
  THREE_YEAR_PLAN: 'three-year-plan',
  WEP: 'written-expenditure-plan'
};

export const FUND_TITLES = {
  [PROGRAM_KEYS.SWP_K12]: {
      [AREAS.PROJECT]: 'Letter of Intent',
      [AREAS.LOI]: 'Letter of Intent',
  },
  [PROGRAM_KEYS.CAEP]: {
      [AREAS.ANNUAL_PLAN]: 'Annual Plan',
      [AREAS.BUDGET]: 'Member Budget & Workplan',
      [AREAS.CFAD]: 'Consortium Fiscal Administration Declaration',
      [AREAS.FISCAL_REPORT]: 'Consortium Fiscal Reporting',
      [AREAS.PROGRAM_AREA_REPORT]: 'Program Area Reporting',
      [AREA_NAMES.CONSORTIUM]: 'Institution Homepage',
      [AREA_NAMES.THREE_YEAR_PLAN]: 'Three-Year Plan',
      [AREA_NAMES.MEMBER]: 'Member Dashboard',
      [AREA_NAMES.ANALYTICS]: 'Monitor Reports',
      [AREA_NAMES.ROLLUP]: 'Member Budget & Workplan Rollup',
      [AREA_NAMES.FIFO]: 'FIFO Report Dashboard',
      [AREA_NAMES.ALLOCATION_BUDGETED]: 'Allocations vs Budgets Report',
      [AREA_NAMES.WEP]: 'Written Expenditure Plan'
  },
  [PROGRAM_KEYS.LVG]: {
      [AREAS.PROJECT]: 'Goals',
  },
  [PROGRAM_KEYS.SEP]: {
      [AREAS.PROJECT]: 'Plans',
      [AREAS.MID_ALLOCATION_REPORT]: 'Annual Report',
      [AREAS.ALLOCATION_END_REPORT]: 'Term-End Expenditure Report'
  },
  [PROGRAM_KEYS.NEP]: {
      [AREAS.PROJECT]: 'Plans',
      [AREAS.FISCAL_REPORT]: 'Fiscal Reporting'
  },
  [PROGRAM_KEYS.PERKINS]: {
      [AREAS.HEADCOUNT_CERTIFICATION]: 'Headcount Certification'
  }
};

export const SHARED_TITLES = {
  [AREA_NAMES.ANALYTICS]: 'Analytics',
  [AREAS.APPLICATION]: 'Applications',
  [AREAS.DELEGATE]: 'Review Details',
  [AREAS.FISCAL_REPORT]: 'Fiscal Reporting',
  [AREA_NAMES.FIFO]: 'FIFO Report Dashboard',
  [AREA_NAMES.ALLOCATION_BUDGETED]: 'Allocations vs Budgets Report',
  [AREAS.FUND_SETTINGS]: 'Fund Settings',
  [AREAS.PROJECT]: 'Project',
  [AREAS.PLAN]: 'Plan',
  [AREAS.REVIEW]: 'Review',
}