<div class="flex flex--align-items-center">
  <div class="dashboard-config-container" [formGroup]="dashboardForm">
    <po-select id="qa_dashboard_select"
      class="margin-bottom-none margin-left-sm"
      labelText="View Dashboard"
      placeholderText="Custom Dashboard"
      [labelHidden]="true"
      [options]="dashboardOptions"
      (optionSelected)="selectDashboard()"
      [selectOnce]="true"
      [hideEmptyOptionsText]="true">
      <select inputRef class="po-select__select" formControlName="dashboard_id"></select>
    </po-select>
    <options-menu *ngIf="dashboardList.length">
      <options-menu-item *ngIf="dashboardForm.get('dashboard_id').value" optionText="Rename Dashboard" iconId="edit" (doEmit)="openSaveDashboardModal(dashboardForm)"></options-menu-item>
      <options-menu-item optionText="Manage Dashboards" iconId="gear" (doEmit)="toggleManageDashboardsModal()"></options-menu-item>
      <ng-container *ngIf="dashboardForm.get('dashboard_id').value">
        <hr class="no-margin">
        <options-menu-item class="delete-dashboard" optionText="Delete Dashboard" iconId="delete" (doEmit)="openDeleteDashboardAlert()"></options-menu-item>
      </ng-container>
    </options-menu>
    <button *ngIf="!dashboardForm.get('dashboard_id').value && filterList.length"
      class="action-button action-button--primary action-button--small margin-left-sm"
      (click)="openSaveDashboardModal(dashboardForm)"
      type="button"
      aria-label="Save Dashboard">
      Save Dashboard
    </button>
  </div>
  <div [formGroup]="filterForm">
    <button #filterToggle (click)="toggleFilterDropdown($event)"
      class="filter-toggle-button action-button action-button--tertiary action-button--small margin-left-sm icon--left">
      <icon iconId="filter"></icon>
      Filters
    </button>
    <div #dashboardFilterPopup *ngIf="filterOpen" class="dashboard-filter__popup-container" (click)="$event.stopPropagation()">
      <div class="dashboard-filter__popup v2-styles">
        <div class="flex flex--justify-content-between margin-bottom">
          <h4>Filter</h4>
          <div>
            <button
              id="qa_filter_apply_button"
              type="submit"
              class="action-button action-button--primary action-button--small"
              (click)="setFilter()">
              Apply
              <div class="loader-spinner-inline"></div>
            </button>
            <button
              id="qa_filter_clear_all_button"
              type="button"
              class="action-button action-button--tertiary action-button--small margin-left-sm"
              (click)="resetFilterDropdown()"
              [disabled]="filterOpen && !hasFilters">
              Clear All
            </button>
          </div>
        </div>

        <div class="dashboard-filter__popup-scroll">
          <collapsible-card sectionName="Program" [isNested]="true">
            <div class="card-title label-text">Program</div>
            <po-select id="qa_dashboard_filter_program"
              labelText="Program"
              [labelHidden]="true"
              [options]="programOptions"
              [selectMultiple]="true">
              <select inputRef class="po-select__select" formControlName="program_ids"></select>
            </po-select>
          </collapsible-card>
          <collapsible-card sectionName="Institution" [isNested]="true">
            <div class="card-title label-text">Institution</div>
            <po-select id="qa_dashboard_filter_institution"
              labelText="Institution"
              [labelHidden]="true"
              [isSearch]="true"
              (search)="updateInstitutionOptions($event)"
              [options]="institutionOptions"
              [selectMultiple]="true">
              <select inputRef class="po-select__select" formControlName="institution_ids"></select>
            </po-select>
          </collapsible-card>
          <collapsible-card sectionName="Event Type" [isNested]="true">
            <div class="card-title label-text">Event Type</div>
            <po-select id="qa_dashboard_filter_event_type"
              labelText="Event Type"
              [labelHidden]="true"
              [options]="eventTypeOptions"
              [selectMultiple]="true">
              <select inputRef class="po-select__select" formControlName="event_types"></select>
            </po-select>
          </collapsible-card>
          <collapsible-card sectionName="System Event" [isNested]="true">
            <div class="card-title label-text">Event Name</div>
            <po-select id="qa_dashboard_filter_system_event"
              labelText="System Event"
              [labelHidden]="true"
              [options]="systemEventOptions"
              [selectMultiple]="true">
              <select inputRef class="po-select__select" formControlName="system_events"></select>
            </po-select>
          </collapsible-card>
          <collapsible-card sectionName="Custom Event (Calendar only)" [isNested]="true">
            <div class="card-title label-text">Custom Event</div>
            <po-select id="qa_dashboard_filter_system_event"
              labelText="Custom Event"
              [labelHidden]="true"
              [options]="customEventOptions"
              [selectMultiple]="true">
              <select inputRef class="po-select__select" formControlName="custom_events"></select>
            </po-select>
          </collapsible-card>
        </div>
      </div>
    </div>
  </div>
  <div class="filter-list v2-styles">
    <button
      *ngFor="let filter of filterList"
      class="filter-item action-button po-select__selection-list-item"
      (click)="filterRemoved(filter)"
      type="button"
      aria-label="Remove Filter">
      {{ filter.label }}
      <icon iconId="close" class="icon-host" data-qa="remove-selected-item"></icon>
    </button>
  </div>
  <div class="flex flex--align-items-center">
    <a *ngIf="hiddenFilterCount" (click)="toggleMoreFilters($event)" class="more-filters-button">+{{ hiddenFilterCount }} more</a>
    <div class="more-filters-button__popup-container">
      <div #moreFilterPopup *ngIf="moreFiltersOpen" class="more-filters-button__popup" (click)="$event.stopPropagation()">
        <button
          *ngFor="let filter of hiddenFilterList"
          class="filter-item action-button po-select__selection-list-item"
          (click)="filterRemoved(filter)"
          type="button"
          aria-label="Remove Filter">
          {{ filter.label }}
          <icon iconId="close" class="icon-host" data-qa="remove-selected-item"></icon>
        </button>
      </div>
    </div>
    <button *ngIf="filterList.length" class="clear-filter-button action-button action-button--tertiary action-button--small"
      (click)="clearFilter()"
      type="button"
      arial-label="Clear Filters">
      Clear filters
    </button>
  </div>
</div>

<full-page-modal *ngIf="showManageDashboardsModal" heading="Manage Dashboards" class="dashboard-manager"
  [small]="true"
  button-action="Done"
  (action)="toggleManageDashboardsModal()"
  (cancel)="toggleManageDashboardsModal()"
  (dismiss)="toggleManageDashboardsModal()">

  <collapsible-card *ngFor="let dashboard of dashboardList" [sectionName]="dashboard.name" [isNested]="true" [startCollapsed]="dashboard.id !== dashboardForm.get('dashboard_id')?.value">
    <div class="card-title dashboard-name">{{ dashboard.name }}</div>
    <div class="card-title-right flex flex--align-items-center">
      <div class="dashboard-date">Created {{ dashboard.created_at | date: 'MM/dd/YYYY' }}</div>
      <options-menu>
        <options-menu-item optionText="Rename Dashboard" iconId="edit" (doEmit)="openSaveDashboardModal(modalDashboardForm, dashboard.id)"></options-menu-item>
        <options-menu-item optionText="View Dashboard" iconId="view" (doEmit)="viewDashboard(dashboard.id)"></options-menu-item>
        <hr class="no-margin">
        <options-menu-item class="delete-dashboard" optionText="Delete Dashboard" iconId="delete" (doEmit)="openDeleteDashboardAlert(dashboard)"></options-menu-item>
      </options-menu>
    </div>
    <button
      *ngFor="let filter of dashboards[dashboard.id].filterList"
      class="filter-item action-button po-select__selection-list-item"
      [disabled]="true"
      type="button"
      [attr.aria-label]="filter.label">
      {{ filter.label }}
    </button>
  </collapsible-card>
</full-page-modal>

<full-page-modal *ngIf="showSaveDashboardModal" heading="{{ currentDashboardForm?.get('dashboard_id').value ? 'Rename' : 'Save' }} Dashboard" [formGroup]="currentDashboardForm"
  [small]="true"
  button-cancel="Cancel"
  button-action="Save Dashboard"
  [button-action-disabled]="!currentDashboardForm?.valid"
  (action)="closeSaveDashboardModal(true)"
  (cancel)="closeSaveDashboardModal()"
  (dismiss)="closeSaveDashboardModal()">

  <input-field labelText="Dashboard name" [canEdit]="true">
    <input inputRef id="save_dashboard_name"
      formControlName="name" [maxlength]="nameLength"
      type="text" aria-required="true" required/>
  </input-field>
</full-page-modal>

<alert *ngIf="showDeleteDashboardAlert"
  button-action="Delete"
  button-dismiss="Cancel"
  level="warning"
  (action)="closeDeleteDashboardAlert(true)"
  (dismiss)="closeDeleteDashboardAlert()">
    <p>
      Are you sure you want to delete the dashboard <strong>{{dashboardToDelete.name}}</strong>?
    </p>
</alert>