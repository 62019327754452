import { State } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.Invoices = {
  items: [],
  invoice: undefined,
  isLoaded: false,
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IInvoicesState {
    items: Array<Model.EAInvoice>;
    invoice: Model.EAInvoice;
    isLoaded: boolean;
    // isLoading: boolean;
    // error: any;
  }

  interface State {
    Invoices: IInvoicesState;
  }
}