import { of, concat } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { State, Queries, Model, Actions as DomainActions } from '@app-ngrx-domains';
import { find, cloneDeep } from 'lodash';
import { toPayload, NewTempId } from '@app-libs';
import { ApiService } from '@app-services';
import { ANNUAL_PLANS_ACTION_TYPES } from './annual-plans.action';
import { Task } from '@app-models';

/**
 * Container class for effects calls.
 */
@Injectable()
export class AnnualPlansEffects {

  /**
   * Creates an instance of effects class.
   * @param {Actions} actions$
   * @param {ApiService} service
   * @param {Store} store
   */
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>,
  ) {
  }

   refresh$ = createEffect(() => this.actions$.pipe(
    ofType(ANNUAL_PLANS_ACTION_TYPES.REFRESH),
    map(toPayload),
    mergeMap(req => {
      return this.apiService.getAnnualPlan(req.id).pipe(mergeMap(
        res => {
          // bundle actions.
          const actions: Action[] = [];
          // refresh entity.
          actions.push(DomainActions.AnnualPlans.refreshSuccess(res));
          actions.push(DomainActions.Layout.showBusySpinner(false));
          if (req.gotoUrl) {
            // goto requested route.
            actions.push(DomainActions.App.go([req.gotoUrl]));
          }
          // Dispatch actions. These are executed in order.
          return concat([
            ...actions
          ]);
        }
      ))
    })
  ));

   updateState$ = createEffect(() => this.actions$.pipe(
    ofType(ANNUAL_PLANS_ACTION_TYPES.UPDATE_STATE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.AnnualPlans.get)),
    mergeMap(data => {
      const [__, annualPlans] = data;
      const ap = find(annualPlans, { id: __.id });
      const taskId = ap.client_state.task_id;
      const client_state = {
        ...ap.client_state,
        ...__.changes,
      }
      delete client_state.task_id;

      return this.apiService.updateTask(taskId, client_state).pipe(mergeMap(
        res => {
          return of(DomainActions.AnnualPlans.updateStateSuccess(__.id, res));
        }
      ));
    })
  ));

  /**
   * Updates annual plan.
   */
   update$ = createEffect(() => this.actions$.pipe(
    ofType(ANNUAL_PLANS_ACTION_TYPES.UPDATE),
    map(toPayload),
    mergeMap(ap => {
      // persist changes.
      return this.apiService.updateAnnualPlan(ap).pipe(
        map((response) => DomainActions.AnnualPlans.updateSuccess(ap)),
        catchError((error) => of(DomainActions.AnnualPlans.serviceFail(error)))
      );
    })
  ));

  /**
   * Updates annual plan strategy.
   */
   updateStrategy$ = createEffect(() => this.actions$.pipe(
    ofType(ANNUAL_PLANS_ACTION_TYPES.UPDATE_STRATEGY),
    map(toPayload),
    mergeMap(item => {
      if (NewTempId.isTemp(item.id)) {
        // create new strategy.
        const newItem = {
          ...item,
        }
        delete newItem.id; // remove temp id.
        return this.apiService.addAnnualPlanStrategy(newItem).pipe(
          map((response) => DomainActions.AnnualPlans.updateStrategySuccess(item, response)),
          catchError((error) => of(DomainActions.AnnualPlans.serviceFail(error)))
        );
      } else {
        // update existing strategy
        return this.apiService.updateAnnualPlanStrategy(item).pipe(
          map((response) => DomainActions.AnnualPlans.updateStrategySuccess(item, response)),
          catchError((error) => of(DomainActions.AnnualPlans.serviceFail(error)))
        );
      }
    })
  ));


  /**
   * Deletes annual plan strategy.
   */
   deleteStrategy$ = createEffect(() => this.actions$.pipe(
    ofType(ANNUAL_PLANS_ACTION_TYPES.DELETE_STRATEGY),
    map(toPayload),
    mergeMap(item => {
      // persist changes.
      return this.apiService.deleteAnnualPlanStrategy(item).pipe(
        map((response) => DomainActions.AnnualPlans.deleteStrategySuccess(item)),
        catchError((error) => of(DomainActions.AnnualPlans.serviceFail(error)))
      );
    })
  ));


   createAmendmentStrategy$ = createEffect(() => this.actions$.pipe(
    ofType(ANNUAL_PLANS_ACTION_TYPES.CREATE_AMENDMENT_STRATEGY),
    map(toPayload),
    mergeMap(payload => {
      const client_state = this.getClientState(payload.amendmentTask, payload.objective);
      const last_id = client_state['additions'][payload.objective].reduce((max_id, curr) => curr.id > max_id ? curr.id : max_id, 0);
      client_state['additions'][payload.objective].push({
        id: last_id + 1,
        description: ''
      });

      return this.apiService.updateTask(payload.amendmentTask.id, client_state).pipe(
        map((res) => DomainActions.AnnualPlans.updateAmendmentTaskSuccess(new Task(res))),
        catchError((error) => of(DomainActions.AnnualPlans.serviceFail(error)))
      );
    })
  ));


   updateAmendmentStrategy$ = createEffect(() => this.actions$.pipe(
    ofType(ANNUAL_PLANS_ACTION_TYPES.UPDATE_AMENDMENT_STRATEGY),
    map(toPayload),
    mergeMap(payload => {
      const client_state = this.getClientState(payload.amendmentTask, payload.objective);
      const modified_strategy = payload.strategy;
      client_state[payload.amendment_type][payload.objective] = client_state[payload.amendment_type][payload.objective].filter(strategy => {
        return strategy.id !== modified_strategy.id;
      });
      client_state[payload.amendment_type][payload.objective].push(modified_strategy);

      return this.apiService.updateTask(payload.amendmentTask.id, client_state).pipe(
        map((res) => DomainActions.AnnualPlans.updateAmendmentTaskSuccess(new Task(res))),
        catchError((error) => of(DomainActions.AnnualPlans.serviceFail(error)))
      );
    })
  ));


   deleteAmendmentStrategy$ = createEffect(() => this.actions$.pipe(
    ofType(ANNUAL_PLANS_ACTION_TYPES.DELETE_AMENDMENT_STRATEGY),
    map(toPayload),
    mergeMap(payload => {
      const client_state = this.getClientState(payload.amendmentTask, payload.objective);
      client_state[payload.amendment_type][payload.objective] = client_state[payload.amendment_type][payload.objective].filter(strategy => {
        return strategy.id !== payload.strategy_id;
      });

      return this.apiService.updateTask(payload.amendmentTask.id, client_state).pipe(
        map((res) => DomainActions.AnnualPlans.updateAmendmentTaskSuccess(new Task(res))),
        catchError((error) => of(DomainActions.AnnualPlans.serviceFail(error)))
      );
    })
  ));


   updateAmendmentValue$ = createEffect(() => this.actions$.pipe(
    ofType(ANNUAL_PLANS_ACTION_TYPES.UPDATE_AMENDMENT_VALUE),
    map(toPayload),
    mergeMap(payload => {
      const client_state = this.getClientState(payload.amendmentTask);
      client_state[payload.key] = payload.value;
      return this.apiService.updateTask(payload.amendmentTask.id, client_state).pipe(
        map((res) => DomainActions.AnnualPlans.updateAmendmentTaskSuccess(new Task(res))),
        catchError((error) => of(DomainActions.AnnualPlans.serviceFail(error)))
      );
    })
  ));


   revertAmendmentValue$ = createEffect(() => this.actions$.pipe(
    ofType(ANNUAL_PLANS_ACTION_TYPES.REVERT_AMENDMENT_VALUE),
    map(toPayload),
    mergeMap(payload => {
      const client_state = this.getClientState(payload.amendmentTask);
      delete client_state[payload.key];
      return this.apiService.updateTask(payload.amendmentTask.id, client_state).pipe(
        map((res) => DomainActions.AnnualPlans.updateAmendmentTaskSuccess(new Task(res))),
        catchError((error) => of(DomainActions.AnnualPlans.serviceFail(error)))
      );
    })
  ));

  private getClientState(amendmentTask: Model.Task, objective?: string) {
    const client_state = cloneDeep(amendmentTask.client_state || {});
    if (!client_state['additions']) {
      client_state['additions'] = {};
    }
    if (objective) {
      if (!client_state['additions'][objective]) {
        client_state['additions'][objective] = [];
      }
      if (!client_state['revisions']) {
        client_state['revisions'] = {};
      }
      if (!client_state['revisions'][objective]) {
        client_state['revisions'][objective] = [];
      }
    }
    return client_state;
  }
}
