import { forkJoin, of, concat, Observable, EMPTY } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { State, Actions as DomainActions, Queries, Model } from '@app-ngrx-domains';
import { NewTempId, toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { COMMENTS_ACTION_TYPES } from './comments.action';
import { UserRoleScope } from '@app-models';

/**
 * Injectable Comments effects class
 */
@Injectable()
export class CommentsEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {
  }

  getComments$ = createEffect(() => this.actions$.pipe(
    ofType(COMMENTS_ACTION_TYPES.SET_RESOURCE),
    map(toPayload),
    mergeMap(({ resource }) => {
      return this.apiService.getComments(resource).pipe(
        mergeMap(res => {
          const allEntries = [];
          res.forEach(r => {
            const resource = r;
            (r.threads || []).forEach(thread => {
              allEntries.push({ resource, thread });
            })
          });

          return of(DomainActions.Comments.loadComments(allEntries))
        }),
        catchError((error) => of(DomainActions.Comments.serviceFail(error)))
      )
    })
  ))

  createThread$ = createEffect(() => this.actions$.pipe(
    ofType(COMMENTS_ACTION_TYPES.CREATE_THREAD),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Comments.getParentResource)),
    mergeMap(([{ resource, thread, temp, callback }, parentResource]) => {
      const threadResource = { ...parentResource, ...resource };
      if (temp) {
        const tempId = NewTempId.id();
        thread.id = tempId;
        thread.temp_id = tempId;
        return of(DomainActions.Comments.createThreadSuccess(threadResource, thread));
      } else {
        const temp_id = thread.temp_id;
        delete thread.temp_id;
        return this.apiService.upsertComment({ resource: threadResource, comment: thread }).pipe(
          mergeMap(savedComment => {
            if (temp_id) {
              savedComment.temp_id = temp_id;
            }

            if (callback) {
              callback(savedComment.id);
            }

            return of(DomainActions.Comments.createThreadSuccess(threadResource, savedComment));
          }),
          catchError((error) => of(DomainActions.Comments.serviceFail(error)))
        );
      }
    })
  ))

  upsertComment$ = createEffect(() => this.actions$.pipe(
    ofType(COMMENTS_ACTION_TYPES.UPDATE_COMMENT),
    map(toPayload),
    mergeMap(({ threadId, resource, comment, callback }) => {
      const wasTemp = NewTempId.isTemp(comment.id);

      if (wasTemp) {
        delete comment.id;
      }

      const temp_id = comment.temp_id;
      delete comment.temp_id;

      const parent_temp_id = comment.parent_temp_id;
      delete comment.parent_temp_id;
      return this.apiService.upsertComment({ resource, comment }).pipe(
        mergeMap(res => {
          if (temp_id) {
            res.temp_id = temp_id;
          }
          if (parent_temp_id) {
            res.parent_temp_id = parent_temp_id;
          }

          if (callback) {
            callback();
          }

          if (wasTemp && !comment.parent_id) {
            // Remove the temporary thread & save the new one
            return of(DomainActions.Comments.createThreadSuccess(resource, res));
          } else {
            return of(DomainActions.Comments.updateCommentSuccess(threadId, resource, res));
          }
        }),
        catchError((error) => of(DomainActions.Comments.serviceFail(error)))
      )
    })
  ));

  resolveComment$ = createEffect(() => this.actions$.pipe(
    ofType(COMMENTS_ACTION_TYPES.RESOLVE_COMMENT),
    map(toPayload),
    mergeMap(({ threadId, resolved, resource, comment, callback }) => {
      return this.apiService.resolveComment(comment.id, resolved).pipe(
        mergeMap(updatedComment => {
          if (callback) {
            callback();
          }
          return of(DomainActions.Comments.updateCommentSuccess(threadId, resource, { ...updatedComment, temp_id: comment.temp_id }));
        }),
        catchError((error) => of(DomainActions.Comments.serviceFail(error)))
      )
    })
  ));

  deleteComment$ = createEffect(() => this.actions$.pipe(
    ofType(COMMENTS_ACTION_TYPES.DELETE_COMMENT),
    map(toPayload),
    mergeMap(({ threadId, resource, comment, callback }) => {
      const isTempComment = NewTempId.isTemp(comment.id);

      if (isTempComment) {
        return of(DomainActions.Comments.deleteCommentSuccess(threadId, resource, comment));
      } else {
        return this.apiService.deleteComment(comment.id).pipe(
          mergeMap(deletedComment => {
            if (callback) {
              callback();
            }
            return of(DomainActions.Comments.deleteCommentSuccess(threadId, resource, { ...deletedComment, temp_id: comment.temp_id }));
          }),
          catchError((error) => of(DomainActions.Comments.serviceFail(error)))
        )
      }
    })
  ));

}
