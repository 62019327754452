import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, withLatestFrom, finalize } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { State, Model, Actions as DomainActions, Queries } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService } from '../../core/services';
import { FISCAL_REPORTS_ACTION_TYPES } from './fiscal-reports.action';
import { FiscalReport, FiscalReportLine, Task, EnumErrorTypes, Profile } from '../../core/models';
import { HttpErrorResponse } from '@angular/common/http';
import { PROGRAM_KEYS, ROUTER_LINKS } from '@app-consts';
import { maxBy } from 'lodash';

/**
 * Injectable effects class
 */
@Injectable()
export class FiscalReportsEffects {

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private apiService: ApiService
  ) {
  }

   getReportingInfo = createEffect(() => this.actions$.pipe(
    ofType(FISCAL_REPORTS_ACTION_TYPES.GET_REPORTING_INFO),
    map(toPayload),
    mergeMap((payload: { proposalId: number, isRefresh: boolean }) => {
      return this.apiService.getFiscalReportsForProposal(payload.proposalId).pipe(
        map(reportInfo => DomainActions.FiscalReports.getReportingInfoSuccess(reportInfo)),
        catchError(error => of(DomainActions.FiscalReports.serviceFail(error)))
      )
    })
  ));

   getReportingInfoForInstitution = createEffect(() => this.actions$.pipe(
    ofType(FISCAL_REPORTS_ACTION_TYPES.GET_REPORTING_INFO_FOR_INSTITUTION),
    map(toPayload),
    mergeMap((payload: { institutionId: number, fundId: number, isRefresh: boolean }) => {
      return this.apiService.getFiscalReportsForInstitution(payload.institutionId, payload.fundId).pipe(
        switchMap(response => {
          const proposals = response.proposals;
          const tasks = response.tasks;
          const actions: Action[] = [];
          actions.push(DomainActions.FiscalReports.getReportingInfoSuccess(tasks));
          actions.push(DomainActions.Proposals.load(proposals));
          const files = proposals.flatMap(proposal => proposal.files);
          actions.push(DomainActions.Files.load({ files }));

          return concat([
            ...actions,
          ]);
        }),
        catchError(error => of(DomainActions.FiscalReports.serviceFail(error)))
      )
    })
  ));

   upsertFiscalReport = createEffect(() => this.actions$.pipe(
    ofType(FISCAL_REPORTS_ACTION_TYPES.UPSERT_FISCAL_REPORT),
    map(toPayload),
    mergeMap(({ fiscalReport, taskId }) => {
      const lines = fiscalReport.lines || [];
      return this.apiService.saveFiscalReport(fiscalReport.proposal_id, FiscalReport.iObject(fiscalReport), !!lines.length).pipe(
        map(response => DomainActions.FiscalReports.upsertFiscalReportSuccess(response, taskId)),
        catchError(error => of(DomainActions.FiscalReports.serviceFail(error)))
      );
    })
  ))

   notifyReportingTask = createEffect(() => this.actions$.pipe(
    ofType(FISCAL_REPORTS_ACTION_TYPES.NOTIFY_REPORTING_TASK),
    map(toPayload),
    mergeMap((task) => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.notifyTask(task).pipe(
        map((response) => DomainActions.FiscalReports.updateReportingTaskSuccess(response, task.id)),
        catchError(error => of (DomainActions.FiscalReports.serviceFail(error)))
      )
    })
  ))

   completeReportingTask = createEffect(() => this.actions$.pipe(
    ofType(FISCAL_REPORTS_ACTION_TYPES.COMPLETE_REPORTING_TASK),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Auth.getCurrentUserId)),
    mergeMap(([req, currentUser]) => {
      const task = req.task;
      const proposalId = task.proposal_id;
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.completeTask(task).pipe(
        map((response) => {
          if (currentUser !== response.performed_by_id) {
            this.store.dispatch(DomainActions.App.setError({
              type: EnumErrorTypes.user,
              location: 'fiscal-reports.effect',
              messageLevel: 'warning',
              show: true,
              message: `The action you are attempting was already performed by ${Profile.contactFullName(response.performed_by)}. Please double check your data, as your changes may have been overwritten.`
            }))
          }
          return (req.refresh)
            ? DomainActions.FiscalReports.getReportingInfo(proposalId, true)
            : DomainActions.FiscalReports.updateReportingTaskSuccess(response, task.id);
        }),
        catchError(error => {
          if (error.status === 409) {
            return of(DomainActions.App.setError({
              type: EnumErrorTypes.user,
              location: this.constructor.name,
              show: true,
              message: error.error ? error.error.message : undefined,
              raw: error
            }));
          }
          return of(DomainActions.FiscalReports.serviceFail(error));
        }),
        finalize(() => this.store.dispatch(DomainActions.Layout.showBusySpinner(false)))
      )
    })
  ))

   undoReportingTask = createEffect(() => this.actions$.pipe(
    ofType(FISCAL_REPORTS_ACTION_TYPES.UNDO_REPORTING_TASK),
    map(toPayload),
    mergeMap((task: Model.Task) => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      const payload = new Task(task);
      return this.apiService.undoTask(payload).pipe(
        map(response => DomainActions.FiscalReports.updateReportingTaskSuccess(response, task.id)),
        catchError(error => of(DomainActions.FiscalReports.serviceFail(error))),
        finalize(() => this.store.dispatch(DomainActions.Layout.showBusySpinner(false)))
      )
    })
  ))

   getProposalReport = createEffect(() => this.actions$.pipe(
    ofType(FISCAL_REPORTS_ACTION_TYPES.GET_PROPOSAL),
    map(toPayload),
    mergeMap(({ proposal_id, fund_key, multi, duration_id }) => {
      return this.apiService.getProposalFiscalReports(proposal_id).pipe(
        mergeMap((response: any) => {
          const baseObject = {
            proposal_id: proposal_id,
            institution_id: response.proposal_institutions[0].id,
            duration_id: duration_id || undefined
          };
          const reports = response.proposal_institutions[0].fiscal_reports.map(FiscalReport.iObjectP);

          if (reports.length) {
            let report;
            const actions = [];

            if (multi) {
              actions.push(DomainActions.FiscalReports.load(reports));
              report = duration_id
                ? reports.find(r => r.duration_id === duration_id)
                : maxBy(reports, 'duration_id');
            } else {
              report = reports[0]
            }

            actions.push(DomainActions.FiscalReports.getForProposalSuccess(report || FiscalReport.iObjectP(baseObject)));
            return concat([...actions]);
          } else {
            return of(DomainActions.FiscalReports.getForProposalSuccess(FiscalReport.iObjectP(baseObject)));
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status && error.status === 403) {
            const actions = [];
            actions.push(DomainActions.App.showAlert('Fiscal report cannot be edited at this time.'));

            switch (fund_key) {
              case PROGRAM_KEYS.SEP:
                actions.push(DomainActions.App.go([fund_key, 'reporting']));
                break;
              case PROGRAM_KEYS.CAEP:
              case PROGRAM_KEYS.IPLAN:
              case PROGRAM_KEYS.NEP:
              case PROGRAM_KEYS.SWP_L:
              case PROGRAM_KEYS.SWP_R:
                actions.push(DomainActions.App.go([fund_key, 'fiscal-reports']));
                break;
              case PROGRAM_KEYS.RSI:
                actions.push(DomainActions.App.go([fund_key, PROGRAM_KEYS.RSI]));
                break;
              case PROGRAM_KEYS.LVG:
              case PROGRAM_KEYS.SWP_K12:
              case PROGRAM_KEYS.GP:
                actions.push(DomainActions.App.go([fund_key]));
                break;
              default:
                actions.push(DomainActions.App.go([ROUTER_LINKS.HOME]));
                break;
            }

            return concat([...actions]);
          } else {
            return of(DomainActions.FiscalReports.serviceFail(error))
          }
        })
      );
    })
  ));

   saveProposalLine = createEffect(() => this.actions$.pipe(
    ofType(FISCAL_REPORTS_ACTION_TYPES.SAVE_LINE_PROPOSAL),
    map(toPayload),
    mergeMap(({ line, report_id }) => {
      return this.apiService.upsertFiscalReportLines(report_id, [line]).pipe(
        map(([response]) => FiscalReportLine.iObjectP(response)),
        map((new_line) => DomainActions.FiscalReports.saveProposalLineSuccess(new_line)),
        catchError(error => of(DomainActions.FiscalReports.serviceFail(error)))
      );
    })
  ));

  /**
   * Saves fiscal report.
   */
   saveFiscalReport = createEffect(() => this.actions$.pipe(
    ofType(FISCAL_REPORTS_ACTION_TYPES.SAVE_FISCAL_REPORT),
    map(toPayload),
    mergeMap((report: Model.FiscalReport) => {
      return this.apiService.saveFiscalReport(report.proposal_id, report).pipe(
        map(response => DomainActions.FiscalReports.saveSuccess(response)),
        catchError(error => of(DomainActions.FiscalReports.serviceFail(error)))
      );
    })
  ));
}
