<div id="budget_item_{{itemId}}" class="card">
  <ng-container [ngSwitch]="!!isPreview">
    <div *ngSwitchCase="true" class="card-title">
      <h5 *ngSwitchCase="true">{{displayTitle}} {{yearName}}</h5>
    </div>

    <div *ngSwitchCase="false" class="card-title">
      <h3>{{displayTitle}} {{yearName}}</h3>
    </div>
  </ng-container>

  <div *ngIf="canEdit" class="help-text">
    <p>
      Budget items may contain clusters of related expenditures. This should be a high level summary of the budget that indicates how funds
      will be utilized to accomplish the plan activities.
    </p>
  </div>

  <form *ngIf="showCard" [formGroup]="form" autocomplete="off" [ngClass]="{'first-touch': firstTouch}">
    <div class="card-section-wrapper">
      <div class="card-section">
        <input-field *ngIf="fieldFilter?.title &&!isPreview" labelText="Budget Item Title" [showCounter]="true" [canEdit]="canEdit">
          <input inputRef id="title_input_{{itemId}}" type="text"
            formControlName="title"
            [maxLength]="CHAR_LIMIT_TITLE"
            (change)="updateAttribute('title')"
            aria-required="true" required>
        </input-field>

        <po-select *ngIf="fieldFilter?.institution_id" id="institution_select_{{itemId}}" labelText="Institution"
          [canEdit]="canEdit"
          [options]="institutionOptions"
          [selectOnce]="true"
          (optionSelected)="updateAttribute('institution_id')">
          <select inputRef class="po-select__select" formControlName="institution_id" aria-required="true" required></select>
        </po-select>

        <ng-container *ngIf="fieldFilter?.showMatch else singleAmount">
          <input-group headingText="{{yearName}} Budget Item Amount" formGroupName="amounts" [control]="form.controls['amounts']" [required]="canEdit">
            <div *ngIf="canEdit" class="help-text">
              <p>At least one of the following budget item amount fields is required.</p>
            </div>
            <div class="columns columns--2">
              <input-field labelText="Grant Funds Amount" [canEdit]="canEdit">
                <input inputRef id="grant_funds_input_{{itemId}}"
                  formControlName="grant_funds_amount"
                  type="text"
                  integerCurrency
                  placeholder="$"
                  (change)="updateAmount('grant_funds_amount')">
              </input-field>

              <input-field labelText="Monetary Match Funds Amount" [canEdit]="canEdit">
                <input inputRef id="monetary_match_input_{{itemId}}"
                  formControlName="monetary_match_amount"
                  type="text"
                  integerCurrency
                  placeholder="$"
                  (change)="updateAmount('monetary_match_amount')">
              </input-field>
            </div>
          </input-group>
        </ng-container>
        <ng-template #singleAmount>
          <ng-container formGroupName="amounts">
            <input-field labelText="Fund Amount" [canEdit]="canEdit">
              <input inputRef id="fund_amount_{{itemId}}"
                formControlName="grant_funds_amount"
                type="text"
                integerCurrency
                placeholder="$"
                (change)="updateAmount('grant_funds_amount')"
                aria-required="true" required>
            </input-field>
          </ng-container>
        </ng-template>
      </div>

      <div class="card-section">
        <po-select *ngIf="fieldFilter?.object_code_id" id="object_code_select_{{itemId}}" labelText="Expenditure Type"
          [canEdit]="canEdit"
          [options]="objectCodeOptions"
          [selectOnce]="true"
          (optionSelected)="updateAttribute('object_code_id')">
          <select inputRef class="po-select__select" formControlName="object_code_id" aria-required="true" required></select>
        </po-select>

        <po-select *ngIf="fieldFilter?.related" id="activities_select_{{itemId}}" labelText="Related Activities"
          [canEdit]="canEdit"
          [options]="activityOptions"
          [selectMultiple]="true"
          (optionSelected)="addRelatedActivity($event)"
          (optionRemoved)="removeRelatedActivity($event)">
          <select inputRef class="po-select__select" formControlName="related_activities" aria-required="true" required></select>
        </po-select>

        <input-field *ngIf="fieldFilter?.description" labelText="Brief Description of Expenditure" [showCounter]="true" [canEdit]="canEdit">
          <textarea inputRef id="description_input_{{itemId}}"
            formControlName="description"
            [maxLength]="CHAR_LIMIT_DESC"
            (change)="updateAttribute('description')"
            aria-required="true" required>
          </textarea>
        </input-field>
      </div>
    </div>

    <div *ngIf="!isPreview" class="card-footer">
      <div class="align-left help-text margin-bottom--none">
        <p>Budget Item Total: {{+budgetTotal | currency:'USD':'symbol':'1.0-0'}}</p>
      </div>
      <div *ngIf="canEdit && canDelete" class="inline-button-group__container inline-button-group__container--right">
        <button id="delete_button_{{itemId}}" class="inline-button icon--left action-button action-button--secondary action-button--small js-delete"
          type="button" (click)="toggleDeleteWarning()">
          <icon iconId="delete"></icon>
          Delete
        </button>
      </div>
    </div>
  </form>
</div>

<alert *ngIf="showDeleteWarning"
  button-dismiss="Cancel"
  button-action="Delete"
  level="warning"
  (dismiss)="toggleDeleteWarning()"
  (action)="deleteBudgetItem()">
  <p>Are you sure you want to delete the '{{displayTitle}}' Budget Item?</p>
</alert>
