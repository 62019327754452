import {AfterContentInit, Component, ContentChildren, forwardRef, Input, OnDestroy, QueryList} from '@angular/core';
import { POSelectComponent } from '../po-select/po-select.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Model } from '@app-ngrx-domains';

@Component({
  selector: 'po-month-day-input',
  templateUrl: './month-day-input.component.html',
})
export class MonthDayInputComponent implements AfterContentInit, OnDestroy {

  @ContentChildren(forwardRef(() => POSelectComponent)) poSelects: QueryList<POSelectComponent>;
  @Input() headingText;
  @Input() legendHidden = false;

  monthComponent: POSelectComponent;
  dayComponent: POSelectComponent;

  private destroy$: Subject<boolean> = new Subject();

  constructor() { }

  ngAfterContentInit() {
    this.monthComponent = this.poSelects.find(component => component.identifier === 'month');
    this.dayComponent = this.poSelects.find(component => component.identifier === 'day');

    this.initValues();
    this.watchMonthValues();
  }

  private initValues() {
    this.setMonthOptions();
    const monthValue = this.monthComponent.selectInput.control.value;
    this.handleMonthValue(monthValue);
  }

  private handleMonthValue(monthValue) {
    if (!monthValue) {
      this.resetDayOptions();
    } else {
      this.setDayOptions(monthValue);
      this.setDaySelection(monthValue);
    }
  }

  private setMonthOptions() {
    this.monthComponent.options = MonthDayInputComponent.months.map((month) => {
      return {
        label: month.name,
        value: month.value
      }
    });
  }

  private setDayOptions(monthValue: number) {
    const month = MonthDayInputComponent.months.find(m => m.value === monthValue);
    this.dayComponent.selectInput.control.control.enable();
    this.dayComponent.options = this.generateDayOptions(month.limit);
  }

  private setDaySelection(monthValue: number) {
    const month = MonthDayInputComponent.months.find(m => m.value === monthValue);
    if (this.dayComponent.selectInput.control.value > month.limit) {
      this.dayComponent.selectInput.control.control.setValue('');
    }
  }

  private watchMonthValues() {
    this.monthComponent.selectInput.control.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(monthValue => {
      this.handleMonthValue(monthValue);
    })
  }

  private resetDayOptions() {
    this.dayComponent.selectInput.control.control.setValue('');
    this.dayComponent.options = [];
    this.dayComponent.selectInput.control.control.disable();
  }

  private generateDayOptions(limit: number): Model.SelectOption[] {
    const options = [];
    for (let i = 1; i <= limit; i++) {
      options.push({
        label: `${i}`,
        value: i
      })
    }
    return options;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  private static months: MonthOption[] = [
    {
      name: 'Jan',
      value: 1,
      limit: 31
    },
    {
      name: 'Feb',
      value: 2,
      limit: 28
    },
    {
      name: 'Mar',
      value: 3,
      limit: 31
    },
    {
      name: 'Apr',
      value: 4,
      limit: 30
    },
    {
      name: 'May',
      value: 5,
      limit: 31
    },
    {
      name: 'Jun',
      value: 6,
      limit: 30
    },
    {
      name: 'Jul',
      value: 7,
      limit: 31
    },
    {
      name: 'Aug',
      value: 8,
      limit: 31
    },
    {
      name: 'Sept',
      value: 9,
      limit: 30
    },
    {
      name: 'Oct',
      value: 10,
      limit: 31
    },
    {
      name: 'Nov',
      value: 11,
      limit: 30
    },
    {
      name: 'Dec',
      value: 12,
      limit: 31
    }
  ];
}

export interface MonthOption {
  name: string;
  value: number;
  limit: number;
}
