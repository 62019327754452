import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { Model } from '@app-ngrx-domains';
import { LookupService } from '@app-services';
import { PROJECT_ROLES } from '@app-consts';
import { find, uniq } from 'lodash';
import { UserRoleScope } from '@app-models';

@Component({
  selector: 'app-user-role-project-card',
  templateUrl: './user-role-project-card.component.html',
})
export class UserRoleProjectCardComponent implements OnInit {
  @Input() count: number;
  @Input() canEditRole: boolean;

  @Input() set userRole(role: { role_id: number, name: string, data: Model.RolePermissions, roleScopes: Array<Model.UserRoleScope> }) {
    this._userRole = role;
    this.roleScopes = this.removeDeletedPrograms(role.roleScopes);
    this.roleScopes.forEach(roleScope => {
      const routerLink = this.projectContactLink(roleScope);
      roleScope.routerLink = routerLink;
    });
  }

  get userRole(): { role_id: number, name: string, data: Model.RolePermissions, roleScopes: Array<Model.UserRoleScope> } {
    return this._userRole;
  }

  @Output() removeClicked: EventEmitter<any> = new EventEmitter();

  public roleAliases: Array<string>;
  public roleScopes: Array<Model.UserRoleScope & { routerLink?: string }>;

  private _userRole: { role_id: number, name: string, data: Model.RolePermissions, roleScopes: Array<Model.UserRoleScope> };


  constructor(
    private lookupService: LookupService,
  ) { }

  ngOnInit() {
    this.gatherAliases();
  }

  private gatherAliases() {
    const role_data = find(PROJECT_ROLES, sys_role => sys_role.ID === this.userRole.role_id);
    if (role_data && role_data.ALIASES) {
      this.roleAliases = uniq(Object.values(role_data.ALIASES));
    }
  }

  public removeDeletedPrograms(roleScopes: Array<Model.UserRoleScope>) {
    return roleScopes.filter((roleScope) => {
      return !roleScope.proposal.deleted
    })
  }

  public expandAliases() {
    return this.roleAliases.join(', ');
  }

  public removeRoleScope(roleScope: Model.UserRoleScope) {
    const removeRoleScopeScope = {
      role_id: roleScope.role_id,
    };
    if (roleScope.fund_id) {
      removeRoleScopeScope['fund_id'] = roleScope.fund_id;
    }
    if (roleScope.institution_id) {
      removeRoleScopeScope['institution_id'] = roleScope.institution_id;
    }
    if (roleScope.proposal_id) {
      removeRoleScopeScope['proposal_id'] = roleScope.proposal_id;
    }

    this.removeClicked.emit(removeRoleScopeScope);
  }

  public fundName(roleData: Model.RolePermissions, roleScope: Model.UserRoleScope): string {
    return this.lookupService.getRoleScopeFundName(roleData, roleScope);
  }

  public institutionName(roleData: Model.RolePermissions, roleScope: Model.UserRoleScope): string {
    return UserRoleScope.institutionName(roleData, roleScope);
  }

  public projectName(roleScope: Model.UserRoleScope): string {
    return UserRoleScope.projectName(roleScope);
  }

  public projectType(roleScope: Model.UserRoleScope): string {
    return this.lookupService.getRoleScopeProjectType(roleScope);
  }

  public projectContactLink(roleScope: Model.UserRoleScope): string {
    return this.lookupService.getRoleScopeProjectLink(roleScope);
  }

  public trackById(index: number, roleScope: Model.UserRoleScope) {
    return roleScope.id;
  }
}
