<ng-container *ngFor="let action of actions">
  <ng-container *ngIf="!action.hide" [ngSwitch]="action.type">

    <a *ngSwitchCase="'link'" class="{{baseClass}} {{ action.class }}"
      [routerLink]="action.route || []" [queryParams]="action.queryParams || {}" [target]="action.newTab ? '_blank' : null">
      <icon *ngIf="action.icon" [iconId]="action.icon"></icon>
      {{ action.title }}
    </a>

    <a *ngSwitchCase="'link-href'" class="{{baseClass}} {{ action.class }}"
      href="{{action.route}}" target="_blank">
     <icon *ngIf="action.icon" [iconId]="action.icon"></icon>
      {{ action.title }}
    </a>

    <button *ngSwitchCase="'button'" class="{{baseClass}} {{ action.class }}"
      [disabled]="action.disabled"
      (click)="emitAction(action)">
     <icon *ngIf="action.icon" [iconId]="action.icon"></icon>
      {{ action.title }}
    </button>

  </ng-container>
</ng-container>