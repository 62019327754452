import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { AREAS, WORKFLOW_STEPS, PROGRAM_KEYS, METRIC_GROUPS, PROPOSAL_TYPES } from '@app-consts';
import { PermissionsService, ProgramService } from '@app-services';

@Component({
  selector: 'project-workplan-outcomes',
  templateUrl: './workplan-outcomes.component.html'
})
export class WorkplanOutcomesComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isPreview = false;
  @Input() guidance: Model.GuidanceWorkflowFilter;

  canEdit = false;
  firstTouch = true;
  minCardLength = 3;
  add_disabled = false;
  version: string = 'v2'; // newer version
  isCAI: boolean = false;

  proposal = undefined;
  workplanOutcomes: Array<Model.EAWorkplanOutcome>;
  activityOptions: Array<Model.SelectOption>;
  objectiveOptions: Array<Model.SelectOption>;
  metricsOptions: Array<Model.SelectOption> = [];

  private programId;
  private destroy$: Subject<boolean> = new Subject();
  private initialized = false;
  private currentWorkflowStep = WORKFLOW_STEPS.OUTCOMES;
  private currentWorkflowTemplateName: string;

  constructor(
    private permissionsService: PermissionsService,
    private programService: ProgramService,
    private route: ActivatedRoute,
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnInit() {
    if (!!this.guidance) {
      // in template mode...prepare to show as guidance template.
      this.setupAsTemplate();
      return;
    }

    this.store.select(Queries.CurrentProposal.get)
      .pipe(
        withLatestFrom(
          this.store.select(Queries.Workflow.getCurrent),
          this.store.select(Queries.LookupTables.getMetricsDefinitions),
          this.store.select(Queries.LookupTables.getMetricsDefinitionsByGroup)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([proposal, current_workflow, metricDefs, getMetricDefs]) => {

        if (!proposal.id) {
          // ignore - in transition.
          return;
        }

        this.proposal = proposal;
        this.currentWorkflowTemplateName = current_workflow.templateName;
        this.workplanOutcomes = this.proposal.workplan_outcomes;

        this.activityOptions = this.proposal.workplan_activities
          .filter(activity => activity.id > 0)
          .map(activity => ({ value: activity.id, label: activity.title || 'Untitled Activity' }));
        this.objectiveOptions = this.proposal.workplan_objectives
          .filter(objective => objective.id > 0)
          .map(objective => ({ value: objective.id, label: objective.title || 'Untitled Objective' }));

        // disables add button if outcomes doesn't have a title
        this.add_disabled = !(this.workplanOutcomes.every(objective => !!objective.title));

        // initialize one time set up code.
        this.initialize(metricDefs, getMetricDefs);
      });
  }

  private setupAsTemplate() {
  }

  /**
   * Initializes the vars that need to be set up just once.
   * */
  private initialize(metricDefs, getMetricDefs) {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;

    // determine first touch
    this.firstTouch = !this.workplanOutcomes.length;
    this.programId = this.proposal.funds[0].id;

    if (!this.guidance) {
      this.guidance = {
        programId: this.programId,
        proposalType: PROPOSAL_TYPES.APPLICATION,
        workflowName: this.currentWorkflowTemplateName,
        stepName: this.currentWorkflowStep,
      }
    }

    // Set up card length and metric options for EWD
    const fund = this.proposal.funds[0];
    if (fund.parent_key === PROGRAM_KEYS.CAI) {
      this.isCAI = true;
      if (fund.key === PROGRAM_KEYS.RFA) {
        this.version = 'v1';
        this.metricsOptions = getMetricDefs(METRIC_GROUPS.CAI).map(def => ({ value: def.id, label: def.name }));
      } else if (fund.key === PROGRAM_KEYS.RFA_v2) {
        this.minCardLength = 1;
      }
    } else {
      this.minCardLength = 2;

      const visionGoals = this.proposal.vision_goals.filter(goal => goal.success_goal && goal.selected);
      visionGoals.forEach(goal => {
        const goalName = goal.success_goal.name;
        goal.success_metrics.forEach(metric => {
          const metricDef = metricDefs.find(def => def.id === metric.value);
          if (metricDef) {
            this.metricsOptions.push({ label: `${goalName}: ${metricDef.name}`, value: metric.id });
          }
        });

        goal.performance_outcomes.forEach(metric => {
          const metricDef = metricDefs.find(def => def.id === metric.value);
          if (metricDef) {
            this.metricsOptions.push({ label: `${goalName}: ${metricDef.uniq} ${metricDef.name}`, value: metric.id });
          }
        });
      });
    }

    if (!this.isPreview) {
      this.fetchPermissions();
      // current this as the current step & put up Next button.
      this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));

      const tempsToBuild = [];
      for (let i = this.workplanOutcomes.length; i < this.minCardLength; i++) {
        tempsToBuild.push({
          effort_area_type: 'workplan_outcomes',
          parent_proposal_id: this.proposal.id,
          title: undefined,
          description: undefined
        })
      }
      if (tempsToBuild.length > 0) {
        this.store.dispatch(Actions.CurrentProposal.createTempEffortAreas({eas: tempsToBuild }));
      }
    }
  }

  /**
   * Fetches permission(s) then sets up action button(s)
   */
  private fetchPermissions() {
    this.permissionsService.canEditProject(this.proposal, AREAS.PROJECT, this.route.snapshot)
      .pipe(takeUntil(this.destroy$))
      .subscribe(canEdit => {
        this.canEdit = canEdit;
      });
  }

  addOutcome() {
    this.store.dispatch(Actions.CurrentProposal.createTempEffortArea({
      ea: {
        effort_area_type: 'workplan_outcomes',
        parent_proposal_id: this.proposal.id,
        title: undefined,
        description: undefined
      }
    }));
  }

  get canDelete(): boolean {
    return this.workplanOutcomes && this.workplanOutcomes.length > this.minCardLength;
  }

  trackById(index: number, item: any): number {
    return item.temp_id ? item.temp_id : item.id;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
