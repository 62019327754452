<div *ngIf="canEdit && requiredContacts?.length">
  <h5>Required Contacts</h5>

  <ul class="no-padding">
    <ol *ngFor="let contactType of requiredContacts" class="no-margin no-padding required"
      [attr.data-invalid]="!isRequirementComplete(contactType)">
      <ng-container [ngSwitch]="isRequirementComplete(contactType)">
        <icon *ngSwitchCase="true" iconId="check-circle"
          aria-label="Requirement Satisfied" title="Requirement Satisfied"
          class="icon--success icon--vertical-align margin-right-xxs">
        </icon>
        <icon *ngSwitchCase="false" iconId="alert"
          aria-label="Additional contacts required" title="Additional contacts required"
          class="icon--negative icon--vertical-align margin-right-xxs">
        </icon>
      </ng-container>
      <span class="bold">{{contactType.roleName}}</span> {{getRequirementString(contactType.numberRequired)}}
    </ol>
  </ul>
</div>

<table *ngIf="contacts?.length; else empty_contacts;" class="po-table">
  <thead>
    <tr class="align-headings--left">
      <th scope="col">Name</th>
      <th scope="col">Role</th>
      <th scope="col">Email</th>
      <th *ngIf="canEdit" scope="col" class="align-center">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr class="align-data--left" *ngFor="let contact of contacts; let i = index">
      <td>
        <app-user-actions [count]="i" [userData]="contact.user" [menuText]="formatContactName(contact.user)"></app-user-actions>
      </td>
      <td>{{getRoleName(contact)}}</td>
      <td>{{getContactEmail(contact.user)}}</td>
      <td *ngIf="canEdit" class="align-center">
        <ng-container [ngSwitch]="canRemoveContacts(contact)">
          <a *ngSwitchCase="true" title="Delete"
            (click)="alertContactRemoval(contact)"
            class="action-button action-button--tertiary action-button--small">
            <icon iconId="delete" data-qa="delete-icon"></icon>
          </a>

          <p *ngSwitchCase="false">None</p>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>

<ng-container *ngIf="canEdit">
  <div class="btn-section btn-section--center">
    <div class="inline-button-group__container inline-button-group__container--right">
      <button *ngIf="canAddContact" class="icon--left action-button action-button--secondary action-button--small"
        type="button"
        (click)="toggleAddContact()">
        <icon iconId="plus"></icon>
        Add Contact
      </button>
    </div>
  </div>
  <div class="btn-section btn-section--right margin-bottom-none">
    <div class="inline-button-group__container inline-button-group__container--right">
      <button *ngIf="canDelete && !hasLead" class="icon--left action-button action-button--secondary action-button--small js-delete"
        type="button"
        (click)="emitRemoveInstitution()">
        <icon  iconId="delete"></icon>
        Remove {{agencyLabel}}
      </button>
    </div>
  </div>
</ng-container>

<ng-template #empty_contacts>
  <div *ngIf="!contacts?.length" class="empty-state" [attr.data-invalid]="required">
    <p>No contacts have been added.</p>
  </div>
</ng-template>

<app-invite-user *ngIf="showInviteContact"
  [fundKey]="programKey"
  [proposalId]="proposalId"
  (cancelAction)="closeContactModals()"
  (closeAction)="closeContactModals()"
  (inviteSuccess)="inviteSuccess($event)"
  [description]="'Invite a new user to NOVA and add them as a contact for this agency.'"
  [additionalForm]="formInviteContact"
  [formConfig]="formInviteContactConfig">
</app-invite-user>

<alert *ngIf="!!tempContactToDelete"
  level="warning"
  button-action="Delete"
  (action)="removeContact(tempContactToDelete)"
  button-dismiss="Cancel"
  (dismiss)="dismissDeleteAlert()">
  <p>
    You are attempting to remove yourself as a contact on this project.
    Once removed, you may no longer have edit or view permissions for
    this project. Are you sure you want to proceed?
  </p>
</alert>

<full-page-modal *ngIf="showAddContact"
  [small]="true"
  heading="Add Agency Contact"
  (action)="checkBeforeAddContact()"
  button-action="Add Contact"
  [button-action-disabled]="!form.valid"
  button-cancel="Cancel"
  (dismiss)="closeContactModals()"
  (cancel)="closeContactModals()">

  <p><a (click)="showInviteModal()" class="text-link">Can't find the contact you're looking for?</a></p>

  <form [formGroup]="form" autocomplete="off" class="first-touch">
    <po-select id="add_contact_select_{{institutionIdString}}"
      labelText="User" placeholderText="Enter name or email"
      [options]="userOptionsList"
      [isSearch]="true"
      (search)="updateContactsList($event, 50)">
      <select inputRef class="po-select__select" formControlName="user" aria-required="true"></select>
    </po-select>
    <po-select id="add_contact_role_select_{{institutionIdString}}"
      labelText="Role / Responsibility" placeholderText="Select Contact's Responsibility"
      [options]="contactOptions"
      [selectOnce]="true">
      <select inputRef class="po-select__select" formControlName="role_id" aria-required="true"></select>
    </po-select>
  </form>
</full-page-modal>

<alert *ngIf="showLeadAlert"
  button-action="Ok"
  button-dismiss="Cancel"
  level="warning"
  (action)="replaceLeadContact();"
  (dismiss)="dismissLeadAlert()">
  <p>
    By assigning a new Project Lead, the existing Project Lead <strong>{{existingLead?.user?.first_name}} {{existingLead?.user?.last_name}}</strong> 
    will be moved to <strong>Alternate Project Lead</strong>. Are you sure you want to continue?
  </p>
</alert>
