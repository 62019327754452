import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { State, Actions } from '@app-ngrx-domains';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { ProgramService } from '@app-services';
import { skipWhile, take } from 'rxjs/operators';

@Injectable()
export class ProgramsResolver implements Resolve<boolean> {
  constructor(
    private store: Store<State>,
    private logger: NGXLogger,
    private programService: ProgramService
  ) { }

  resolve(): Observable<boolean> {
    return new Observable(subscriber => {
      this.logger.debug(`[programs-resolver] loading`);

      this.programService.initialLoaded().pipe(
        skipWhile(loaded => !loaded),
        take(1)
      ).subscribe(() => {
        this.logger.debug(`[programs-resolver] loaded`);
        subscriber.next(true);
        subscriber.complete();
      }, error => {
        this.logger.error(`[programs-resolver] error=${JSON.stringify(error)}`);
        subscriber.error(false);
        subscriber.complete();
      });
    });
  }
}
