import { forkJoin, of, Observable } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType, Effect } from '@ngrx/effects';
import { State, Queries, Model, Actions as DomainActions } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService, AttributesService } from '@app-services';
import { INSTITUTION_ACTION_PREFIX, INSTITUTION_ACTION_TYPES } from './institution.action';
import { EnumErrorTypes, UserRoleScope } from '@app-models';
import { ROUTER_LINKS } from '@app-consts';
import { isNil } from 'lodash';

@Injectable()
export class InstitutionEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private router: Router,
    private store: Store<State>,
    private attributesService: AttributesService
  ) { }

  private errorPayload(error, location, show) {
    return DomainActions.App.setError({
      type: EnumErrorTypes.api,
      raw: error,
      location,
      show
    });
  }

  @Effect() get$: Observable<Action> = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.GET),
    map(toPayload),
    mergeMap((payload) => {
      // Fetch institution by id
      const institution_id = payload.institution_id;

      return this.apiService.getInstitutionById(institution_id).pipe(mergeMap((response: Model.Institution) => {
        return of(DomainActions.Institution.load(response));
      }),
      catchError(error => {
        this.router.navigate([ROUTER_LINKS.INSTITUTIONS]);
        return of(this.errorPayload(error, INSTITUTION_ACTION_TYPES.GET, false));
      }));
    })
  );

  @Effect() update$: Observable<Action> = this.actions$.pipe(ofType(INSTITUTION_ACTION_TYPES.UPDATE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Institution.getInstitutionId)),
    mergeMap(([payload , institution_id]) => {
      const updates = Object.entries(payload.updates).reduce((obj, entry) => {
        const [key, value] = entry;
        obj[key] = isNil(value) ? undefined : value;
        return obj;
      }, {});

      return this.apiService.updateInstitution({ id: institution_id, ...updates }).pipe(mergeMap((response: Model.Institution) => {
        return of(DomainActions.Institution.load(response));
      }),
      catchError(error => {
        return of(this.errorPayload(error, INSTITUTION_ACTION_TYPES.UPDATE, true));
      }));
    })
  );

  @Effect() addRoleScopes$: Observable<Action> = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.ADD_ROLE_SCOPES),
    map(toPayload),
    mergeMap((payload) => {
      const role_scopes = payload.role_scopes.map(role_scope => UserRoleScope.stripped(role_scope));
      return forkJoin(role_scopes.map(role_scope => {
        return forkJoin([
          this.apiService.addUserRoleScope(role_scope, payload.notify_on_assignment),
          this.apiService.getProfileById(role_scope.user_id)]).pipe(map(([role, user]) => ({ ...role, user })))
        })).pipe(
          mergeMap(res => of(DomainActions.Institution.addRoleScopesSuccess(res))),
          catchError(err => {
            if (err.status === 409) {
              return of(DomainActions.App.showAlert(`
                <strong>Couldn't Add Duplicate Role</strong>
                This user already has already been assigned this role.`, true));
            } else  {
              return of(this.errorPayload(err, INSTITUTION_ACTION_TYPES.ADD_ROLE_SCOPES, true));
            }
          }));
      })
  );

  @Effect() removeRoleScope$: Observable<Action> = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.REMOVE_ROLE_SCOPE),
    map(toPayload),
    mergeMap((payload) => {
      const role_scope = UserRoleScope.stripped(payload.role_scope);
      return this.apiService.removeUserRoleScope(role_scope).pipe(mergeMap(() => {
        return of(DomainActions.Institution.removeRoleScopeSuccess(payload.role_scope));
      }),
      catchError(error => of(this.errorPayload(error, INSTITUTION_ACTION_TYPES.UPDATE, true))));
    })
  );

  /**
   * Updates institution attribute.
   */
   @Effect() upsertAttribute$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.UPSERT_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Institution.getInstitution)),
    mergeMap(([req, institution]) => {
      return this.attributesService.upsertAttribute$(INSTITUTION_ACTION_PREFIX, req, institution, null, null, institution.id);
    })
  );

  /**
   * Updates multiple attributes at once
   */
  @Effect() upsertAttributes$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.UPSERT_ATTRIBUTES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Institution.getInstitution)),
    mergeMap(([req, institution]) => {
      return this.attributesService.upsertAttributes$(INSTITUTION_ACTION_PREFIX, req, institution, institution.id);
    })
  )

  /**
   * Updates multiple attributes at once
   */
  @Effect() deleteAttributes$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.DELETE_ATTRIBUTES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Institution.getInstitution)),
    mergeMap(([req, institution]) => {
      return this.attributesService.deleteAttributes$(INSTITUTION_ACTION_PREFIX, req, institution, null, institution.id);
    })
  )

  /**
   * Adds institution multi attribute.
   */
  @Effect() addMultiAttribute$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.ADD_MULTI_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Institution.getInstitution)),
    mergeMap(([req, institution]) => {
      return this.attributesService.addMultiAttribute$(INSTITUTION_ACTION_PREFIX, req, institution, null, institution.id);
    })
  );

  /**
   * Deletes institution multi attribute.
   */
  @Effect() deleteMultiAttribute$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.DELETE_MULTI_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Institution.getInstitution)),
    mergeMap(([req, institution]) => {
      return this.attributesService.deleteMultiAttribute$(INSTITUTION_ACTION_PREFIX, req, institution, null, institution.id);
    })
  );

  /**
   * Creates effort area & an attribute.
   */
  @Effect() creatAttributeEffortArea$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.CREATE_ATTRIBUTE_EFFORT_AREA),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Institution.getInstitution)),
    mergeMap(([req, institution]) => {
      return this.attributesService.creatAttributeEffortArea$(INSTITUTION_ACTION_PREFIX, req, null, institution.id);
    })
  );

  /**
   * Create effort area.
   */
  @Effect() createEffortArea$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.CREATE_EFFORT_AREA),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.createEffortArea$(INSTITUTION_ACTION_PREFIX, req);
    })
  );

  @Effect() createMultiEffortAreas$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.CREATE_MULTI_EFFORT_AREAS),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.createMultiEffortAreas$(INSTITUTION_ACTION_PREFIX, req);
    })
  );

  /**
   * Updates effort area.
   */
  @Effect() updateEffortArea$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.UPDATE_EFFORT_AREA),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.updateEffortArea$(INSTITUTION_ACTION_PREFIX, req);
    })
  );

  /**
   * Deletes effort area.
   */
  @Effect() deleteEffortArea$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.DELETE_EFFORT_AREA),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.deleteEffortArea$(INSTITUTION_ACTION_PREFIX, req);
    })
  );

  /**
   * Deletes list of effort areas.
   */
  @Effect() deleteMultiEffortAreas$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.DELETE_MULTI_EFFORT_AREAS),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.deleteMultiEffortAreas$(INSTITUTION_ACTION_PREFIX, req);
    })
  );

  @Effect() cloneEffortAreas$ = this.actions$.pipe(
    ofType(INSTITUTION_ACTION_TYPES.CLONE_EFFORT_AREAS),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.cloneEffortAreas$(INSTITUTION_ACTION_PREFIX, req.operations, req.parentEffortAreas);
    })
  );
}
