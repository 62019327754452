<ng-container *ngIf="(initialized$ | async)" [ngSwitch]="!!isPreview">
  <!-- Editable -->
  <ng-container *ngSwitchCase="false">
    <div *ngIf="!lead_id" class="error-text" level="error">Please specify a lead institution on the Description tab in order to complete the Contacts list.</div>
    <contact-section *ngFor="let section of sections"
      [canEdit]="canEdit"
      [section]="section"
      [context]="context"
    ></contact-section>
  </ng-container>

  <!-- Preview Mode -->
  <ng-container *ngSwitchCase="true">
    <div class="card">
      <div class="card-title">
        <h3>Contacts</h3>
      </div>

      <div *ngIf="contacts" class="contacts">
        <ng-container *ngIf="!contacts.length">
          <p class="empty-state">No contacts have been assigned.</p>
        </ng-container>
        <div *ngFor="let contact of contacts; let i = index" class="contacts__contact contacts__contact--with-options">
          <h4 class="contacts__contact__heading">{{getRoleName(contact)}}</h4>
          <ng-container *ngIf="contact.user">
            <app-user-actions 
              [count]="i" 
              [userData]="contact.user" 
              [menuText]="getUserFullName(contact.user)"
              class="contacts__contact__options">
            </app-user-actions>
            <p class="contacts__contact__details">
              <ng-container *ngIf="contact.user.title">{{contact.user.title}}<br></ng-container>
              <ng-container *ngIf="contact.user.email_address">
                <a href="mailto:{{contact.user.email_address}}" class="text-link" id="qa_contact_email{{i}}">
                  {{contact.user.email_address}}</a><br>
              </ng-container>
              <ng-container *ngIf="contact.user.phone">
                {{ contact.user.phone | phone }} {{ contact.user.phone_extension | phone_extension }}
              </ng-container>
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
