import { Model } from '@app-ngrx-domains';
import { IEffortArea } from '../effort-area';

export interface IAnnualPlanStrategies extends IEffortArea {
  objective_id: number;
  activity_id: number;
  is_locked: boolean;
  metric_definitions: Array<Model.AttributeValue>;
}

export class AnnualPlanStrategies {

  /**
   * Returns target objective's annual plan strategies.
   * @static
   * @param {number} target_duration_id
   * @param {number} target_objective_id
   * @param {Array<Model.EAAnnualPlanStrategies>} annualPlanStrategies
   * @returns {*} AnnualPlanStrategies
   */
  static targetYearObjectiveStrategies(target_duration_id: number, target_objective_id, annualPlanStrategies: Array<Model.EAAnnualPlanStrategies>): any {
    const result = annualPlanStrategies.filter(strategy => strategy.duration_id === target_duration_id && strategy.objective_id === target_objective_id);
    return result;
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type EAAnnualPlanStrategies = IAnnualPlanStrategies;
  }
}