<full-page-modal *ngIf="showSignupModal"
  [small]="true"
  [heading]="programName"
  button-cancel="Remind me later"
  button-action="Submit"
  [button-action-disabled]="programSignupForm.invalid"
  (cancel)="showSignupModal = false;"
  (dismiss)="showSignupModal = false;"
  (action)="submitProgramSignup()">
  <form [formGroup]="programSignupForm">

    <h3>Select Your {{selectionLabel}}</h3>
    <div class="help-text">
      <p>
        To complete your {{programName}} registration, choose your {{selectionLabel}} from the list below.
        By submitting your selection, you will be assigned a permissioned role for your {{selectionLabel}}.
      </p>
    </div>

    <po-select id="signup_selection"
      [labelText]="selectionLabel"
      placeholderText="Select your {{selectionLabel}}"
      [options]="selectionOptions$ | async"
      [selectOnce]="true">
      <select inputRef class="po-select__select" formControlName="selection" aria-required="true"></select>
    </po-select>

    <po-select *ngIf="optionProposalMap" id="signup_proposal_selection"
      labelText="Proposal"
      placeholderText="Select your proposal"
      [options]="proposalOptions"
      [selectOnce]="true">
      <select inputRef class="po-select__select" formControlName="proposal_id" aria-required="true"></select>
    </po-select>

    <div *ngIf="!!acknowledgement">
      <ng-container *ngTemplateOutlet="acknowledgement.body"></ng-container>

      <radio-check [labelText]="acknowledgement.label">
        <input inputRef id="acknowledgement_check" formControlName="acknowledgement_check" type="checkbox" aria-required="true" required>
      </radio-check>
    </div>

  </form>
</full-page-modal>
