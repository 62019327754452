<form *ngIf="!isReply || !isTemp || (isSelected && !parent.resolved)" [formGroup]="form" class="comment-item">
  <div #commentElementChild class="comment-card__container">
    <div *ngIf="!isReply || !item.draft" class="comment-card__info">
      <div>
        <div class="comment-creator">{{ creator.first_name }} {{ creator.last_name }}</div>
        <div *ngIf="item.created_at" class="comment-date">{{ item.created_at | po_calendar_date : calendarFormat }}</div>
      </div>

      <div *ngIf="showOptionsMenu" class="flex flex--align-items-center">
        <button *ngIf="!isReply" (click)="onResolve.emit()" title="Resolve" class="resolve-button action-button action-button--small">
          <icon iconId="checkmark" class="icon--positive"></icon>
        </button>
        <options-menu #optionsMenu iconId="more">
          <options-menu-item optionText="Edit" (doEmit)="edit()"></options-menu-item>
          <options-menu-item optionText="Delete" (doEmit)="showDeleteAlert = true;"></options-menu-item>
        </options-menu>
      </div>
    </div>
    <div class="comment-card__form">
      <div class="comments-comment comments-comment-form">
        <input-field labelText="Comment" [canEdit]="canEdit && (isTemp ? isSelected : (isEditing || item.draft))" [labelHidden]="true"
          [disableComments]="true" [hideError]="true">
          <po-html-textarea #commentInput inputRef id="comments_form-input-{{item.id}}" formControlName="comment"
            [htmlCharLimit]="1000" (click)="onInputFocus()"
            [toggleToolbar]="true" [hideToolbar]="true" [enableMentions]="true" [disableComments]="true"
            [htmlInputHeight]="10" [enableCharCounter]="false" placeholderText="Type a {{isReply ? 'reply' : 'comment'}}..." aria-required="true"
            (blur)="onInputBlur($event)">
          </po-html-textarea>
        </input-field>
      </div>
      <div *ngIf="showButtons" class="btn-section btn-section--right comment-card__buttons">
        <div class="inline-button-group">
          <button #cancelButton class="action-button action-button--secondary action-button--small" type="button"
            (click)="delete()">
            Cancel
          </button>
          <button #actionButton class="action-button action-button--primary action-button--small" type="button"
            (click)="save()" [disabled]="!form.valid">
            {{ actionLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<alert *ngIf="showDeleteAlert"
  button-action="Delete"
  button-dismiss="Cancel"
  level="warning"
  (action)="delete()"
  (dismiss)="showDeleteAlert = false;">
  <ng-container [ngSwitch]="!!isReply">
    <p *ngSwitchCase="false">
      Are you sure you want to delete this thread? Deleting it will also delete any replies it has.
    </p>
    <p *ngSwitchCase="true">
      Are you sure you want to delete this comment?
    </p>
  </ng-container>
</alert>
