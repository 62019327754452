import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { LookupService, PermissionsService, ProgramService } from '@app-services';
import { ProposalBase as Proposal } from '@app-models';
import { ACTIONS, AREAS, PROGRAM_KEYS, PROPOSAL_TYPES, WORKFLOW_STEPS } from '@app-consts';
import { groupBy } from 'lodash';

@Component({
  selector: 'app-budget-forecast-v2',
  templateUrl: './budget-forecast-v2.component.html',
})
export class BudgetForecastV2Component implements OnInit, OnDestroy, AfterViewInit {
  @Input() isPreview = false;
  @Input() guidance: Model.GuidanceWorkflowFilter;

  public initialized$ = new BehaviorSubject<boolean>(false);
  public canEdit: boolean = false;
  public firstTouch: boolean = false;
  public isPlan: boolean = false;

  public proposal: Model.SWPProposal;
  public parentKey: string;
  public program: Model.Fund;
  public yearOptions: Array<Model.Duration> = [];
  public yearForm: FormGroup;
  public forecastsByYear: { [yearId: number]: Array<{
    institution: Model.Institution,
    year: Model.Duration,
    budgetTotal: number,
    forecast?: Model.EAPlanExpenditureForecast
  }>} = {};

  private destroy$: Subject<boolean> = new Subject();
  private currentWorkflowStep = WORKFLOW_STEPS.FORECAST;
  private workflowName: string;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    private permissionsService: PermissionsService,
    private lookupService: LookupService,
    private programService: ProgramService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (!!this.guidance) {
      // in template mode...prepare to show as guidance template.
      this.setupAsGuidanceTemplate();
      return;
    }

    this.store.select(Queries.CurrentProposal.get).pipe(
      filter(p => p && p.id),
      withLatestFrom(this.store.select(Queries.Workflow.getCurrent)),
      takeUntil(this.destroy$),
    ).subscribe(([proposal, workflow]) => {

      this.proposal = proposal;
      this.isPlan = Proposal.isPlan(proposal);
      this.parentKey = this.proposal.funds[0].parent_key;
      this.program = this.programService.getProgramById(this.proposal.funds[0].id);
      this.workflowName = workflow.name;
      this.initialize();
    });
  }

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async initialize() {
    if (this.initialized$.value) {
      // Re-Init Stored Forecasts
      this.proposal.plan_expenditure_forecasts.forEach(forecast => {
        const forecastCard = (this.forecastsByYear[forecast.duration_id] || []).find(card => {
          return card['institution'].id === forecast['institution_id'];
        });
        if (forecastCard) {
          forecastCard.forecast = forecast;
        }
      });

      return;
    }

    this.initialized$.next(true);

    try {
      this.store.dispatch(Actions.Layout.showBusySpinner(true));

      if (!this.guidance) {
        // set up guidance
        this.guidance = {
          programId: this.proposal.funds[0].id,
          proposalType: PROPOSAL_TYPES.APPLICATION,
          workflowName: this.workflowName,
          stepName: this.currentWorkflowStep,
        }
      }

      const forecastsToCreate = [];
      const endYear = Proposal.getEndYear(this.proposal);

      for (let year = this.proposal.duration_id; year <= endYear; year++) {
        const yearDuration = this.lookupService.getYear(year);
        this.yearOptions.push(yearDuration);

        let currentBudgetItems = this.proposal.plan_budget_items.filter(budgetItem => {
          const isRevised = !!budgetItem.revised_amount || budgetItem.revised_amount === 0;
          const hasAmount = !!budgetItem.direct_amount || budgetItem.direct_amount === 0;
          // Filter out budget items that only have revised_amount
          return budgetItem.duration_id === year && (!isRevised || hasAmount);
        });

        if (this.isSWP || this.program.is_small_program) {
          const partnerInstitutionIds = this.proposal.institutions.map(inst => inst.id);
          currentBudgetItems = currentBudgetItems.filter(budgetItem => {
            return partnerInstitutionIds.includes(budgetItem.institution_id);
          });
        }

        const yearsBudgets = groupBy(currentBudgetItems, 'institution_id');

        this.forecastsByYear[year] = Object.keys(yearsBudgets).map(Number).map(id => {
          const budgetTotal = yearsBudgets[id]
            .reduce((total, budgetItem) => total + (budgetItem.direct_amount || budgetItem.grant_funds_amount || 0), 0);

          const forecast = this.getForecast(year, id);
          if (!forecast) {
            const item = { duration_id: year };
            item['institution_id'] = id;
            forecastsToCreate.push(item);
          }

          if (this.isSWP || this.program.is_small_program) {
            const institution = this.proposal.institutions.find(inst => inst.id === id);
            return { institution, budgetTotal, year: yearDuration, forecast };
          }
        });
      }

      // Sort forecast cards
      const leadId = this.proposal.lead_institution ? this.proposal.lead_institution.id : undefined;
      Object.entries(this.forecastsByYear).forEach(([year, forecasts]) => {
        this.forecastsByYear[year] = forecasts.sort((a, b) => {
          if (leadId) {
            if (a['institution'].id === leadId) { return -1 }
            if (b['institution'].id === leadId) { return 1 }
          }
          return a['institution'].name > b['institution'].name ? 1 : -1;
        });
      });

      if (this.yearOptions.length) {
        this.yearForm = this._fb.group({ selectedYear: [this.yearOptions[0].id] });
      }

      if (!this.isPreview) {
        this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));

        this.permissionsService.canCanProject(this.proposal, AREAS.PROJECT, [
          ACTIONS.SUBMIT, ACTIONS.EDIT
        ], this.route.snapshot)  
        .subscribe((permissions) => {
          const canEdit = !!permissions.find(p => p.action === ACTIONS.EDIT && p.allowed);
          const canSubmit = !!permissions.find(p => p.action === ACTIONS.SUBMIT && p.allowed);
  
          const isDraft = Proposal.projectIsDraft(this.proposal);
          const isCertified = Proposal.projectIsCertified(this.proposal);
          this.canEdit = isCertified ? canSubmit : canEdit && (this.proposal.type === AREAS.APPLICATION || isDraft);
        });

        this.firstTouch = !this.proposal.plan_expenditure_forecasts.length;
        this.createForecasts(forecastsToCreate);
      }
    } finally {
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
    }
  }

  private createForecasts(forecastsToCreate) {
    forecastsToCreate.forEach(forecast => {
      const ea = {
        effort_area_type: 'plan_expenditure_forecasts',
        parent_proposal_id: this.proposal.id,
        duration_id: forecast.duration_id,
        quarter_4: 100
      };

      ea['institution_id'] = forecast['institution_id'];

      this.store.dispatch(Actions.CurrentProposal.createEffortArea({
        ea
      }));
    });
  }

  updateForecast(event, card) {
    const forecast = this.getForecast(card.year.id, card.institution.id);
    if (!forecast) { return; }

    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({
      ea: forecast,
      key: event.quarter,
      value: event.value
    }));
  }

  getForecast(durationId: number, id: number) {
    return this.proposal.plan_expenditure_forecasts.find(forecast => {
      return forecast['institution_id'] === id && forecast.duration_id === durationId;
    });
  }

  get isSWP(): boolean {
    return [PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.parentKey);
  }

  get displayedCards() {
    if (!this.yearForm) {
      return [];
    }

    const selectedYear = this.yearForm.get('selectedYear').value;
    return this.forecastsByYear[selectedYear];
  }

  getCardName(card) {
    return card.institution.name || 'Unknown Institution';
  }

  trackByCard(index: number, card: { institution: Model.Institution; year: Model.Duration }) {
    return `${card.institution.id}_${card.year.id}`;
  }

  private setupAsGuidanceTemplate() {
    this.initialized$.next(true);
  }

}
