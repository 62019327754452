import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, ExtraOptions, Routes, Route } from '@angular/router';
import { FundVersionGuard } from './core/guards';
import { CompositeRouteGuard } from './shared.project/guards';

function mapRoute(route: Route, parentGuards?: Array<any>) {
  const routeGuards = [ ...route.canActivate || [], ...parentGuards || []];
  if (route.canActivate?.some(guard => guard.name === CompositeRouteGuard.name)) {
    // Add composite guards to list of all route guards
    routeGuards.push(...route.data?.routeGuards || []);
  }

  const isRefreshableRoute = routeGuards?.some(guard => guard === FundVersionGuard);
  if (isRefreshableRoute) {
    route.runGuardsAndResolvers = 'always';
  }

  route.children?.forEach(childRoute => {
    mapRoute(childRoute, route.canActivateChild);
  });
}

@NgModule({
  imports: [RouterModule],
  exports: [RouterModule]
})
export class AppRouterModule {
  static forRoot(routes: Routes, config: ExtraOptions): ModuleWithProviders<RouterModule> {
    routes.forEach(route => {
      mapRoute(route)
    });

    return RouterModule.forRoot(routes, config);
  }

  static forChild(routes: Routes): ModuleWithProviders<RouterModule> {
    routes.forEach(route => {
      mapRoute(route)
    });

    return RouterModule.forChild(routes);
  }
}
