<full-page-modal
  heading="Preview & Test Email"
  button-action="Close"
  [button-action-disabled]="!canCloseModal"
  (action)="closeAction.emit()"
  (dismiss)="closeAction.emit()">

  <div class="card-title">
    <h3 class="h3">Email Preview</h3>
    <p class="form-help__text">Below is a preview of how your email will look in your recipients' inboxes.  There may be slight discrepancies depending on how your recipients are viewing their email.</p>
  </div>

  <ng-container *ngIf="previewPage; else loading">
    <iframe width="100%" height="500" [srcdoc]="previewPage | safeHtml" frameBorder=0></iframe>
  </ng-container>

  <ng-template #loading>
    <p class="message">Loading email preview... <span class="loader-spinner-inline"></span></p>
  </ng-template>

  <!-- Send Test Email -->
  <form [formGroup]="emailTestForm" autocomplete="off">
    <h3 class="h3">Send Test Email</h3>

    <po-select id="qa_emailTest_testRecipientsSelect"
      labelText="Recipients"
      placeholderText="Start typing Name or Email"
      [canEdit]="true"
      [options]="contactList"
      [selectMultiple]="true"
      [isSearch]="true"
      (search)="getContactList($event, 50)">
      <select inputRef class="po-select__select" formControlName="recipients"></select>
    </po-select>

    <div class="card-footer card-footer--right">
      <button class="action-button action-button--primary margin-bottom-sm" type="button"
              [disabled]="!canSendTestEmail"
              (click)="sendTestEmailClicked()">
        Send Test Email
      </button>
    </div>

    <po-status-message [statusMessage]="statusMessage"></po-status-message>
  </form>

</full-page-modal>
