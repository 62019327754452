import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil, withLatestFrom, skipWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { AREAS, WORKFLOW_STEPS, WORKFLOW_TYPES, PROPOSAL_TYPES } from '@app-consts';
import { PermissionsService, ProgramService } from '@app-services';
import { Budget, ProposalBudget } from '@app-models';

@Component({
  selector: 'allocated-budget',
  templateUrl: './allocated-budget.component.html'
})
export class AllocatedBudgetComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isPreview = false;
  @Input() hideTitle = false;

  canEdit = false;
  firstTouch = true;
  form: FormGroup;

  guidance: Model.GuidanceWorkflowFilter;
  proposal: Model.ProposalBudget;
  budgetItems: Array<Model.EAPlanBudgetItem>;
  programSettings: Model.EAProgramSettings;
  durationForecasts: { [duration_id: number]: Model.EAPlanExpenditureForecast } = {};
  durationOptions: Array<Model.Duration>;
  activityOptions: Array<Model.SelectOption>;
  objectCodeOptions: Array<Model.SelectOption>;
  institutionOptions: Array<Model.SelectOption>;
  indirectObjectCodeId: number;

  // Moneys In
  monetaryMatch: number;
  inKindMatch: number;
  expectedGrantMatch: number;
  allocationsAmount: number;

  // Moneys Out
  monetaryMatchBudgeted: number;
  directCostsBudgeted: number;
  indirectCostsBudgeted: number;
  grantFundsBudgeted: number;

  // Ratios
  matchRatioRequired = 0;
  matchRatioCurrent: number;

  summaryEntries = [
    {
      title: 'Total Grant Amount Budgeted:',
      class: 'budget-summary__col1__row2',
      isSet: () => this.isSet(this.grantFundsBudgeted) && this.isSet(this.allocationsAmount),
      amount: () => this.grantFundsBudgeted,
      isInvalid: () => {
        return this.isPlan
          ? this.allocationsAmount !== this.grantFundsBudgeted
          : false;
      },
      alertText: () => {
        return this.isPlan
          ? 'Must equal grant amount'
          : undefined;
      },
      helpText: () => {
        if (this.isPlan) {
          const dollarAmount = this.currencyPipe.transform(Math.abs(this.allocationsAmount), 'USD', 'symbol', '1.0-0');
          return `of ${dollarAmount}`;
        } else {
          return undefined;
        }
      },
    },
    {
      title: 'Monetary Match Funds Budgeted:',
      class: 'budget-summary__col1__row3',
      isSet: () => (this.isSet(this.monetaryMatchBudgeted) && this.isSet(this.monetaryMatch)),
      amount: () => this.monetaryMatchBudgeted,
      isInvalid: () => this.monetaryMatch !== this.monetaryMatchBudgeted,
      alertText: () => 'Must equal monetary match',
      helpText: () => {
        const dollarAmount = this.currencyPipe.transform(Math.abs(this.monetaryMatch), 'USD', 'symbol', '1.0-0');
        return `of ${dollarAmount}`;
      }
    },
    {
      title: 'Total Match Amount:',
      class: 'budget-summary__col1__row4',
      isSet: () => this.isSet(this.inKindMatch) && this.isSet(this.monetaryMatch) && this.isSet(this.matchRatioCurrent),
      amount: () => this.inKindMatch + this.monetaryMatch,
      isInvalid: () => (this.matchRatioCurrent < this.matchRatioRequired),
      alertText: () => `Must meet or exceed ${this.matchRatioRequired}%`,
      helpText: () => {
        let helper: string;
        if (this.matchRatioCurrent < this.matchRatioRequired) {
          helper = `(${Number(this.matchRatioCurrent.toFixed(2))}% of grant funds)`;
        } else {
          helper = `(${Number(this.matchRatioRequired.toFixed(2))}% match met)`;
        }
        return helper;
      }
    },
    {
      title: 'Indirect Funds Budgeted:',
      class: 'budget-summary__col2__row2',
      isSet: () => (this.isSet(this.indirectCostsBudgeted) && this.isSet(this.directCostsBudgeted)),
      amount: () => this.indirectCostsBudgeted,
      isInvalid: () => false,
      alertText: () => undefined,
      helpText: () => {
        const percent = Budget.indirectPercentage(this.indirectCostsBudgeted, this.directCostsBudgeted);
        return `(${Number(percent.toFixed(2))}%)`;
      }
    },
    {
      title: 'Total Available Funds Budgeted:',
      class: 'budget-summary__col2__row3',
      isSet: () => (this.isSet(this.totalAvailable) && this.isSet(this.totalBudgeted)),
      amount: () => this.totalBudgeted,
      isInvalid: () => this.totalBudgeted !== this.totalAvailable,
      alertText: () => 'Must equal Grant plus Match funds',
      helpText: () => {
        const dollarAmount = this.currencyPipe.transform(Math.abs(this.totalAvailable), 'USD', 'symbol', '1.0-0');
        return `of ${dollarAmount}`;
      }
    },
    {
      title: 'Total Remaining:',
      class: 'budget-summary__col2__row4',
      isSet: () => (this.isSet(this.totalAvailable) && this.isSet(this.totalBudgeted)),
      amount: () => this.totalAvailable - this.totalBudgeted,
      isInvalid: () => this.totalBudgeted !== this.totalAvailable,
      alertText: () => undefined,
    }
  ];

  private destroy$: Subject<boolean> = new Subject();
  private initialized = false;
  private currentWorkflowStep = WORKFLOW_STEPS.BUDGET;

  constructor(
    private programService: ProgramService,
    private permissionsService: PermissionsService,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private store: Store<State>,
    private currencyPipe: CurrencyPipe,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnInit() {
    this.store.select(Queries.CurrentProposal.get).pipe(
      withLatestFrom(
        this.store.select(Queries.LookupTables.getDurations),
        this.store.select(Queries.LookupTables.getObjectCodesMS),
        this.store.select(Queries.CurrentProposal.getInstitutions)
      ),
      skipWhile(([p, d, oc, i]) => !p || !d || !oc || !i),
      takeUntil(this.destroy$)
    )
    .subscribe(data => {
      const [proposal, durations, objectCodes, institutions] = data;

      if (!proposal.id) {
        // ignore - in transition.
        return;
      }

      this.proposal = proposal as Model.ProposalBudget;
      this.budgetItems = this.proposal.plan_budget_items;
      this.proposal.plan_expenditure_forecasts.forEach(forecast => {
        this.durationForecasts[forecast.duration_id] = forecast;
      });

      this.initialize(data);

      this.getBudgetedAmounts();
      this.matchRatioCurrent = ProposalBudget.getCurrentMatchRatio(this.proposal, this.programSettings, true);
    });
  }

  private initialize(data: any) {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    const [proposal, durations, objectCodes, institutions] = data;

    this.programSettings = this.programService.getProgramById(proposal.fund_ids[0]).program_settings;
    this.matchRatioRequired = ProposalBudget.getMatchRequirementForInstitution(this.programSettings, this.proposal.lead_institution);

    this.activityOptions = ProposalBudget.getActivityOptions(this.proposal);
    this.institutionOptions = institutions;

    this.objectCodeOptions = objectCodes;
    this.indirectObjectCodeId = this.objectCodeOptions.find(code => code.label === 'Indirect Costs').value;

    // calculate duration options based on the fund type.
    this.durationOptions = Budget.getProjectYearDurations(proposal, durations);
    if (!this.durationOptions.length) {
      // there are no valid durations to work with. set moneys in so the summary card looks ok.
      this.monetaryMatch = 0;
      this.inKindMatch = 0;
      this.expectedGrantMatch = 0;
      return;
    }

    this.buildExpenditureForecasts();
    this.getMatchTotals();
    this.getAllocationsAmount();

    const firstDuration = this.durationOptions[0] && this.durationOptions[0].id;
    this.form = this._fb.group({
      durationFilter: [firstDuration]
    });

    this.firstTouch = !this.budgetItems.length;

    this.guidance = {
      programId: this.proposal.funds[0].id,
      proposalType: PROPOSAL_TYPES.APPLICATION,
      workflowName: WORKFLOW_TYPES.CAI,
      stepName: this.currentWorkflowStep
    }

    if (!this.isPreview) {
      this.fetchPermissions();
      // current this as the current step & put up Next button.
      this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));

      if (this.firstTouch) {
        // Create the first budgetItem on load
        this.store.dispatch(Actions.CurrentProposal.createTempEffortArea({
          ea: {
            effort_area_type: 'plan_budget_items',
            parent_proposal_id: this.proposal.id,
            duration_id: firstDuration
          }
        }));
      }
    }
  }

  buildExpenditureForecasts() {
    const tempsToBuild = [];
    this.durationOptions.forEach(duration => {
      const forecast = this.proposal.plan_expenditure_forecasts.find(f => f.duration_id === duration.id);
      if (!forecast) {
        tempsToBuild.push({
          effort_area_type: 'plan_expenditure_forecasts',
          parent_proposal_id: this.proposal.id,
          duration_id: duration.id
        });
      }
    });

    if (tempsToBuild.length) {
      this.store.dispatch(Actions.CurrentProposal.createTempEffortAreas({ eas: tempsToBuild }));
    }
  }

  getForecastForDuration(duration_id: number) {
    return this.durationForecasts[duration_id];
  }

  budgetItemsForDuration(duration_id: number) {
    return this.budgetItems
      .filter(item => item.duration_id === duration_id)
      .sort((a, b) => a.id > b.id ? 1 : 0);
  }

  getBudgetAmountForDuration(duration_id: number) {
    const budgetItems = this.budgetItems.filter(b => b.duration_id === duration_id);
    const amounts = ProposalBudget.getPlanBudgetedAmounts(budgetItems);
    return amounts.monetary_match + amounts.indirect_costs + amounts.direct_costs;
  }

  getMatchTotals() {
    const totals = ProposalBudget.getMatchTotals(this.proposal);
    const program = this.programService.getProgramById(this.proposal.fund_ids[0]);
    this.monetaryMatch = totals.financial_contribution;
    this.inKindMatch = totals.in_kind_match;
    this.expectedGrantMatch = ProposalBudget.getExpectedGrantMatch(this.proposal, program.program_settings);
  }

  getAllocationsAmount() {
    this.allocationsAmount = this.isPlan
      ? ProposalBudget.getAllocationsAmount(this.proposal.allocations)
      : 0;
  }

  getBudgetedAmounts() {
    const amounts = ProposalBudget.getPlanBudgetedAmounts(this.budgetItems);
    this.monetaryMatchBudgeted = amounts.monetary_match;
    this.directCostsBudgeted = amounts.direct_costs;
    this.indirectCostsBudgeted = amounts.indirect_costs;
    this.grantFundsBudgeted = this.directCostsBudgeted + this.indirectCostsBudgeted;
  }

  get totalBudgeted() {
    return Number(this.monetaryMatchBudgeted) + Number(this.directCostsBudgeted) + Number(this.indirectCostsBudgeted);
  }

  get totalAvailable() {
    const total = Number(this.directCostsBudgeted) + Number(this.indirectCostsBudgeted) + Number(this.monetaryMatch);
    return total;
  }

  getDurationLabel(duration: Model.Duration) {
    return `${duration.year - 1} - ${duration.year}`;
  }

  get selectedDurationId() {
    if (this.form) {
      return this.form.get('durationFilter').value;
    }
  }

  private fetchPermissions() {
    const extraScope: Model.PermResourceScope = {
      fund_id: this.proposal.funds[0].id,
    };
    this.permissionsService.canEdit(AREAS.PROJECT, this.route.snapshot, extraScope)
    .pipe(takeUntil(this.destroy$))
    .subscribe(canEdit => {
      this.canEdit = canEdit;
    });
  }

  isSet(value) {
    return value || value === 0;
  }

  get isPlan(): boolean {
    return this.proposal ? this.proposal.type === PROPOSAL_TYPES.PLAN : false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
