<div *ngIf="assurances?.length" [ngClass]="{'card': showCardInPreview || !isPreview}">
  <div class="card-title" class="help-text">
    <h3 class="h3">Assurances</h3>
    <p *ngIf="!isPreview">
    Please attest to the following assurances.
    </p>
  </div>

  <form *ngIf="form" [formGroup]="form" autocomplete="off" [ngClass]="{'first-touch': firstTouch}">
    <div *ngFor="let item of assurances; let i=index; let last=last" formGroupName="assurances" [ngClass]="{ 'margin-bottom': !last }">
      <div *ngIf="item.title" class="label-text">
        {{item.title}}
      </div>
      <radio-check [labelText]="item.description" [canEdit]="canEdit" readOnlyType="checked-unchecked" class="margin-bottom-none">
        <input inputRef id="qa_assurance_checkbox{{i}}"
          class="checkbox" type="checkbox"
          formControlName="item{{i}}"
          (change)="persistAssuranceChange(i)"
          [required]="!isPreview" aria-required="!isPreview"/>
      </radio-check>
      <ul *ngIf="item.files?.length" class="icon-list menu">
        <li *ngFor="let file of item.files" class="icon-list__item">
            <a [href]="file.url" target="_blank" [attr.aria-label]="'file: ' + file.filename" class="icon-list__item__link">
            <icon [iconId]="fileIconType(file)"></icon>
            <span> {{file.filename}}</span>
            </a>
        </li>
      </ul>
    </div>
  </form>
</div>