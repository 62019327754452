import { Utilities } from '@app-models';
import { Model } from '@app-ngrx-domains';
import { IEffortArea } from '../effort-area';
import { ProposalBase } from '../proposal-base';
import { AREA_NAMES } from '../../consts';

export interface IThreeYearPlan extends IEffortArea {
  // Details
  executive_summary: string;
  assurance_ids: Array<Model.AttributeValue>

  // Assessment
  assessment_overview: string;
  regional_alignment: string;
  educational_needs: string;
  entities_contribution: string;
  program_area_participants: Array<Model.EAProgramAreaParticipants>;
  service_providers: Array<Model.AttributeValue>;
  regional_service_evaluation: string;

  // Metrics
  vision_goals: Array<Model.EAVisionGoal>;
  target_goals: Array<Model.EATargetGoal>;
  member_spending_targets: Array<Model.EAMemberSpendingTargets>;

  // Objectives
  three_year_plan_objectives: Array<Model.EAThreeYearPlanObjectives>;

  // Activities & Outcomes
  workplan_activities: Array<Model.EAWorkplanActivity>;

  // Funds Evaluation
  funds_evaluation: string;
}

export interface IThreeYearPlanObjectives extends IEffortArea {
  // title, description only
}

export class ThreeYearPlan {

  /**
   * Returns target year's three year plan.
   * @static
   * @param {number} target_duration_id
   * @param {Array<Model.ThreeYearPlan>} threeYearPlans
   * @returns {*} ThreeYearPlan
   */
  static targetYearThreeYearPlan(target_duration_id: number, threeYearPlans: Array<Model.EAThreeYearPlan>): Model.EAThreeYearPlan {
    const result = (threeYearPlans || []).sort((a, b) => a.duration_id > b.duration_id ? -1 : 1).find(plan => Utilities.between(target_duration_id - plan.duration_id, 0, 2));
    return result;
  }

  static isDraft(proposal, durationId): boolean {
    const plan = this.targetYearThreeYearPlan(durationId, proposal.three_year_plans);
    return ProposalBase.isAreaDraft(proposal, AREA_NAMES.THREE_YEAR_PLAN, plan && plan.duration_id);
  }

  static isCertified(proposal, durationId): boolean {
    const plan = this.targetYearThreeYearPlan(durationId, proposal.three_year_plans);
    return ProposalBase.isAreaCertified(proposal, AREA_NAMES.THREE_YEAR_PLAN, plan && plan.duration_id);
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type EAThreeYearPlan = IThreeYearPlan;
    export type EAThreeYearPlanObjectives = IThreeYearPlanObjectives;
  }
}
