<p class="margin-top-xxs text-info text--small" *ngIf="showNoResults()">{{emptyOptionsDisplayText}}</p>

<div class="type-ahead__results-container" *ngIf="showResultsList() && filteredOptions?.length">
  <ul #resultsScroller class="type-ahead__results-list">
    <ng-container *ngFor="let option of filteredOptions; let index = index; trackBy:trackById">
      <results-list-item #item class="type-ahead__results-list-item-host"
        (mousedown)="optionSelectedClick(item)"
        (mouseover)="handleMouseOver(item)"
        [searchValue]="input.control.value"
        [option]="option"
        [index]="index">
      </results-list-item>
    </ng-container>
  </ul>
</div>
