// NOTE: These empty-case definitions are only necessary for cases that use temp versions of the effort area.
//       In that case, we need to initialize arrays & objects, so you can push & assign to them.
//       Therefore, the empty defs probably just need to define the arrays & objects, not ALL of the null primitive attributes.
export const EFFORT_AREAS = {
  activity_outcomes: {
    description: null,
    input_labels: [],
    input_type: null,
    outcome_description: null,
  },
  capability: {
    experience: null,
    collaboration: null,
    leveraging_funds: null,
    identified_barriers: null,
    sustainability: null,
    has_paid_staff: null,
    needs_k12_coordinator: null,
    has_committee_member: null,
  },
  pathway_improvements: {
    activities: [],
    detailed_description: null,
  },
  statement_of_works: {
    activity_metrics: [],
    performance_outcomes: null,
    timeline: null,
    responsible_person: null,
  },
  application_contributions: {
    financial_contribution: null,
    in_kind_match: null,
    in_kind_match_type: null,
    ada_number: null,
    participating_schools: [],
    partner_provisions: []
  },
  activity_items: {
    related_metrics: []
  },
  plan_budget_items: {
    related_activities: []
  },
  workplan_objectives: {
    activities_outcomes: [],
    responsible_user: null,
    settings_objective_id: null,
    objective_type: null,
    evidence_type: null,
    evidence_description: null,
    success_metrics: [],
    metrics_description: null
  },
  workplan_activities: {
    objective_ea_id: null,
    start_date: null,
    end_date: null,
    success_metrics: [],
    vision_goal_metrics: [],
    responsible_user_ids: [],
    dependencies: null,
    regional_priority_sectors: [],
    related_categories: []
  },
  workplan_outcomes: {
    activity_ea_id: null,
    objective_ea_id: null,
    success_metrics: [],
    vision_goal_metrics: [],
    metrics_description: null,
  },
  workplan_practices: {
    practice_next_steps: undefined,
    adoption_scale: undefined,
    progress_to_date: undefined,
    support_needed: undefined,
    support_challenge: undefined,
    support_detail: undefined,
    support_types: undefined,
  },
  priority_sectors: {
    sector_id: null,
    top_code_ids: [],
    soc_code_ids: [],
    description: null,
  },
  practice_next_steps: {
    description: undefined,
    term_duration: undefined,
    implementation: undefined,
  },
  curriculum_topics: {
    hours: null,
  },
  work_processes: {
    hours: null,
  },
  wage_periods: {
    percent_of_wage: null,
    duration: null,
    ojt_hours: null,
    alt_wage_vacation: null,
    alt_wage_other: null,
    include_health: null,
    include_pension: null,
    include_training: null,
  },
  interview_categories: {
    weight: null,
  },
  apprenticeship_trades: {
    soc_code_id: null,
    rsi_cumulative_hours_per_year: null,
    rsi_cumulative_hours_for_occupation: null,
    term_of_apprenticeship_hours: null,
    term_of_apprenticeship_months: null,
    wages_basic_rate: null,
    wages_taxable_rate: null,
    wages_health: null,
    wages_pension: null,
    wages_vacation: null,
    wages_training: null,
    wages_other: null,
    period_advancement_criteria: null,
    wage_agreement_employers: null,
    overtime_criteria: null,
    curriculum_topics: [],
    work_processes: [],
    wage_periods: [],
  },
  population_groups: {
    metric_definition_id: null,
    impacted_group_id: null,
    custom_demographic: null,
    impacted_group_gender_id: null
  },
  guidances: {
    proposal_type: null,
    workflow_step: null,
  },
  budget_match_requirements: {
    institution_type: null,
    match_percent: null,
  },
  assurances: {
  },
  rsi_hourly_rate: {
    type: null,
    amount: null,
  },
  reporting_activity_items: {
    population_id: null,
    activity_item_id: null,
    category_ids: [],
    scale_of_adoption: null
  },
  program_activities: {
    related_metrics: [],
    activity_categories: []
  },
  vision_goals: {
    success_goal: null,
    success_metrics: []
  },
  match_reports: {
    financial_match_funds: [],
    inkind_match_funds: []
  },
  occupational_clusters: {
    subregions: [],
    counties: [],
    lmi_occupations: [],
    lmi_programs: []
  },
  survey_questions: {
    response_options: []
  },
  student_population_plan: {
    intended_audience: [],
    areas_of_completion: [],
    resources_needed: [],
    outcome_measurements: []
  },
  rsi_reports: {
    apprenticeship_programs: []
  },
  annual_plan_strategies: {
    metric_definitions: []
  }
};

/**
 * Ranges enforced within effort areas.
 */
export const EFFORT_AREA_RANGES = {
  MATCH_PERCENT_MIN: 0,
  MATCH_PERCENT_MAX: 500,
  BUDGET_LIMIT_PERCENT_MIN: 0,
  BUDGET_LIMIT_PERCENT_MAX: 100,
  PRIORITY_SECTORS_MIN: 5,
};

export const EFFORT_AREA_ID_TYPES = {
  LICENSE_NUMBER: 'License Number',
  EIN: 'EIN',
};
