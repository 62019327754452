<ng-container *ngIf="budgetItems?.length">
  <div *ngFor="let budgetItem of budgetItems; trackBy: trackById;">
    <allocated-budget-item [budgetItem]="budgetItem"
      [activityOptions]="activityOptions"
      [objectCodeOptions]="objectCodeOptions"
      [institutionOptions]="institutionOptions"
      [canEdit]="canEdit"
      [firstTouch]="firstTouch"
      [isPreview]="isPreview">
    </allocated-budget-item>
  </div>
</ng-container>

<div *ngIf="canEdit" class="btn-section btn-section--center">
  <button class="action-button action-button--primary icon--left" type="button"
    (click)="newBudgetItem()">
    <icon iconId="plus"></icon>
    New Budget Item
  </button>
</div>

<div *ngIf="expenditureForecast" [ngClass]="{'card': !isPreview }">
  <div *ngIf="!isPreview" class="card-title">
    <h3>{{ durationString }} Quarterly Expenditure Forecast</h3>
  </div>

  <div class="columns columns--5">
    <div class="column-start-1 column-end--2">
      <dl class="project-card__status-list align-left">
        <dt class="project-card__status-title">{{ durationString }} Amount</dt>
        <dd class="project-card__status-text">{{ +budgetTotal | currency:'USD':'symbol':'1.0-0' }}</dd>
      </dl>
    </div>

    <div class="column-start-2 column-end-6">
      <app-budget-forecast
        [canEdit]="canEdit"
        [firstTouch]="firstTouch"
        [isPreview]="isPreview"
        [isRequired]="budgetItems && budgetItems.length"
        [durationString]="durationString"
        [helpText]="forecastHelpText"
        [totalAmount]="budgetTotal"
        [fixedQ4]="true"
        [quarter_1]="expenditureForecast.quarter_1"
        [quarter_2]="expenditureForecast.quarter_2"
        [quarter_3]="expenditureForecast.quarter_3"
        [quarter_4]="expenditureForecast.quarter_4"
        (updateQuarter)="updateForecast($event)">
      </app-budget-forecast>
    </div>
  </div>
</div>
