<span #popUpTrigger class="pop-up--trigger">
  <ng-content select="[popUpTrigger]"></ng-content>
</span>

<div *ngIf="isOpen" #popUpContainer class="pop-up">
  <div class="pop-up__header">

    <ng-content select="[popUpHeader]"></ng-content>
    <!-- <h4 class="margin-bottom-none">{{header}}</h4> -->

    <button *ngIf="!hideClose" class="pop-up__header--close-button action-button" type="button"
      (click)="closePopUp()">
      <icon iconId="close"></icon>
    </button>
  </div>

  <ng-content select="[popUpContent]"></ng-content>
</div>
