import { State } from '@app-ngrx-domains';

// States managed by reducer; used also as initial states
State.Layout = {
  bookmark: {
    show: false
  },
  header: {
    actions: [],
    emit: null,
    isProfile: false,
    pre_title: '',
    show: false,
    showStatus: false,
    status: '',
    statusType: '',
    sub_title: '',
    title: '',
    titleHref: null,
    subTitleHref: null,
    resource: null,
    user: null
  },
  headerMessage: {
    message: '',
    type: '',
  },
  nav: {
    show: true,
  },
  page: {
    type: '',
  },
  spinner: {
    show: false,
  },
  logo: {
    show: true,
  },
  additionalHeaders: [],
  comments: {
    enabled: false,
    resource: null,
    isOpen: false,
    totalComments: 0,
  }
};

// Adds states definitions to ngrx-domains table.
declare module '@app-ngrx-domains' {

  export interface ILayoutState {
    bookmark: Model.Bookmark;
    header: Model.HeaderLayout;
    headerMessage: Model.StatusMessage;
    nav: Model.NavLayout;
    page: Model.PageLayout;
    spinner: Model.Spinner;
    logo: Model.Logo;
    additionalHeaders: Array<Model.AdditionalHeader>;
    comments: Model.CommentsSection;
  }

  interface State {
    Layout: ILayoutState;
  }
}
