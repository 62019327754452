<li *ngIf="isVisible" class="options-menu__menu__item" [class.options-menu__menu__item--danger]="danger">
  <ng-container [ngSwitch]="!!url || !!queryParams">
    <a *ngSwitchCase="true" class="options-menu__menu__item__link"
      [ngClass]="{ 'icon--left': !!iconId }" [routerLink]="url" [queryParams]="queryParams"
      (keydown)="handleKeydown($event)">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>

    <button *ngSwitchDefault type="button" class="options-menu__menu__item__link"
      [ngClass]="{ 'icon--left': !!iconId }"
      (click)="emit()"
      (keydown)="handleKeydown($event)">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </ng-container>
</li>

<ng-template #content>
  <ng-container [ngSwitch]="useContent">
    <ng-content *ngSwitchCase="true"></ng-content>
    <ng-container *ngSwitchDefault>
      <icon *ngIf="!!iconId" [iconId]="iconId"></icon>
      {{ optionText }}
    </ng-container>
  </ng-container>
</ng-template>