import * as _ from 'lodash';
import { Action } from '@ngrx/store';
import { Actions, Model, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { EnumErrorTypes } from '@app-models';

/**
 * Fiscal reports action types
 */
const ACTION_PREFIX = 'FISCAL_REPORTS_';
export const FISCAL_REPORTS_ACTION_TYPES = {
  GET_REPORTING_INFO: `${ACTION_PREFIX}GET_REPORTING_INFO`,
  GET_REPORTING_INFO_SUCCESS: `${ACTION_PREFIX}GET_REPORTING_INFO_SUCCESS`,
  UPSERT_FISCAL_REPORT: `${ACTION_PREFIX}UPSERT_FISCAL_REPORT`,
  UPSERT_FISCAL_REPORT_SUCCESS: `${ACTION_PREFIX}UPSERT_FISCAL_REPORT_SUCCESS`,
  COMPLETE_REPORTING_TASK: `${ACTION_PREFIX}COMPLETE_REPORTING_TASK`,
  NOTIFY_REPORTING_TASK: `${ACTION_PREFIX}NOTIFY_REPORTING_TASK`,
  UNDO_REPORTING_TASK: `${ACTION_PREFIX}UNDO_REPORTING_TASK`,
  UPDATE_REPORTING_TASK_SUCCESS: `${ACTION_PREFIX}UPDATE_REPORTING_TASKS_SUCCESS`,
  GET_REPORTING_INFO_FOR_INSTITUTION: `${ACTION_PREFIX}GET_REPORTING_INFO_FOR_INSTITUTION`,
  RESET: `${ACTION_PREFIX}RESET`,

  LOAD: `${ACTION_PREFIX}LOAD`,
  PUSH_STATUS: `${ACTION_PREFIX}PUSH_STATUS`,
  CLEAR_STATUS: `${ACTION_PREFIX}CLEAR_STATUS`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  NOOP: `${ACTION_PREFIX}NOOP`,
  SAVE_FISCAL_REPORT: `${ACTION_PREFIX}SAVE`,
  SAVE_FISCAL_REPORT_SUCCESS: `${ACTION_PREFIX}SAVE_SUCCESS`,

  GET_PROPOSAL: `${ACTION_PREFIX}GET_PROPOSAL`,
  GET_PROPOSAL_SUCCESS: `${ACTION_PREFIX}GET_PROPOSAL_SUCCESS`,
  SAVE_LINE_PROPOSAL: `${ACTION_PREFIX}SAVE_LINE_PROPOSAL`,
  SAVE_LINE_PROPOSAL_SUCCESS: `${ACTION_PREFIX}SAVE_LINE_PROPOSAL_SUCCESS`,
  PROPOSAL_STATUS: `${ACTION_PREFIX}SET_STATUS_PROPOSAL`
};

/**
 * Fiscal reports creator class
 */
export class FiscalReportsActions {

  /* Fiscal Reporting V2 */

  // isRefresh: if false, the current redux state is cleaned out while new reportInfo is being fetched
  getReportingInfo(proposalId: number, isRefresh?: boolean): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.GET_REPORTING_INFO,
      payload: { proposalId, isRefresh }
    }
  }

  getReportingInfoForInstitution(institutionId: number, fundId: number, isRefresh?: boolean): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.GET_REPORTING_INFO_FOR_INSTITUTION,
      payload: { institutionId, fundId, isRefresh }
    }
  }

  getReportingInfoSuccess(reportingInfo: Array<Model.FiscalReportInfo>): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.GET_REPORTING_INFO_SUCCESS,
      payload: reportingInfo
    }
  }

  upsertFiscalReport(fiscalReport: Model.FiscalReport, taskId: number): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.UPSERT_FISCAL_REPORT,
      payload: { fiscalReport, taskId }
    }
  }

  upsertFiscalReportSuccess(fiscalReport: Model.FiscalReport, taskId: number): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.UPSERT_FISCAL_REPORT_SUCCESS,
      payload: { fiscalReport, taskId }
    }
  }

  notifyReportingTask(task: any): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.NOTIFY_REPORTING_TASK,
      payload: task
    }
  }

  completeReportingTask(task: any, refresh = true): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.COMPLETE_REPORTING_TASK,
      payload: { task, refresh }
    }
  }

  undoReportingTask(task: Model.Task | Model.FiscalReportInfo): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.UNDO_REPORTING_TASK,
      payload: task
    }
  }

  updateReportingTaskSuccess(task: Model.Task, oldId: number): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.UPDATE_REPORTING_TASK_SUCCESS,
      payload: { task, oldId }
    }
  }

  /**
   * Resets fiscal reporting redux storage.
   */
  reset(): Action {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.RESET,
    };
  }

  /* Fiscal Reporting V1 */

  /**
   * Loads fiscal reports.
   * @param fiscalReports
   */
  load(fiscalReports: Array<Model.FiscalReport>): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.LOAD,
      payload: fiscalReports,
    };
  }

  /**
   * Load fiscal reports for a proposal id
   * @param {number} proposal_id
   * @param {string} fund_key
   * @param {boolean} multi - load multiple reports
   * @param {number} duration_id - if multi is true, select a duration_id to load as the main report
   */
  getForProposal(proposal_id: number, fund_key: string, multi: boolean = false, duration_id: number = undefined): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.GET_PROPOSAL,
      payload: { proposal_id, fund_key, multi, duration_id }
    }
  }

  getForProposalSuccess(report: Model.FiscalReport): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.GET_PROPOSAL_SUCCESS,
      payload: report
    }
  }

  /**
   * Save a fiscal report line, modifying the proposal fiscal report state
   * @param {Model.FiscalReportLine} line
   */
  saveProposalLine(line: Model.FiscalReportLine): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.SAVE_LINE_PROPOSAL,
      payload: { line, report_id: line.fiscal_report_id},
    }
  }

  saveProposalLineSuccess(result: Model.FiscalReportLine): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.SAVE_LINE_PROPOSAL_SUCCESS,
      payload: result,
    }
  }

  /**
   * Loads the statuses of a set of fiscal reports (Q1 - Q4)
   *
   * @param {Model.FiscalReportStatus} reportStatus
   * @returns {Action}
   */
  pushStatus(reportStatus: Model.FiscalReportStatus): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.PUSH_STATUS,
      payload: reportStatus,
    };
  }

  /**
   * Clears the list of report statuses.
   */
  clearStatus(): Action {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.CLEAR_STATUS
    };
  }

  /**
   * Saves a fiscal report.
   */
  save(fiscalReport: Model.FiscalReport): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.SAVE_FISCAL_REPORT,
      payload: fiscalReport
    };
  }

  /**
   * Successfully saved fiscal report.
   */
  saveSuccess(response): ActionWithPayload<any> {
    return {
      type: FISCAL_REPORTS_ACTION_TYPES.SAVE_FISCAL_REPORT_SUCCESS,
      payload: response,
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: FISCAL_REPORTS_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.FiscalReports = new FiscalReportsActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    FiscalReports: FiscalReportsActions;
  }
}
