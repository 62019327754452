import { forkJoin, concat, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { State, Queries, Actions as DomainActions } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { ENTITIES_ACTION_TYPES } from './entities.action';
import { FUND_TYPES } from '@app-consts';

/**
 * Container class for current entity effects calls.
 */
@Injectable()
export class EntitiesEffects {

  /**
   * Creates an instance of effects class.
   * @param {Actions} actions$
   * @param {ApiService} service
   * @param {Store} store
   */
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>,
  ) {
  }

  /**
   * Updates entity's info.
   */
   updateInfo$ = createEffect(() => this.actions$.pipe(
    ofType(ENTITIES_ACTION_TYPES.UPDATE_INFO),
    map(toPayload),
    mergeMap(req => {
      // persist changes.
      return this.apiService.updateInstitution(req.info).pipe(
        map(() => DomainActions.Entities.updateInfoSuccess(req)),
        catchError((error) => of(DomainActions.Entities.serviceFail(error)))
      );
    })
  ));

  /**
   * Associates institution as a new member agency.
   */
   associateMember$ = createEffect(() => this.actions$.pipe(
    ofType(ENTITIES_ACTION_TYPES.ADD_MEMBER),
    map(toPayload),
    mergeMap(req => {
      // adding a member requires: (1) adding a proposals_institutions record, (2) submit task record, & (3) allocation record
      const member = {
        institution_id: req.memberInstitutionId,
        proposal_id: req.leadProposalId,
        duration_id: req.duration_id,
      };

      const allocation = {
        amount: 0,
        type: 'initial',
        from_institution_id: req.leadInstitutionId,
        to_institution_id: member.institution_id,
        fund_id: FUND_TYPES.AEBG,
        duration_id: member.duration_id,
      };

      // start the spinner... will take bit of time.
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));

      // create the member & allocation
      return forkJoin(
        this.apiService.addMember(member, req.voting_only),
        req.voting_only ? of(null) : this.apiService.createAllocation(allocation)).pipe(
          mergeMap(data => {
            const [__, resAllocation] = data;

            // now go ahead read the member
            return this.apiService.getMember(member).pipe(mergeMap((resMember: any) => {
              // bundle actions to run sequentially.
              const actions: Action[] = [];
              actions.push(DomainActions.Entities.addMemberSuccess(resMember));
              if (!req.voting_only) {
                // ...fills in to_instituion object from proposal's lead institution.
                resAllocation.to_institution = { ...resMember.institution };
                actions.push(DomainActions.Allocations.addMember(resAllocation));
              }
              actions.push(DomainActions.CurrentProposal.upsertInstitutionSuccess(resMember.institution, {is_lead: false, is_employer: false}));

              // Dispatch actions. These are executed in order.
              return concat([
                ...actions,
                DomainActions.Layout.showBusySpinner(false),
              ]);
            }));
      }));
    }),
    catchError((error) => {
      // stop the spinner.. encountered an error.
      this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
      return of(DomainActions.Entities.serviceFail(error));
    })
  ));

  /**
   * Removes member agency.
   */
   removeMember$ = createEffect(() => this.actions$.pipe(
    ofType(ENTITIES_ACTION_TYPES.REMOVE_MEMBER),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Allocations.getMembers)),
    mergeMap(data => {
      const [req, memberAllocations] = data;
      // find the allocation to delete.
      const allocation_years = memberAllocations[req.memberInstitutionId];
      const allocations = allocation_years && allocation_years[req.duration_id] ? allocation_years[req.duration_id] : [];

      // show busy spinner...
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));

      const allocation_deletions = allocations.map(allocation => this.apiService.deleteAllocation({ id: allocation.id }));
      return forkJoin(
        ...allocation_deletions,
        this.apiService.removeMember({
          institution_id: req.memberInstitutionId,
          proposal_id: req.leadProposalId,
          duration_id: req.duration_id,
        })
      ).pipe(mergeMap(() => {
        const actions: Action[] = [];
        actions.push(DomainActions.Entities.removeMemberSuccess(req));
        actions.push(DomainActions.Allocations.removeMember(req));
        actions.push(DomainActions.CurrentProposal.removeInstitutionSuccess(req.memberInstitutionId));

        // Dispatch actions. These are executed in order.
        return concat([
          ...actions,
          DomainActions.Layout.showBusySpinner(false),
        ]);
      }));
    }),
    catchError((error) => {
      // stop the spinner.. encountered an error.
      this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
      return of(DomainActions.Entities.serviceFail(error));
    })
  ));

  /**
   * Creates new member agency.
   */
   createMember$ = createEffect(() => this.actions$.pipe(
    ofType(ENTITIES_ACTION_TYPES.ADD_NEW_MEMBER),
    map(toPayload),
    mergeMap(req => {
      // show busy spinner...
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      // create the institution first.
      return this.apiService.createInstitution(req.memberInstitution).pipe(
        mergeMap((res: any) => {
          // then associate created institution as a member agency.
          return concat([
            DomainActions.Entities.associateMember({
              leadProposalId: req.leadProposalId,
              leadInstitutionId: req.leadInstitutionId,
              duration_id: req.duration_id,
              memberInstitutionId: res.id,
              voting_only: req.voting_only
            }),
            DomainActions.Layout.showBusySpinner(false),
          ]);
        }),
        catchError((error) => {
          // stop busy spinner...
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(DomainActions.Entities.serviceFail(error));
        }));
    })
  ));

   refreshMember$ = createEffect(() => this.actions$.pipe(
    ofType(ENTITIES_ACTION_TYPES.REFRESH_MEMBER),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Entities.getCurrentMember)),
    mergeMap(([payload, member]) => {
      return this.apiService.getMember(member).pipe(
        mergeMap(res => {
          // bundle actions.
          const actions: Action[] = [];
          // refresh member.
          if (res.tasks) {
            res.tasks = res.tasks.filter(task => task.duration_id === member.duration_id);
          }
          actions.push(DomainActions.Entities.addMemberSuccess(res));
          if (payload.gotoUrl) {
            // goto requested route.
            actions.push(DomainActions.App.go([payload.gotoUrl]));
          }

          // Dispatch actions. These are executed in order.
          return concat([
            ...actions
          ]);
        }
      ));
    })
  ));

   setMemberVotingStatus$ = createEffect(() => this.actions$.pipe(
    ofType(ENTITIES_ACTION_TYPES.SET_VOTING_ONLY_STATUS),
    map(toPayload),
    withLatestFrom(
      this.store.select(Queries.Allocations.getMembers),
      this.store.select(Queries.Entities.getLeadInfo)
    ),
    mergeMap(([data, memberAllocations, leadInfo]) => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      const member = {
        proposal_id: data.proposal_id,
        institution_id: data.institution_id,
        duration_id: data.duration_id
      };
      const voting_only = data.voting_only;
      let allocation_observables = [];

      if (voting_only) {
        // We need to clear allocations given to the member
        const allocations = memberAllocations[member.institution_id][member.duration_id];
        allocation_observables = allocations.map(allocation => this.apiService.deleteAllocation({ id: allocation.id }));
      } else {
        const allocation = {
          amount: 0,
          type: 'initial',
          from_institution_id: leadInfo.institution_id,
          to_institution_id: member.institution_id,
          fund_id: FUND_TYPES.AEBG,
          duration_id: member.duration_id
        };
        allocation_observables = [this.apiService.createAllocation(allocation)];
      }

      return forkJoin(
        this.apiService.updateMemberVotingStatus(member, voting_only),
        ...allocation_observables
      ).pipe(mergeMap((res) => {
        const member_row = res[0];
        const actions: Action[] = [];
        if (voting_only) {
          actions.push(DomainActions.Allocations.removeMember({ memberInstitutionId: member.institution_id, duration_id: member.duration_id }));
        } else {
          actions.push(DomainActions.Allocations.addMember(res[1]));
        }
        actions.push(DomainActions.Entities.addMemberSuccess(member_row));
        this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
        return concat(actions);
      }));
    })
  ));

   updateMemberState$ = createEffect(() => this.actions$.pipe(
    ofType(ENTITIES_ACTION_TYPES.UPDATE_MEMBER_CLIENT_STATE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Entities.getCurrentMember)),
    mergeMap(data => {
      const [__, member] = data;
      const taskId = member.client_state.task_id;
      const client_state = {
        ...member.client_state,
        ...__,
      };
      delete client_state.task_id;

      return this.apiService.updateTask(taskId, client_state).pipe(mergeMap(
        res => {
          return of(DomainActions.Entities.updateMemberStateSuccess(res));
        }
      ));
    })
  ));
}
