<header>
  <ng-container [ngSwitch]="loggedIn$ | async">
    <ng-container *ngSwitchCase="true">
      <div class="content-header__row user-header v2-styles">
        <div id="qa_headerPane_projectTitle_text" class="title-header__titles-container">
          <div id="qa_header_preTitle" class="title-header__program-title">
            {{ headerInfo.programTitle }}
          </div>

          <div id="qa_header_title" class="title-header__route-title">
            {{ headerInfo.routeTitle }}
            <ng-container *ngIf="headerInfo.grant">
              <span class="title-header__grant-label">
                ({{ grantTitle.term }}:
                <span class="title-header__grant-text">
                  {{ grantTitle.name }}<ng-container *ngIf="grantTitle.id"> [ID: {{ grantTitle.id }}]</ng-container>
                </span>)
              </span>
            </ng-container>
          </div>
        </div>

        <p class="media-print">
          Produced: {{timeStamp()}}
          <span class="user" *ngIf="profileName$"> - By {{profileName$ | async}}</span>
        </p>

        <div class="flex flex--align-items-center no-print">
          <div class="notifications-menu-icon">
            <options-menu iconId="notification" class="v2-icon-button" listClass="notifications-menu">
              <options-menu-item optionText="View All Notifications" routerLink="/notifications" [useContent]="true">
                <div class="notifications-menu-header v2-styles card-title">
                  <span>NOTIFICATIONS</span>
                  <span>See all</span>
                </div>
              </options-menu-item>
              <hr *ngIf="unreadMessages.length" class="no-margin">
              <options-menu-item *ngFor="let message of unreadMessages"
                [optionText]="message.message_title"
                routerLink="/notifications"
                [queryParams]="{ messageId: message.id }"
                [useContent]="true">

                <div class="flex flex--align-items-center margin-right-xs">
                  <div class="notification-status"></div>
                </div>

                <div class="action-list-item__content">
                  <div class="action-list-item__title" id="qa_action_list_item_title">
                    <span class="text-ellipsis text-ellipsis--clamp-2">{{ message.message_title }}</span>
                  </div>
                </div>

                <div class="subtitle subtitle--small margin-right-sm">
                  {{ message.created_at | po_date }}
                </div>

              </options-menu-item>
            </options-menu>
            <div class="notification-status" [ngClass]="{ 'notification-status--viewed': !unreadMessages.length }"></div>
          </div>


          <options-menu iconId="disclosure-down" class="user-options">
            <div slot="label" class="flex flex--align-items-center user-label">
              <icon iconId="avatar-shield"></icon>
              <span class="user-name margin-left-xs">{{profileName$ | async}}</span>
            </div>
            <options-menu-item optionText="Profile" routerLink="/profile"></options-menu-item>
            <options-menu-item optionText="Logout" (doEmit)="logout()"></options-menu-item>
          </options-menu>
        </div>
      </div>

      <div *ngIf="headerInfo.title" class="content-header__row title-header">
        <div class="title-header__titles flex flex--column">
          <div class="title-header__title-container">
            <ng-container [ngSwitch]="!!headerInfo.titleHref">
              <span *ngSwitchCase="true" id="qa_header_title" class="title-header__title h4 title-header__title--link">
                <a [routerLink]="headerInfo.titleHref.href" [queryParams]="headerInfo.titleHref.queryParams || {}">{{headerInfo.title}}</a>
              </span>

              <span *ngSwitchCase="false" id="qa_header_title" class="title-header__title h4">
                {{ headerInfo.title }}
              </span>
            </ng-container>

            <dashboard-filter *ngIf="showDashboardFilters && headerInfo.title === 'Dashboard'"></dashboard-filter>

            <app-user-actions *ngIf="userData && isProfile"
              [isProfile]="isProfile"
              [userData]="userData"
              [menuText]="userActionsSubtitle.userName"
              [prefix]="userActionsSubtitle.prefix"
              [suffix]="userActionsSubtitle.suffix">
            </app-user-actions>

            <div class="flex flex--align-items-center">
              <ng-container *ngIf="isBookmarkable" [ngSwitch]="activeBookmark">
                <button *ngSwitchCase="true" title="Remove Bookmark"
                        id="qa_header_bookmark_button" class="action-button action-button--secondary action-button--small title-header__bookmarker no-print"
                        [attr.aria-label]="'Remove Bookmark'"
                        (click)="removeBookmark()">
                  <icon iconId="bookmark-fill"></icon>
                </button>

                <button *ngSwitchCase="false" title="Add Bookmark"
                        id="qa_header_bookmark_button" class="action-button action-button--secondary action-button--small title-header__bookmarker no-print"
                        [attr.area-label]="'Add Bookmark'"
                        (click)="addBookmark()">
                  <icon iconId="bookmark-empty"></icon>
                </button>
              </ng-container>
              <div class="margin-left-sm">
                <div *ngIf="headerInfo.status" id="qa_header_workflow_status" class="title-header__status">
                  {{ headerInfo.status | uppercase }}
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="headerInfo.sub_title" [ngSwitch]="subTitleCase">
            <div *ngSwitchCase="'withUserData'" id="qa_header_subtitle" class="title-header__sub-title">
              <p *ngIf="!userData">{{headerInfo.sub_title}}</p>
              <app-user-actions *ngIf="userData"
                [isProfile]="isProfile"
                [userData]="userData"
                [menuText]="userActionsSubtitle.userName"
                [prefix]="userActionsSubtitle.prefix"
                [suffix]="userActionsSubtitle.suffix">
              </app-user-actions>
            </div>

            <a *ngSwitchCase="'withHref'" id="qa_header_subtitle" [routerLink]="headerInfo.subTitleHref.href" [queryParams]="headerInfo.subTitleHref.queryParams || {}" class="title-header__sub-title title-header__title--link">
              {{ headerInfo.sub_title }}
            </a>

            <p *ngSwitchDefault id="qa_header_subtitle" class="title-header__sub-title">
              <span>{{ headerInfo.sub_title }}</span>
            </p>
          </ng-container>

          <nav *ngIf="!!headerInfo.breadcrumbs" class="breadcrumbs" aria-label="breadcrumb">
            <ol class="breadcrumbs__list">
              <ng-container *ngFor="let crumb of headerInfo.breadcrumbs">
                <li *ngIf="crumb.route" class="breadcrumbs__item"><a [routerLink]="crumb.route">{{crumb.title}}</a></li>
                <li *ngIf="!crumb.route" class="breadcrumbs__item">{{crumb.title}}</li>
              </ng-container>
            </ol>
          </nav>
        </div>

        <div *ngIf="(!printMode && (headerInfo?.actions?.length || isBookmarkable)) || showHeaderMessage()" class="title-header__status-actions no-print">
          <div *ngIf="!printMode && (headerInfo?.actions?.length || isBookmarkable)" class="title-header__actions">
            <app-action-bar [actions]="headerInfo.actions"></app-action-bar>
          </div>

          <div class="title-header__messages" *ngIf="showHeaderMessage()">
            <div *ngIf="headerMessage.type === 'loading'" class="tool-tray-status fadeInRight">
              <p data-qa="app_action_loading_text" class="message">
                {{headerMessage.message ? headerMessage.message : 'loading'}}
                <span class="loader-spinner-inline"></span>
              </p>
            </div>

            <div *ngIf="headerMessage.type === 'success'" class="tool-tray-status fadeInRight">
              <icon iconId="checkmark" class="icon--block icon--positive"></icon>
              <p data-qa="app_action_confirmation_text" class="message">{{headerMessage.message ? headerMessage.message : 'Success!'}}</p>
            </div>

            <div *ngIf="headerMessage.type === 'fail'" class="tool-tray-status fadeInRight">
              <icon iconId="alert" class="icon--block icon--negative"></icon>
              <p data-qa="app_action_failure_text" class="message">{{headerMessage.message ? headerMessage.message : 'There was an error, please try again.'}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="title-header content-header__row" *ngFor="let header of additionalHeaders; trackBy: trackByHeaderName">
        <ng-container *ngTemplateOutlet="header.template; context: { $implicit: header.data }"></ng-container>
      </div>
    </ng-container>

    <div *ngSwitchDefault class="content-header">
      <div [ngSwitch]="showHeaderLink()" class="content-header__branding">
        <a *ngSwitchCase="true" [routerLink]="['/']" aria-label="Go to Login">
          <img src="/assets/images/{{logo.src}}" alt="{{logo.altText}}"/>
        </a>

        <img *ngSwitchCase="false" src="/assets/images/{{logo.src}}" alt="{{logo.altText}}" id="qa_login_nova_logo"/>
      </div>
    </div>
  </ng-container>
</header>