import { Model } from '@app-ngrx-domains';

export interface IResourceState {
    resource_id?: number;
    state_name: string;
    duration_id?: number;
    institution_id?: number;
    created_at?: string;
    updated_at?: string;
}

export interface IResourceStateExtended extends IResourceState, Model.Resource {
}

export class ResourceState implements IResourceState {
    resource_id?: number;
    state_name: string;
    duration_id?: number;
    created_at?: string;
    updated_at?: string;

    constructor(raw) {
        if (raw) {
            this.resource_id = raw.resource_id || null;
            this.state_name = raw.state_name || null;
            this.duration_id = raw.duration_id || null;
            this.created_at = raw.created_at || null;
            this.updated_at = raw.updated_at || null;
        }
    }
}

declare module '@app-ngrx-domains' {
    export namespace Model {
        export type ResourceState = IResourceState;
        export type ResourceStateExtended = IResourceStateExtended
    }
}
