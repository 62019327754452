<!-- Content -->
<collapsible-card [hideCollapseButton]="!isPreview">
  <div class="card-title">
    <h3>{{title}}</h3>
    <div *ngIf="!isPreview" class="help-text">
      <p>
        {{ helperText }}
      </p>
    </div>
  </div>

  <p *ngIf="!guidanceEAs?.length" class="empty-state">No Guidances have been added</p>
  <ng-container
    [ngTemplateOutlet]="guidanceList"
    [ngTemplateOutletContext]="{guidances: guidanceEAs}">
  </ng-container>
</collapsible-card>

<ng-template #guidanceList let-guidances="guidances">
  <div *ngFor="let guidance of guidances; let first = first; trackBy: trackByKey" [ngSwitch]="guidance.isWorkflow">
    <ng-container *ngSwitchCase="true">
      <div class="card">
        <div class="card-title">
          <h4>{{guidance.workflowTitle}}</h4>
        </div>
        <ng-container
          [ngTemplateOutlet]="guidanceList"
          [ngTemplateOutletContext]="{guidances: guidance.guidances}">
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault
      [ngTemplateOutlet]="guidanceCard"
      [ngTemplateOutletContext]="{guidance: guidance}">
    </ng-container>
  </div>
</ng-template>

<ng-template #guidanceCard let-guidance="guidance">
  <guidance-card
    [canEdit]="canEdit"
    [firstTouch]="firstTouch"
    [isPreview]="isPreview"
    [isRevertible]="isRevertible"
    [required]="required"
    [guidanceEA]="guidance"
    [parentEffortAreas]="parentEffortAreas">
  </guidance-card>
</ng-template>