<ng-container *ngIf="(initialized$ | async)" [ngSwitch]="!isPreview">
  <!-- Edit -->
  <ng-container *ngSwitchCase="true">
    <h2 class="header-title h2 mb-l" id="qa_tab_title">Forecast</h2>
    <po-guidance-text [workflowFilter]="guidance" fieldName="guidance"></po-guidance-text>

    <div *ngIf="yearForm" [formGroup]="yearForm" class="segmented-control segmented-control--stand-alone margin-bottom">
      <ul class="segmented-control__list" role="radiogroup">
        <li *ngFor="let year of yearOptions; let i=index;" class="segmented-control__item">
          <input #radio id="budget_year_option_{{i}}" class="segmented-control__input"
            formControlName="selectedYear" [value]="year.id"
            type="radio"/>
          <label for="budget_year_option_{{i}}" class="segmented-control__label"
            [attr.aria-checked]="radio.checked" attr.aria-label="View budget items for {{year.name}}">
            {{year.name}}
          </label>
        </li>
      </ul>
    </div>

    <div *ngIf="!displayedCards?.length" class="card">
      <p class="empty-state margin-bottom-none">No budgets have been created for this year.</p>
    </div>

    <div *ngFor="let card of displayedCards; trackBy: trackByCard" class="card">
      <div class="card-title">
        <h3>{{getCardName(card)}} Expenditure Forecast</h3>
      </div>

      <div class="columns columns--5 no-margin">
        <dl class="column-start-1 column-end-2 margin-top-none">
          <dt class="project-card__status-title">Budget Amount</dt>
          <dd class="project-card__status-text">{{+card.budgetTotal | currency:'USD':'symbol':'1.0'}}</dd>
        </dl>

        <div class="column-start-2 column-end-6">
          <app-budget-forecast *ngIf="!!card.forecast"
            [canEdit]="canEdit"
            [firstTouch]="false"
            [durationString]="card.year.name"
            [totalAmount]="card.budgetTotal"
            [fixedQ4]="true"
            [count]="getCardName(card)"
            [quarter_1]="card.forecast.quarter_1"
            [quarter_2]="card.forecast.quarter_2"
            [quarter_3]="card.forecast.quarter_3"
            [quarter_4]="card.forecast.quarter_4"
            [helpText]="false"
            (updateQuarter)="updateForecast($event, card)">
          </app-budget-forecast>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Preview -->
  <ng-container *ngSwitchCase="false">
    <div class="card">
      <div class="card-title">
        <h3>Forecast</h3>
      </div>

      <div *ngFor="let year of yearOptions">
        <ng-container *ngIf="forecastsByYear[year.id]?.length">
          <h4>{{year.name}} Expenditure Forecasts</h4>

          <ng-container *ngFor="let card of forecastsByYear[year.id]; trackBy: trackByCard">
            <app-budget-forecast *ngIf="!!card.forecast"
              [isPreview]="isPreview"
              [durationString]="getCardName(card)"
              [totalAmount]="card.budgetTotal"
              [fixedQ4]="true"
              [quarter_1]="card.forecast.quarter_1"
              [quarter_2]="card.forecast.quarter_2"
              [quarter_3]="card.forecast.quarter_3"
              [quarter_4]="card.forecast.quarter_4"
              [helpText]="false">
            </app-budget-forecast>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
