import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Model, Queries, State } from '@app-ngrx-domains';
import { ApiService, PermissionsService } from '@app-services';
import { ROUTER_LINKS } from '@app/core/consts';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html'
})

export class UserActionsComponent implements OnDestroy, OnInit {
  @HostBinding('class') class = 'user-actions';
  @Input() altButton: boolean = true;
  @Input() count: number = 0;
  @Input() isProfile: boolean = false;
  @Input() isUserManagement: boolean = false;
  @Input() menuClass: string = 'user-actions__container';
  @Input() menuText: string = '';
  @Input() prefix: string = '';
  @Input() suffix: string = '';
  @Input() useMailLink: boolean = false;
  @Input() userData: Model.User;
  @Output() changedAction: EventEmitter<null> = new EventEmitter();

  private destroy$: Subject<boolean> = new Subject();

  canSudo: boolean = false;
  showDeleteUserAlert: boolean = false;
  showResetUserAlert: boolean = false;
  showRestoreUserAlert: boolean = false;
  showMenu: boolean = false;
  ariaLabel: string;

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private permissionsService: PermissionsService,
    private router: Router,
    private store: Store<State>,
    private logger: NGXLogger
  ) { }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  ngOnInit() {
    // Combine these strings for screen readers
    this.ariaLabel = `${this.prefix} ${this.menuText} ${this.suffix}`.trim();

    this.store.select(Queries.Auth.canSudo).pipe(
      take(1)
    ).subscribe(canSudo => {
      setTimeout(() => {
        try {
          this.canSudo = canSudo;
          this.cdr.detectChanges();
        } catch (err) {
          this.logger.warn('[user-actions] Failed to DetectChanges');
        }
      }, 0);
    });

    this.showMenu = this.hasMenuItems;
  }

  /**
   * Returns true if there is at least one menu item to show.
   */
  private get hasMenuItems(): boolean {
    if (this.canShowMenu('delete')) {
      return true;
    }

    if (this.canShowMenu('reset')) {
      return true;
    }

    if (this.canShowMenu('restore')) {
      return true;
    }

    if (this.canShowMenu('sudo')) {
      return true;
    }

    if (this.canShowMenu('view')) {
      return true;
    }

    return false;
  }

  /**
   * Returns true if the user is a deleted data.
   */
  private get isDeleted(): boolean {
    return this.userData.status === 'deleted';
  }

  public get userFullName(): string {
    return `${this.userData.first_name} ${this.userData.last_name}`;
  }

  public get userProfileLink(): string {
    return `/admin/users/${this.userData.id}/profile`;
  }

  /**
   * Returns true if given menu item can be shown.
   * @param emitType
   */
  public canShowMenu(emitType: string): boolean {
    if ((!this.isUserManagement && !this.isProfile) && (emitType === 'delete' || emitType === 'restore')) {
      return false;
    }
    if (this.isDeleted) {
      // deleted user should only be allowed to be restored.
      return emitType === 'restore';
    } else if (emitType === 'sudo') {
      return this.canSudo;
    } else if (emitType === 'restore') {
      // can't restore (in)active user.
      return false;
    } else if (emitType === 'view') {
      return !this.isProfile;
    } else {
      return true;
    }
  }

  public confirmDeleteUser() {
    this.apiService.deleteProfileById(this.userData.id).subscribe(result => {
      // let parent know of the change
      this.changedAction.emit();
        // Display success message.
      this.store.dispatch(Actions.App.showInfo(`Successfully deleted user '${this.userFullName}'.`));
    }, err => this.store.dispatch(Actions.App.showAlert(`Failed to delete user '${this.userFullName}'.`)));
    this.showDeleteUserAlert = false;
  }

  public confirmResetUser() {
    this.apiService.passwordReset(this.userData.email_address).subscribe(result => {
      // display success
      this.store.dispatch(Actions.App.showInfo(`Successfully reset password for user '${this.userFullName}'.`));
    }, err => this.store.dispatch(Actions.App.showAlert(`Failed to reset password for user '${this.userFullName}'.`)));
    this.showResetUserAlert = false;
  }

  public confirmRestoreUser() {
    this.apiService.restoreProfileById(this.userData.id).subscribe(result => {
      // let parent know of the change
      this.changedAction.emit();
      // display success and reapply filters
      this.store.dispatch(Actions.App.showInfo(`Successfully restored user '${this.userFullName}'.`));
    }, err => this.store.dispatch(Actions.App.showAlert(`Failed to restore user '${this.userFullName}'.`)));
    this.showRestoreUserAlert = false;
  }

  public doEvent(action: string) {
    if (action === 'delete') {
      this.deleteUserAlert();
    } else if (action === 'reset') {
      this.resetUserAlert();
    } else if (action === 'restore') {
      this.restoreUserAlert();
    } else if (action === 'sudo') {
      this.sudoLoginUser(this.userData);
    }
  }

  public deleteUserAlert() {
    this.showDeleteUserAlert = true;
  }

  public dismissDeleteUserAlert() {
    this.showDeleteUserAlert = false;
  }

  public dismissResetUserAlert() {
    this.showResetUserAlert = false;
  }

  public dismissRestoreUserAlert() {
    this.showRestoreUserAlert = false;
  }

  public resetUserAlert() {
    this.showResetUserAlert = true;
  }

  public restoreUserAlert() {
    this.showRestoreUserAlert = true;
  }

  sudoLoginUser(user: any) {
    let redirect;
    if (this.isProfile) {
      redirect = ROUTER_LINKS.PROFILE;
    } else if (this.isUserManagement) {
      redirect = ROUTER_LINKS.HOME;
    }

    this.store.dispatch(Actions.Auth.sudoLogin(user.id, redirect));
  }
}
