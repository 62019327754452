<div class="status-card status-card--{{level}}"  [ngClass]="{ 'status-card--small': small, 'status-card--smaller': smaller }" [attr.aria-expanded]="expanded">
  <div *ngIf="!noIcon" class="status-card__icon">
    <icon [iconId]="iconId"></icon>
  </div>

  <div class="status-card__body" #contentWrapper>
    <ng-content></ng-content>
  </div>

  <button
    *ngIf="collapsible"
    class="action-button action-button--tertiary status-card__trigger"
    [ngClass]="{ 'action-button--small': small || smaller }"
    type="button" [title]="expanded ? 'Collapse Card' : 'Expand Card'"
    (click)="toggleExpand()">
    <icon iconId="disclosure"></icon>
  </button>

  <button
    *ngIf="!!dismissLabel"
    type="button"
    [textContent]="dismissLabel"
    (click)="closeAlert()"
    class="action-button action-button--secondary margin-left-sm"
    [ngClass]="{ 'action-button--small': small || smaller }"
    id="qa_inline_alert_close_button">
  </button>

  <ng-container *ngIf="!!actionLabel" [ngSwitch]="!!actionLink">
    <button
      *ngSwitchCase="false"
      type="button"
      [textContent]="actionLabel"
      (click)="doAction()"
      class="action-button action-button--secondary margin-left-sm"
      [ngClass]="{ 'action-button--small': small || smaller }"
      id="qa_inline_alert_action_button">
      <div class="loader-spinner-inline"></div>
    </button>

    <a *ngSwitchCase="true" class="action-button action-button--secondary margin-left-sm"
      [ngClass]="{ 'action-button--small': small || smaller }"
      [routerLink]="actionLink">
      {{actionLabel}}
    </a>
  </ng-container>

  <ng-content select="a"></ng-content>
</div>
