<hr *ngIf="isPreview && !isFirst">

<div [ngClass]="{'card': !isPreview }">
  <div *ngIf="!isPreview" class="card-title">
    <h3>{{objectiveEA.title || "Objective Title"}}</h3>
    <div *ngIf="!!helpText['card']" class="help-text">
      <p [innerHTML]="helpText['card'] | safeHtml"></p>
    </div>
  </div>

  <h4 *ngIf="isPreview && showTitleInPreview">{{objectiveEA.title || "Objective Title"}}</h4>

  <form *ngIf="form" [formGroup]="form" [ngClass]="{'first-touch': firstTouch}" autocomplete="off">

    <div class="columns columns--2 no-margin">
      <input-field *ngIf="collectObjectiveName" [showCounter]="false" labelText="Objective Name" [canEdit]="canEdit">
        <input inputRef id="objective_name_input_{{uniqueId}}" type="text" maxlength="50" formControlName="title" (blur)="persistValue('title')" required  aria-required="true"/>
      </input-field>
      <po-select *ngIf="collectObjectiveType" id="objective_type_select_{{uniqueId}}"
        labelText="Objective Type"
        [canEdit]="canEdit"
        [options]="objectiveTypeOptions"
        [selectOnce]="true"
        (optionSelected)="persistValue('objective_type')">
        <select inputRef id="objective_type_select_{{uniqueId}}" class="po-select__select" formControlName="objective_type" aria-required="true" required></select>
      </po-select>
    </div>

    <input-field labelText="Description of Objective" [canEdit]="canEdit">
      <div *ngIf="!!helpText['description'] && !isPreview" class="help-text">
        <p>{{helpText['description']}}</p>
      </div>
      <po-html-textarea inputRef id="objective_description_text_area_{{uniqueId}}" formControlName="description"
        [htmlCharLimit]="descriptionLength"
        (change)="persistValue('description')"
        required aria-required="true"></po-html-textarea>
    </input-field>

    <div *ngIf="isCAIv2" class="columns columns--2 no-margin">
      <po-select id="{{count}}_metrics_select"
          labelText="Select Student Success Metrics that Apply to this Objective"
          [canEdit]="canEdit"
          [options]="metricsOptions"
          [selectMultiple]="true"
          (optionSelected)="addSuccessMetric($event)"
          (optionRemoved)="removeSuccessMetric($event)">
          <select inputRef class="po-select__select" formControlName="success_metrics"></select>
      </po-select>

      <input-field labelText="Description of Metric(s)" [canEdit]="canEdit">
        <div class="help-text">
          <p>Describe how the Student Success Metric(s) relate to the projected Objective.</p>
        </div>
        <po-html-textarea inputRef formControlName="metrics_description" id="{{count}}_metrics_description"
          [htmlCharLimit]="narrativeMax"
          (change)="persistAttribute('metrics_description')"></po-html-textarea>
      </input-field>
    </div>

    <ng-container *ngIf="collectEvidence">
      <h3 class="h3">Supporting Evidence</h3>
      <div class="columns columns--2">
        <po-select id="objective_evidence_type_select_{{uniqueId}}"
            labelText="Supporting Evidence Type"
            [canEdit]="canEdit"
            [options]="evidenceOptions"
            [selectOnce]="true"
            (optionSelected)="persistValue('evidence_type')">
            <select inputRef id="objective_evidence_type_select_{{uniqueId}}" class="po-select__select" formControlName="evidence_type" aria-required="true" required></select>
          </po-select>
      </div>

      <input-field labelText="Description of Evidence" [canEdit]="canEdit">
        <div *ngIf="!isPreview" class="help-text">
          <p>Description of how this evidence shapes the plan. Provide specific evidence.</p>
        </div>
        <po-html-textarea inputRef id="objective_evidence_description_text_area_{{uniqueId}}" formControlName="evidence_description"
          [htmlCharLimit]="descriptionLength"
          (change)="persistValue('evidence_description')"
          required aria-required="true"></po-html-textarea>
      </input-field>

      <label for="qa_upload_document_table_title" class="c-field__label">Add/Upload Source(s)</label>
      <app-document-table
        [fileList]="fileList"
        [enableHideOldFiles]="false"
        [enableHidePastVersions]="false"
        [enableUpload]="canEdit"
        [formatDocTitleFn]="formatDocTitle"
        [columnsToShow]="{source_type: true, type: false, year: false, actions: canEdit}"
        [hideAddButton]="!objectiveEA.id || objectiveEA.id < 0"
        [titleAsSource]="true"
        (addDocumentClicked)="onClickAddButton()"
        (deleteDocumentClicked)="onDeleteFile($event)"
      ></app-document-table>

      <app-document-upload-modal
        *ngIf="showUploadDocumentModal"
        [allowURL]="true"
        [creatorUserId]="userId"
        [documentTypeIds]="docTypesForUpload"
        [fundId]="proposal.funds[0].id"
        [proposalId]="proposal.id"
        [effortAreaId]="objectiveEA.id"
        (closeSuccess)="onUploadSuccess($event)"
        (closeFail)="onUploadFailOrClose()"
        (closeCancel)="onUploadFailOrClose()"
      ></app-document-upload-modal>
    </ng-container>

    <ng-container *ngIf="collectStrategy">
      <input-field labelText="How will this Objective address the Regional Strategy plan" [canEdit]="canEdit">
        <po-html-textarea inputRef id="objective_regional_strategy_description_text_area_{{uniqueId}}" formControlName="regional_strategy_description"
          [htmlCharLimit]="descriptionLength"
          (change)="persistValue('regional_strategy_description')"
          [required]="!isSmallProgram" [attr.aria-required]="!isSmallProgram"></po-html-textarea>
      </input-field>

      <input-field labelText="Description of Alignment with Sector Strategy/Regional Priority" [canEdit]="canEdit">
        <div *ngIf="!isPreview" class="help-text">
          <p>Show how the plan will inform a potential sector strategy/regional priority.</p>
        </div>
        <po-html-textarea inputRef id="objective_alignment_description_text_area_{{uniqueId}}" formControlName="alignment_description"
          [htmlCharLimit]="descriptionLength"
          (change)="persistValue('alignment_description')"
          [required]="!isSmallProgram" [attr.aria-required]="!isSmallProgram"></po-html-textarea>
      </input-field>
    </ng-container>

    <div class="card-footer card-footer--right">
      <button type="button" *ngIf="canDelete && canEdit" (click)="toggleDeleteObjective();" title="Delete" class="action-button action-button--secondary action-button--small inline-button" data-qa="qa_workplan_delete-objective_button">
        <icon iconId="delete" data-qa="delete-icon"></icon> &nbsp; Delete
      </button>
    </div>
  </form>
</div>

<alert *ngIf="showDeleteObjectiveAlert"
  button-action="Delete"
  button-dismiss="Cancel"
  level="warning"
  (action)="deleteObjective()"
  (dismiss)="toggleDeleteObjective()">
  <ng-container [ngSwitch]="!!objectiveEA.title">
    <p *ngSwitchCase="true">
      Are you sure you want to delete the objective <strong>{{objectiveEA.title}}</strong>?
    </p>
    <p *ngSwitchCase="false">
      Are you sure you want to delete this objective?
    </p>
  </ng-container>
</alert>
