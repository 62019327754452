<div *ngIf="stepperAvailable" #stepperComponent class="tab-section error-stepper" [@slideOver]>
  <span class="error-stepper__title" role="alert" [attr.aria-live]="ariaLiveStatus" [attr.aria-label]="ariaLiveText" [innerHtml]="titleText">
  </span>

  <div class="error-stepper__divider"></div>
<!-- Remove "hidden" view, if 1 error replace buttons with "Show" -->
  <ng-container [ngSwitch]="showStepper">
    <ng-container *ngSwitchCase="true">
      <button class="error-stepper__action"
        aria-label="Previous error on page" title="Previous error" (click)="prev()">
        <icon iconId="disclosure-up"></icon>
      </button>
      <button class="error-stepper__action margin-right-none"
        aria-label="Next error on page" title="Next error" (click)="next()">
        <icon iconId="disclosure-down"></icon>
      </button>

      <div class="error-stepper__divider"></div>
      <button [id]="goButtonId" class="error-stepper__action text-link" aria-label="Go to error" (click)="focusError()">Go</button>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <a class="error-stepper__action" aria-label="Show errors" (click)="show()">Show</a>
    </ng-container>

  </ng-container>
</div>