import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { State, Actions } from '@app-ngrx-domains';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { ProgramService } from '@app-services';
import { skipWhile, take } from 'rxjs/operators';
import { Utilities } from '@app-models';

@Injectable()
export class FullProgramResolver implements Resolve<boolean> {
  constructor(
    private store: Store<State>,
    private logger: NGXLogger,
    private programService: ProgramService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable(subscriber => {

      const parentKey =  Utilities.programKeyFromRoute(state.url);
      this.logger.debug(`[full-program-resolver] loading program: ${parentKey}`);

      this.programService.programLoaded(parentKey).pipe(
        skipWhile(loaded => !loaded),
        take(1)
      ).subscribe(loaded => {
        this.logger.debug(`[full-program-resolver] loaded`);
        subscriber.next(true);
        subscriber.complete();
      }, error => {
        this.logger.error(`[full-program-resolver] error=${JSON.stringify(error)}`);
        subscriber.error(false);
        subscriber.complete();
      });
    });
  }
}
