import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { Utilities } from '@app/core/models';
import { Actions, Queries, State } from '@app-ngrx-domains';
import { ProgramService, LookupService } from '@app/core/services';
import { Store } from '@ngrx/store';
import { skipWhile, take } from 'rxjs/operators';
import { PROGRAM_KEYS } from '@app/core/consts';

@Injectable()
export class ProgramAllocationsResolver implements Resolve<boolean> {

  public static replacers = {
    parentProgramId: 'parentProgramId',
    childProgramIds: 'childProgramIds',
    rcContributorId: 'rcContributorId',
    stateInstitutionId: 'stateInstitutionId',
    valueFromRoute: (paramName) => ({ routeParam: paramName })
  };

  constructor(
    private store: Store<State>,
    private programService: ProgramService,
    private lookupService: LookupService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable(subscriber => {
      this.store.dispatch(Actions.ProgramAllocations.clear());

      const { lookupAllocations = {}, lookupCarryovers = {}, lookupTasks = {}, lookupProposals = [] } = route.data;
      const waitOnAllocations = [], waitOnCarryovers = [], waitOnTasks = [], waitOnProposals = [];
      const parentKey = Utilities.programKeyFromRoute(state.url);
      const parentProgram = this.programService.getParentProgramByKey(parentKey);
      const childPrograms = this.programService.getChildProgramsByParentKey(parentKey);

      const replacers = (rawFilter) => {
        const filter = { ...rawFilter };
        let doSkip = false;
        Object.entries(filter).forEach(([key, value]) => {
            if (value === ProgramAllocationsResolver.replacers.parentProgramId) {
              filter[key] = parentProgram.id;
            } else if (value === ProgramAllocationsResolver.replacers.childProgramIds) {
              if (childPrograms.length > 0) {
                filter[key] = childPrograms.map(p => p.id);
              } else {
                // If no child programs are found, then skip the query or else all of the allocations get returned
                doSkip = true;
              }
            } else if (value === ProgramAllocationsResolver.replacers.rcContributorId) {
              const contributor = this.programService.getRCContributorByParentKey(parentKey);
              if (contributor) {
                filter[key] = contributor && contributor.id;
              } else {
                doSkip = true;
              }
            } else if (value === ProgramAllocationsResolver.replacers.stateInstitutionId) {
              filter[key] = this.lookupService.getStateInstitution.value;
            } else if (value && !!value['routeParam']) {
              const paramValue = route.params[value['routeParam']];
              filter[key] = Number.isNaN(Number(paramValue)) ? paramValue : Number(paramValue);
            }
        });

        return doSkip ? null : filter;
      }

      Object.entries(lookupAllocations).forEach(([key, rawFilter]) => {
        const filter = replacers(rawFilter);
        if (!!filter) {
          this.store.dispatch(Actions.ProgramAllocations.fetchAllocations(replacers(filter), key));
        } else {
          this.store.dispatch(Actions.ProgramAllocations.fetchSuccess('allocations', key, []));
        }
        waitOnAllocations.push(key);
      });

      Object.entries(lookupCarryovers).forEach(([key, rawFilter]) => {
        const filter = replacers(rawFilter);
        if (!!filter) {
          this.store.dispatch(Actions.ProgramAllocations.fetchCarryovers(replacers(filter), key));
        } else {
          this.store.dispatch(Actions.ProgramAllocations.fetchSuccess('carryovers', key, []));
        }
        waitOnCarryovers.push(key);
      });

      Object.entries(lookupTasks).forEach(([key, rawFilter]) => {
        const filter = replacers(rawFilter);
        if (!!filter) {
          this.store.dispatch(Actions.ProgramAllocations.fetchTasks(replacers(filter), key));
        } else {
          this.store.dispatch(Actions.ProgramAllocations.fetchSuccess('tasks', key, []));
        }
        waitOnTasks.push(key);
      });

      Object.entries(lookupProposals).forEach(([key, data]) => {
        const filter = replacers(data['filter']);
        if (!!filter) {
          this.store.dispatch(Actions.ProgramAllocations.fetchProposals(filter, key));
        } else {
          this.store.dispatch(Actions.ProgramAllocations.fetchSuccess('proposals', key, []));
        }
        waitOnProposals.push(key);
      });

      return combineLatest([
        this.store.select(Queries.ProgramAllocations.getAllocations),
        this.store.select(Queries.ProgramAllocations.getCarryovers),
        this.store.select(Queries.ProgramAllocations.getTasks),
        this.store.select(Queries.ProgramAllocations.getProposals)
      ]).pipe(
        skipWhile(([allocations, carryovers, tasks, proposals]) =>
          !waitOnAllocations.every(key => !!allocations[key]) ||
          !waitOnCarryovers.every(key => !!carryovers[key]) ||
          !waitOnTasks.every(key => !!tasks[key]) ||
          !waitOnProposals.every(key => !!proposals[key])
        ),
        take(1)
      ).subscribe(() => {
        subscriber.next(true);
        subscriber.complete();
      }, error => {
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }
}