import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, Model, State } from '@app-ngrx-domains';
import { CHAR_LIMITS, OBJECT_CODES } from '@app/core/consts';
import { LookupService } from '@app/core/services';
import { ValidatorsEx } from '@app/core/utilities';
import { Store } from '@ngrx/store';
import { differenceBy } from 'lodash';

@Component({
  selector: 'app-budget-table',
  templateUrl: './budget-table.component.html'
})
export class BudgetTableComponent implements OnInit {

  @Input() canEdit: boolean;
  @Input() isPreview: boolean;
  @Input() proposalId: number;
  @Input() yearId: number;
  @Input() fundId: number;
  @Input() institution: Model.Institution;
  @Input() budgetItems: Array<Model.EAPlanBudgetItem>;
  @Input() objectCodeOptions: Array<Model.SelectOption>;
  @Input() fundingSources: { [sourceId: number]: string };
  @Input() activityOptions: Array<Model.SelectOption>;
  @Input() allowCloning: boolean;
  @Input() monetaryMatchPercent: number;
  @Input() inkindMatchPercent: number;
  @Input() showRelatedActivities: boolean;
  @Input() requireRelatedActivities: boolean;
  @Output() cloneFromPrevious: EventEmitter<null> = new EventEmitter();

  @ViewChild('descriptionTmpl', { static: true }) descriptionTmpl: TemplateRef<any>;
  @ViewChild('activitiesTmpl', { static: true }) activitiesTmpl: TemplateRef<any>;

  form: FormGroup;
  tableColumns = [];
  showForm: boolean;
  editingItem: Model.EAPlanBudgetItem;
  deletingItem: Model.EAPlanBudgetItem;

  showCloneAlert: boolean;
  descriptionLimit: number = CHAR_LIMITS.HAPPY_MEDIUM;

  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
    private  lookupService: LookupService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      object_code_id: [undefined, [ValidatorsEx.requiredSelection]],
      description: [undefined, [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)]],
      related_activities: [[], (this.requireRelatedActivities ? [Validators.required] : [])],
      amounts: this._fb.group({
        grant_funds_amount: [undefined],
        monetary_match_amount: [undefined],
        inkind_match_amount: [undefined]
      }, { validators: ValidatorsEx.requireAmount })
    }, { validators: this.indirectMatchValidator });

    this.tableColumns = [
      {
        name: 'Expenditure Type', cssClass: 'col-15-rem',
        value: (budgetItem: Model.EAPlanBudgetItem) => this.getObjectCode(budgetItem.object_code_id)
      },
      {
        name: 'Year', cssClass: 'col-5-rem',
        value: (budgetItem: Model.EAPlanBudgetItem) => this.lookupService.getYearName(budgetItem.duration_id)
      },
      {
        name: 'Description', cssClass: 'col-20-rem', template: this.descriptionTmpl
      },
      {
        name: 'Funds Amount', cssClass: 'col-10-rem align-right',
        value: (budgetItem: Model.EAPlanBudgetItem) => this.formatCurrency(budgetItem.grant_funds_amount)
      }
    ];

    if (this.showRelatedActivities) {
      this.tableColumns.splice(1, 0, {
        name: 'Related Activities', cssClass: 'col-20-rem', template: this.activitiesTmpl
      });
    }

    if (this.fundingSources) {
      this.tableColumns.unshift({
        name: 'Funding Source', cssClass: 'col-15-rem',
        value: (budgetItem: Model.EAPlanBudgetItem) => this.fundingSources[budgetItem.fund_id] || 'N/A'
      });
    }

    if (this.monetaryMatchPercent) {
      this.tableColumns.push({
        name: 'Monetary Match Amount', cssClass: 'col-10-rem align-right',
        value: (budgetItem: Model.EAPlanBudgetItem) => this.formatCurrency(budgetItem.monetary_match_amount)
      });
    }

    if (this.inkindMatchPercent) {
      this.tableColumns.push({
        name: 'In-Kind Match Amount', cssClass: 'col-10-rem align-right',
        value: (budgetItem: Model.EAPlanBudgetItem) => this.formatCurrency(budgetItem.inkind_match_amount)
      });
    }
  }

  submitForm() {
    if (this.editingItem) {
      this.saveChanges();
    } else {
      this.createBudgetItem();
    }
    this.toggleShowForm();
  }

  createBudgetItem() {
    const values = this.form.value;
    const ea = {
      effort_area_type: 'plan_budget_items',
      parent_proposal_id: this.proposalId,
      fund_id: this.fundId,
      duration_id: this.yearId,
      institution_id: this.institution.id,
      object_code_id: values.object_code_id,
      description: values.description,
      related_activities: values.related_activities,
      grant_funds_amount: values.amounts.grant_funds_amount,
      monetary_match_amount: values.amounts.monetary_match_amount,
      inkind_match_amount: values.amounts.inkind_match_amount
    };

    this.store.dispatch(Actions.CurrentProposal.createEffortArea({ ea, hasAttributes: true }));
  }

  editBudgetItem(budgetItem: Model.EAPlanBudgetItem) {
    this.form.setValue({
      object_code_id: budgetItem.object_code_id,
      description: budgetItem.description,
      related_activities: budgetItem.related_activities.map(activity => activity.value),
      amounts: {
        grant_funds_amount: budgetItem.grant_funds_amount,
        monetary_match_amount: budgetItem.monetary_match_amount,
        inkind_match_amount: budgetItem.inkind_match_amount
      }
    });

    this.editingItem = budgetItem;
    this.showForm = true;
  }

  promptDeleteItem(budgetItem: Model.EAPlanBudgetItem) {
    this.deletingItem = budgetItem;
  }

  deleteBudgetItem() {
    this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({ ea: this.deletingItem }));
    this.deletingItem = undefined;
  }

  saveChanges() {
    const attributes = [];
    const pushIfChanged = (attributeName, value) => {
      if (value !== this.editingItem[attributeName]) {
        attributes.push({ key: attributeName, value, ea: this.editingItem });
      }
    };

    Object.entries(this.form.value).forEach(([attribute, value]) => {
      if (attribute === 'amounts') {
        Object.entries(value).forEach(([amountName, amount]) => {
          if (value !== this.editingItem[attribute]) {
            pushIfChanged(amountName, amount);
          }
        });
      } else if (attribute === 'related_activities') {
        const oldSelections = this.editingItem.related_activities.map(activity => activity.value);
        const newSelections = value;

        differenceBy(oldSelections, newSelections).forEach(removedItem => {
          this.store.dispatch(Actions.CurrentProposal.deleteMultiAttribute({
            key: attribute, value: removedItem, ea: this.editingItem
          }));
        });
        differenceBy(newSelections, oldSelections).forEach(addedItem => {
          this.store.dispatch(Actions.CurrentProposal.addMultiAttribute({
            key: attribute, value: addedItem, ea: this.editingItem
          }));
        });
      } else {
        pushIfChanged(attribute, value);
      }
    });

    this.store.dispatch(Actions.CurrentProposal.upsertAttributes(attributes));
  }

  toggleShowForm() {
    this.showForm = !this.showForm;
    if (!this.showForm) {
      this.form.reset();
      this.editingItem = undefined;
    }
  }

  clonePrevious() {
    this.cloneFromPrevious.emit();
    this.showCloneAlert = false;
  }

  getObjectCode(objectCodeId: number) {
    const objectCode = (this.objectCodeOptions || []).find(option => option.value === objectCodeId);
    return objectCode ? objectCode.label : 'Unknown Object Code';
  }

  formatCurrency(amount) {
    return this.currencyPipe.transform(amount || 0, 'USD', 'symbol', '1.0')
  }

  indirectMatchValidator = (formGroup) => {
    const objectCode = formGroup.get('object_code_id').value;
    const amounts = formGroup.get('amounts').value;
    if (objectCode === OBJECT_CODES.INDIRECT_COSTS) {
      if (amounts.monetary_match_amount || amounts.inkind_match_amount) {
        return { validationError: 'Match amounts are not allowed on "Indirect Cost" budgets' };
      }
    }
  }

  getTotal(attr: string) {
    return this.budgetItems.reduce((sum, item) => {
      return sum += item[attr] || 0;
    }, 0);
  }

  trackById(index: number, item: Model.EAPlanBudgetItem) {
    return item['temp_id'] || item.id;
  }

}
