
import { Component } from '@angular/core';
import { Actions, State } from '@app-ngrx-domains';
import { PAGE_TYPES } from '@app/core/consts';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent {

  constructor(
    private store: Store<State>
  ) {
    this.store.dispatch(Actions.Layout.setPageType(PAGE_TYPES.NAVLESS));
  }
}
