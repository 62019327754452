import {Component, Input} from '@angular/core';
import { Model } from '@app-ngrx-domains';
import { fadeOutAnimation } from '@app-generic/animations';

@Component({
  selector: 'po-status-message',
  templateUrl: './status-message.component.html',
  animations: [fadeOutAnimation]
})
export class StatusMessageComponent {

  @Input() statusMessage: Model.StatusMessage = {
    message: undefined,
    type: undefined // loading | success | fail (hidden if empty)
  };

  @Input() alignLeft = false;

  constructor() { }

  get showMessage() {
    return this.statusMessage?.type && this.statusMessage.type.length;
  }

  get messageType() {
    return this.statusMessage?.type;
  }

}
