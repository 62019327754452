<h2 class="header-title h2 mb-l" id="qa_tab_title">Contacts</h2>

<!-- POINT OF CONTACT -->
<app-project-contact-role-item *ngIf="contacts[pocContactTypeId].pc"
  id="qa_poc_card"
  [contactType]="'pointofcontact'"
  [contactIndex]="0"
  [contact]="contacts[pocContactTypeId]"
  [contactDetails]="contacts[pocContactTypeId].pc.user"
  [canEdit]="canEdit"
  [firstTouch]="firstTouch"
  [inviter]="inviter"
  [fundId]="context.fund_id"
  [fundKey]="context.fund_key"
  [proposalId]="context.proposal_id"
  (associateContact)="onAssociateContact($event)"
  (removeContact)="onRemoveContact($event)"
></app-project-contact-role-item>

<!-- ALTERNATE POINT OF CONTACTS -->
<div *ngFor="let alternate of alternatePoCList; let i = index; trackBy: trackById">
  <app-project-contact-role-item *ngIf="alternate.pc"
    id="qa_alternate_poc_card{{i}}"
    [contactType]="'alternate'"
    [contactIndex]="i"
    [contact]="alternate"
    [contactDetails]="alternate.pc.user"
    [canEdit]="canEdit"
    [firstTouch]="firstTouch"
    [inviter]="inviter"
    [fundId]="context.fund_id"
    [fundKey]="context.fund_key"
    [proposalId]="context.proposal_id"
    (associateContact)="onAssociateContact($event, i)"
    (removeContact)="onRemoveContact($event)"
  ></app-project-contact-role-item>
</div>
<div class="btn-section btn-section--center">
  <button type="button" class="action-button action-button--primary center icon--left js-add-new-contact" (click)="addAlternatePointOfContact()" id="qa_add_alternate_contact_button">
    <icon iconId="plus"></icon>
    Add {{alternatePoCContactBtnText}}
  </button>
</div>

<!-- FISCAL REPORTERS -->
<div *ngFor="let reporter of fiscalReporterList; let i = index; trackBy: trackById">
  <app-project-contact-role-item *ngIf="reporter.pc && includeFiscalReporters"
    id="qa_fiscal_reporter_card{{i}}"
    [contactType]="'reporter'"
    [contactIndex]="i"
    [contact]="reporter"
    [contactDetails]="reporter.pc.user"
    [canEdit]="canEdit"
    [firstTouch]="firstTouch"
    [inviter]="inviter"
    [fundId]="context.fund_id"
    [fundKey]="context.fund_key"
    (associateContact)="onAssociateContact($event, i)"
    (removeContact)="onRemoveContact($event)"
  ></app-project-contact-role-item>
</div>
<div class="btn-section btn-section--center" *ngIf="includeFiscalReporters">
  <button type="button" class="action-button action-button--primary center icon--left js-add-new-contact" (click)="addFiscalReporter()" id="qa_add_reporter_contact_button">
    <icon iconId="plus"></icon>
    Add {{reporterContactBtnText}}
  </button>
</div>

<!-- CONTACTS -->
<div *ngFor="let contact of contactList; let i = index;">
  <app-project-contact-role-item *ngIf="contact.pc"
    id="qa_contact_contact_card{{i}}"
    [contactType]="'contact'"
    [contactIndex]="i"
    [contact]="contact"
    [contactDetails]="contact.pc.user"
    [canEdit]="canEdit"
    [firstTouch]="firstTouch"
    [inviter]="inviter"
    [fundId]="context.fund_id"
    [fundKey]="context.fund_key"
    [proposalId]="context.proposal_id"
    (associateContact)="onAssociateContact($event)"
    (removeContact)="onRemoveContact($event)"
  ></app-project-contact-role-item>
</div>

<!-- APPROVER CONTACTS -->
<h2 class="header-title h2 mb-l">{{approverHeaderText}}</h2>
<div *ngFor="let approver of approverList; let i = index;">
  <app-project-contact-role-item *ngIf="approver.pc"
    id="qa_approver_contact_card{{i}}"
    [contactType]="'approver'"
    [contactIndex]="i"
    [contact]="approver"
    [contactDetails]="approver.pc.user"
    [designeeDetails]="approver.dc?.user"
    [canEdit]="canEdit && !approver.readOnly"
    [firstTouch]="firstTouch"
    [inviter]="inviter"
    [fundId]="context.fund_id"
    [fundKey]="context.fund_key"
    [proposalId]="context.proposal_id"
    (associateContact)="onAssociateContact($event)"
    (removeContact)="onRemoveContact($event)"
  ></app-project-contact-role-item>
</div>
