import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload, NewTempId } from '@app-libs';
import { EnumErrorTypes } from '@app-models';

/**
 * Invoices action types
 */
const ACTION_PREFIX = 'INVOICES_';
export const INVOICES_ACTION_TYPES = {
  GET: `${ACTION_PREFIX}GET`,
  LOAD: `${ACTION_PREFIX}LOAD`,
  PUSH_STATUS: `${ACTION_PREFIX}PUSH_STATUS`,
  CLEAR_STATUS: `${ACTION_PREFIX}CLEAR_STATUS`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  NOOP: `${ACTION_PREFIX}NOOP`,
  GET_INVOICE: `${ACTION_PREFIX}GET_INVOICE`,
  GET_INVOICE_SUCCESS: `${ACTION_PREFIX}GET_INVOICE_SUCCESS`,
  CREATE_INVOICE: `${ACTION_PREFIX}CREATE_INVOICE`,
  CREATE_INVOICE_SUCCESS: `${ACTION_PREFIX}CREATE_INVOICE_SUCCESS`,
  CREATE_INVOICE_FAIL: `${ACTION_PREFIX}CREATE_INVOICE_FAIL`,
  UPDATE_INVOICE: `${ACTION_PREFIX}UPDATE_INVOICE`,
  UPDATE_INVOICE_SUCCESS: `${ACTION_PREFIX}UPDATE_INVOICE_SUCCESS`,
  UPDATE_INVOICES: `${ACTION_PREFIX}UPDATE_INVOICES`,
  UPDATE_INVOICES_SUCCESS: `${ACTION_PREFIX}UPDATE_INVOICES_SUCCESS`,
  DELETE_INVOICE: `${ACTION_PREFIX}DELETE_INVOICE`,
  DELETE_INVOICE_SUCCESS: `${ACTION_PREFIX}DELETE_INVOICE_SUCCESS`,
  SUBMIT_INVOICE: `${ACTION_PREFIX}SUBMIT_INVOICE`,
  MARK_AS_PAID: `${ACTION_PREFIX}MARK_AS_PAID`
}

/**
 * Invoices action class
 */
export class InvoicesActions {
  get(fundId: number, isRefresh?: boolean): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.GET,
      payload: { fundId, isRefresh }
    };
  }

  /**
   * Fetched data - send it along to reducer
   * @param invoices
   */
  load(invoices: Array<Model.EAInvoice>): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.LOAD,
      payload: invoices
    };
  }

  /**
   * Resets data
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.LOAD,
      payload: [],
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: INVOICES_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  /**
   * Starts get of invoice action.
   *
   * @param {number} invoiceId
   * @returns {Action}
   */
   getInvoice(invoiceId: number): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.GET_INVOICE,
      payload: invoiceId
    };
  }

  getInvoiceSuccess(item: Model.EAInvoice): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.GET_INVOICE_SUCCESS,
      payload: item
    };
  }

  createInvoice(item: any): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.CREATE_INVOICE,
      payload: item
    };
  }

  createInvoiceSuccess(item: any): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.CREATE_INVOICE_SUCCESS,
      payload: item
    };
  }

  createInvoiceFail(): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.user,
      location: INVOICES_ACTION_TYPES.CREATE_INVOICE_FAIL,
      show: true,
      message: 'There are no uninvoiced expenditures at this time. A new invoice cannot be generated.',
    });
  }

  // Update individual invoice
  updateInvoice(item: any): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.UPDATE_INVOICE,
      payload: item
    };
  }

  updateInvoiceSuccess(item: any): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.UPDATE_INVOICE_SUCCESS,
      payload: item
    };
  }

  // Update an invoice from a table
  updateInvoices(item: any): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.UPDATE_INVOICES,
      payload: item
    };
  }

  updateInvoicesSuccess(item: any): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.UPDATE_INVOICES_SUCCESS,
      payload: item
    };
  }

  deleteInvoice(invoiceId: any): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.DELETE_INVOICE,
      payload: invoiceId
    };
  }

  deleteInvoiceSuccess(item: any): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.DELETE_INVOICE_SUCCESS,
      payload: item
    };
  }

  submitInvoice(invoiceId: any): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.SUBMIT_INVOICE,
      payload: invoiceId
    };
  }

  markAsPaid(invoiceId: any): ActionWithPayload<any> {
    return {
      type: INVOICES_ACTION_TYPES.MARK_AS_PAID,
      payload: invoiceId
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Invoices = new InvoicesActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Invoices: InvoicesActions;
  }
}
