<form [formGroup]="form" autocomplete="off">
  <div class="card" id="qa_userinfo_card">

    <div class="card-header">
      <div class="card-title">
        <div class="columns columns--2">
          <h3 class="header-title h3">Professional Information</h3>
          <div class="text-right">
            <button type="submit" class="action-button action-button--primary margin-bottom-sm"
            id="qa_profile_save_info_top"
            [disabled]="!form.valid"
            (click)="onSubmit()">
            Save Profile
            </button>
            <po-status-message *ngIf="showStatus" id="qa_userinfo_submit_status0"
                               [statusMessage]="statusMessage">
            </po-status-message>
          </div>
        </div>
      </div>
    </div>

    <p><span class="error-asterisk">*</span> Indicates required field</p>

    <div class="columns columns--4">
      <input-field labelText="Honorific">
        <input type="text" inputRef id="qa_userinfo_prefix_text" formControlName="prefix">
      </input-field>

      <input-field labelText="First Name">
        <input type="text" inputRef id="qa_userinfo_first_name_text" formControlName="first_name" aria-required="true">
      </input-field>

      <input-field labelText="Last Name">
        <input type="text" inputRef id="qa_userinfo_last_name_text" formControlName="last_name" aria-required="true">
      </input-field>

      <input-field labelText="Professional Suffix">
        <input type="text" inputRef id="qa_userinfo_suffix_text" formControlName="suffix">
      </input-field>
    </div>

    <div class="columns columns--2">
      <po-select
        id="qa_userinfo_job_cateogry_select"
        [options]="jobCategories$ | async"
        labelText="Professional Category"
        placeholderText="Select Category"
        [sortByLabel]="true"
        [selectOnce]="true">
        <select inputRef class="po-select__select" formControlName="job_category_id" aria-required="true"></select>
      </po-select>

      <input-field labelText="Job Title">
        <input type="text" inputRef id="qa_userinfo_title_text" formControlName="title">
      </input-field>
    </div>
    <div class="columns columns--2">
      <po-select
        id="qa_userinfo_institution_select"
        labelText="Employer"
        placeholderText="Start typing organization or institution name"
        [options]="institutionsLookupFormatted"
        [isSearch]="true"
        [selectMultiple]="false"
        (search)="updateInstitutionsList($event)">
        <select inputRef class="po-select__select" formControlName="employer" aria-required="true"></select>
      </po-select>

      <div [ngClass]="(errors['email'] ? 'error' : '')">
        <input-field labelText="Work Email">
          <input type="text" inputRef id="qa_userinfo_email_address_text" formControlName="email_address" aria-required="true">
        </input-field>
        <span [hidden]="!errors['email']" class="c-field__note">{{errors['email']}}</span>
        <p [hidden]="!errors['email']"><a routerLink="/passwordReset">Did you forget your password?</a></p>
      </div>
    </div>

    <div class="columns columns--4">
      <input-field labelText="Phone">
        <input type="phone" phone-transform inputRef id="qa_userinfo_phone_text" formControlName="phone">
      </input-field>

      <input-field labelText="Phone Extension">
        <input type="text" inputRef id="qa_userinfo_phone_extension" formControlName="phone_extension">
      </input-field>

      <input-field labelText="Fax">
        <input type="phone" phone-transform inputRef id="qa_userinfo_fax_text" formControlName="fax">
      </input-field>

      <input-field labelText="Fax Extension">
        <input type="text" inputRef id="qa_userinfo_fax_extension" formControlName="fax_extension">
      </input-field>
    </div>

    <input-field labelText="Address Line 1">
      <input type="text" inputRef id="qa_userinfo_address_text" formControlName="address">
    </input-field>

    <input-field labelText="Address Line 2">
      <input type="text" inputRef id="qa_userinfo_address_2_text" formControlName="address_2">
    </input-field>

    <div class="columns columns--2">
      <input-field labelText="City">
        <input type="text" inputRef id="qa_userinfo_city_text" formControlName="city">
      </input-field>

      <input-field labelText="Zip Code">
        <input type="text" inputRef id="qa_userinfo_zip_text" formControlName="zip">
      </input-field>
    </div>

    <div class="card-footer card-footer--right margin-bottom-sm">
      <button type="submit" class="action-button action-button--primary"
        id="qa_profile_save_info_bottom"
        [disabled]="!form.valid"
        (click)="onSubmit()">
        Save Profile
      </button>
    </div>
    <po-status-message *ngIf="showStatus" id="qa_userinfo_submit_status1"
                       [statusMessage]="statusMessage">
    </po-status-message>
  </div>
</form>
