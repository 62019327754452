import {Component, Input, Output, OnInit, OnDestroy, ViewChild, TemplateRef, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { IDocumentType} from '@app-models';
import { ApiService } from '@app-services';
import { FILE_UPLOAD_TYPES, CHAR_LIMITS, UPLOAD_LIMIT, ALLOWED_EXTENSIONS, DOCUMENT_TYPES } from '@app-consts';
import { ValidatorsEx } from '@app-utilities';

import { last as _last, every as _every } from 'lodash';
import { EnumErrorTypes } from '@app-models';

@Component({
  selector: 'app-document-upload-modal',
  templateUrl: './document-upload-modal.component.html',
})
export class DocumentUploadModalComponent implements OnInit, OnDestroy {
  @Input() documentTypeIds: Array<number> = [];
  @Input() allowedExtensions = ALLOWED_EXTENSIONS;
  @Input() allowURL = false;
  @Input() creatorUserId: number;
  @Input() fundId?: number;
  @Input() institutionId?: number;
  @Input() institutions: Array<Model.SelectOption> = [];
  @Input() proposalId?: number;
  @Input() yearDurationId?: number;
  @Input() effortAreaId?: number;
  @Input() uploadType: string = FILE_UPLOAD_TYPES.PROJECT_FILE;
  @Input() collectComments = true;
  @Input() useTitle?: string;
  @Output() closeSuccess: EventEmitter<any> = new EventEmitter();
  @Output() closeFail: EventEmitter<any> = new EventEmitter();
  @Output() closeCancel: EventEmitter<void> = new EventEmitter();
  @ViewChild('uploadInput') uploadInput;

  @Input() preuploadCheck: Function;

  private UPLOAD_ERROR = {
    location: this.constructor.name,
    type: EnumErrorTypes.api,
    raw: {},
    show: true,
    message: `The upload failed. Please try again.`
  };
  private destroy$: Subject<boolean> = new Subject();
  uploader: FileUploader;
  uploadForm: FormGroup;
  uploadOkResponses = [];
  uploadErrorResponses = [];

  files = [];
  uploadDocumentTypeOptions = [];
  sourceTypeOptions: Array<Model.SelectOption> = [];
  isSingleDocType: boolean; // If true, show the type as read-only. Otherwise, use po-select to pick doc type.
  singleDocTypeName: string;
  user: any;
  fileNames: string[] = [];

  commentLimit: number = CHAR_LIMITS.MEDIUM;
  showUploadErrorAlert = false;
  showUploadDocumentModal = false;

  statuses: { [name: string ]: Model.StatusMessage } = {
    upload: { message: undefined, type: undefined }
  };

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private store: Store<State>,
  ) {}

  ngOnInit() {
    this.initUploader(); // Init & config the file uploader
    this.buildFormGroup();
    this.sourceTypeOptions = [
      { label: 'File', value: 'file' },
      { label: 'URL', value: 'url'}
    ];

    this.store.select(Queries.LookupTables.getDocumentTypesMap)
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe((documentTypesLookup: Map<number, IDocumentType>) => {
      // Format the options list of upload document types
      this.uploadDocumentTypeOptions = this.documentTypeIds.map(type_id => ({
        value: type_id,
        label: documentTypesLookup.get(type_id).name
      }))
      .sort((a, b) => a.label.localeCompare(b.label)); // sort by label.
      // Detect if there's only 1 available document type, and initialize accordingly
      this.isSingleDocType = this.documentTypeIds.length === 1;
      if (this.isSingleDocType) {
        // Set our single document type into the form, so it can be picked up later.
        this.uploadForm.get('document_type_id').setValue(this.uploadDocumentTypeOptions[0].value);
        this.singleDocTypeName = this.uploadDocumentTypeOptions[0].label;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private buildFormGroup() {
    const form = this.formBuilder.group({
      document_type_id: ['', Validators.required ],
      comment: '',
      amendment: false,
      // This isn't the file input directly, but it gets set whenever the file-type input changes. See https://stackoverflow.com/questions/44072909/using-reactive-form-validation-with-input-type-file-for-an-angular-app.
      files: this.formBuilder.array([], Validators.required),
      url: this.formBuilder.control([], [Validators.required, ValidatorsEx.url])
    });

    form.get('url').disable();

    if (this.allowURL) {
      form.addControl('source_type', new FormControl(['', Validators.required]));
    }

    if (this.institutions && this.institutions.length) {
      form.addControl('institution_id', new FormControl([null, Validators.required]));
    }

    this.uploadForm = form;

    this.clearFiles();
  }

  clearFiles() {
    this.uploader.clearQueue();
    this.uploadForm.get('files').setValue([]);
    this.fileNames = [];
  }

  removeFile(fileName: string) {
    const file = this.uploader.queue.filter(item => item.file.name === fileName);
    file.forEach(item => {
      this.uploader.removeFromQueue(item);
    });
    const filesControls = <FormArray>this.uploadForm.get('files');
    let idx;
    while ( (idx = filesControls.value.indexOf(fileName)) >= 0) {
      filesControls.removeAt(idx);
    }
    this.fileNames = this.fileNames.filter(item => item !== fileName);
  }

  /**
   * Initializes the FileUploader instance.
   */
  initUploader() {
    this.uploader = this.apiService.fileUploader(this.uploadType);
    this.uploader.clearQueue(); // Clear out any prior files that might be attached from prior actions
    this.uploader.onAfterAddingFile = (item) => {
      const extension = item.file.name.substr(item.file.name.lastIndexOf('.')).toLowerCase()
      if (!this.allowedExtensions.some(ext => ext.toLowerCase() === extension)) {
        this.showMessage('upload', { message: this.badFileTypeMsg(), type: 'fail' });
        this.uploader.removeFromQueue(item);
      } else if (this.fileNames.includes(item.file.name)) {
        this.showMessage('upload', { message: 'This filename is already chosen', type: 'fail' });
        this.uploader.removeFromQueue(item);
      } else {
        item.withCredentials = false;
        this.fileNames.push(item.file.name);
        const filesControls = <FormArray>this.uploadForm.get('files');
        filesControls.push( this.formBuilder.control(item.file.name) );
        this.showMessage('upload', { message: 'Ready to upload', type: 'success' });
      }
    };

    this.uploader.onCompleteItem = (item: any, res: any, status: any, headers: any) => {
      const statusCode = status || res?.statusCode || res?.code;

      if (!res || statusCode < 200 || statusCode > 299) { // ERROR CASE
        this.uploadErrorResponses.push(res);
      } else { // SUCCESS CASE
        this.uploadOkResponses.push(res);
      }
    };

    this.uploader.onErrorItem = (item: any, res: any, status: any, headers: any) => {
      this.uploadErrorResponses.push(JSON.parse(res));
    };

    this.uploader.onCompleteAll = () => {
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      if (this.uploadErrorResponses.length) {
        const errorResponse = this.uploadErrorResponses[0];

        const error = (errorResponse.message && errorResponse.code && errorResponse.code < 500)
          ? errorResponse : {...this.UPLOAD_ERROR, raw: errorResponse || 'No response from server'};

        this.closeFail.emit(error); // TODO: Flesh out the error object

        if (error.message && error['code'] && error['code'] < 500) {
          this.store.dispatch(Actions.App.showAlert(error.message));
        } else {
          this.store.dispatch(Actions.App.setError(error));
        }
      }

      this.uploadOkResponses.forEach(res => {
        const file = JSON.parse(res);
        this.closeSuccess.emit(file);
      });
    }

  }

  /**
   * Takes the document details from the Upload File modal fields, and
   * performs the upload using a ng-file-upload FileUploader object
   * @param {Document} document
   */
  async submitUploadDocumentForm() {
    this.store.dispatch(Actions.Layout.showBusySpinner(true));

    const document_type_id = this.uploadForm.get('document_type_id').value;
    const comment = this.uploadForm.get('comment').value;
    const institution_id = this.uploadForm.get('institution_id');

    const fileToUpload: any = {
      document_type_id: document_type_id,
    };
    if (this.proposalId) {
      fileToUpload.proposal_id = this.proposalId;
    }
    if (this.fundId) {
      fileToUpload.fund_id = this.fundId;
    }
    if (this.yearDurationId) {
      fileToUpload.duration_id = this.yearDurationId;
    }

    if (this.institutionId) {
      fileToUpload.institution_id = this.institutionId;
    } else if (institution_id) {
      fileToUpload.institution_id = institution_id.value;
    }

    if (this.effortAreaId) {
      fileToUpload.effort_area_id = this.effortAreaId;
    }

    if (!!this.useTitle) {
      fileToUpload.title = this.useTitle;
    }

    if (comment) { // Because of the way the file uploader works, the comment property can't be null or undefined, so we add it here only when necessary.
      fileToUpload.comment = comment;
    }

    if (this.preuploadCheck) {
      await this.preuploadCheck(fileToUpload);
    }

    this.uploader.options.additionalParameter = fileToUpload;

    // upload the last item that was entered... in case file was selected more than once.
    if (this.fileIsUrl) {
      let url = this.uploadForm.get('url').value;
      const urlPrefix = url.substring(0, 8);
      if (!urlPrefix.includes('http://') && !urlPrefix.includes('https://')) {
        url = 'https://' + url;
      }

      const file = await this.apiService.saveUrl({ filename: url, ...fileToUpload }).toPromise();
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      this.closeSuccess.emit(file);
    } else if (_every(this.uploader.queue, item => item.file.size < UPLOAD_LIMIT.VALUE)) {
      this.uploader.uploadAll();
    } else {
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      this.showMessage('upload', { message: `File size is too big.  Limit is ${UPLOAD_LIMIT.SHORT}`, type: 'fail' });
    }
  }

  cancelUploadDocumentForm() {
    this.closeCancel.emit();
  }

  setSource(isFile) {
    if (isFile === 'url') {
      this.uploadForm.controls['url'].enable();
      this.uploadForm.controls['files'].disable();
    } else {
      this.uploadForm.controls['url'].disable();
      this.uploadForm.controls['files'].enable();
    }
  }

  get fileIsUrl() {
    return this.uploadForm && this.allowURL ? this.uploadForm.get('source_type').value === 'url' : false;
  }

  private showMessage(key: string, status: Model.StatusMessage) {
    this.statuses[key] = { message: status.message, type: status.type };
  }

  private badFileTypeMsg(): string {
    let message = 'File type not supported. Please upload a file of ';
    message += (this.allowedExtensions.length > 1) ? 'types ' : 'type ';
    message += this.allowedExtensions.join(' ');
    return message;
  }

  getFileTypes(): string {
    return this.allowedExtensions.join(',');
  }

  /**
   * Whenever user changes the file input, transfers the filename to a dummy hidden input, which can be validated.
   * @param $event
   */

  uploadFileClicked() {
    this.uploadInput.nativeElement.click();
  }
}
