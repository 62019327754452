
<ng-content select=".certification-card__header"></ng-content>
<ng-content select=".certification-card__main"></ng-content>

<footer class="certification-card__footer" [ngClass]="getClassState()">

  <!-- Section to handle when task has been completed -->
  <ng-container *ngIf="completed">
    <div class="certification-card__approved-heading certification-card__approval-heading" id="qa_certification_approved_heading{{index}}">
      {{ taskStatus }}
    </div>

    <p class="certification-card__approval-status" id="qa_certification_card_approved_date{{index}}">
      {{ task.updated_at | po_date:'dateTime12HoursTZ' }}
    </p>
  </ng-container>

  <!-- Section to handle when task is waiting for certifier action -->
  <ng-container *ngIf="!completed && !hideStatus" [ngSwitch]="canEdit">
    <ng-container *ngSwitchCase="false">
      <button *ngIf="!hideReminder" type="button" id="qa_certification_card_send_reminder_action"
              class="action-button action-button--secondary action-button--loader certification-card__approval-button no-print"
              (click)="sendReminder()"
              [disabled]="disableButtons()">
        Send Reminder
        <div class="loader-spinner-inline"></div>
      </button>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <button *ngIf="!isSubmittal" type="button"
              class="action-button action-button--secondary action-button--loader certification-card__approval-button"
              id="qa_certification_card_reject_action"
              (click)="completeTask(true)"
              [disabled]="disableButtons()">
        Reject
        <div class="loader-spinner-inline"></div>
      </button>
      <button type="button" (click)="completeTask(false)"
              [disabled]="disableButtons()"
              class="action-button action-button--primary action-button--loader certification-card__approval-button"
              id="qa_certification_card_approve_action">
        {{ completeVerb }}
        <div class="loader-spinner-inline"></div>
      </button>
    </ng-container>
    <p class="certification-card__approval-status" id="qa_certification_card_approval_status">{{ awaitingStatus }}</p>
  </ng-container>

</footer>
