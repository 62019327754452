<div class="card card--contact">
  <div class="card-header">
    <div class="card-title">
      <h3 class="h3">{{contact.title}}</h3>
    </div>
  </div>

  <form [formGroup]="form" autocomplete="off" [ngClass]="{'first-touch': firstTouch}">
    <div class="card-section-wrapper">
      <!-- MAIN CONTACT -->
      <div class="card-section field-group">
        <!-- MAIN CONTACT: DROPDOWN -->
        <div>
          <po-select
            id="qa_contacts_{{contactType}}{{contactIndex}}_select"
            [canEdit]="canEdit"
            labelText="Contact Name"
            [options]="contactsLookupFormatted"
            [isSearch]="true"
            [selectMultiple]="false"
            [showEmpty]="true"
            emptyText="Invite New User"
            emptyIcon="invite"
            placeholderText="Enter name or email"
            (optionSelected)="associateUser($event)"
            (emptySelected)="showModal(true)"
            (optionRemoved)="removeUser()"
            (search)="updateContactsList($event)">
            <select inputRef class="po-select__select" formControlName="contactId" aria-required="true"></select>
          </po-select>
          <p class="contact-meta">
            <span class="contact-meta-item contact-meta-item--title" *ngIf="contactDetails?.title"><strong>Title:</strong> {{contactDetails.title}}</span>
            <span class="contact-meta-item contact-meta-item--email" *ngIf="contactDetails?.email_address">
              <strong>Email: </strong> <a href="mailto:{{contactDetails.email_address}}" class="text-link">{{contactDetails.email_address}}</a>
            </span>
            <span class="contact-meta-item contact-meta-item--phone" *ngIf="contactDetails?.phone">
              <strong>Phone:</strong> {{ contactDetails.phone | phone }} {{ contactDetails.phone_extension | phone_extension}}
            </span>
          </p>
          <!-- DESIGNEE CONTACT -->
          <ng-container *ngIf="contact.hasDesignee">
            <po-select
              id="qa_contacts_{{contactType}}{{contactIndex}}_select_designee"
              [canEdit]="canEdit"
              labelText="Designee Contact"
              [options]="contactsLookupFormatted"
              [isSearch]="true"
              [selectMultiple]="false"
              [showEmpty]="true"
              emptyText="Invite New Designee"
              emptyIcon="invite"
              placeholderText="Enter name or email"
              (emptySelected)="showModal(true)"
              (optionSelected)="associateUser($event, true)"
              (optionRemoved)="removeUser(true)"
              (search)="updateContactsList($event)">
              <select inputRef class="po-select__select" formControlName="designeeId"></select>
            </po-select>
            <p class="contact-meta">
              <span class="contact-meta-item contact-meta-item--title" *ngIf="designeeDetails?.title"><strong>Title:</strong> {{designeeDetails.title}}</span>
              <span class="contact-meta-item contact-meta-item--email" *ngIf="designeeDetails?.email_address">
                <strong>Email:</strong> <a href="mailto:{{designeeDetails.email_address}}" class="text-link">{{designeeDetails.email_address}}</a>
              </span>
              <span class="contact-meta-item contact-meta-item--phone" *ngIf="designeeDetails?.phone">
                <strong>Phone:</strong> {{ designeeDetails.phone | phone }} {{ designeeDetails.phone_extension | phone_extension}}
              </span>
            </p>
          </ng-container>

          <div class="error-text" *ngIf="alertMessage" level="error">
            <p>{{alertMessage}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer card-footer--right" *ngIf="canEdit && contact.canDelete">
      <button type="button"
              class="action-button inline-button action-button--secondary action-button--small icon--left js-delete"
              (click)="removeUser()"
              data-qa="delete-contact-action">
        <icon iconId="delete"></icon>
        Delete
      </button>
    </div>
  </form>
</div>

<app-invite-user *ngIf="showModalInvite"
  (cancelAction)="showModal(false)"
  (closeAction)="showModal(false)"
  (inviteSuccess)="inviteActions($event)"
  [description]="modalDescription()"
  [contactOnly]="contact.contactOnly"
  [fundKey]="fundKey"
  [proposalId]="proposalId"
  [roleId]="contact.pc.role_id">
</app-invite-user>
