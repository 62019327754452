import { Model } from '@app-ngrx-domains';
import { INSTITUTION_TYPES } from '../consts';
import { IProposalItem } from './proposal';
import { ProposalInstitution } from './proposal-institution';
import { pick } from 'lodash';

export interface IInstitutionHierarchy {
  id: number;
  name: string;
  type: string;
  district: string;
  district_id: number;
  region: string;
  region_id: number;
}

export interface IInstitution extends Partial<Model.ProposalInstitution> {
  // NOTE: All ProposalInstitution properties are optional on IInstitution, based on the `Partial<>` construct
  id: number;
  name?: string;
  type?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  website?: string;
  phone?: string;
  logo_url?: string;
  longitude?: number;
  latitude?: number;
  long_name?: string;
  attribute_1?: string;
  ada_number?: number;
  ein?: string;
  hierarchy?: Model.InstitutionHierarchy;
  users?: Model.UserRoleScope;
  proposals?: Array<IProposalItem>;
  parent?: Array<Model.Institution>;
  institution_settings?: Array<Model.EAInstitutionSettings>;
  cds_code?: string;
}

export class Institution {
  static iObject(i: any): IInstitution {
    return {
      ...ProposalInstitution.iObject(i), // These additional propoerties might cause errors if using iOjbect to write back to the service.
      ...pick(i, [
        'id',
        'name',
        'type',
        'address1',
        'address2',
        'city',
        'state',
        'zip',
        'website',
        'phone',
        'logo_url',
        'longitude',
        'latitude',
        'long_name',
        'attribute_1',
        'ada_number',
        'ein',
        'hierarchy',
        'users',
        'proposals',
        'parent'
      ])
    };
  }

  static getTypeForHeader(institution: IInstitution): string {
    switch (institution.type) {
      case INSTITUTION_TYPES.RC:
        return 'Regional';
      case INSTITUTION_TYPES.CCD:
        return 'District';
      case INSTITUTION_TYPES.COLLEGE:
        return 'College';
      case INSTITUTION_TYPES.CONSORTIUM:
        return 'Consortium';
      default:
        return 'Institutional';
    }
  }

  static getFullAddress(model: IInstitution): string {

    const chkString = (str: string) => {
      if (str && str !== null) {
        return str;
      } else {
        return '';
      }
    };

    if (model) {
      const address1 = chkString(model.address1);
      const address2 = chkString(model.address2);
      const city = chkString(model.city);
      const state = chkString(model.state);
      const zip = chkString(model.zip);
      if (`${address1}${address2}${city}${state}${zip}` === '') {
        // no known address.
        return '';
      } else {
        return `${address1} ${address2} | ${city}, ${state} | ${zip}`;
      }
    } else {
      return '';
    }
  }

  static getLongNameWithId(model: IInstitution): string {
    if (model) {
      return `${model.attribute_1} ${model.long_name}`;
    }
  }

  static getLongNameOrName(model: IInstitution): string {
    if (model) {
      return model.long_name ? model.long_name : model.name;
    }
  }


  static getNameWithId(model: IInstitution): string {
    if (model) {
      return `${model.attribute_1} ${model.name}`;
    }
  }

  static getWebsite(model: IInstitution): string {
    if (model && model.website) {
      return model.website;
    } else {
      return '';
    }
  }

  static isDistrict(model: IInstitution): boolean {
    return model.type === INSTITUTION_TYPES.CCD;
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Institution = IInstitution;
    export type InstitutionHierarchy = IInstitutionHierarchy;
  }
}
