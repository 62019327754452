<div class="progress-bar"
     (mouseenter)="barMouseenter()"
     (mouseleave)="barMouseleave()"
     [class.bar-focused]="isBarFocused()">
  <div
    class="progress-bar__bars-container">
    <div
      *ngFor="let bar of progressBarItems; let i = index;"
      (mouseover)="barMouseover(i)"
      (mouseout)="barMouseout()"
      class="progress-bar__bar"
      [ngClass]="bar.bgClass"
      [class.focused]="barFocused(i)"
      [style.width.%]="bar.width">
    </div>
  </div>

  <div class="progress-bar__legend">
    <p
      *ngFor="let bar of progressBarItems; let i = index"
      (mouseover)="barMouseover(i)"
      class="progress-bar__legend-item" [class.focused]="barFocused(i)">
      <span class="progress-bar__legend-item-dot" [ngClass]="bar.bgClass"></span>
      <span class="progress-bar__legend-item-label">{{ bar.label }}:</span>
      <span class="progress-bar__legend-item-value" [ngClass]="{'error-text': bar.value < 0}">{{ format(bar.value) }}</span>
    </p>
  </div>
</div>
