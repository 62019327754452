import { Component, OnInit, Input } from '@angular/core';
import { Model } from '@app-ngrx-domains';
import { LookupService } from '@app/core/services';

@Component({
  selector: 'contact-section',
  templateUrl: './contact-section.component.html'
})
/**
 * The purpose of this component is to house the type for the contacts.
 * Probably shouldn't be dealing with data persistence here.
 *
 * alternatively, we don't need to have seperate components for this. How does the saving work?
 * has the 'new contact' buttons, which add/remove empty URS to/from the store
 */
export class ContactSectionComponent implements OnInit {
  @Input() canEdit: boolean = true; // todo: default false
  @Input() section: Model.EAContactSection;
  @Input() context: Model.ContactContext;

  types = {};

  constructor(private lookupService: LookupService) {}

  model: Model.EAAssurance
  ngOnInit() {
    // if role.institution == 1, but type.institution is not required, allow the role institution to be changed.
    for (const type of this.section.contact_types) {
      switch (type.institution) {
        case 'all':
          type.institutions = this.context.institutions;
          break;
          default:
        case 'lead': // pick was also an option, used for SWP-R. unused at this time.
          type.institutions = [this.context.lead_institution];
          break;
      }

    }
  }
}
