<div class="certification-card" [attr.data-invalid]="!completed">
  <header class="certification-card__header">
    <h4 class="certification-card__title">{{headerText}}</h4>
  </header>

  <div class="certification-card__main">
    <div *ngFor="let user of users; let i=index" class="mb-s">
      <ul class="certification-card__contact-info">
        <li class="certification-card__contact-info-item certification-card__contact-name">
          <app-user-actions
            [count]="i"
            [menuText]="getUserFullName(user)"
            [userData]="user"
            class="contacts__contact__options">
          </app-user-actions>
        </li>
        <li *ngIf="user.title" class="certification-card__contact-info-item certification-card__contact-title">{{user.title}}</li>
        <li *ngIf="user.email_address" class="certification-card__contact-info-item">
          <a href="mailto:{{user.email_address}}" class="text-link" data-qa="qa_sep_certifier_email">{{user.email_address}}</a>
        </li>
        <li *ngIf="user.phone" class="certification-card__contact-info-item">
          {{ user.phone | phone }} {{ user.phone_extension | phone_extension }}
        </li>
      </ul>
    </div>
    <p *ngIf="!users?.length" class="empty-state">No users have been assigned to this role.</p>
  </div>

  <footer class="certification-card__footer" [ngClass]="cardState.class_value">
    <ng-container [ngSwitch]="completed">
      <ng-container *ngSwitchCase="true">
        <!-- The task is completed, no further actions allowed -->
        <div class="certification-card__approved-heading certification-card__approval-heading">
          {{ cardState.text_value }}
        </div>

        <ng-container [ngTemplateOutlet]="rejectButton"></ng-container>

        <p class="certification-card__approval-status" data-qa="qa_approval_date">
          {{ approvalDate | po_date:'dateTime12HoursTZ' }}
        </p>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container [ngSwitch]="canApprove">
          <ng-container *ngSwitchCase="true">
            <ng-container [ngTemplateOutlet]="completeButton"></ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <!-- User cannot modify the state of this task -->
            <ng-container *ngTemplateOutlet="reminderButton"></ng-container>
          </ng-container>
        </ng-container>
        <p class="certification-card__approval-status" data-qa="qa_approval_state">{{ cardState.text_value }}</p>
      </ng-container>
    </ng-container>
  </footer>
</div>

<ng-template #rejectButtonTmpl>
  <button *ngIf="!isSubmittal && canApprove" (click)="completeTask(true)" type="button" class="action-button action-button--secondary action-button--loader certification-card__approval-button">
    {{ rejectButtonText }}
  </button>
</ng-template>

<ng-template #completeButtonTmpl>
  <button type="button" (click)="completeTask(false)" class="action-button action-button--primary action-button--loader certification-card__approval-button">
    {{ completeVerb }}
  </button>
</ng-template>

<ng-template #reminderButton>
  <button *ngIf="canRemind" class="action-button action-button--secondary action-button--loader certification-card__approval-button no-print"
    [disabled]="isSending" type="button" (click)="sendReminder()">
    {{reminderText}}
    <div *ngIf="isSending" class="loader-spinner-inline" [style.display]="'inline-block'"></div>
    <icon *ngIf="isFailure" iconId="alert" class="icon--block icon--right icon--negative"></icon>
  </button>

  <div *ngIf="isSuccess" class="status-message__message">
    <p class="status-message" id="qa_status_message">{{reminderText}}
      <icon iconId="checkmark" class="icon--block icon--right icon--positive"></icon>
    </p>
  </div>
</ng-template>
