
import { Model } from '@app-ngrx-domains';
import { Duration } from './duration';

export interface IFiscalReportData {
  budget_items: Array<Model.BudgetItem>;
  prior_expenditures?: any;
  title?: string;
  forecast?: Model.EAPlanExpenditureForecast;
}

export interface IFiscalReport {
  id?: number;
  proposal_id: number;
  institution_id: number;
  institution?: Model.Institution;
  fund_id?: number;
  effort_area_id?: number;
  duration_id: number;
  duration?: Duration;
  creator_id?: number;
  comments?: string;
  require_explanation?: boolean;
  below_target_explanation?: string;
  submitted?: boolean;
  lines?: IFiscalReportLine[];
  encumbered_added_explanation?: string;
  encumbered_removed_explanation?: string;
}

export interface IFiscalReportInfo extends Model.Task {
  reportingGroup: string;
  institution: Model.Institution;
  duration: Model.Duration;
  fund?: Model.Fund;
  fiscal_report: IFiscalReport;
  role: Model.Role;
  data?: any;
  validationErrors?: Array<string>,
  helpText?: Array<{
    preText?: string,
    linkText?: string,
    link?: string,
    postText?: string
  }>,
  fundingSources?: Array<Model.FiscalReportInfo>;
  expenditureTotal?: number;
}

export class FiscalReport {
  static iObjectBase(i: any) {
    return {
      id: i.id || undefined,
      proposal_id: i.proposal_id,
      institution_id: i.institution_id || undefined,
      fund_id: i.fund_id || undefined,
      effort_area_id: i.effort_area_id || undefined,
      duration_id: i.duration_id,
      creator_id: i.creator_id || undefined,
      comments: i.comments || '',
      require_explanation: !!i.require_explanation ? true : false,
      below_target_explanation: i.below_target_explanation || '',
      submitted: !!i.submitted ? true : false,
      lines: i.lines || [],
      encumbered_added_explanation: i.encumbered_added_explanation || '',
      encumbered_removed_explanation: i.encumbered_removed_explanation || ''
    };
  }

  static iObject(i: any): IFiscalReport {
    const report = FiscalReport.iObjectBase(i);
    report.lines = report.lines
      .filter(l => l.amount !== undefined)
      .map(FiscalReportLine.iObject);
    return report;
  }

  /**
   * iObjectP
   * @param {any} i Object which will be turned into IFiscalReport
   * Proposal fiscal reports are shaped a little differently than institution fiscal reports.
   */
  static iObjectP(i: any): IFiscalReport {
    const report = FiscalReport.iObjectBase(i);
    report.lines = report.lines
      .filter(l => l.amount !== undefined)
      .map(FiscalReportLine.iObjectP);
    return report;
  }
}

export interface IFiscalReportStatus {
  label: string;
  status: {
    quarter_1: boolean,
    quarter_2: boolean,
    quarter_3: boolean,
    quarter_4: boolean,
  };
}

export class FiscalReportStatus {
  static iObject(i: any): IFiscalReportStatus {
    return {
      label: i.label,
      status: {
        quarter_1: i.status ? i.status.quarter_1 : false,
        quarter_2: i.status ? i.status.quarter_1 : false,
        quarter_3: i.status ? i.status.quarter_1 : false,
        quarter_4: i.status ? i.status.quarter_1 : false,
      }
    }
  }
}

export interface IFiscalReportLine {
  id?: number;
  object_code_id: number;
  category_id?: number;
  amount?: any;
  allocation_duration_id?: number;
  credit?: boolean;
  match?: boolean;
  fund_id?: number;
  fiscal_report_id?: number;
  is_encumbered?: boolean;
}

export class FiscalReportLine {
  static iObject(i: any): IFiscalReportLine {
    return {
      object_code_id: i.object_code_id,
      category_id: i.category_id || null,
      amount: i.amount || 0,
      is_encumbered: i.is_encumbered || false
    }
  }

  static iObjectP(i: any): IFiscalReportLine {
    return {
      object_code_id: i.object_code_id,
      category_id: i.category_id || null,
      id: i.id || undefined,
      fund_id: i.fund_id,
      amount: i.amount || 0,
      fiscal_report_id: i.fiscal_report_id,
      is_encumbered: i.is_encumbered || false
    }
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type FiscalReportData = IFiscalReportData;
    export type FiscalReport = IFiscalReport;
    export type FiscalReportLine = IFiscalReportLine;
    export type FiscalReportStatus = IFiscalReportStatus;
    export type FiscalReportInfo = IFiscalReportInfo;
  }
}
