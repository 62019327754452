<div class="date-time-picker" [ngSwitch]="!!canEdit">
  <label *ngIf="canEdit && showLabel()" for="{{inputId}}" [class.required]="required">
    {{labelText}}
  </label>

  <ng-content select=".help-text"></ng-content>

  <ng-container *ngSwitchCase="true">
    <div class="columns columns--2" [formGroup]="dateTimeInputControl">
      <mat-form-field floatLabel="never">
        <input matInput class="date-picker" id="{{inputId}}" [matDatepicker]="picker"
               [placeholder]="placeHolder"
               formControlName="date"
               [min]="minDate"
               [max]="maxDate"
               (dateChange)="dateInputChange($event)"
               [required]="required">
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon svgIcon="icon-calendar"> </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <ngx-timepicker-field id="{{inputId}}_time"
        formControlName="time"
        [defaultTime]="'00:00 am'"
        (timeChanged)="timeInputChange($event)">
      </ngx-timepicker-field>

      <div class="input-footer">
        <show-errors class="error-text" [control]="hostControl" [showUntouched]="errorsOnUntouched"></show-errors>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="false">
    <p class="label-text" *ngIf="!canEdit && showLabel()">
      {{labelText}}
    </p>

    <div>
      {{getReadOnlyText()}}
    </div>
  </ng-container>
</div>
