import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ROUTER_LINKS } from '@app/core/consts';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { WORKFLOW_METRICS } from '../consts';

@Injectable()
export class MetricDefinitionGuard implements CanActivate {

  constructor(
    private router: Router,
    private logger: NGXLogger
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {

      this.logger.debug('[metric-definition-guard] checking...');

      const metricDefinition = route.params['metricDefinition'];

      if (!WORKFLOW_METRICS.some(m => m.value === metricDefinition)) {
        this.logger.error(`[metric-definition-guard][${state.url}] '${metricDefinition}' is not a valid metric.`);
        this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      this.logger.debug(`[metric-definition-guard][${state.url}] has access to route`);
      subscriber.next(true);
      subscriber.complete();
    });
  }
}