<ng-container *ngIf="initialized" [ngSwitch]="!!isPreview">
  <!-- Preview -->
  <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="content"></ng-container>

  <!-- Edit -->
  <ng-container *ngSwitchCase="false">
    <h2 id="qa_tab_title">{{title}}</h2>
    <!-- guidance -->
    <po-guidance-text [workflowFilter]="guidance" fieldName="guidance"></po-guidance-text>
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </ng-container>
</ng-container>

<!-- Content -->
<ng-template #content>
  <project-goals-metrics-inline
    [canEdit]="canEdit"
    [firstTouch]="firstTouch"
    [isPreview]="isPreview"
    [guidance]="guidance">
  </project-goals-metrics-inline>

  <project-targets-actuals-table *ngIf="collectGoals"
    [canEdit]="canEdit"
    [firstTouch]="firstTouch"
    [isPreview]="isPreview"
    [title]="tableTitle"
    [proposal]="proposal"
    [metricsDefs]="metricsDefs"
    [selectedMetricsIds]="selectedMetricsIds"
    [actualsDurations]="actualsDurations"
    [lbActuals]="lbActuals"
    [swpActuals]="swpActuals"
    [planLength]="planLength"
    [targetInstitutionId]="targetInstitutionId">
  </project-targets-actuals-table>
</ng-template>

