import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload, NewTempId } from '@app-libs';
import { EnumErrorTypes } from '@app-models';

/**
 * List of proposal action types
 */
export const COMMENTS_ACTION_PREFIX = 'COMMENTS_';
export const COMMENTS_ACTION_TYPES = {
  CLEAR: `${COMMENTS_ACTION_PREFIX}CLEAR`,
  SET_ENABLED: `${COMMENTS_ACTION_PREFIX}SET_ENABLED`,
  SET_RESOURCE: `${COMMENTS_ACTION_PREFIX}SET_RESOURCE`,
  LOAD_COMMENTS: `${COMMENTS_ACTION_PREFIX}LOAD_COMMENTS`,

  CREATE_THREAD: `${COMMENTS_ACTION_PREFIX}CREATE_THREAD`,
  CREATE_THREAD_SUCCESS: `${COMMENTS_ACTION_PREFIX}CREATE_THREAD_SUCCESS`,

  CREATE_TEMP_REPLY: `${COMMENTS_ACTION_PREFIX}CREATE_THREAD`,

  UPDATE_COMMENT: `${COMMENTS_ACTION_PREFIX}UPDATE_COMMENT`,
  UPDATE_COMMENT_SUCCESS: `${COMMENTS_ACTION_PREFIX}UPDATE_COMMENT_SUCCESS`,

  RESOLVE_COMMENT: `${COMMENTS_ACTION_PREFIX}RESOLVE_COMMENT`,

  DELETE_COMMENT: `${COMMENTS_ACTION_PREFIX}DELETE_COMMENT`,
  DELETE_COMMENT_SUCCESS: `${COMMENTS_ACTION_PREFIX}DELETE_COMMENT_SUCCESS`,

  SERVICE_FAIL: `${COMMENTS_ACTION_PREFIX}SERVICE_FAIL`,
  NOOP: `${COMMENTS_ACTION_PREFIX}NOOP`,
};

/**
 * Comment action creator class
 */
export class CommentsActions {

  constructor() {}

  clear(): Action {
    return {
      type: COMMENTS_ACTION_TYPES.CLEAR
    };
  }

  setCommentsEnabled(isEnabled: boolean): ActionWithPayload<any> {
    return {
      type: COMMENTS_ACTION_TYPES.SET_ENABLED,
      payload: { isEnabled }
    };
  }

  setResource(resource: Model.Resource): ActionWithPayload<any> {
    return {
      type: COMMENTS_ACTION_TYPES.SET_RESOURCE,
      payload: { resource }
    };
  }

  loadComments(entries: Array<{ resource: Model.Resource, thread: Model.Comment }>) {
    return {
      type: COMMENTS_ACTION_TYPES.LOAD_COMMENTS,
      payload: { entries }
    };
  }

  createThread(resource: Model.Resource, thread: Model.Comment, temp: boolean = true, callback?: () => void): ActionWithPayload<any> {
    return {
      type: COMMENTS_ACTION_TYPES.CREATE_THREAD,
      payload: {
        resource,
        thread: {
          ...thread,
          draft: true // TODO: still need this?
        },
        temp,
        callback
      }
    };
  }

  createThreadSuccess(resource: Model.Resource, thread: Model.Comment): ActionWithPayload<any> {
    return {
      type: COMMENTS_ACTION_TYPES.CREATE_THREAD_SUCCESS,
      payload: { resource, thread }
    };
  }

  createTempReply(thread: Model.Comment) {
    console.log('creating templ reply', thread);
    const temp_id = NewTempId.id()
    return {
      type: COMMENTS_ACTION_TYPES.UPDATE_COMMENT_SUCCESS,
      payload: {
        threadId: thread.id,
        comment: {
          id: temp_id,
          temp_id,
          parent_id: thread.id,
          draft: true,
          parent_temp_id: thread.temp_id
        },
      }
    };
  }

  updateComment(threadId: number, resource: Model.Resource, comment: Model.Comment, callback?: () => void): ActionWithPayload<any> {
    return {
      type: COMMENTS_ACTION_TYPES.UPDATE_COMMENT,
      payload: { threadId, resource, comment, callback }
    };
  }

  updateCommentSuccess(threadId: number, resource: Model.Resource, comment: Model.Comment): ActionWithPayload<any> {
    return {
      type: COMMENTS_ACTION_TYPES.UPDATE_COMMENT_SUCCESS,
      payload: { threadId, resource, comment }
    };
  }

  resolveComment(threadId: number, resolved: boolean, resource: Model.Resource, comment: Model.Comment, callback?: () => void): ActionWithPayload<any> {
    return {
      type: COMMENTS_ACTION_TYPES.RESOLVE_COMMENT,
      payload: { threadId, resolved, resource, comment }
    };
  }

  deleteComment(threadId: number, resource: Model.Resource, comment: Model.Comment, callback?: () => void): ActionWithPayload<any> {
    return {
      type: COMMENTS_ACTION_TYPES.DELETE_COMMENT,
      payload: { threadId, resource, comment, callback }
    };
  }

  deleteCommentSuccess(threadId: number, resource: Model.Resource, comment: Model.Comment): ActionWithPayload<any> {
    return {
      type: COMMENTS_ACTION_TYPES.DELETE_COMMENT_SUCCESS,
      payload: { threadId, resource, comment }
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: COMMENTS_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Comments = new CommentsActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Comments: CommentsActions;
  }
}
