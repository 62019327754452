<alert *ngIf="showDeleteConfirmation" level="warning"
  button-dismiss="Cancel" (dismiss)="closeDeleteConfirmation()"
  button-action="Delete" (action)="confirmDeleteFile()">
  <h4>Delete File</h4>
  <p>Are you sure you want to delete the file “{{formatTitle(fileToDelete)}}“?</p>
</alert>

<alert *ngIf="showReplaceConfirmation" level="warning"
  button-dismiss="Cancel" (dismiss)="showReplaceConfirmation = null;"
  button-action="Continue" (action)="confirmReplaceFile()">
  <h4>Are you sure?</h4>
  <p>You are removing "{{formatTitle(showReplaceConfirmation)}}" and replacing it with a new file upload.</p>
</alert>

<ng-container *ngIf="files?.length; else emptyTemplate || empty">
  <div class="table-overflow">
    <div class="table-overflow__inside--60-rem">
      <table class="po-table" data-qa="qa_documents_table">
        <ng-content select="caption"></ng-content>
        <thead>
          <tr>
            <th *ngIf="_columnsToShow.title" scope="col" class="col-max-15-rem align-left">{{ titleAsSource ? 'Source' : 'Document Title' }}</th>
            <th *ngIf="_columnsToShow.institution" scope="col" class="col-10-rem align-left">{{getColumnTitle('institution', 'Institution')}}</th>
            <th *ngIf="_columnsToShow.type" scope="col" class="col-10-rem align-left">{{getColumnTitle('type', 'Type')}}</th>
            <th *ngIf="_columnsToShow.source_type" scope="col" class="col-10-rem align-left">{{getColumnTitle('source_type', 'Source Type')}}</th>
            <th *ngIf="_columnsToShow.year" scope="col" class="col-10-rem align-left">{{getColumnTitle('year', 'Fund Year')}}</th>
            <th *ngIf="_columnsToShow.user" scope="col" class="col-12-rem align-left">{{getColumnTitle('user', 'Uploaded By')}}</th>
            <th *ngIf="_columnsToShow.time" scope="col" class="col-12-rem align-left">{{getColumnTitle('time', 'Uploaded')}}</th>
            <th *ngIf="_columnsToShow.comment" scope="col" class="col-15-rem align-left">{{getColumnTitle('comment', 'Comment')}}</th>
            <th *ngIf="_columnsToShow.actions" scope="col" class="col-5-rem align-center">{{getColumnTitle('actions', 'Actions')}}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let file of files">
            <th *ngIf="_columnsToShow.title" class="col-max-15-rem align-left" scope="row">
              <ng-container [ngSwitch]="!!file.htmlUrl">
                <!-- No matching HTML file case -->
                <a *ngSwitchCase="false" class="icon-link" href="{{file.url}}" target="_blank">
                  <icon [iconId]="getIconId(file)">Actions</icon>
                  {{ formatTitle(file) }}
                </a>

                <!-- HAS matching HTML file case -->
                <ng-container *ngSwitchCase="true">
                  <p>{{ formatTitle(file) }}</p>
                  <div class="columns columns--2">
                    <a class="icon-link" [href]="file.url" target="_blank">
                      <icon [iconId]="getIconId(file)"></icon>
                      View PDF
                    </a>
                    <a class="icon-link" [href]="file.htmlUrl" target="_blank">
                      <icon iconId="document"></icon>
                      View HTML
                    </a>
                  </div>
                </ng-container>
              </ng-container>
            </th>
            <td *ngIf="_columnsToShow.institution" class="col-10-rem align-left">{{getInstitutionName(file.institution_id)}}</td>
            <td *ngIf="_columnsToShow.type" class="col-10-rem align-left">{{formatDocType(file.document_type_id)}}</td>
            <td *ngIf="_columnsToShow.source_type" class="col-10-rem align-left">{{file.uploaded ? 'File' : 'URL'}}</td>
            <td *ngIf="_columnsToShow.year" class="col-10-rem align-left">{{formatYearName(file.duration_id)}}</td>
            <td *ngIf="_columnsToShow.user" class="col-12-rem align-left">{{formatUserName(file.create_user)}}</td>
            <ng-container *ngIf="_columnsToShow.time" [ngSwitch]="isDateDefined(file.created_at)">
              <td *ngSwitchCase="true" class="col-10-rem">{{formatDateString(file.created_at)}}</td>
              <td *ngSwitchCase="false" aria-label="Not available" class="col-10-rem">N/A</td>
            </ng-container>
            <td *ngIf="_columnsToShow.comment" class="col-15-rem align-left">
              <div>
                <ng-container [ngSwitch]="!!file.comment">
                  <p *ngSwitchCase="true" [innerHtml]="file.comment | replaceBr | safeHtml"></p>
                  <p *ngSwitchCase="false" aria-label="Not available">N/A</p>
                </ng-container>
              </div>
            </td>
            <td *ngIf="_columnsToShow.actions" class="col-5-rem align-center">
              <div class="inline-button-group__container">
                <!--TODO: Make comments editable by clicking edit button-->
                <!--<a class="action-button action-button&#45;&#45;tertiary action-button&#45;&#45;small inline-button"-->
                   <!--data-qa="qa_files_table_edit_button"-->
                   <!--[routerLink]="proposalLink(row)"-->
                   <!--(click)="logEdit()"-->
                   <!--title="Edit"-->
                   <!--&gt;-->
                  <!--<icon iconId="edit"></icon>-->
                <!--</a>-->
                <a class="action-button action-button--tertiary action-button--small inline-button"
                  data-qa="qa_files_table_delete_button"
                  *ngIf="canDelete(file)"
                  title="Delete"
                  (click)="onDeleteIconClick(file)">
                  <icon iconId="delete" data-qa="delete-icon"></icon>
                </a>

                <a *ngIf="canReplace(file)" title="Replace" (click)="replaceFileClicked(file)"
                  class="action-button action-button--tertiary action-button--small inline-button">
                  <icon iconId="sync"></icon>
                </a>
              </div>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<div *ngIf="displayOptionsButtonGroup || (enableUpload && !hideAddButton)" class="card-footer columns columns--2" [ngClass]="{'no-margin': noMargin}">
  <div class="align-left">
    <div class="inline-button-group__container inline-button-group__container--right" *ngIf="displayOptionsButtonGroup">
      <ng-container *ngIf="displayOldFilesButton">
        <button type="button" (click)="toggleOldFiles()"class="action-button action-button--secondary action-button--small"
          [ngClass]="{ 'inline-button': optionsButtonGroupHasMultipleButtons }">
          {{hidingOldFiles ? 'Show' : 'Hide'}} older documents
        </button>
      </ng-container>
      <ng-container *ngIf="displayPastVersionsButton">
        <button type="button" (click)="toggleOldVersions()" class="action-button action-button--secondary action-button--small"
          [ngClass]="{ 'inline-button': optionsButtonGroupHasMultipleButtons }">
          {{hidingPastVersions?'Show all versions':'Hide past versions'}}
        </button>
      </ng-container>
    </div>
  </div>

  <div *ngIf="enableUpload && !hideAddButton" class="align-right margin-top-sm">
    <button type="button"
            (click)="onClickAddDocument()"
            class="action-button action-button--secondary action-button--small icon--left"
            data-qa="qa_add_document_action">
      <icon iconId="plus"></icon>
      Add Document
    </button>
  </div>
</div>

<ng-template #empty>
  <p class="empty-state">No documents.</p>
</ng-template>
