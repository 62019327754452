/**
 * Error type enumerations
 */
export enum EnumErrorTypes {
  none = 0,
  core = 1,
  network,
  api,
  user,
  unhandled,
}

/**
 * Error handler interface
 */
export interface IErrorHandler {
  // type of error
  type: EnumErrorTypes;
  // location of the error
  location?: string;
  // log error to server
  logToServer?: boolean;
  // show error to user
  show?: boolean;
  // prompts user to refresh page
  refresh?: boolean;
  // actual error object
  raw?: any;
  // any details that would help with debugging
  details?: any;
  // User-friendly message
  message?: string;
  messageLevel?: string;
  // show show help desk link - allows display of help desk link without requiring a refresh
  showHelpdeskLink?: boolean;
}

export interface ISelectOption {
  value: any;
  label: string;
  extras?: any;
}

export interface IActionTypes {
  [action: string]: string
}

interface IReorderListData {
  id: number;
  title: string;
  subTitle?: string;
  locked?: boolean;
  subSections?: Array<{
    sectionId: string | number;
    sectionTitle: string;
    items: Array<{
      id: number;
      title: string;
      subTitle?: string;
    }>
  }>
};

interface IProgressBarItem {
  label: string;
  width: number;
  value: number;
  bgClass: string;
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ErrorHandler = IErrorHandler;
    export type SelectOption = ISelectOption;
    export type ActionTypes = IActionTypes;
    export type ReorderListData = IReorderListData;
    export type ProgressBarItem = IProgressBarItem;
  }
}
