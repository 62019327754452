import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { State, Queries } from '@app-ngrx-domains';
import { Observable } from 'rxjs';
import { withLatestFrom, skipWhile, take } from 'rxjs/operators';
import { ROUTER_LINKS } from '@app/core/consts';
import { get } from 'lodash';

@Injectable()
export class WorkflowGuard implements CanActivate {

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private store: Store<State>
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return new Observable((subscriber) => {
      this.logger.debug(`[workflow-guard][${state.url}] starting check`);

      this.store.select(Queries.CurrentProposal.getLoadStatus).pipe(
        skipWhile(status => status.isLoading),
        withLatestFrom(this.store.select(Queries.CurrentProposal.get)),
        take(1)
      ).subscribe(([status, proposal]) => {

        const requirements = route.data.workflowRequirements || [];

        const checkRequirement = (root, req) => {
          const value = req.parameter ? route.params[req.parameter] : req.value.toString();
          const items = [].concat(get(root, req.itemKey, []));
          return items.find(item => item[req.itemField].toString() === value);
        };
        const validRoute = requirements.every(req => {
          const item = checkRequirement(proposal, req);
          if (item && req.requirements) {
            return req.requirements.every(childReq => checkRequirement(item, childReq));
          }
          return !!item;
        });

        if (!validRoute) {
          this.logger.warn(`[workflow-guard][${state.url}] Invalid parameters for route`);
          this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
          subscriber.next(false);
        } else {
          subscriber.next(true);
        }
        subscriber.complete();
      });
    })
  }
}
