<form [formGroup]="form">
  <div class="columns columns--3">
    <div class="columns--1">
      <input-field labelText="Financial Contribution" [canEdit]="canEdit">
        <input inputRef id="financial_contribution_input_{{count}}"
        min="0"
        integerCurrency
        placeholder="$"
        type="text"
        formControlName="financial_contribution"
        (change)="persistAttribute('financial_contribution')" [attr.aria-required]="isRequired"/>
      </input-field>
    </div>

    <div class="columns--2">
      <input-field labelText="In-Kind Match" [canEdit]="canEdit">
        <input inputRef id="in_kind_input_{{count}}"
          min="0"
          integerCurrency
          placeholder="$"
          type="text"
          formControlName="in_kind_match"
          (change)="persistAttribute('in_kind_match')" [attr.aria-required]="isRequired"/>
      </input-field>
    </div>

    <div class="columns--3" *ngIf="includeInkindMatchType">
      <po-select id="in_kind_match_input_{{count}}"
        labelText="In-Kind Match Type"
        placeholderText="Select Type"
        [options]="inKindMatchOptions"
        [canEdit]="canEdit"
        [selectOnce]="true"
        (optionSelected)="persistAttribute('in_kind_match_type')">
        <select inputRef class="po-select__select" formControlName="in_kind_match_type" [attr.aria-required]="isInKindMatchTypeRequired"></select>
      </po-select>
    </div>
  </div>

  <input-field labelText="Description of In-Kind Match" [canEdit]="canEdit" [showCounter]="true">
    <textarea inputRef id="description_input_{{count}}" type="text"
      maxLength="{{narrative_limit}}"
      formControlName="description"
      (change)="persistAttribute('description')"
      [attr.aria-required]="false"></textarea>
  </input-field>
</form>
