<ng-container *ngIf="showMenu && canSudo">
  <ng-container *ngIf="!isProfile; else userProfileActions">
    <span *ngIf="prefix?.length > 0" aria-hidden="true" class="margin-right-xs">{{prefix}}</span>

    <div class="{{menuClass}}" [attr.aria-label]="ariaLabel">
      {{menuText}}
      <ng-container *ngTemplateOutlet="userActions"></ng-container>
    </div>

    <span *ngIf="suffix?.length > 0" aria-hidden="true" class="margin-left-xs">{{suffix}}</span>
  </ng-container>
</ng-container>

<ng-container *ngIf="!(showMenu && canSudo)">
  <span *ngIf="useMailLink">
    by <a href="mailto:{{userData.email_address}}">{{menuText}}</a>
  </span>
  <span *ngIf="!useMailLink">{{ariaLabel}}</span>
</ng-container>

<alert *ngIf="showDeleteUserAlert"
  button-action="Delete"
  button-dismiss="Cancel"
  level="warning"
  (action)="confirmDeleteUser()"
  (dismiss)="dismissDeleteUserAlert()">
  <p><strong>Deleted users cannot log in.</strong> Are you sure you want to delete <strong>"{{userFullName}}"</strong>?</p>
</alert>

<alert *ngIf="showResetUserAlert"
  button-action="Reset"
  button-dismiss="Cancel"
  level="warning"
  (action)="confirmResetUser()"
  (dismiss)="dismissResetUserAlert()">
  <p>
    Are you sure you want to reset the password for <strong>"{{userFullName}}"</strong>?
  </p>
</alert>

<alert *ngIf="showRestoreUserAlert"
  button-action="Restore"
  button-dismiss="Cancel"
  level="warning"
  (action)="confirmRestoreUser()"
  (dismiss)="dismissRestoreUserAlert()">
  <p>
    Are you sure you want to restore <strong>"{{userFullName}}"</strong>?
  </p>
</alert>

<ng-template #userActions>
  <options-menu [buttonId]="'qa_user_options_button' + count"
    [buttonClass]="altButton ? 'options-menu__trigger--alt' : ''"
    [menuId]="'qa_adminConsole_userOptions_list' + count"
    [iconId]="isProfile ? 'disclosure-down' : 'more'"
    class="no-print user-actions__menu">
    <options-menu-item [isVisible]="canShowMenu('view')" optionText="View Profile"
      [routerLink]="userProfileLink">
    </options-menu-item>
    <options-menu-item [isVisible]="canShowMenu('sudo') && !isProfile" optionText="Sudo Login"
      (doEmit)="doEvent('sudo')">
    </options-menu-item>
    <options-menu-item [isVisible]="canShowMenu('reset')" optionText="Send Reset Password Email"
      (doEmit)="doEvent('reset')">
    </options-menu-item>
    <div *ngIf="canShowMenu('delete') || canShowMenu('restore')" class="options-menu__section">
      <options-menu-item optionText="Delete User" [danger]="true" [isVisible]="canShowMenu('delete')"
        (doEmit)="doEvent('delete')">
      </options-menu-item>
      <options-menu-item optionText="Restore User" [danger]="true" [isVisible]="canShowMenu('restore')"
        (doEmit)="doEvent('restore')">
      </options-menu-item>
    </div>
  </options-menu>
</ng-template>

<ng-template #userProfileActions>
  <div class="split-button margin-left">
    <button *ngIf="canShowMenu('sudo')" (click)="doEvent('sudo')" type="button">
      Sudo Login
    </button>
    <ng-container *ngTemplateOutlet="userActions"></ng-container>
  </div>
</ng-template>