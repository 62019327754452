import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Injector, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'po-toggle',
  templateUrl: './toggle.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ToggleComponent), multi: true }
  ]
})

export class ToggleComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
  @Input() labelText: string;
  @Input() labelHidden: boolean = false;
  @Input() labelClass: string;
  @Input() onText: string = 'On';
  @Input() offText: string = 'Off';
  @Input() toggleTextHidden: boolean = false;
  @Input() _value: boolean;
  @Input() disabled: boolean;
  @Input() canEdit: boolean = true;

  get value(): boolean {
    return this._value;
  };

  set value(value: boolean) {
    if (value !== this._value) {
        this._value = value;
        this.onChange(value);
    }
  }

  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter();

  inputControl: FormControl;

  protected lastValue: string;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterContentInit() {
    this.inputControl = this.injector.get(NgControl).control as FormControl;

    setTimeout(() => {
      this.cdr.detectChanges();
    });
  }

  valueChanged() {
    this.writeValue(this.value);
    if (this.toggleChange) {
      this.toggleChange.emit(this.value);
    }
  }

  get showLabel() {
    return this.labelText && !this.labelHidden;
  }

  /////////////////////////////////////////////////////////////////////
  // ControlValueAccessor
  /////////////////////////////////////////////////////////////////////
  writeValue(value: boolean) {
    this.value = value;
  }

  onChange = (_: any) => {
    setTimeout(() => {
      this.cdr.detectChanges();
    });
  };
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  onTouched = (_: any) => { };
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
