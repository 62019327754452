<hr *ngIf="isPreview && !isFirst">

<div [ngClass]="{'card': !isPreview }">
  <div *ngIf="!isPreview" class="card-title">
    <h3 class="h3">{{activityEA.title || "Activity Title"}}</h3>
    <div *ngIf="!!helpText['card']" class="help-text">
      <p>{{helpText['card']}}</p>
    </div>
  </div>

  <form *ngIf="form" [formGroup]="form" [ngClass]="{'first-touch': firstTouch}" autocomplete="off">

    <div class="columns columns--2">
      <input-field [showCounter]="true" labelText="Activity Name" [canEdit]="canEdit && !activityEA?.is_locked">
        <input inputRef id="activity_name_input_{{count}}" type="text" maxlength="50" formControlName="title" (blur)="persistValue('title')" required  aria-required="true"/>
      </input-field>

      <po-select *ngIf="collectObjective" id="activity_objective_select_{{count}}"
        labelText="Objective that Applies to this Activity"
        placeholderText="Select Objective"
        [canEdit]="canEdit && !activityEA?.is_locked"
        [options]="objectives"
        [selectOnce]="true"
        (optionSelected)="persistValue('objective_ea_id')">
        <select inputRef id="objective_select_{{count}}" class="po-select__select" formControlName="objective_ea_id" aria-required="true" required></select>
      </po-select>
    </div>

    <input-field [labelText]="labels.description" [canEdit]="canEdit">
      <div *ngIf="!!helpText['description'] && !isPreview" class="help-text">
        <p>{{helpText['description']}}</p>
      </div>
      <po-html-textarea inputRef id="activity_description_{{count}}" formControlName="description"
        [htmlCharLimit]="textAreaLength"
        (change)="persistValue('description')"
        required aria-required="true"></po-html-textarea>
    </input-field>

    <!-- collect outcomes -->
    <ng-container *ngIf="collectOutcomes">
      <ng-container [ngSwitch]="isCAEP">
        <div *ngSwitchCase="true" class="columns columns--3">
          <input-field labelText="Short-Term Outcomes (12 Months)" [canEdit]="canEdit">
            <div *ngIf="!isPreview" class="help-text">
              <p>
                Provide a brief description of an outcome, to be completed in the next year, that is specific, measurable, achievable, relevant, and time-bound (SMART).
              </p>
            </div>
            <po-html-textarea inputRef id="activity_short_term_outcomes_{{count}}" formControlName="short_term_outcomes"
              [htmlCharLimit]="shortTextAreaLength"
              (change)="persistValue('short_term_outcomes')"
              required aria-required="true"></po-html-textarea>
          </input-field>
          <input-field labelText="Intermediate Outcomes (1-3 Years)" [canEdit]="canEdit">
            <div *ngIf="!isPreview" class="help-text">
              <p>
                Provide a brief description of an outcome, to be completed in the next 1-3 years, that is specific, measurable, achievable, relevant, and time-bound (SMART).
              </p>
            </div>
            <po-html-textarea inputRef id="activity_intermediate_outcomes_{{count}}" formControlName="intermediate_outcomes"
              [htmlCharLimit]="shortTextAreaLength"
              (change)="persistValue('intermediate_outcomes')"
              required aria-required="true"></po-html-textarea>
          </input-field>
          <input-field labelText="Long-Term Outcomes (3-5 Years)" [canEdit]="canEdit">
            <div *ngIf="!isPreview" class="help-text">
              <p>
                Provide a brief description of an outcome, to be completed in the next 3-5 years, that is specific, measurable, achievable, relevant, and time-bound (SMART).
              </p>
            </div>
            <po-html-textarea inputRef id="activity_long_term_outcomes_{{count}}" formControlName="long_term_outcomes"
              [htmlCharLimit]="shortTextAreaLength"
              (change)="persistValue('long_term_outcomes')"
              required aria-required="true"></po-html-textarea>
          </input-field>
        </div>

        <input-field *ngSwitchDefault labelText="Performance Outcome" [canEdit]="canEdit">
          <div *ngIf="!isPreview" class="help-text">
            <p>
              Describe the outcome in qualitative and quantitative terms.
            </p>
          </div>
          <po-html-textarea inputRef id="activity_performance_outcome_{{count}}" formControlName="performance_outcome"
            [htmlCharLimit]="textAreaLength"
            (change)="persistValue('performance_outcome')"
            required aria-required="true"></po-html-textarea>
        </input-field>
      </ng-container>
    </ng-container>

    <div class="columns columns--2">
      <!-- collect timeline -->
      <po-select *ngIf="collectTimeline" id="timeline_select_{{count}}" class = "mt-xl"
                labelText="Timeline - Term and Year"
                [options]="durationsList"
                [selectOnce]="true"
                [canEdit]="canEdit"
                (optionSelected)="persistValue('duration_id')">
        <select class="po-select__select" inputRef formControlName="duration_id" aria-required="true"></select>
      </po-select>

      <!-- collect start-end dates -->
      <input-group *ngIf="collectStartEndDates && form && form.controls['dates']"
        headingText="Dates"
        formGroupName="dates"
        [control]="form.controls['dates']"
        [errorsOnUntouched]="canEdit">
        <div class="columns columns--2">
          <po-month-year-picker id="start_date_{{count}}"
            formControlName="start_date"
            labelText="Projected Start Date"
            [canEdit]="canEdit"
            (dateChange)="persistDate('start_date')"
            [minDateString]="minDateString"
            [maxDateString]="maxDateString"
            [required]="true">
          </po-month-year-picker>

          <po-month-year-picker id="end_date_{{count}}"
            formControlName="end_date"
            labelText="Projected End Date"
            [canEdit]="canEdit"
            (dateChange)="persistDate('end_date')"
            [minDateString]="minDateString"
            [maxDateString]="maxDateString"
            [required]="true">
          </po-month-year-picker>
        </div>
      </input-group>

      <!-- collect completion date -->
      <po-date-picker *ngIf="collectCompletionDate" id="completion_date_{{count}}" labelText="Proposed Completion Date"
        formControlName="end_date"
        [canEdit]="canEdit"
        (dateChange)="persistValue('end_date')"
        [minDateString]="minDateString"
        [required]="true"
      ></po-date-picker>

      <!-- collect metrics -->
      <po-select *ngIf="collectMetrics || collectVisionGoals" id="metrics_select_{{count}}" class = "mt-xl"
        [labelText]="labels['metrics']"
        [canEdit]="canEdit"
        [options]="metricsOptions"
        [selectMultiple]="true"
        (optionSelected)="persistMetric($event)"
        (optionRemoved)="removeMetric($event)">
        <div *ngIf="!!helpText['metrics'] && !isPreview" class="help-text">
          <p>{{helpText['metrics']}}</p>
        </div>
        <select inputRef class="po-select__select" formControlName="success_metrics" [attr.aria-required]="!metricsOptional" [attr.required]="!metricsOptional"></select>
      </po-select>
    </div>

    <po-select *ngIf="collectReportingCategories" id="categories_select_{{count}}" class="mt-xl"
      labelText="Reporting Categories"
      [canEdit]="canEdit"
      [options]="categoryOptions"
      [selectMultiple]="true"
      (optionSelected)="persistCategory($event)"
      (optionRemoved)="removeCategory($event)">
      <div *ngIf="!isPreview" class="help-text">
        <p>Select the Reporting Categories that apply to this Activity</p>
      </div>
      <select inputRef class="po-select__select" formControlName="related_categories" [attr.aria-required]="true" [attr.required]="true"></select>
    </po-select>

    <!-- SWPv3 Sectors -->
    <ng-container *ngIf="isSWPv3">
      <div [attr.data-invalid]="activityEA.regional_priority_sectors.length === 0">
        <p class="label-text">Regional Priority Sectors<span class="error-asterisk" *ngIf="canEdit"> *</span></p>
        <div *ngIf="!isPreview" class="help-text">
          <p>Indicate the percentage of each sector selected (max of 5) that this activity will address.
            Percentages must add up to 100%.</p>
        </div>
        <table *ngIf="prioritySectorsForm?.controls.length; else empty_sectors" class="po-table no-margin">
          <thead>
            <tr>
              <th scope="col">Regional Priority Sector</th>
              <th scope="col" class="align-right col-25-rem">% of Activity Addressed by Priority Sector</th>
              <th *ngIf="canEdit" scope="col" class="align-center col-5-rem">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let psForm of prioritySectorsForm?.controls; index as i; trackBy: trackById" [formGroup]="psForm">
              <td>
                <po-select id="priority_sector_select_{{count}}_{{i}}"
                  class="no-margin"
                  placeholderText="Select Regional Priority Sector"
                  [options]="sectorsOptions"
                  [disabledOptions]="selectedSectors"
                  [selectOnce]="true"
                  [labelHidden]="true"
                  (optionSelected)="persistSector(psForm, 'priority_sector_id')"
                  [canEdit]="canEdit"
                  labelText="Priority Sector">
                  <select inputRef class="po-select__select" formControlName="priority_sector_id" aria-required="true"></select>
                </po-select>
              </td>
              <td class="align-data--right">
                <input-field [labelHidden]="true" [canEdit]="canEdit" labelText="Activity Percentage">
                  <input inputRef type="text"
                    id="priority_sector_activity_percentage_{{count}}_{{i}}"
                    formControlName="activity_percentage"
                    transform="percent"
                    (change)="persistSector(psForm, 'activity_percentage')"/>
                </input-field>
              </td>
              <td *ngIf="canEdit" class="align-center">
                <button title="Delete" class="action-button action-button--tertiary action-button--small"
                  (click)="removeSector(psForm, i)">
                  <icon iconId="delete" data-qa="delete-icon"></icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <show-errors *ngIf="showPrioritySectorError" [control]="prioritySectorsForm" [showUntouched]="!firstTouch" class="error-text"></show-errors>
        <div *ngIf="canEdit" class="btn-section btn-section--center">
          <div class="inline-button-group_container inline-button-group_container--right">
            <button class="icon--left action-button action-button--small"
              [ngClass]="activityEA.regional_priority_sectors.length > 0 ? 'action-button--tertiary' : 'action-button--secondary'"
              type="button" (click)="addSector()"
              [disabled]="activityEA.regional_priority_sectors.length >= 5">
              <icon iconId="plus"></icon>
              Add Priority Sector
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Responsible persons -->
    <div class="columns columns--2">
      <!-- Responsible persons -->
      <div [attr.data-invalid]="activityEA.responsible_user_ids.length === 0">
        <p class="label-text">Responsible person(s)<span class="error-asterisk" *ngIf="canEdit"> *</span></p>
        <div *ngIf="!!helpText['responsible_user'] && !isPreview" class="help-text">
          <p>{{helpText['responsible_user']}}</p>
        </div>
        <table *ngIf="responsible_people?.length; else empty_people" class="po-table">
          <thead>
            <tr class="align-headings--left">
              <th scope="col">Name</th>
              <th *ngIf="canEdit" scope="col" class="align-center col-5-rem">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="align-data--left" *ngFor="let person of responsible_people trackBy: trackByUserValue; let i = index">
              <td>
                <app-user-actions [count]="i" [userData]="person.user" [menuText]="person.label"></app-user-actions>
              </td>
              <td *ngIf="canEdit" class="align-center">
                <a title="Delete" class="action-button action-button--tertiary action-button--small"
                  (click)="removePerson(person)">
                  <icon iconId="delete" data-qa="delete-icon"></icon>
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="canEdit" class="btn-section btn-section--center">
          <div class="inline-button-group_container inline-button-group_container--right">
            <button class="icon--left action-button action-button--small"
              [ngClass]="showAddPersonForm ? 'action-button--tertiary' : 'action-button--secondary'"
              type="button" (click)="toggleAddPersonForm()">
              <icon iconId="plus"></icon>
              Add Responsible Person
            </button>
          </div>
        </div>

        <form *ngIf="showAddPersonForm" [formGroup]="add_person_form">
            <div class="po-select__add_person_input">
              <po-select  id="person_name_select_{{count}}"
                labelText="Name"
                placeholderText="Enter name"
                [disabledOptions]="disabledList"
                [selectOnce]="true"
                [isSearch]="allowGlobalUsers"
                [options]="personOptionsList"
                (search)="updateUserList($event)"
                (optionSelected)="addPerson($event)">
                <select inputRef class="po-select__select" formControlName="name" aria-required="true">
                </select>
              </po-select>
            </div>
        </form>
      </div>

      <ng-container *ngIf="isSWPv3">
        <ng-container *ngTemplateOutlet="dependencies"></ng-container>
      </ng-container>

      <!-- Sectors -->
      <ng-container *ngIf="collectPrioritySector && !isSWPv3">
        <po-select id="priority_sector_select_{{count}}"
          [options]="sectorsOptions"
          [selectOnce]="true"
          labelText="Regional Priority Sector"
          (optionSelected)="persistValue('priority_sector')"
          [canEdit]="canEdit">
          <div *ngIf="!isPreview" class="help-text">
            <p>Select the Regional Priority Sector that this Activity will address</p>
          </div>
          <select inputRef class="po-select__select" formControlName="priority_sector" [attr.aria-required]="!prioritySectorOptional" [attr.required]="!prioritySectorOptional"></select>
        </po-select>
      </ng-container>
    </div>

    <ng-container *ngIf="!isSWPv3">
      <ng-container *ngTemplateOutlet="dependencies"></ng-container>
    </ng-container>

    <div *ngIf="canEdit && !activityEA?.is_locked" class="card-footer card-footer--right">
      <p *ngIf="preventDeleteReason">{{preventDeleteReason}}</p>
      <button type="button" *ngIf="canDelete && !preventDeleteReason" (click)="toggleDeleteActivity();" title="Delete"
        class="action-button action-button--secondary action-button--small inline-button icon--left"
        data-qa="qa_workplan_delete-objective_button">
        <icon iconId="delete" data-qa="delete-icon"></icon>Delete
      </button>
    </div>
  </form>
</div>

<ng-template #empty_people>
  <div *ngIf="!responsible_people?.length" class="empty-state margin-bottom">
    <p>No responsible person(s) added.</p>
  </div>
</ng-template>

<ng-template #empty_sectors>
  <div *ngIf="!prioritySectorsForm?.controls.length" class="empty-state margin-bottom">
    <p>No Regional Priority Sector(s) added.</p>
  </div>
</ng-template>

<!-- Dependencies -->
<ng-template #dependencies>
  <input-field *ngIf="collectDependencies" [formGroup]="form" labelText="Dependencies" [canEdit]="canEdit">
    <div *ngIf="!isPreview" class="help-text">
      <p>Contributions of others required for a successful project outcome.</p>
    </div>
    <po-html-textarea inputRef id="activity_dependencies_{{count}}" formControlName="dependencies"
      [htmlCharLimit]="textAreaLength"
      (change)="persistValue('dependencies')"
      required aria-required="true"></po-html-textarea>
  </input-field>
</ng-template>

<alert *ngIf="showDeleteActivityAlert"
  button-action="Delete"
  button-dismiss="Cancel"
  level="warning"
  (action)="deleteActivity()"
  (dismiss)="toggleDeleteActivity()">
  <ng-container [ngSwitch]="!!activityEA.title">
    <p *ngSwitchCase="true">
      Are you sure you want to delete the activity <strong>{{activityEA.title}}</strong>?
    </p>
    <p *ngSwitchCase="false">
      Are you sure you want to delete this activity?
    </p>
  </ng-container>
</alert>
