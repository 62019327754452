import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Actions, Model, State } from '@app-ngrx-domains';
import { EnumErrorTypes } from '@app/core/models';
import { LookupService } from '@app/core/services';
import { ValidatorsEx } from '@app/core/utilities';
import { CHAR_LIMITS, PROGRAM_KEYS } from '@app/core/consts';

@Component({
  selector: 'project-success-card',
  templateUrl: './success-card.component.html'
})
export class SuccessCardComponent implements OnInit {

  @Input() successStory: Model.EASuccessStory;
  @Input() canEdit: boolean = false;
  @Input() canDelete: boolean = false;
  @Input() proposal: Model.ProposalBase;

  @Output() updateResponsible = new EventEmitter<{deleted: boolean, user_id: number, ea_id: number}>();

  noContacts = false;
  showAddContact = false;
  showInviteModal = false;
  firstTouch = true;
  med_text_length = CHAR_LIMITS.LONG;
  long_text_length = CHAR_LIMITS.EXTRA_LONG;
  programKey: string;
  collectGoals: boolean = false;

  get contactsList() {
    if (this.successStory && this.successStory.contacts) {
      return this.successStory.contacts.map(c => c.user);
    }
    return [];
  }

  goalsList = [
    { id: '1', value: 'Increase by at least 20 percent the number of California Community College students annually who acquire associate degrees, credentials, certificates, or specific skill sets that prepare them for an in-demand job.' },
    { id: '2', value: 'Increase by 35 percent the number of California Community College students transferring annually to a UC or CSU' },
    { id: '3', value: 'Decrease the average number of units accumulated by California Community College students earning associate degrees' },
    { id: '4', value: 'Increase the percent of exiting CTE students who report being employed in their field of study' },
    { id: '5', value: 'Reduce equity gaps across all of the above measures through faster improvements among traditionally underrepresented student groups' },
    { id: '6', value: 'Reduce regional achievement gaps across all of the above measures through faster improvements among colleges located in regions with the lowest educational attainment of adults' },
  ]

  goalUniques(_: number, goal: any) {
    return goal.type;
  }

  get disabledOptions() {
    return this.contactsList.map(c => c.id);
  }
  contactsLookupFormatted: Array<Model.SelectOption> = [];

  form: FormGroup;
  contactForm: FormGroup;

  showDeleteWarning = false;

  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
    private lookupService: LookupService
  ) {}

  ngOnInit() {
    this.programKey = this.proposal.funds[0].parent_key || this.proposal.funds[0].key;
    this.collectGoals = [PROGRAM_KEYS.GP].includes(this.programKey);

    const requireValidation = () => {
      return this.isRequired;
    }

    const controls = {
      title: [this.successStory.title, [ValidatorsEx.conditionalValidators(requireValidation, [Validators.required, Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN)])]],
      description: [this.successStory.description, [ValidatorsEx.conditionalValidators(requireValidation, [ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)])]],
      challenge: [this.successStory.challenge, [ValidatorsEx.conditionalValidators(requireValidation, [ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)])]],
      outcomes: [this.successStory.outcomes, [ValidatorsEx.conditionalValidators(requireValidation, [ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)])]],
    }
    if (this.collectGoals) {
      const _formShape = {};
      this.goalsList.forEach(goal => {
        _formShape[goal.id] = [!!(this.successStory.goal_types.find(gt => gt.value === goal.value))];
      })
      controls['goals'] = this._fb.group(_formShape, { validators: [ValidatorsEx.conditionalValidators(requireValidation, [ValidatorsEx.requiredCheckGroup])] });
    }
    this.form = this._fb.group(controls);

    // vision for success goals should be FKed to program settings visions for success.
    this.contactForm = this._fb.group({
      contactId: [undefined, [ValidatorsEx.conditionalValidators(requireValidation, [Validators.required])]]
    })
  }

  persistValue(attributeName: string) {
    const value = this.form.get(attributeName).value;
    const prevValue = this.successStory[attributeName];

    if (this.successStory.id) {
      if (value !== null && value !== prevValue) {
        this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: attributeName, value, ea: this.successStory }))
      }
    } else {
      this.store.dispatch(Actions.CurrentProposal.createEffortArea({
        ea: {
          ...this.successStory,
          [attributeName]: value
        }
      }))
    }
  }

  newContact() {
    this.showAddContact = !this.showAddContact;
    this.noContacts = !this.noContacts;
  }

  removeClicked() {
    this.successStory.contacts.map(async (c) => {
      if (c.value > 0) {
        await this.deleteUser(Number(c.value));
      }
    })
    this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({ ea: this.successStory }));
  }

  confirmContactModal() {
    const contact = this.contactForm.get('contactId').value;
    this.associateUser(contact.value);
    this.cancelContactModal();
  }

  cancelContactModal() {
    this.showAddContact = false;
    this.contactForm.reset({ contact: null });
  }

  updateContactsList(filter, limit) {
    this.lookupService.formattedContactList$(filter, limit)
      .subscribe(
        (res) => {
          this.contactsLookupFormatted = res;
        },
        (err) => {
          this.store.dispatch(Actions.App.setError({
            type: EnumErrorTypes.api,
            location: this.constructor.name,
            show: true,
            raw: err
          }));
        }
      );
  }

  clickVFSGoal(attr: { id: number, value: string }) {
    const checked = this.form.value['goals'][attr.id];
    if (!checked) {
      this.store.dispatch(Actions.CurrentProposal.deleteMultiAttribute({ key: 'goal_types', value: attr.value, ea: this.successStory }));
    } else {
      this.store.dispatch(Actions.CurrentProposal.addMultiAttribute({ key: 'goal_types', value: attr.value, ea: this.successStory }));
    }
  }

  associateUser(user_id: number) {
    this.store.dispatch(Actions.CurrentProposal.addMultiAttribute({ key: 'contacts', value: user_id, ea: this.successStory }));
    this.updateResponsible.emit({ deleted: false, user_id, ea_id: this.successStory.id });
    this.noContacts = false;
  }

  deleteUser(user_id: number) {
    this.store.dispatch(Actions.CurrentProposal.deleteMultiAttribute({ key: 'contacts', value: user_id, ea: this.successStory }));
    this.updateResponsible.emit({ deleted: true, user_id, ea_id: this.successStory.id });
  }

  inviteSuccess(user: Model.User) {
    this.contactForm.get('contactId').setValue({ value: user.id });
    this.associateUser(user.id);
    this.showInviteModal = false;
  }

  get isRequired() {
    return !!(
      this.successStory.title
      || this.successStory.description
      || (this.successStory.contacts && this.successStory.contacts.length > 0)
      || this.successStory.challenge
      || this.successStory.outcomes
      || (this.collectGoals && this.successStory.goal_types.length > 0)
    );
  }

  get newContactValid(): boolean {
    return this.contactForm.valid;
  }

  public toggleDeleteWarning() {
    this.showDeleteWarning = !this.showDeleteWarning;
  }
}
