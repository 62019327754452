import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Model, State, Queries, Actions } from '@app-ngrx-domains';
import { LookupService, ApiService } from '@app/core/services';
import { Store } from '@ngrx/store';
import { IContactItem } from '../contact-role-item/contact-role-item.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Profile, UserRoleScope } from '@app/core/models';
import { PROJECT_ROLES } from '@app/core/consts';

/*
  This component deals with ONE institution, not all institutions.
  Handles i/o between the contact-role-item and the store.
*/
@Component({
  selector: 'contact-institution',
  templateUrl: './contact-institution.component.html'
})
export class ContactInstitutionComponent implements OnInit, OnDestroy {
  @Input() type: Model.EAContactType;
  @Input() institution: Model.SelectOption = { value: null, label: 'institution name' }; // this is the institution this card should use.
  @Input() context: Model.ContactContext;
  @Input() set canEdit(v) { this._canEdit = v };
  private _canEdit;
  get canEdit(): boolean {
    return !this.type.readOnly && this._canEdit;
  }
  @Input() contactIndex: number = 0;
  firstTouch: boolean = true;
  contacts: Array<IContactItem> = [];
  title: string = '';
  typeName: string;

  private destroy$ = new Subject();

  constructor(
    protected lookupService: LookupService,
    protected apiService: ApiService,
    protected store: Store<State>
  ) {}

  ngOnInit() {
    switch (this.type.role.id) {
      case PROJECT_ROLES.PROJECT_LEAD.ID:
        this.typeName = 'pointofcontact';
        break;
      case PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID:
        this.typeName = 'alternate';
        break;
    }
    if (this.type.approver) {
      this.typeName = 'approver';
    }
    this.store.select(Queries.Contacts.get).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe(contacts => {
      const institutionRoleContacts = contacts.filter(c => {
        let matches = true;
        if (this.type.role.institution) {
            matches = c.institution_id === this.institution.value;
        }
        if (c.designee) {
          matches = matches && c.designee === this.type.designee;
        }
        return c.role_id === this.type.role.id && matches;
      }); 
      this.mapToContactItems(institutionRoleContacts);
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  mapToContactItems(contacts = []) {
    this.contacts = [];
    this.setTitle();
    if (this.type.readOnly) {
      this._canEdit = false;
      if (this.contacts.length < 1) {
        // there's a lookup thing we have to do
        this.fetchReadonlyUser(this.type.role.id);
      }
      return; // readOnly shouldn't do any other things
    }


    if (this.type.institution) {
      let contact;
      contact = contacts.find(c => c.institution_id === this.institution.value);
      contacts.filter(c => c.institution_id === this.institution.value).forEach(c => {
        this.addOldContact(contact);
      })
    } else if (this.type.multi) {
      for (const contact of contacts) {
        this.addOldContact(contact);
      }
      this.contacts.forEach(c => { c.canDelete = true; })
    } else if (this.type.designee) {
      // assemble contact/designee. There should only be two, so this should always work as we expect.
      const contact = contacts.find(c => !c.designee);
      const designee = contacts.find(c => c.designee) || {};
      this.addOldContact(contact, designee);
    } else {
      // just map the contact in
      if (contacts.length > 0) {
        const contact = contacts[0] || {};
        this.addOldContact(contact)
      }
    }

    if (this.type.required) {
      // do required things. We need at least one, so we should make an empty one and display it.
      if (this.contacts.length < this.type.required) {
        for (let i = 0; i < this.type.required; i++) {
          let contact;
          if (this.type.institution) {
            contact = this.generateEmptyContact()
            contact.institution_id = this.institution.value;
          }
          this.addOldContact(contact);
        }
      }
    }
  }

  private fetchReadonlyUser(roleId: number) {
    this.apiService.listProfiles({ role_ids: [roleId], fund_ids: [this.context.fund_id] })
    .subscribe((response: { count: number, users: Array<Profile>}) => {
      if (response && response.count > 0) {
        const contact = this.generateEmptyContact();
        contact.user = response.users[0];
        this.addOldContact(contact);
      }
    })
  }

  private setTitle() {
    this.title = this.type.title ? this.type.title : this.lookupService.getRoleLongName(this.type.role.id);
    if (this.type.institution) {
      this.title += ` - ${this.institution.label}`
    }
  }

  contactName(c: UserRoleScope) {
    if (c.user) {
      return Profile.contactFullName(c.user);
    }
    return '';
  }

  addOldContact(contact: Model.UserRoleScope = null, designee: Model.UserRoleScope = {}) {
    if (!contact) {
      contact = this.generateEmptyContact();
    } else {
      this.firstTouch = false;
    }
    if (this.type.designee && !designee.user_id) {
      designee = this.generateEmptyContact(true);
    }
    this.contacts.push({
      title: this.title,
      required: this.type.required > 0,
      hasDesignee: this.type.designee,
      readOnly: this.type.readOnly,
      canDelete: this.canEdit && this.type.multi,
      pc: contact, // these need to be actual URS items
      dc: designee,
      contactOnly: false,
    });
  }

  private generateEmptyContact(designee = false): UserRoleScope {
    return new UserRoleScope({
      role_id: this.type.role.id,
      proposal_id: this.context.proposal_id,
      user_id: null,
      institution_id: this.type.role.institution ? this.institution.value : null,
      fund_id: (this.type.role.fund || this.type.role.proposal) ? this.context.fund_id : null,
      user: {},
      designee,
    });
  }

  onAssociateContact(role_scope: UserRoleScope, index: number) {
    this.store.dispatch(Actions.Contacts.add(role_scope))
  }

  onRemoveContact(event) {
    if (event.user_id) {
      // actually remove it
      this.store.dispatch(Actions.Contacts.delete(event));
    } else {
      this.contacts = this.contacts.filter(c => c.pc !== event)
    }
  }
}
