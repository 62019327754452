import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'po-rank-control',
  templateUrl: './rank-control.component.html',
})
export class RankControlComponent implements OnInit {
  @Input() id: number;
  @Input() rank: number;
  @Input() canEdit = false;
  @Output() up: EventEmitter<number> = new EventEmitter();
  @Output() down: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
}
