import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeNonprintChars' })
export class RemoveNonprintChars implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return value;
    }
    return value.replace(/[^ -~]+/g, ' ');
  }
}
