import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import { LookupService, ProgramService } from '@app-services';
import { FUND_TYPES, FUND_SETTINGS, OBJECT_CODES, FORECAST_SUM_HELP_TEXT, AEBG_BUDGET_V1_MAX_YEAR } from '@app-consts';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { Budget } from '@app-models';
import { CHAR_LIMITS, PROGRAM_KEYS } from '@app-consts';

@Component({
  selector: 'app-budget-item',
  templateUrl: './budget-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetItemComponent implements OnInit {
  @Input() canEdit = true;
  @Input() firstTouch: boolean;
  @Input() count: number;
  @Input() set budgetItem(budgetItem: Model.BudgetItem) {
    this.budget = new Budget(budgetItem);
  }
  @Input() institutions: Array<Model.LookupTableMenuOption>;
  @Input() objectCodes: any;
  @Input() fundType: number; // fund_id
  @Input() fundYearDurationId: number;
  @Input() leadInstitutionId: number;
  @Input() leadInstitutionName = '';
  @Input() totalAllocation = 0;
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() dupe: EventEmitter<any> = new EventEmitter();
  @Output() touch: EventEmitter<any> = new EventEmitter();

  budget: Budget;
  budgetForm: FormGroup;
  forecastForm: AbstractControl;
  showCollegeErrorAlert = false;

  fundObjectCodes: Array<any> = [];
  budgetTypes: Array<Model.LookupTableMenuOption> = [];
  descrLengthLimit = CHAR_LIMITS.HAPPY_MEDIUM;
  institutionLabel = 'Lead Institution';
  isAebgV1BudgetItem = false;

  forecastHelpText = FORECAST_SUM_HELP_TEXT;

  constructor(
      private _fb: FormBuilder,
      private store: Store<State>,
      private lookupService: LookupService,
      private programService: ProgramService
    ) {}

  ngOnInit() {
    this.initialize();
  }

  private initialize() {
    // Set any missing fields
    if (!this.budget.hasInstitutionId) {
      this.budget.setInstitutionId(this.leadInstitutionId);
    }

    if (!this.budget.hasFundId()) {
      this.setMissingFundId();
    }

    if (this.budget.isIPlan()) {
      this.setBudgetTypes();
    }
    this.setFundObjectCodes();
    this.buildForm();
    this.isReadOnly();

    if (this.budget.isAEBG()) {
      this.institutionLabel = 'Member Institution';

      if (this.fundYearDurationId <= AEBG_BUDGET_V1_MAX_YEAR) {
        this.isAebgV1BudgetItem = true;
      }
    }
  }

  private setMissingFundId() {
    let fundId;
    if (this.budget.isIPlan() && this.budget.hasBudgetType()) {
      const budgetType = this.budget.getBudgetType();
      fundId = Budget.fundIdByBudgetType(budgetType);
    } else if (this.budget.isIPlan() && !this.budget.hasBudgetType()) {
      fundId = FUND_TYPES.SSSP;
    } else {
      fundId = this.fundType;
    }

    this.budget.setFundId(fundId);
  }

  private setBudgetTypes() {
    this.budgetTypes = this.programService.getChildProgramsByParentKey(PROGRAM_KEYS.IPLAN).map(fund => {
      return { value: fund.id, label: fund.name };
    })
  }

  private setFundObjectCodes() {
    this.fundObjectCodes = this.objectCodes[this.budget.getFundId()]
      .map((objCode) => {
        return {
          value: objCode.id,
          label: objCode.name,
        };
      });
  }

  private buildForm() {
    const formGroup = this.budget.generateFormGroup(this.firstTouch, this.isReadOnly(), this._fb);
    this.budgetForm = this._fb.group(formGroup);
    if (this.isSwpAebgBudgetItem) {
      this.forecastForm = this.budgetForm.controls['forecastForm'];
    }
  }

  public deleteBudgetItem() {
    this.delete.emit({ id: this.budget.getId() });
  }

  public dupeBudgetItem() {
    const dupeItem = this.budget.serverObject();
    delete dupeItem.id;
    this.dupe.emit(dupeItem);
  }

  public updateForecast(event: { quarter: string, value: number }) {
    this.budgetForm.controls['forecastForm'].get(event.quarter).setValue(event.value);
    this.updateBudgetItem();
  }

  public updateBudgetItem() {
    const formValue = this.budgetForm.value;

    if (formValue.institution_id) {
      const institution = this.getInstitution(formValue.institution_id);
      this.budget.setInstitution(institution);
    }

    if (this.budget.isAEBG()) {
      if (formValue.object_code_id === OBJECT_CODES.INDIRECT_COSTS) {
        // clear budget as consortium expense on indirect costs.
        this.budget.isConsortiumExpense = false;
        formValue.budget_type = this.budget.getBudgetType();
        this.budgetForm.get('consortium_expense').disable();
      } else {
        this.budgetForm.get('consortium_expense').enable();
      }
    }

    let fields = {...this.budgetForm.value};
    if (this.forecastForm) {
      fields = {...fields, ...this.forecastForm.value };
    }
    this.budget.batchUpdate(fields);

    this.store.dispatch(
      Actions.Budget.updateBudgetItem(this.budget.serverObject())
    );

    if (this.touch) {
      this.touch.emit(true);
    }
  }

  get isIndirectCost() {
    return this.budgetForm.get('object_code_id').value === OBJECT_CODES.INDIRECT_COSTS;
  }

  public updateConsortiumExpense() {
    // toggle check mark
    this.budget.isConsortiumExpense = !this.budget.isConsortiumExpense;
    // set it in form
    this.budgetForm.get('budget_type').setValue(this.budget.getBudgetType());
    this.updateBudgetItem();
  }

  private getInstitution(id: number) {
    const foundDx = _.findIndex(this.institutions, { value: id });
    if (foundDx >= 0) {
      return {
        ...this.institutions[foundDx],
        id: this.institutions[foundDx].value,
        name: this.institutions[foundDx].label,
      };
    }
  }

  public getPercentOfAllocation() {
    return Budget.percentOf(this.budget.getAmount(), this.totalAllocation);
  }

  public shouldShowErrorAlert() {
    if (this.budget.isSWPLocalShare() && !this.budget.hasInstitutionId()) {
      this.showCollegeErrorAlert = true;
    } else {
      this.showCollegeErrorAlert = false;
    }
  }

  public isSwpAebgBudgetItem() {
    return this.budget.isSWP() || this.budget.isAEBG();
  }

  getBudgetItemTitle() {
    return this.budgetForm.get('title').value;
  }

  get fundYearName() {
    return this.fundType !== FUND_TYPES.GP ? this.lookupService.years[this.fundYearDurationId].name : undefined;
  }

  public getDollarsFromForecast(quarterForecastControl: string, budgetAmountControl: string) {
    const percentControlValue = this.forecastForm.get(quarterForecastControl).value / 100;
    const totalValue = this.budgetForm.get(budgetAmountControl).value;
    return totalValue * percentControlValue || 0;
  }

  public getBudgetTotal() {
    return this.budgetForm.get('amount').value;
  }

  /**
   * Helper function for character limit display
   * @param input
   */
  public isMaxLength(input) {
    return input.value.length === input.maxLength;
  }

  public isReadOnly() {

    return (this.fundType === FUND_TYPES.SWP_L || this.fundType === FUND_TYPES.SWP_R)
      && this.fundYearDurationId < FUND_SETTINGS[this.fundType].currentYear;
  }

}
