
<ng-container [ngSwitch]="!!hasPopup">
  <po-pop-up *ngSwitchCase="true" [hideClose]="true" style="position: relative;">
    <div class="badge badge--flex badge--{{type}} badge--clickable" popUpTrigger>
      <ng-container *ngTemplateOutlet="content"></ng-container>
      <icon *ngIf="iconId" class="icon--{{type}} icon--right" [iconId]="iconId"></icon>
    </div>
    <div popUpContent>
      <ng-content select="[badgePopup]"></ng-content>
    </div>
  </po-pop-up>

  <span *ngSwitchCase="false" class="pop-up--trigger">
    <div class="badge badge--flex badge--{{type}}" [ngSwitch]="!!labelText">
      <ng-container *ngSwitchCase="true">{{labelText}}</ng-container>
      <ng-container *ngSwitchDefault [ngTemplateOutlet]="content"></ng-container>
      <icon *ngIf="iconId" class="icon--{{type}} icon--right" [iconId]="iconId"></icon>
    </div>
  </span>
</ng-container>

<ng-template #content>
  <ng-content select="[badgeLabel]"></ng-content>
</ng-template>
