import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State, Model, Actions } from '@app-ngrx-domains';
import { ValidatorsEx } from '@app/core/utilities';
import { LookupService, ProgramService } from '@app/core/services';
import { CHAR_LIMITS } from '@app/core/consts';
import { BudgetAmountAttribute, Fund } from '@app-models';
import { get, groupBy } from 'lodash';

@Component({
  selector: 'app-budget-v2-card',
  templateUrl: './budget-card.component.html'
})
export class BudgetV2CardComponent implements OnInit {

  @Input() set canEdit(allowed: boolean) {
    this._canEdit = allowed;
    if (this.form) {
      this.buildColumns();
    }
  }
  @Input() isPreview: boolean;
  @Input() proposalId: number;
  @Input() institution: Model.Institution;
  @Input() fund: Model.Fund;
  @Input() parentEffortAreaId: number;
  @Input() yearId: number;
  @Input() title: string;
  @Input() budgetItems: Array<Model.EAPlanBudgetItem>;
  @Input() isModifying: boolean;
  @Input() modifiableObjectCodeOptions: Array<Model.SelectOption>;
  @Input() objectCodeOptions: Array<Model.SelectOption> = [];
  @Input() activityOptions: Array<Model.SelectOption> = [];
  @Input() allowCloning: boolean;
  @Input() disableCloning = false;
  @Input() displayColumns: Array<string>;
  @Input() isRollup: boolean = false;
  @Input() preSorted: boolean = false;
  @Input() match: boolean = false;
  @Output() cloneFromPrevious: EventEmitter<null> = new EventEmitter();

  @ViewChild('objectCodeTmpl', {static: true}) objectCodeTmpl: TemplateRef<any>;
  @ViewChild('yearTmpl', {static: true}) yearTmpl: TemplateRef<any>;
  @ViewChild('relatedActivityTmpl', {static: true}) relatedActivityTmpl: TemplateRef<any>;
  @ViewChild('descriptionTmpl', {static: true}) descriptionTmpl: TemplateRef<any>;
  @ViewChild('ongoingOneTimeTmpl', {static: true}) ongoingOneTimeTmpl: TemplateRef<any>;
  @ViewChild('programTmpl', {static: true}) programTmpl: TemplateRef<any>;
  @ViewChild('amountTmpl', {static: true}) amountTmpl: TemplateRef<any>;
  @ViewChild('matchAmountTmpl', {static: true}) matchAmountTmpl: TemplateRef<any>;
  @ViewChild('actionsTmpl', {static: true}) actionsTmpl: TemplateRef<any>;

  columns: Array<{
    id: string;
    name: string;
    cellTemplate: TemplateRef<any>;
    cellCssClass?: string;
    headerCssClass?: string;
    previewOnly: boolean;
  }> = [];
  form: FormGroup;
  showForm: boolean;
  showCloneAlert: boolean;
  editingItem: Model.EAPlanBudgetItem;
  deletingItem: Model.EAPlanBudgetItem;
  descriptionLimit: number = CHAR_LIMITS.HAPPY_MEDIUM;

  get canEdit(): boolean {
    return this._canEdit;
  }
  private _canEdit: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
    private lookupService: LookupService,
    private programService: ProgramService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.buildColumns();

    if (this.isRollup) {
      this.rollupBudgetItems();
    }

    if (this.isPreview && !this.preSorted) {
      this.budgetItems = this.budgetItems.sort((a, b) => {
        const prop = this.isRollup ? this.columns[0].id : 'duration_id';
        return a[prop] > b[prop] ? 1 : -1;
      });
    }
  }

  initForm() {
    let amountCtrls = {};

    if (this.match) {
      amountCtrls = {
        direct_amount: new FormControl(undefined),
        monetary_match_amount: new FormControl(undefined)
      }
    } else {
      const amountValidators = !!(this.modifiableObjectCodeOptions || []).length ? [Validators.required] : [Validators.required, Validators.min(1)];
      amountCtrls = {
        direct_amount: new FormControl(undefined, amountValidators)
      }
    }

    this.form = this._fb.group({
      object_code_id: [undefined, [ValidatorsEx.requiredSelection]],
      related_activities: [undefined, [ValidatorsEx.requiredSelection]],
      description: [undefined, [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)]],
      ongoing: [undefined, [Validators.required]],
      amounts: this._fb.group(amountCtrls, { validator: this.match ? ValidatorsEx.requireOne(true) : [] })
    });
  }

  buildColumns() {
    // Define table columns
    const columnDefinitions = [
      {
        id: 'object_code_id',
        name: 'Expenditure Type',
        cellTemplate: this.objectCodeTmpl,
        headerCssClass: this.isRollup ? '' : 'col-15-rem',
        previewOnly: false
      },
      {
        id: 'year',
        name: 'Year',
        cellTemplate: this.yearTmpl,
        headerCssClass: this.isRollup ? '' : 'col-5-rem',
        previewOnly: true
      },
      {
        id: 'related_activities',
        name: 'Related Activity',
        cellTemplate: this.relatedActivityTmpl,
        headerCssClass: this.isRollup ? '' : 'col-15-rem',
        previewOnly: false
      },
      {
        id: 'description',
        name: 'Description',
        cellTemplate: this.descriptionTmpl,
        headerCssClass: this.isRollup ? '' : 'col-20-rem',
        previewOnly: false
      },
      {
        id: 'ongoing',
        name: 'One-time/Ongoing',
        cellTemplate: this.ongoingOneTimeTmpl,
        headerCssClass: this.isRollup ? '' : 'col-10-rem',
        previewOnly: false
      },
      {
        id: 'fund_id',
        name: this.isRollup ? 'Program' : 'Budget Amount Program Source',
        cellTemplate: this.programTmpl,
        headerCssClass: this.isRollup ? '' : 'col-10-rem',
        previewOnly: false
      }
    ];
    const defaultColumns = ['object_code_id', 'year', 'related_activities', 'description', 'ongoing'];
    this.columns = this.displayColumns
      ? columnDefinitions.filter(column => this.displayColumns.includes(column.id))
      : columnDefinitions.filter(column => defaultColumns.includes(column.id));

    this.columns.push({
      id: 'amount',
      name: 'Amount',
      cellTemplate: this.amountTmpl,
      headerCssClass: 'col-10-rem align-right',
      cellCssClass: 'align-right',
      previewOnly: false
    });

    if (this.match) {
      this.columns.push({
        id: 'monetary_match_amount',
        name: 'Match Amount',
        cellTemplate: this.matchAmountTmpl,
        headerCssClass: 'col-10-rem align-right',
        cellCssClass: 'align-right',
        previewOnly: false
      });
    }

    if (this.canEdit) {
      this.columns.push({
        id: 'actions',
        name: 'Actions',
        cellTemplate: this.actionsTmpl,
        headerCssClass: 'col-5-rem align-center',
        previewOnly: false
      })
    }
  }

  submitForm() {
    if (this.editingItem) {
      this.saveChanges();
    } else {
      this.createBudgetItem();
    }
    this.toggleShowForm();
  }

  rollupBudgetItems(): void {
    const rollup = [];
    const prop = this.columns[0].id;
    const budgetItems = groupBy(this.budgetItems, prop);

    Object.keys(budgetItems).forEach(id => {
      const budgetItem = {};
      budgetItem[prop] = Number(id);

      const directAmount = budgetItems[id].reduce((total, item) => total + (item.direct_amount || 0), 0);
      if (directAmount) {
        budgetItem['direct_amount'] = directAmount;
      }

      if (this.match) {
        const matchAmount = budgetItems[id].reduce((total, item) => total + (item.monetary_match_amount || 0), 0);
        if (matchAmount) {
          budgetItem['monetary_match_amount'] = matchAmount;
        }
      }

      rollup.push(budgetItem);
    });

    this.budgetItems = rollup;
  }

  createBudgetItem() {
    const values = this.form.value;
    const ea = {
      effort_area_type: 'plan_budget_items',
      parent_proposal_id: this.proposalId,
      duration_id: this.yearId,
      object_code_id: values.object_code_id,
      description: values.description,
      direct_amount: this.isModifying ? undefined : values.amounts.direct_amount,
      revised_amount: this.isModifying ? values.amounts.direct_amount : undefined,
      related_activities: values.related_activities,
      ongoing: values.ongoing,
    };
    if (this.match) {
      ea['monetary_match_amount'] = values.amounts.monetary_match_amount;
    }
    if (this.institution) {
      ea['institution_id'] = this.institution.id;
    }
    if (this.fund) {
      ea['fund_id'] = this.fund.id;
    }
    if (this.parentEffortAreaId) {
      ea['parent_effort_area_id'] = this.parentEffortAreaId;
    }

    this.store.dispatch(Actions.CurrentProposal.createEffortArea({
      ea,
      hasAttributes: true
    }));
  }

  saveChanges() {
    const attributes = [];
    Object.entries(this.form.value).forEach(([attribute, value]) => {
      if (attribute === 'related_activities') {
        const currentActivity = get(this.editingItem, 'related_activities[0].value');
        if (value !== currentActivity) {
          attributes.push({ key: attribute, value, ea: this.editingItem });
          if (!!currentActivity) {
            this.store.dispatch(Actions.CurrentProposal.deleteMultiAttribute({ key: 'related_activities', value: currentActivity, ea: this.editingItem }));
          }
        }
      } else if (attribute === 'amounts') {
        Object.keys(value).forEach(item => {
          const attr = this.isModifying && item === 'direct_amount' ? 'revised_amount' : item;
          if (value[item] !== this.editingItem[attr]) {
            attributes.push({ key: attr, value: value[item], ea: this.editingItem });
          }
        });
      } else {
        if (value !== this.editingItem[attribute]) {
          attributes.push({ key: attribute, value, ea: this.editingItem });
        }
      }
    });

    this.store.dispatch(Actions.CurrentProposal.upsertAttributes(attributes));
  }

  toggleShowForm() {
    this.showForm = !this.showForm;
    if (!this.showForm) {
      this.form.reset();
      this.editingItem = undefined;
    }
  }

  toggleCloneAlert() {
    this.showCloneAlert = !this.showCloneAlert;
  }

  clonePrevious() {
    this.cloneFromPrevious.emit();
    this.showCloneAlert = false;
  }

  editBudgetItem(budgetItem: Model.EAPlanBudgetItem) {
    const activity = budgetItem.related_activities.length ? budgetItem.related_activities[0] : undefined;

    let amountValues = { direct_amount: this.getAmount(budgetItem) };

    if (this.match) {
      amountValues['monetary_match_amount'] = budgetItem.monetary_match_amount;
    }

    this.form.setValue({
      object_code_id: budgetItem.object_code_id,
      related_activities: activity ? activity.value : 0,
      description: budgetItem.description,
      ongoing: budgetItem.ongoing,
      amounts: amountValues
    });

    this.editingItem = budgetItem;
    this.showForm = true;
  }

  promptDeleteItem(budgetItem: Model.EAPlanBudgetItem) {
    this.deletingItem = budgetItem;
  }

  deleteBudgetItem() {
    this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({
      ea: this.deletingItem
    }));

    this.deletingItem = undefined;
  }

  getObjectCode(objectCodeId: number) {
    const objectCode = this.objectCodeOptions.find(option => option.value === objectCodeId);
    return objectCode && objectCode.label || 'Unknown Object Code';
  }

  getYear(yearId: number) {
    return this.lookupService.getYearName(yearId);
  }

  getActivity(relatedActivities: Array<Model.AttributeValue>) {
    const activityId = relatedActivities.length ? relatedActivities[0].value : undefined;
    const activity = this.activityOptions.find(option => option.value === activityId);
    return activity && activity.label || 'Unknown Activity';
  }

  getProgramName(id) {
    const program = this.programService.getProgramById(id);
    return Fund.getShortestName(program);
  }

  getBudgetTotal(amount: BudgetAmountAttribute) {
    return this.budgetItems.reduce((total, item) => total + (item[amount] || 0), 0);
  }

  getAmount(budgetItem: Model.EAPlanBudgetItem) {
    return this.isModifying && (budgetItem.revised_amount || budgetItem.revised_amount === 0) ? budgetItem.revised_amount : budgetItem.direct_amount;
  }

  getColumnClasses(
    budgetItem: Model.EAPlanBudgetItem, 
    column: {
      id: string;
      name: string;
      cellTemplate: TemplateRef<any>;
      cellCssClass?: string;
      headerCssClass?: string;
      previewOnly: boolean;
    }
  ) {
    let classes = column.cellCssClass ? column.cellCssClass : '';
    if (this.isModifying && column.id === 'amount' && this.isRevised(budgetItem)) {
      if (this.isRevisedHigherOrLower(budgetItem, true)) {
        classes += ' cell--highlighted--positive';
      }
      if (this.isRevisedHigherOrLower(budgetItem, false)) {
        classes += ' cell--highlighted--neutral';
      }
    }

    return classes;
  }

  isRevised(budgetItem: Model.EAPlanBudgetItem): Model.EAPlanBudgetItem {
    if (!this.isModifying || !budgetItem) {
      return;
    }
    return !!budgetItem.revised_amount || budgetItem.revised_amount === 0 ? budgetItem : undefined;
  }

  isRevisedHigherOrLower(budgetItem: Model.EAPlanBudgetItem, higher: boolean) {
    const revised = this.isRevised(budgetItem);
    return revised && (higher ? revised.revised_amount > revised.direct_amount : revised.revised_amount < revised.direct_amount);
  }

  canEditOrModify(budgetItem: Model.EAPlanBudgetItem) {
    return this.isModifying ? (this.modifiableObjectCodeOptions || []).find(o => o.value === budgetItem.object_code_id) : this.canEdit;
  }

  get allowAdd() {
    return this.isModifying ? !!(this.modifiableObjectCodeOptions || []).length && this.canEdit : this.canEdit;
  }

  get cardName() {
    return this.fund ? Fund.getShortestName(this.fund) : this.institution.name;
  }

  get cardTitle() {
    return this.title ? this.title : `${this.cardName}: Budget`;
  }

  get buttonText() {
    if (this.fund) {
      return `Add ${this.cardName} Budget Item`;
    }
    return `Add Budget Item`;
  }

  get columnsTotalSpan() {
    let padding = this.isPreview ? 1 : this.canEdit ? 3 : 2;

    if (this.match) {
      padding++;
    }

    return this.columns.length - padding;
  }

  trackById(index: number, item: Model.EAPlanBudgetItem) {
    return item.id;
  }
}
