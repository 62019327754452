import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, Actions, Model } from '@app-ngrx-domains';
import { ValidatorsEx } from '@app-utilities';

@Component({
  selector: 'address-list',
  templateUrl: './addresses.component.html'
})
export class AddressListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() set addressEA(ea: Model.EAAddress) {
    this._addressEA = ea;
  }

  get addressEA() {
    return this._addressEA;
  }
  _addressEA: Model.EAAddress;

  @Input() addressIndex: number;
  @Input() canDelete: boolean;
  @Input() canEdit: boolean;
  @Input() firstTouch: boolean;
  @Input() isPreview: boolean;
  @Input() required: boolean = false;

  form: FormGroup;
  proposal = undefined;
  showDeleteAddressAlert = false;


  initialized$ = new BehaviorSubject<boolean>(false);
  isTouched$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private destroy$: Subject<boolean> = new Subject();

  formFields: Array<any>;

  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.initialize();
  }

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  updateIsTouched() {
    const isTouched = Object.keys(this.form.controls).reduce((values, controlName) => {
      return values || !!this.form.get(controlName).value;
    }, false);

    this.isTouched$.next(isTouched);
  }

  get isTouched() {
    return this.isTouched$.value;
  }

  private initialize() {
    if (this.initialized$.value) {
      // all's been setup.
      return;
    }

    this.initialized$.next(true);

    const checkCondition = () => {
      return this.isTouched$.value;
    }

    const getTextValidator = 
      this.required ? [Validators.required] : 
        ValidatorsEx.conditionalValidators(checkCondition, [Validators.required]);

    const getZipValidator =
      this.required ? [Validators.required, ValidatorsEx.zipCode] :  
        [ValidatorsEx.conditionalValidators(checkCondition, [Validators.required, ValidatorsEx.zipCode])];

    this.formFields = [
      { fieldName: 'Address Line 1', controlName: 'line_1', validators: getTextValidator},
      { fieldName: 'Address Line 2', controlName: 'line_2'},
      { fieldName: 'City/County', controlName: 'city', validators: getTextValidator},
      { fieldName: 'State', controlName: 'state', validators: getTextValidator},
      { fieldName: 'Zip Code', controlName: 'zip', validators: getZipValidator},
    ];

    const formObj = {};
    this.formFields.forEach(field => {
      formObj[field.controlName] = [this.addressEA[field.controlName], field.validators]
    });

    // prepare form
    this.form = this._fb.group(formObj);



    this.updateIsTouched();

    this.form.valueChanges
      .pipe(
        debounceTime(250),
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.updateIsTouched();
    });
  }

  toggleDeleteAddress() {
    this.showDeleteAddressAlert = !this.showDeleteAddressAlert;
  }

  persistValue(attributeName: string) {
    const value = this.form.get(attributeName).value;
    const prevValue = this.addressEA[attributeName];
    if (value !== null && value !== prevValue) {
      this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: attributeName, value, ea: this.addressEA }));
    }
  }

  deleteAddress(address: any) {
    this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({ ea: address }));
  }

}
