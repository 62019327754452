import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ContentChildren, ElementRef, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LeftNavComponent } from '../left-nav.component';
import { NavItemComponent } from '../nav-item/nav-item.component';
import { NavTreeService } from '../nav-tree/nav-tree.service';

@Component({
  selector: 'po-nav-group',
  templateUrl: './nav-group.component.html',
  animations: [
    trigger('openState', [
      state('closed', style({
        height: 0,
        opacity: 0
      })),
      state('open', style({
        height: '*',
        opacity: 1
      })),
      transition('closed => open', [
        style({height: 0, opacity: 0}),
        animate('200ms ease-out', style({height: '*', opacity: 1}))
      ]),
      transition('open => closed', [
        animate('200ms ease-out', style({height: 0, opacity: 0}))
      ])
    ])
  ]
})
export class NavGroupComponent implements OnInit, AfterViewInit {

  @Input() id;
  @Input() itemText;
  @Input() itemUrl;
  @Input() exactUrl = false;
  @Input() alternateUrls = [];
  @Input() iconId;
  @Input() keepOpen = false;
  @ContentChildren(NavItemComponent) navItemChildren: QueryList<NavItemComponent>;
  @ContentChildren(NavGroupComponent) navGroupChildren: QueryList<NavGroupComponent>;
  @ViewChild('treeItem') treeItem: ElementRef;
  private groupChildren: NavGroupComponent[] = [];

  isOpen = false;
  isLast = false;
  isVisible = true;
  isActive = false;
  isTabbable = false;
  destroy$: Subject<boolean> = new Subject();

  constructor(private router: Router, private navTreeService: NavTreeService, private leftNav: LeftNavComponent) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setInitialState();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.setOpenStatus();
      this.setChildrenVisibility(this);
    });
    this.navGroupChildren.changes.subscribe(() => this.setInitialState());
  }

  private setInitialState() {
    this.setGroupChildren();
    this.setLastItem();
    this.setOpenStatus();
    this.setChildrenVisibility(this);
  }

  private setGroupChildren() {
    this.groupChildren = this.navGroupChildren.filter(child => child.itemText !== this.itemText);
  }

  private setLastItem() {
    if (this.navItemChildren.last) {
      this.navItemChildren.last.isLast = true;
    }

    if (this.groupChildren.length) {
      this.groupChildren[this.groupChildren.length - 1].isLast = true;
    }
  }

  private setOpenStatus() {
    if (this.keepOpen) {
      this.isOpen = true;
      return;
    }

    const itemActive = this.navItemChildren.some(item => {
      if (!item.itemUrl) {
        return false;
      } else {
        return this.router.isActive(item.itemUrl, item.exactUrl)
      }
    });

    const groupActive = this.navGroupChildren.some(item => {
      if (!item.itemUrl) {
        return false;
      } else {
        return this.router.isActive(item.itemUrl, item.exactUrl);
      }
    });

    this.isOpen = this.isActive = itemActive || groupActive;
  }

  private setChildrenVisibility(group) {
    // set each nav item visibility
    group.navItemChildren.forEach( child => {
      child.isVisible = group.isOpen && group.isVisible;
    });

    // set each nav group visibility
    group.groupChildren.forEach( child => {
      child.isVisible = this.isOpen;
    });

    if (group.groupChildren.length === 0) {
      return;
    }

    group.groupChildren.forEach(child => {
      child.setChildrenVisibility(child);
    });
  }

  handleKeyPress(event) {
    this.navTreeService.handleKeyPress({
      keyboardEvent: event,
      component: this
    })
  }

  handleKeyUp(event) {
    this.navTreeService.handleKeyUp({
      keyboardEvent: event,
      component: this
    })
  }

  handleClick(event) {
    this.navTreeService.handleClick({
      keyboardEvent: event,
      component: this
    });
  }

  toggleOpen() {
    // If nav is expanded toggle the group
    // If nav is collapsed and group is closed, open the group
    if (this.leftNav.expandNav || !this.isOpen) {
      this.isOpen = !this.isOpen;
      this.setChildrenVisibility(this);
    }

    // If nav is collapsed, always expand it on click
    if (!this.leftNav.expandNav) {
      this.leftNav.toggleExpand();
    }
  }

  setFocus() {
    this.isTabbable = true;
    this.treeItem.nativeElement.focus();
  }

  clearFocus() {
    this.isTabbable = false;
  }

  get isOpenExpanded() {
    return this.isOpen && this.leftNav.expandNav;
  }
}
