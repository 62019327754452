import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Model, State, Actions, Queries } from '@app-ngrx-domains';
import { ProposalBudget } from '@app-models';

@Component({
  selector: 'allocated-budget-year',
  templateUrl: './budget-quarterly-forecast.component.html'
})
export class BudgetQuarterlyForecastComponent {
  @Input() canEdit: boolean;
  @Input() firstTouch: boolean;
  @Input() isPreview: boolean;
  @Input() proposalId: number;

  @Input() activityOptions: Model.SelectOption[];
  @Input() objectCodeOptions: Model.SelectOption[];
  @Input() institutionOptions: Model.SelectOption[];

  @Input() durationId: number;
  @Input() durationString: string;
  @Input() expenditureForecast: Model.EAPlanExpenditureForecast;
  @Input() budgetItems: Array<Model.EAPlanBudgetItem>;

  forecastHelpText = `Based on the planned yearly expenditure entered, forecast the year-to-date spending by entering the cumulative
  percentages for each quarter. For example: 25% for Q1, 50% for Q2, 75% for Q3, and 100% for Q4.`

  constructor(
    private store: Store<State>
  ) { }

  updateForecast(event: { quarter: string, value: number }) {
    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: event.quarter, value: event.value, ea: this.expenditureForecast }));
  }

  newBudgetItem() {
    this.store.dispatch(Actions.CurrentProposal.createTempEffortArea({
      ea: {
        effort_area_type: 'plan_budget_items',
        parent_proposal_id: this.proposalId,
        duration_id: this.durationId
      }
    }));
  }

  get budgetTotal() {
    if (this.budgetItems) {
      const amounts = ProposalBudget.getPlanBudgetedAmounts(this.budgetItems);
      return amounts.monetary_match + amounts.indirect_costs + amounts.direct_costs;
    } else {
      return 0;
    }
  }

  trackById(index: number, item: any): number {
    return item.temp_id ? item.temp_id : item.id;
  }
}
