<h2 class="header-title h2 mb-l" id="qa_tab_title">{{section.title}}</h2>

<ng-container *ngFor="let type of section.contact_types; let i = index">
  <ng-container [ngSwitch]="type.specialType">
    <ng-container *ngSwitchDefault>
      <contact-institution *ngFor="let inst of type.institutions"
        [type]="type"
        [institution]="inst"
        [context]="context"
        [canEdit]="canEdit"
        [contactIndex]="i"
      >
      </contact-institution>

    </ng-container>

    <sa-contact-type *ngSwitchCase="'signing-authority'"
      [canEdit]="canEdit"
      [context]="context"
      [type]="type"
    ></sa-contact-type>
  </ng-container>

</ng-container>
