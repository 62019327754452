import { Model } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ModelBase } from './model.base';
import { IUserRoleScope } from './user-role-scope';
import { Task } from './task';
import { WORKFLOW_STEPS, STATE_TYPES, TASK_TYPES } from '../consts';

export interface IMember {
  id?: number;
  institution_id: number;
  institution?: Model.Institution;
  proposal_id: number;
  duration_id?: number;
  voting_only?: boolean;
  carryover_member?: boolean;
  is_active?: boolean;
  tasks?: Array<Model.Task>;
  state_id?: number;
  client_state?: { [name: string]: any };
}

export interface IMemberInfo {
  program_year: Model.Duration,
  direct_funding: boolean,
  institution_id: number,
  proposal_id: number,
  name: string,
  shortName: string,
}

export class Member extends ModelBase implements IMember {
  public id: number;
  public institution_id: number;
  public institution: Model.Institution;
  public proposal_id: number;
  public duration_id: number;
  public voting_only: boolean;
  public carryover_member: boolean;
  public is_active: boolean;
  public tasks: Array<Model.Task>;
  public state_id: number;
  public client_state: { [name: string]: any };

  constructor(raw: any) {
    super();
    if (raw) {
      this.id = raw.id;
      this.institution_id = raw.institution_id;
      this.institution = raw.institution;
      this.proposal_id = raw.proposal_id;
      this.voting_only = raw.voting_only;
      this.is_active = raw.is_active;
      this.carryover_member = raw.carryover_member;
      this.duration_id = raw.duration_id;

      this.tasks = [];
      this.state_id = STATE_TYPES.DRAFT;
      this.client_state = {};

      // filter out tasks belonging to given year.
      this.tasks = raw.tasks.filter(task => task.duration_id === this.duration_id);
      const submitTask = this.tasks.find(task => task.task_type === TASK_TYPES.CAEP_MEMBER_PLAN_SUBMIT);
      const approveTask = this.tasks.find(task => task.task_type === TASK_TYPES.CAEP_MEMBER_PLAN_APPROVE);
      // determine state id from state of submit & approval tasks.
      this.state_id = Task.getStateFromTasks(submitTask, approveTask);
      // initialize client state
      this.client_state[WORKFLOW_STEPS.WORKPLAN + '_state'] = 'pristine';
      this.client_state[WORKFLOW_STEPS.BUDGET + '_state'] = 'pristine';
      // read client state from submit task.
      if (submitTask) {
        const task_client_state = submitTask.client_state;
        this.client_state = {
          ...this.client_state,
          ...task_client_state,
          ['task_id']: submitTask.id,
        };
      }
    }
  }

  /**
   * Looks through the list of contacts, then builds the contacts belonging to each member.
   *
   * @param {Array<Model.Member>} members
   * @param {Array<Model.UserRoleScope>} contacts
   * @returns { [institution_id: number]: Array<IUserRoleScope> }
   */
  static getMembersContacts(members: Array<Model.Member>, contacts: Array<Model.UserRoleScope>): { [institution_id: number]: Array<IUserRoleScope> } {
    const memberContacts = {};
    if (members) {
      members.forEach(m => {
        if (m.institution_id) {
          memberContacts[m.institution_id] = contacts.filter(c => c.institution_id === m.institution_id);
        }
      });
    }
    return memberContacts;
  }

/**
   * Returns true if member can be deleted; this is determined by presense of approver task.
   * @static
   * @param {Model.Member} member
   * @returns {boolean}
   */
  static isRemovable(member: Model.Member): boolean {
    return !member.tasks || !Task.getTask(member.tasks, TASK_TYPES.CAEP_MEMBER_PLAN_APPROVE);
  }

    /**
   * Returns true if member has been certified.
   * @static
   * @param {number} state_id
   * @returns {boolean}
   */
  static isCertified(state_id: number): boolean {
    return state_id && state_id === STATE_TYPES.CERTIFIED;
  }

  /**
   * Returns true if member is in draft mode.
   * @static
   * @param {number} state_id
   * @returns {boolean}
   */
  static isDraft(state_id: number): boolean {
    return !state_id || state_id === STATE_TYPES.DRAFT;
  }

  /**
   * Returns status/state as a name to be shown on header bar.
   * @param {number} state_id
   * @returns {string}
   */
  static formatStateHeader(state_id: number): string {
    if (this.isDraft(state_id)) {
      return 'DRAFT';
    } else {
      switch (state_id) {
        case STATE_TYPES.SUBMITTED:
          return 'SUBMITTED';

        case STATE_TYPES.CERTIFIED:
          return 'CERTIFIED';

        default:
          return 'SUBMITTED';
      }
    }
  }

  /**
   * Formats the state name.
   * @param {number} state_id
   */
  static stateName(state_id: number): string {
    const result = _.startCase(this.formatStateHeader(state_id).toLowerCase());
    return result;
  }

  static formatMemberRep(member: any) {
    const rep = 'Member Representative';
    if (member) {
      if ( member.institution ) {
        return `${member.institution.name} - ${rep}`;
      } else if ( member.name) {
        return `${member.name} - ${rep}`
      }
    }
    return rep;
  }

}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Member = IMember;
    export type MemberInfo = IMemberInfo;
  }
}
