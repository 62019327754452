import { Model } from '@app-ngrx-domains';
import { DEFAULT_STEP_OPTIONS, WORKFLOW_TYPES } from '@app-consts';

export const RSI_REPORTING_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.RSI,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: '1',
      title: 'Next Year Projection',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: '2',
      title: 'P1',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: '3',
      title: 'P2',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: '4',
      title: 'Final',
      ...DEFAULT_STEP_OPTIONS,
    },
  ]
};
