import { Component, Input, OnInit } from '@angular/core';
import { Model } from '@app-ngrx-domains';
import { Profile } from '@app/core/models';

@Component({
  selector: 'app-project-history-item',
  templateUrl: './project-history-item.component.html'
})
export class ProjectHistoryItemComponent implements OnInit {
  @Input() count: number = 0;
  @Input() task: Model.Task;
  @Input() files: Array<Model.Document>;
  @Input() showCompareLink: boolean;
  @Input() isFirst: boolean;
  @Input() itemUrl: string;

  // Display Fields
  comment: string;
  date: any;
  htmlFile: Model.Document;
  pdfFile: Model.Document;
  performer: string;
  // status: string; Unused for now
  title: string;
  user: any;

  ngOnInit() {
    this.title = this.task.store.message;
    this.user = this.task.performed_by;

    if (this.user) {
      this.performer = Profile.contactFullName(this.user);
    }

    this.date = this.task.created_at;
    this.comment = this.task.store.comment || this.task.store.comments;

    const files = this.files.sort((a, b) => a.id < b.id ? 1 : -1)

    this.pdfFile = files.find(file => !!file.filename.trim().match(/\.pdf$/i));
    this.htmlFile = files.find(file => !!file.filename.trim().match(/\.html$/i));
  }
}
