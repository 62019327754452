import { ModelBase } from './model.base';
import * as moment from 'moment';
import {IMessageTemplate} from './message-template';
import { Utilities } from './utilities';
import { DATE_TIME_MOMENT_FORMAT_STRINGS, EOT_DURATION, FINAL_DURATION } from '@app-consts';
import { Model } from '@app-ngrx-domains';

export interface IEvent {
  id?: number;
  title?: string;
  name?: string;
  fund_id?: number;
  event_type?: string;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  event_time?: string;
  forecast_quarter?: number;
  repeats_annually?: boolean;
  is_private?: boolean;
  is_custom?: boolean;
  color?: string;
  url?: string;
  fund?: Model.Fund;
}

export class Event extends ModelBase {

  static convertToEventTime(event: IEvent): string {
    if (!event || !event.event_time) {
      return;
    }

    if (event.repeats_annually) {
      const today = moment().startOf('day');
      const currentYear = today.year();
      const eventDate = moment.tz(event.event_time, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone).year(currentYear);

      // If month/day of this year has passed, set year to next year
      if (eventDate.isBefore(today)) {
        eventDate.add(1, 'years');
      }

      return eventDate.utc().toISOString();
    } else {
      return event.event_time;
    }
  }

  static formatName(event: IEvent, excludeType: boolean = false): string {
    if (event) {
      let name = event.name;
      if (event.forecast_quarter) {
        if (event.forecast_quarter === FINAL_DURATION) {
          name = `Final ${name}`;
        } else if (event.forecast_quarter === EOT_DURATION) {
          name = `End of Term ${name}`;
        } else {
          name = `Q${event.forecast_quarter} ${name}`;
        }
      }

      if (event.event_type && !excludeType) {
        return `${event.event_type}: ${name}`;
      } else {
        return name;
      }
    }

    return '';
  }

  static formatEventDate(event: IEvent): string {
    if (event && event.event_time) {
      const format = event.repeats_annually ? DATE_TIME_MOMENT_FORMAT_STRINGS.dateYearless : DATE_TIME_MOMENT_FORMAT_STRINGS.dateMedium;
      return Utilities.convertToNOVADateTime(event.event_time, format);
    } else {
      return 'Not Set';
    }
  }

  static formatEventDateTime(event: IEvent, useFormatString?: string): string {
    const eventTime = Event.convertToEventTime(event);
    if (!eventTime) {
      return 'Not set';
    }

    return Utilities.convertToNOVADateTime(eventTime, useFormatString);
  }

  static formatEventDateTimeLong(event: IEvent): string {
    return Event.formatEventDateTime(event, DATE_TIME_MOMENT_FORMAT_STRINGS.dateTimeLong12HoursTZ);
  }

  static formatDate(message: IMessageTemplate): string {
    return Event.formatEventDate(message.event);
  }

  static formatTriggerDate(message: IMessageTemplate): string {
    const event = message.event;
    if (!event || !event.event_time) {
      return 'Not set';
    }

    const eventDate = moment.tz(event.event_time, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone);
    const dateFormat = event.repeats_annually ? DATE_TIME_MOMENT_FORMAT_STRINGS.dateYearless : DATE_TIME_MOMENT_FORMAT_STRINGS.dateMedium;

    if (message.offset_type) {
      switch (message.offset_type) {
        case 'on':
          return eventDate.format(dateFormat);
        case 'before':
          return eventDate.subtract(message.date_offset, 'days').format(dateFormat);
        case 'after':
          return eventDate.add(message.date_offset, 'days').format(dateFormat);
        default:
          return eventDate.format(dateFormat);
      }
    } else {
      return eventDate.format(dateFormat);
    }
  }

  static isExpired(date: string): boolean {
    const eventDate = moment.tz(date, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone);
    const now = moment.tz({}, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone);

    return now.isSameOrAfter(eventDate);
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Event = IEvent;
  }
}
