import { Model } from '@app-ngrx-domains';
import { ProposalBase } from './proposal-base';
import { isNil } from 'lodash';

export interface IProposalSurvey extends Model.ProposalBase {
  // Survey responses
  survey_responses: Array<Model.EASurveyResponse>;
}

/**
 * Survey related static helper functions.
 */
export class ProposalSurvey extends ProposalBase {

  static getValueForKey(responses: Array<Model.EASurveyResponse>,
    questionId: number,
    responseOptionId: number = null,
    tableRowId: number = null,
    tableColumnId: number = null) {
    const response = responses.find(r =>
      r.question_id === questionId &&
      r.response_option_id === responseOptionId &&
      r.table_row_id === tableRowId &&
      r.table_column_id === tableColumnId);
    if (!response) {
      return undefined;
    }

    // return the first non-nil response type, or undefined
    return ['text_response', 'int_response', 'bool_response'].reduce((value, type) => {
      if (isNil(value) && !isNil(response[type])) {
        return response[type];
      } else {
        return value;
      }
    }, undefined);
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ProposalSurvey = IProposalSurvey;
  }
}
