<div [ngClass]="{'card': !isPreview }">
  <div class="card-title">
    <h4>"{{guidanceTitle}}" Guidance</h4>
  </div>

  <form *ngIf="form" [formGroup]="form" [ngClass]="{'first-touch': firstTouch}">
    <input-field labelText="Guidance Text" [canEdit]="canEdit" [revertibleField]="isRevertible" [modifiedFieldName]="guidanceEA.workflow_step">
      <po-html-textarea inputRef id="guidance_description_text_area{{guidanceStep}}" formControlName="description"
        [htmlCharLimit]="narrativeLength"
        (change)="persistAttribute('description')"
      ></po-html-textarea>
    </input-field>
  </form>
</div>
