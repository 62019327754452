<full-page-modal
  small="true"
  heading="Upload Document"
  button-action="Upload Document"
  (action)="submitUploadDocumentForm()"
  [button-action-disabled]="!uploadForm.valid"
  button-cancel="Cancel"
  (cancel)="cancelUploadDocumentForm()"
  (dismiss)="cancelUploadDocumentForm()">
  <form *ngIf="uploadForm" [formGroup]="uploadForm">
    <po-select *ngIf="institutions && institutions.length" id="upload_institution_select"
               [options]="institutions"
               [placeholderText]="'Select an institution'"
               [labelText]="'Institution'"
               [selectMultiple]="false"
               [selectOnce]="true">
      <select inputRef class="po-select__select"
              formControlName="institution_id">
      </select>
    </po-select>

    <ng-container [ngSwitch]="isSingleDocType">
      <div *ngSwitchCase="true">
        <p class="label-text">Document Type:</p>
        {{ singleDocTypeName }}
      </div>
      <po-select *ngSwitchCase="false" id="document_type_id"
                 [options]="uploadDocumentTypeOptions"
                 [placeholderText]="'Select a document type'"
                 labelText="Document Type"
                 [selectMultiple]="false"
                 [selectOnce]="true">
        <select inputRef class="po-select__select"
                formControlName="document_type_id">
        </select>
      </po-select>
    </ng-container>

    <po-select *ngIf="allowURL" id="source_type_select"
                 [options]="sourceTypeOptions"
                 [placeholderText]="'Select a source type'"
                 labelText="Source Type"
                 [selectMultiple]="false"
                 [selectOnce]="true"
                 (optionSelected)="setSource($event)">
        <select inputRef class="po-select__select"
                formControlName="source_type">
        </select>
      </po-select>

    <div class="c-field margin-bottom">
      <ng-container [ngSwitch]="fileIsUrl">
        <ng-container *ngSwitchCase="false">
          <label for="qa_upload_document_modal" class="c-field__label">File</label>
          <div class="flex">
              <button type="button" (click)="uploadFileClicked()" class="action-button action-button--secondary action-button--small icon--left">
                <icon iconId="plus"></icon>
                Browse
              </button>
            <ul #filenames class="icon-list">
              <li *ngFor="let fileName of fileNames" class="icon-list__item">
                <button (click)="removeFile(fileName)" class="icon-list__item__button action-button action-button--tertiary action-button--tiny" title="Remove">
                  <icon iconId="close"></icon>
                </button>
                {{fileName}}
              </li>
            </ul>
            <input id="qa_upload_document_modal" type="file" [accept]="getFileTypes()" ng2FileSelect [uploader]="uploader" multiple="true" [hidden]="true" #uploadInput />
            <input type="hidden" formArrayName="files" /> <!-- File validation Field -->
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="true">
          <input-field labelText="URL" [canEdit]="true">
          <input type="text" aria-required="true" inputRef formControlName="url" id="qa_url_input">
        </input-field>
        </ng-container>
      </ng-container>
    </div>

    <input-field *ngIf="collectComments" labelText="Comment" class="mb-l">
      <div class="help-text">
        <p>Describe the content or purpose of this upload in {{commentLimit}} characters or less (optional)</p>
      </div>
      <po-html-textarea inputRef id="document_comment"
        formControlName="comment"
        [htmlCharLimit]="commentLimit">
      </po-html-textarea>
    </input-field>

  </form>
  <div class="margin-bottom">
    <po-status-message [statusMessage]="statuses.upload"></po-status-message>
  </div>
</full-page-modal>
