import {ChangeDetectionStrategy, Component, Input, OnInit, PipeTransform} from '@angular/core';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import { Model } from '@app-ngrx-domains';
import { PercentTransform } from '@app-generic/pipes';

@Component({
  selector: 'po-progress-bar',
  templateUrl: './progress-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements OnInit {

  @Input() progressBarItems: Array<Model.ProgressBarItem>;
  @Input() formatType = '';
  @Input() pipeArgs: Array<any> = [];

  private mouseEntered: boolean = false;

  private pipe: PipeTransform;
  private focusedBar: number = null;

  constructor(
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private percentPipe: PercentTransform) {
  }

  ngOnInit() {
    this.setFormatType();
  }

  barMouseenter() {
    this.mouseEntered = true;
  }

  barMouseover(barIndex: number) {
    this.focusedBar = barIndex;
  }

  barMouseout() {
    this.focusedBar = null;
  }

  barMouseleave() {
    this.mouseEntered = false;
    this.focusedBar = null;
  }

  barFocused(barIndex: number) {
    return this.mouseEntered && this.focusedBar === barIndex;
  }

  isBarFocused() {
    return this.mouseEntered && this.focusedBar !== null;
  }

  private setFormatType() {
    switch (this.formatType) {
      case 'currency':
      case 'dollars':
        this.pipe = this.currencyPipe;
        break;
      case 'percent':
        this.pipe = this.percentPipe;
        break;
      case 'decimal':
        this.pipe = this.decimalPipe;
        break;
      default:
        this.pipe = this.currencyPipe;
        break;
    }
  }

  /**
   * Forms the value according to the pipe type.
   * @param value
   */
  format(value: number) {
    if (this.pipe) {
      return this.pipe.transform(value, ...this.pipeArgs);
    } else {
      return value;
    }
  }
}
