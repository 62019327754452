export * from './sep-student-population-plan.guard';
export * from './sep-metric-definition.guard';

import { StudentPopulationPlanGuard } from "./sep-student-population-plan.guard";
import { MetricDefinitionGuard } from "./sep-metric-definition.guard";

export const SEPGuards = [
  StudentPopulationPlanGuard,
  MetricDefinitionGuard
];
