import { ICommentsState, State } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload, NewTempId } from '@app-libs';
import { COMMENTS_ACTION_TYPES } from './comments.action';

export function CommentsReducer(state: ICommentsState = State.Comments, action: ActionWithPayload<any>): ICommentsState {
  switch (action.type) {

    case COMMENTS_ACTION_TYPES.CLEAR: {
      return { ...State.Comments };
    }

    case COMMENTS_ACTION_TYPES.SET_ENABLED: {
      return {
        ...state,
        is_commenting_enabled: action.payload.isEnabled
      };
    }

    case COMMENTS_ACTION_TYPES.LOAD_COMMENTS: {
      return {
        ...state,
        threads: action.payload.entries.reduce((threads, entry) => {
          const { resource, thread } = entry;
          threads[thread.id] = {
            resource,
            comment: thread,
            replies: thread.replies || []
          };
          return threads;
        }, {})
      };
    }

    case COMMENTS_ACTION_TYPES.SET_RESOURCE: {
      return {
        ...state,
        parent_resource: action.payload.resource,
        threads: {}
      };
    }

    case COMMENTS_ACTION_TYPES.CREATE_THREAD_SUCCESS: {
      const { resource, thread } = action.payload;

      return {
        ...state,
        threads: {
          ...state.threads,
          [thread.temp_id || thread.id]: {
            resource,
            comment: thread,
            replies: thread.replies || []
          }
        }
      };
    }

    case COMMENTS_ACTION_TYPES.UPDATE_COMMENT_SUCCESS: {
      const updatedComment = action.payload.comment;
      const threadId = updatedComment.parent_temp_id || action.payload.threadId;

      if (updatedComment.parent_id) {
        const existingThread = state.threads[threadId];
        if (!existingThread) { return state; }

        let updatedReplies;
        if (NewTempId.isTemp(updatedComment.id)) {
          updatedReplies = [...existingThread.replies, updatedComment];
        } else {
          updatedReplies = existingThread.replies.map(reply => {
            return (reply.temp_id != null ? reply.temp_id === updatedComment.temp_id : reply.id === updatedComment.id) ? updatedComment : reply
          });
        }

        return {
          ...state,
          threads: {
            ...state.threads,
            [threadId]: {
              ...existingThread,
              replies: updatedReplies
            }
          }
        };
      } else {
        const existingThread = state.threads[threadId];
        return existingThread
          ? {
          ...state,
          threads: {
            ...state.threads,
            [threadId]: {
              ...existingThread,
              comment: updatedComment,
            }
          }
        } : state;
      }
    }

    case COMMENTS_ACTION_TYPES.DELETE_COMMENT_SUCCESS: {
      const deletedComment = action.payload.comment;
      const threadId = deletedComment.temp_id || action.payload.threadId;

      if (deletedComment.parent_id) {
        const existingThread = state.threads[threadId];
        if (!existingThread) { return state; }

        return {
          ...state,
          threads: {
            ...state.threads,
            [threadId]: {
              ...existingThread,
              replies: existingThread.replies.filter(reply => reply.id !== deletedComment.id)
            }
          }
        };
      } else {
        const threads = state.threads;
        delete threads[threadId];
        return {
          ...state,
          threads
        };
      }
    }

    default:
      return state;
  }
}
