import { Model } from '@app-ngrx-domains';
import { DEFAULT_STEP_OPTIONS, WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';

export const MID_ALLOCATION_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.SEP_MID_ALLOCATION,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.EXPENDITURES,
      title: 'Expenditures',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.METRICS,
      title: 'Metrics',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.ACTIVITIES,
      title: 'Activities',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.SPENDING,
      title: 'Category Spending',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.SUCCESS,
      title: 'Success Story',
      optional: true,
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.CHALLENGES,
      title: 'Challenges',
      optional: true,
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    },
  ]
};

export const MID_ALLOCATION_WORKFLOW_CONFIG_WITH_SURVEY: Model.Workflow = {
  name: WORKFLOW_TYPES.SEP_MID_ALLOCATION,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.EXPENDITURES,
      title: 'Expenditures',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.METRICS,
      title: 'Metrics',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.ACTIVITIES,
      title: 'Activities',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.SPENDING,
      title: 'Category Spending',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.SUCCESS,
      title: 'Success Story',
      optional: true,
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.CHALLENGES,
      title: 'Challenges',
      optional: true,
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.SURVEY,
      title: 'Survey',
      optional: false,
      ...DEFAULT_STEP_OPTIONS
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    },
  ]
};

export const COLLEGE_AR_REPORTING_PERIOD = 'SEA Annual Report';
