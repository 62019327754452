<ng-container *ngIf="durationOptions?.length; else noDurations">
  <ng-container [ngSwitch]="!isPreview">
    <ng-container *ngSwitchCase="true">
      <app-budget-summary [summaryEntries]="summaryEntries" [canEdit]="canEdit" summaryTitle="Budget Summary"></app-budget-summary>
      <h2 class="header-title mb-l" id="qa_tab_title">Budget</h2>
      <po-guidance-text [workflowFilter]="guidance"></po-guidance-text>

      <div *ngIf="form" [formGroup]="form" class="segmented-control segmented-control--stand-alone margin-bottom">
        <ul class="segmented-control__list" role="radiogroup">
          <li *ngFor="let duration of durationOptions; let i = index;" class="segmented-control__item" id="budget_year_selector{{i}}">
            <input #radio class="segmented-control__input" type="radio"
              formControlName="durationFilter"
              id="budget_year_radio{{i}}"
              [value]="duration.id"/>
            <label for="budget_year_radio{{i}}" class="segmented-control__label"
              [attr.aria-checked]="radio.checked"
              attr.aria-label="View budget items for {{ getDurationLabel(duration) }}">
              {{ getDurationLabel(duration) }}
            </label>
          </li>
        </ul>
      </div>

      <h3>Budget Items</h3>
      <ng-container *ngFor="let duration of durationOptions">
        <ng-container *ngIf="duration.id === selectedDurationId">
          <p *ngIf="!budgetItemsForDuration(duration.id)?.length" class="empty-state">No budget items created.</p>
          <allocated-budget-year
            [canEdit]="canEdit"
            [firstTouch]="firstTouch"
            [proposalId]="proposal.id"
            [activityOptions]="activityOptions"
            [objectCodeOptions]="objectCodeOptions"
            [institutionOptions]="institutionOptions"
            [durationId]="duration.id"
            [durationString]="getDurationLabel(duration)"
            [budgetItems]="budgetItemsForDuration(duration.id)"
            [expenditureForecast]="getForecastForDuration(duration.id)">
          </allocated-budget-year>
        </ng-container>
      </ng-container>
    </ng-container>

    <div *ngSwitchCase="false" class="card">
      <div class="card-title">
        <h3>Budget</h3>
      </div>

      <app-budget-summary summaryTitle="Budget Summary"
        [summaryEntries]="summaryEntries"
        [canEdit]="canEdit"
        [bordered]="true"
        [spaced]="true"
        [isSticky]="false"
        [headingLevel]="'h4'">
      </app-budget-summary>

      <ng-container *ngFor="let duration of durationOptions">
        <h4>{{getDurationLabel(duration)}} Budget Items</h4>
        <p *ngIf="!budgetItemsForDuration(duration.id)?.length" class="empty-state">No budget items created.</p>
        <allocated-budget-year *ngIf="budgetItemsForDuration(duration.id)?.length"
          [canEdit]="canEdit"
          [firstTouch]="firstTouch"
          [isPreview]="true"
          [proposalId]="proposal.id"
          [activityOptions]="activityOptions"
          [objectCodeOptions]="objectCodeOptions"
          [institutionOptions]="institutionOptions"
          [durationId]="duration.id"
          [durationString]="getDurationLabel(duration)"
          [budgetItems]="budgetItemsForDuration(duration.id)"
          [expenditureForecast]="getForecastForDuration(duration.id)">
        </allocated-budget-year>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #noDurations>
  <div class="card">
    <div class="card-title">
      <h3>Budget</h3>
    </div>
    <ng-container [ngSwitch]="!isPreview">
      <po-guidance-text [workflowFilter]="guidance"></po-guidance-text>

      <inline-alert  *ngSwitchCase="true" level="info" small="true">
        <p>
          There are no fiscal years to report budget items on. Please go to the "Details" tab, and
          enter valid Projected Start & End Dates before continuing.
        </p>
      </inline-alert>
      <p *ngSwitchCase="false" class="empty-state">
        No budget items have been added.
      </p>
    </ng-container>
  </div>
</ng-template>
