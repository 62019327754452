import { ModelBase } from './model.base';
import { STATE_TYPES } from '@app-consts';
import { Profile } from './user';
import { Utilities } from './utilities';
import { Model } from '@app-ngrx-domains';

export interface ITask {
  id: number;
  task_type: string;
  completed: boolean;
  deleted: boolean;
  store: any;
  client_state: any;
  performed_by_id: number;
  performed_by?: any;
  files?: Array<Model.Document>
  created_at?: Date;
  updated_at?: Date;
  user_id?: number;
  institution_id?: number;
  proposal_id?: number;
  cfad_id?: number;
  annual_plan_id?: number;
  duration_id?: number;
  role_id?: number;
  fund_id?: number;
  effort_area_id?: number;
  proxy_id?: number;
}

export class Task extends ModelBase implements ITask {
  public id: number;
  public task_type: string;
  public completed: boolean;
  public deleted: boolean;
  public store: any;
  public client_state: any;
  public performed_by_id: number;
  public performed_by: any;
  public files: Array<Model.Document>;
  public created_at: Date;
  public updated_at: Date;
  public duration_id: number;

  public user_id: number;
  public institution_id: number;
  public proposal_id: number;
  public cfad_id: number;
  public annual_plan_id: number;
  public role_id: number;
  public fund_id: number;
  public effort_area_id: number;

  constructor(
    raw: any
  ) {
    super();
    this.id = raw.id;
    this.task_type = raw.task_type;
    this.completed = !!raw.completed;
    this.deleted = !!raw.deleted;
    this.store = raw.store;
    this.client_state = raw.client_state || {};
    this.performed_by_id = raw.performed_by_id;
    this.performed_by = raw.performed_by;
    this.files = raw.files;
    this.created_at = raw.created_at;
    this.updated_at = raw.updated_at;

    this.user_id = raw.user_id || null;
    this.institution_id = raw.institution_id || null;
    this.proposal_id = raw.proposal_id || null;
    this.cfad_id = raw.cfad_id || null;
    this.annual_plan_id = raw.annual_plan_id || null;
    this.duration_id = raw.duration_id || null;
    this.role_id = raw.role_id || null;
    this.fund_id = raw.fund_id || null;
    this.effort_area_id = raw.effort_area_id || null;
  }

  /**
   * Returns the task of given type.
   * @param tasks
   * @param task_type
   */
  static getTask(tasks: Array<ITask>, task_type: string): ITask {
    const result = tasks.find(t => t.task_type === task_type);
    return result;
  }

  /**
   * Returns the state of the workflow given the tasks that are associated.
   * @param submit_task The submit task of the workflow
   * @param approve_task The approval task of the workflow
   */
  static getStateFromTasks(submit_task: ITask, approve_task?: ITask) {
    const submitted = submit_task && submit_task.completed;
    const rejected = approve_task && approve_task.store && approve_task.store['rejected'];
    const approved = approve_task && approve_task.completed && !rejected;
    if (submitted && approved) {
      return STATE_TYPES.CERTIFIED;
    } else if (submitted) {
      return STATE_TYPES.SUBMITTED;
    } else {
      return STATE_TYPES.DRAFT;
    }
  }

  /**
   * Returns the state of the workflow given the tasks that are associated.
   * @param submit_task The submit task of the workflow
   * @param approve_tasks One or more approver tasks of the workflow
   */
  static getStateFromTasksWMultipleApprovers(submit_task: ITask, approve_tasks?: Array<ITask>, allow_rejected?: boolean) {
    // see if task has been submitted
    const submitted = submit_task && submit_task.completed;
    // assume no approval process has taken place.
    let rejected = false;
    let approved = false;
    if (submitted && approve_tasks && approve_tasks.length > 0) {
      // look for at least one rejection.
      rejected = approve_tasks.some(t => t.store && t.store['rejected']);
      if (!rejected) {
        // make sure that each member has approved.
        approved = approve_tasks.every(t => t.completed);
      }
    }

    if (submitted && approved) {
      return STATE_TYPES.CERTIFIED;
    } else if (allow_rejected && rejected) {
      return STATE_TYPES.REJECTED;
    } else if (submitted) {
      return STATE_TYPES.SUBMITTED;
    } else {
      return STATE_TYPES.DRAFT;
    }
  }

  /**
   * Returns the performed by & on as formatted string.
   * @param task
   * @param prefix
   */
  static formatTaskAuthor(task: Model.Task, prefix: string = ''): string {
    let author = prefix;

    if (task) {
      if (task.performed_by) {
        author += ` by ${Profile.contactFullName(task.performed_by)}`;
      }
      if (task.created_at) {
        author += ` on ${Utilities.convertDateToString(task.created_at, false, true)}`;
      }
    }

    return author;
  }

  /**
   * Returns the performed on as formatted string.
   * @param task
   * @param prefix
   */
  static formatTaskDate(task: Model.Task, prefix: string = ''): string {
    let author = prefix;

    if (task) {
      if (task.created_at) {
        author += ` on ${Utilities.convertDateToString(task.created_at, false, true)}`;
      }
    }

    return author;
  }

  /**
   * Gets file associated with task by document type
   * @param task
   * @param document_type
   */

  static getDocumentUrlFromTask(task: Model.Task, document_type: number) {
    const result = task.files.filter(file => file.document_type_id === document_type);
    return result;
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Task = ITask;
  }
}
