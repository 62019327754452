import { of, concat, Observable } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Root, State, Actions as DomainActions, Queries } from '@app-ngrx-domains'
import { ActionWithPayload, toPayload } from '@app-libs';
import { CURRENT_WORKFLOW_ACTION_TYPES } from './current-workflow.action';
import { WORKFLOW_STEPS } from '@app-consts';

import { validateStepData as budgetValidateStepData } from '../validators/budget.validator';
import { validateStepData as conclusionValidateStepData } from '../validators/conclusion.validator';
import { validateStepData as contactsValidateStepData } from '../validators/contacts.validator';
import { validateStepData as descriptionValidateStepData } from '../validators/description.validator';
import { validateStepData as workplanValidateStepData } from '../validators/workplan.validator';
import { validateStepData as workplan1ValidateStepData } from '../validators/workplan1.validator';
import { validateStepData as workplan2ValidateStepData } from '../validators/workplan2.validator';
import { validateStepData as workplan3ValidateStepData } from '../validators/workplan3.validator';

/**
 * Injectable Workflow effects class
 */
@Injectable()
export class CurrentWorkflowEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>,
  ) {  }

  /** Utility function to set a step as valid or invalid */
  setValidity(stepName: string, valid: boolean): ActionWithPayload<any> {
    return DomainActions.Workflow.setValidity(stepName, valid);
  }

  /**
   * Runs validation on one or all workflow steps, and dispatches action to mark step in/valid accordingly.
   */
   validate$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_WORKFLOW_ACTION_TYPES.VALIDATE),
    map(toPayload),
    withLatestFrom(
      this.store.select(Queries.CurrentProposal.getProposal),
      this.store.select(Root.Contacts),
      this.store.select(Root.Workplan),
      this.store.select(Root.Budget),
      this.store.select(Queries.Workflow.getCurrent),
      this.store.select(Queries.Files.getItems),
    ),
    switchMap((data: any): Observable<any> => {
      const [ step, proposal, contacts, workplan, budget, currentWorkflow, files ] = data;
      const actions = [];

      /**
       * Returns true if step is the target step to check, or that the step is to check all
       * and the step is included in the current workflow.
       */
      const isStepOrIncludesStep = ( checkStep: string ) => {
        return (step === checkStep) || ((step === WORKFLOW_STEPS.ALL) && currentWorkflow.steps.findIndex(s => s.route === checkStep) >= 0);
      };

      ///////////////////////////////////////////////////////////////////////////////
      // Validation checks begin here
      ///////////////////////////////////////////////////////////////////////////////
      if (isStepOrIncludesStep(WORKFLOW_STEPS.DESCRIPTION)) {
        const valid = descriptionValidateStepData(proposal);
        actions.push(this.setValidity(WORKFLOW_STEPS.DESCRIPTION, valid));
      }

      if (isStepOrIncludesStep(WORKFLOW_STEPS.CONTACTS)) {
        const valid = contactsValidateStepData(contacts, budget, proposal);
        actions.push(this.setValidity(WORKFLOW_STEPS.CONTACTS, valid));
      }

      if (isStepOrIncludesStep(WORKFLOW_STEPS.WORKPLAN)) {
        const valid = workplanValidateStepData({
          ...workplan,
          risks: proposal.workplan_risks,
          project_type: proposal.project_type,
        });
        actions.push(this.setValidity(WORKFLOW_STEPS.WORKPLAN, valid));
      }

      if (isStepOrIncludesStep(WORKFLOW_STEPS.WORKPLAN1)) {
        const valid = workplan1ValidateStepData({
          ...workplan,
          proposal: proposal,
        });
        actions.push(this.setValidity(WORKFLOW_STEPS.WORKPLAN1, valid));
      }

      if (isStepOrIncludesStep(WORKFLOW_STEPS.WORKPLAN2)) {
        const valid = workplan2ValidateStepData({
          ...workplan,
          proposal: proposal,
        });
        actions.push(this.setValidity(WORKFLOW_STEPS.WORKPLAN2, valid));
      }

      if (isStepOrIncludesStep(WORKFLOW_STEPS.WORKPLAN3)) {
        const valid = workplan3ValidateStepData({
          ...workplan,
          proposal: proposal,
        });
        actions.push(this.setValidity(WORKFLOW_STEPS.WORKPLAN3, valid));
      }

      if (isStepOrIncludesStep(WORKFLOW_STEPS.BUDGET)) {
        const valid = budgetValidateStepData({...budget, project_type: proposal.project_type});
        actions.push(this.setValidity(WORKFLOW_STEPS.BUDGET, valid));
      }

      if (isStepOrIncludesStep(WORKFLOW_STEPS.CONCLUSION)) {
        const valid = conclusionValidateStepData(proposal, files);
        actions.push(this.setValidity(WORKFLOW_STEPS.CONCLUSION, valid));
      }

      return concat([
        ...actions,
      ]);
    }),
    catchError((error) => of({ type: 'CURRENT_WORKFLOW_VALIDATE_ERROR', payload: error }))
  ));

  /**
   * Adds workflow step to section of workflows.
   */
   addWorkflowStepToSection$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_WORKFLOW_ACTION_TYPES.ADD_WORKFLOW_STEP_TO_SECTION),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Workflow)),
    switchMap(([req, workflowState]) => {
      const currentWorkflow = workflowState.workflows[workflowState.current];
      const sectionStep = currentWorkflow.steps.find(s => s.route === req.sectionName);
      if (!sectionStep) {
        // coudn't find the section step.
        return;
      }

      // replace args in source
      const replace = (source: string): string => {
        let updated = source;
        (req.config.args || []).forEach(arg => {
          updated = updated.replace(arg.name, arg.value.toString());
        });
        return updated;
      }

      // build the workflow & the new step.
      const actions: Action[] = [];
      const workflowName = replace(sectionStep.workflow.name);
      const stepRoute = replace(sectionStep.workflow.route);
      const parentRoute = currentWorkflow.parent.route;
      const stepBaseLink = `${currentWorkflow.baseLink}/${parentRoute}`;

      const newStep = {
        ...sectionStep,
        route: stepRoute,
        routerLink: `${parentRoute}/${stepRoute}`,
        title: req.config.title,
        workflow: {
          name: workflowName,
          templateName: sectionStep.workflow.templateName,
          static: false,
        }
      };

      // add step to section first so that the sub-workflow's status can propagate upwards
      actions.push(DomainActions.Workflow.addStepToSection(req.sectionName, newStep));

      // add workflow
      actions.push(DomainActions.CurrentWorkflow.setWorkflowSteps({
        config: req.config.workflow,
        workflowType: workflowName,
        workflowPath: newStep.route,
        show: false,
        baseLink: stepBaseLink,
        title: newStep.title,
        parent: {
          name: currentWorkflow.name,
          title: currentWorkflow.title,
          route: sectionStep.route,
        }
      }, false));

      // Dispatch actions. These are executed in order.
      return concat([
        ...actions
      ]);
    })
  ));

}
