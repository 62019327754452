
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Actions, Queries, State } from '@app-ngrx-domains';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class InstitutionGuard implements CanActivate, CanActivateChild {

  constructor(
    private logger: NGXLogger,
    private store: Store<State>,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoaded(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoaded(route, state);
  }

  isLoaded(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {
      this.logger.debug(`[institution-guard][${state.url}] checking...`);

      this.store.select(Queries.CurrentProposal.get).pipe(
        filter(p => p && !!p.id),
        take(1)
      ).subscribe(proposal => {
        if (proposal.lead_institution) {
          this.store.dispatch(Actions.Institution.get(proposal.lead_institution.id));
          subscriber.next(true);
          subscriber.complete();
        } else {
          this.logger.error(`[institution-guard][${state.url}] proposal does not have lead institution to load`);
          subscriber.next(false);
          subscriber.complete();
        }
      });
    });
  }
}
