import { Directive, ElementRef, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[popUpTrigger]'
})
export class PopUpTriggerDirective {

  openState = new BehaviorSubject<boolean>(false);

  constructor(
    private el: ElementRef
  ) {}

  get element() {
    return this.el.nativeElement;
  }

  @HostListener('click')
  handleClick() {
    this.openState.next(!this.openState.getValue());
  }

}
