import { IAppState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { APP_ACTION_TYPES } from './app.action';

export function AppReducer(state: IAppState = State.App, action: ActionWithPayload<any>): IAppState {
  switch (action.type) {

    case APP_ACTION_TYPES.SET_ERROR: {
      return {
        ...state,
        error: action.payload
      }
    }

    case APP_ACTION_TYPES.SET_MODAL: {
      return {
        ...state,
        modal: {
          ...action.payload
        }
      }
    }

    case APP_ACTION_TYPES.CLEAR_MODAL: {
      return {
        ...state,
        modal: State.App.modal
      }
    }

    default:
      return state;
  }
}
