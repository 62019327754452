import { Model } from '@app-ngrx-domains';
import { WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';

export const FORMS_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.PERKINS_APPLICATION_FORMS,
  templateName: WORKFLOW_TYPES.PERKINS_APPLICATION_FORMS,
  title: 'Forms',
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.RESPONSE,
      title: 'Responses',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.NEEDS,
      title: 'Local Needs Assessment',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.METRICS,
      title: 'Goals & Metrics',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.SUMMARY,
      title: 'Budget Summary',
      showStatus: true,
      valid: false
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview',
      showStatus: false,
    },
  ]
};
