import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { CHAR_LIMITS, DOCUMENT_TYPES, PROGRAM_KEYS } from '@app-consts';
import { ValidatorsEx } from '@app-utilities';

@Component({
  selector: 'objectives-card',
  templateUrl: './objectives-card.component.html',
})
export class ObjectivesCardComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() canEdit = false;
  @Input() canDelete = true;
  @Input() isFirst: boolean;
  @Input() isPreview = false;
  @Input() isPrimary = false;
  @Input() firstTouch = true;
  @Input() objectiveEA: Model.EAWorkplanObjective;
  @Input() cardHelpText: string;
  @Input() descriptionLength = CHAR_LIMITS.NARRATIVE_MAX;
  @Input() metricsOptions: Array<Model.SelectOption> = [];
  @Input() count: number;
  @Input() showTitleInPreview = false;
  @Input() parentEffortAreas: Array<Model.EffortArea>;

  showUploadDocumentModal = false;
  collectObjectiveName = true;
  collectObjectiveType = false;
  collectEvidence = false;
  collectStrategy = false;
  fileList: Array<Model.Document>;
  proposal = undefined;
  fund: Model.Fund;
  showDeleteObjectiveAlert = false;
  userId: number;
  uniqueId: number;

  docTypesForUpload: Array<number>;
  objectiveTypeOptions: Array<Model.SelectOption>;
  objectiveTypes = [
    'Address new industry priorities',
    'Bridge Supply/Demand Gap',
    'Curriculum development',
    'Faculty professional development',
    'Improve Diversity, Equity, and Inclusion (DEI)',
    'Lab technology adoption',
    'Other',
    'Upskill incumbent workers'
  ];
  swpV3ObjectiveTypes = [
    'Develop new program to address ongoing market need',
    'Develop new program to address acute market need',
    'Increase quality of existing program(s)',
    'Increase enrollment of existing program(s)',
    'Improve career readiness and job placement',
    'Improve Diversity, Equity, and Inclusion (DEI)'
  ];
  evidenceOptions: Array<Model.SelectOption>;
  evidenceTypes = [
    'CCCO-driven',
    'Faculty/College-driven',
    'Industry-validated',
    'LMI data',
    'Other',
    'Regional Consortia-driven'
  ];

  form: FormGroup;
  narrativeMax = CHAR_LIMITS.NARRATIVE_MAX;
  isCAIv2: boolean;
  isSmallProgram: boolean;

  public helpText: { [name: string]: string} = {};

  private destroy$: Subject<boolean> = new Subject();
  private initialized = false;

  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnInit() {
    combineLatest([
      this.store.select(Queries.CurrentProposal.get),
      this.store.select(Queries.Files.getItems),
      this.store.select(Queries.Auth.getCurrentUser),
      this.store.select(Queries.Cfad.get),
    ]).pipe(
      filter(([p, f, u]) => p && p.id),
      takeUntil(this.destroy$)
    ).subscribe(([proposal, files, currentUser, cfad]) => {
      this.proposal = proposal;
      this.fund = proposal.funds[0];
      this.fileList = files.filter(f => f.effort_area_id === this.objectiveEA.id);
      this.userId = !!currentUser ? currentUser.id : undefined;

      // initialize one time set up code.
      this.initialize(proposal, cfad);
    });
  }

  /**
   * Initializes the vars that need to be set up just once.
   * */
  private initialize(proposal, cfad) {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;

    this.isSmallProgram = this.fund.is_small_program;
    this.uniqueId = +this.objectiveEA.id;
    this.collectObjectiveName = this.fund.key !== PROGRAM_KEYS.CAEP;
    this.collectObjectiveType = this.isSmallProgram || [PROGRAM_KEYS.EWD, PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R, PROGRAM_KEYS.RCM].includes(this.fund.parent_key);
    this.collectEvidence = [PROGRAM_KEYS.EWD].includes(this.fund.parent_key);
    this.collectStrategy = this.isSmallProgram || [PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.fund.parent_key);
    this.isCAIv2 = this.fund.parent_key === PROGRAM_KEYS.CAI && this.fund.key === PROGRAM_KEYS.RFA_v2;

    if (this.isSmallProgram || [PROGRAM_KEYS.SWPL_v3, PROGRAM_KEYS.SWPR_v3].includes(this.fund.key)) {
      this.objectiveTypes = this.swpV3ObjectiveTypes;
    }

    this.fillHelpText();

    // prepare form
    this.form = this._fb.group({
      title: [this.objectiveEA.title, [Validators.required, Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN)]],
      description: [this.objectiveEA.description, [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)]],
    });

    if (this.isCAIv2) {
      this.form.addControl('success_metrics', new FormControl(this.objectiveEA.success_metrics.map(attr => attr.value)));
      this.form.addControl('metrics_description', new FormControl(this.objectiveEA.metrics_description, [ValidatorsEx.htmlCharMaximum(this.narrativeMax)]));

      // clean up any obsolete metric references
      const successMetrics = this.form.get('success_metrics').value || [];
      if (successMetrics.length > 0) {
        const metricRefIds = this.metricsOptions.map(mo => mo.value);
        const cleanedSuccessMetrics = [];
        let refresh = false;
        successMetrics.forEach(id => {
          if (metricRefIds.includes(id)) {
            cleanedSuccessMetrics.push(id);
          } else {
            this.removeSuccessMetric(id);
            refresh = true;
          }
        });
        if (refresh) {
          this.form.get('success_metrics').setValue(cleanedSuccessMetrics);
        }
      }
    }

    if (this.collectObjectiveType) {
      this.objectiveTypeOptions = this.objectiveTypes
        .map(type => ({ label: type, value: type }))
        .sort((a, b) => a.label < b.label ? -1 : 1);
      this.form.addControl('objective_type', new FormControl(this.objectiveEA.objective_type, [Validators.required, ValidatorsEx.requiredSelection]));
    }

    if (this.collectEvidence) {
      this.evidenceOptions = this.evidenceTypes
        .map(type => ({ label: type, value: type }))
        .sort((a, b) => a.label < b.label ? -1 : 1);
      this.docTypesForUpload = [DOCUMENT_TYPES.SUPPORTING_DOCS.id];
      this.form.addControl('evidence_type', new FormControl(this.objectiveEA.evidence_type, [Validators.required, ValidatorsEx.requiredSelection]));
      this.form.addControl('evidence_description', new FormControl(this.objectiveEA.evidence_description, [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)]));
    }

    if (this.collectStrategy) {
      this.form.addControl('regional_strategy_description', new FormControl(this.objectiveEA.regional_strategy_description, this.getNarrativeValidators()));
      this.form.addControl('alignment_description', new FormControl(this.objectiveEA.alignment_description, this.getNarrativeValidators()));
    }
  }

  private fillHelpText() {
    // assign default help text
    this.helpText = {
      ['card']: undefined,
      ['description']: 'Description of the objective must be quantifiable and measurable.',
    };

    // help text overrides
    switch (this.fund.parent_key) {
      case PROGRAM_KEYS.CAI: {
        this.helpText.card = 'Objectives should be based on the scope of the proposed project while remaining consistent with the Objectives of the RFA Specification.';
        break;
      }

      case PROGRAM_KEYS.EWD: {
        this.helpText.description = 'Description of the objective must be quantifiable and measurable (e.g., bridge supply/demand gap of 500 workers, industry priority for workers with social work experience, faculty professional development in pedagogy, etc.).';
        break;
      }

      case PROGRAM_KEYS.SWP_R:
      case PROGRAM_KEYS.SWP_L: {
        this.helpText.card = 'The objective can be a single grant-period project, or the description of objective can provide information on how this plan supports the Vision of Success goals or Regional Strategies over a longer term.';
        this.helpText.description = 'Description of the objective must be quantifiable and measurable (e.g., bridge supply/demand gap of 500 workers, industry priority for workers with social work experience, faculty professional development in pedagogy, etc.).';
        break;
      }

      default: {
        this.helpText.card = this.cardHelpText;
        if (this.fund.key === PROGRAM_KEYS.CAEP) {
          this.helpText.description = '';
        }
      }
    }
  }

  persistValue(attributeName: string) {
    const value = this.form.get(attributeName).value;
    const prevValue = this.objectiveEA[attributeName];
    if (value !== null && value !== prevValue) {
      this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: attributeName, value, ea: this.objectiveEA, parentEffortAreas: this.parentEffortAreas }));
    }
  }

  persistAttribute(attributeName: string) {
    const value = this.form.get(attributeName).value;
    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: attributeName, value, ea: this.objectiveEA }));
  }

  addSuccessMetric(metricId: number) {
    this.store.dispatch(Actions.CurrentProposal
      .addMultiAttribute({
        key: 'success_metrics',
        value: metricId,
        ea: this.objectiveEA
      }));
  }

  removeSuccessMetric(metricId: number) {
    this.store.dispatch(Actions.CurrentProposal
      .deleteMultiAttribute({
        key: 'success_metrics',
        value: metricId,
        ea: this.objectiveEA
      }));
  }

  toggleDeleteObjective() {
    this.showDeleteObjectiveAlert = !this.showDeleteObjectiveAlert;
  }

  deleteObjective() {
    // delete all associated files when deleting objective
    this.fileList.forEach(file => {
      this.onDeleteFile(file.id);
    });

    this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({ ea: this.objectiveEA, parentEffortAreas: this.parentEffortAreas }));
    this.toggleDeleteObjective();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ////////// METHODS USED BY TEMPLATE ////////////
  // Title formatter function
  formatDocTitle(file: Model.Document) {
    return file.title || file.filename;
  }

  onClickAddButton() {
    this.showUploadDocumentModal = true;
  }

  onUploadSuccess(file: Model.Document) {
    this.store.dispatch(Actions.Files.append(file));
    this.showUploadDocumentModal = false;
  }

  onUploadFailOrClose() {
    this.showUploadDocumentModal = false;
  }

  onDeleteFile(fileId: number) {
    this.store.dispatch(Actions.Files.delete(fileId));
  }

  private getNarrativeValidators() {
    return this.isSmallProgram ? [] : [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)];
  }
}
