import {Component, EventEmitter, forwardRef, Injector, Input, Output, OnInit, OnDestroy, ElementRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, NgControl, AbstractControl, Validator, ValidationErrors, FormControl, FormGroup, Validators} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { DATE_TIME_MOMENT_FORMAT_STRINGS } from '@app-consts';
import { Utilities } from '@app-models';
import * as moment from 'moment';

const MONTH_DAY_YEAR_FORMATS = {
  parse: {
    dateInput: DATE_TIME_MOMENT_FORMAT_STRINGS.date,
  },
  display: {
    dateInput: DATE_TIME_MOMENT_FORMAT_STRINGS.date,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'po-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_DAY_YEAR_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTimePickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateTimePickerComponent), multi: true }
  ],
})

export class DateTimePickerComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  @Input() labelText: string;
  @Input() labelHidden = false;
  @Input() canEdit = true;
  @Input() errorsOnUntouched = false;
  @Input() placeHolder: string =  'mm/dd/yyyy'; // place holder for date piece
  @Input() required: boolean = false; // picker is required
  @Input() minDateString: string = '2007-01-01';
  @Input() maxDateString: string = '2030-12-31';
  @Output() dateTimeChange: EventEmitter<string> = new EventEmitter();

  dateTimeInputControl: FormGroup;
  hostControl: any;

  minDate: Date;
  maxDate: Date;

  inputId: string;

  private _timeValue: string;
  private _dateValue: string;
  private lastValue: string;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private el: ElementRef,
    private injector: Injector
  ) {
    this.inputId = `${this.el.nativeElement.id}_picker_input`;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit() {
    // get the host control that's hosting the date time control.
    this.hostControl = this.injector.get(NgControl);

    this.dateTimeInputControl = new FormGroup({
      date: new FormControl('', this.required ? [Validators.required] : []),
      time: new FormControl('', this.required ? [Validators.required] : [])
    });

    this.minDate = moment(this.minDateString, DATE_TIME_MOMENT_FORMAT_STRINGS.isoDate).toDate();
    this.maxDate = moment(this.maxDateString, DATE_TIME_MOMENT_FORMAT_STRINGS.isoDate).toDate();

    this.lastValue = this.getDateTimeValue();
  }

  dateInputChange(event: MatDatepickerInputEvent<Date>) {
    // handle date changes.
    this.dateTimeInputControl.get('date').setValue(event.value);
    this.setDateValue(this.dateTimeInputControl.get('date').value);
    this.valueChanged();
  }

  timeInputChange(timeValue: string) {
    // handle time changes.
    this.setTimeValue(timeValue);
    this.valueChanged();
  }

  private get timeValue(): string {
    return this._timeValue;
  }

  private setTimeValue(value: string) {
    this._timeValue = !Utilities.isNil(value) ? Utilities.convertTime(value, true) : null;
  }

  private get dateValue(): string {
    return this._dateValue;
  }
  private setDateValue(value: Date) {
    this._dateValue = !Utilities.isNil(value) ? moment(value).format(DATE_TIME_MOMENT_FORMAT_STRINGS.date) : null;
  }

  private valueChanged() {
    const value = this.getDateTimeValue();

    if (value !== this.lastValue) {
      this.lastValue = value;
      this.onChange(value);
      if (this.dateTimeChange) {
        this.dateTimeChange.emit(value);
      }
    }
  }

  private getDateTimeValue() {
    const dateValue = this.dateValue;
    const timeValue = this.timeValue;

    let dateTimeValue = null;
    if (dateValue !== null) {
      dateTimeValue = (timeValue === null) ? `${dateValue}` : `${dateValue} ${timeValue}`;
    }

    return !Utilities.isNil(dateTimeValue) ? moment(dateTimeValue, DATE_TIME_MOMENT_FORMAT_STRINGS.dateTime).toISOString() : null;
  }

  showLabel() {
    return this.labelText && !this.labelHidden;
  }

  getReadOnlyText() {
    return Utilities.convertToNOVADateTime(this.lastValue);
  }

  /////////////////////////////////////////////////////////////////////
  // ControlValueAccessor
  /////////////////////////////////////////////////////////////////////
  writeValue(value: string) {

    if (!Utilities.isNil(value)) {
      // set the initial value.
      this.lastValue = value;

      const dateValue = moment(value).toDate();
      this.dateTimeInputControl.get('date').setValue(dateValue);
      this.setDateValue(dateValue);

      const timeValue = moment(value).format(DATE_TIME_MOMENT_FORMAT_STRINGS.time12Hrs);
      this.dateTimeInputControl.get('time').setValue(timeValue);
      this.setTimeValue(timeValue);
    }
  }

  onChange = (_: any) => { };
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  onTouched = (_: any) => { };
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.dateTimeInputControl.get('date').disable();
      this.dateTimeInputControl.get('time').disable();
    } else {
      this.dateTimeInputControl.get('date').enable();
      this.dateTimeInputControl.get('time').enable();
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Validator
  /////////////////////////////////////////////////////////////////////
  validate(c: AbstractControl): ValidationErrors | null {
    if (!this.required) {
      // no need to check
      return null;
    }

    const dateCtrl = this.dateTimeInputControl.get('date');
    const timeCtrl = this.dateTimeInputControl.get('time');

    let message: string;
    if (!!dateCtrl.errors && !!timeCtrl.errors) {
      message = 'Select a valid date and time';
    } else if (!!dateCtrl.errors) {
      message = 'Select a valid date';
    } else if (!!timeCtrl.errors) {
      message = 'Select a valid time';
    };

    return !!message ? { dateTime: { message } } : null;
  }
}
