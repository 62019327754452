import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Actions, Model, Queries, State } from '@app-ngrx-domains';
import { MESSAGE_STATES } from '@app/core/consts';
import { UserMessage } from '@app/core/models/user-message';
import { ProgramService } from '@app/core/services';
import { Store } from '@ngrx/store';
import { orderBy } from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Event } from '../../models';

interface ISelectMessageList extends Model.UserMessage {
  selectName: string,
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit, OnDestroy {
  userId: number;
  form: FormGroup;
  MESSAGE_STATES = MESSAGE_STATES;
  tabMap = {
    'Unread': MESSAGE_STATES.UNREAD,
    'Read': MESSAGE_STATES.READ,
    'Archived': MESSAGE_STATES.ARCHIVED
  };

  selectedTab: string = MESSAGE_STATES.UNREAD;
  selectMsgList: Array<ISelectMessageList> = [];
  itemSize: number = 66;  // Height in pixels of each row

  displayedMessage: ISelectMessageList;
  displayedMessageAlert: boolean;

  showDeleteMessageAlert: boolean;

  filterForm: FormGroup;
  filterOpen: boolean;
  filteredProgramIds: Array<number> = [];
  programOptions: Array<Model.SelectOption> = [];

  scrollToMessageId: number;
  messagesLoaded: boolean;

  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @ViewChild('selectAllCheckbox') selectAllCheckbox;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
    private programService: ProgramService,
    private route: ActivatedRoute
  ) {
    // update header.
    this.store.dispatch(Actions.Layout.setHeader({
      ...State.Layout.header,
      title: 'Notifications'
    }));
  }

  ngOnInit() {
    combineLatest([
      this.store.select(Queries.Auth.getCurrentUserId),
      this.programService.smallPrograms.pipe(filter(sp => !!sp), take(1)),
    ]).pipe(take(1))
      .subscribe(([userId, smallPrograms]) => {
        this.userId = userId;

        let parentPrograms = this.programService.parentPrograms;
        let programs = [...parentPrograms, ...smallPrograms].sort((a, b) => a.short_name > b.short_name ? 1 : -1);
        this.programOptions = programs.map(p => ({ value: p.id, label: p.short_name }));

        this.filterForm = this._fb.group({
          program_ids: [[]]
        });

        this.store.select(Queries.Auth.getCurrentUserMessages)
          .pipe(takeUntil(this.destroy$))
          .subscribe(messages => {
            this.updateMessages(messages);

            // Scroll to and select message if necessary
            if (!this.messagesLoaded) {
              this.scrollToMessage(+this.route.snapshot.queryParams.messageId);
              this.messagesLoaded = true;
            }
          });

        this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
          const messageId = +params.messageId;
          if (messageId != null) {
            if (this.selectedTab !== MESSAGE_STATES.UNREAD) {
              // Notification was clicked via header, need to be on Unread tab
              this.selectTab(MESSAGE_STATES.UNREAD);
            } else {
              this.scrollToMessage(messageId);
            }
          }
        });
      });
  }

  scrollToMessage(messageId: number) {
    let index = 0;
    if (messageId !== this.scrollToMessageId) {
      index = this.selectMsgList.findIndex(m => m.id === messageId);

      if (index > -1) {
        // Select the message to display body
        this.selectMessage(this.selectMsgList[index]);
      }
      this.scrollToMessageId = messageId;
    }

    if (index >= 0 && this.virtualScroll) {
      this.virtualScroll.scrollToIndex(index);
    }
  }

  private updateMessages(messages: Array<Model.UserMessage>) {
    const filteredMessages = messages.filter(m => !m.deleted && (this.selectedTab === MESSAGE_STATES.ARCHIVED ? m.archived : !m.archived));
    // build the list & preserve the selection state if possible.
    const selectList = [];
    const selectCtrls = [];
    if (filteredMessages.length > 0) {
      filteredMessages.forEach(msg => {
        const selectName = `msg_${msg.id}`;
        selectList.push({
          selectName: selectName,
          ...msg,
        });

        if (this.form && !this.form.controls[selectName]) {
          this.form.addControl(selectName, new FormControl(false));
        }
        selectCtrls[selectName] = false;
      });
    }
    if (!this.form) {
      // update the selection form.
      this.form = this._fb.group({
        select_all: [false],
        ...selectCtrls,
      });
    } else {
      setTimeout(() => {
        this.setSelectAllCheckboxState();
      });
    }

    // update new lists.
    this.selectMsgList = orderBy(selectList, ['created_at'], ['desc']);
  }

  get messagesSelected() {
    return this.selectMsgList.some(message => this.isChecked(message.selectName));
  }

  getMessages(messageState: string) {
    this.store.dispatch(Actions.Auth.refreshUserMessages(messageState));
  }

  selectTab(tabName: string) {
    const tab = this.tabMap[tabName];
    this.messagesLoaded = false;
    this.selectedTab = tab;
    this.form.reset();
    this.getMessages(tab);
  }

  public checkAll() {
    const checked = this.form.get('select_all').value;
    this.selectMsgList.forEach(msg => {
      this.form.get(msg.selectName).setValue(checked);
    });

    this.setSelectAllCheckboxState();
  }

  public checkMessage(checked: boolean, msg: ISelectMessageList) {
    this.setSelectAllCheckboxState();
  }

  private isChecked(selectName: string) {
    return this.form && this.form.get(selectName) ? this.form.get(selectName).value : false;
  }

  private setSelectAllCheckboxState() {
    const allSelected = this.selectMsgList.every(message => this.isChecked(message.selectName));
    const someSelected = this.selectMsgList.some(message => this.isChecked(message.selectName));

    if (this.selectAllCheckbox && this.selectAllCheckbox.nativeElement) {
      this.selectAllCheckbox.nativeElement.indeterminate = someSelected && !allSelected;
      this.selectAllCheckbox.nativeElement.checked = allSelected;
    }
  }

  public isMessageEmail(msg: ISelectMessageList): boolean {
    return !!msg.email_response;
  }

  public formatContentTitle(msg: ISelectMessageList): string {
    if (msg.message) {
      const msg_subject = msg.message.subject;
      if (msg_subject) {
        return msg_subject;
      } else if (msg.message.event_id && msg.message.event) {
        return Event.formatName(msg.message.event);
      }
    } else if (msg.message_title) {
      return msg.message_title;
    }
    return '[No Title]';
  }

  public openDeleteMessageAlert(forDisplayed?: boolean) {
    this.displayedMessageAlert = forDisplayed;
    this.showDeleteMessageAlert = true;
  }

  public closeDeleteMessageAlert(confirm?: boolean) {
    this.showDeleteMessageAlert = false;

    if (confirm) {
      this.markMessages(MESSAGE_STATES.DELETED, true, this.displayedMessageAlert ? this.displayedMessage : null);
    }
  }

  public markMessages(markType: string, value: boolean, msg?: ISelectMessageList) {
    const messages = msg ? [msg] : this.getCheckedMessages();
    if (messages.length > 0) {
      this.store.dispatch(Actions.Auth.markUserMessages({
        value,
        markType,
        messages,
      }));

      messages.forEach(msg => {
        msg[markType] = value;

        if (this.displayedMessage && msg.id === this.displayedMessage.id) {
          this.displayedMessage = msg;
        }
      });

      if (this.displayedMessage && markType === MESSAGE_STATES.DELETED) {
        this.displayedMessage = null;
      }
    }
  }

  public selectMessage(msg: ISelectMessageList) {
    this.displayedMessage = msg;
    if (!msg.read) {
      this.markMessages(MESSAGE_STATES.READ, true, msg);
    }
  }

  public toggleFilterDropdown(event?) {
    if (event) {
      event.stopPropagation();
    }

    this.filterOpen = !this.filterOpen;
  }

  public setFilter() {
    this.filteredProgramIds = this.filterForm.value.program_ids;
    this.getMessages(this.selectedTab);
  }

  public getCheckedMessages() {
    return this.selectMsgList.filter(msg => this.isChecked(msg.selectName));
  }

  public trackById(index: number, item: any) {
    return item.id;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
