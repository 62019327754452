import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Queries, State } from '@app-ngrx-domains';
import { ROUTER_LINKS } from '@app/core/consts';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class StudentPopulationPlanGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<State>,
    private logger: NGXLogger
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {

      this.logger.debug('[student-population-plan-guard] checking...');

      const metricDefinition = route.params['metricDefinition'];
      const populationId = Number(route.params['populationId']);

      this.store.select(Queries.CurrentProposal.getSEP).pipe(
        take(1)
      ).subscribe(proposal => {
        const population = proposal.student_populations.find(p => p.id === populationId);
        if (population) {
          if (population.disabled) {
            // If population is disabled, that means user had deleted this population from the "Students Experiencing DI" tab.
            this.logger.error(`[student-population-plan-guard][${state.url}] Student Population has been deleted.`);
            this.rerouteToPageNotFound(subscriber, state);
            return;
          }

          // Make sure this metric is enabled for this student population
          if (!population.metric_definitions.some(m => m.value === metricDefinition)) {
            this.logger.error(`[student-population-plan-guard][${state.url}] Metric '${metricDefinition}' not enabled for Student Population id '${populationId}'.`);
            this.rerouteToPageNotFound(subscriber, state);
            return;
          }
        } else {
          this.logger.error(`[student-population-plan-guard][${state.url}] Invalid Student Population id '${populationId}'.`);
          this.rerouteToPageNotFound(subscriber, state);
          return;
        }

        this.logger.debug(`[student-population-plan-guard][${state.url}] has access to route`);
        subscriber.next(true);
        subscriber.complete();
      });
    });
  }

  private rerouteToPageNotFound(subscriber, state) {
    this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
    subscriber.next(false);
    subscriber.complete();
  }
}