import { Component, Input } from '@angular/core';
import { Model } from '@app-ngrx-domains';
import { Profile, Institution } from '@app/core/models';

@Component({
  selector: 'profile-info',
  templateUrl: './profile-info.component.html'
})
export class ProfileInfoComponent {
  @Input() set user(u: Model.User) {
    if (u && u.id) {
      this.is_set = true;
      this.full_name = Profile.contactFullName(u);
      this.email_address = u.email_address;
      this.phone = u.phone;
      this.phone_ext = u.phone_extension;
      if (u.employer && u.employer.id) {
        this.employer = Institution.getLongNameOrName(u.employer);
        this.address = Institution.getFullAddress(u.employer)
      }
    } else {
      this.is_set = false;
    }
  }

  is_set: boolean = false;
  full_name: string;
  email_address: string;
  phone: string;
  phone_ext: string;
  title: string;
  employer: string;
  address: string;

  constructor() {}
}
