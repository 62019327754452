import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Model } from '@app-ngrx-domains';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'reorder-list',
  templateUrl: './reorder-list.component.html'
})

export class ReorderListComponent implements OnInit {

  @Input() header?: string;
  @Input() helpText?: string;
  @Input() data: Array<Model.ReorderListData>;
  @Input() isMoveable = () => true;
  @Input() sticky: boolean;
  @Input() canEdit: boolean = false;
  @Output() orderChanged: EventEmitter<{ newOrder: Array<{ id: number }>, subSectionId?: string | number}> = new EventEmitter();

  form: FormGroup;
  useAccessibilityMode: boolean = false;

  constructor(
    private _fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this._fb.group({
      accessibleMode: [this.useAccessibilityMode]
    });
  }

  reorderItems(items: Array<{ id: number }>, event: { previousIndex: number, currentIndex: number }, subSectionId?: string | number) {
    const newOrder = [...items];
    moveItemInArray(newOrder, event.previousIndex, event.currentIndex);
    this.orderChanged.emit({ newOrder, subSectionId });
  }

  toggleAccessibilityMode() {
    this.useAccessibilityMode = this.form.get('accessibleMode').value;
  }

  dragDropDisabled(items: Array<any>) {
    return !this.canEdit || (this.useAccessibilityMode || items?.length < 2);
  }

}
