import { TemplateRef, ViewContainerRef } from '@angular/core';
import { State, Model } from '@app-ngrx-domains';
import { EnumErrorTypes } from '../models';

/**
 * States managed by reducer; used also as initial states
 */
State.App = {
  modal: undefined,
  error: {
    type: EnumErrorTypes.none,
  }
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IAppState {
    modal?: {
      inputs: { [key: string]: any }
      template: ViewContainerRef,
      templateContext?: { [key: string]: any },
    }
    error: Model.ErrorHandler;
  }

  interface State {
    App: IAppState;
  }
}
