import { Directive, ElementRef, HostListener, Input, OnInit, OnDestroy } from '@angular/core';
import { NgControl} from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { Queries, State } from '@app-ngrx-domains';
import { CommentService } from '@app/core/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[inputRef]'
})
export class InputRefDirective implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject();

  // tableRow and tableColumn used by multi-paste directive (should be 0 indexed)
  @Input() tableRow: number;
  @Input() tableColumn: number;
  @Input() disableComments: boolean = false;

  element: HTMLInputElement;
  focus = false;
  focusSubject = new BehaviorSubject<boolean>(null);
  keyUpSubject = new BehaviorSubject<KeyboardEvent>(null);
  pasteSubject = new BehaviorSubject<ClipboardEvent>(null);
  isCommentsOpen = false;
  disabledInputTypes = ['radio', 'checkbox']

  constructor(
    private el: ElementRef,
    public control: NgControl,
    private store: Store<State>,
    private commentService: CommentService,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit() {
    this.element = this.el.nativeElement;
    this.focusSubject.asObservable().subscribe((value) => {
      this.focus = value;
    });

    // Listen for comments open
    this.store.select(Queries.Layout.isCommentsOpen)
      .pipe(takeUntil(this.destroy$))
      .subscribe(isOpen => {
        this.isCommentsOpen = isOpen;
      });
  }

  hasError() {
    return this.control.errors;
  }

  @HostListener('focus')
  handleFocus() {
    this.focusSubject.next(true);
  }

  @HostListener('focusin')
  handleFocusIn() {
    // only show the comment widget from here if it's not a froala editor
    // the html-text.commpent will set focus if something is selected instead
    if (this.allowComments && this.element.tagName !== 'PO-HTML-TEXTAREA') {
      this.commentService.setShowCommentWidget({id: this.element.id});
    }
  }

  @HostListener('focusout')
  handleFocusOut() {
    if (this.allowComments) {
      this.commentService.setHideCommentWidget({id: this.element.id});
    }
  }

  @HostListener('blur')
  handleBlur() {
    this.focusSubject.next(false);
  }

  @HostListener('keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    this.keyUpSubject.next(event);
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    this.pasteSubject.next(event);
  }

  get allowComments() {
    return !this.disableComments && this.isCommentsOpen && !this.disabledInputTypes.includes(this.element.type);
  }

}
