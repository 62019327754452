import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logger } from '@app/app-logger';
import { collapsibleCardAnimation } from '@app/shared.generic/animations';

@Component({
  selector: 'filter-section',
  templateUrl: './filter-section.component.html',
  animations: [collapsibleCardAnimation]
})
export class FilterSectionComponent implements OnInit {
  @Input() filter: ISearchModalFilter;
  @Input() startOpen: boolean;
  isOpen: boolean;
  @Input()
  set options(value) {
    this._options = value.map(v => ({ value: v, checked: false }));
    this.results = [...this._options];
  }

  get options() {
    return this._options;
  }

  _options = [];
  @Output() filterChanged = new EventEmitter();
  results = [];

  constructor() { }

  ngOnInit() {
    if (!(this.filter && this.filter.field && this.filter.label)) {
      Logger.error('A unique filter field and label are required in filter-section component:');
      Logger.log(this);
      return;
    }
    this.isOpen = this.startOpen;
  }

  open() {
    this.isOpen = !this.isOpen;
  }

  search(searchText: string) {
    if (searchText) {
      const initialRegexp = /([a-zA-Z0-9._-]+)/g;
      const wordMatches = searchText.match(initialRegexp);

      if (!!wordMatches) {
        this.results = this.options.filter(option => wordMatches.every(word => {
          return option.value.toLowerCase().search(word.toLowerCase()) >= 0;
        }));
      } else {
        this.results = [];
      }
    } else {
      this.results = [...this.options];
    }
  }

  onFilterChanged() {
    this.filterChanged.emit({
      field: this.filter.field,
      options: this.selectedOptions
    });
  }

  get selectedOptions() {
    return this.options.filter(o => o.checked).map(o => o.value);
  }
}

export interface ISearchModalFilter {
  label?: string,
  field?: string,
  options?: Array<string>
}