import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, Model, Queries, State } from '@app-ngrx-domains';
import { MESSAGE_STATES } from '@app/core/consts';
import { AppUtils } from '@app/core/utilities';
import { slideOutAnimation } from '@app/shared.generic/animations';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  animations: [slideOutAnimation]
})

export class DashboardComponent implements OnInit, OnDestroy {

  hideEventFeatures: boolean = true;
  mentionNotification: Model.UserMessage;
  mentionHeader: string;
  mentionBody: string;
  commentResourceId: number;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute
  ) {
    const showCalendar = AppUtils.showWIPFeature('calendar');
    if (showCalendar || this.route.snapshot.queryParams.showCalendar) {
      this.hideEventFeatures = false;
    }
  }

  ngOnInit() {
    this.store.dispatch(Actions.Layout.setHeader({
      ...State.Layout.header,
      title: 'Dashboard'
    }));

    this.store.select(Queries.Auth.getLatestUserMention).pipe(
      takeUntil(this.destroy$),
      debounceTime(500)
    ).subscribe(message => {
      this.mentionNotification = message;

      if (this.mentionNotification) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = this.mentionNotification.message_text;

        const commentCreator = tempDiv.querySelector('#comment_creator');
        const commentBody = tempDiv.querySelector('#comment_body');
        const commentLink = tempDiv.querySelector('#comment_link');

        this.mentionHeader = commentCreator && commentCreator.textContent || 'You\'ve been mentioned in a comment:';
        this.mentionBody = commentBody && commentBody.innerHTML;

        const href = commentLink && commentLink['href'] || '';
        const resourceId = href.match(/resourceId=([^&]*)/);
        this.commentResourceId = resourceId && resourceId.length > 1 ? resourceId[1] : undefined;
      } else {
        this.commentResourceId = undefined;
      }
    });
  }

  dismissNotification() {
    this.store.dispatch(Actions.Auth.markUserMessages({
      value: true,
      markType: MESSAGE_STATES.READ,
      messages: [this.mentionNotification]
    }));

    this.mentionNotification = undefined;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
