import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, State, Queries, Model } from '@app-ngrx-domains';
import { LookupService, ProgramService } from '@app-services';
import { EnumErrorTypes, RolePermissions, Utilities } from '@app-models';
import { AppUtils, ValidatorsEx } from '@app-utilities';
import moment from 'moment';
import { DATE_TIME_MOMENT_FORMAT_STRINGS } from '@app/core/consts';

@Component({
  selector: 'app-add-role-users-modal',
  templateUrl: './add-role-users-modal.component.html',
})
export class AddRoleUsersModalComponent implements OnInit {
  @Input() headerText: string;
  @Input() cancelButtonText = 'Cancel';
  @Input() roleData: Model.RolePermissions;
  @Input() selectedInstitution: Model.Institution;
  @Input() institutionOptions: Array<Model.SelectOption>; // Used to limit institution selection to provided options
  @Input() selectedFund: Model.Fund;

  @Output() closeAction: EventEmitter<null> = new EventEmitter();
  @Output() cancelAction: EventEmitter<null> = new EventEmitter();
  @Output() addSuccessAction: EventEmitter<Array<Model.UserRoleScope>> = new EventEmitter();

  @Input() existingContacts: Array<Model.UserRoleScope> = [];
  disabledContacts: Array<number> = [];
  collisionNames: Array<string> = [];

  formAddRole: FormGroup;

  contactList: Array<Model.SelectOption> = [];
  fundList: Array<Model.SelectOption> = [];
  sectorList: Array<Model.SelectOption> = [];
  institutionsLookupFormatted: Array<Model.SelectOption> = [];

  fundDisabled = false;
  showPermissions = false;
  collectFundScope = false;
  collectInstitutionScope = false;
  collectSectorScope = false;
  showExpires = false;
  minDate: string;
  showInviteUser: boolean;

  constructor(
    private _fb: FormBuilder,
    private lookupService: LookupService,
    private programService: ProgramService,
    private store: Store<State>,
  ) { }

  ngOnInit() {
    const selectedInstitutionOption = this.selectedInstitution ?
      AppUtils.formatInstitution(this.selectedInstitution)
      : undefined;


    // Get current date to set minimum value on expirations
    this.minDate = moment().startOf('day').add(1, 'day').toISOString();

    this.formAddRole = this._fb.group({
      users: [[], [ValidatorsEx.requiredSelection]],
      fund_type: [this.selectedFund ? this.selectedFund.id : undefined],
      institution: [selectedInstitutionOption],
      sector_id: [undefined],
      role_expires: [false, [Validators.required]],
      expire_on: [undefined, [ValidatorsEx.date, ValidatorsEx.minDate(
        moment.utc(this.minDate, DATE_TIME_MOMENT_FORMAT_STRINGS.isoDate).toString()
        )]]
    });

    this.fundList = this.selectedFund
      ? [{ value: this.selectedFund.id, label: this.selectedFund.name }]
      : this.programService.getProgramOptions(true);

    // Role has already been selected, so based on that show other scopes accordingly.
    this.fundDisabled = false;
    this.collectFundScope = false;
    this.collectInstitutionScope = false;
    this.collectSectorScope = false;

    // show permissions.
    this.showPermissions = true;

    // based on the role configuration, collect fund type and/or institution.
    this.collectFundScope = !this.selectedFund && RolePermissions.isRequired(this.roleData.fund);
    this.collectInstitutionScope = !this.selectedInstitution && RolePermissions.isRequired(this.roleData.institution);
    this.collectSectorScope = RolePermissions.isRequired(this.roleData.sector);

    this.runCollisionCheck();

    if (this.collectFundScope) {
      this.formAddRole.controls['fund_type'].setValidators([Validators.required]);
    }

    if (this.collectInstitutionScope) {
      this.formAddRole.controls['institution'].setValidators([Validators.required]);
    }

    if (this.collectSectorScope) {
      this.store.select(Queries.LookupTables.getMonitoredSectors)
        .pipe(take(1))
        .subscribe((sectors) => {
          this.sectorList = sectors;
          this.formAddRole.controls['sector_id'].setValidators([Validators.required]);
        });
    }
  }

  runCollisionCheck() {
    const {users = [], fund_type = undefined, institution = undefined }
     = this.formAddRole.value;

    this.collisionNames = [];
    this.disabledContacts = [];
    this.existingContacts.forEach(contact => {
      if (contact.institution_id === institution
          && contact.fund_id === fund_type
          && contact.role_id === this.roleData.id) {
            users.forEach(user => {
              const collision = user.value === contact.user_id;
              if (collision) {
                this.collisionNames.push(user.label);
              }
            })
            this.disabledContacts.push(contact.user_id);
          }
    })
  }

  getCollisionNames() {
    return this.collisionNames.reduce((ret, name, i) => {
      ret += name;
      return ret + (i < this.collisionNames.length - 1 ? '; ' : '');
    }, '');
  }

  public getContactList(filter: string, limit: number) {
    this.lookupService.formattedContactList$(filter, limit)
      .subscribe(
        (res) => {
          this.contactList = res;
        },
        (err) => {
          this.store.dispatch(Actions.App.setError({
            type: EnumErrorTypes.api,
            location: this.constructor.name,
            show: true,
            raw: err
          }));
        }
      );
  }

  public get isValid(): boolean {
    const users = this.formAddRole.get('users').value;
    if (!users || users.length < 1) {
      return false;
    }

    const fundType = this.formAddRole.get('fund_type').value;
    if (this.collectFundScope && !fundType) {
      return false;
    }

    const institutionId = this.formAddRole.get('institution').value;
    if (this.collectInstitutionScope && !institutionId) {
      return false;
    }

    const sectorId = this.formAddRole.get('sector_id').value;
    if (this.collectSectorScope && !sectorId) {
      return false;
    }

    const roleExpires = this.formAddRole.get('role_expires').value;
    if (roleExpires) {
      const dateControl = this.formAddRole.get('expire_on');
      if (!dateControl.valid) {
        return false;
      }
    }

    return !this.collisionNames.length;
  }

  updateInstitutionsList(filterStr: string, limit = 30) {
    const filters = {
      match_strings: filterStr,
      limit
    };

    this.lookupService.formattedInstitutionList$(filters)
      .subscribe(
        (res) => {
          this.institutionsLookupFormatted = res;
        },
        (err) => {
          this.store.dispatch(Actions.App.setError({
            type: EnumErrorTypes.api,
            location: this.constructor.name,
            show: false,
            raw: err,
          }));
        }
      );
  }

  public addUsersRoleScope() {
    if (this.formAddRole.get('role_expires').value === false) {
      this.formAddRole.controls['expire_on'].clearValidators();
      this.formAddRole.controls['expire_on'].reset();
    } else {
      this.formAddRole.controls['expire_on'].setValidators([ValidatorsEx.date]);
      this.formAddRole.controls['expire_on'].updateValueAndValidity();
    }

    // collect list of role scopes to add.
    const roleScopes = [];

    const roleId = this.roleData.id;
    const users = this.formAddRole.get('users').value;
    const fundTypes = this.formAddRole.get('fund_type').value;
    const institutionOption = this.formAddRole.get('institution').value;
    const institutionId = institutionOption && institutionOption.value ? institutionOption.value : institutionOption;
    const sectorId = this.formAddRole.get('sector_id').value;
    const expirationDate = this.formAddRole.get('expire_on').value;

    const scope = {
      role_id: roleId,
    };
    if (institutionId) {
      scope['institution_id'] = institutionId;
    }
    if (sectorId) {
      scope['sector_id'] = sectorId;
    }
    if (expirationDate) {
      scope['expire_on'] = moment(expirationDate).format(DATE_TIME_MOMENT_FORMAT_STRINGS.isoDate);
    }

    users.forEach(user => {

      if (fundTypes) {
        if (Array.isArray(fundTypes)) {
          fundTypes.forEach(fundId => {
            roleScopes.push({
              ...scope,
              user_id: user.value,
              fund_id: fundId
            });
          });
        } else {
          roleScopes.push({
            ...scope,
            user_id: user.value,
            fund_id: fundTypes
          });
        }
      } else {
        roleScopes.push({
          ...scope,
          user_id: user.value,
        });
      }
    });

    // ask parent to add the role scopes.
    this.addSuccessAction.emit(roleScopes);
  }

  toggleInviteUser() {
    this.showInviteUser = !this.showInviteUser;
  }

  inviteSuccess(user: any) {
    this.showInviteUser = false;
    // create selectOption of newly created user and add to list
    const updatedUsersList = this.formAddRole.get('users').value.concat([Utilities.formatContact(user)]);
    this.formAddRole.get('users').setValue(updatedUsersList);
    this.store.dispatch(Actions.App.showInfo(`<p><strong>${user.first_name} ${user.last_name}</strong> has been invited to NOVA.</p>`));
  }
}
