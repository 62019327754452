import { IAppState, Model, Query, Queries, Root, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IAppState>('App');

/**
 * Predefined queries to run against root states.
 */
export interface IAppQueries {
  getError: Query<Model.ErrorHandler>,
  getModal: Query<{ template: any }>
}

Queries.App = {
  getError: fromRoot(state => state.error),
  getModal: fromRoot(state => state.modal)
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    App: Query<IAppState>;
  }

  interface Queries {
    App: IAppQueries;
  }
}
