import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State, Actions, Model } from '@app-ngrx-domains';
import { CHAR_LIMITS } from '@app-consts';
import { ValidatorsEx } from '@app-utilities';

@Component({
  selector: 'outcomes-card',
  templateUrl: './outcomes-card.component.html'
})
export class OutcomesCardComponent implements OnInit, AfterViewInit {

  @Input() workplanOutcome: Model.EAWorkplanOutcome;
  @Input() activityOptions: Array<Model.SelectOption> = [];
  @Input() objectiveOptions: Array<Model.SelectOption> = [];
  @Input() metricsOptions: Array<Model.SelectOption> = [];
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() isPreview: boolean;
  @Input() version: string = 'v2';
  @Input() firstTouch: boolean;
  @Input() isFirst: boolean;
  @Input() count: number;
  @Input() isCAI: boolean;

  form: FormGroup;
  showDeleteWarning: boolean;
  titleMax = CHAR_LIMITS.SHORT;
  narrativeMax = CHAR_LIMITS.NARRATIVE_MAX;
  narrativeMin = CHAR_LIMITS.NARRATIVE_MIN;

  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnInit() {
    this.form = this._fb.group({
      title: [this.workplanOutcome.title, [Validators.required, Validators.minLength(this.narrativeMin), Validators.maxLength(this.titleMax)]],
      description: [this.workplanOutcome.description, [Validators.required, ValidatorsEx.htmlCharMinimum(this.narrativeMin), ValidatorsEx.htmlCharMaximum(this.narrativeMax)]],
      success_metrics: [(this.useVisionGoal ?
        this.workplanOutcome.vision_goal_metrics.map(vGM => vGM.value) :
        this.workplanOutcome.success_metrics.map(attr => attr.value)), [Validators.required]],
      metrics_description: [this.workplanOutcome.metrics_description, [Validators.required, ValidatorsEx.htmlCharMinimum(this.narrativeMin), ValidatorsEx.htmlCharMaximum(this.narrativeMax)]]
    });

    if (this.isCAI) {
      let objectiveEaId = this.workplanOutcome.objective_ea_id;
      if (!!objectiveEaId) {
        const objectiveIds = this.objectiveOptions.map(obj => obj.value);
        if (!objectiveIds.includes(objectiveEaId)) {
          // unlink this ref ea id.
          this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: 'objective_ea_id', value: undefined, ea: this.workplanOutcome }));
          objectiveEaId = undefined;
        }
      }
      this.form.addControl('objective_ea_id', new FormControl(objectiveEaId, [Validators.required]));
    } else {
      let activityEaId = this.workplanOutcome.activity_ea_id;
      if (!!activityEaId) {
        const activityIds = this.activityOptions.map(act => act.value);
        if (!activityIds.includes(activityEaId)) {
          // unlink this ref ea id.
          this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: 'activity_ea_id', value: undefined, ea: this.workplanOutcome }));
          activityEaId = undefined;
        }
      }
      this.form.addControl('activity_ea_id', new FormControl(activityEaId, [Validators.required]));
    }

    // clean up any obsolete metric references
    const successMetrics = this.form.get('success_metrics').value;
    if (successMetrics.length > 0) {
      const metricRefIds = this.metricsOptions.map(mo => mo.value);
      const cleanedSuccessMetrics = [];
      let refresh = false;
      successMetrics.forEach(id => {
        if (metricRefIds.includes(id)) {
          cleanedSuccessMetrics.push(id);
        } else {
          this.removeSuccessMetric(id);
          refresh = true;
        }
      });
      if (refresh) {
        this.form.get('success_metrics').setValue(cleanedSuccessMetrics);
      }
    }
  }

  persistAttribute(attributeName: string) {
    const value = this.form.get(attributeName).value;
    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: attributeName, value, ea: this.workplanOutcome }));
  }

  addSuccessMetric(metricId: number) {
    this.store.dispatch(Actions.CurrentProposal
      .addMultiAttribute({
        key: this.useVisionGoal ? 'vision_goal_metrics' : 'success_metrics',
        value: metricId,
        ea: this.workplanOutcome
      }));
  }

  removeSuccessMetric(metricId: number) {
    this.store.dispatch(Actions.CurrentProposal
      .deleteMultiAttribute({
        key: this.useVisionGoal ? 'vision_goal_metrics' : 'success_metrics',
        value: metricId,
        ea: this.workplanOutcome
      }));
  }

  delete() {
    this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({ ea: this.workplanOutcome }));
  }

  toggleShowWarning() {
    this.showDeleteWarning = !this.showDeleteWarning;
  }

  get allowDelete() {
    return this.canEdit && this.canDelete && (!this.isFirst || this.workplanOutcome && this.workplanOutcome.id > 0);
  }

  get cardTitle() {
    return this.workplanOutcome.title || 'Outcome';
  }

  get useVisionGoal() {
    return !this.isCAI;
  }

  get showSuccessMetrics() {
    return !this.isCAI || this.version === 'v1';
  }
}
