import { Model } from '@app-ngrx-domains';
import { WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';
import { FORMS_WORKFLOW_CONFIG } from './application-forms-workflow-config.const';
import { CERTIFICATION_WORKFLOW_CONFIG } from './application-certification-workflow-config.const';
import {
  SUB_APPLICATIONS_WORKFLOW_CONFIG,
  SUB_APPLICATIONS_TOP_CODES_WORKFLOW_CONFIG,
  SUB_APPLICATIONS_ACROSS_CTE_WORKFLOW_CONFIG,
  SUB_APPLICATIONS_ADMIN_WORKFLOW_CONFIG
} from './application-sub-applications-workflow-config.const';


export const APPLICATION_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.PERKINS_APPLICATION,
  templateName: WORKFLOW_TYPES.PERKINS_APPLICATION,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.DETAILS,
      title: 'Details & Status',
      showStatus: false,
    },
    {
      route: WORKFLOW_STEPS.MAP,
      title: 'Application Map',
      showStatus: false,
    },
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.BUDGET,
      title: 'Budget Summary',
      showStatus: false,
      workflow: {
        name: WORKFLOW_TYPES.PERKINS_MODIFY_BUDGET,
        templateName: WORKFLOW_TYPES.PERKINS_MODIFY_BUDGET,
        route: WORKFLOW_STEPS.BUDGET,
        static: false
      },
      hide: true
    },
    {
      route: WORKFLOW_STEPS.FORMS,
      title: 'Forms',
      showStatus: true,
      workflow: {
        name: WORKFLOW_TYPES.PERKINS_APPLICATION_FORMS,
        templateName: WORKFLOW_TYPES.PERKINS_APPLICATION_FORMS,
        static: true,
        route: WORKFLOW_STEPS.FORMS,
      }
    },
    {
      route: WORKFLOW_STEPS.CERTIFICATION,
      title: 'Certification',
      showStatus: true,
      workflow: {
        name: WORKFLOW_TYPES.PERKINS_APPLICATION_CERTIFICATION,
        templateName: WORKFLOW_TYPES.PERKINS_APPLICATION_CERTIFICATION,
        static: true,
        route: WORKFLOW_STEPS.CERTIFICATION,
      }
    },
    {
      route: 'sub-applications',
      title: 'CCD / College',
      showStatus: true,
      workflow: {
        name: `${WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS}/:institutionId`,
        templateName: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS,
        static: false,
        route: 'sub-applications/:institutionId',
      }
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      showStatus: false,
    }
  ]
};

export const MODIFY_BUDGET_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.PERKINS_MODIFY_BUDGET,
  templateName: WORKFLOW_TYPES.PERKINS_MODIFY_BUDGET,
  title: 'Modify Budget',
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.SUMMARY,
      title: 'Budget Summary',
      showStatus: false
    },
    {
      route: `institutions/:institutionId`,
      title: 'CCD / College',
      showStatus: true,
    }
  ]
};

export const APPLICATION_WORKFLOW_CONFIG_TABLE = {
  [WORKFLOW_TYPES.PERKINS_APPLICATION]: APPLICATION_WORKFLOW_CONFIG,
  [WORKFLOW_TYPES.PERKINS_APPLICATION_FORMS]: FORMS_WORKFLOW_CONFIG,
  [WORKFLOW_TYPES.PERKINS_APPLICATION_CERTIFICATION]: CERTIFICATION_WORKFLOW_CONFIG,
  [WORKFLOW_TYPES.PERKINS_MODIFY_BUDGET]: MODIFY_BUDGET_WORKFLOW_CONFIG,
  [WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS]: SUB_APPLICATIONS_WORKFLOW_CONFIG,
  [WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_TOP_CODES]: SUB_APPLICATIONS_TOP_CODES_WORKFLOW_CONFIG,
  [WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ACROSS_CTE]: SUB_APPLICATIONS_ACROSS_CTE_WORKFLOW_CONFIG,
  [WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ADMIN]: SUB_APPLICATIONS_ADMIN_WORKFLOW_CONFIG
};
