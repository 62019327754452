import { State } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.Entities = {
  lead: {
    institution_id: null,
    proposal_id: null,
  },
  members: {},
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {

  /**
   * Interface definition of current entity states that this reducer manages.
   */
  export interface IEntitiesState {
    readonly program_year?: Model.Duration;
    readonly direct_funding?: boolean;
    lead: Model.Member;
    members: { [duration_id: number]: Array<Model.Member> };
    current_member_institution_id?: number;
    current_duration_id?: number;
  }

  interface State {
    Entities: IEntitiesState;
  }
}
