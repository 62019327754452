import { filter, skipWhile, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { ApiService, LookupService, PermissionsService, ProgramService } from '@app-services';
import { ProposalBase as Proposal } from '@app-models';
import { AREAS, PROGRAM_KEYS, WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';
import { PlanWorkflowValidatorService } from '@app/RCM/validators/plan-workflow-validator.service';

@Component({
  selector: 'rcm-plan-workflow',
  template: '',
})
export class PlanWorkflowComponent implements OnInit, OnDestroy {

  protected program: Model.Fund;
  private destroySubject$: Subject<boolean> = new Subject();

  private visibleWorkflow = false;
  private baseLink: string;
  private isPlanProposal: boolean;

  constructor(
    protected store: Store<State>,
    protected apiService: ApiService,
    protected programService: ProgramService,
    protected permissionsService: PermissionsService,
    protected lookupService: LookupService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected workflowValidatorService: PlanWorkflowValidatorService,
  ) {
  }

  private _currentWorkflowStep: string;
  protected get currentWorkflowStep(): string {
    return this._currentWorkflowStep;
  }

  protected set currentWorkflowStep(workflowStep: string) {
    this._currentWorkflowStep = workflowStep;
  }

  ngOnInit() {
    // wait until workflows have been built
    this.store.select(Queries.Workflow.get)
      .pipe(
        withLatestFrom(this.store.select(Queries.CurrentProposal.get)),
        skipWhile(([workflows, p]) => !workflows || !workflows[WORKFLOW_TYPES.RCM_PLAN]),
        take(1)
      )
      .subscribe(([workflows, proposal]) => {
        this.isPlanProposal = Proposal.isPlan(proposal);

        if (this.isPlanProposal) {
          // set plan workflow as current and make it visible if the plan is in draft mode.
          this.visibleWorkflow = Proposal.planIsDraft(proposal);
          this.store.dispatch(Actions.CurrentWorkflow.setCurrentWorkflow(WORKFLOW_TYPES.RCM_PLAN, this.visibleWorkflow, this.currentWorkflowStep));
          this.program = this.programService.getProgramById(proposal.fund_ids[0]);
          this.baseLink = `${PROGRAM_KEYS.RCM}/plans/${proposal.id}`;

          this.permissionsService.canEditProject(proposal, AREAS.PROJECT, this.route.snapshot)
            .pipe(take(1))
            .subscribe(canEdit => {
              this.manageWorkflow(canEdit);
            });
        }
      });
  }

  ngOnDestroy() {
    if (this.isPlanProposal) {
      // clear action buttons.
      this.store.dispatch(Actions.Layout.setActions([]));
    }

    this.destroySubject$.next(true);
    this.destroySubject$.complete();
  }

  /**
   * Updates workflow status & shows/hides plan workflow & perform validations.
   */
  private manageWorkflow(canEdit: boolean) {
    let previousStatus: string;
    let previewShowSteps = true;

    // if user can edit the plan, then always show the workflow
    if (canEdit && !this.visibleWorkflow) {
      const detailsLink = `/${this.baseLink}/${WORKFLOW_STEPS.DETAILS}`;
      if (detailsLink === this.router.url) {
        // re-route to preview page.
        const previewLink = `/${this.baseLink}/${WORKFLOW_STEPS.PREVIEW}`;
        this.router.navigate([previewLink], { replaceUrl: true });
        return;
      }

      previewShowSteps = false;
      this.visibleWorkflow = true;
      this.store.dispatch(Actions.Workflow.showSteps(previewShowSteps, [WORKFLOW_STEPS.CONTACTS, WORKFLOW_STEPS.PREVIEW]));
      this.store.dispatch(Actions.Workflow.show(this.visibleWorkflow));
    }

    combineLatest([
      this.store.select(Queries.CurrentProposal.get),
      this.store.select(Queries.Contacts.get),
      this.store.select(Queries.Files.getItems),
      this.store.select(Queries.LookupTables.getMetricsDefinitions),
    ]).pipe(
      filter(([p, p_c, f, m_d]) => p && p.id && !!p_c),
      takeUntil(this.destroySubject$)
    ).subscribe(([proposal, proposal_contacts, files, metric_definitions]) => {
      // show or hide workflows or workflow steps.
      const status = Proposal.getPlanState(proposal);
      if (status !== previousStatus) {
        // update status.
        this.store.dispatch(Actions.Layout.setHeaderTitles({ status }));
        previousStatus = status;

        const isDraft = Proposal.planIsDraft(proposal);
        const isCertified = Proposal.planIsCertified(proposal);

        // if user can edit, then show the main workflow
        if (canEdit) {
          const canShowSteps = isDraft;
          if (previewShowSteps !== canShowSteps) {
            previewShowSteps = canShowSteps;
            // show/hide the workflow steps.
            this.store.dispatch(Actions.Workflow.showSteps(canShowSteps, [WORKFLOW_STEPS.PREVIEW]));
          }
        } else {
          // user doesn't have permission to edit - show or hide workflow based on status.

          // prevent user from routing to hidden workflow step
          const contactsLink = `/${this.baseLink}/${WORKFLOW_STEPS.CONTACTS}`;
          const previewLink = `/${this.baseLink}/${WORKFLOW_STEPS.PREVIEW}`;
          if (isCertified && ![contactsLink, previewLink].includes(this.router.url)) {
            // re-route to preview page.
            const previewLink = `/${this.baseLink}/${WORKFLOW_STEPS.PREVIEW}`;
            this.router.navigate([previewLink], { replaceUrl: true });
            return;
          }

          const canShowWorkflow = isDraft || isCertified;
          if (this.visibleWorkflow !== canShowWorkflow) {
            this.visibleWorkflow = canShowWorkflow;
            // show/hide the workflow.
            this.store.dispatch(Actions.Workflow.showSteps(false, [WORKFLOW_STEPS.CONTACTS, WORKFLOW_STEPS.PREVIEW]));
            this.store.dispatch(Actions.Workflow.show(canShowWorkflow));
          } else if (!canShowWorkflow) {
            // user cannot edit this workflow - re-route user to preview page.
            const previewLink = `/${this.baseLink}/${WORKFLOW_STEPS.PREVIEW}`;
            if (previewLink !== this.router.url) {
              this.router.navigate([previewLink], { replaceUrl: true });
            }
          }
        }
      }

      // Then validate root workflow steps.
      this.store.select(Queries.Workflow.getCurrent).pipe(take(1)).subscribe(currentWorkflow => {
        // validate workflow steps
        const fundId = (proposal.funds && proposal.funds.length) ? proposal.funds[0].id : undefined;
        const grant = this.programService.getProgramById(fundId);
        const workflowStatuses = this.workflowValidatorService.validateSteps(currentWorkflow, {
          program: grant,
          proposal,
          proposal_contacts,
          files,
          metric_definitions
        });

        // update current workflow statuses.
        this.store.dispatch(Actions.Workflow.updateSteps(workflowStatuses));
      });
    });
  }
}