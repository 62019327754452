<div *ngIf="!borderless; else filterContent" class="list-filter__container" id="qa_project_filter">
  <div class="columns columns--2">
    <div>
      <h4 class="list-filter__heading">Filter {{ projectLongName }}</h4>
    </div>
    <div *ngIf="enableSaveFilters" class="flex flex--justify-content-end flex--align-items-center">
      <form *ngIf="saveFilterForm" class="inline-block margin-right-sm" [formGroup]="saveFilterForm">
        <radio-check class="checkbox" labelText="Save Filter Settings">
          <input inputRef type="checkbox" id="optIn_{{filterKey}}" formControlName="optedIn" (change)="toggleOptIn()" [value]="true">
        </radio-check>
      </form>

      <button (click)="clearFilters()" [disabled]="!showClearFilters" type="button" class="action-button action-button--small action-button--secondary">
        Clear Filters
      </button>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="filterContent"></ng-container>

</div>

<ng-template #filterContent>
  <form *ngIf="filterForm" [formGroup]="filterForm">
    <div class="list-filter__fields-container list-filter__fields-container--compact margin-bottom">
        <div *ngFor="let filter of filters">
          <ng-container [ngSwitch]="getTemplateForFilter(filter)">
            <input-field *ngSwitchCase = "'text'" [labelText]="filter.name">
              <input inputRef id="qa_filter_{{filter.field}}_text"
                type="text"
                placeholder="Start typing {{filter.placeholder ? filter.placeholder : filter.name}}"
                [formControlName]="filter.field"
                (change)="logFilter(filter.field)">
            </input-field>

            <po-select *ngSwitchCase = "'select'" id="qa_filter_{{filter.field}}_select"
              [labelText]="filter.name"
              [options]="filterOptions[filter.field]"
              placeholderText="Select {{filter.placeholder ? filter.placeholder : filter.name}}"
              [selectMultiple]="isMultiSelect(filter.type)"
              [selectOnce]="filter.selectOnce ? true : false"
              (optionRemoved)="logFilter(filter.field)"
              (optionSelected)="logFilter(filter.field)">
              <select inputRef class="po-select__select" [formControlName]="filter.field"></select>
            </po-select>

            <po-select *ngSwitchCase="'search'" id="qa_filter_{{filter.field}}_search"
              [labelText]="filter.name"
              placeholderText="Start typing {{filter.placeholder ? filter.placeholder : filter.name}}"
              [options]="filterOptions[filter.field]"
              [selectMultiple]="true"
              [isSearch]="true"
              (search)="updateResultsList(filter, $event)">
              <select inputRef class="po-select__select" [formControlName]="filter.field"></select>
            </po-select>
          </ng-container>
        </div>
        <div *ngIf="!filterOnChange" class="list-filter__buttons">
          <!-- manual apply -->
          <button type="submit" class="action-button action-button--primary list-filter__apply-button" (click)="applyFilters()" >
            Apply Filter
          </button>
        </div>
    </div>
  </form>
</ng-template>
