import { Routes } from '@angular/router';
import * as ProjectComponents from '@app/shared.project/components';
import { AuthGuard, AdminLookupTablesResolver } from './core/guards';
import { FullProgramResolver, LookupTablesResolve, ProgramsResolver } from './shared.project/guards';
import * as CoreComponents from './core/components';
import { StyleGuideComponent } from './core/components/test';
import { environment } from '../environments/environment';
import { CORE_ROUTES, PROGRAM_KEYS } from './core/consts';

// Define routes that should only be available in non-Prod environments.
let devRoutes = [];
if (!environment.production) {
  devRoutes = [
    { path: CORE_ROUTES.STYLEGUIDE, component: StyleGuideComponent },
  ];
}

export const routes: Routes = [
  {
    path: CORE_ROUTES.MAINTENANCE,
    component: CoreComponents.MaintenanceComponent,
  },
  // *** MAIN ROUTES
  {
    path: '',
    resolve: {
      programsLoaded: ProgramsResolver,
    },
    children: [
      // *** LAZY LOADING FEATURE/FUND ROUTES
      {
        path: CORE_ROUTES.ADMIN,
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: CORE_ROUTES.INSTITUTIONS,
        loadChildren: () => import('./institution-settings/institution-settings.module').then(m => m.InstitutionSettingsModule),
      },
      {
        path: 'aebg/:restOfPath',
        redirectTo: 'caep/:restOfPath',
      },
      {
        path: 'aebg',
        redirectTo: PROGRAM_KEYS.CAEP
      },
      {
        path: PROGRAM_KEYS.CAEP,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./AEBG/AEBG.module').then(m => m.AEBGModule),
      },
      {
        path: PROGRAM_KEYS.GP,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./GP/GP.module').then(m => m.GPModule),
      },
      {
        path: PROGRAM_KEYS.IPLAN,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./iPlan/iPlan.module').then(m => m.IPlanModule),
      },
      {
        path: PROGRAM_KEYS.SWP_L,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./SWP/SWP.module').then(m => m.SWPModule),
      },
      {
        path: PROGRAM_KEYS.SWP_R,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./SWP/SWP.module').then(m => m.SWPModule),
      },
      {
        path: PROGRAM_KEYS.LVG,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./LVG/LVG.module').then(m => m.LVGModule),
      },
      {
        path: PROGRAM_KEYS.SWP_K12,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./SWPK12/SWPK12.module').then(m => m.SWPK12Module),
      },
      {
        path: PROGRAM_KEYS.SEP,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./SEP/SEP.module').then(m => m.SEPModule),
      },
      {
        path: PROGRAM_KEYS.CAI,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./CAI/CAI.module').then(m => m.CAIModule),
      },
      {
        path: PROGRAM_KEYS.EWD,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./EWD/EWD.module').then(m => m.EWDModule),
      },
      {
        path: PROGRAM_KEYS.NEP,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./NEP/NEP.module').then(m => m.NEPModule),
      },
      {
        path: PROGRAM_KEYS.FISCAL_AGENTS,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./FA/FA.module').then(m => m.FAModule),
      },
      {
        path: PROGRAM_KEYS.PERKINS,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./Perkins/Perkins.module').then(m => m.PerkinsModule),
      },
      {
        path: PROGRAM_KEYS.RCM,
        resolve: { programLoaded: FullProgramResolver },
        loadChildren: () => import('./RCM/RCM.module').then(m => m.RCMModule),
      },
      {
        path: PROGRAM_KEYS.SMALL_PROGRAMS,
        loadChildren: () => import('./small-programs/small-programs-routes.module').then(m => m.SmallProgramsRoutesModule),
      },

      // *** MAIN ROUTES
      ...devRoutes,
      {
        path: '',
        component: CoreComponents.DashboardComponent,
        canActivate: [AuthGuard],
        resolve: {
          tablesLoaded: LookupTablesResolve,
        },
      },
      {
        path: CORE_ROUTES.REDIRECT,
        component: CoreComponents.RedirectComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'sign-up/:contextPath',
        redirectTo: CORE_ROUTES.SIGN_UP + '/:contextPath'
      },
      {
        path: CORE_ROUTES.SIGN_UP + '/:contextPath', // gmail doesn't like this format
        component: CoreComponents.SignupComponent
      },
      {
        path: CORE_ROUTES.CONTACT_SENT,
        component: CoreComponents.HelpComponent,
        canActivate: [AuthGuard]
      },
      {
        path: CORE_ROUTES.FUNDS,
        component: CoreComponents.FundsPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: CORE_ROUTES.HELP,
        component: CoreComponents.HelpComponent,
      },
      {
        path: CORE_ROUTES.PRIVACY_POLICY,
        component: CoreComponents.PrivacyPolicyComponent
      },
      {
        path: CORE_ROUTES.REQUEST_ACCESS,
        component: CoreComponents.RequestAccessComponent,
      },
      {
        path: CORE_ROUTES.LOGIN,
        component: CoreComponents.LoginComponent
      },
      {
        path: CORE_ROUTES.NOTIFICATIONS,
        component: CoreComponents.NotificationsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: CORE_ROUTES.PAGE_ACCESS_DENIED,
        component: CoreComponents.PageAccessDeniedComponent
      },
      {
        path: CORE_ROUTES.PAGE_NOT_FOUND,
        component: CoreComponents.PageNotFoundComponent
      },
      {
        path: CORE_ROUTES.UNSUPPORTED_BROWSER,
        component: CoreComponents.UnsupportedBrowserComponent
      },
      {
        path: CORE_ROUTES.FORGOT_PASSWORD,
        component: CoreComponents.ForgotPasswordComponent,
      },
      {
        path: CORE_ROUTES.PASSWORD_RESET,
        component: CoreComponents.PasswordResetComponent
      },
      {
        path: CORE_ROUTES.PROFILE,
        component: CoreComponents.ProfileComponent,
        canActivate: [AuthGuard],
        resolve: {
          tablesLoaded: AdminLookupTablesResolver,
        },
      },
      {
        path: CORE_ROUTES.WELCOME,
        component: CoreComponents.RegisterProfileComponent,
        canActivate: [AuthGuard],
        resolve: {
          tablesLoaded: AdminLookupTablesResolver
        }
      },
      {
        path: CORE_ROUTES.RELEASE_NOTES,
        component: CoreComponents.ReleaseNotesComponent
      },
      {
        path: `${PROGRAM_KEYS.ALLIED_HEALTH}/lookup`,
        canActivate: [AuthGuard],
        component: ProjectComponents.AnalyticsComponent,
        data: {
          lookerObject: '/embed/sso/dashboards/212',
        }
      },
      {
        path: '**',
        component: CoreComponents.PageNotFoundComponent
      },
    ]
  }
];
