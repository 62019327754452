import { AREAS } from './permissions.const';

export const PROPOSAL_TYPES = {
  APPLICATION: AREAS.APPLICATION,
  PLAN: AREAS.PLAN,
  PROJECT: AREAS.PROJECT,
  FISCAL_AGENT: 'FA',
  DISTRICT_REPORTING: 'district-reporting'
}

export const PROPOSAL_TYPE_NAMES = {
  [PROPOSAL_TYPES.APPLICATION]: 'Application',
  [PROPOSAL_TYPES.PLAN]: 'Plan',
  [PROPOSAL_TYPES.PROJECT]: 'Project',
  [PROPOSAL_TYPES.FISCAL_AGENT]: 'Fiscal Agent',
  [PROPOSAL_TYPES.DISTRICT_REPORTING]: 'District Reporting'
}
