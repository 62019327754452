import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State, Queries, Actions as DomainActions } from '@app-ngrx-domains'
import { NewTempId, toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { Metric } from '@app-models';
import { METRICSEX_ACTION_TYPES } from './metrics-ex.action';

/**
 * Injectable effects class.
 *
 */
@Injectable()
export class MetricsExEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {  }

  /**
   * Upserts proposal metric for LVG.
   */
   upsertMetric$ = createEffect(() => this.actions$.pipe(
    ofType(METRICSEX_ACTION_TYPES.UPSERT_METRIC),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.getId)),
    mergeMap(([item, proposalId]) => this.apiService.upsertMetric({ ...item, proposal_id: proposalId }).pipe(
      map((response) => DomainActions.MetricsEx.upsertMetricSuccess(response)),
      catchError((error) => of(DomainActions.MetricsEx.serviceFail(error)))
    ))
  ));

  /**
   * Deletes proposal metric.
   */
   deleteMetric$ = createEffect(() => this.actions$.pipe(
    ofType(METRICSEX_ACTION_TYPES.DELETE_METRIC),
    map(toPayload),
    mergeMap(item => {
      // persist changes.
      const metric = new Metric(item).serverObject();
      return this.apiService.deleteMetric(metric).pipe(
        map((response) => DomainActions.MetricsEx.deleteMetricSuccess(item)),
        catchError((error) => of(DomainActions.MetricsEx.serviceFail(error)))
      );
    })
  ));


  /**
   * Deletes metrics associated with one or more lmi programs.
   */
   deleteLmiMetrics$ = createEffect(() => this.actions$.pipe(
    ofType(METRICSEX_ACTION_TYPES.DELETE_LMI_PROGRAM_METRICS),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.MetricsEx.get)),
    mergeMap(([lmiProgramIds, metrics]) => {
      // get metrics to remove
      const lmiProgramMetrics = metrics.filter(m => lmiProgramIds.includes(m.lmi_program_id));
      const observables = lmiProgramMetrics.filter(item => !NewTempId.isTemp(item.id))
        .map(item => new Metric(item).serverObject())
        .map(obj => this.apiService.deleteMetric(obj));

      const obsRemoveMetrics = (observables.length === 0) ? of([]) : forkJoin(observables);
      return obsRemoveMetrics.pipe(
        map(() => DomainActions.MetricsEx.deleteLmiProgramMetricsSuccess(lmiProgramIds)),
        catchError((error) => of(DomainActions.MetricsEx.serviceFail(error))));
    })
  ));



}
