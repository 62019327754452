import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions } from '@app-ngrx-domains';
import { ROUTER_LINKS, PROGRAM_KEYS } from '@app-consts';
import { LOOKUP_TABLES } from '@app/core/state-management/lookup-tables.action';
import { Utilities } from '../models';

/** Resolver for pre-loading Lookup tables before progressing to views that need them.
 */
@Injectable()
export class AdminLookupTablesResolver implements Resolve<boolean> {

  private isAllLoaded = false;
  private isAnyError = false;

  constructor(
    private store: Store<State>,
    private router: Router,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {
      if (this.isAllLoaded) {
        // all the lookups have been loaded already.
        subscriber.next(true);
        subscriber.complete();
        return;
      }

      // wait for lookups to be all loaded.
      const waitFor = [
        LOOKUP_TABLES.DURATIONS,
        LOOKUP_TABLES.JOB_CATEGORIES,
        LOOKUP_TABLES.ROLES,
        LOOKUP_TABLES.SECTORS,
        LOOKUP_TABLES.DOCUMENT_TYPES,
        LOOKUP_TABLES.SETTINGS_INSTITUTIONS,
        LOOKUP_TABLES.PROGRAM_DIVISIONS,
        LOOKUP_TABLES.CATEGORIES
      ];

      waitFor.forEach(table => {
        if (table === LOOKUP_TABLES.SETTINGS_INSTITUTIONS) {
          this.store.dispatch(Actions.LookupTables.lookupInstitutions({ ccc: true, include_settings: true }));
        } else {
          this.store.dispatch(Actions.LookupTables.doLookup(table));
        }
      });

      this.store.select(Queries.LookupTables.getLoadStatus).subscribe((status: any) => {
        this.isAllLoaded = true; // Assume all tables are loaded
        // Iterate through the table list and set allLoaded false if any isn't loaded.
        for (const table of waitFor) {
          const tableIsLoaded = !!status[table + 'IsLoaded'];
          this.isAllLoaded = this.isAllLoaded && tableIsLoaded;
        }

        if (this.isAllLoaded) { // If all tables are loaded, sent TRUE and terminate the stream.
          subscriber.next(true);
          subscriber.complete();
        } else { // isLoaded is still false, but we might have an error.
          subscriber.next(false);
          // Iterate through all the lookup results. If any lookup resulted in Error, stop waiting and route to Home.
          for (const table of waitFor) {
            if (!!status[table + 'Error']) {
              subscriber.error(false);
              subscriber.complete();
              this.router.navigate([ROUTER_LINKS.HOME]);
              break;
            }
          }
        }
      }, (error) => {
        this.router.navigate([ROUTER_LINKS.HOME]);
        subscriber.error(false);
        subscriber.complete();
        this.router.navigate([ROUTER_LINKS.HOME]);
      });
    });
  }
}
