import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Actions, Model, Queries, State } from '@app-ngrx-domains';
import { Comment } from '@app/core/models';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarDateFormat } from '@app/shared.generic/pipes';
import { NewTempId } from '@app/libs';
import { OptionsMenuComponent } from '@app/shared.generic/components';
import { COMMENT_ID_CLASSES } from '@app/core/consts';

@Component({
  selector: 'comment-card',
  templateUrl: './comment-card.component.html'
})
export class CommentCardComponent implements OnInit, OnDestroy {
  @Input() threadId: number;
  @Input() canEdit: boolean;
  @Input() currentUser: Model.User;

  @ViewChild('optionsMenu') optionsMenuEl: OptionsMenuComponent;

  resource: Model.Resource;
  parentComment: Model.Comment;
  isSelected: boolean;
  comments: Array<Model.Comment> = [];
  mouseover: boolean;
  showResolveAlert: boolean;

  elementHighlightedClass = 'comment-element-highlight';

  calendarFormat: CalendarDateFormat = {
    lastDay : 'LT [Yesterday]',
    sameDay : 'LT [Today]',
    nextDay : '[Tomorrow]',
    lastWeek : 'l LT',
    nextWeek : 'l LT',
    sameElse : 'l LT'
  };

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private el: ElementRef,
    private store: Store<State>,
  ) { }

  @HostListener('document:mouseup', ['$event'])
  onDocumentClick(event) {
    const target = event.target;
    const path = event.composedPath ? event.composedPath() : event.path;
    const cardInPath = path.find(e => e === this.el.nativeElement);
    const inputInPath = target.id === this.resource.element_id;

    // If the card or associated input is selected, scroll to its counterpart
    if (cardInPath || inputInPath) {
      let scrollTo;
      if (cardInPath) {
        scrollTo = 'input'
      } else if (inputInPath && (this.resource.threads?.length && this.resource.threads[0]?.id === this.parentComment.id)) {
        // TODO: Scrolling to card will not work on cards created after init. Need to figure out a cleaner solution for this.
        // Temp threads make this extra tricky because there's no resource ID to determine if other temp threads have been created for this resource

        // Only scroll to the first card for this input
        scrollTo = 'card';
      }
      this.setSelected(true, scrollTo);
    } else {
      let select = false;
      if (target.tagName === 'SPAN') {
        const classList = Array.from(target.classList);
        // Check if text area highlight has class for this comment
        if (classList && classList.includes(`${COMMENT_ID_CLASSES.COMMENT_ID}--${this.parentComment.id}`)) {
          // If it's a draft, check if it's for the current user
          if (classList.includes(COMMENT_ID_CLASSES.DRAFT)) {
            if (classList.includes(`${COMMENT_ID_CLASSES.CREATOR_ID}--${this.currentUser.id}`)) {
              select = true;
            }
          } else {
            select = true;
          }
        }
      }

      if (select) {
        this.setSelected(true, 'card');
      } else {
        this.setSelected(false);
      }
    }
  }

  @HostListener('mouseenter')
  onMouseOver() {
    this.mouseover = true;
  }

  @HostListener('mouseleave')
  onMouseOut() {
    this.mouseover = false;
  }

  ngOnInit() {
    if (NewTempId.isTemp(this.threadId)) {
      this.isSelected = true;
    }

    let initialized = false;
    this.store.select(Queries.Comments.getThread(this.threadId)).pipe(
      takeUntil(this.destroy$)
    ).subscribe(thread => {
      console.log(' got thread for ID: ',this.threadId, thread)

      if (!thread) {
        console.log('no thread for ', this.threadId);
        return;
      }

      // Text areas don't use temp IDs, so check if this is a new thread a different way
      if (!initialized && !this.isSelected && thread.comment.draft && !thread.comment.comment) {
        this.isSelected = true;
      }
      initialized = true;

      this.parentComment = thread.comment;
      this.resource = thread.resource;

      const comments = [];
      comments.push(thread.comment);

      if (thread.replies && thread.replies.length) {
        comments.push(...thread.replies);
      } else {

      }

      this.comments = comments;

      if (this.isSelected) {
        this.showReplyInput();
      }
    });
  }

  setSelected(isSelected: boolean, scrollTo?: string) {
    if (!this.resource) {
      console.log('ERROR: Selecting missing resource: ', isSelected);
      return;
    }

    if (this.isSelected === isSelected) {
      return;
    }

    this.isSelected = isSelected;
    if (isSelected) {
      if (scrollTo) {
        const elementId = this.resource.element_id;

        const inputElement = document.getElementById(elementId);
        if (!inputElement) {
          console.warn('document: click - trying to scroll to a nonexistent inputElement.', elementId);
          return;
        }
        inputElement.classList.add(this.elementHighlightedClass);

        if (scrollTo === 'input') {
          this.scrollToElement(inputElement);
        } else if (scrollTo === 'card') {
          this.scrollToElement(this.el.nativeElement);
        }
      }

      this.showReplyInput();
    } else {
      this.clearElementHighlighting();
    }
  }

  scrollToElement(element) {
    element.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
  }

  resolve() {
    this.store.dispatch(Actions.Comments.resolveComment(this.threadId, !this.parentComment.resolved, this.resource, this.parentComment));
    this.showResolveAlert = false;
  }

  showComment(comment: Comment) {
    return !NewTempId.isTemp(comment.id) || this.isSelected;
  }

  showReplyInput() {
    if (this.comments.length && !this.hasDrafts) {
      this.store.dispatch(Actions.Comments.createTempReply(this.parentComment));
    }
  }

  clearElementHighlighting() {
    const elementId = this.resource.element_id;
    const inputElement = document.getElementById(elementId);
    if (inputElement) {
      inputElement.classList.remove(this.elementHighlightedClass);
    }
  }

  hasDraftComment(comments) {
    return comments.some(c => c.draft);
  }

  get hasDrafts() {
    return this.comments && this.comments.some(c => c.draft);
  }

  trackById(index, item) {
    return item.temp_id || item.id;
  }

  ngOnDestroy() {
    this.setSelected(false);
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
