<full-page-modal *ngIf="showModal"
  small="true"
  heading="Share"
  button-action="Ok"
  (action)="submitForm()"
  [button-action-disabled]="!formEmail.valid"
  (dismiss)="closeModal()"
  button-cancel="Cancel"
  (cancel)="closeModal()">
  <div *ngIf="showSendToSelfAlert" class="help-text">
    <p>
      <strong>DID YOU KNOW?</strong> The <strong>{{pageName}}</strong> page contains the same information,
      in the same order, as the preview file sent via email. To see exactly how the PDF file will look, you can click
      your browser's <strong>Print</strong> button from the <strong>{{pageName}}</strong> page and choose the
      "Print to PDF" option, or click the <strong>Print Preview</strong> button below.
    </p>
    <button type="button" (click)="triggerPrint()" class="action-button action-button--primary action-button--small">
      Print Preview
    </button>
    <p></p>
  </div>

  <form [formGroup]="formEmail" autocomplete="off">
    <po-select id="qa_email_pdf_user_select"
               labelText="Recipient(s)"
               placeholderText="Select recipients for this document"
               [options]="userList"
               [selectMultiple]="true"
               [isSearch]="true"
               (search)="getUserList($event, 50)"
               (optionSelected)="checkSendToSelf($event)">
      <div class="help-text">
        <p>
          Recipients will receive this document in an email with attachments in the following formats:
          PDF and HTML for screen readers.
        </p>
      </div>
      <select inputRef class="po-select__select" formControlName="users" aria-required="true"></select>
    </po-select>

    <input-field labelText="Comments">
      <div class="help-text">
        <p>You can add comments about this document that will be included in the email to recipients.</p>
      </div>
      <po-html-textarea inputRef id="submissionComments"
        formControlName="comments"
        [htmlCharLimit]="commentsLength">
      </po-html-textarea>
    </input-field>
  </form>
</full-page-modal>
