<ng-container *ngIf="(canEdit || isTemplate) else readOnlyTemplate">
  <ng-container [ngSwitch]="canEdit">
    <!-- Editable Score Card -->
    <div class="score-card" *ngSwitchCase="true">
      <form [formGroup]="form" autocomplete="off">
        <header class="score-card-header">
          <div class="score-card__title">
            <h4>Review:  {{config.title}}</h4>
          </div>
          <ng-container *ngIf="config.collectScore" >
              <div [ngClass]="{
                'score-card__error-text-hidden': isNil(errorText),
                'score-card__error-text-visible': !isNil(errorText)}">
                {{errorText}}
              </div>
            <div class="score-card__score">
              Score ({{config.minScore + '-' + config.maxScore}})
            </div>

            <div class="score-card__score-field">
              <input-field [labelHidden]="true" labelText="Scoring" [canEdit]="true" [hideError]="true">
                <input inputRef type="text"
                  id="score_card_score_input"
                  formControlName="score"
                  [transform]="'number'"
                  [pipeArgs]="[]"
                  (change)="persistValue('score')"
                  required/>
              </input-field>
            </div>
          </ng-container>
        </header>

        <section [ngClass]="{
          'score-card__nopadding': hide,
          '': !hide}">
          <div class="columns columns--3 score-card__small">
            <div class="column-start-1 column-end-2">
              <label>Scoring Instructions</label>
              <p>{{config.scoringCriteria}}</p>
            </div>

            <div class="column-start-2 column-end-4">
              <input-field labelText="Comments">
                <po-html-textarea inputRef id="score_card_comments_text"
                  formControlName="comment"
                  [htmlCharLimit]="narrativeLength"
                  (change)="persistValue('comment')"
                  [attr.aria-required]="config.collectScore && !config.scoreCommentsOptional">
                </po-html-textarea>
              </input-field>
            </div>
          </div>
        </section>

        <footer class="score-card__footer">
          <div class="score-card__footer-title">
            <a (click)="toggleHideCluster()" class="text-link-white">Comments/Scoring Instructions
                <icon [iconId]="hide ? 'disclosure' : 'disclosure-up'" class="icon--white icon--vertical-align icon--disclosure" data-qa="open-close-chevron" role="button" [ngClass]="{ 'is-hidden':hide }"></icon>
            </a>
          </div>

          <div class="score-card__footer_score">
            Comprehensive Score &nbsp; {{scores[0].total}} / {{config.totalScore}}
          </div>
        </footer>
      </form>
    </div>

    <!-- Template Score Card -->
    <div class="score-card__read-only" *ngSwitchDefault>
      <form [formGroup]="form">
        <header class="score-card__read-only-header">
          <div class="score-card__read-only-title">
            <h4>{{config.title}}</h4>
          </div>
          <div class="score-card__read-only-score" *ngIf="config.collectScore">
            <strong>Score ({{config.minScore + '-' + config.maxScore}})  _______</strong>
          </div>
        </header>

        <section class="score-card__read-only-content">
          <div class="columns columns--3">
            <div class="column-start-1 column-end-2">
              <label>Scoring Criteria</label>
              <p>{{config.scoringCriteria}}</p>
            </div>

            <div class="column-start-2 column-end-4">
              <input-field labelText="Comments">
                <po-html-textarea inputRef id="score_card_comments_text"
                  formControlName="comment"
                  [htmlCharLimit]="narrativeLength">
                </po-html-textarea>
              </input-field>
            </div>
          </div>
        </section>
      </form>
    </div>
  </ng-container>
</ng-container>

<!-- Read-Only Template -->
<ng-template #readOnlyTemplate>

    <ng-container [ngSwitch]="isCumulative">
      <!-- Cumulative score card summary -->
      <div class="score-card__read-only" *ngSwitchCase="true">
        <header class="score-card__read-only-header">
          <div class="score-card__read-only-title">
            <h4>{{config.title}}</h4>
          </div>
          <div class="score-card__read-only-score" *ngIf="config.collectScore">
            Average score: {{+notesAvgScore | number:'1.1-2'}} /  {{config.maxScore}}
          </div>
        </header>

        <section class="score-card__read-only-content">
          <div *ngFor="let score of scores" class="columns columns--3" [class.po-table__table-alert]="score.excluded" [class.text-removed]="score.excluded">
            <div class="column-start-1 column-end-2">
              <label>Reviewer</label>
              <p>{{scorerFullName(score)}}</p>
            </div>

            <div class="column-start-2 column-end-3">
              <label>Score</label>
              <p>{{scoreString(score.resource)}}</p>
            </div>

            <div class="column-start-3 column-end-5">
              <label>Comments</label>
              <p [innerHTML]="commentsString(score.resource)"></p>
            </div>
          </div>
        </section>
      </div>

      <!-- Single score card summary -->
      <div class="score-card__read-only" *ngSwitchDefault>
        <header class="score-card__read-only-header">
          <div class="score-card__read-only-title">
            <h4>{{config.title}}</h4>
          </div>
          <div class="score-card__read-only-score" *ngIf="config.collectScore">
            {{scoreString(scores[0].resource)}} points
          </div>
        </header>

        <section class="score-card__read-only-content">
          <label>Comments</label>
          <p [innerHTML]="commentsString(scores[0].resource)"></p>
        </section>
      </div>
    </ng-container>
  </ng-template>
