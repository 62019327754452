<full-page-modal
  small="true"
  heading="Invite User"
  (action)="inviteUser()"
  button-action="Invite User"
  [button-action-disabled]="!formsValid()"
  (dismiss)="closeAction.emit()"
  [button-cancel]="cancelButton"
  (cancel)="cancelAction.emit()">

  <p class="h6 mb-l">{{description}}</p>

  <div class="error-text" *ngIf="errorMessage" level="error">
    <p>{{errorMessage}}</p>
  </div>

  <form [formGroup]="formInviteUser" autocomplete="off">
    <input-field labelText="First Name">
      <input inputRef id="firstInput" type="text" maxlength="20" formControlName="first_name" aria-required="true" />
    </input-field>
    <input-field labelText="Last Name">
      <input inputRef id="lastInput" type="text" maxlength="20" formControlName="last_name" aria-required="true" />
    </input-field>
    <input-field labelText="Work Email">
      <input inputRef id="emailInput" type="text" maxlength="254" formControlName="email" aria-required="true" />
    </input-field>
  </form>

  <form *ngIf="additionalForm" [formGroup]="additionalForm">
    <ng-container *ngFor="let control of getKeys(additionalForm.controls)">
      <ng-container *ngIf="!(formConfig[control].hidden && formConfig[control].hidden())" [ngSwitch]="formConfig[control].type !== 'select'">
        <input-field *ngSwitchCase="true"
          labelText="{{formConfig[control].label}}">
          <input inputRef type="{{formConfig[control].type}}" formControlName="{{control}}" id="{{control}}_Input" [attr.aria-required]="formConfig[control].required">
        </input-field>
        <po-select *ngSwitchDefault
          id="{{control}}_Input"
          labelText="{{formConfig[control].label}}"
          [options]="formConfig[control].select_options || []"
          [selectMultiple]="false" [selectOnce]="true">
          <select inputRef class="po-select__select" formControlName="{{control}}" [attr.aria-required]="formConfig[control].required"></select>
        </po-select>
      </ng-container>
    </ng-container>
  </form>

</full-page-modal>
