import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'a[href]'
})
export class ExternalUrlsDirective {
  @HostBinding('attr.rel') relAttr = null;
  @HostBinding('attr.target') targetAttr = null;
  @Input() href: string;
  
  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnChanges() {
    const isLinkExternal = !this.elementRef.nativeElement.hostname.includes(location.hostname);
    if (isLinkExternal) {
      this.setUrl();
    }
    this.setAttributes(isLinkExternal);
  }

  setUrl() {
    if (!(this.href.startsWith('mailto') || this.href.startsWith('http'))) {
      this.href = `http://${this.href}`;
    }
    this.elementRef.nativeElement.href = this.href;
  }

  setAttributes(isLinkExternal) {
    if (isLinkExternal) {
      this.relAttr = 'noopener';
      this.targetAttr = '_blank';
    } else {
      this.relAttr = null;
      this.targetAttr = null;
    }
  }
}
