import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges } from '@angular/core';
import { REPORTING_CADENCES } from '@app-consts';
import { Model } from '@app-ngrx-domains';
import { ProposalBase } from '@app/core/models';
import { LookupService } from '@app/core/services';
import { maxBy } from 'lodash';

@Component({
  selector: 'plan-timeline',
  templateUrl: './plan-timeline.component.html',
  animations: [
    trigger('yearAddedRemoved', [
      transition(':enter', [
        style({ flex: 0.00001 }),
        animate('200ms linear', style({ flex: 1 })),
      ]),
      // TODO: Removing leaving animations for now because they break the timeline when expanding/collapsing preview card
      // transition(':leave', [
      //   animate('200ms linear', style({ flex: 0.00001 })),
      // ])
    ]),
    trigger('reportAddedRemoved', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms', style({ opacity: 1 })),
      ]),
      // transition(':leave', [
      //   animate('150ms', style({ opacity: 0 }))
      // ])
    ]),
  ],
})

export class PlanTimelineComponent implements OnChanges {
  @Input() programSettings: Model.EAProgramSettings;
  @Input() planLength: number;
  @Input() fundingSources: Array<Model.FundingSourceSettings> = [];
  @Input() inDetailsTab: boolean;
  @Input() useYearlyPlanLength: boolean;

  startYear: number;
  startQuarter: number;
  spendingDuration: number;
  reportingCadence: string;
  showFinalReport: boolean;
  showPlanReportingOnly: boolean;

  yearsToDisplay = [];

  constructor (
    private lookupService: LookupService
  ) {}

  ngOnChanges() {
    if (!this.programSettings) {
      return;
    }

    this.startYear = this.programSettings.base_duration_id;
    this.startQuarter = this.useYearlyPlanLength ? 1 : this.programSettings.start_quarter;
    const planLength = this.planLength || (this.useYearlyPlanLength ? this.programSettings.plan_length : this.programSettings.quarterly_plan_length);
    this.spendingDuration = this.useYearlyPlanLength ? planLength * 4 : planLength;
    this.reportingCadence = this.programSettings.fiscal_reporting_cadence;
    this.showFinalReport = this.programSettings.include_final_report;
    this.showPlanReportingOnly = this.programSettings.aggregate_funding_sources;

    if (!this.showTimeline) {
      return;
    }

    let minYear = this.startYear;
    let maxYear = ProposalBase.getEndYear({ duration_id: this.startYear, quarterly_plan_length: this.spendingDuration, start_quarter: this.startQuarter } as any);
    const endYear = maxYear;

    if (this.fundingSources) {
      // Find the earliest start year and latest end year across all funding sources
      for (const { start_year, start_quarter, quarterly_plan_length } of this.fundingSources) {
        if (start_year) {
          minYear = Math.min(minYear, start_year);
          if (quarterly_plan_length && start_quarter) {
            maxYear = Math.max(maxYear, ProposalBase.getEndYear({ duration_id: start_year, quarterly_plan_length, start_quarter } as any));
          }
        }
      }
    }

    // If the plan ends on the 4th quarter, add an additional year to display to leave room for Final Report icon
    const range = this.startQuarter - 1 + this.spendingDuration;
    if (maxYear === endYear && this.showReporting && range % 4 === 0) {
      ++maxYear;
    }

    const years = [];
    let planStart, planActive, planEnd, showFinalReport = false;
    let planDuration = 1;
    const fundDurations = {};
    for (let year = minYear; year <= maxYear; year++) {
      const quarters = [];

      for (let quarter = 1; quarter <= 4; quarter++) {
        const fundBars = [];
        planStart = !planActive && year === this.startYear && quarter === this.startQuarter;
        if (planStart) {
          planActive = true;
        }
        planEnd = planActive && planDuration === this.spendingDuration;

        if (this.fundingSources?.length) {
          for (const source of this.fundingSources) {
            if ([source.start_year, source.start_quarter, source.quarterly_plan_length].some(s => s == null)) {
              continue;
            }
            const fundId = source.id;
            const start = !fundDurations[fundId] && source.start_year === year && source.start_quarter === quarter;

            if (start) {
              fundDurations[fundId] = 1;
            }
            const end = fundDurations[fundId] && source.quarterly_plan_length === fundDurations[fundId];
            const active = !!fundDurations[fundId];

            const showFundingFinalReport = source.include_final_report && showFinalReport;
            const reportingCadence = this.programSettings.ignore_contributor_settings ? this.reportingCadence : source.fiscal_reporting_cadence;
            fundBars.push({
              id: fundId,
              name: source.fund_name,
              start,
              active,
              end,
              showFinalReport: showFundingFinalReport,
              title: this.getQuarterTitle(active, fundDurations[fundId], reportingCadence, source.quarterly_plan_length, showFundingFinalReport)
            });
          }
        }

        const timelineQuarter = {
          year,
          name: `Q${quarter}`,
          planStart,
          planActive,
          planEnd,
          showFinalReport,
          duration: planDuration,
          title: this.getQuarterTitle(planActive, planDuration, this.reportingCadence, this.spendingDuration, showFinalReport),
          fundBars
        };

        if (planEnd) {
          // If plan ended on this quarter, show final report icon on next quarter
          showFinalReport = this.showFinalReport;
          planActive = false;
        } else if (planActive) {
          // If plan started, keep increasing duration
          planDuration++;
        } else if (showFinalReport) {
          // Turn off final report flag
          showFinalReport = false;
        }

        for (const fund of fundBars) {
          const fundId = fund.id;
          if (fund.end) {
            delete fundDurations[fundId];
          } else {
            fundDurations[fundId]++;
          }
        }

        quarters.push(timelineQuarter);
      }

      years.push({
        year,
        name: this.lookupService.getYearNameByDuration(year),
        quarters
      });
    }
    this.yearsToDisplay = years;
  }

  getQuarterTitle(isActive, currentDuration, reportingCadence, spendingDuration?, showFinalReport?) {
    if (reportingCadence) {
      const cadence = REPORTING_CADENCES.find(rc => reportingCadence === rc.value).cadence;

      // Show the report if this quarter matches cadence or if it's the last quarter of the duration or the final report
      if (showFinalReport) {
        return 'Final Report';
      } else if (isActive && cadence) {
        if (currentDuration % cadence === 0) {
          return 'Fiscal Report';
        } else if (currentDuration === spendingDuration) {
          return 'End of Term Report';
        }
      }
    }
    return;
  }


  get showTimeline() {
    return [this.startYear, this.startQuarter, this.spendingDuration].every(value => value != null);
  }

  get showReporting() {
    return this.showTimeline && this.reportingCadence != null;
  }

  trackByName(index: number, item: any) {
    return item.name;
  }

  trackById(index: number, item: any) {
    return item.id;
  }
}