import { IAuthState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { AUTH_ACTION_TYPES } from './auth.action';
import { orderBy, pullAllBy } from 'lodash';


export function AuthReducer(state: IAuthState = State.Auth, action: ActionWithPayload<any>): IAuthState {

  switch (action.type) {

    case AUTH_ACTION_TYPES.SET_ROUTE_WATCHER: {
      return {
        ...state,
        refreshRouteGuards: action.payload.watch
      };
    }


    case AUTH_ACTION_TYPES.LOGIN: {
      return {
        ...state,
        inProgress: true,
        isLoggedIn: false,
        error: null,
        user: null,
        userMessages: [],
        userError: null,
      };
    }

    case AUTH_ACTION_TYPES.LOGIN_SUCCESS: {
      return {
        ...state,
        inProgress: false,
        isLoggedIn: true,
        waitingOnUserToLogin: false,
        user: action.payload.user,
        visibleFunds: action.payload.funds
      };
    }

    case AUTH_ACTION_TYPES.LOGIN_FAILED: {
      return {
        ...state,
        inProgress: false,
        error: action.payload,
      };
    }

    case AUTH_ACTION_TYPES.REFRESH_SUCCESS: {
      return {
        ...state,
        visibleFunds: action.payload
      }
    }

    case AUTH_ACTION_TYPES.REFRESH_FAILED: {
      return {
        ...state,
        error: action.payload,
      };
    }


    case AUTH_ACTION_TYPES.LOGIN_WAITING_ON_USER: {
      return {
        ...state,
        waitingOnUserToLogin: action.payload,
      };
    }

    case AUTH_ACTION_TYPES.LOGOUT_SUCCESS:
    case AUTH_ACTION_TYPES.LOGIN_CHECK_FAILED:  {
      return {
        ...State.Auth
      };
    }

    case AUTH_ACTION_TYPES.UPDATE_USER: {
      return {
        ...state,
        inProgress: true,
        userError: null
      };
    }

    case AUTH_ACTION_TYPES.CRUD_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        inProgress: false,
        userError: null
      };
    }

    case AUTH_ACTION_TYPES.SET_SUDO_PERMISSION: {
      return {
        ...state,
        canSudo: action.payload
      };
    }

    case AUTH_ACTION_TYPES.SET_SUDO_LOGIN: {
      return {
        ...state,
        isSudoLoggedIn: action.payload
      };
    }

    case AUTH_ACTION_TYPES.REFRESH_USER_MESSAGES_SUCCESS: {
      const { messageType, messages } = action.payload;
      return {
        ...state,
        userMessages: messages,
        unreadMessages: [...(messageType === 'unread' ? messages : state.unreadMessages || [])]
      };
    }

    case AUTH_ACTION_TYPES.MARK_USER_MESSAGES_SUCCESS: {
      const updatedMessages = [];

      const { markType, value, messages } = action.payload;

      // Update messages
      const existingMessages = [];
      state.userMessages.forEach(message => {
        const updatedMessage = messages.find(m => m.id === message.id);
        if (updatedMessage) {
          existingMessages.push(updatedMessage.id);
          updatedMessages.push({
            ...message,
            [markType]: value
          });
        } else {
          updatedMessages.push({
            ...message
          });
        }
      });

      // Insert new messages
      const unreadMessages = [...state.unreadMessages];
      messages.forEach(message => {
        // If the payload message is not one of the ones that was updated above,
        // then that means it needs to be added to the list
        if (!existingMessages.includes(message.id)) {
          updatedMessages.push({
            ...message,
            [markType]: value
          });
        }

        // Update unread messages
        const unreadMessage = state.unreadMessages.find(m => m.id === message.id);
        if (!message.read && !unreadMessage) {
          // If marked as unread but doesn't exist in unread message list, add it
          unreadMessages.push(message);
        } else if (message.read && unreadMessage) {
          // If marked as read and exists in unread message list, remove it
          pullAllBy(unreadMessages, [message], 'id');
        }
      });

      return {
        ...state,
        userMessages: updatedMessages,
        unreadMessages: orderBy(unreadMessages, ['created_at', ['desc']])
      };
    }

    default:
      return state;
  }
}
