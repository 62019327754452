import { IWorkflowState, State, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { WORKFLOW_ACTION_TYPES } from './workflow.action';
import { WORKFLOW_TYPES } from '../consts';

export function WorkflowReducer(state: IWorkflowState = State.Workflow, action: ActionWithPayload<any>): IWorkflowState {
  switch (action.type) {
    case WORKFLOW_ACTION_TYPES.SET: {
      return {
        ...state,
        current: action.payload.isCurrent ? action.payload.kind : state.current,
        workflows: {
          ...state.workflows,
          [action.payload.kind]: {
            ...state.workflows[action.payload.kind],
            ...action.payload.workflow,
          }
        }
      }
    }

    case WORKFLOW_ACTION_TYPES.CLEAR: {
      const kind = (action.payload.kind === WORKFLOW_TYPES.CURRENT) ? state.current : action.payload.kind;
      if (kind === WORKFLOW_TYPES.CURRENT) {
        // current workflow has not been set.
        return state;
      }

      // Completely remove workflow
      if (action.payload.remove) {
        const workflows = { ...state.workflows };
        delete workflows[kind];
        return {
          ...state,
          workflows
        };
      }

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [kind]: {
            ...state.workflows[kind],
            show: false,
            currentStep: undefined,
          }
        }
      }
    }

    case WORKFLOW_ACTION_TYPES.UPDATE_STEP: {
      const kind = (action.payload.kind === WORKFLOW_TYPES.CURRENT) ? state.current : action.payload.kind;
      if (kind === WORKFLOW_TYPES.CURRENT) {
        // current workflow has not been set.
        return state;
      }

      // reduce steps
      const steps = state.workflows[kind].steps.map(step => {
        if (step.route === action.payload.step) {
          // update status
          return {
            ...step,
            ...action.payload.update,
          };
        } else {
          // not changed
          return step;
        }
      });

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [kind]: {
            ...state.workflows[kind],
            steps: steps
          }
        }
      }
    }

    case WORKFLOW_ACTION_TYPES.UPDATE_STEP_SCORE: {
      const kind = (action.payload.kind === WORKFLOW_TYPES.CURRENT) ? state.current : action.payload.kind;
      if (kind === WORKFLOW_TYPES.CURRENT) {
        // current workflow has not been set.
        return state;
      }

      // reduce steps
      const steps = state.workflows[kind].steps.map(step => {
        if (step.route === action.payload.step) {
          // update score within the score card
          return {
            ...step,
            scoreCard: {
              ...step.scoreCard,
              score: action.payload.score,
            }
          };
        } else {
          // not changed
          return step;
        }
      });

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [kind]: {
            ...state.workflows[kind],
            steps: steps
          }
        }
      }
    }

    case WORKFLOW_ACTION_TYPES.UPDATE_STEPS: {
      const kind = (action.payload.kind === WORKFLOW_TYPES.CURRENT) ? state.current : action.payload.kind;
      if (kind === WORKFLOW_TYPES.CURRENT) {
        // current workflow has not been set.
        return state;
      }

      // reduce steps
      const steps = state.workflows[kind].steps.map(step => {
        const update = action.payload.steps.find((u: Model.WorkflowStep) => u.route === step.route);
        if (update) {
          if (update.scoreCard) {
            // update status + score
            const reducedStep = { ...step };
            Object.keys(update).filter(key => key !== 'route').forEach(key => {
              if (key === 'scoreCard') {
                reducedStep[key] = {
                  ...step.scoreCard,
                  score: update.scoreCard.score,
                }
              } else {
                reducedStep[key] = update[key];
              }
            });
            return reducedStep;
          } else {
            // update status
            return {
              ...step,
              ...update,
            };
          }
        } else {
          // not changed
          return step;
        }
      });

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [kind]: {
            ...state.workflows[kind],
            steps: steps
          }
        }
      }
    }

    case WORKFLOW_ACTION_TYPES.SET_PROPS: {
      const kind = (action.payload.kind === WORKFLOW_TYPES.CURRENT) ? state.current : action.payload.kind;
      if (kind === WORKFLOW_TYPES.CURRENT) {
        // current workflow has not been set.
        return state;
      }

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [kind]: {
            ...state.workflows[kind],
            ...action.payload.props,
          }
        }
      }
    }

    case WORKFLOW_ACTION_TYPES.ADD_STEP_TO_SECTION: {
      const kind = (action.payload.kind === WORKFLOW_TYPES.CURRENT) ? state.current : action.payload.kind;
      if (kind === WORKFLOW_TYPES.CURRENT) {
        // current workflow has not been set.
        return state;
      }

      // reduce steps
      const reducedSteps = state.workflows[kind].steps.map(step => {
        if (step.route === action.payload.sectionName) {
          // append new step to section
          return {
            ...step,
            steps: [...step.steps, action.payload.newStep]
          };
        } else {
          // not changed
          return step;
        }
      });

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [kind]: {
            ...state.workflows[kind],
            steps: reducedSteps
          }
        }
      }
    }

    case WORKFLOW_ACTION_TYPES.REMOVE_STEP_FROM_SECTION: {
      const kind = (action.payload.kind === WORKFLOW_TYPES.CURRENT) ? state.current : action.payload.kind;
      if (kind === WORKFLOW_TYPES.CURRENT) {
        // current workflow has not been set.
        return state;
      }

      // reduce steps
      const reducedSteps = state.workflows[kind].steps.map(step => {
        if (step.route === action.payload.sectionName) {
          // remove step from section
          const updatedSteps = (step.steps || []).filter(s => s.route !== action.payload.stepName);
          return {
            ...step,
            steps: updatedSteps
          };
        } else {
          // not changed
          return step;
        }
      });

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [kind]: {
            ...state.workflows[kind],
            steps: reducedSteps
          }
        }
      }
    }

    case WORKFLOW_ACTION_TYPES.UPDATE_STEPS_IN_SECTION: {
      const kind = (action.payload.kind === WORKFLOW_TYPES.CURRENT) ? state.current : action.payload.kind;
      if (kind === WORKFLOW_TYPES.CURRENT) {
        // current workflow has not been set.
        return state;
      }

      // reduce steps
      const reduceSteps = state.workflows[kind].steps.map(step => {
        if (step.route === action.payload.sectionName) {
          const updatedSteps = (step.steps || []).map(sectionStep => {
            const update = action.payload.steps.find((u: Model.WorkflowStep) => u.route === sectionStep.route);
            if (update) {
              return {
                ...sectionStep,
                ...update,
              }
            } else {
              return sectionStep;
            }
          });
          return {
            ...step,
            steps: updatedSteps
          };
        } else {
          // not changed
          return step;
        }
      });

      return {
        ...state,
        workflows: {
          ...state.workflows,
          [kind]: {
            ...state.workflows[kind],
            steps: reduceSteps
          }
        }
      }
    }

    case WORKFLOW_ACTION_TYPES.SHOW_ERROR_STEPPER: {
      const show = action.payload;

      return {
        ...state,
        showErrorStepper: action.payload
      };
    }

    default:
      return state;
  }
}
