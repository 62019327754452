export const REVIEW_SURVEY_CONFIG_v1 = [
  {
    'id': 'review_survey_q1',
    'text': 'Q1.  Is there evidence in the application that it will align with programs that serve traditionally underserved and underrepresented pupils (i.e., English learners, foster youth, and free and reduced-price lunch subgroups)?',
    'choices': [
      {
        'id': 'yes',
        'text': 'Yes'
      },
      {
        'id': 'no',
        'text': 'No',
      },
    ]
  },
  {
    'id': 'review_survey_q2',
    'text': 'Q2.  Does this application serve pupils with higher than average dropout rates according to the "Target Pupil" section of the application?',
    'choices': [
      {
        'id': 'yes',
        'text': 'Yes'
      },
      {
        'id': 'no',
        'text': 'No',
      },
    ]
  },
  {
    'id': 'review_survey_q3',
    'text': 'Q3.  Are the Lead LEA and/or any of the partner LEAs located in an area of the state with a high unemployment rate?  Please refer to the LEA Characteristics List to determine if any of the LEAs are in a high unemployment area.',
    'choices': [
      {
        'id': 'yes',
        'text': 'Yes'
      },
      {
        'id': 'no',
        'text': 'No',
      },
    ]
  },
  {
    'id': 'review_survey_q4',
    'text': 'Q4.  Does the LEAD LEA and/or any of the partner LEAs operate within rural school districts?  Please select "Yes" if the Lead LEA has indicated that it is in a rural school district in the "Lead & Partner Agencies" section.  If not, please refer to the LEA Characteristics List to check if any of the partner LEAs are in rural school districts.',
    'choices': [
      {
        'id': 'yes',
        'text': 'Yes'
      },
      {
        'id': 'no',
        'text': 'No',
      },
    ]
  },
  {
    'id': 'review_survey_q5',
    'text': 'Q5.  How well does the application meet the needs of its local and regional economy as articulated in the CTE Regional Consortia regional plan?',
    'choices': [
      {
        'id': 'extremely',
        'text': '5 - Extremely'
      },
      {
        'id': 'very',
        'text': '4 - Very'
      },
      {
        'id': 'moderately',
        'text': '3 - Moderately',
      },
      {
        'id': 'slightly',
        'text': '2 - Slightly',
      },
      {
        'id': 'notatall',
        'text': '1 - Not at All',
      },
    ]
  },
  {
    'id': 'review_survey_q6',
    'text': 'Q6.  How well does the application leverage existing structures for funding (i.e. Perkins, Partnership, Academies Program, or Agricultural Career Technical Education Incentive, Strong Workflorce Program at the Community College level)?',
    'choices': [
      {
        'id': 'extremely',
        'text': '5 - Extremely'
      },
      {
        'id': 'very',
        'text': '4 - Very'
      },
      {
        'id': 'moderately',
        'text': '3 - Moderately',
      },
      {
        'id': 'slightly',
        'text': '2 - Slightly',
      },
      {
        'id': 'notatall',
        'text': '1 - Not at All',
      },
    ]
  },
  {
    'id': 'review_survey_q7',
    'text': 'Q7.  How well does the application include and leverage contributions from collaborative partners (i.e. industry, labor, philanthropic sources)?',
    'choices': [
      {
        'id': 'extremely',
        'text': '5 - Extremely'
      },
      {
        'id': 'very',
        'text': '4 - Very'
      },
      {
        'id': 'moderately',
        'text': '3 - Moderately',
      },
      {
        'id': 'slightly',
        'text': '2 - Slightly',
      },
      {
        'id': 'notatall',
        'text': '1 - Not at All',
      },
    ]
  },
  {
    'id': 'review_survey_q8',
    'text': 'Q8.  To what extent does the application make investments in CTE infrastructure, equipment, and facilities?',
    'choices': [
      {
        'id': 'great_extent',
        'text': '5 - A great extent'
      },
      {
        'id': 'considerable',
        'text': '4 - Considerable'
      },
      {
        'id': 'moderate',
        'text': '3 - Moderate',
      },
      {
        'id': 'slight',
        'text': '2 - Slight',
      },
      {
        'id': 'notatall',
        'text': '1 - Not at All',
      },
    ]
  },
];
