<h1>NOVA Style Guide</h1>

<h2>Buttons & Button-like links</h2>

<h3>Single Buttons</h3>
<p>
  Buttons look like this. &lt;button&gt; elements are only used for actions.
  "Buttons" that simply link to a location use the &lt;a&gt; element.
</p>

<button type="button" class="action-button action-button--primary"
        [disabled]="false"
        (click)="showActionAlert('Button clicked')">
  Single Primary button
</button>

<button type="button" class="action-button action-button--secondary action-button"
        [disabled]="false"
        (click)="showActionAlert('Button clicked')">
  Single Secondary button
</button>

<button type="button" class="action-button action-button--tertiary action-button"
        [disabled]="false"
        (click)="showActionAlert('Button clicked')">
  Single Secondary button
</button>

<button type="button" class="action-button action-button--secondary action-button--small"
        [disabled]="false"
        (click)="showActionAlert('Button clicked')">
  Single Secondary Small button
</button>


<h3>Links that look like buttons</h3>
<p>
  These are button-looking links. Clicking them just routes you somewhere else in the app.
  The same styles apply to &lt;a&gt; as did for &lt;button&gt;.
</p>
<a [href]="'/styleguide'" class="action-button action-button--primary">
  Go to Style Guide
</a>
<a [href]="'/'" class="action-button action-button--secondary">
  Go to App Root
</a>


<h3>Button Groups</h3>
<p>
  The button group styles are mostly used for icon-only button groups.
</p>
<div class="inline-button-group__container">
  <button class="inline-button action-button action-button--tertiary" (click)="showActionAlert('Button clicked')">
    One
  </button>
  <button class="inline-button action-button action-button--tertiary" (click)="showActionAlert('Button clicked')">
    Two
  </button>
  <button class="inline-button action-button action-button--tertiary" (click)="showActionAlert('Button clicked')">
    Three
  </button>
</div>


<h3>Buttons & Button Groups with Icons</h3>
<div class="inline-button-group__container">
  <button class="inline-button action-button action-button--small action-button--tertiary icon-only"
          title="Edit" (click)="showActionAlert('Edit clicked!')">
    <icon iconId="edit"></icon>
  </button>

  <button class="inline-button action-button action-button--small action-button--tertiary icon-only"
          title="Delete" (click)="showActionAlert('Delete clicked!')">
    <icon iconId="delete"></icon>
  </button>
</div>


<h2>Alerts</h2>
<p>
  The &lt;alert&gt; component provides a pop-up alert in different styles: <code>info, success, warning, error</code>
</p>
<div class="inline-button-group__container">
  <button class="inline-button action-button action-button--tertiary" (click)="showActionAlert('Info level', 'info')">
    info
  </button>
  <button class="inline-button action-button action-button--tertiary" (click)="showActionAlert('Success level', 'success')">
    success
  </button>
  <button class="inline-button action-button action-button--tertiary" (click)="showActionAlert('Warning level', 'warning')">
    warning
  </button>
  <button class="inline-button action-button action-button--tertiary" (click)="showActionAlert('Error level', 'error')">
    error
  </button>
</div>

<h2>Dispatching Application Errors</h2>

<p>
  Use <code>this.store.dispatch(Actions.App.*)</code> actions to trigger application errors
  which can't/shouldn't be handled at the component level. The only available options are:
  "OK" or "Refresh"
</p>
<p>
  In addition, system alerts set with <code>Actions.App.setError()</code> are displayed in the console.
</p>

<div class="inline-button-group__container">
  <button class="inline-button action-button action-button--tertiary" (click)="showAppError()">
    Trigger App Error
  </button>
  <button class="inline-button action-button action-button--tertiary" (click)="showAppAlert()">
    Trigger App Alert
  </button>
  <button class="inline-button action-button action-button--tertiary" (click)="showAppInfo()">
    Trigger App Info
  </button>
  <button class="inline-button action-button action-button--tertiary" (click)="showAppSuccess()">
    Trigger App Success
  </button>
</div>



<alert *ngIf="actionAlertMessage"
  button-action="OK"
  button-dismiss="Cancel"
  return-value="'I get passed to the (action) and (dismiss) events.'"
  [level]="actionAlertLevel"
  (action)="clearActionAlert()"
  (dismiss)="clearActionAlert()"
>
  <p>{{actionAlertMessage}}</p>
</alert>
