import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload, AttributeActions } from '@app-libs';
import { IUserRoleScope } from '@app-models';

export const INSTITUTION_ACTION_PREFIX = 'INSTITUTION_';
export const INSTITUTION_ACTION_TYPES = {
  GET: `${INSTITUTION_ACTION_PREFIX}GET`,
  GET_SUCCESS: `${INSTITUTION_ACTION_PREFIX}GET_SUCCESS`,
  LOAD: `${INSTITUTION_ACTION_PREFIX}LOAD`,
  UPDATE: `${INSTITUTION_ACTION_PREFIX}UPDATE`,
  ADD_ROLE_SCOPES: `${INSTITUTION_ACTION_PREFIX}ADD_ROLE_SCOPES`,
  ADD_ROLE_SCOPES_SUCCESS: `${INSTITUTION_ACTION_PREFIX}ADD_ROLE_SCOPES_SUCCESS`,
  REMOVE_ROLE_SCOPE: `${INSTITUTION_ACTION_PREFIX}REMOVE_ROLE_SCOPE`,
  REMOVE_ROLE_SCOPE_SUCCESS: `${INSTITUTION_ACTION_PREFIX}REMOVE_ROLE_SCOPE_SUCCESS`,
  SET_LOGO_URL: `${INSTITUTION_ACTION_PREFIX}SET_LOGO_URL`,

  // ********** Action Types Managed by Parent/AttributeActions Class ********
  UPSERT_ATTRIBUTE: `${INSTITUTION_ACTION_PREFIX}UPSERT_ATTRIBUTE`,
  UPSERT_ATTRIBUTES: `${INSTITUTION_ACTION_PREFIX}UPSERT_ATTRIBUTES`,
  DELETE_ATTRIBUTES: `${INSTITUTION_ACTION_PREFIX}DELETE_ATTRIBUTES`,
  ADD_MULTI_ATTRIBUTE: `${INSTITUTION_ACTION_PREFIX}ADD_MULTI_ATTRIBUTE`,
  DELETE_MULTI_ATTRIBUTE: `${INSTITUTION_ACTION_PREFIX}DELETE_MULTI_ATTRIBUTE`,
  CREATE_ATTRIBUTE_SUCCESS: `${INSTITUTION_ACTION_PREFIX}CREATE_ATTRIBUTE_SUCCESS`,
  UPSERT_ATTRIBUTE_SUCCESS: `${INSTITUTION_ACTION_PREFIX}UPSERT_ATTRIBUTE_SUCCESS`,
  UPSERT_ATTRIBUTES_SUCCESS: `${INSTITUTION_ACTION_PREFIX}UPSERT_ATTRIBUTES_SUCCESS`,
  DELETE_ATTRIBUTE_SUCCESS: `${INSTITUTION_ACTION_PREFIX}DELETE_ATTRIBUTE_SUCCESS`,
  DELETE_ATTRIBUTES_SUCCESS: `${INSTITUTION_ACTION_PREFIX}DELETE_ATTRIBUTES_SUCCESS`,
  CREATE_EFFORT_AREA: `${INSTITUTION_ACTION_PREFIX}CREATE_EFFORT_AREA`,
  CREATE_EFFORT_AREA_SUCCESS: `${INSTITUTION_ACTION_PREFIX}CREATE_EFFORT_AREA_SUCCESS`,
  CREATE_MULTI_EFFORT_AREAS: `${INSTITUTION_ACTION_PREFIX}CREATE_MULTI_EFFORT_AREAS`,
  CREATE_MULTI_EFFORT_AREAS_SUCCESS: `${INSTITUTION_ACTION_PREFIX}CREATE_MULTI_EFFORT_AREAS_SUCCESS`,
  UPDATE_EFFORT_AREA: `${INSTITUTION_ACTION_PREFIX}UPDATE_EFFORT_AREA`,
  UPDATE_EFFORT_AREA_SUCCESS: `${INSTITUTION_ACTION_PREFIX}UPDATE_EFFORT_AREA_SUCCESS`,
  DELETE_EFFORT_AREA: `${INSTITUTION_ACTION_PREFIX}DELETE_EFFORT_AREA`,
  DELETE_EFFORT_AREA_SUCCESS: `${INSTITUTION_ACTION_PREFIX}DELETE_EFFORT_AREA_SUCCESS`,
  CREATE_ATTRIBUTE_EFFORT_AREA: `${INSTITUTION_ACTION_PREFIX}CREATE_ATTRIBUTE_EFFORT_AREA`,
  CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS: `${INSTITUTION_ACTION_PREFIX}CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS`,
  DELETE_ITEM_SUCCESS: `${INSTITUTION_ACTION_PREFIX}DELETE_ITEM_SUCCESS`,
  CLONE_EFFORT_AREAS: `${INSTITUTION_ACTION_PREFIX}CLONE_EFFORT_AREAS`,
  DELETE_MULTI_EFFORT_AREAS: `${INSTITUTION_ACTION_PREFIX}DELETE_MULTI_EFFORT_AREAS`,
  DELETE_MULTI_EFFORT_AREAS_SUCCESS: `${INSTITUTION_ACTION_PREFIX}DELETE_MULTI_EFFORT_AREAS_SUCCESS`,
};

export class InstitutionActions extends AttributeActions {
  constructor() {
    super(INSTITUTION_ACTION_PREFIX);
  }

  get(institution_id: number): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.GET,
      payload: { institution_id }
    }
  }

  load(institution: Model.Institution): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.LOAD,
      payload: { institution }
    }
  }

  update(updates: Model.Institution): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.UPDATE,
      payload: { updates }
    }
  }

  addRoleScopes(role_scopes: Array<IUserRoleScope>, notify_on_assignment?: boolean): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.ADD_ROLE_SCOPES,
      payload: { role_scopes, notify_on_assignment }
    };
  }

  addRoleScopesSuccess(role_scopes: Array<IUserRoleScope>): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.ADD_ROLE_SCOPES_SUCCESS,
      payload: { role_scopes }
    }
  }

  removeRoleScope(role_scope: IUserRoleScope): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.REMOVE_ROLE_SCOPE,
      payload: { role_scope }
    };
  }

  removeRoleScopeSuccess(role_scope: IUserRoleScope): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.REMOVE_ROLE_SCOPE_SUCCESS,
      payload: { role_scope }
    };
  }

  setLogoUrl(logo_url: string): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.SET_LOGO_URL,
      payload: { logo_url }
    };
  }
}

Actions.Institution = new InstitutionActions();

declare module '@app-ngrx-domains' {
  interface Actions {
    Institution: InstitutionActions
  }
}
