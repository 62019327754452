
import {withLatestFrom, take, skipWhile} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import * as _ from 'lodash';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidatorsEx } from '../../utilities';
import { AnalyticsService, ApiService, EVENT_CATEGORY, ProgramService } from '../../services';
import { PAGE_TYPES } from '../../consts';
import { ALL_RELEASES } from '../../consts';
import { EnumErrorTypes } from '../../models';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html'
})
export class HelpComponent implements OnInit, OnDestroy {
  helpForm: FormGroup;
  user = {email_address: '', first_name: '', last_name: ''};
  showStatus = false;
  waitingOnService = false;
  statusMessage: Model.StatusMessage;
  loggedOut = false;
  requestAccess = false;
  ACCESS_ID = 'access';

  funds: Array<Model.SelectOption> = [];

  subjects: Array<Model.SelectOption> = [
    { value: 1, label: 'Help request'},
    { value: 2, label: 'Bug in the system' },
    { value: 3, label: 'Cosmetic issue' },
    { value: 4, label: 'Feature request' },
    { value: this.ACCESS_ID, label: 'Request access to NOVA'},
    { value: 6, label: 'Miscellaneous' },
  ];

  private subscriptions: Subscription[] = [];

  constructor(
    private apiService: ApiService,
    private _fb: FormBuilder,
    private store: Store<State>,
    private analyticsService: AnalyticsService,
    private programService: ProgramService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.store.dispatch(Actions.Layout.setPageType(PAGE_TYPES.DEFAULT));
    this.requestAccess = !!activatedRoute.snapshot.queryParams['requestAccess'];

    this.funds = this.programService.getProgramOptions();
    this.funds.push({ label: 'Unspecified', value: 99 });
  }

  ngOnInit() {
    // setup header
    const headerInfo: Model.HeaderLayout = {
      ...State.Layout.header,
      title: 'NOVA Help Desk',
    };

    this.store.dispatch(Actions.Layout.setHeader(headerInfo));
    this.helpForm = this._fb.group({
      name: [undefined, [Validators.required]],
      email: [undefined, [Validators.required, ValidatorsEx.email]],
      program: [undefined, [Validators.required]],
      subject: [this.requestAccess ? this.ACCESS_ID : undefined, [Validators.required]],
      message: [undefined, [Validators.required, Validators.minLength(5)]]
    });

    let tempSub;
    tempSub = this.store.select(Queries.Auth.getGuardStates).pipe(
        skipWhile(states => (!states.isLoggedIn && states.isInProgress)),
        take(1),
        withLatestFrom(this.store.select(Queries.Auth.getCurrentUser))
      )
      .subscribe(results => {
        const [states, user] = results;
        if (user) { // There is an auth'd user
          this.user = user;
          this.setFormDefaults();
        } else { // The user is not auth'd (ie, using the Help page anonymously).
        // reset the body style for login.
          this.loggedOut = true;
          this.store.dispatch(Actions.Layout.setPageType(PAGE_TYPES.NAVLESS));
        }
      });
    this.subscriptions.push(tempSub);

    // In case the "Message Sent" message is displayed, clear it whenever the user edits the form again.
    tempSub = this.helpForm.valueChanges.subscribe(changes => {
      this.showStatus = false;
    });
    this.subscriptions.push(tempSub);
  }

  ngOnDestroy() {
    this.store.dispatch(Actions.Layout.clearPageType());

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  logClick(link: HTMLElement) {
    const linkName = link.textContent.trim();
    this.analyticsService.logEvent(EVENT_CATEGORY.link, 'help', linkName);
  }

  submitFeedback() {
    const payload = this.helpForm.value;
    const chosenSubject = this.subjects.find(s => s.value === payload.subject);
    const helpEmailOptions = {
      name: payload.name,
      email: payload.email,
      program: payload.program,
      type: chosenSubject.label,
      message: payload.message,
      client_version: ALL_RELEASES[0].version,
      user_agent: navigator.userAgent,
    };
    this.statusMessage = {message: 'Please wait...', type: 'loading'};
    this.waitingOnService = true; // disable Submit button while waiting on the service.

    this.apiService.sendHelpDeskEmail(helpEmailOptions).subscribe(
      (res: any) => {
        this.setFormDefaults();
        const messageText = this.requestAccess ?
          'Your request was sent. The NOVA support team will contact you soon.' :
          'Message Sent. Thank you for the feedback!';
        this.statusMessage = {message: messageText, type: 'success'};
        this.showStatus = true;
        this.waitingOnService = false;
      },
      (err) => {
        this.statusMessage = {message: 'There was an error sending your message. Please try again.', type: 'fail'};
        this.showStatus = true;
        this.waitingOnService = false;

        // Report the error to the global handler
        this.store.dispatch(Actions.App.setError({
          type: EnumErrorTypes.api,
          location: this.constructor.name,
          show: false,
          raw: JSON.stringify(err.error),
        }));
      }
    );
  }

  private setFormDefaults() {
    // If the user object isn't set (ie, there is no logged in user), stash name & email values
    // from the form, so the user doesn't have to retype them.
    if (!this.user.email_address) {
      this.user.first_name = this.helpForm.controls['name'].value;
      this.user.last_name = '';
      this.user.email_address = this.helpForm.controls['email'].value;
    }
    this.helpForm.reset();
    this.helpForm.controls['name'].setValue(this.user.first_name + ' ' + this.user.last_name);
    this.helpForm.controls['email'].setValue(this.user.email_address);
    this.helpForm.controls['subject'].setValue(this.requestAccess ? this.ACCESS_ID : undefined);
  }

  submitButtonEnabled() {
    return this.helpForm.valid && !this.waitingOnService;
  }
}
