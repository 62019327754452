import {
  Component, EventEmitter,
  Input, OnInit, Output
} from '@angular/core';
import { Model } from '@app-ngrx-domains';
import * as moment from 'moment-timezone';

@Component({
  selector: 'po-time-picker-v2',
  templateUrl: './time-picker-v2.component.html'
})

export class TimePickerV2Component implements OnInit {

  // Specific to time picker
  @Input() formCtrl;
  @Input() increment: number = 30;

  // Passed to po-select
  @Input() id: string;
  @Input() disabledOptions: number[] = [];
  @Input() labelText: string = 'Time';
  @Input() labelHidden = false;
  @Input() placeholderText = '';
  @Input() selectMultiple = false;
  @Input() selectOnce = false;
  @Input() canEdit = true;

  @Input() inline: boolean = false;
  @Input() emptyOption: Model.SelectOption;
  @Input() emptyText: string;
  @Input() emptyIcon: string;
  @Input() showEmpty: boolean = false;

  @Output() search = new EventEmitter<string>();
  @Output() optionSelected = new EventEmitter();
  @Output() optionRemoved = new EventEmitter();

  timeOptions = [];

  ngOnInit() {
    const time = moment().set({ hour: 0, minute: 0, second: 0 });
    let minutes = 0;

    while (minutes >= 0 && minutes < 1440) { // 1440 minutes in a day
      this.timeOptions.push({
        value: minutes,
        label: time.format('h:mm A')
      });

      minutes += this.increment;
      time.add(this.increment, 'minutes');
    }
  }

  selectOption(value) {
    this.optionSelected.emit(value);
  }

  removeOption(value) {
    this.optionRemoved.emit(value);
  }

  doSearch(value) {
    this.search.emit(value);
  }
}
