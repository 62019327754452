<h1 *ngIf="loggedOut" class="header-title h2 mb-l text-center" id="qa_helpDesk_title_h2">
  {{ !requestAccess ? 'NOVA Help Desk' : 'Request Access to NOVA' }}
</h1>
<div *ngIf="!requestAccess" class="card" id="qa_helpDesk_faq_div">
  <div class="card-title">

    <div class="card-section-wrapper columns columns--2 columns-divider">
      <div class="card-section">
        <h2 *ngIf="!requestAccess" class="h3">NOVA Instructional Video Playlists</h2>
        <div class="help-text">
          <p>These videos are great places to start if you are new to NOVA</p>
        </div>
        <ul class="icon-list menu">
          <li class="icon-list__item">
            <a #nivChannel (click)="logClick(nivChannel)" class="icon-list__item__link" href="https://www.youtube.com/channel/UCbnKxCuUjTq1eKNWZRPpbsA" target="_blank">
              <icon iconId="video"></icon>
              NOVA YouTube Channel
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivGeneral (click)="logClick(nivGeneral)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz96d0Ek9hed8nnqpqPTLQ8Rd" target="_blank">
              <icon iconId="video"></icon>
              General
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivCAEP (click)="logClick(nivCAEP)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz95G4sE5myZDH1vrcq929FqJ" target="_blank">
              <icon iconId="video"></icon>
              CAEP
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivEWD (click)="logClick(nivEWD)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz97BQkY8poXkxwj9myqQXtJO" target="_blank">
              <icon iconId="video"></icon>
              EWD
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivGP (click)="logClick(nivGP)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz95CyPk32uY4a8S1kIRDRIWg" target="_blank">
              <icon iconId="video"></icon>
              Guided Pathways
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivIP (click)="logClick(nivIP)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz94RgnknWyaKwcKHQq8-SSXp" target="_blank">
              <icon iconId="video"></icon>
              Integrated Planning
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivK12 (click)="logClick(nivK12)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz96yAakur_lpJK1lAc-wGQji" target="_blank">
              <icon iconId="video"></icon>
              K12
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivLVG (click)="logClick(nivLVG)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz95jeEem6e-AtwkQLt_GVK-v" target="_blank">
              <icon iconId="video"></icon>
              Local Vision Goals
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivNEP (click)="logClick(nivNEP)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz95R_uZXBGLt6UvW1ap4YVJv" target="_blank">
              <icon iconId="video"></icon>
              Nursing
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivPerkins (click)="logClick(nivPerkins)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz95NaKh6EZSngV3IBzmqBTeD" target="_blank">
              <icon iconId="video"></icon>
              Perkins
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivSEP (click)="logClick(nivSEP)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz96evja81JlY8Um1Le3DHPl7" target="_blank">
              <icon iconId="video"></icon>
              Student Equity
            </a>
          </li>
          <li class="icon-list__item">
            <a #nivSWP (click)="logClick(nivSWP)" class="icon-list__item__link" href="https://www.youtube.com/playlist?list=PLR5eKEjUxz96-2TFnohCv-dduRilUhE3z" target="_blank">
              <icon iconId="video"></icon>
              SWP - Local & Regional
            </a>
          </li>
        </ul>
      </div>
      <div class="card-section">
        <h2 *ngIf="!requestAccess" class="h3">NOVA Support</h2>
          <a class="action-button action-button--primary action-button--large icon--center mb-s mr-s ml-s mt-s" href="https://support.cccco.edu/csm?id=sc_cat_item&sys_id=a870423e1b61d010714286e8cd4bcb22" target="_blank" >
            Submit Support Request
          </a>

        <p>
          By clicking the "Submit Support Request" button above, you will be taken to the Chancellor's Office ServiceNow portal where you may submit a new request.
        </p>
        <p>
          When describing your request, please provide as much detail as possible including:
        </p>
          <ul>
              <li>Name of your institution</li>

              <li>Name of the fund you're working in</li>

              <li>Link to the page you have a question about (copy from URL field in your browser)</li>

              <li>Detailed steps we can follow to reproduce your issue, if you're having one</li>

              <li>Any other helpful information so we may provide the quickest response</li>
          </ul>
          <p>
            Please understand that support requests are answered in the order received and may take up to 48 hours for a reply. We strive for same day service.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="card card--help-form" id="qa_helpDesk_getInTouch_div">
  <div *ngIf="!requestAccess" class="card__header">
    <div class="card-title">
      <h2 class="h3">Need Access to NOVA?</h2>
      <div class="help-text">
        <p>
          If you are new to NOVA and would like to have an account created for you,
          please use the <a href="https://nova.cccco.edu/request-access" target="_blank">Access Request</a> wizard to provide your contact information and details for the access you need.
          If you already have a NOVA account and require a modification to your access permissions, please submit a NOVA Support Request through the support portal.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="card card--help-attributions"
     id="qa_helpDesk_attribution_div">
  <div *ngIf="!requestAccess" class="card__header">
    <div class="card-title">
      <h2 class="h3">Attributions</h2>
      <p class="text-center"><a href="https://services.onetcenter.org/" target="_blank" title="This site incorporates information from O*NET Web Services. Click to learn more."><img src="https://www.onetcenter.org/image/link/onet-in-it.svg" style="width: 130px; height: 60px; border: none" alt="O*NET in-it"></a></p>
      <p>This site incorporates information from <a href="https://services.onetcenter.org/" target="_blank">O*NET Web Services</a> by the U.S. Department of Labor, Employment and Training Administration (USDOL/ETA). O*NET&reg; is a trademark of USDOL/ETA.</p>
      <p><a href="https://www.flaticon.com/free-icons/standard" title="standard icons">Standard icons created by Smashicons - Flaticon</a></p>
    </div>
  </div>
</div>
