/**
 * Stub template/placeholder for new or undefined workflow steps.
 */
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { ACTION_BUTTONS, AREAS, CHAR_LIMITS, WORKFLOW_STEPS } from '@app-consts';
import { PermissionsService } from '@app-services';

@Component({
  selector: 'app-template-workflow-placeholder',
  templateUrl: './workflow-placeholder.component.html'
})
export class WorkflowPlaceholderComponent implements OnInit, OnDestroy {
  @Input() isPreview = false;

  canEdit = false;
  firstTouch = true;
  form: FormGroup;

  private destroy$: Subject<boolean> = new Subject();
  private initialized = false;
  private currentWorkflowStep = WORKFLOW_STEPS.DESCRIPTION;
  private workflowStepName = `${this.currentWorkflowStep}_state`;
  private isStepDirty = false;

  constructor(
    private permissionsService: PermissionsService,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private store: Store<State>,
  ) {
  }

  ngOnInit() {

    this.store.select(Queries.CurrentProposal.getProposal)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data: any) => {
      // initialize one time set up code.
      this.initialize(data);

      const [p] = data;

      // Validate all fields and update the CurrentWorkflow state accordingly
      this.store.dispatch(Actions.CurrentWorkflow.validate(this.currentWorkflowStep));
    });

    // listen for button actions.
    this.store.select(Queries.Layout.getEmitState)
    .pipe(takeUntil(this.destroy$))
    .subscribe((emitted: { name: string }) => {
      if (emitted && emitted.name === ACTION_BUTTONS.NEXT) {
        this.store.dispatch(Actions.CurrentWorkflow.gotoNext(this.currentWorkflowStep));
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Initializes the vars that need to be set up just once.
   * @param {any} data
   * */
  private initialize(data: any) {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;

    const [p] = data;

    // see if the step is pristine based on data
    this.firstTouch = this.isPristine(data);
    this.isStepDirty = !this.firstTouch;

    // get permissions.
    if (!this.isPreview) {
      this.fetchPermissions();
    }

    // build form controls
    this.form = this._fb.group({
    });

    // put up 'next' button.
    this.store.dispatch(Actions.Layout.setActions([
      {
        id: 1,
        type: 'button',
        title: 'Next',
        route: ACTION_BUTTONS.NEXT,
        class: 'primary',
      },
    ]));
  }

  private isPristine(data: any) {
    const [p] = data;

    // determine tab's pristine state.
    return true;
  }

  private fetchPermissions() {
    this.permissionsService.canEdit(AREAS.PROJECT, this.route.snapshot)
    .pipe(takeUntil(this.destroy$))
    .subscribe(canEdit => {
      this.canEdit = canEdit;
    });
  }

  markDirty() {
    if (!this.isStepDirty) {
      // mark step as dirty.
      this.isStepDirty = true;
      // clear first touch
      this.store.dispatch(Actions.Workflow.clearFirstTouch(this.currentWorkflowStep));
    }
  }
}
