import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { AREAS, PROJECT_ROLES, WORKFLOW_STEPS, WORKFLOW_TYPES, PROPOSAL_TYPES } from '@app-consts';
import { PermissionsService, ProgramService } from '@app-services';
import { ProposalBase as Proposal } from '@app-models';

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html'
})
export class AgenciesComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isPreview = false;
  @Input() hideTitle = false;

  form: FormGroup;

  canEdit = false;
  firstTouch = false;
  isApplication = true;

  guidance: Model.GuidanceWorkflowFilter;
  leadAgencyTypes: Array<string>;
  partnerAgencyTypes: Array<string>;
  fund: Model.Fund;
  proposal: Model.CAIProposal | Model.SPProposal;
  proposalId: number;

  leadAgency: Model.Institution;
  leadContactRole = PROJECT_ROLES.PROJECT_LEAD.ID;
  showLeadAgency = true;
  partnerAgencies: Model.Institution[];
  noEmployerSelected = false;
  contactRole = PROJECT_ROLES.CONTACT.ID;
  placeholderActive: boolean;
  title = 'Partners';

  private destroy$: Subject<boolean> = new Subject();
  private initialized = false;
  private currentWorkflowStep = WORKFLOW_STEPS.AGENCIES;
  private workflowName = WORKFLOW_TYPES.CAI;

  constructor(
    private programService: ProgramService,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnInit() {
    if (!!this.guidance) {
      this.setupAsTemplate();
      return;
    }

    this.store.select(Queries.CurrentProposal.get).pipe(
      withLatestFrom(this.store.select(Queries.Workflow.getCurrent)),
      takeUntil(this.destroy$)
    ).subscribe(([proposal, workflow]) => {

      if (!proposal.id) {
        // ignore - in transition.
        return;
      }

      // Capture the proposal fundamentals
      this.proposal = proposal;
      this.isApplication = Proposal.isApplication(proposal);
      this.proposalId = proposal.id;
      this.workflowName = workflow.name;

      const programId = proposal.fund_ids[0];
      this.fund = this.programService.getProgramById(programId);

      this.partnerAgencyTypes = this.programService.getPartnerInstitutionTypes(programId);
      this.partnerAgencies = proposal.institutions ?
        proposal.institutions
          .filter(inst => !inst.is_lead && this.partnerAgencyTypes.includes(inst.type))
          .sort((a, b) => a.name - b.name)
        : [];

      this.placeholderActive = !this.fund.is_small_program && !this.partnerAgencies.length; // Display a placeholder agency
      let agencies = [...this.partnerAgencies];

      if (this.fund.is_small_program) {
        this.title = 'Collaborative Partners';
        this.showLeadAgency = false;
        this.currentWorkflowStep = WORKFLOW_STEPS.PARTNERS;
        this.contactRole = undefined;
      } else {
        this.noEmployerSelected = !this.partnerAgencies.some(a => a.is_employer);
        this.leadAgencyTypes = this.programService.getParticipatingInstitutionTypes(programId);
        this.leadAgency = proposal.lead_institution ? proposal.lead_institution : undefined;
        agencies.unshift(this.leadAgency);
      }

      // Add temp application_contribution EA to any agencies that don't already have them.
      agencies.forEach(inst => {
        if (inst && !this.getAgencyAppContribution(inst) && this.isApplication) {
          this.store.dispatch(Actions.CurrentProposal.createTempEffortArea({
            ea: {
              effort_area_type: 'application_contributions',
              institution_id: inst.id,
              parent_proposal_id: this.proposalId
            },
          }));
        }
      });

      // initialize one time set up code.
      this.initialize(proposal);
    });
  }

  /**
   * Initializes the vars that need to be set up just once.
   * */
  private initialize(proposal: Model.CAIProposal | Model.SPProposal) {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;

    if (!this.isPreview) {
      this.fetchPermissions(proposal);
      // current this as the current step & put up Next button.
      this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));

      this.guidance = {
        programId: this.fund.id,
        proposalType: PROPOSAL_TYPES.APPLICATION,
        workflowName: this.workflowName,
        stepName: this.currentWorkflowStep
      }
    }

    // determine first touch
    this.firstTouch = !(this.leadAgency || this.partnerAgencies.length);
  }

  /**
   * Fetches permission(s) then sets up action button(s)
   */
  private fetchPermissions(proposal: Model.ProposalBase) {
    const extraScope: Model.PermResourceScope = {
      fund_id: this.fund.id,
    };
    this.permissionsService.canEdit(AREAS.PROJECT, this.route.snapshot, extraScope)
      .pipe(takeUntil(this.destroy$))
      .subscribe(canEdit => {
        this.canEdit = canEdit;
      });
  }

  addPartnerAgency() {
    this.placeholderActive = true;
  }

  getExistingAgencyIds() {
    return this.proposal ? this.proposal.institutions.map(agency => agency.id) : [];
  }

  getAgencyAppContribution(agencyInst): Model.EAApplicationContribution {
    if (!agencyInst || !this.proposal || !this.proposal.application_contributions) {
      return null;
    } else {
      const appContr = this.proposal.application_contributions.find(ac => ac.institution_id === agencyInst.id);
      return appContr;
    }
  }

  private setupAsTemplate() {
    this.fund = this.programService.getProgramById(this.guidance.programId);
    this.initialized = true;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  trackById(index: number, item: any): number {
    return item.id;
  }

}
