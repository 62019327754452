/**
 * Shows up as /styleguide on non-Prod environments
 */
import {Component, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { EnumErrorTypes } from '../../models';

@Component({
  selector: 'po-style-guide',
  templateUrl: './style-guide.component.html',
})
export class StyleGuideComponent implements OnInit {

  actionAlertMessage = '';
  actionAlertLevel = 'info';

  constructor(private store: Store<State>) {
  }

  ngOnInit() {
    this.store.dispatch(Actions.Layout.showNavBar(true));

    this.store.dispatch(Actions.Layout.setHeader({
      ...State.Layout.header,
      title: 'Style Guide',
    }));

    // TODO: Put a test button in the header.
  }

  showActionAlert(msg, level = 'info') {
    this.actionAlertMessage = msg;
    this.actionAlertLevel = level;
  }
  clearActionAlert() {
    this.actionAlertMessage = '';
  }

  /*********  APPLICATION ALERTS AND ERRORS **************/
  showAppError() {
    try {
      throw new Error('Oh Goodness. Something bad happened.');
    } catch (err) {
      this.store.dispatch(Actions.App.setError({
        location: this.constructor.name,
        type: EnumErrorTypes.core, // See EnumErrorTypes for different types
        raw: err,
        show: true,
        refresh: false, // Set true if the only sensible action is to refresh the browser.
        message: `This is a human-readable message. Please try again.`
      }));
    }
  }
  showAppAlert() {
    this.store.dispatch(Actions.App.showAlert('This is a simple Application Alert.'));
  }
  showAppInfo() {
    this.store.dispatch(Actions.App.showInfo('This is a simple message.'));
  }
  showAppSuccess() {
    this.store.dispatch(Actions.App.showSuccess('This is a success message.'));
  }
}
