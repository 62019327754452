<form *ngIf="form" [formGroup]="form" [ngClass]="{'first-touch': firstTouch}">
  <div class="card-section-wrapper">
    <div *ngIf="!isSingleTypeAgency" class="card-section">
      <po-select id="qa_agency_type_select_{{count}}"
        labelText="{{isLead ? 'Lead Institution' : 'Partner'}} Type"
        [canEdit]="canEditAgency"
        [options]="agencyTypeOptions"
        [selectOnce]="true"
        (optionSelected)="resetSelectedAgency()">
        <select inputRef class="po-select__select" formControlName="agencyType" aria-required="true">
        </select>
      </po-select>
    </div>

    <div class="card-section">
      <po-select id="qa_agency_name_select_{{count}}"
        labelText="{{isLead ? 'Lead Institution' : 'Partner/Employer'}} Name"
        [canEdit]="canEditAgency"
        placeholderText="Start typing agency name"
        [options]="institutionsLookupFormatted"
        [disabledOptions]="existingAgencyIds"
        [isSearch]="true"
        (search)="updateInstitutionsList($event)"
        (optionSelected)="saveAgency()"
        (optionRemoved)="resetSelectedAgency()">
        <select inputRef class="po-select__select" formControlName="agency" aria-required="true">
        </select>
      </po-select>
      <ng-container *ngIf="canEditAgency && !isLead && canCreateAgencyOptions.length">
        <a (click)="openAddAgency()" class="text-link">Can't find who you're looking for?</a>
      </ng-container>
    </div>
  </div>

  <div>
    <radio-check *ngIf="!isLead" [labelText]="'This agency is an employer.'" [canEdit]="canEdit" [readOnlyType]="'check'">
      <input inputRef id="qa_partner_is_employer_checkbox{{count}}"
             class="checkbox" type="checkbox"
             formControlName="isEmployer"
             (change)="toggleIsEmployer()"/>
    </radio-check>
    <p *ngIf="showEmployerAlert" class="error-text">At least one partner agency must be an employer.</p>
  </div>

  <h4 class="h4">{{isLead ? 'Lead Institution' : 'Partner'}} Information</h4>
  <div class="columns columns--2">
    <dl class="project-card__status-list align-left">
      <dt class="project-card__status-title">Address</dt>
      <dd class="project-card__status-text mb-s">{{agency_address}}</dd>
    </dl>

    <dl class="project-card__status-list align-left">
      <dt class="project-card__status-title">Website</dt>
      <dd class="project-card__status-text">{{agency_website}}</dd>
    </dl>
  </div>

  <app-application-contribution *ngIf="!isSmallProgram && appContribution"
    [application_contribution]="appContribution"
    [isPreview]="isPreview"
    [canEdit]="canEdit"
    [isRequired]="false"
    [includeInkindMatchType]="true"
    [count]="count">
  </app-application-contribution>

  <ng-container *ngIf="contactTypes">
    <h4 class="h4" [ngClass]="{'required': canEdit}">Contacts</h4>
    <app-agency-contacts
      [canEdit]="canEdit"
      [fundId]="fundId"
      [proposalId]="proposalId"
      [contactTypes]="contactTypes"
      [agencyLabel]="'Agency'"
      [selectedInstitution]="selectedAgency"
      (removeInstitutionAction)="showRemoveAgencyAlert=true">
    </app-agency-contacts>
  </ng-container>

  <div *ngIf="canEdit && !contactTypes" class="card-footer card-footer--right">
    <button class="action-button action-button--secondary action-button--small icon--left"
      type="button"
      (click)="showRemoveAgencyAlert=true">
      <icon iconId="delete"></icon>
      Remove Partner Agency
    </button>
  </div>
</form>

<alert *ngIf="showRemoveAgencyAlert"
  level="warning"
  button-dismiss="Cancel"
  button-action="Remove"
  (action)="deleteAgency()"
  (dismiss)="showRemoveAgencyAlert=false">
  <ng-container [ngSwitch]="!!selectedAgencyName">
    <p *ngSwitchCase="true">Are you sure you want to remove <strong>{{selectedAgencyName}}</strong>?</p>
    <p *ngSwitchCase="false">Are you sure you want to remove this partner agency?</p>
  </ng-container>
</alert>

<!-- Create new partner agency -->
<app-create-partner-agency *ngIf="showCreateAgency"
  [heading]="'Add Partner'"
  [typeOptions]="canCreateAgencyOptions"
  [pickListFieldName]="isLead ? 'Lead Institution' : 'Partner/Employer'"
  (cancelAction)="showCreateAgency=false"
  (closeAction)="showCreateAgency=false"
  (createSuccess)="addOrUpdateAgency($event)">
</app-create-partner-agency>
