import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[poPopupAnchor]'
})
export class PopupAnchorDirective {
  private scrollContainerQuery = '.content-body__container, .popup-scroll-container';
  private scrollDisabledClass = 'scroll-disabled';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  get element() {
    return this.el.nativeElement;
  }

  get scrollContainers() {
    return this.element.querySelectorAll(this.scrollContainerQuery);
  }

  appendPopup(popup) {
    this.renderer.appendChild(this.element, popup);
  }

  disableScroll() {
    this.scrollContainers.forEach(container => {
      this.renderer.addClass(container, this.scrollDisabledClass);
    });
  }

  enableScroll() {
    this.scrollContainers.forEach(container => {
      this.renderer.removeClass(container, this.scrollDisabledClass);
    });
  }

  getBoundingClientRect() {
    return this.element.getBoundingClientRect();
  }
}
