import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ContentChild, ElementRef, Inject, Input, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PopUpTriggerDirective } from '@app/shared.generic/directives';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'po-pop-up',
  templateUrl: './pop-up.component.html'
})

export class PopUpComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() hideClose: boolean;
  @ContentChild(PopUpTriggerDirective, { static: true }) trigger: PopUpTriggerDirective;
  @ViewChild('popUpTrigger') popUpTrigger: ElementRef;
  @ViewChild('popUpContainer') popUpContainer: ElementRef;
  contentBody: HTMLElement;

  isOpen: boolean;
  unlistenClicks: Function;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private renderer: Renderer2,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    if (this.trigger) {
      this.trigger.openState.pipe(
        takeUntil(this.destroy$)
      ).subscribe(openState => {
        this.isOpen = openState;

        if (this.isOpen) {
          this.unlistenClicks = this.renderer.listen('document', 'click', (event) => {
            const path = event.composedPath ? event.composedPath() : event.path;
            if (!path.find(e => e === this.trigger?.element || e === this.popUpContainer?.nativeElement)) {
              this.closePopUp();
            }
          });

          // Determine if the popup should be pinned to the left or right of the trigger
          this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
              const contentRect = this.contentBody.getBoundingClientRect();
              const triggerRect = this.popUpTrigger.nativeElement.getBoundingClientRect();
              const centerX = (contentRect.width / 2) + contentRect.x;

              const pinTo = triggerRect.x > centerX ? 'right' : 'left';

              this.renderer.setStyle(this.popUpContainer.nativeElement, pinTo, 0);
              this.renderer.setStyle(this.popUpContainer.nativeElement, 'opacity', 1);
            });
          })
        } else {
          this.unlisten();
        }
      });
    }
  }

  ngAfterViewInit() {
    this.contentBody = this.document.querySelector('.content-body') as HTMLElement;
  }

  closePopUp() {
    if (this.trigger) {
      this.trigger.openState.next(false);
    }
  }

  unlisten() {
    if (this.unlistenClicks) {
      this.unlistenClicks();
      this.unlistenClicks = undefined;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
