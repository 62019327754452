import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Model } from '@app-ngrx-domains';
import { CHAR_LIMITS, NOT_ENTERED } from '@app-consts';
import { Utilities, Profile, Resource } from '@app-models';

@Component({
  selector: 'po-score-card',
  templateUrl: './score-card.component.html',
})
export class ScoreCardComponent implements OnInit {
  @Input() canEdit = false;
  @Input() isCumulative = false;
  @Input() isTemplate = false;
  @Input() obscure = true;
  @Input() config: Model.ConfigScoreCard;
  @Output() update: EventEmitter<any> = new EventEmitter();
  private _scores: Array<Model.ResourceScore>;
  @Input() set scores(value: Array<Model.ResourceScore>) {
    this._scores = value;
    if (this.form) {
      const scoreCtrl = this.form.get('score');
      if (this.isScoreReadOnly) {
        scoreCtrl.setValue(this.readOnlyScore);
        scoreCtrl.disable();
      } else {
        scoreCtrl.setValue(this.scores[0].resource.note.score);
        scoreCtrl.enable();

        const commentCtrl = this.form.get('comment');
        commentCtrl.setValue(this.scores[0].resource.note.comment);

        if (this.config.collectScore && !this.config.scoreCommentsOptional) {
          commentCtrl.setValidators([Validators.required, Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN)]);
        } else {
          commentCtrl.setValidators(null);
        }

        commentCtrl.updateValueAndValidity();
      }
    }
  }
  get scores(): Array<Model.ResourceScore> {
    return this._scores;
  }

  @Input() isScoreReadOnly = false;
  private _readOnlyScore: number;
  @Input() set readOnlyScore(value: number) {
    this._readOnlyScore = value;
    if (this.form && this.isScoreReadOnly) {
      this.form.get('score').setValue(this._readOnlyScore);
    }
  }
  get readOnlyScore(): number {
    return this._readOnlyScore;
  }

  form: FormGroup;
  narrativeLength = CHAR_LIMITS.COMMENTS;
  errorText: string;
  notesAvgScore: number;
  hide = false;

  constructor(
    private _fb: FormBuilder,
  ) { }

  ngOnInit() {
    if (this.isCumulative) {
      // show cumulative score cards.
      this.notesAvgScore = Resource.getAverageScore(this.scores);
    } else if (this.isTemplate) {
      // show score card as a tempate.
      this.form = this._fb.group({
        score: [{value: null, disabled: true}],
        comment: [{value: null, disabled: true}],
      });
    } else {
      // manage single score card.
      this.form = this._fb.group({
        score: this.isScoreReadOnly ? [{value: this.readOnlyScore, disabled: true}] : [this.scores[0].resource.note.score],
        comment: [this.scores[0].resource.note.comment,
          this.config.collectScore && !this.config.scoreCommentsOptional ? [Validators.required, Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN)] : []
        ],
      });

      // perform initial validation.
      if (!this.isScoreReadOnly) {
        this.isValidScore(this.scores[0].resource.note.score);
      }
    }
  }

  toggleHideCluster() {
    this.hide = !this.hide;
  }

  persistValue(ctrlName: string) {
    const oldValue = this.scores[0].resource.note[ctrlName];
    let value = this.form.get(ctrlName).value;

    if (ctrlName === 'score') {
      if (this.isNil(value)) {
        // reassign value to null.
        value = null;
      } else {
        value = Number(value);
      }
      if (!this.isValidScore(value) && value !== null) {
        // value out of range, reset value.
        value = null;
        this.form.get(ctrlName).setValue(value);
      }
    }

    if (value !== oldValue) {
      this.update.emit({
        [ctrlName]: value,
      });
    }
  }

  isNil(value: any) {
    return Utilities.isNil(value);
  }

  isValidScore(score: number): boolean {
    if (this.isNil(score)) {
      this.errorText = 'This field is required';
    } else if (score < this.config.minScore) {
      this.errorText = `The minimum value is ${this.config.minScore}`;
    } else if (score > this.config.maxScore) {
      this.errorText = `The maximum value is ${this.config.maxScore}`;
    } else {
      this.errorText = undefined;
    }

    return this.errorText === undefined;
  }

  scorerFullName(score: Model.ResourceScore): string {
    return this.obscure ? `Reviewer ${score.scorer_index}` : Profile.contactFullName(score.scorer);
  }

  scorerEmail(scorer: Model.User): string {
    return Profile.contactEmail(scorer, this.obscure);
  }

  scoreString(resource: Model.Resource): string {
    let score: number = null;
    if (this.isScoreReadOnly && !this.isCumulative) {
      score = this.readOnlyScore;
    } else {
      score = resource ? resource.note.score : null;
    }
    return `${this.isNil(score) ? '-' : score} /  ${this.config.maxScore ? this.config.maxScore : '-'}`;
  }

  commentsString(resource: Model.Resource): string {
    const note = resource ? resource.note : null;
    return !note || this.isNil(note.comment) ? NOT_ENTERED : note.comment;
  }
}
