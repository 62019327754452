import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Model } from '@app-ngrx-domains';
import { Store } from '@ngrx/store';
import { Actions, Queries, State } from '@app-ngrx-domains';
import { FUND_TYPES, PROJECT_ROLES } from '@app-consts';
import { ApiService, PermissionsService } from '@app-services';
import { Profile } from '@app-models';


@Component({
  selector: 'app-approval-card',
  templateUrl: './approval-card.component.html'
})
export class ApprovalCardComponent implements OnInit, OnChanges {
  // @Input() contact: any;
  @Input() index: any;
  @Input() task: any;
  @Input() isSubmitted: boolean;
  @Input() isSubmittal = false; // Change display logic to use 'Submit' verbs rather than 'Approve'
  @Input() fundType: any;
  @Input() hideReminder: boolean;
  @Input() hideStatus: boolean;
  @Input() contacts: Array<Model.UserRoleScope>;
  @Input() validApprovers: Array<Model.User>;

  @Output() approve: EventEmitter<any> = new EventEmitter<any>();
  @Output() reject: EventEmitter<any> = new EventEmitter<any>();
  @Output() remind: EventEmitter<any> = new EventEmitter<any>();


  currentUser: Model.User;

  // State of Task
  useCertify: boolean = false;
  approved: boolean = false;
  completed: boolean = false;
  performedBy: string = '';
  // canEdit: boolean = false;
  hasTask: boolean = false;

  // For displaying loading symbols
  approving: boolean = false;
  rejecting: boolean = false;

  constructor(
    private store: Store<State>,
    private apiService: ApiService,
    private permissionsService: PermissionsService
  ) { }

  ngOnInit() {
    this.store.select(Queries.Auth.getCurrentUser).subscribe(
      cu => {
        this.currentUser = cu;
        this.initialize();
      }
    )

    // Use the work 'Certify' for GP instead of 'Approve'
    this.useCertify = this.fundType === FUND_TYPES.GP;
  }

  ngOnChanges() {
    this.initialize();
    this.resetLoading();
  }

  initialize() {
    // Check if a task was passed in, if not this means the proposal has not ever been submitted

    this.hasTask = !!this.task;

    // Used to control card state, whether to disable/hide buttons
    this.approved = this.hasTask && this.task.completed && !this.task.store.rejected;

    // Try to get a pretty name to put next to Approved
    if (this.approved) {
      const approved_by = this.task.performed_by_id;
      let approver;
      if (this.task.performed_by) {
        approver = this.task.performed_by;
      } else if (this.validApprovers && this.validApprovers.length) {
        approver = this.validApprovers.find(a => a.id === approved_by);
      } else if ( this.contacts && this.contacts.length) {
        approver = this.contacts.map(c => c.user).find(user => user && user.id === approved_by);
      }

      if (approver) {
        this.performedBy = ` by ${Profile.contactFullName(approver)}`;
      } else if (!approver && this.fundType === FUND_TYPES.AEBG) {
        // Find approver through API service if they have been removed as Member Rep in CFADs
        this.apiService.getProfileById(approved_by).subscribe(profile => {
          this.performedBy = ` by ${Profile.contactFullName(profile)}`;
      });
      }
    }
    this.completed = this.hasTask && !!this.task.completed;

  }

  completeTask(rejected: boolean) {
    rejected ? this.reject.emit() : this.approve.emit();
    return;
  }

  sendReminder() {
    this.remind.emit();
  }

  resetLoading() {
    this.approving = false;
    this.rejecting = false;
  }

  getClassState() {

    if (this.completed && this.approved) {
      return 'certification-card--approved';
    } else if (this.completed) {
      return 'certification-card--rejected';
    } else if (this.isSubmitted) {
      return 'certification-card--waiting';
    } else {
      return 'certification-card--disabled';
    }
  }

  disableButtons() {
    return !this.hasTask || (!this.isSubmittal && !this.isSubmitted);
  }

  get canEdit() {
    switch (this.fundType) {
      case FUND_TYPES.AEBG:
        return this.contacts
          .filter((contact: Model.UserRoleScope) => contact.role_id === PROJECT_ROLES.MEMBER_REP.ID)
          .some((contact: Model.UserRoleScope) => this.currentUser && contact.user_id === this.currentUser.id);
      case FUND_TYPES.SWP_L:
      case FUND_TYPES.SWP_R:
      case FUND_TYPES.GP:
        return this.validApprovers &&
          this.validApprovers.map(user => user.id).includes(this.currentUser.id);
      case FUND_TYPES.SWP_K12:
      case FUND_TYPES.LVG:
        return this.hasTask &&
          this.contacts.filter((contact: Model.UserRoleScope) => contact.role_id === this.task.role_id)
            .some((contact: Model.UserRoleScope) => this.currentUser && contact.user_id === this.currentUser.id);
      default:
        return false;
    }
  }

  get taskStatus() {
    switch (this.fundType) {
      case FUND_TYPES.SWP_K12: {
        if (this.approved) {
          return 'Accepted Funding';
        } else {
          return 'Rejected Funding' + this.performedBy;
        }
      }

      default: {
        if (this.approved) {
          if (this.isSubmittal) {
            return 'Submitted' + this.performedBy;
          } else {
            return (this.useCertify ? 'Certified' : 'Approved') + this.performedBy;
          }
        } else {
          return 'Rejected' + this.performedBy;
        }
      }
    }
  }

  get awaitingStatus() {
    switch (this.fundType) {
      case FUND_TYPES.SWP_K12: {
        return 'Awaiting Acceptance/Rejection';
      }

      default: {
        if (this.isSubmitted && !this.isSubmittal) {
          return this.useCertify ? 'Awaiting Certification' : 'Awaiting Approval';
        } else {
          return 'Awaiting Submittal';
        }
      }
    }
  }

  get completeVerb() {
    switch (this.fundType) {
      case FUND_TYPES.SWP_K12: {
        return 'Accept';
      }

      default: {
        if (this.isSubmittal) {
          return 'Submit';
        } else {
          return this.useCertify ? 'Certify' : 'Approve';
        }
      }
    }
  }
}
