
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { PROGRAM_KEYS, ROUTER_LINKS } from '@app-consts';
import { Utilities } from '@app-models';
import { Actions, Queries, State } from '@app-ngrx-domains';
import { ProgramService } from '@app-services';
import { Store } from '@ngrx/store';
import { maxBy } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { filter, skipWhile, take, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class GrantGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private programService: ProgramService,
    private store: Store<State>,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoaded(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoaded(route, state);
  }

  // Loads the most recent grant of a given key
  isLoaded(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {
      this.logger.debug(`[grant-guard][${state.url}] checking...`);

      const parentKey = Utilities.programKeyFromRoute(state.url);

      this.programService.programLoaded(parentKey).pipe(
        skipWhile((loaded) => !loaded),
        take(1)
      ).subscribe(() => {
        const grantKey = route.data['grantKey'];
        if (!grantKey) {
          // redirect user to page-not-found page.
          this.logger.info(`[grant-guard][${state.url}] bad url - redirecting to page-not-found`);
          this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
          subscriber.next(false);
          subscriber.complete();
          return;
        }

        const grants = this.programService.getChildProgramsByParentKey(parentKey, grantKey);
        const latestGrant = maxBy(grants, 'program_settings.base_duration_id');

        // fetch fund.
        this.store.dispatch(Actions.Fund.get(latestGrant.id));

        this.store.select(Queries.Fund.getLoadingStatus).pipe(
          withLatestFrom(this.store.select(Queries.Fund.get)),
          filter(([status, fund]) => !status.is_loading && !!(fund && fund.id)),
          take(1)
        ).subscribe(data => {
          const [status, fund] = data;
          const rootFund = this.programService.getRootFund(fund);

          if (fund.deleted) {
            this.logger.error(`[grant-guard][${state.url}] failed to load, '${fund.name}' grant has been deleted`);
            this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
            subscriber.error(false);
            subscriber.complete();
            return;
          }

          if (status.error) {
            this.logger.error(`[grant-guard][${state.url}] failed to load=${JSON.stringify(status.error)}`);
            this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
            subscriber.error(false);
            subscriber.complete();
            return;
          }

          if ((fund.key === PROGRAM_KEYS.GRANTS && rootFund.key !== parentKey) && (fund.parent_key && fund.parent_key !== parentKey)) {
            this.logger.error(`[grant-guard][${state.url}] failed to load, wrong parent=${fund.parent_key}`);
            this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
            subscriber.error(false);
            subscriber.complete();
            return;
          }

          this.logger.debug(`[grant-guard][${state.url}] loaded`);
          subscriber.next(true);
          subscriber.complete();
        },
        error => {
          // error thrown while loading proposal preview.
          this.logger.error(`[grant-guard][${state.url}] error=${JSON.stringify(error)}`);
          // route user back to fund settings dashboard.
          this.router.navigate([`/${parentKey}/settings`]);
          subscriber.error(false);
          subscriber.complete();
        });
      });
    });
  }
}
