import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State, Actions, Model } from '@app-ngrx-domains';
import { CHAR_LIMITS } from '@app-consts';
import { Utilities } from '@app-models';
import { inKindMatchSources } from '@app/SWPK12/consts';

@Component({
  selector: 'app-application-contribution',
  templateUrl: './agency-application_contribution.component.html'
})
export class ApplicationContributionComponent implements OnInit {
  @Input() canEdit = false;
  @Input() isPreview = false;
  @Input() set application_contribution(contrib: Model.EAApplicationContribution) {
    if (this.form && !this.canEdit) {
      // reset match values that could have changed.
      this.form.get('financial_contribution').setValue(contrib.financial_contribution);
      this.form.get('in_kind_match').setValue(contrib.in_kind_match);
    }
    this._applicationContribution = contrib;
  }
  get application_contribution(): Model.EAApplicationContribution{
    return this._applicationContribution;
  }
  _applicationContribution: Model.EAApplicationContribution;


  @Input() isRequired = true;
  @Input() includeInkindMatchType = false;
  @Input() count: number;

  @Output() delete: EventEmitter<Model.EffortArea> = new EventEmitter();
  isInKindMatchTypeRequired: boolean;
  form: FormGroup;
  inKindMatchOptions: Array<Model.SelectOption> = inKindMatchSources.map(source => ({ label: source, value: source }));
  narrative_limit = CHAR_LIMITS.LONG;

  constructor(
    private store: Store<State>,
    private _fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    const input_validators = this.isRequired ? [Validators.required, Validators.min(0)] : [Validators.min(0)];
    this.form = this._fb.group({
      financial_contribution: [this.application_contribution['financial_contribution'], input_validators],
      in_kind_match: [this.application_contribution['in_kind_match'], input_validators],
      in_kind_match_type: [
        this.application_contribution['in_kind_match_type'],
        this.isRequired ? [Validators.required] : [],
      ],
      description: [this.application_contribution.description, [Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN)]]
    });
    this.updateInkindMatchTypeRequired();
  }

  persistAttribute(attribute_name: string) {
    const value = this.form.get(attribute_name).value;
    const oldValue = this.application_contribution[attribute_name] || null;
    if (value !== oldValue) {
      this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: attribute_name, value, ea: this.application_contribution }))
      if (attribute_name === 'in_kind_match') {
        this.updateInkindMatchTypeRequired();
      }
    }
  }

  get institution_id() {
    return this.application_contribution.institution_id;
  }
  updateInkindMatchTypeRequired() {
    if (!this.includeInkindMatchType) {
      //  no need to check in kind match type requirement
      return;
    }
    this.isInKindMatchTypeRequired = this.isRequired;
    if (!this.isInKindMatchTypeRequired && this.form) {
      const inKindMatch = this.form.get('in_kind_match').value;
      if (!Utilities.isNil(inKindMatch) && inKindMatch > 0) {
        this.isInKindMatchTypeRequired = true;
      }
    }
  }
}
