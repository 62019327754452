import { filter, withLatestFrom, takeUntil, take } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { CHAR_LIMITS, MAX_ALLOCATION_COLS, METRIC_GROUPS, METRIC_GROUPS_LABELS, PROGRAM_KEYS, PROJECT_ROLES, WORKFLOW_STEPS } from '@app-consts';
import { LookupService, ProgramService } from '@app-services';
import { ValidatorsEx } from '@app-utilities';
import { Duration } from '@app-models';
import * as moment from 'moment';
import { uniqBy, find, pull, some } from 'lodash';

export const allSectorsOption = { value: 1, label: 'Across All Sectors' };

@Component({
  selector: 'activities-card',
  templateUrl: './activities-card.component.html'
})
export class ActivitiesCardComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() set activityEA(value: Model.EAWorkplanActivity) {
    this._activityEA = value;
    this.setContacts();
    this.setSectors();
  };

  get activityEA() {
    return this._activityEA;
  }
  @Input() canEdit = false;
  @Input() canDelete = true;
  @Input() preventDeleteReason: string;
  @Input() firstTouch = true;
  @Input() isFirst: boolean;
  @Input() isPreview = false;
  @Input() isPrimary = false;
  @Input() objectives = [];
  @Input() count: number;
  @Input() threeYearPlan: Model.EAThreeYearPlan;
  @Input() parentEffortAreas: Model.EffortArea[];

  private _activityEA = {} as Model.EAWorkplanActivity;

  public collectObjective: boolean = false;
  public collectOutcomes: boolean = false;
  public collectMetrics: boolean = false;
  public metricsOptional: boolean = false;
  public collectVisionGoals: boolean = false;
  public collectPrioritySector: boolean = false;
  public prioritySectorOptional: boolean = false;
  public collectDependencies: boolean = false;
  public collectTimeline: boolean = false;
  public collectStartEndDates: boolean = false;
  public collectCompletionDate: boolean = false;
  public collectReportingCategories: boolean = false;

  public contacts = [];
  public disabledList = [];
  public durationsList: Array<Model.SelectOption>;
  public responsible_people = [];
  public responsible_id_list = [];

  public metricsOptions: Array<Model.SelectOption> = [];
  public sectorsOptions: Array<Model.SelectOption> = [];
  public proposal = undefined;
  public allowGlobalUsers: boolean = false;
  public personOptionsList = [];
  public showAddPersonForm = false;

  public showDeleteActivityAlert = false;

  public add_person_form: FormGroup;
  public form: FormGroup;
  public textAreaLength = CHAR_LIMITS.EXTRA_MEDIUM;
  public shortTextAreaLength = CHAR_LIMITS.MEDIUM;
  public minDateString: string;
  public maxDateString: string;
  public labels: { [name: string]: string} = {};
  public helpText: { [name: string]: string} = {};

  public prioritySectorsForm: FormArray;
  public selectedSectors: Array<number> = [];
  private sectors: Array<Model.SelectOption> = []

  public categoryOptions: Array<Model.SelectOption> = [];

  public isCAIv2 = false;
  public isSWPv3 = false;
  public isCAEP = false;

  private destroy$: Subject<boolean> = new Subject();
  private initialized = false;
  private fund: Model.Fund;

  constructor(
    private lookupService: LookupService,
    private programService: ProgramService,
    private _fb: FormBuilder,
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnInit() {
    combineLatest([
      this.store.select(Queries.CurrentProposal.get),
      this.store.select(Queries.Contacts.get),
    ]).pipe(
      withLatestFrom(
        this.store.select(Queries.LookupTables.getMetricsDefinitions),
        this.store.select(Queries.LookupTables.getMetricsDefinitionsByGroup),
        this.store.select(Queries.LookupTables.getSectorsCCCCO),
        this.store.select(Queries.LookupTables.getCategories),
      ),
      filter(([[p, c], , , ]) => p && p.id && !!c),
      takeUntil(this.destroy$)
    ).subscribe(([[proposal, contacts], metricDefs, getMetrics, sectors, categories]) => {

      this.proposal = proposal;
      this.sectors = [...sectors];

      // initialize one time set up code.
      this.initialize(metricDefs, getMetrics, contacts, categories);
    });
  }

  /**
   * Initializes the vars that need to be set up just once.
   * */
  private initialize(metricDefs, getMetrics, contacts, categories) {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;

    this.fund = this.proposal.funds[0];
    this.isSWPv3 = [PROGRAM_KEYS.SWPL_v3, PROGRAM_KEYS.SWPR_v3].includes(this.fund.key);
    this.isCAIv2 = this.fund.parent_key === PROGRAM_KEYS.CAI && this.fund.key === PROGRAM_KEYS.RFA_v2;
    this.isCAEP = this.fund.key === PROGRAM_KEYS.CAEP;

    this.collectObjective = !this.fund.is_small_program;
    this.collectOutcomes = [PROGRAM_KEYS.PERKINS, PROGRAM_KEYS.RCM].includes(this.fund.parent_key) || this.isCAEP;
    this.collectMetrics = (!this.isCAIv2 && [PROGRAM_KEYS.CAI].includes(this.fund.parent_key));
    this.collectVisionGoals = ![PROGRAM_KEYS.EWD, PROGRAM_KEYS.CAI].includes(this.fund.parent_key);
    this.collectDependencies = this.fund.is_small_program || [PROGRAM_KEYS.EWD, PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.fund.parent_key);
    this.collectPrioritySector = this.fund.is_small_program || [PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.fund.parent_key);
    this.prioritySectorOptional = this.fund.is_small_program;

    // time related collections
    this.collectTimeline = [PROGRAM_KEYS.PERKINS, PROGRAM_KEYS.RCM].includes(this.fund.parent_key);
    this.collectStartEndDates = [PROGRAM_KEYS.CAI].includes(this.fund.parent_key);
    this.collectCompletionDate = this.fund.is_small_program || this.isCAEP || [PROGRAM_KEYS.EWD, PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(this.fund.parent_key);

    if (this.fund.is_small_program) {
      const program = this.programService.getProgramById(this.fund.id);
      const hasVisionGoals = program.program_settings.workflow_steps.find(step => step.enabled && step.workflow_name === WORKFLOW_STEPS.METRICS);
      if (hasVisionGoals) {
        this.collectVisionGoals = true;
      } else {
        this.collectMetrics = true;
        this.metricsOptional = true;
      }

      if (program.program_settings.use_funding_categories) {
        this.categoryOptions = categories
          .filter(c => c.fund_id === this.fund.id && !c.deleted)
          .sort((a, b) => a.name > b.name ? 1 : -1)
          .map(c => ({ label: c.name, value: c.id }));

        if (this.categoryOptions.length) {
          this.collectReportingCategories = true;
        }
      }
    }

    // only CAI RFA has a limited list of possible responsible people
    const isCAIRFA = this.fund.parent_key === PROGRAM_KEYS.CAI && [PROGRAM_KEYS.RFA, PROGRAM_KEYS.RFA_v2].includes(this.fund.key);
    this.contacts = isCAIRFA ?
      contacts.filter(contact =>
        contact.role_id === PROJECT_ROLES.PROJECT_LEAD.ID ||
        contact.role_id === PROJECT_ROLES.CONTACT.ID
      ) : contacts;

    // contacts is total list of people that can be added as responsible people
    this.contacts = uniqBy(this.contacts
      .map((contact) => ({
        value: contact.user.id,
        label: contact.user.first_name + ' ' + contact.user.last_name,
        user: contact.user
      })), (contact) => contact.value);

    this.allowGlobalUsers = this.isCAEP;
    this.personOptionsList = this.allowGlobalUsers ? [] : this.contacts.sort((a, b) => a.label > b.label ? 1 : -1);

    // determine first touch
    this.firstTouch = !(
      this.activityEA.title
      || this.activityEA.description
      || this.activityEA.success_metrics.length
      || this.activityEA.objective_ea_id
      || this.activityEA.regional_priority_sectors.length
      || this.activityEA.responsible_user_ids.length
      || this.activityEA.performance_outcome
      || this.activityEA.duration_id
      || this.activityEA.dependencies
      || this.activityEA.start_date
      || this.activityEA.end_date
      || (this.isCAEP ? (this.activityEA.short_term_outcomes || this.activityEA.intermediate_outcomes || this.activityEA.long_term_outcomes) : false)
    );

    this.fillHelpTextAndLabels();

    // prepare form
    this.form = this._fb.group({
      title: [this.activityEA.title, [Validators.required, Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN)]],
      description: [this.activityEA.description, [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)]],
      success_metrics: [this.collectVisionGoals
        ? this.activityEA.vision_goal_metrics.map(vGM => vGM.value) : this.activityEA.success_metrics.map(attr => attr.value),
        this.metricsOptional ? undefined : [Validators.required]],
      responsible_user_ids: [this.activityEA.responsible_user_ids, [Validators.required]]
    });

    if (this.collectObjective) {
      this.form.addControl('objective_ea_id', new FormControl(this.activityEA.objective_ea_id, [Validators.required]));
    }

    if (this.collectTimeline) {
      this.durationsList = this.lookupService.getDurationsByType('quarter')
        .filter(d => d.year >= this.proposal.duration_id - 1 && d.year <= this.proposal.duration_id + MAX_ALLOCATION_COLS).map(d => {
          return { label: Duration.getDurationSeasonString(d), value: d.id }
        });
      this.form.addControl('duration_id', new FormControl(this.activityEA.duration_id, [Validators.required]));
    }

    if (this.collectOutcomes) {
      if (this.isCAEP) {
        this.form.addControl('short_term_outcomes', new FormControl(this.activityEA.short_term_outcomes, [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)]));
        this.form.addControl('intermediate_outcomes', new FormControl(this.activityEA.intermediate_outcomes, [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)]));
        this.form.addControl('long_term_outcomes', new FormControl(this.activityEA.long_term_outcomes, [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)]));
      } else {
        this.form.addControl('performance_outcome', new FormControl(this.activityEA.performance_outcome, [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)]));
      }
    }

    if (this.collectDependencies) {
      this.form.addControl('dependencies', new FormControl(this.activityEA.dependencies, [Validators.required, ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)]));
    }

    this.setSectors(true);

    if (this.collectCompletionDate) {
      // Get current date to set minimum value on completion date
      this.minDateString = moment(this.proposal.created_at).startOf('day').add(1, 'day').toISOString();
      this.form.addControl('end_date', new FormControl(this.activityEA.end_date, [Validators.required]));
    }

    if (this.collectStartEndDates) {
      this.minDateString = `${this.proposal.duration_id - 2}-01-01`;
      this.maxDateString = `${this.proposal.duration_id + 10}-12-31`;

      const dateForm = this._fb.group({
        start_date: [this.activityEA.start_date, [Validators.required]],
        end_date: [this.activityEA.end_date, [Validators.required]],
      }, { validator: ValidatorsEx.endDateValidator('start_date', 'end_date') });
      this.form.addControl('dates', dateForm);
    }

    if (this.collectReportingCategories) {
      this.form.addControl('related_categories', new FormControl(this.activityEA.related_categories.map(c => c.value), [Validators.required]));
    }

    this.add_person_form = this._fb.group({
      name: [undefined, [Validators.required]]
    });

    this.lookupAndVerifyMetrics(metricDefs, getMetrics);
    this.setContacts(true);
    this.verifySelectedObjective();
  }

  private lookupAndVerifyMetrics(metricDefs, getMetrics) {
    const parentKey = this.fund.parent_key;
    const proposal = this.isCAEP ? this.threeYearPlan : this.proposal;

    if (this.collectMetrics) {
      const metricGroup = Object.values(METRIC_GROUPS).includes(parentKey) ? parentKey : METRIC_GROUPS.DEFAULT;
      this.metricsOptions = getMetrics(metricGroup).map(i => ({ value: i.id, label: i.name }));
    } else if (this.collectVisionGoals && proposal.vision_goals) {
      const visionGoals = proposal.vision_goals.filter(goal => goal.success_goal && goal.selected);
      const isRCM = parentKey === PROGRAM_KEYS.RCM;
      visionGoals.forEach(goal => {
        const goalName = goal.success_goal.name;
        goal.success_metrics.forEach(metric => {
          const metricDef = metricDefs.find(def => def.id === metric.value);
          const groupName = isRCM ? `${METRIC_GROUPS_LABELS[metricDef.group]}: ` : '';
          if (metricDef) {
            this.metricsOptions.push({ label: `${goalName}: ${groupName}${metricDef.name}`, value: metricDef.id });
          }
        });
      });
    }

    if (this.isCAEP) {
      // Add 'All' options
      const metricsAllOptions = getMetrics(METRIC_GROUPS.CAEP).filter(m => m.area === 'All');
      (metricsAllOptions || []).forEach(m => this.metricsOptions.push({ value: m.id, label: `All: ${m.name}` }));
      this.metricsOptions = (this.metricsOptions || []).sort((a, b) => a.label > b.label ? 1 : -1);
    }

    // clean up any obsolete references
    const successMetrics = this.form.get('success_metrics').value;
    if (successMetrics.length > 0) {
      const metricRefIds = this.metricsOptions.map(mo => mo.value);
      const cleanedSuccessMetrics = [];
      let refresh = false;
      successMetrics.forEach(id => {
        if (metricRefIds.includes(id)) {
          cleanedSuccessMetrics.push(id);
        } else {
          this.removeMetric(id);
          refresh = true;
        }
      });
      if (refresh) {
        this.form.get('success_metrics').setValue(cleanedSuccessMetrics);
      }
    }
  }

  updateUserList(filter: string) {
    this.lookupService.formattedContactList$(filter, 100).subscribe((res) => {
      this.personOptionsList = res;
    });
  }

  private setContacts(verify: boolean = false) {
    if (this.allowGlobalUsers) {
      this.responsible_people = this.activityEA.responsible_user_ids.filter(item => !!item.user).map(item => ({
        value: item.value,
        label: item.user.first_name + ' ' + item.user.last_name,
        user: item.user
      })).sort((a, b) => a.label > b.label ? 1 : -1);

      return;
    }

    // responsible_id_list is the list of ids of already selected responsible people
    if (verify && this.activityEA.responsible_user_ids.length > 0) {
      // purge obsolete contacts
      this.responsible_id_list = [];
      const contactIds = this.contacts.map(contact => contact.value);
      this.activityEA.responsible_user_ids.forEach(idObj => {
        if (contactIds.includes(idObj.value)) {
          this.responsible_id_list.push(idObj.value);
        } else {
          this.removePerson(idObj);
        }
      });
    } else {
      this.responsible_id_list = this.activityEA.responsible_user_ids.map((idObj) => idObj.value);
    }

    this.disabledList = this.responsible_id_list;
    this.responsible_people = this.contacts
      .filter(contact => this.responsible_id_list.includes(contact.value))
      .sort((a, b) => a.label > b.label ? 1 : -1);
  }

  private verifySelectedObjective() {
    if (this.activityEA.objective_ea_id) {
      const objectiveIds = this.objectives.map(obj => obj.value);
      if (!objectiveIds.includes(this.activityEA.objective_ea_id)) {
        this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: 'objective_ea_id', value: undefined, ea: this.activityEA }));
        this.form.get('objective_ea_id').setValue(undefined);
      }
    }
  }

  private fillHelpTextAndLabels() {
    // assign default help text
    this.helpText = {
      ['card']: 'Describe the who, what, and when of your Workplan.',
      ['description']: 'Key deliverable required for successful project completion.',
      ['responsible_user']: 'Note: Responsible persons are selected from agency contacts.',
      ['metrics']: undefined,
    };

    // assign default labels
    this.labels = {
      ['description']: 'Brief Description of Activity',
      ['metrics']: 'Select Student Success Metrics that Apply to this Activity',
    };

    // help text & label overrides
    if (this.fund.parent_key === PROGRAM_KEYS.EWD) {
      this.helpText.description = 'Key deliverables and how this activity contributes to a successful project outcome in the current grant period.';
      this.helpText.responsible_user = undefined;
    } else if ([PROGRAM_KEYS.SWP_R, PROGRAM_KEYS.SWP_L].includes(this.fund.parent_key)) {
      this.helpText.description = 'Key deliverables and how this activity contributes to a successful project outcome in the current grant period.';
      this.helpText.responsible_user = 'Note: Responsible persons are selected from users added to the contacts tab.';
      this.helpText.metrics = 'Select the Student Success Metrics and Performance Outcomes that align with the projected activity';
      this.labels.metrics = 'Student Success Metrics and SWP Metrics';
    } else if (this.fund.is_small_program) {
      this.helpText.description = 'Key deliverables and how this activity contributes to a successful project outcome in the current grant period.';
      this.labels.description = 'Brief Description of Activity and Significance of Activity to Outcome';
    } else if (this.fund.key === PROGRAM_KEYS.CAEP) {
      this.helpText.card = '';
      this.helpText.description = `Describe the activity that will be carried out; what agencies or individuals responsible to carry out the activity; the key
        deliverables; how the activity will contribute to achieving short-term, intermediate, and long-term outcomes; and how the activity will contribute to
        one or more of the CAEP objectives and targets related to Student Barriers and adult education Metrics.`;
      this.helpText.metrics = 'Select the Adult Ed Metrics that align with the projected activity.';
      this.labels.metrics = 'Adult Ed Metrics and Student Barriers'
    }
  }

  persistValue(attributeName: string) {
    const value = this.form.get(attributeName).value;
    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: attributeName, value, ea: this.activityEA, parentEffortAreas: this.parentEffortAreas }));
  }

  persistMetric(metricDefId: number) {
    this.store.dispatch(Actions.CurrentProposal
      .addMultiAttribute({
        key: this.collectVisionGoals ? 'vision_goal_metrics' : 'success_metrics',
        value: metricDefId,
        ea: this.activityEA,
        parentEffortAreas: this.parentEffortAreas
      }));
  }

  removeMetric(metricDefId: string) {
    this.store.dispatch(Actions.CurrentProposal
      .deleteMultiAttribute({
        key: this.collectVisionGoals ? 'vision_goal_metrics' : 'success_metrics',
        value: metricDefId,
        ea: this.activityEA,
        parentEffortAreas: this.parentEffortAreas
      }));
  }

  persistCategory(categoryId: number) {
    this.store.dispatch(Actions.CurrentProposal
      .addMultiAttribute({
        key: 'related_categories',
        value: categoryId,
        ea: this.activityEA,
        parentEffortAreas: this.parentEffortAreas
      }));
  }

  removeCategory(categoryId: string) {
    this.store.dispatch(Actions.CurrentProposal
      .deleteMultiAttribute({
        key: 'related_categories',
        value: categoryId,
        ea: this.activityEA,
        parentEffortAreas: this.parentEffortAreas
      }));
  }

  persistDate(attributeName: string) {
    const value = this.form.get('dates').get(attributeName).value;
    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: attributeName, value, ea: this.activityEA }));

    if (attributeName === 'start_date') {
      const dates = this.form.get('dates');
      const minimumEndDate = moment(value);
      const endDate = moment(dates.get('end_date').value);
      if (minimumEndDate.isAfter(endDate)) {
        // Set the end date to the minimum duration
        dates.get('end_date').setValue(value);
      }
    }
  }

  toggleAddPersonForm() {
    this.showAddPersonForm = !this.showAddPersonForm;
    this.add_person_form.reset();
  }

  removePerson(person) {
    this.store.dispatch(Actions.CurrentProposal.deleteMultiAttribute({ key: 'responsible_user_ids', value: person.value, ea: this.activityEA, parentEffortAreas: this.parentEffortAreas }));
  }

  addPerson(person) {
    this.add_person_form.get('name').setValue(undefined);
    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: 'responsible_user_ids', value: person, ea: this.activityEA, parentEffortAreas: this.parentEffortAreas }));
  }

  trackByUserValue(index: number, item: any): number {
    return item.value;
  }

  toggleDeleteActivity() {
    this.showDeleteActivityAlert = !this.showDeleteActivityAlert;
  }

  deleteActivity() {
    this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({ ea: this.activityEA, parentEffortAreas: this.parentEffortAreas }));
    this.toggleDeleteActivity();
  }

  private setSectors(initialize: boolean = false) {
    if (this.collectPrioritySector) {
      if (initialize) {
        this.sectorsOptions = this.sectors;

        if (this.isSWPv3) {
          this.prioritySectorsForm = new FormArray([], this.validateSectors());
        } else {
          if (!this.fund.is_small_program) {
            this.sectorsOptions.unshift(allSectorsOption);
          }
          this.form.addControl('priority_sector', new FormControl(this.activityEA.priority_sector, this.prioritySectorOptional ? undefined : [Validators.required]));
        }
      }

      if (this.isSWPv3) {
        const sectors = this.activityEA.regional_priority_sectors;
        for (let i = 0; i < sectors.length; i++) {
          const sector = sectors[i];
          const formGroup = this.prioritySectorsForm.at(i);
          if (formGroup == null) {
            this.prioritySectorsForm.push(this.initializeSector(sector));
          } else {
            formGroup.patchValue(sector);
          }
        }
      }
    }
  }

  initializeSector(sector) {
    const priority_sector_id = sector.priority_sector_id;
    const formGroup = this._fb.group({
      id: sector.id,
      temp_id: sector.temp_id,
      priority_sector_id: [priority_sector_id, [Validators.required]],
      activity_percentage: [{
        value: sector.activity_percentage, disabled: priority_sector_id == null
      }, [Validators.required, Validators.min(0), Validators.max(100)]]
    });

    if (priority_sector_id == null) {
      formGroup.get('priority_sector_id').valueChanges.pipe(
        filter(s => s != null),
        take(1)
      ).subscribe(() => {
        formGroup.get('activity_percentage').enable();
      });
    } else {
      this.selectedSectors.push(priority_sector_id);
    }

    return formGroup;
  }

  addSector() {
    this.store.dispatch(Actions.CurrentProposal.createTempEffortArea({
      ea: {
        effort_area_type: 'regional_priority_sectors',
        parent_effort_area_id: this.activityEA.id,
        parent_proposal_id: this.activityEA.parent_proposal_id,
        priority_sector_id: undefined,
        activity_percentage: undefined
      },
      parentEffortAreas: [this.activityEA]
    }));
  }

  persistSector(form: FormGroup, attributeName: string) {
    const prioritySectors = this.activityEA.regional_priority_sectors;
    const prioritySector = form.value;
    const previousPrioritySector = find(prioritySectors,
      ps => ps.id === prioritySector.id || (ps.temp_id && ps.temp_id === prioritySector.temp_id));

    if (previousPrioritySector.id < 0 ) {
      // If sector EA is still temp, create an actual one
      this.store.dispatch(Actions.CurrentProposal.createEffortArea({
        ea: {
          ...previousPrioritySector,
          [attributeName]: prioritySector[attributeName]
        },
        parentEffortAreas: [this.activityEA]
      }));
    } else {
      // Otherwise just update the sector attributes
      this.store.dispatch(Actions.CurrentProposal.upsertAttribute({
        ea: previousPrioritySector,
        parentEffortAreas: [this.activityEA],
        key: attributeName,
        value: prioritySector[attributeName]
      }));
    }

    // Update the priority sector dropdown to disable selected sectors
    if (attributeName === 'priority_sector_id') {
      pull(this.selectedSectors, previousPrioritySector.priority_sector_id);
      this.selectedSectors.push(prioritySector.priority_sector_id);
    }
  }

  removeSector(form: FormGroup, index: number) {
    const sector = form.value;
    const deletingSector = find(this.activityEA.regional_priority_sectors, ['id', sector.id]);
    this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({ ea: deletingSector, parentEffortAreas: [this.activityEA] }));
    this.prioritySectorsForm.removeAt(index);
    pull(this.selectedSectors, sector.priority_sector_id);
  }

  validateSectors() {
    const result = (formArray) => {
      let percentageTotal = 0;
      for (const formGroup of formArray.controls) {
        percentageTotal += (Number(formGroup.get('activity_percentage').value) || 0)
      }
      if (percentageTotal !== 100) {
        for (const formGroup of formArray.controls) {
          formGroup.get('activity_percentage').setErrors({ validationError: '' });
        }
        return { validationError: 'Percentages must add up to 100%' };
      } else {
        for (const formGroup of formArray.controls) {
          formGroup.setErrors(null);
        }
        return null;
      }
    }
    return result;
  }

  get showPrioritySectorError() {
    // Only show percentage error if there is at least one percentage value set
    return this.prioritySectorsForm.controls.length > 0
      && some(this.prioritySectorsForm.controls, (control) => control.get('activity_percentage').value != null);
  }

  trackById(index: number, row: any): number {
    const item = row.value;
    return item.temp_id ? item.temp_id : item.id;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
