
import {withLatestFrom} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { WORKFLOW_STEPS, FUND_TYPES, FUND_SETTINGS, BUDGET_TYPES, ACTION_BUTTONS, PROGRAM_KEYS, OBJECT_CODE_TYPES } from '@app-consts';
import { Budget, Allocation } from '@app-models';

import { Store } from '@ngrx/store';
import { Model, State, Queries, Actions } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ProgramService } from '@app-services';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
})
export class BudgetComponent implements OnInit, OnDestroy {
  canEdit = true;
  firstTouch = true;
  proposalId: number;
  estimated_cost: number;
  leadInstitutionId: number;
  leadInstitutionName = '';
  projectType: number;
  isAEBG: boolean;
  isIPlan: boolean;
  isGP: boolean;

  fundYearDurationId: number;
  proposalYearDurationId: number;
  proposalDuration: number;

  institutions: Array<Model.LookupTableMenuOption> = [];
  availableObjectCodes = {};
  budgetItems: Array<Model.BudgetItem>;
  filteredBudgetItems: Array<Model.BudgetItem>;

  pageTitle: string;
  BUDGET_TYPES = BUDGET_TYPES;
  FUND_TYPES = FUND_TYPES;

  // what user can do within this component.
  aclAbilities = {
    editUnrestricted: true,
    restrictedInstitutions: [],
  };
  // budget summary to show if needed.
  summary: any = {};
  totalAllocation = 0;


  years: Array<Object> = [];
  filterYear: FormControl;
  readOnly: false;

  private subscriptions = [];   // Subscriptions to destroy when exiting the view
  private initialized = false;
  private currentWorkflowStep = WORKFLOW_STEPS.BUDGET;
  private workflowStateName = `${this.currentWorkflowStep}_state`;
  private isStepDirty = false;
  // private reportSubmitted = false;
  // private saveReportDisabled = true;
  private defaultFundId: number;
  // private allocations: Model.Allocations;

  constructor(
    private store: Store<State>,
    private router: Router,
    private programService: ProgramService,
  ) {
  }

  ngOnInit() {

    // listen for budgetItems...
    this.subscriptions.push(this.store.select(Queries.Budget.getItems).pipe(withLatestFrom(
      this.store.select(Queries.CurrentProposal.getProposal),
      this.store.select(Queries.LookupTables.getFundsObjectCodes),
      this.store.select(Queries.LookupTables.getObjectCodes),
      this.store.select(Queries.CurrentProposal.getInstitutions),
      this.store.select(Queries.Budget.getAllocations),
      this.store.select(Queries.LookupTables.getYears)
    )).subscribe((data: Array<any>) => {

      const [budget_items, proposal, allFundObjectCodes, objectCodes, proposal_institutions, allocations, years] = data;

      this.proposalYearDurationId = proposal.duration_id;
      this.proposalDuration = proposal.plan_length;

      if (this.filterYear)  {
        this.fundYearDurationId = this.filterYear.value;
      } else {
        this.fundYearDurationId = this.proposalYearDurationId;
      }

      // initialize one time set up code.
      this.Initialize(data);

      if (this.aclAbilities.editUnrestricted) {
        this.budgetItems = budget_items;
      } else {
        // restrict the list to accessible institutions.
        this.budgetItems = budget_items.filter(item => {
          if (!item.institution || _.includes(this.aclAbilities.restrictedInstitutions, item.institution_id)) {
            return item;
          }
        });
      }

      // Reset fundYearDurationId to proposal year
      this.filteredBudgetItems = this.budgetItems;

      if (this.isIPlan) {
        this.calculateIPlanTotals();
      } else if (this.isGP) {
        this.calculateGPTotals();
      }

      if (this.filteredBudgetItems.length === 0 && this.proposalYearDurationId === this.fundYearDurationId) {
        this.addBudgetItem(false);
      } else {
        // Validate all fields and update the CurrentWorkflow state accordingly
        this.store.dispatch(Actions.CurrentWorkflow.validate(this.currentWorkflowStep));
      }
    }));


    // listen for button actions.
    this.subscriptions.push(this.store.select(Queries.Layout.getEmitState).subscribe((emitted: { name: string }) => {
      if (!emitted) {
        return;
      }

      switch (emitted.name) {
        case ACTION_BUTTONS.NEXT:
          this.store.dispatch(Actions.CurrentWorkflow.gotoNext(this.currentWorkflowStep));
          break;
      }
    }));

  } // ngOnInit

  /**
   * Initializes the vars needed to listen for budget changes.
   * @private
   * @param {Array<any>} data
  * */
  private Initialize(data: any) {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    const [budget_items, proposal, allFundObjectCodes, objectCodes, proposal_institutions, allocations, years] = data;

    this.initialized = true;

    // determine first touch flag.
    this.firstTouch = proposal[this.workflowStateName] === 'pristine';
    this.isStepDirty = !this.firstTouch;

    this.proposalId = proposal.id;
    this.projectType = proposal.project_type;
    this.isAEBG = proposal.project_type === FUND_TYPES.AEBG;
    this.isIPlan = proposal.project_type === FUND_TYPES.IPLAN;
    this.isGP = proposal.project_type === FUND_TYPES.GP;
    this.estimated_cost = proposal.estimated_cost;
    this.leadInstitutionId = proposal.lead_institution ? proposal.lead_institution.id : undefined;
    this.leadInstitutionName = proposal.lead_institution ? proposal.lead_institution.name : '';
    if (this.isIPlan) {
      this.defaultFundId = FUND_TYPES.BSI
    } else {
      this.defaultFundId = proposal.fund_ids[0];
    }

    this.institutions = [];

    if (this.isIPlan) {
      // set up the total summary we're to show for iPlan.
      this.summary = {
        sub_total: { label: 'Funds Subtotal', amount: 0, status: '' },
        sub_total_match: { label: 'Funds Match Subtotal', amount: 0, status: '' },
        total: { label: 'Total', amount: 0, status: '' },
      };
      // _.forEach(FUND_SETTINGS[FUND_TYPES.IPLAN].budgetTypeMenuOptions, setting => {
      this.programService.getChildProgramsByParentKey(PROGRAM_KEYS.IPLAN).forEach(fund => {
        this.summary[fund.id] = {
          label: fund.name,
          amount: 0,
          status: '',
        }
      })
    }

    if (this.isGP) {
      // set up total allocations we're to show for GP.
      this.summary = {
        total_allocation: Allocation.getAllocationSum(allocations[this.fundYearDurationId]),
        total_budget: 0,
        total_allocation_percent: 0,
      };
      this.totalAllocation = this.summary.total_allocation;
    }


    // build out the object codes per fund.
    _.forEach(FUND_SETTINGS[this.projectType].collections, fund_id => {
      const { programKey } = FUND_SETTINGS[fund_id];
      this.availableObjectCodes[fund_id] = _.has(allFundObjectCodes, programKey)
        ? allFundObjectCodes[programKey]
        : allFundObjectCodes[OBJECT_CODE_TYPES.DEFAULT];
    });

    this.pageTitle = 'Program Budget';
    this.store.dispatch(Actions.Layout.setActions([
      {
        id: 1,
        type: 'button',
        title: 'Next',
        route: ACTION_BUTTONS.NEXT,
        class: 'primary',
      },
    ]));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  private calculateIPlanTotals() {
    // recalculate totals for iPlan.
    // get fund totals first.
    _.forEach(FUND_SETTINGS[FUND_TYPES.IPLAN].budgetTypeMenuOptions, setting => {
      this.summary[setting.value].amount = Budget.costsFundIdTotal(this.budgetItems, setting.value)
    });
    // set match/non-match indicators.
    if (this.summary[FUND_TYPES.SSSP_CM].amount < this.summary[FUND_TYPES.SSSP_C].amount) {
      this.summary[FUND_TYPES.SSSP_CM].status = 'mismatch';
    } else {
      this.summary[FUND_TYPES.SSSP_CM].status = 'match';
    }

    if (this.summary[FUND_TYPES.SSSP_M].amount < this.summary[FUND_TYPES.SSSP].amount) {
      this.summary[FUND_TYPES.SSSP_M].status = 'mismatch';
    } else {
      this.summary[FUND_TYPES.SSSP_M].status = 'match';
    }
    // calculate funds' sub-total.
    this.summary['sub_total'].amount = this.summary[FUND_TYPES.BSI].amount
      + this.summary[FUND_TYPES.SE].amount
      + this.summary[FUND_TYPES.SSSP_C].amount
      + this.summary[FUND_TYPES.SSSP].amount;
    // calculate match funds' sub-total.
    this.summary['sub_total_match'].amount =
      this.summary[FUND_TYPES.SSSP_CM].amount
      + this.summary[FUND_TYPES.SSSP_M].amount;
    // calculate total.
    this.summary['total'].amount = Budget.costsTypeTotal(this.budgetItems, 'reporting');
  }

  private calculateGPTotals() {
    this.summary.total_budget = Budget.costsTypeTotal(this.budgetItems, 'reporting');
    this.summary.total_allocation_percent = Budget.percentOf(this.summary.total_budget, this.summary.total_allocation);
  }

  addBudgetItem(markDirty: boolean) {

    const budgetItem = new Budget({
      proposal_id: this.proposalId,
      fund_id: this.defaultFundId,
      duration_id: this.fundYearDurationId,
      quarter_4: !this.isAEBG ? 100 : null, // Non-AEBG funds lock the Q4 forecast at 100% which can never change
    });

    if ((this.isAEBG || this.isGP || this.isIPlan) && this.leadInstitutionId) {
      // assign lead college as the default institution.
      budgetItem.setInstitutionId(this.leadInstitutionId);
    }

    this.store.dispatch(Actions.Budget.addBudgetItem(budgetItem.iObject<Model.BudgetItem>()));
    if (markDirty) {
      this.markDirty();
    }
  }

  canCopyBudgetItems() {
    return this.filteredBudgetItems.length === 0
      && this.fundYearDurationId !== this.proposalYearDurationId;
  }

  copyBudgetItemsFromPreviousYear() {
    const yearToCopy = this.fundYearDurationId - 1;

    this.budgetItems.filter((item: Model.BudgetItem) => {
      return item.duration_id === yearToCopy;
    }).forEach((item: Model.BudgetItem) => {
      this.copyBudgetItem(item);
    });
    this.markDirty();
  }

  private copyBudgetItem(budgetItem: Model.BudgetItem) {
    const copiedItem = Object.assign(
      new Budget(budgetItem).serverObject(),
      { duration_id: this.fundYearDurationId, id: null }
    );

    this.store.dispatch(Actions.Budget.addBudgetItem(copiedItem));
    this.markDirty();
  }

  deleteBudgetItem(e) {
    this.store.dispatch(Actions.Budget.deleteBudgetItem(e.id));
    this.markDirty();
  }

  dupeBudgetItem(e) {
    this.store.dispatch(Actions.Budget.addBudgetItem(e));
    this.markDirty();
  }

  itemTouched() {
    this.markDirty();
  }

  markDirty() {
    if (!this.isStepDirty) {
      // mark step as dirty.
      this.isStepDirty = true;
      this.store.dispatch(Actions.CurrentProposal.setStateDirty(this.currentWorkflowStep));
      this.store.dispatch(Actions.Workflow.clearFirstTouch(this.currentWorkflowStep));
    }
  }

  /** Returns the ID property of a generic item, used to uniquify ngFor loops in templates. */
  trackById(index: number, item: any): number {
    return item.id;
  }
}
