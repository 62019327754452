import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Logger } from '../../../app-logger';

@Component({
  selector: 'po-toggle-tabs',
  templateUrl: './toggle-tabs.component.html'
})
export class ToggleTabsComponent implements OnInit {

  @Input() groupName: string; // Each toggle-tab instance on the page needs a unique "groupName"
  @Input() tabs: Array<string> = [];
  @Input() initialTab?: string;
  @Output() tabSelected: EventEmitter<string> = new EventEmitter();

  selectedTab: string;

  ngOnInit() {

    if (!this.groupName) {
      Logger.error('ToggleTabs requires a unique "groupName"');
      this.groupName = Math.round(Math.random() * 1000).toString();
    }

    if (this.initialTab && this.tabs.includes(this.initialTab)) {
      this.selectedTab = this.initialTab;
    } else if (this.tabs.length) {
      this.selectedTab = this.tabs[0];
    }
  }

  emitTabSelected(tab: string) {
    this.tabSelected.emit(tab);
  }
}
