import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State, Queries } from '@app-ngrx-domains'
import { toPayload } from '@app-libs';
import { AttributesService } from '@app-services';
import { CURRENT_PROPOSAL_ACTION_PREFIX, CURRENT_PROPOSAL_ACTION_TYPES } from './current-proposal.action';

/**
 * Injectable effects class.
 */
@Injectable()
export class CurrentProposalAttributesEffects {

  constructor(
    private actions$: Actions,
    private attributesService: AttributesService,
    private store: Store<State>
  ) {
  }

  /**
   * Updates proposal attribute.
   */
   upsertAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.get)),
    mergeMap(([req, proposal]) => {
      return this.attributesService.upsertAttribute$(CURRENT_PROPOSAL_ACTION_PREFIX, req, proposal);
    })
  ));

  /**
   * Updates multiple attributes at once
   */
   upsertAttributes$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_ATTRIBUTES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.get)),
    mergeMap(([req, proposal]) => {
      return this.attributesService.upsertAttributes$(CURRENT_PROPOSAL_ACTION_PREFIX, req, proposal);
    })
  ))

  /**
   * Deletes multiple attributes at once
   */
   deleteAttributes$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.DELETE_ATTRIBUTES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.get)),
    mergeMap(([req, proposal]) => {
      return this.attributesService.deleteAttributes$(CURRENT_PROPOSAL_ACTION_PREFIX, req, proposal);
    })
  ))

  /**
   * Adds proposal multi attribute.
   */
   addMultiAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.ADD_MULTI_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.get)),
    mergeMap(([req, proposal]) => {
      return this.attributesService.addMultiAttribute$(CURRENT_PROPOSAL_ACTION_PREFIX, req, proposal);
    })
  ));

  /**
   * Deletes proposal multi attribute.
   */
   deleteMultiAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.DELETE_MULTI_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.get)),
    mergeMap(([req, proposal]) => {
      return this.attributesService.deleteMultiAttribute$(CURRENT_PROPOSAL_ACTION_PREFIX, req, proposal);
    })
  ));

  /**
   * Creates effort area & an attribute.
   */
   creatAttributeEffortArea$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.CREATE_ATTRIBUTE_EFFORT_AREA),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.creatAttributeEffortArea$(CURRENT_PROPOSAL_ACTION_PREFIX, req);
    })
  ));

  /**
   * Create effort area.
   */
   createEffortArea$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.CREATE_EFFORT_AREA),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.createEffortArea$(CURRENT_PROPOSAL_ACTION_PREFIX, req);
    })
  ));

   createMultiEffortAreas$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.CREATE_MULTI_EFFORT_AREAS),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.createMultiEffortAreas$(CURRENT_PROPOSAL_ACTION_PREFIX, req);
    })
  ));

  /**
   * Updates effort area.
   */
   updateEffortArea$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.UPDATE_EFFORT_AREA),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.updateEffortArea$(CURRENT_PROPOSAL_ACTION_PREFIX, req);
    })
  ));

  /**
   * Deletes effort area.
   */
   deleteEffortArea$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.DELETE_EFFORT_AREA),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.deleteEffortArea$(CURRENT_PROPOSAL_ACTION_PREFIX, req);
    })
  ));

  /**
   * Deletes list of effort areas.
   */
   deleteMultiEffortAreas$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.DELETE_MULTI_EFFORT_AREAS),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.deleteMultiEffortAreas$(CURRENT_PROPOSAL_ACTION_PREFIX, req);
    })
  ));

   cloneEffortAreas$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.CLONE_EFFORT_AREAS),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.cloneEffortAreas$(CURRENT_PROPOSAL_ACTION_PREFIX, req.operations, req.parentEffortAreas);
    })
  ));

  /**
   * Replaces original multi EAs with pending EAs
   */
  applyPendingMultiEffortAreas$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.APPLY_PENDING_MULTI_EFFORT_AREAS),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.applyPendingMultiEffortAreas$(CURRENT_PROPOSAL_ACTION_PREFIX, req);
    })
  ));
}
