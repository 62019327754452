import { WORKFLOW_STEPS } from '@app-consts';

export const REVIEW_WORKFLOW_CONFIG_v2_2020 = {
  [WORKFLOW_STEPS.DESCRIPTION]: {
    showStatus: false,
    scoreCard: {
      title: 'Pathway Identification',
      collectScore: false,
      scoringCriteria: 'This section does not require scoring. Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.AGENCIES]: {
    showStatus: false,
    scoreCard: {
      title: 'Lead & Partner Agencies',
      collectScore: false,
      scoringCriteria: 'This section does not require scoring. Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.PARTNERS]: {
    showStatus: false,
    scoreCard: {
      title: 'Higher Ed. & Collaborative Partners',
      collectScore: false,
      scoringCriteria: 'This section does not require scoring. Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.OBJECTIVES]: {
    showStatus: false,
    scoreCard: {
      title: 'Problem Statement & Project Objectives',
      collectScore: true,
      score: null,
      minScore: 0,
      maxScore: 25,
      scoringCriteria: 'Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.PATHWAY]: {
    showStatus: false,
    scoreCard: {
      title: 'Industry Sectors & Pathways',
      collectScore: false,
      scoringCriteria: 'This section does not require scoring. Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.WORKPLAN]: {
    showStatus: false,
    scoreCard: {
      title: 'Work Plan',
      collectScore: true,
      score: null,
      minScore: 0,
      maxScore: 60,
      scoringCriteria: 'Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    },
    subScores: [
      {
        area: 'funding',
        title: 'A. Work to be funded by K12 SWP',
        collectScore: true,
        score: null,
        minScore: 0,
        maxScore: 20,
        totalScore: 60,
      },
      {
        area: 'artifacts',
        title: 'B. Artifacts of activities',
        collectScore: true,
        score: null,
        minScore: 0,
        maxScore: 5,
        totalScore: 60,
      },
      {
        area: 'role',
        title: 'C. Role of partner community college(s)',
        collectScore: true,
        score: null,
        minScore: 0,
        maxScore: 15,
        totalScore: 60,
      },
      {
        area: 'partners',
        title: 'D. Collaborative partner(s), K-12 partner agency, and/or CTE resources',
        collectScore: true,
        score: null,
        minScore: 0,
        maxScore: 10,
        totalScore: 60,
      },
      {
        area: 'success',
        title: 'E. Indicators of accomplishments',
        collectScore: true,
        score: null,
        minScore: 0,
        maxScore: 10,
        totalScore: 60,
      },
    ]
  },
  [WORKFLOW_STEPS.BUDGET]: {
    showStatus: false,
    scoreCard: {
      title: 'Budget & Match',
      collectScore: true,
      score: null,
      minScore: 0,
      maxScore: 15,
      scoringCriteria: 'Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.DOCUMENTS]: {
    showStatus: false,
    scoreCard: {
      title: 'Supporting Documents',
      collectScore: false,
      scoringCriteria: 'This section does not require scoring. Please use the comments field to describe one strength and one weakness of this section.',
      totalScore: 100,
    }
  },
  [WORKFLOW_STEPS.CONCLUSION]: {
    hide: false,
  }
};
