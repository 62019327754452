import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, withLatestFrom, takeUntil } from 'rxjs/operators';
import { WORKFLOW_STEPS, PROGRAM_KEYS, AREAS } from '@app/core/consts';
import { LookupService, ProgramService, PermissionsService } from '@app/core/services';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { Profile } from '@app/core/models';

@Component({
  selector: 'project-contacts-2',
  templateUrl: './contacts-2.component.html'
})
export class Contacts2Component implements OnInit, OnDestroy {
  inviter = '';
  settings: any = {};
  sections: Array<Model.EAContactSection>;
  canEdit = false;
  contacts: Array<Model.UserRoleScope>;
  lead_id: number;
  @Input() isPreview: boolean = false;

  context: Model.ContactContext;
  proposal: Model.ProposalItem;
  initialized$ = new BehaviorSubject<boolean>(false);

  private destroy$: Subject<boolean> = new Subject();
  private currentWorkflowStep = WORKFLOW_STEPS.CONTACTS;

  constructor(
    private permissionsService: PermissionsService,
    private programService: ProgramService,
    private lookupService: LookupService,
    private route: ActivatedRoute,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.store.select(Queries.Contacts.get).pipe(
      withLatestFrom(
        this.store.select(Queries.CurrentProposal.get),
        this.store.select(Queries.Auth.getCurrentUser)
      ),
      filter(([c, p, u]) => p && p.id && !!c && !!u),
      takeUntil(this.destroy$)
    )
    .subscribe(([contact_items, proposal, user]) => {

      this.contacts = contact_items;
      this.proposal = proposal;

      this.initialize(user);
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private async initialize(user: Model.User) {
    if (this.initialized$.value) {
      // all's been setup.
      return;
    }

    try {
      this.inviter = Profile.contactFullName(user);
      this.lead_id = this.proposal.lead_institution ? this.proposal.lead_institution.id : null;
      if (!this.lead_id) {
        return;
      }

      this.context = {
        proposal_id: this.proposal.id,
        fund_id: this.programService.getParentProgramById(this.proposal.funds[0].id).id,
        fund_key: this.proposal.funds[0].key,
        institution_id: this.lead_id,
        is_legacy_fund: false,
        show_next_button: false,
        inviter: this.inviter,
        approver_institution_id: this.proposal.approver_institution_id,
        lead_institution_id: this.lead_id,
        lead_institution: this.formatInstitution(this.proposal.lead_institution),
        institutions: this.proposal.institutions ? this.proposal.institutions.map(i => this.formatInstitution(i)) : null,
      }

      this.context.is_legacy_fund = [PROGRAM_KEYS.LVG, PROGRAM_KEYS.GP, PROGRAM_KEYS.IPLAN].includes(this.context.fund_key);

      const program = this.programService.programs.find(fund => fund.key === this.context.fund_key);
      if (program && program.program_settings && program.program_settings.contact_sections) {
        this.sections = program.program_settings.contact_sections;
      }

      if (!this.isPreview) {
        this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));
        const extraScope: Model.PermResourceScope = this.lead_id ? { institution_id: this.lead_id } : undefined;
        this.canEdit = await this.permissionsService.canEditProject(this.proposal, AREAS.PROJECT, this.route.snapshot, extraScope).toPromise();
      }

    } finally {
      this.initialized$.next(true);
    }
  }

  private formatInstitution(institution: Model.Institution): Model.SelectOption {
    const option = { value: institution.id, label: '' };
    if (institution.long_name) {
      option.label = institution.long_name;
    } else if (institution.name) {
      option.label = institution.name;
    }
    return option;
  }

  public getUserFullName(user: Model.User) {
    return Profile.contactFullName(user);
  }

  public getRoleName(contact: Model.UserRoleScope): string {
    let name: string;
    if (contact.role) {
      name = contact.role.long_name;
    } else {
      name = this.lookupService.getRoleLongName(contact.role_id) || this.lookupService.getRoleNameForFund(contact.role_id, this.context.fund_id);
    }
    return name;
  }
}
