
<label for="file_upload" [class.required]="required" [attr.data-invalid]="required && !existingFile">{{labelText}}</label>
<div class="file-upload margin-bottom">
  <ng-container *ngIf="canEdit">
    <button type="button" class="action-button action-button--primary action-button--small margin-right-sm"
      [disabled]="statusMessage?.type === 'loading'"
      (click)="uploadInput.click()">
      Choose File
    </button>
    <input #uploadInput id="file_upload" type="file" ng2FileSelect [uploader]="fileUploader" [hidden]="true" #fileUploadInput [accept]="allowedExtensionsExpanded"/>
  </ng-container>

  <div *ngIf="existingFile" class="file-upload__file">
    <a [href]="existingFile.url" target="_blank" class="pre-wrap text-link">{{existingFile.filename}}</a>
    <button *ngIf="canEdit" class="file-upload__clear-button" title="Remove File" (click)="toggleRemoveAlert()">
      <icon iconId="close"></icon>
    </button>
  </div>

  <po-status-message *ngIf="statusMessage" [statusMessage]="statusMessage" [alignLeft]="true"></po-status-message>
</div>

<alert *ngIf="showRemoveAlert"
  button-action="Remove"
  button-dismiss="Cancel"
  level="warning"
  (action)="removeFile()"
  (dismiss)="toggleRemoveAlert()">
  <p>Are you sure you want to remove this document?</p>
</alert>