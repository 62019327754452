/**
 * Barrel all of the guards within this directory
 */
export * from './project.guard';
export * from './composite.guard';
export * from './invoice.guard';
export * from './lmi-cross-sector.guard';
export * from './lookup-tables.resolver';
export * from './program-areas.resolver';
export * from './programs.resolver';
export * from './full-program.resolver';
export * from './project-reviews.resolver';
export * from './workflow.guard';

/*
 * List available core guards
 */
import { ProjectGuard } from './project.guard';
import { CompositeRouteGuard } from './composite.guard';
import { InvoiceGuard } from './invoice.guard';
import { LmiCrossSectorGuard } from './lmi-cross-sector.guard';
import { LookupTablesResolve } from './lookup-tables.resolver';
import { ProgramAreasResolver } from './program-areas.resolver';
import { ProgramsResolver } from './programs.resolver';
import { FullProgramResolver } from './full-program.resolver';
import { ProjectReviewsResolver } from './project-reviews.resolver';
import { WorkflowGuard } from './workflow.guard';

export const ProjectGuards = [
  ProjectGuard,
  CompositeRouteGuard,
  InvoiceGuard,
  LmiCrossSectorGuard,
  LookupTablesResolve,
  ProgramAreasResolver,
  ProgramsResolver,
  FullProgramResolver,
  ProjectReviewsResolver,
  WorkflowGuard
];
