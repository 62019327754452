
import { take, takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, State, Queries, Model } from '@app-ngrx-domains';
import { ValidatorsEx } from '@app-utilities';
import { ApiService, LookupService } from '@app-services';
import { EnumErrorTypes } from '@app-models';
import { AppUtils } from '@app-utilities';
import { CHAR_LIMITS } from '@app/core/consts';

@Component({
  selector: 'app-share-document',
  templateUrl: './share-document.component.html',
})

export class ShareDocumentComponent implements OnDestroy, OnInit {
  formEmail: FormGroup;
  showModal = false;
  currentUserId;
  showSendToSelfAlert = false;
  commentsLength = CHAR_LIMITS.LONG;
  userList: Array<Model.SelectOption> = [];
  private destroy$: Subject<boolean> = new Subject();

  @Input() emailOptions;
  @Input() sharePdfName;
  @Input() pageName = 'Preview';

  constructor(
    private _fb: FormBuilder,
    private apiService: ApiService,
    private lookupService: LookupService,
    private store: Store<State>,
  ) {}

  ngOnInit() {
    this.formEmail = this._fb.group({
      users: [[], [ValidatorsEx.requiredSelection]],
      comments: [],
    });

    // Get the current userId, so we can detect if the user is sending email to themself
    this.store.select(Queries.Auth.getCurrentUserId)
      .pipe(take(1))
      .subscribe(userId => {
        this.currentUserId = userId;
      })

    // listen for button actions
    this.store.select(Queries.Layout.getEmitState)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((emitted) => {
        // open the Share Document modal
        if (emitted && emitted.name === this.sharePdfName) {
          setTimeout(() => this.shareDocument(), 250);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getUserList(filter: string, limit: number) {
    this.lookupService.formattedContactList$(filter, limit)
      .subscribe(
        (res) => {
          this.userList = res;
        },
        (err) => {
          this.store.dispatch(Actions.App.setError({
            type: EnumErrorTypes.api,
            location: this.constructor.name,
            show: true,
            raw: err
          }));
        }
      );
  }

  shareDocument() {
    // reset user selection
    this.formEmail.get('users').setValue([]);
    this.formEmail.get('comments').setValue('');
    // show email dialog
    this.showModal = true;
  }

  submitForm() {
    this.closeModal();
    const users = this.formEmail.get('users').value;
    const recipients = users.map(user => user.value);
    this.emailOptions.queryParams = this.emailOptions.queryParams || {};
    this.emailOptions.queryParams.forPrint = true;
    this.emailOptions.comments = this.formEmail.get('comments').value;

    this.store.dispatch(Actions.Layout.updateHeaderMessage('loading', `Sending document(s)`));
    this.apiService.emailDocument(recipients, this.emailOptions).subscribe(res => {
      this.store.dispatch(Actions.Layout.updateHeaderMessage('success', `Document(s) sent successfully.`));
    },
    error => {
      this.store.dispatch(Actions.Layout.updateHeaderMessage('fail', `Failed to send document(s). Please try again.`));
    });
  }

  closeModal() {
    this.showModal = false;
    this.showSendToSelfAlert = false;
  }

  checkSendToSelf(selectedUserId) {
    if (selectedUserId === this.currentUserId) {
      this.showSendToSelfAlert = true;
    }
  }

  triggerPrint() {
    this.closeModal();
    AppUtils.openBrowserPrintModal();
  }

}
