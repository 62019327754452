import { Injectable } from '@angular/core';
import { take, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, Model, Queries, Actions } from '@app-ngrx-domains';
import { ProgramService } from '@app-services';
import { WORKFLOW_TYPES, WORKFLOW_STEPS } from '@app-consts';
import * as validators from '@app/RCM/validators/workflow.validator';


/**
 * RCM Plan workflow validator service
 */
@Injectable()
export class PlanWorkflowValidatorService {

  constructor(
    private store: Store<State>,
    private programService: ProgramService
  ) {
  }

  /**
   * Validates given workflow and returns validation statuses to be updated.
   * @param workflow
   * @param data
   */
  public validateSteps(
    workflow: Model.Workflow,
    data: {
      program: Model.Fund,
      proposal: Model.RCMProposal,
      proposal_contacts?: Array<Model.UserRoleScope>,
      durations?: Array<Model.Duration>,
      files?: Array<Model.Document>,
      metric_definitions?: Array<any>,
    }): Array<Model.WorkflowStep> {

    const workflow_step_validators = {
      [WORKFLOW_TYPES.RCM_PLAN]: {
        [WORKFLOW_STEPS.DETAILS]: () => validators.validateDetails(data.proposal, data.program.program_settings),
        [WORKFLOW_STEPS.QUALIFICATIONS]: () => validators.validateQualifications(data.proposal),
        [WORKFLOW_STEPS.CONTACTS]: () => validators.validateContacts(data.proposal, data.proposal_contacts),
        [WORKFLOW_STEPS.PROBLEM_STATEMENT]: () => validators.validateProblemStatement(data.proposal),
        [WORKFLOW_STEPS.METRICS]: () => validators.validateMetrics(data.proposal, data.metric_definitions),
        [WORKFLOW_STEPS.RESPONSE_TO_PROBLEM]: () => validators.validateResponseToProblem(data.proposal),
        [WORKFLOW_STEPS.SECTORS]: () => validators.validatePrioritySectors(data.proposal),
        [WORKFLOW_STEPS.OBJECTIVES]: () => validators.validateWorkplanObjectives(data.proposal),
        [WORKFLOW_STEPS.ACTIVITIES]: () => validators.validateWorkplanActivities(data.proposal),
        [WORKFLOW_STEPS.BUDGET]: () => validators.validateBudgets(data.proposal, data.program.program_settings, this.programService),
        [WORKFLOW_STEPS.FORECAST]: () => validators.validateForecasts(data.proposal, data.program.program_settings, this.programService),
        [WORKFLOW_STEPS.DOCUMENTS]: () => validators.validateDocuments(data.proposal, data.files),
      },
      [WORKFLOW_TYPES.RCM_BUDGET_YEARS]: {
        [WORKFLOW_STEPS.BUDGET]: (duration_id?: number) => validators.validateBudget(data.proposal, data.program.program_settings, duration_id, this.programService),
      },
      [WORKFLOW_TYPES.RCM_FORECAST_YEARS]: {
        [WORKFLOW_STEPS.FORECAST]: (duration_id?: number) => validators.validateForecast(data.proposal, data.program.program_settings, duration_id, this.programService),
      },
    }

    // set up validation statuses to return to caller.
    const stepStatuses: Array<Model.WorkflowStep> = [];

    // loop through the steps and validate those steps that can be validated.
    const validatorName = workflow.templateName;

    workflow.steps.filter(ws => ws.showStatus && !ws.hide).forEach(ws => {
      // set step status
      let isValid: boolean;
      if (!!ws.workflow) {
        // if static workflow, then take the validation status as is, otherwise validate if check exists
        if (ws.workflow.static) {
          isValid = ws.valid;
        } else if (!!workflow_step_validators[validatorName][ws.route]) {
          const valid = workflow_step_validators[validatorName][ws.route]();
          isValid = !!valid ? true : ws.valid;
        } else {
          isValid = ws.valid;
        }
      } else if ([WORKFLOW_TYPES.RCM_BUDGET_YEARS, WORKFLOW_TYPES.RCM_FORECAST_YEARS].includes(validatorName)) {
        const validatorRoute = validatorName === WORKFLOW_TYPES.RCM_BUDGET_YEARS 
          ? WORKFLOW_STEPS.BUDGET 
          : WORKFLOW_STEPS.FORECAST;
        isValid = workflow_step_validators[validatorName][validatorRoute](Number(ws.route));
        if (validatorName === WORKFLOW_TYPES.RCM_FORECAST_YEARS) {
          isValid = isValid && workflow_step_validators[WORKFLOW_TYPES.RCM_BUDGET_YEARS][WORKFLOW_STEPS.BUDGET](Number(ws.route));
        }
      } else {
        isValid = workflow_step_validators[validatorName][ws.route]();
      }
      stepStatuses.push({ route: ws.route, valid: isValid });
    });

    return stepStatuses;
  }

  /**
   * Validate entire master workflows once.
   */
  public validatePlanWorkflow() {
    this.store.select(Queries.Workflow.get)
      .pipe(take(1))
      .subscribe(workflows => {

        const validateNestedWorkflow = (parentWorkflow: Model.Workflow) => {
          if (!parentWorkflow || !parentWorkflow.steps || !parentWorkflow.steps.length) {
            // nothing to validate.
            return;
          }

          for (const step of parentWorkflow.steps) {
            if (step.workflow) {
              if (step.workflow.section) {
                // step through the workflows in the section.
                for (const sectionStep of step.steps) {
                  // recurse.
                  validateNestedWorkflow(workflows[sectionStep.workflow.name]);
                }
              } else {
                // recurse.
                validateNestedWorkflow(workflows[step.workflow.name]);
              }
            }
          }

          // validate workflow - bottom up
          this.validateSingleWorkflow(parentWorkflow.name);
        };
      });
  }

  /**
    * Validates single workflow of given name.
    * @param workflowName
    */
  public validateSingleWorkflow(workflowName: string) {
    this.store.select(Queries.Workflow.get)
      .pipe(
        withLatestFrom(
          this.store.select(Queries.CurrentProposal.get),
          this.store.select(Queries.Contacts.get),
          this.store.select(Queries.LookupTables.getDurations),
          this.store.select(Queries.LookupTables.getMetricsDefinitions),
        ),
        take(1)
      )
      .subscribe(([workflows, proposal, proposal_contacts, durations, metric_definitions]) => {

        const workflow = workflows[workflowName];
        const grant = this.programService.getProgramById(proposal.funds[0].id);

        // validate workflow steps
        const workflowStatuses = this.validateSteps(workflow, {
          program: grant,
          proposal,
          proposal_contacts,
          durations,
          metric_definitions,
        });

        // update current workflow statuses.
        this.store.dispatch(Actions.Workflow.updateSteps(workflowStatuses, workflow.name));
      });
  }
}