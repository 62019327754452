import { Model } from '@app-ngrx-domains';
import { WORKFLOW_TYPES, WORKFLOW_STEPS } from '@app-consts';

export const CERTIFICATION_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.PERKINS_APPLICATION_CERTIFICATION,
  templateName: WORKFLOW_TYPES.PERKINS_APPLICATION_CERTIFICATION,
  title: 'Certification',
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: 'cte-1',
      title: 'Allocation Certification',
      showStatus: true,
      valid: false,
    },
    {
      route: 'cte-3',
      title: 'Assurances',
      showStatus: true,
      valid: false,
    },
    {
      route: 'cte-4',
      title: 'Regulations',
      showStatus: true,
      valid: false,
    },
    {
      route: 'cte-6',
      title: 'Target Performance',
      showStatus: true,
      valid: false,
    },
    {
      route: 'cte-7',
      title: 'Actual Performance',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview',
      showStatus: false,
    },
  ]
};
