import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '@app/core/services';
import moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, skip, takeUntil } from 'rxjs/operators';
import { pull } from 'lodash';
import { DashboardFilterEvent, DashboardFilterService } from '@app/core/services/dashboard-filter.service';

@Component({
  selector: 'app-action-items',
  templateUrl: './action-items.component.html'
})
export class ActionItemsComponent implements OnInit, OnDestroy {
  tabs = ['Open', 'Completed'];
  selectedTab = this.tabs[0];
  displayedActionItems: ActionItemsDataSource;
  showDeleted = false;
  itemSize = 66; // The height in pixels of each action item. Required for virtual scrolling
  today = moment().startOf('day');

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private apiService: ApiService,
    private dashboardFilterService: DashboardFilterService
  ) {
    this.displayedActionItems = new ActionItemsDataSource(this.apiService, false, this.showDeleted);
  }

  ngOnInit() {
    this.dashboardFilterService.filter$.pipe(skip(1), takeUntil(this.destroy$))
      .subscribe((filter) => {
        let options;
        if (filter) {
          options = {
            program_ids: filter.program_ids,
            institution_ids: filter.institution_ids,
            event_types: filter.event_types,
            event_names: filter.system_events
          };
        }

        this.displayedActionItems.setFilter(options);
      }
    );
  }

  hide(item) {
    const hide = !item.is_hidden;
    this.apiService.setActionItemVisibility(item.id, hide).subscribe(() => {
      if (this.displayedActionItems.showHidden) {
        item.is_hidden = hide;
      } else {
        this.displayedActionItems.removeItem(item);
      }
    });
  }

  isPastDue(item) {
    return !item.task.completed && moment(item.event_time).isBefore(this.today);
  }

  get isLoading() {
    return this.displayedActionItems.isLoading;
  }

  get isEmpty() {
    return this.displayedActionItems.isEmpty;
  }

  trackById(index, item) {
    return item.id;
  }

  ngOnDestroy() {
    this.displayedActionItems.disconnect();

    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

export class ActionItemsDataSource extends DataSource<string | undefined> {
  isLoading: boolean = false;

  private cachedItems = [];
  private nextPage = 0;
  private initThreshold = 50; // don't pull more items on init if cache count exceeds this number
  private rangeEnd = this.initThreshold;
  private itemBuffer = 75; // amount of items remaining that will trigger the next call
  private dataStream = new BehaviorSubject<any>(this.cachedItems);
  private subscription = new Subscription();
  private currentFilter: DashboardFilterEvent;

  constructor(private apiService: ApiService, private completed: boolean, public showHidden: boolean = false) {
    super();
  }

  connect(collectionViewer: CollectionViewer) {
    this.subscription.add(
      collectionViewer.viewChange.pipe(
        debounceTime(500),
      ).subscribe(range => {
        this.rangeEnd = range.end;

        // Try fetching the next page in case we're reaching the end of the list
        this.fetchNextPage();
      })
    );

    return this.dataStream;
  }

  updateItems() {
    this.cachedItems = [];
    this.nextPage = 0;
    this.rangeEnd = this.initThreshold;
    this.dataStream.next(this.cachedItems);
    return this.fetchNextPage();
  }

  fetchNextPage() {
    // Pull more items from the service if there are more
    if (!this.isLoading && Number.isInteger(this.nextPage) && (this.rangeEnd + this.itemBuffer > this.cachedItems.length)) {

      this.isLoading = true;
      const options = {
        page: this.nextPage,
        completed: this.completed,
        show_hidden: this.showHidden,
        ...this.currentFilter || {}
      };

      return this.apiService.getActionItems(options).subscribe(response => {
        this.cachedItems = this.cachedItems.concat(response.action_items);
        this.nextPage = response.next_page_number;

        this.dataStream.next(this.cachedItems);
        this.isLoading = false;
      });
    }
  }

  selectTab(tab: string) {
    this.completed = tab === 'Completed';
    this.updateItems();
  }

  toggleHidden() {
    this.showHidden = !this.showHidden;
    this.updateItems();
  }

  removeItem(item) {
    pull(this.cachedItems, item);
    this.dataStream.next(this.cachedItems);
  }

  setFilter(filter) {
    this.currentFilter = filter;
    this.updateItems();
  }

  disconnect() {
    this.subscription.unsubscribe();
    this.isLoading = false;
  }

  get isEmpty() {
    return !this.isLoading && !this.cachedItems.length;
  }
}