import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from "@angular/router";
import { Actions, Queries, State } from "@app-ngrx-domains";
import { ROUTER_LINKS } from "@app/core/consts";
import { Utilities } from "@app/core/models";
import { LookupService, PermissionsService, ProgramService } from "@app/core/services";
import { Store } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { Observable } from "rxjs";
import { skipWhile, withLatestFrom, take } from "rxjs/operators";

@Injectable()
export class InvoiceGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private lookupService: LookupService,
    private permissionsService: PermissionsService,
    private programService: ProgramService,
    private store: Store<State>,
  ) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {
      this.logger.debug(`[invoice-guard][${state.url}] checking...`);

      // check if there's an invoice id
      const invoiceId = Number(route.params['invoiceId']);
      if (invoiceId) {
        this.store.dispatch(Actions.Invoices.getInvoice(invoiceId));
      } else {
        // if no invoice id param, then load the full list of invoices
        const parentKey = Utilities.programKeyFromRoute(state.url);
        const parentProgram = this.programService.getProgramByKey(parentKey);
        if (!parentProgram) {
          subscriber.next(false);
          subscriber.complete();
          return;
        }
        let fundId = parentProgram.id;
        this.store.dispatch(Actions.Invoices.get(fundId));
      }

      this.logger.debug(`[invoice-guard][${state.url}] loading invoice data...`);

      this.store.select(Queries.Invoices.isLoaded)
        .pipe(
          skipWhile(isLoaded => !isLoaded),
          // withLatestFrom(this.store.select(Queries.Invoice.get)),
          take(1)
        ).subscribe(() => {
          this.permissionsService.canGoTo(route.data.routeCheck, route).subscribe(check => {
            if (check.allowed) {
              this.logger.debug(`[invoice-guard][${state.url}] has access to route`);
              subscriber.next(true);
              subscriber.complete();
            } else {
              this.logger.debug(`[invoice-guard][${state.url}] does not have access & has no rerouting url.`);
              this.router.navigate([ROUTER_LINKS.PAGE_ACCESS_DENIED], { queryParams: { badUrl: state.url } });
            }
            subscriber.next(false);
            subscriber.complete();
          });
        }, (error) => {
          // failed to load invoice data.
          this.router.navigate([ROUTER_LINKS.HOME]);
          subscriber.error(false);
          subscriber.complete();
        });
    });
  }
}