import { Injectable } from "@angular/core";
import { State, Actions as DomainActions, Model } from "@app-ngrx-domains";
import { ApiService } from "@app/core/services";
import { toPayload } from "@app/libs";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { map, mergeMap, catchError, finalize } from "rxjs/operators";
import { INVOICES_ACTION_TYPES } from "./invoices.action";

@Injectable()
export class InvoicesEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) { }

  /**
   * Get list of invoices
   */
  @Effect() listInvoices$ = this.actions$.pipe(
    ofType(INVOICES_ACTION_TYPES.GET),
    map(toPayload),
    mergeMap(req => {
      // Send get request to API
      return this.apiService.listInvoices(req.fundId).pipe(
        map(response => DomainActions.Invoices.load(response)),
        catchError(error => of(DomainActions.Invoices.serviceFail(error))
      ));
    })
  );

  /**
   * Get invoice by id
   */
  @Effect() getInvoice$ = this.actions$.pipe(
    ofType(INVOICES_ACTION_TYPES.GET_INVOICE),
    map(toPayload),
    mergeMap(itemId => {
      // Send get request to API
      return this.apiService.getInvoiceById(itemId).pipe(
        map(response => DomainActions.Invoices.getInvoiceSuccess(response)),
        catchError(error => of(DomainActions.Invoices.serviceFail(error))
      ));
    })
  );

  /**
   * Create invoice.
   */
   @Effect() createInvoice$ = this.actions$.pipe(
    ofType(INVOICES_ACTION_TYPES.CREATE_INVOICE),
    map(toPayload),
    mergeMap(item => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.createInvoice(item).pipe(
        map(response => DomainActions.Invoices.createInvoiceSuccess(response)),
        catchError(error => of(DomainActions.Invoices.serviceFail(error))),
        finalize(() => this.store.dispatch(DomainActions.Layout.showBusySpinner(false)))
      );
    })
  );

  /**
   * Updates invoice.
   */
  @Effect() updateInvoice$ = this.actions$.pipe(
    ofType(INVOICES_ACTION_TYPES.UPDATE_INVOICE),
    map(toPayload),
    mergeMap(item => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.updateInvoice(item).pipe(
        map(response => DomainActions.Invoices.updateInvoiceSuccess(response)),
        catchError(error => of(DomainActions.Invoices.serviceFail(error))),
        finalize(() => this.store.dispatch(DomainActions.Layout.showBusySpinner(false)))
      );
    })
  );

  /**
   * Updates invoice from a list.
   */
   @Effect() updateInvoices$ = this.actions$.pipe(
    ofType(INVOICES_ACTION_TYPES.UPDATE_INVOICES),
    map(toPayload),
    mergeMap(item => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.updateInvoice(item).pipe(
        map(response => DomainActions.Invoices.updateInvoicesSuccess(response)),
        catchError(error => of(DomainActions.Invoices.serviceFail(error))),
        finalize(() => this.store.dispatch(DomainActions.Layout.showBusySpinner(false)))
      );
    })
  );

  /**
   * Submits invoice.
   */
   @Effect() submitInvoice$ = this.actions$.pipe(
    ofType(INVOICES_ACTION_TYPES.SUBMIT_INVOICE),
    map(toPayload),
    mergeMap(itemId => {
      // updating existing invoice
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.submitInvoice(itemId).pipe(
        map((response: Model.EAInvoice) => DomainActions.Invoices.get(response.fund_id, true)),
        catchError(error => of(DomainActions.Invoices.serviceFail(error))),
        finalize(() => this.store.dispatch(DomainActions.Layout.showBusySpinner(false)))
      );
    })
  );

  /**
   * Marks invoice as paid.
   */
   @Effect() markInvoiceAsPaid$ = this.actions$.pipe(
    ofType(INVOICES_ACTION_TYPES.MARK_AS_PAID),
    map(toPayload),
    mergeMap(itemId => {
      // updating existing invoice
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.markInvoiceAsPaid(itemId).pipe(
        map(response => DomainActions.Invoices.updateInvoicesSuccess(response)),
        catchError(error => of(DomainActions.Invoices.serviceFail(error))),
        finalize(() => this.store.dispatch(DomainActions.Layout.showBusySpinner(false)))
      );
    })
  );

  /**
   * Deletes invoice.
   */
  @Effect() deleteInvoice$ = this.actions$.pipe(
    ofType(INVOICES_ACTION_TYPES.DELETE_INVOICE),
    map(toPayload),
    mergeMap(item => {
      return this.apiService.deleteInvoice(item).pipe(
        map(response => DomainActions.Invoices.deleteInvoiceSuccess(item)),
        catchError(error => of(DomainActions.Invoices.serviceFail(error)))
      );
    })
  )
}