import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { ValidatorsEx } from '@app-utilities';

@Component({
    selector: 'app-assurance-card',
    templateUrl: './assurance-card.component.html'
})
export class AssuranceCardComponent implements OnInit, AfterViewInit{
  @Input() set assurances(value: Array<Model.EAAssurance>) {
    if (!value || !value.length) {
      this._assurances = [];
    } else {
      this._assurances = value;
    }

    if (this.proposal) {
      this.initialize();
    }
  };

  get assurances() {
    return this._assurances;
  }
  @Input() parentEA: any;
  @Input() canEdit: boolean = false;
  @Input() firstTouch: boolean = true;
  @Input() isPreview: boolean = false;
  @Input() showCardInPreview: boolean = false;

  proposal = undefined;
  form: FormGroup;
  initialized: boolean = false;
  _assurances: Array<Model.EAAssurance>;

  private destroy$: Subject<boolean> = new Subject();
  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    if (this.isPreview) {
      // detach this component from the change detections
      this.cdr.detach();
    }
  }

  ngOnInit() {
    this.store.select(Queries.CurrentProposal.get)
    .pipe(takeUntil(this.destroy$))
    .subscribe((proposal) => {
      this.proposal = proposal;

      // initialize one time set up code.
      this.initialize();
    });
  }

  initialize() {
    if (this.initialized || !this.assurances.length) {
      // all's been setup.
      return;
    }

    this.initialized = true;

    let assuranceIds = this.proposal.assurance_ids;
    if (this.parentEA) {
      assuranceIds = this.parentEA.assurance_ids;
    }

    const assuranceFormDefinitions: any = {};
    this.assurances.forEach((assurance, index) => {
      const isSet = assuranceIds.map(a => a.value).includes(assurance.id);
      assuranceFormDefinitions['item' + index] = [isSet, [Validators.required, ValidatorsEx.requiredCheck]];
    });

    this.form = this._fb.group({
      assurances: this._fb.group(assuranceFormDefinitions)
    });
  }

  /**
   * Persist assurance response.
   * @param index into programAssurances
  */
  persistAssuranceChange(index: number) {
    const isChecked = this.form.get('assurances.item' + index).value;
    const assuranceId = this.assurances[index].id;
    if (isChecked) {
      this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: 'assurance_ids', value: assuranceId, ea: this.parentEA }));
    } else {
      this.store.dispatch(Actions.CurrentProposal.deleteMultiAttribute({ key: 'assurance_ids', value: assuranceId, ea: this.parentEA }));
    }
  }

  fileIconType(file) {
    if (file.filename.endsWith('.pdf')) {
      return 'file-pdf';
    } else {
      return 'document';
    }
  }
}