import { Component, Input } from '@angular/core';
import { Model } from '@app-ngrx-domains';
import { Profile } from '@app/core/models';
import { LookupService } from '@app/core/services';

@Component({
  selector: 'app-contact-cards',
  templateUrl: './contact-cards.component.html',
})
export class ContactCardsComponent {

  @Input() contacts: Array<Model.UserRoleScope>;
  @Input() programKey: string;
  @Input() wide = false;

  constructor(
    private lookupService: LookupService,
  ) { }

  getUserFullName(user: Model.User) {
    return Profile.contactFullName(user);
  }

  getRoleName(contact: Model.UserRoleScope): string {
    return this.lookupService.getRoleNameForFund(contact.role_id, this.programKey);
  }
}
