import { of, concat } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { State, Queries, Actions as DomainActions } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { CFAD_ACTION_TYPES } from './cfad.action';

/**
 * Container class for allocation effects calls.
 */
@Injectable()
export class CfadEffects {

  /**
   * Creates an instance of effects class.
   * @param {Actions} actions$
   * @param {ApiService} service
   * @param {Store} store
   */
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>,
  ) {
  }

   refresh$ = createEffect(() => this.actions$.pipe(
    ofType(CFAD_ACTION_TYPES.REFRESH),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Cfad.getId)),
    mergeMap(data => {
      const [req, cfadId] = data;
      return this.apiService.getCfad(cfadId).pipe(
        mergeMap(res => {
          // bundle actions.
          const actions: Action[] = [];
          // refresh cfad.
          actions.push(DomainActions.Cfad.refreshSuccess(res));
          actions.push(DomainActions.Layout.showBusySpinner(false));
          if (req.gotoUrl) {
            // goto requested route.
            actions.push(DomainActions.App.go([req.gotoUrl]));
          }
          // Dispatch actions. These are executed in order.
          return concat([
            ...actions
          ]);
        }
      ));
    }),
    catchError((error) => {
      // stop the spinner.. encountered an error.
      this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
      return of(DomainActions.Cfad.serviceFail(error));
    })
  ));

   updateState$ = createEffect(() => this.actions$.pipe(
    ofType(CFAD_ACTION_TYPES.UPDATE_STATE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Cfad.get)),
    mergeMap(data => {
      const [__, cfad] = data;
      const taskId = cfad.client_state.task_id;
      const client_state = {
        ...cfad.client_state,
        ...__,
      };
      delete client_state.task_id;

      return this.apiService.updateTask(taskId, client_state).pipe(mergeMap(
        res => {
          return of(DomainActions.Cfad.updateStateSuccess(res));
        }
      ));
    }),
    catchError((error) => {
      return of(DomainActions.Cfad.serviceFail(error));
    })
  ));

  /**
   * Updates cfad field.
   */
   updateField$ = createEffect(() => this.actions$.pipe(
    ofType(CFAD_ACTION_TYPES.UPDATE_FIELD),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Cfad.get)),
    mergeMap(data => {
      const [req, currentCfad] = [...data];

      const updateCfad = {};
      updateCfad['id'] = currentCfad.id;
      updateCfad[req.name] = req.value;

      if (!(currentCfad[req.name] === updateCfad[req.name])) {
        // persist changes.
        return this.apiService.updateCfad(updateCfad).pipe(
          mergeMap(() => {
            // bundle actions.
            const actions: Action[] = [];
            // send out updated action.
            actions.push(DomainActions.Cfad.updateSuccess(req));
            // Dispatch actions. These are executed in order.
            return concat([
              ...actions
            ]);
          }),
          catchError((error) => of(DomainActions.Cfad.serviceFail(error)))
        );
      } else {
        // nothing to update.
        return of(DomainActions.Cfad.noOp());
      }
    }),
    catchError((error) => of(DomainActions.Cfad.serviceFail(error)))
  ));
}
