import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload, AttributeActions } from '@app-libs';
import { EnumErrorTypes, Proposal, LVGProposal, IPlanProposal, ProposalBase } from '@app-models';
import { PROGRAM_KEYS } from '@app-consts';

/**
 * List of proposal action types
 */
export const CURRENT_PROPOSAL_ACTION_PREFIX = 'CURRENT_PROPOSAL_';
export const CURRENT_PROPOSAL_ACTION_TYPES = {
  // ********** Action Types Managed by Parent/AttributeActions Class ********
  UPSERT_ATTRIBUTE: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPSERT_ATTRIBUTE`,
  UPSERT_ATTRIBUTES: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPSERT_ATTRIBUTES`,
  DELETE_ATTRIBUTES: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_ATTRIBUTES`,
  ADD_MULTI_ATTRIBUTE: `${CURRENT_PROPOSAL_ACTION_PREFIX}ADD_MULTI_ATTRIBUTE`,
  DELETE_MULTI_ATTRIBUTE: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_MULTI_ATTRIBUTE`,
  CREATE_ATTRIBUTE_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}CREATE_ATTRIBUTE_SUCCESS`,
  UPSERT_ATTRIBUTE_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPSERT_ATTRIBUTE_SUCCESS`,
  UPSERT_ATTRIBUTES_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPSERT_ATTRIBUTES_SUCCESS`,
  DELETE_ATTRIBUTE_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_ATTRIBUTE_SUCCESS`,
  DELETE_ATTRIBUTES_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_ATTRIBUTES_SUCCESS`,
  CREATE_EFFORT_AREA: `${CURRENT_PROPOSAL_ACTION_PREFIX}CREATE_EFFORT_AREA`,
  CREATE_EFFORT_AREA_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}CREATE_EFFORT_AREA_SUCCESS`,
  CREATE_MULTI_EFFORT_AREAS: `${CURRENT_PROPOSAL_ACTION_PREFIX}CREATE_MULTI_EFFORT_AREAS`,
  CREATE_MULTI_EFFORT_AREAS_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}CREATE_MULTI_EFFORT_AREAS_SUCCESS`,
  UPDATE_EFFORT_AREA: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPDATE_EFFORT_AREA`,
  UPDATE_EFFORT_AREA_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPDATE_EFFORT_AREA_SUCCESS`,
  DELETE_EFFORT_AREA: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_EFFORT_AREA`,
  DELETE_EFFORT_AREA_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_EFFORT_AREA_SUCCESS`,
  CREATE_ATTRIBUTE_EFFORT_AREA: `${CURRENT_PROPOSAL_ACTION_PREFIX}CREATE_ATTRIBUTE_EFFORT_AREA`,
  CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS`,
  APPEND_ITEM: `${CURRENT_PROPOSAL_ACTION_PREFIX}APPEND_ITEM`,
  DELETE_ITEM_FILE: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_ITEM_FILE`,
  DELETE_ITEM_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_ITEM_SUCCESS`,
  CLONE_EFFORT_AREAS: `${CURRENT_PROPOSAL_ACTION_PREFIX}CLONE_EFFORT_AREAS`,
  DELETE_MULTI_EFFORT_AREAS: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_MULTI_EFFORT_AREAS`,
  DELETE_MULTI_EFFORT_AREAS_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_MULTI_EFFORT_AREAS_SUCCESS`,
  APPLY_PENDING_MULTI_EFFORT_AREAS: `${CURRENT_PROPOSAL_ACTION_PREFIX}APPLY_PENDING_MULTI_EFFORT_AREAS`,
  APPLY_PENDING_MULTI_EFFORT_AREAS_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}APPLY_PENDING_MULTI_EFFORT_AREAS_SUCCESS`,
  // ********** Action Types Managed by Parent/AttributeActions Class ********

  GET: `${CURRENT_PROPOSAL_ACTION_PREFIX}GET`,
  GET_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}GET_SUCCESS`,
  GET_FAIL: `${CURRENT_PROPOSAL_ACTION_PREFIX}GET_FAIL`,
  SET_ISLOADED: `${CURRENT_PROPOSAL_ACTION_PREFIX}SET_ISLOADED`,
  TOGGLE_ALL_SECTOR: `${CURRENT_PROPOSAL_ACTION_PREFIX}TOGGLE_ALL_SECTOR`,
  COMPLETE_TASK: `${CURRENT_PROPOSAL_ACTION_PREFIX}COMPLETE_TASK`,
  UNDO_TASK: `${CURRENT_PROPOSAL_ACTION_PREFIX}UNDO_TASK`,
  TASK_UPDATE_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}TASK_UPDATE_SUCCESS`,
  COMPLETE_TASK_AND_REFRESH: `${CURRENT_PROPOSAL_ACTION_PREFIX}COMPLETE_TASK_AND_REFRESH`,
  REFRESH_TASKS: `${CURRENT_PROPOSAL_ACTION_PREFIX}REFRESH_TASKS`,
  REFRESH_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}REFRESH_SUCCESS`,
  UPSERT_INSTITUTION: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPSERT_INSTITUTION`,
  REMOVE_INSTITUTION: `${CURRENT_PROPOSAL_ACTION_PREFIX}REMOVE_INSTITUTION`,
  REMOVE_INSTITUTION_AND_RELATIONS: `${CURRENT_PROPOSAL_ACTION_PREFIX}REMOVE_INSTITUTION_AND_RELATIONS`,
  REMOVE_AGENCY: `${CURRENT_PROPOSAL_ACTION_PREFIX}REMOVE_AGENCY`,
  UPSERT_INSTITUTION_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPSERT_INSTITUTION_SUCCESS`,
  REMOVE_INSTITUTION_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}REMOVE_INSTITUTION_SUCCESS`,
  SET_STATE_DIRTY: `${CURRENT_PROPOSAL_ACTION_PREFIX}SET_STATE_DIRTY`,
  CREATE_CLOSE: `${CURRENT_PROPOSAL_ACTION_PREFIX}CREATE_CLOSE`,
  REOPEN_PROPOSAL: `${CURRENT_PROPOSAL_ACTION_PREFIX}REOPEN_PROPOSAL`,
  UPDATE_CLIENT_STATE: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPDATE_CLIENT_STATE`,
  UPSERT_COMMENT: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPSERT_COMMENT`,
  UPSERT_COMMENT_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}UPSERT_COMMENT_SUCCESS`,
  DELETE_COMMENT: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_COMMENT`,
  DELETE_COMMENT_SUCCESS: `${CURRENT_PROPOSAL_ACTION_PREFIX}DELETE_COMMENT_SUCCESS`,
  SERVICE_FAIL: `${CURRENT_PROPOSAL_ACTION_PREFIX}SERVICE_FAIL`,
  NOOP: `${CURRENT_PROPOSAL_ACTION_PREFIX}NOOP`,
};

/**
 * Proposal action creator class
 */
export class CurrentProposalActions extends AttributeActions {

  constructor() {
    super(CURRENT_PROPOSAL_ACTION_PREFIX);
  }

  /**
   * Sets the is_loaded flag
   *
   * @returns {Action}
   */
  setIsLoaded(): Action {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.SET_ISLOADED
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any, location?: string): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: location ? location : CURRENT_PROPOSAL_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  /**
   * Starts get of proposal action.
   *
   * @param {number} id
   * @param {number?} institution_id
   * @param {boolean} addToProposals
   * @returns {Action}
   */
  get(id: number, institution_id?: number, addToProposals: boolean = false): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.GET,
      payload: { id, institution_id, addToProposals }
    };
  }

  /**
   * Dispatches get was success action.
   */
  getSuccess(proposal: any): ActionWithPayload<any> {
    const programKey = ProposalBase.getProgramKey(proposal);
    const fund = proposal.funds[0];

    // transform proposal data based on model key type.
    let data: any;
    switch (programKey) {
      case PROGRAM_KEYS.LVG:
        data = new LVGProposal(proposal).iObject<Model.LVGProposal>();
        break;

      case PROGRAM_KEYS.IPLAN:
        data = new IPlanProposal(proposal).iObject<Model.IPlanProposal>();
        break;

      case PROGRAM_KEYS.GP:
        if (fund.key === PROGRAM_KEYS.GP_2) {
          data = proposal;
        } else {
          data = new Proposal(proposal).iObject<Model.ProposalItem>();
        }
        break;

      case PROGRAM_KEYS.SWP_L:
        if ([PROGRAM_KEYS.SWPL_v2, PROGRAM_KEYS.SWPL_v3].includes(fund.key)) {
          data = proposal;
        } else {
          data = new Proposal(proposal).iObject<Model.ProposalItem>();
        }
        break;

      case PROGRAM_KEYS.SWP_R:
        if ([PROGRAM_KEYS.SWPR_v2, PROGRAM_KEYS.SWPR_v3].includes(fund.key)) {
          data = proposal;
        } else {
          data = new Proposal(proposal).iObject<Model.ProposalItem>();
        }
        break;

      case PROGRAM_KEYS.CAEP:
        data = new Proposal(proposal).iObject<Model.ProposalItem>();
        break;

      case PROGRAM_KEYS.CAI:
      case PROGRAM_KEYS.EWD:
      case PROGRAM_KEYS.NEP:
      case PROGRAM_KEYS.SEP:
      case PROGRAM_KEYS.RSI:
      case PROGRAM_KEYS.FISCAL_AGENTS:
      case PROGRAM_KEYS.PERKINS:
      case PROGRAM_KEYS.SWP_K12:
      case PROGRAM_KEYS.RCM:
      default:
        // no transformation is needed... assign as is.
        data = proposal;
        break;

    }

    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.GET_SUCCESS,
      payload: data
    };
  }

  /**
   * Dispatches get failed action.
   */
  getFail(error: any): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.GET_FAIL,
      payload: error
    };
  }

  completeTask(task: any): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.COMPLETE_TASK,
      payload: task
    };
  }

  undoTask(task: any, refresh?: boolean): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.UNDO_TASK,
      payload: { task, refresh }
    };
  }

  taskUpdateSuccess(task: any): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.TASK_UPDATE_SUCCESS,
      payload: task
    };
  }

  completeTaskAndRefresh(task: any, completeRefresh = false): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.COMPLETE_TASK_AND_REFRESH,
      payload: { task, completeRefresh }
    };
  }

  refreshTasks(): Action {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.REFRESH_TASKS,
    };
  }

  refreshTasksSuccess(proposal: any): ActionWithPayload<any> {
    const refresh = {};
    const possibleFields = [
      'state_id', 'statuses', 'states', 'tasks',
      'submit_task', 'approve_tasks', 'close_task',
      'perkins_sub_applications'
    ];

    possibleFields.forEach(field => {
      if (proposal[field]) {
        refresh[field] = proposal[field];
      }
    });

    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.REFRESH_SUCCESS,
      payload: refresh,
    };
  }

  /**
   * Fires off a task to close the current proposal
   * @param formValues contains close_date (string), finished (boolean),
   *                   fully_expended (boolean), and comments (string)
   * @returns {Action}
   */
  close(formValues: any): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.CREATE_CLOSE,
      payload: formValues
    };
  }

  reopen(comments?: any): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.REOPEN_PROPOSAL,
      payload: {
        comments: comments,
      }
    };
  }


  /************** Multi-select events ****************/

  upsertInstitution(institution_id: number, attributes: {is_lead?: boolean, is_employer?: boolean, is_inactive?: boolean}): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_INSTITUTION,
      payload: {
        institution_id,
        attributes
      }
    };
  }

  removeInstitution(institution_id: number, reselectFunction?: any): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_INSTITUTION,
      payload: {
        institution_id: institution_id,
        reselect_function: reselectFunction || null
      }
    };
  }

  removeInstitutionAndRelations(institution_id: number, is_lead: boolean, new_institution_id?: number): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_INSTITUTION_AND_RELATIONS,
      payload: {
        institution_id: institution_id,
        is_lead,
        new_institution_id,
      }
    };
  }

  removeAgency(institution_id: number, options: any, successFunction?: any): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_AGENCY,
      payload: {
        institution_id,
        options,
        confirm_success: successFunction || null
      }
    };
  }

  upsertInstitutionSuccess(institution: any, attributes: {is_lead?: boolean, is_employer?: boolean, is_inactive?: boolean}): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_INSTITUTION_SUCCESS,
      payload: {
        institution,
        attributes
      }
    };
  }

  removeInstitutionSuccess(institution_id: number): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_INSTITUTION_SUCCESS,
      payload: {
        institution_id: institution_id
      }
    };
  }

  toggleAllSector(enable: boolean = false) {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.TOGGLE_ALL_SECTOR,
      payload: enable
    };
  }


  /************** Set State Actions ****************/
  /**
   * Sets current proposal's workflow state to be dirty.
   *
   * @param {any} step
   * @returns {Action}
   */
  setStateDirty(step: any): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.SET_STATE_DIRTY,
      payload: step
    };
  }

  updateClientState(state: any): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.UPDATE_CLIENT_STATE,
      payload: state,
    };
  }


  upsertComment(resource: Model.Resource, comment: string, noteId?: number): ActionWithPayload<any> {
    const note = {
      type: 'note',
      comment: comment
    };

    if (noteId) {
      note['id'] = noteId;
    }

    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_COMMENT,
      payload: { resource, note }
    };
  }

  upsertCommentSuccess(comment: Model.ResourceNote): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_COMMENT_SUCCESS,
      payload: comment
    };
  }

  deleteComment(id: number): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.DELETE_COMMENT,
      payload: id
    };
  }

  deleteCommentSuccess(id: number): ActionWithPayload<any> {
    return {
      type: CURRENT_PROPOSAL_ACTION_TYPES.DELETE_COMMENT_SUCCESS,
      payload: id
    }
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.CurrentProposal = new CurrentProposalActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    CurrentProposal: CurrentProposalActions;
  }
}
