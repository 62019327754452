import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_TIME_MOMENT_FORMAT_STRINGS } from '@app/core/consts';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import * as moment from 'moment';

const MONTH_DAY_YEAR_FORMATS = {
  parse: {
    dateInput: 'MM/DD'
  },
  display: {
    dateInput: 'MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'po-repeat-date-input',
  templateUrl: './repeat-date-input.component.html',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_DAY_YEAR_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RepeatDateInputComponent), multi: true }
  ],
})

export class RepeatDateInputComponent extends DatePickerComponent {
  @Input() placeHolder: string =  'mm/dd';

  getReadOnlyText() {
    if (this.inputControl) {
      return moment(this.inputControl.value).format(DATE_TIME_MOMENT_FORMAT_STRINGS.monthDay);
    }
  }
}
