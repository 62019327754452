import { trigger, style, transition, animate } from '@angular/animations';

export const fadeOutAnimation = trigger('fadeInOut', [
  transition(':enter', [
    style({
      opacity: 0
    }),
    animate('200ms',
      style({
        opacity: 1
      }))
  ]),
  transition(':leave', [
    style({
      opacity: 1
    }),
    animate('200ms',
      style({
        opacity: 0
      }))
  ])
]);
