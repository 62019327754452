import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Model } from '@app-ngrx-domains';
import { ROUTER_LINKS } from '@app/core/consts';
import { Resource } from '@app/core/models';
import { ApiService, ProgramService } from '@app/core/services';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
})
export class RedirectComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private programService: ProgramService
  ) {}

  ngOnInit() {
    const resourceId = this.route.snapshot.queryParams['resourceId'];

    if (resourceId) {
      this.handleResourceRedirect(resourceId);
    }
  }

  handleResourceRedirect(resourceId: number) {
    this.apiService.getResourceById(resourceId).subscribe(resource => {

      if (resource.route) {
        const queryString = resource.duration_id ? `?duration=${resource.duration_id}` : '';
        const url = `${resource.route}${queryString}`;
        // use navigateByUrl because fiscal reports were ignoring the duration
        this.router.navigateByUrl(url);
      } else {
        let fundId: number;
        let program: Model.Fund;

        if (resource.fund_id) {
          fundId = resource.fund_id;
        } else if (resource.proposal) {
          fundId = resource.proposal.fund_ids[0];
        }

        if (fundId) {
          program = this.programService.getProgramById(fundId);
        }

        const { route, queryParams } = Resource.resourceLink(resource, program) || {};
        this.router.navigate([route || ROUTER_LINKS.HOME], queryParams);
      }
    }, (error) => {
      this.router.navigate([ROUTER_LINKS.HOME]);
    });
  }
}
