import { Model } from '@app-ngrx-domains';
import { DEFAULT_STEP_OPTIONS, WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';

// #####################  SWP_K12 v1 Letter of Intent Workflow  ###################
export const LOI_WORKFLOW_CONFIG_v1: Model.Workflow  = {
  name: WORKFLOW_TYPES.SWP_K12_v1_LOI,
  baseLink: undefined, // will be assigned by the builder
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.DESCRIPTION,
      title: 'Details',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.LEAD_AGENCY,
      title: 'Lead Agency',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.AGENCIES,
      title: 'Partner Agencies',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    },
  ]
};

// #####################  SWP_K12 v1 Application Workflow  ###################
export const APPLICATION_WORKFLOW_CONFIG_v1: Model.Workflow = {
  name: WORKFLOW_TYPES.SWP_K12_v1_APPLICATION,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.DESCRIPTION,
      title: 'Summary',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.AGENCIES,
      title: 'Lead & Partner Agencies',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.PARTNERS,
      title: 'Collaborative Partners',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.NEEDS,
      title: 'Statement of Need',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.TARGET,
      title: 'Target Pupil',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.PATHWAY,
      title: 'Pathway Improvement Strategies',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.SOW,
      title: 'Statement of Work',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.CAPABILITY,
      title: 'Capability & Sustainability',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.BUDGET,
      title: 'Budget',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.DOCUMENTS,
      title: 'Supporting Documents',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    },
    {
      route: WORKFLOW_STEPS.CONCLUSION,
      title: 'Review Summary & Assessment',
      ...DEFAULT_STEP_OPTIONS,
      hide: true,
    },
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      showStatus: true,
      hide: true,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    },
  ]
};
