import {take} from 'rxjs/operators';
import { Component, Input, Output, OnInit, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { LookupService } from '@app-services';
import { Store } from '@ngrx/store';
import { State, Model, Queries } from '@app-ngrx-domains';
import { Document, Profile } from '@app-models';

interface IColumns {
  title?: boolean;
  type?: boolean;
  source_type?: boolean;
  year?: boolean;
  user?: boolean;
  time?: boolean;
  comment?: boolean;
  actions?: boolean;
  institution?: boolean;
};
const DEFAULT_COLUMNS: IColumns = {
  title: true,
  type: true,
  source_type: false,
  year: true,
  user: false,
  time: true,
  comment: true,
  actions: true,
  institution: false
};

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
})
export class DocumentTableComponent implements OnInit {
  @ViewChild('empty', { static: true }) emptyState: TemplateRef<any>;

  @Input() enableUpload?: boolean; // Displays the Add File button
  @Input() enableHidePastVersions?: boolean; // Enables show/hide button for older files with matching type, fund, inst and year
  @Input() enableHideOldFiles?: boolean; // Enables show/hide button for files with year < oldFilesCutoffYearDurationId
  @Input() oldFilesCutoffYearDurationId?: number; // Use with enableHidOldFiles
  @Input() titleAsSource?: boolean = false // Swaps the Document Title heading with Source
  @Input() columnTitleOverrides: { [column: string]: string } = {};
  @Input() formatDocTitleFn?: (IDocument) => string; // Formatter function for document title
  @Input() canDeleteFn?: (IDocument) => boolean;
  @Input() replaceableFileTypes: Array<number> = [];
  @Input() set fileList(list) { // Setter construction makes table data update immediately when input files changes.
    this.initFiles(list);
  }
  @Input() set columnsToShow(columns: IColumns) { // Columns default to on. To turn off, ex: {type: false, actions: true}.
    this._columnsToShow = { ...DEFAULT_COLUMNS, ...columns }; // Build a new object so we don't mutate the source object.
  }
  @Input() emptyTemplate?: TemplateRef<any>;
  @Input() hideAddButton?: boolean = false // Conditionally hides the add button, used to prevent adding file on temp EA
  @Input() noMargin?: boolean = false;
  @Output() addDocumentClicked: EventEmitter<null> = new EventEmitter();
  @Output() deleteDocumentClicked: EventEmitter<number> = new EventEmitter();
  @Output() replaceDocumentClicked: EventEmitter<number> = new EventEmitter();
  private _fileList = [];
  private _displayList;
  private _oldfilesCutoffYearDurationId: number; // Initialized to oldFilesCutoffYearDurationId, and can get set lower
  _columnsToShow: IColumns = DEFAULT_COLUMNS;
  docTypes = [];

  hidingOldFiles;
  hidingPastVersions;
  oldFilesExist;
  pastVersionsExist;

  showDeleteConfirmation = false;
  showReplaceConfirmation: Model.Document;
  fileToDelete: Model.Document;

  constructor(
    private lookupService: LookupService,
    private store: Store<State>
  ) { }

  ngOnInit() {
    // Set default values for the @Input()s
    // The setter structure for @Input() fileList doesn't seem to catch the other @Inputs' defaults,
    // so we set the default values here.
    this.enableUpload = !!this.enableUpload;
    this.enableHidePastVersions = !!this.enableHidePastVersions;
    this.enableHideOldFiles = !!this.enableHideOldFiles;
    this.oldFilesCutoffYearDurationId = this.oldFilesCutoffYearDurationId || 2001; // Default to 2001 as the earliest year to show files.

    // If hiding old files/versions is enabled, start with them hidden.
    this.hidingOldFiles = this.enableHideOldFiles;
    this.hidingPastVersions = this.enableHidePastVersions;

    if (!this.emptyTemplate) {
      this.emptyTemplate = this.emptyState;
    }

    // Now init the starting file list using the intialized filters.

    this.store.select(Queries.LookupTables.getDocumentTypes)
      .pipe(take(1))
      .subscribe((docTypes) => {
        this.docTypes = docTypes;
        this.initFiles(this._fileList);
      })
  }

  initFiles(files) {
    this._oldfilesCutoffYearDurationId = this.oldFilesCutoffYearDurationId;
    this._fileList = Document.processHtmlDupeFiles(files);
    this._fileList = this.identifyOldDocumentVersions(this._fileList);
    this._displayList = this.filterDisplayList(this._fileList);
  }

  /**
   * Computes if each file is a past version or not, then compute the oldFilesExist & pastVersionsExist flags
   * @param {Array<any>} files input list. ITEMS ARE MUTATED with new properties.
   * @returns {Array<any>} the transformed files list
   */
  identifyOldDocumentVersions(filesInput: Array<any>): Array<any> {
    const files = _.cloneDeep(filesInput);

    // Flag whether each file is latest revision & find the oldest file year
    let newestFileYearDurationId = 0;
    files.forEach(f => {
      f.created_at = f.created_at || '0'; // This is a kludge to accommodate null values, so they sort to the bottom. Used by isDateUndefined()
      f.isPastVersion = !!_.find(files, (f2: any) => {
        if ((f.document_type_id === f2.document_type_id) && // compare only files of same type
          (f.fund_id === f2.fund_id) &&   // compare only files of same year
          (f.institution_id === f2.institution_id) && // compare only files of same institution
          (f.duration_id === f2.duration_id) &&   // compare only files of same year
          (f.created_at < f2.created_at)) { // MATCH if current file is younger than the reference file
          return true;
        } else {
          return false;
        }
      });
      if (f.duration_id > newestFileYearDurationId) { // Find the latest year within the fileset
        newestFileYearDurationId = f.duration_id;
      }
    });

    // If the latest file year is less than the cutoff, then set the cutoff to the year of the latest file
    if (newestFileYearDurationId < this._oldfilesCutoffYearDurationId) {
      this._oldfilesCutoffYearDurationId = newestFileYearDurationId;
    }
    this.oldFilesExist = !!_.find(files, (f: any) => (f.duration_id < this._oldfilesCutoffYearDurationId));
    this.pastVersionsExist = !!_.find(files, (f: any) => (f.isPastVersion));

    // sort the list.
    return _.orderBy(files, ['duration_id', 'created_at'], ['desc', 'desc']);
  }

  /**
   * Generates the filtered list of files to display in the table, based on the currently active filter options.
   * If the current filter set
   * @param files
   */
  filterDisplayList(files: Array<any>): Array<any> {
    // If there are no files to begin with, then get out now.
    if (!files || !files.length) {
      return [];
    }

    // Filter out old files or past version, based on appropriate filters
    const filteredList = files.filter(file => {
      if (this.hidingOldFiles && file.duration_id < this._oldfilesCutoffYearDurationId) {
        return false;
      }
      if (this.hidingOldFiles && !file.duration_id) { // If missing duration_id, assume the file is old
        return false;
      }
      if (this.hidingPastVersions && file.isPastVersion) {
        return false;
      }

      return true;
    });

    // If for any reason the resulting list has no entries, return the original list so we display SOMEthing
    if (!filteredList.length) {
      return files;
    }

    return filteredList;
  }

  onClickAddDocument() {
    this.addDocumentClicked.emit();
  }
  onDeleteIconClick(file) {
    this.fileToDelete = file;
    this.showDeleteConfirmation = true;
  }
  closeDeleteConfirmation() {
    this.showDeleteConfirmation = false;
    delete this.fileToDelete;
  }
  confirmDeleteFile() {
    this.deleteDocumentClicked.emit(this.fileToDelete.id);
    this.closeDeleteConfirmation();
  }

  replaceFileClicked(file: Model.Document) {
    this.showReplaceConfirmation = file;
  }
  confirmReplaceFile() {
    this.replaceDocumentClicked.emit(this.showReplaceConfirmation.id);
    this.showReplaceConfirmation = null;
  }

  toggleOldFiles() {
    this.hidingOldFiles = !this.hidingOldFiles;
    this._displayList = this.filterDisplayList(this._fileList);
  }

  toggleOldVersions() {
    this.hidingPastVersions = !this.hidingPastVersions;
    this._displayList = this.filterDisplayList(this._fileList);
  }

  /**
   * Formats the document table's title field. The first available option wins, in order of precedence:
   * 1) formatTitleFn function, if present; 2) `title` field; 3) `document_type.name`; 4) Default to `filename`
   * @param file
   */
  formatTitle(file) {
    if (!file) {
      return 'N/A';
    }

    if (this.formatDocTitleFn) {
      return this.formatDocTitleFn(file)
    } else if (file.title) {
      return file.title;
    } else if (file.document_type) {
      return file.document_type.name;
    } else {
      return file.filename;
    }
  }

  formatDocType(document_type_id) {
    return this.docTypes.find(dt => dt.id === document_type_id).name;
  }

  formatDateString(date) {
    const d = new Date(date);
    return (d.toLocaleDateString() + ', ' + d.toLocaleTimeString());
  }

  formatYearName(yearDurationId) {
    const duration = this.lookupService.durations.find(d => d.id === yearDurationId);
    return duration ? duration.name : 'Unknown';
  }

  formatUserName(user: Model.User) {
    return Profile.contactFullName(user);
  }

  getInstitutionName(institutionId: number): string {
    return this.lookupService.getInstitutionName(institutionId);
  }

  getIconId(file) {
    if (!!file.filename.trim().match(/\.pdf$/i)) {
      return 'pdf';
    } else if (!file.uploaded) {
      return 'link';
    } else {
      return 'document';
    }
  }

  getColumnTitle(column: string, defaultTitle: string) {
    return this.columnTitleOverrides[column] || defaultTitle;
  }

  get displayOldFilesButton() {
    return this.enableHideOldFiles && this.oldFilesExist && this.oldFilesCutoffYearDurationId;
  }
  get displayPastVersionsButton() {
    return this.enableHidePastVersions && this.pastVersionsExist;
  }
  get displayOptionsButtonGroup() {
    return this.displayOldFilesButton || this.displayPastVersionsButton;
  }
  get optionsButtonGroupHasMultipleButtons() {
    return this.displayOldFilesButton && this.displayPastVersionsButton;
  }
  get files() {
    return this._displayList;
  }

  canReplace(file) {
    return this.replaceableFileTypes && this.replaceableFileTypes.includes(file.document_type_id);
  }

  canDelete(file) {
    try {
      const docType = this.docTypes.find(dt => dt.id === file.document_type_id);
      if (!docType.deletable) {
        return false;
      }

      return !!this.canDeleteFn ? this.canDeleteFn(file) : true;
    } catch (e) {
      return false;
    }
  }
  isDateDefined(date) {
    return date > '0';
  }
}