import { Model } from '@app-ngrx-domains';
import { DEFAULT_STEP_OPTIONS, WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';
import { WORKFLOW_METRICS } from './sep.const';

export const ANNUAL_REPORT_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.SEP_ANNUAL_REPORT,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.EXPENDITURES,
      title: 'Expenditures',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.SPENDING,
      title: 'Category Spending',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.METRICS,
      title: 'Metrics & Outcomes',
      ...DEFAULT_STEP_OPTIONS,
      workflow: {
        name: 'Metrics & Outcomes',
        templateName: WORKFLOW_TYPES.SEP_ANNUAL_REPORT_METRICS,
        static: true
      }
    },
    {
      route: WORKFLOW_STEPS.SUCCESS,
      title: 'Success Story',
      optional: true,
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.CHALLENGES,
      title: 'Challenges',
      optional: true,
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    }
  ]
};

export const ANNUAL_REPORT_METRIC_SUBWORKFLOW: Model.Workflow = {
  name: WORKFLOW_TYPES.SEP_ANNUAL_REPORT_METRICS,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.SUMMARY,
      title: 'Summary of Metrics & Outcomes',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false
    },
    ...WORKFLOW_METRICS.map(metric => {
      return {
        route: metric.value,
        title: metric.label,
        ...DEFAULT_STEP_OPTIONS
      };
    })
  ]
};
