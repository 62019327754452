import { ModelBase } from './model.base';
import * as _ from 'lodash';
import { Model } from '@app-ngrx-domains';
import { DocumentType } from './document-type';
import { Utilities } from './utilities';

const DUPE_TIME_RANGE = 2000; // Files outside this range +/- cannot be considered an equivalent duplicate in different format

export interface IDocument {
  id?: number;
  filename?: string;
  document_type_id?: number;
  document_type?: Model.DocumentType;
  url?: string;
  comment?: string;
  title?: string;
  fund_id?: number;
  proposal_id?: number;
  institution_id?: number;
  duration_id?: number;
  creator_user_id?: number;
  update_user_id?: number;
  message_template_id?: number;
  effort_area_id?: number;
  creator_task_id?: number;
  created_at?: Date;
}

export class Document extends ModelBase implements IDocument {
  id: number;
  filename: string;
  document_type_id: number;
  document_type: Model.DocumentType;
  url: string;
  comment?: string;
  title?: string;
  fund_id: number;
  proposal_id: number;
  institution_id: number;
  duration_id: number;
  creator_user_id: number;
  message_template_id: number;
  effort_area_id: number;
  creator_task_id?: number;
  created_at: Date;

  constructor(raw?) {
    super();
    if (raw) {
      this.id = raw.id;
      this.filename = raw.filename;
      this.document_type_id = raw.document_type_id;
      this.document_type = raw.document_type;
      this.url = raw.url;
      this.comment = raw.comment;
      this.title = raw.title;
      this.fund_id = raw.fund_id;
      this.proposal_id = raw.proposal_id;
      this.institution_id = raw.institution_id;
      this.duration_id = raw.duration_id;
      this.creator_user_id = raw.creator_user_id;
      this.message_template_id = raw.message_template_id;
      this.effort_area_id = raw.effort_area_id;
      this.creator_task_id = raw.creator_task_id;
      this.created_at = raw.created_at;
    }
  }

  /**
   * Returns latest version of a file of given type.
   * @static
   * @param {Array<IDocument>} files
   * @param {number} documentTypeId
   */
  static getLatestFile(files: Array<IDocument>, documentTypeId: number) {
    let latest: Date;
    let matchedDx = -1;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.document_type_id === documentTypeId) {
        if (!latest || file.created_at > latest) {
          latest = file.created_at;
          matchedDx = i;
        }
      }
    }

    if (matchedDx >= 0) {
      return {
        ...files[matchedDx],
        uploaded_date: Utilities.convertDateToString(files[matchedDx].created_at)
      };
    }
  }

  /**
   * Returns files of given document type.
   * @param files
   * @param documentTypeId
   */
  static getFiles(files: Array<IDocument>, documentTypeId: number): Array<any> {
    let matched = _.filter(files, (file: any) => (file.document_type_id === documentTypeId)).map(file => ({
      ...file,
      uploaded_date: Utilities.convertDateToString(file.created_at),
    }));
    matched = _.sortBy(matched, ['title', 'uploaded_date']);
    return matched;
  }

  /**
   * Processes the array of input documents, some of which might be duplicate content in HTML form.
   * HTML dupes are removed, and the URL for the HTML version is stored on its non-HTML sibling.
   * @param files
   * @return {Array<any>} a new array of files, mutated from the input files array
   */
  static processHtmlDupeFiles(filesInput): Array<any> {
    // TODO MJM: The PDF-to-HTML file matching logic probably belongs on the Service side.
    //           It's strange for this system logic to be isolated to the client.
    const outputFiles = [];
    const fileList = _.cloneDeep(filesInput);

    // Identify all HTML files (ie, have '.html' extension) and mark with isHmtl attribute
    fileList.forEach(f => f.isHtml = !!f.filename.match(/\.html?$/) );

    // Go through all files and try to merge each with HTML dupes
    _.forEach(fileList, (f) => {
      if ( !f.isHtml ) {
        Document.mergeFileWithHtmlDupe(f, fileList);
        outputFiles.push(f);
      }
    });

    // At this point, all files have been marked with isHtml and (where applicable) isHtmlDupe.
    // Any HTML files not marked as a dupe are unique, and therefore should be included in the output.
    _.forEach(fileList, f => {
      if (f.isHtml && !f.isHtmlDupe) {
        outputFiles.push(f);
      }
    })

    return outputFiles;
  }

  /**
   * Given a file and a list of files, looks for an HTML equivalent version in the list.
   * If a match is found, sets fileItem.htmlUrl on the given file object, and isHtmlDupe on the found file
   * @param fileItem
   * @param fileList
   * @return void - Results are stored directly on the fileItem and members of fileList.
   */
  static mergeFileWithHtmlDupe(fileItem, fileList): void {
    // If the given file is an HTML file, then it can't have an HTML dupe. Get out.
    if (fileItem.isHtml) {
      return;
    }

    // Get the base of the filename. Ex: 'filename.pdf' ==> 'filename'
    const getFilenameBase = (filename) => {
      const base = filename || '';
      // If base has a '.' strip off the extension. Otherwise use the whole filename as the base.
      return base.match(/\./) ? base.match(/(.*)\./)[1] : base;
    }

    const fnameBase = getFilenameBase(fileItem.filename);
    const timeRangeMin = fileItem.created_at ? (new Date(fileItem.created_at)).valueOf() - DUPE_TIME_RANGE : null;
    const timeRangeMax = fileItem.created_at ? (new Date(fileItem.created_at)).valueOf() + DUPE_TIME_RANGE : null;
    // Filter to get list of any matching HTML files.
    const htmlMatchFile = _.filter(fileList, refFile => {
      if (!refFile.isHtml) { // Non-html files are obviously not a match. Get out.
        return false;
      }
      if (refFile.duration_id !== fileItem.duration_id) { // If years don't match it's clearly not the same content.
        return false;
      }
      if (getFilenameBase(refFile.filename) !== fnameBase) { // If the filenames don't match
        return false;
      };

      // If we have a created_at stamp, but the ref file isn't within 1000ms of it
      if (fileItem.created_at &&
        ( (new Date(refFile.created_at)).valueOf() < timeRangeMin || (new Date(refFile.created_at).valueOf() > timeRangeMax) )
      ) {
        return false;
      };

      return true;
    })[0]; // Use the first item, if it exists. (There should be 0 or 1 at max)

    if (htmlMatchFile) {
      htmlMatchFile.isHtmlDupe = true; // Mark this item as a dupe so we can identify it later.
      fileItem.htmlUrl = htmlMatchFile.url; // Merge the HTML URL onto the given fileItem
    }
  }

  /**
   * Given a list of files and a file type, return the file of said type
   * @param files
   * @param documentTypeId The NOVA id of the document type
   * @param fileType Ex: 'html', 'pdf'
   * @return {IDocument} File of given type
   */
  static getFileOfType(files: Array<IDocument>, documentTypeId: number, fileType: string) {
    const regex = new RegExp(`.${fileType}$`, 'i');
    return Document.getFiles(files, documentTypeId).find(file => !!file.filename.trim().match(regex));
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Document = IDocument;
  }
}
