import { Model } from '@app-ngrx-domains';
import { CHAR_LIMITS } from '../consts';
import { Utilities } from '../models/utilities';
import * as moment from 'moment';

export class Validate {

  static hasMinimumChars(value: string, min = CHAR_LIMITS.NARRATIVE_MIN): boolean {
    return (value && value.length >= min);
  }

  static stringWithinRange(value: string, min = CHAR_LIMITS.NARRATIVE_MIN, max = CHAR_LIMITS.MEDIUM): boolean {
    return (value && value.length >= min && value.length <= max);
  }

  static htmlHasMinimumChars(value: string, min = CHAR_LIMITS.NARRATIVE_MIN, isOptional = false): boolean {
    if (!isOptional && !value) {
      return false;
    }

    const text = Utilities.stripTags(value);
    if (!text.length && isOptional) {
      return true;
    }

    return text.length >= min;
  }

  static isUntouched(value: any): boolean {
    return value === undefined || value === null || value === '';
  }

  static isTouched(value: any): boolean {
    return !Validate.isUntouched(value);
  }

  static isUrl(value: string): boolean {
    const URL_REGEXP = /^((?:http|ftp)s?:\/\/)?(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i;

    return value && URL_REGEXP.test(value);
  }

  static hasContacts(contacts: Array<Model.UserRoleScope>, roleId: number, institutionId?: number) {
    if (!contacts || !contacts.length) {
      return false;
    }

    return contacts.find(c => c.role_id === roleId && (!institutionId || c.institution_id === institutionId)) ? true : false;
  }

  static hasContributions(contributions: Array<Model.EAApplicationContribution>, institutionId: number, required?: Array<string>) {
    if (!contributions || !contributions.length) {
      return false;
    }

    const contrib = contributions.find(c => !Utilities.isTempId(c.id) && c.institution_id === institutionId);
    if (!contrib) {
      return false;
    }

    if (required && required.length && !required.every(key => !Validate.isUntouched(contrib[key]))) {
      return false;
    }

    return true;
  }

  /**
   *  Validates that the endDate is at least minDuration ahead of the startDate
   * @param startDate String in date format (2019-01-02T08:00:00.000Z)
   * @param endDate String in date format (2019-01-02T08:00:00.000Z)
   * @param minDurationYear
   */
  static meetsMinDuration(startDate: string, endDate: string, minDurationYear = 0) {
    const minEndDate = moment(startDate).add(minDurationYear, 'years');
    const endMoment = moment(endDate);

    return !minEndDate.isAfter(endMoment);
  }

  static zipCode(zipcode: string) {
    const ZIPCODE_REGEXP =  /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return ZIPCODE_REGEXP.test(zipcode);
  }
}
