import { filter, debounceTime, takeUntil, withLatestFrom, startWith } from 'rxjs/operators';
import {
  AfterContentInit, ChangeDetectorRef, AfterViewInit, Component, ContentChild, EventEmitter,
  Input, OnDestroy, Output, ViewChild, HostBinding, TemplateRef
} from '@angular/core';
import { ResultsListComponent } from '../results-list/results-list.component';
import { InputRefDirective } from '../../directives';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, Subject, interval, combineLatest } from 'rxjs';
import { Logger } from '../../../app-logger';
import { NOT_ENTERED } from '@app-consts';
import { Model } from '@app-ngrx-domains';

@Component({
  selector: 'po-select',
  templateUrl: './po-select.component.html'
})

export class POSelectComponent implements AfterViewInit, AfterContentInit, OnDestroy {

  searchValue = '';
  searchInputForm: FormGroup;
  selectedOptions$: BehaviorSubject<Model.SelectOption[]> = new BehaviorSubject([]);
  selectedOption$: BehaviorSubject<Model.SelectOption> = new BehaviorSubject(null);
  selectAllOption = { label: 'Select All', value: -5 };

  @Input() id: string;
  @Input() disabledOptions: number[] = [];
  @Input() labelText: string;
  @Input() labelHidden = false;
  @Input() placeholderText = '';
  @Input() selectMultiple = false;
  @Input() selectOnce = false;
  @Input() isSearch = false;
  @Input() canEdit = true;
  @Input() identifier;
  @Input() sortByLabel: boolean = false;
  @Input() enableSelectAll = false;
  @Input() removeAlertTmpl: TemplateRef<any>;

  @Input() inline: boolean = false;

  @Input() disableComments: boolean = false;

  @Input() emptyOptionsText: string; // Message to display when results-list options are empty. Overrides default value
  @Input() hideEmptyOptionsText: boolean = false;

  // For displaying additional "empty state" functionality i.e. "Invite New User"
  @Input() emptyOption: Model.SelectOption;
  @Input() emptyText: string;
  @Input() emptyIcon: string;
  @Input() showEmpty: boolean = false;
  showEmptyButton = false;

  @Input() set options(ops: Model.SelectOption[]) {
    this._options = this.enableSelectAll ? [this.selectAllOption, ...ops] : ops;
    this.nullIsValid = this._options.some(option => option.value === null);
    this.handleOptionsChange();
  }
  get options() {
    return this._options;
  }
  _options: Model.SelectOption[] = [];

  @HostBinding('attr.data-invalid') invalidState = 'false';
  @HostBinding('attr.id') hostId;
  @HostBinding('class.po-select--spaced') @Input() spaced = false;
  @ViewChild(InputRefDirective, {static: true}) searchInput: InputRefDirective;
  @ViewChild('resultsList') resultsList: ResultsListComponent;
  @ContentChild(InputRefDirective, {static: true}) selectInput: InputRefDirective;

  @Output() search = new EventEmitter<string>();
  @Output() optionSelected = new EventEmitter();
  @Output() optionRemoved = new EventEmitter();

  private destroy$: Subject<boolean> = new Subject();
  private nullIsValid: boolean;
  disabled = false;
  searchKeySubscription: Subscription;
  searchPasteSubscription: Subscription;
  notEnteredText = NOT_ENTERED;
  showAlert = false;
  optionToRemove: Model.SelectOption;
  validityStatus: boolean;

  constructor (
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.searchInputForm = this.formBuilder.group({
      searchInput: ['']
    });
  }

  ngAfterContentInit() {
    if (!this.id) {
      Logger.error('A unique ID is required in po-select component:');
      Logger.log(this.selectInput);
    } else {
      this.hostId = this.id;
    }

    if (!this.labelText) {
      Logger.error('Label text required in po-select component:');
      Logger.log(this.selectInput);
    } else if (this.inline) {
      this.labelText += ':';
    }

    this.selectInput.element.id = this.id + '_field';

    // Watch validation changes in case control has async validators
    this.selectInput.control.statusChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(status => {
      if (['VALID', 'INVALID'].includes(status) && this.validityStatus !== status) {
        this.validityStatus = status;
        this.handleValidation();
      }
    });
    this.setSelections(this.selectInput.control.value);
  }

  ngAfterViewInit() {
    this.displayInputValues();
    this.watchFormValues();
    this.watchDisabled();

    this.checkValidation();
    if (this.isSearch) {
      this.searchInit();
    }

    if (this.selectOnce) {
      this.searchInput.focusSubject.pipe(
        takeUntil(this.destroy$)
      ).subscribe(focus => {
        if (focus != null) {
          this.handleSearchFocus(focus);
        }
      });
    }
  }

  showLabel() {
    return this.labelText && !this.labelHidden;
  }

  private searchInit() {
    this.searchInput.focusSubject.pipe(
      takeUntil(this.destroy$)
    ).subscribe((focused) => {
      if (focused) {
        this.searchKeySubscription = this.searchInput.keyUpSubject.asObservable()
          .pipe(
            debounceTime(100),
            withLatestFrom(this.selectedOptions$, this.selectedOption$),
            filter(([event, options, option]) => {
              return this.shouldPerformSearch(event, options, option);
            })
          ).subscribe(() => {
            this.performSearch();
          });
        this.searchPasteSubscription = this.searchInput.pasteSubject.asObservable()
          .pipe(filter(pasteEvent => !!pasteEvent))
          .subscribe(() => {
            this.performSearch();
          });
      } else {
        if (this.searchKeySubscription)  {
          this.searchKeySubscription.unsubscribe();
          this.searchKeySubscription = undefined;
        }
        if (this.searchPasteSubscription)  {
          this.searchPasteSubscription.unsubscribe();
          this.searchPasteSubscription = undefined;
        }
      }
    });
  }

  private setSelections(formValue) {
    if (formValue == null && (!this.nullIsValid || formValue !== null)) {
      return;
    }

    if (this.isSearch) {
      this.selectMultiple ? this.selectedOptions$.next(formValue) : this.selectedOption$.next(formValue);
    } else if (this.options) {
      if (this.selectMultiple) {
        const selections = this.options.filter(option => {
          return formValue.find(value => {
            return value === option.value;
          });
        });

        this.selectedOptions$.next(selections);
      } else {
        const selection = this.options.find(option => {
          return formValue === option.value;
        });

        this.selectedOption$.next(selection);
      }
    }

    this.resetFocus();
    this.handleValidation();
  }

  private displayInputValues() {
    if (this.selectMultiple) {
      this.searchInput.control.control.setValue('');
    } else {
      const selection = this.selectedOption$.value;
      if (selection != null) {
        this.setSearchInputValue(selection.label, true);
      } else {
        this.setSearchInputValue('', false);
      }
    }

    this.resetFocus();
    this.handleValidation();
  }

  private watchFormValues() {
    this.selectInput.control.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(formValue => {
      this.checkValidation();
      if (formValue != null || (this.nullIsValid && formValue === null)) {
        this.setSelections(formValue);
      } else {
        this.selectedOption$.next(null);
        this.selectedOptions$.next(null);
      }
      this.displayInputValues();
    });
  }

  private updateInputValues() {
    if (this.selectInput.control.control) {
      if (this.selectMultiple) {
        let selectedValues;
        if (this.isSearch) {
          selectedValues = this.selectedOptions$.value;
        } else {
          selectedValues = this.selectedOptions$.value
            .map( option => {
              return option.value;
            });
        }
        this.selectInput.control.control.setValue(selectedValues);
      } else {
        const selection = this.selectedOption$.value != null ? this.selectedOption$.value : null;
        if (selection != null) {
          this.selectInput.control.control.setValue(this.isSearch ? selection : selection.value);
        } else {
          this.selectInput.control.control.setValue(selection);
        }
      }
    }
  }

  private watchDisabled() {
    if (this.selectInput.control.disabled) {
      this.toggleDisabled(true);
    }

    this.selectInput.control.statusChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(status => {
      this.toggleDisabled(status === 'DISABLED');
    });
  }

  private toggleDisabled(disabled) {
    this.disabled = disabled;
    this.disabled ? this.searchInput.control.control.disable() : this.searchInput.control.control.enable();
  }

  private handleOptionsChange() {
    if (!this.isSearch) {
      if (this.selectInput && this.selectInput.control) {
        this.setSelections(this.selectInput.control.value);
        this.handleSearchFocus(false);
      }
    }
  }

  private handleValidation() {
    if (!this.selectInput.element) {
      return;
    }

    setTimeout(() => {
      const validator = this.selectInput.element.getAttribute('aria-required') ? Validators.required : [];
      if (this.selectInput.control.errors) {
        this.searchInputForm.controls['searchInput'].setValidators(validator);
      } else {
        this.searchInputForm.controls['searchInput'].clearValidators();
      }
      this.searchInputForm.controls['searchInput'].updateValueAndValidity();
      this.checkValidation();
      this.cdr.detectChanges();

      this.searchInput.focusSubject.pipe(
        takeUntil(this.destroy$)
      ).subscribe( (focus) => {
        if (focus) {
          this.selectInput.control.control.markAsTouched();
        }
      });
    });
  }

  private handleSearchFocus(focus) {
    if (focus) {
      this.setSearchInputValue('', false);
    } else {
      const selectedOption = this.selectedOption$.value;
      if (selectedOption != null) {
        this.setSearchInputValue(selectedOption.label, true);
      }
    }
  }

  private setSearchInputValue(text: string, readOnly: boolean) {
    if (this.searchInput.control.control) {
      this.searchInput.control.control.setValue(text);
    }
    if (this.searchInput.element) {
      this.searchInput.element.readOnly = readOnly;
    }
  }

  private resetFocus() {
    if (this.searchInput.element) {
      this.searchInput.element.blur();
    }
  }

  @Output() emptySelected = new EventEmitter();
  doSelectEmpty() {
    this.emptySelected.emit();
  }

  select(option) {
    if (this.enableSelectAll && option.value === this.selectAllOption.value) {
      const options = this.options.filter(o => o.value !== this.selectAllOption.value);
      this.selectedOptions$.next(options);
    } else if (this.selectMultiple) {
      const selections = this.selectedOptions$.value || [];
      selections.push(option);
      this.selectedOptions$.next(selections);
    } else {
      this.selectedOption$.next(option);
    }

    this.updateInputValues();
    this.showEmptyButton = false;
    this.optionSelected.emit(option.value);
  }

  dismissAlert() {
    this.showAlert = false;
    this.optionToRemove = undefined;
  }

  remove(option: Model.SelectOption, skipEmit?: boolean) {
    if (this.removeAlertTmpl) {
      if (this.optionToRemove) {
        this.dismissAlert();
      } else {
        this.showAlert = true;
        this.optionToRemove = option;
        return;
      }
    }

    if (this.selectMultiple) {
      const selections = this.selectedOptions$.value
        .filter(selection => {
          return selection.value !== option.value;
      });
      this.selectedOptions$.next(selections);
    } else {
      this.selectedOption$.next(null);
    }

    this.updateInputValues();
    if (!skipEmit) {
      this.optionRemoved.emit(option.value);
    }
  }

  private shouldPerformSearch(event: KeyboardEvent, options: Array<Model.SelectOption>, option: Model.SelectOption) {
    if (!event || option) {
      return;
    }
    return ((/[\w\.]/.test(event.key) || event.keyCode === 8 || event.keyCode === 16));
    // TODO: && !this.selection
  }

  private performSearch() {
    let newValue = '';
    if (this.searchInput.control.control.value) {
      newValue = this.searchInput.control.control.value.toLowerCase();
    }

    if (newValue === this.searchValue) { return; } // Return early if value hasn't changed
    this.searchValue = newValue;

    if (this.searchValue.length > 0) {
      this.search.emit(this.searchValue);
    } else {
      this.options = [];
    }

    this.resultsList.resetScroller();
    if (this.showEmpty) {
      this.showEmptyButton = true;
    }
  }

  showSelectionList() {
    if (this.selectMultiple) {
      return this.selectedOptions$.value && this.selectedOptions$.value.length > 0;
    } else {
      return false;
    }
  }

  showClearButton() {
    return this.selectedOption$.value != null && !this.selectOnce;
  }

  getSelectedText() {
    return this.selectedOption$.value != null ? this.selectedOption$.value.label : NOT_ENTERED;
  }

  hasSelectedOptions() {
    return this.selectedOptions$.value.length;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  get required() {
    const value = this.selectInput.element.getAttribute('aria-required');
    return value && value !== 'false';
  }

  private checkValidation() {
    const isInvalid = this.selectInput.control?.invalid?.toString();
    this.searchInput.element.setAttribute('aria-invalid', isInvalid);

    // SetTimeout to avoid expressionChangedAfterChecked error
    setTimeout(() => {
      this.invalidState = isInvalid;
      this.cdr.markForCheck();
    }, 0);
  }
}
