import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { ACTION_BUTTONS, AREAS, PROGRAM_KEYS, PROPOSAL_TYPES, WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';
import { ApiService, LookupService, PermissionsService, ProgramService } from '@app-services';
import { Proposal, ThreeYearPlan } from '@app-models';
import { PlanWorkflowComponent } from '@app/RCM/components/workflows/plan-workflow.component';
import { PlanWorkflowValidatorService } from '@app/RCM/validators/plan-workflow-validator.service';

@Component({
  selector: 'project-workplan-activities',
  templateUrl: './workplan-activities.component.html'
})
export class WorkplanActivitiesComponent extends PlanWorkflowComponent implements OnInit, OnDestroy {
  @Input() isPreview = false;
  @Input() hideTitle = false;
  @Input() guidance: Model.GuidanceWorkflowFilter;

  canEdit = false;
  firstTouch = true;
  title = 'Workplan: Activities';

  addDisabled = false;
  objectives = [];

  proposal = undefined;
  threeYearPlan: Model.EAThreeYearPlan;
  activityEAs: Array<Model.EAWorkplanActivity>;
  objectiveEAs: Array<Model.EAWorkplanObjective | Model.EAThreeYearPlanObjectives>;
  parentEffortAreas: Model.EffortArea[];

  initialized = false;
  includeOutcomes = false;

  budgetedActivityIds: Array<number>;
  reportedActivityIds: Array<number>;
  summaryEntries: Array<Model.BudgetSummaryEntry> = [];

  private destroy$: Subject<boolean> = new Subject();
  private fund: Model.Fund;
  private isPlan: boolean;
  private isCAEP: boolean;
  private parentKey: string;
  private currentWorkflowTemplateName: string;
  private minActivityLength = 3;

  constructor(
    protected store: Store<State>,
    protected apiService: ApiService,
    protected programService: ProgramService,
    protected permissionsService: PermissionsService,
    protected lookupService: LookupService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected workflowValidatorService: PlanWorkflowValidatorService,
  ) {
    super(store, apiService, programService, permissionsService, lookupService, route, router, workflowValidatorService);
  }

  ngOnInit() {
    if (!!this.guidance) {
      // in template mode...prepare to show as guidance template.
      this.setupAsTemplate();
      return;
    }

    this.store.select(Queries.CurrentProposal.get).pipe(
      withLatestFrom(
        this.store.select(Queries.Workflow.getCurrent)
      ),
      filter(([p, w]) => p && p.id && !!w),
      takeUntil(this.destroy$)
    ).subscribe(([proposal, current_workflow]) => {

      this.proposal = proposal;
      this.isPlan = Proposal.isPlan(proposal);
      this.fund = this.proposal.funds[0];
      this.parentKey = this.fund.parent_key;
      this.isCAEP = this.fund.key === PROGRAM_KEYS.CAEP;

      this.currentWorkflowStep = this.isCAEP ? WORKFLOW_STEPS.ACTIVITIES_AND_OUTCOMES : WORKFLOW_STEPS.ACTIVITIES;
      this.currentWorkflowTemplateName = current_workflow.templateName || current_workflow.name;

      if (this.isCAEP) {
        const durationId = Number(this.route.snapshot.params['durationId']);
        this.threeYearPlan = proposal.three_year_plans.find(plan => plan.duration_id === durationId);
        this.activityEAs = this.threeYearPlan.workplan_activities;
        this.objectiveEAs = this.threeYearPlan.three_year_plan_objectives;
        this.parentEffortAreas = [this.threeYearPlan];
      } else {
        this.activityEAs = this.proposal.workplan_activities;
        this.objectiveEAs = this.proposal.workplan_objectives;
      }

      // disables add button if any objective doesn't have a title
      this.addDisabled = !(this.activityEAs.every(activity => !!activity.title));

      // set up options for objective selects
      this.objectives = this.objectiveEAs
        .filter((objective) => objective.id > 0 )
        .map((objective) => ({
          value: objective.id,
          label: objective.title || 'Untitled Objective'
        }))
        .sort((a, b) => {
          const aLabel = a.label.toUpperCase();
          const bLabel = b.label.toUpperCase();
          return aLabel > bLabel ? 1 : -1;
        });

      if (this.isRCM) {
        this.getActivitiesSummary();
      }

        // initialize one time set up code.
      this.initialize();
    });
  }

  /**
   * Initializes the vars that need to be set up just once.
   * */
  private initialize() {
    if (this.initialized) {
      // all's been setup.
      return;
    }
    this.initialized = true;

    const isCAIv2 = this.fund.parent_key === PROGRAM_KEYS.CAI && this.fund.key === PROGRAM_KEYS.RFA_v2;

    this.includeOutcomes = this.fund.is_small_program || this.isCAEP || [PROGRAM_KEYS.PERKINS, PROGRAM_KEYS.RCM].includes(this.fund.parent_key);

    if ([PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R, PROGRAM_KEYS.RCM].includes(this.fund.parent_key)) {
      this.getBudgetedActivityIds();
      this.getReportedActivityIds();
    }
    
    if (isCAIv2 || this.fund.is_small_program || this.isCAEP || [PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R, PROGRAM_KEYS.RCM].includes(this.fund.parent_key)) {      
      this.minActivityLength = 1;
    }

    if (this.isCAEP) {
      this.title = "Activities";
    }

    if (this.includeOutcomes) {
      this.title += ' & Outcomes';
    }

    if (!this.guidance) {
      const workflowName = this.currentWorkflowTemplateName === WORKFLOW_TYPES.RCM_PLAN ? WORKFLOW_TYPES.RCM : this.currentWorkflowTemplateName;
      this.guidance = {
        programId: this.fund.id,
        proposalType: PROPOSAL_TYPES.APPLICATION,
        workflowName: workflowName,
        stepName: this.currentWorkflowStep,
      };
    }

    if (this.isRCM && this.isPlan) {
      super.ngOnInit();
    }

    if (!this.isPreview) {
      this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));

      // listen for next button.
      this.store.select(Queries.Layout.getEmitState)
        .pipe(takeUntil(this.destroy$))
        .subscribe((emitted) => {
          if (emitted && emitted.id === ACTION_BUTTONS.NEXT) {
            this.store.dispatch(Actions.CurrentWorkflow.gotoNext(this.currentWorkflowStep));
          }
        });

      this.fetchPermissions();

      // determine first touch
      this.firstTouch = !this.activityEAs.length;

      for (let i = this.activityEAs.length; i < this.minActivityLength; i++) {
        this.addActivity();
      }
    }
  }

  /**
   * Fetches permission(s) then sets up action button(s)
   */
  private fetchPermissions() {
    this.permissionsService.canEditProject(this.proposal, AREAS.PROJECT, this.route.snapshot)
      .pipe(takeUntil(this.destroy$))
      .subscribe(canEdit => {
        this.canEdit = canEdit;
      });
  }

  trackById(_: number, item: any): number {
    return item.temp_id ? item.temp_id : item.id;
  }

  addActivity(disableAdd: boolean = false) {
    const temp = {
      effort_area_type: 'workplan_activities',
      parent_proposal_id: this.proposal.id,
      title: undefined,
      description: undefined
    };

    const effortArea = this.isCAEP 
      ? { ea: { ...temp, parent_effort_area_id: this.threeYearPlan.id }, parentEffortAreas: this.parentEffortAreas } 
      : { ea: { ...temp } };

    if ([PROGRAM_KEYS.SWPL_v3, PROGRAM_KEYS.SWPR_v3].includes(this.fund.key)) {
      // SWPv3 activities contain a child EA, so we need to make sure the activity EA
      // is actually created first in order for the child EA to save properly
      this.store.dispatch(Actions.CurrentProposal.createEffortArea(effortArea));
    } else {
      this.store.dispatch(Actions.CurrentProposal.createTempEffortArea(effortArea));
    }
    
    this.addDisabled = disableAdd;
  }

  get canDelete(): boolean {
    return this.activityEAs && (this.activityEAs.length > this.minActivityLength);
  }

  shouldPreventDelete(activityId: number): string | null {
    if (this.reportedActivityIds && this.reportedActivityIds.includes(activityId)) {
      return 'Expenditures have been reported for this activity.'
    } else if (this.budgetedActivityIds && this.budgetedActivityIds.includes(activityId)) {
      return 'There are budget items associated with this activity.'
    }
  }

  getActivitiesSummary() {
    this.summaryEntries = [];

    const getClass = (index) => {
      const column = index % 2 === 0 ? 2 : 1;
      return `budget-summary__col${column} budget-summary--alt`;
    };

    this.proposal.workplan_objectives.forEach((objective, index) => {
      const amount = this.proposal.workplan_activities.reduce((sum, activity) => {
        if (activity.objective_ea_id === objective.id) {
          sum += 1;
        }
        return sum;
      }, 0);

      // This error is only informational; shown as an error but not a validation requirement
      const invalidAmount = amount < 3 || amount > 5;
      this.summaryEntries.push({
        title: objective.title,
        class: getClass(index),
        isNotCurrency: true,
        isSet: () => true,
        amount: () => amount,
        amountErrors: () => invalidAmount,
        isInvalid: () => invalidAmount,
        alertText: () => null,
        helpText: () => null,
      });
    });
  }

  getBudgetedActivityIds() {
    if (Array.isArray(this.proposal['plan_budget_items'])) {
      this.budgetedActivityIds = this.proposal['plan_budget_items'].reduce((ids, budget) => {
        budget.related_activities.forEach(activity => {
          if (!ids.includes(activity.value)) {
            ids.push(activity.value);
          }
        });
        return ids;
      }, []);
    }
  }

  getReportedActivityIds() {
    if (Array.isArray(this.proposal['activity_spending_reports'])) {
      const submittedReportDurations = [];
      (this.proposal.states['fiscal_report_submit'] || []).forEach(report => {
        if (!submittedReportDurations.includes(report.duration_id)) {
          submittedReportDurations.push(report.duration_id);
        }
      });

      this.reportedActivityIds = this.proposal['activity_spending_reports'].reduce((ids, report) => {
        if (submittedReportDurations.includes(report.duration_id)) {
          if (!ids.includes(report.activity_id)) {
            ids.push(report.activity_id);
          }
        }
        return ids;
      }, []);
    }
  }

  private setupAsTemplate() {
    if (this.isCAEP) {
      this.title = 'Activities';
    }
    this.initialized = true;
  }

  get isRCM(): boolean {
    return this.parentKey === PROGRAM_KEYS.RCM;
  }

  ngOnDestroy() {
    if (this.isRCM && this.isPlan) {
      super.ngOnDestroy();
    }

    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
