import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input, OnDestroy,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { Logger } from '../../../app-logger';
import { InputRefDirective } from '../../directives';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html'
})

export class SearchInputComponent implements AfterViewInit, OnInit, OnDestroy {

  searchValue = '';
  form: FormGroup;

  @Input() id: string;
  @Input() placeholderText = '';

  @HostBinding('attr.id') hostId;
  @ViewChild(InputRefDirective, {static: true}) searchInput: InputRefDirective;

  @Output() search = new EventEmitter<string>();

  private destroy$: Subject<boolean> = new Subject();
  searchKeySubscription: Subscription;
  searchPasteSubscription: Subscription;

  constructor (
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.formBuilder.group({
      searchInput: ['']
    });
  }

  ngOnInit() {
    if (!this.id) {
      Logger.error('A unique ID is required in search-input component:');
      Logger.log(this.searchInput);
    } else {
      this.hostId = this.id;
    }
  }

  ngAfterViewInit() {
    this.searchInit();
  }

  private searchInit() {
    this.searchInput.focusSubject.pipe(
      takeUntil(this.destroy$)
    ).subscribe((focused) => {
      if (focused) {
        this.searchKeySubscription = this.searchInput.keyUpSubject.asObservable()
          .pipe(
            debounceTime(250),
            filter((event) => {
              return this.shouldPerformSearch(event);
            })
          ).subscribe(() => {
            this.performSearch();
          });
        this.searchPasteSubscription = this.searchInput.pasteSubject.asObservable()
          .pipe(filter(pasteEvent => !!pasteEvent))
          .subscribe(() => {
            this.performSearch();
          });
      } else {
        if (this.searchKeySubscription)  {
          this.searchKeySubscription.unsubscribe();
          this.searchKeySubscription = undefined;
        }
        if (this.searchPasteSubscription)  {
          this.searchPasteSubscription.unsubscribe();
          this.searchPasteSubscription = undefined;
        }
      }
    });
  }

  private shouldPerformSearch(event: KeyboardEvent) {
    if (!event) {
      return;
    }
    return ((/[\w\.]/.test(event.key) || event.key === 'Backspace' || event.key === 'Shift'));
  }

  private performSearch() {
    let newValue = '';
    if (this.searchInput.control.control.value) {
      newValue = this.searchInput.control.control.value.toLowerCase();
    }

    if (newValue === this.searchValue) { return; } // Return early if value hasn't changed
    this.searchValue = newValue;

    this.search.emit(this.searchValue);
  }

  clearValue() {
    this.form.reset();
  }

  get showClearButton() {
    return !!this.form?.get('searchInput')?.value;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
