import { trigger, style, transition, animate } from '@angular/animations';

export const slideOutAnimation = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)' }),
    animate('250ms ease-out',
      style({ transform: 'translateY(0)' }))
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0)' }),
    animate('250ms ease-in',
      style({ transform: 'translateY(-100%)' }))
  ])
]);
