import { forkJoin, concat, of, Observable } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { State, Queries, Model, Actions as DomainActions } from '@app-ngrx-domains';
import { startCase, cloneDeep } from 'lodash';
import { toPayload, NewTempId } from '@app-libs';
import { ApiService, LookupService, AttributesService } from '@app-services';
import { CURRENT_PROPOSAL_ACTION_PREFIX, CURRENT_PROPOSAL_ACTION_TYPES } from './current-proposal.action';
import { Cfad, AnnualPlan, Proposal, UserRoleScope, EnumErrorTypes, Profile } from '@app-models';
import { FUND_TYPES, FUND_SETTINGS, TASK_TYPES, PROJECT_ROLES, PROGRAM_KEYS} from '@app-consts';

/**
 * Container class for current proposal effects calls.
*/
@Injectable()
export class CurrentProposalEffects {

  /**
   * Creates an instance of CurrentProposalEffects.
   * @param {Actions} actions$
   * @param {ApiService} service
   * @param {Store} store
   */
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private attributesService: AttributesService,
    private lookupService: LookupService,
    private store: Store<State>,
  ) {
  }

  /**
   * Returns the year AnnualPlan was approved.
   */
  private getAEBGProgramYear(proposal: any): number {
    // get the latest year's cfad.
    const cfad: Model.Cfad = Cfad.latestYearCfad(proposal.cfads);
    const programYearDurationId = Cfad.isCertified(cfad.status_id) ? cfad.duration_id : cfad.duration_id - 1
    return programYearDurationId;
  }

  /**
   * Return actions that need to be hydrated after
   * proposal is fetched from the persistent storage.
   *
   * @param {any} proposal
   * @param {number?} institution_id
   * @returns {Action[]}
   */
  private hydrateProposalStates(proposal: any, institution_id?: number): Action[] {
    // fund/project type.
    const projectType = proposal.fund_ids[0];

    const actions: Action[] = [];
    actions.push(DomainActions.CurrentProposal.getSuccess(proposal)); // hydrate main body
    actions.push(DomainActions.Workplan.load(proposal)); // hydrate workplan
    actions.push(DomainActions.Budget.load(proposal)); // hydrate budget
    actions.push(DomainActions.Contacts.load(proposal)); // hydrate contacts
    actions.push(DomainActions.Files.load(proposal)); // hydrate files

    // hydrate metrics
    if (projectType === FUND_TYPES.LVG) {
      actions.push(DomainActions.MetricsEx.load(proposal));
      actions.push(DomainActions.Metrics.loadLVG(proposal.lead_institution.id)); // hydrate launchboard metrics
    } else if (projectType === FUND_TYPES.SEP) {
      actions.push(DomainActions.Metrics.loadSEP(proposal.lead_institution.id));
    } else {
      actions.push(DomainActions.MetricsEx.load(proposal));
      if (projectType === FUND_TYPES.GP) {
        const p = new Proposal(proposal).iObject<Model.ProposalItem>();
        actions.push(DomainActions.Metrics.loadGP(p)); // hydrate launchboard metrics
      } else {
        actions.push(DomainActions.Metrics.reset());
      }
    }

    if (projectType !== FUND_TYPES.AEBG) {
      // clear out aebg redux states.
      actions.push(DomainActions.Entities.reset());
      actions.push(DomainActions.Cfad.reset());
      actions.push(DomainActions.AnnualPlans.reset());
      actions.push(DomainActions.Allocations.reset());
      actions.push(DomainActions.CfadAmendment.reset());
      actions.push(DomainActions.ProposalReports.resetProgramAreaReports());
    } else {
      const programYearNum = this.getAEBGProgramYear(proposal);
      const programYearObj: Model.Duration = {
        id: programYearNum,
        year: this.lookupService.years[programYearNum].year,
        name: this.lookupService.years[programYearNum].name,
        type: 'annual'
      };

      actions.push(DomainActions.Entities.load(proposal, programYearObj, institution_id));
      actions.push(DomainActions.Cfad.load(proposal));
      actions.push(DomainActions.CfadAmendment.load(proposal));
      actions.push(DomainActions.AnnualPlans.load(proposal));
      // allocations will now get hydrated after it's obtained the budget limits.
    }

    return actions;
  }

  /**
   * Listens for GET action
   */
   get$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.GET),
    map(toPayload),
    mergeMap(req => {
      // Send get request to API
      return this.apiService.getProposalById(req.id).pipe(
        mergeMap((response: any) => {
          // bundle actions.
          const actions: Action[] = [];
          // hydrate other dependencies.
          actions.push(...this.hydrateProposalStates(response.proposal, req.institution_id));

          if (req.addToProposals) {
            // add raw proposal into proposals redux storage so fiscal reporting, for instance,
            // can work with surveys.
            actions.push(DomainActions.Proposals.load([cloneDeep(response.proposal)], req.id));
          }

          // get extra data if needed
          const fund_id = response.proposal.fund_ids[0];
          const fundKey = response.proposal.funds[0].key;
          const proposal_id = response.proposal.id;
          const lead_institution_id = response.proposal.lead_institution && response.proposal.lead_institution.id || undefined;

          if (fund_id === FUND_TYPES.AEBG) {
            const program_year = this.getAEBGProgramYear(response.proposal);

            return forkJoin([program_year, program_year - 1, program_year - 2].reduce((observables, year) => {
              observables[year] = this.apiService.getBudgetLimits({
                from_institution_id: lead_institution_id,
                fund_id: FUND_TYPES.AEBG,
                proposal_id: proposal_id,
                duration_id: year,
              });
              return observables;
            }, {})).pipe(
              mergeMap(budgetLimits => {
                // hydrate budget limits so we can refer to it.
                actions.push(DomainActions.Allocations.load(response.proposal, budgetLimits));

                // Dispatch actions...done reading.
                return concat([
                  ...actions,
                  DomainActions.CurrentProposal.setIsLoaded()
                ]);
              })
            );
          } else if (fund_id === FUND_TYPES.GP || fund_id === FUND_TYPES.SEP || [PROGRAM_KEYS.SEP_V2].includes(fundKey)) {
            // read lead institution's allocations.
            return this.apiService.getAllocations({
              direction: 'to',
              institution_id: lead_institution_id,
              fund_id: [PROGRAM_KEYS.SEP_V2].includes(fundKey) ? FUND_TYPES.SEP : fund_id,
            }).pipe(
              mergeMap((resAllocation: any) => {
                actions.push(DomainActions.Budget.loadAllocations(resAllocation));
                // Dispatch actions...done reading.
                return concat([
                  ...actions,
                  DomainActions.CurrentProposal.setIsLoaded(),
                ]);
              }));
          } else {
            // Dispatch actions...done reading.
            return concat([
              ...actions,
              DomainActions.CurrentProposal.setIsLoaded(),
            ]);
          }
        })
      );
    }),
    catchError((error) => of(DomainActions.CurrentProposal.getFail(error)))
  ));

   completeTask$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.COMPLETE_TASK),
    map(toPayload),
    mergeMap(task => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.completeTask(task).pipe(
        mergeMap(response => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(DomainActions.CurrentProposal.taskUpdateSuccess(response));
        }),
        catchError(error => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.COMPLETE_TASK));
        }));
    })
  ));

   undoTask$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.UNDO_TASK),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.getId)),
    mergeMap(([payload, proposal_id]) => {
      const { task, refresh } = payload;
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.undoTask(task).pipe(
        mergeMap(response => {
          if (refresh) {
            return this.apiService.getProposalById(proposal_id).pipe(
              mergeMap(res => {
                this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
                return of(DomainActions.CurrentProposal.refreshTasksSuccess(res.proposal));
              }));
          } else {
            this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
            return of(DomainActions.CurrentProposal.taskUpdateSuccess(response));
          }
        }),
        catchError(error => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
          return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.UNDO_TASK));
        }));
    })
  ));

   createCloseTask$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.CREATE_CLOSE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.getProposal)),
    mergeMap(([payload, proposal]) => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));

      const getCloseTaskType = (): string => {
        return (proposal.funds[0].key === PROGRAM_KEYS.SWP_K12_v1) ? TASK_TYPES.SWPK_PLAN_CLOSE_SUBMIT : TASK_TYPES.RFA_PLAN_CLOSE_SUBMIT ;
      }

      const apiTaskCall = [PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(proposal.funds[0].parent_key)
        ? this.apiService.createTask({ // swp l/r create task to start the closure
            task_type: TASK_TYPES.SWP_PROPOSAL_CLOSE,
            proposal_id: proposal.id,
            ...payload
          })
        : this.apiService.completeTask({ // all else use complete task to start the closure
            task_type: getCloseTaskType(),
            proposal_id: proposal.id,
            ...payload
          });

      return apiTaskCall.pipe(
        mergeMap(() => {
          return this.apiService.getProposalById(proposal.id).pipe(
            mergeMap(p => {
              this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
              return of(DomainActions.CurrentProposal.refreshTasksSuccess(p.proposal));
            }));
        }),
        catchError(error => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.CREATE_CLOSE));
        }));
    })
  ));

   completeTaskAndRefresh$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.COMPLETE_TASK_AND_REFRESH),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.getId), this.store.select(Queries.Auth.getCurrentUserId)),
    mergeMap(([req, proposal_id, currentUser]) => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      // complete task.
      return this.apiService.completeTask(req.task).pipe(
        mergeMap(response => {
          // re-fetch proposal to get the tasks.
          if (currentUser !== response.performed_by_id) {
            this.store.dispatch(DomainActions.App.setError({
              type: EnumErrorTypes.user,
              location: 'fiscal-reports.effect',
              messageLevel: 'warning',
              show: true,
              message: `The action you are attempting was already performed by ${Profile.contactFullName(response.performed_by)}. Please double check your data, as your changes may have been overwritten.`
            }))
          }

          return this.apiService.getProposalById(proposal_id).pipe(
            mergeMap(res => {
              if (req.completeRefresh) {
                const actions: Action[] = [];
                // hydrate other dependencies.
                actions.push(...this.hydrateProposalStates(res.proposal));
                actions.push(DomainActions.Layout.showBusySpinner(false));
                return concat([
                  ...actions,
                ]);
              } else {
                this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
                return of(DomainActions.CurrentProposal.refreshTasksSuccess(res.proposal));
              }
            }));
        }),
        catchError(error => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.COMPLETE_TASK_AND_REFRESH));
        }));
    })
  ));

   refreshTasks$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.REFRESH_TASKS),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.getId)),
    mergeMap(([__, proposal_id]) => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.getProposalById(proposal_id).pipe(
        mergeMap(
          res => {
            this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
            return of(DomainActions.CurrentProposal.refreshTasksSuccess(res.proposal));
          }
        ),
        catchError((error) => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.REFRESH_TASKS))
        }))
    })
  ));

   addInstitution$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_INSTITUTION),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.getId)),
    mergeMap(data => {
      const [payload, proposal_id] = data;
      return this.apiService.upsertProposalInstitution(proposal_id, payload.institution_id, payload.attributes).pipe(
        mergeMap(response => {
          return of(DomainActions.CurrentProposal.upsertInstitutionSuccess(response.institution, payload.attributes));
        }),
        catchError(error => {
          return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_INSTITUTION));
        }));
    })
  ));

   removeInstitution$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_INSTITUTION),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.getId)),
    mergeMap(([payload, proposal_id]) => {
      return this.apiService.removeProposalInstitution(proposal_id, payload.institution_id).pipe(
        mergeMap(response => {
          return of(DomainActions.CurrentProposal.removeInstitutionSuccess(payload.institution_id));
        }),
        catchError(error => {
          if (error.status === 409) {
            if (payload.reselect_function) {
              payload.reselect_function(payload.institution_id);
            }
            const alertString = `<strong>${error.error.message}</strong>`;
            return of(DomainActions.App.showAlert(alertString));
          }
          return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_INSTITUTION));
        }));
    })
  ));

   removeAgency$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_AGENCY),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.getId)),
    mergeMap(([payload, proposal_id]) => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.removeProposalAgency(proposal_id, payload.institution_id, payload.options).pipe(
        mergeMap(() => {
          // re-fetch proposal
          return this.apiService.getProposalById(proposal_id).pipe(
            mergeMap(response => {
              // confirm success
              if (payload.confirm_success) {
                payload.confirm_success();
              }

              const actions: Action[] = [];
              // hydrate other dependencies.
              actions.push(...this.hydrateProposalStates(response.proposal));
              actions.push(DomainActions.Layout.showBusySpinner(false));
              return concat([
                ...actions,
              ]);
            }));
        }),
        catchError(error => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_AGENCY));
        }));
    })
  ));

  /**
   * Disassociates an institution and its relations from the proposal.
   */
   removeInstitutionAndRelations$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_INSTITUTION_AND_RELATIONS),
    map(toPayload),
    withLatestFrom(
      this.store.select(Queries.CurrentProposal.get),
      this.store.select(Queries.Contacts.get),
    ),
    mergeMap(([payload, proposal, proposal_contacts]) => {
      // start the spinner
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));

      // remove relations first.
      const observables = [];
      // contacts to remove.
      const contacts = payload.is_lead ? [] : proposal_contacts.filter(c => c.institution_id === payload.institution_id);
      contacts.forEach(c => {
        observables.push(this.apiService.removeUserRoleScope(UserRoleScope.stripped(c)));
        const has_other_contacts = proposal_contacts.find(pc =>
          (pc.institution_id !== c.institution_id && pc.role_id === PROJECT_ROLES.CONTACT.ID && pc.user_id === c.user_id));
        if (!has_other_contacts && ![PROGRAM_KEYS.SWP_K12_v4, PROGRAM_KEYS.SWP_K12_v5].includes(proposal.funds[0].key)) {
          observables.push(this.apiService.removeUserRoleScope(UserRoleScope.stripped({
            user_id: c.user_id,
            fund_id: c.fund_id,
            role_id: PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID,
            proposal_id: c.proposal_id
          })));
        }
      });

      const effortAreasToRemove = [];
      const eaLocations = [proposal.application_contributions, proposal.collaborative_partners, proposal.swp_collaborative_partners];
      eaLocations.forEach(location => {
        if (location) {
          const eaToRemove = location.find(ea => ea.institution_id === payload.institution_id);
          if (eaToRemove && !NewTempId.isTemp(eaToRemove.id)) {
            effortAreasToRemove.push(eaToRemove);
          }
        }
      });

      observables.push(...effortAreasToRemove.map(ea => this.apiService.deleteEffortArea(ea)));

      // remove relations obs operator.
      const obsRemoveRelations = (observables.length === 0) ? of([]) : forkJoin(observables);
      return obsRemoveRelations.pipe(
        mergeMap(() => {
          // remove institution
          return this.apiService.removeProposalInstitution(proposal.id, payload.institution_id).pipe(
            mergeMap(response => {
              if (response.remaining_objects) {
                // there was error removing the institution.
                const remaining_objects = Object.keys(response.remaining_objects).map(key => {
                  return `\n${response.remaining_objects[key]} Remaining ${startCase(key)}(s)`;
                });
                const alertString = `<strong>${response.message}</strong>${remaining_objects}`;

                this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
                return of(DomainActions.App.showAlert(alertString));
              }

              // successfully disassociated the institution - update the redux store.
              const actions: Action[] = [];
              if (contacts.length > 0) {
                // update contacts
                actions.push(DomainActions.Contacts.deleteSuccess(contacts));
              }

              effortAreasToRemove.forEach(ea => {
                actions.push(this.attributesService.deleteEffortAreaSuccess(CURRENT_PROPOSAL_ACTION_PREFIX, ea, payload.parentEffortAreas));
              });

              // update proposal institutions relations
              actions.push(DomainActions.CurrentProposal.removeInstitutionSuccess(payload.institution_id));

              if (payload.new_institution_id) {
                // replace with set new institution
                actions.push(DomainActions.CurrentProposal.upsertInstitution(payload.new_institution_id, {is_lead: payload.is_lead}));
              }

              return concat([
                ...actions,
                DomainActions.Layout.showBusySpinner(false),
              ]);
            }))
        }),
        catchError(error => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          if (error.status === 409 && error.error?.message) {
            return of(DomainActions.App.showAlert(error.error.message));
          }
          return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_INSTITUTION));
        }));
    })
  ));

  /**
   * Listens for TOGGLE_ALL_SECTOR action, then calls the api service to write the data.
   */
   toggleAllSector$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.TOGGLE_ALL_SECTOR),
    map(toPayload),
    // Pull in the latest currentProposal state into the payload
    withLatestFrom(this.store.select(Queries.CurrentProposal.getProposal),
      (payload, proposal: any) => ({ enable: payload, currentProposalId: proposal.id, currentAllSectorState: proposal.all_sectors })
    ),
    // Send update request to API
    mergeMap((payload: any) => {
      if (payload.currentAllSectorState !== payload.enable) {
        /* Issue the API call to init/destroy all-sector (crosscut) proposal */
        return (payload.enable ?
          this.apiService.allSectorPlanInit(payload.currentProposalId) :
          this.apiService.allSectorPlanDestroy(payload.currentProposalId)).pipe(mergeMap(() => {

            // update all sectors flag
            const attribute: Model.Attribute = {
              proposal_id: payload.currentProposalId,
              attribute_name: 'all_sectors',
              value: payload.enable ? 1 : 0,
            }
            return this.apiService.upsertAttribute(attribute).pipe(mergeMap((response: any) => {
              // on success
              const actions: Action[] = [];

              // update flag.
              actions.push(this.attributesService.upsertAttributeSuccess(CURRENT_PROPOSAL_ACTION_PREFIX, attribute.attribute_name, undefined, response));

              // Dispatch actions. These are executed in order.
              return concat([
                ...actions
              ]);
            }),
            catchError(error => {
              return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.TOGGLE_ALL_SECTOR));
            }));
          }),
          catchError(error => {
            return of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.TOGGLE_ALL_SECTOR));
          }));
      }
    })
  ));

  /**
   * Listens for SET_STATE_DIRTY action, then calls the api service to write the data.
   */
   setStateDirty$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.SET_STATE_DIRTY),
    map(toPayload),
    // Pull in the latest currentProposal state into the payload
    withLatestFrom(
      this.store.select(Queries.CurrentProposal.getProposal), (payload, proposal) => ({ dirtyStep: payload, currentProposal: proposal })
    ),
    mergeMap((req: any) => {
      const stepFieldName = `${req.dirtyStep}_state`;
      const isPristine = (req.currentProposal) ? req.currentProposal[stepFieldName] === 'pristine' : true;
      if (req.currentProposal && isPristine) {
        // mark state as dirty
        const attribute: Model.Attribute = {
          proposal_id: req.currentProposal.id,
          attribute_name: stepFieldName,
          value: 'dirty',
        }
        return this.apiService.upsertAttribute(attribute).pipe(
          map((response) => this.attributesService.upsertAttributeSuccess(CURRENT_PROPOSAL_ACTION_PREFIX, stepFieldName, undefined, response)),
          catchError((error) => of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.SET_STATE_DIRTY))));
      } else {
        // terminate here... state doesn't need to be updated.
        // return observableEmpty();
      }
    })
  ));

  updateClientState$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.UPDATE_CLIENT_STATE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.get)),
    mergeMap(([state, proposal]) => {
      const taskId = state.task_id;
      const client_state = {
        ...state,
      };
      delete client_state.task_id;

      return this.apiService.updateTask(taskId, client_state).pipe(
        mergeMap(res => {
          return of(DomainActions.CurrentProposal.taskUpdateSuccess(res));
        }),
        catchError((error) => of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.UPDATE_CLIENT_STATE)))
      )
    })
  ));

  upsertResourceNote$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_COMMENT),
    map(toPayload),
    mergeMap((payload) => {
      return this.apiService.upsertComment(payload).pipe(
        mergeMap(res => {
          return of(DomainActions.CurrentProposal.upsertCommentSuccess(res))
        }),
        catchError((error) => of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.UPDATE_CLIENT_STATE)))
      )
    })
  ));

  deleteResourceNote$ = createEffect(() => this.actions$.pipe(
    ofType(CURRENT_PROPOSAL_ACTION_TYPES.DELETE_COMMENT),
    map(toPayload),
    mergeMap((payload) => {
      return this.apiService.deleteResourceNote(payload).pipe(
        mergeMap(res => {
          return of(DomainActions.CurrentProposal.deleteCommentSuccess(res))
        }),
        catchError((error) => of(DomainActions.CurrentProposal.serviceFail(error, CURRENT_PROPOSAL_ACTION_TYPES.UPDATE_CLIENT_STATE)))
      )
    })
  ));
}
