<ng-container *ngIf="!isPreview else preview">
  <h2 class="header-title h2 mb-l" id="qa_tab_title">This is a place holder</h2>

  <form [formGroup]="form" autocomplete="off" *ngIf="form" [ngClass]="{'first-touch': firstTouch}">
    <div class="card">
      <div class="card-title">
        <h3>This is a card.</h3>
        <p class="help-text">
          This is a sentence of descriptive text.
        </p>
      </div>

    </div>

  </form>
</ng-container>

<!-- PREVIEW -->
<ng-template #preview>
  <div class="card">
    <div class="card-title">
      <h3 class="h3 mb-l">This is a place holder</h3>
    </div>

  </div>
</ng-template>
