import { PROGRAM_KEYS } from '@app/core/consts/core.const';

export const FILE_UPLOAD_TYPES = {
  LMI: 'lmi',
  CONSORTIUM_FILE: 'consortium_file',
  INSTITUTION_LOGO: 'INSTITUTION_LOGO',
  PROJECT_FILE: 'projectfile',
  MESSAGE_FILE: 'messagefile',
  CORE_INDICATORS: 'core_indicators',
  HEADCOUNT: 'headcount'
};

export const ALLOWED_EXTENSIONS = [
  '.pdf',
  '.png',
  '.jpg', '.jpeg',
  '.doc', '.docx',
  '.csv', '.xls', '.xlsx'
];

// TODO: These should just define the ID constants. Name (and all other detail about the types) should come from the DB.
export const DOCUMENT_TYPES = {
  AEBG_AB86_PLAN: {
    id: 1,
    name: 'AB86 Plan'
  },
  ANNUAL_PLAN_SNAPSHOT: {
    id: 2,
    name: 'Annual Plan'
  },
  CFAD_SNAPSHOT: {
    id: 3,
    name: 'Consortium Fiscal Administration Declaration'
  },
  AEBG_DATA_AND_ACCOUNTABILITY_PLAN: {
    id: 4,
    name: 'Data and Accountability Plan'
  },
  AEBG_GOVERNANCE: {
    id: 5,
    name: 'Governance'
  },
  AEBG_MEMBER_ALLOCATIONS: {
    id: 6,
    name: 'Member Allocations'
  },
  AEBG_PERFORMANCE_TARGETS: {
    id: 7,
    name: 'Performance Targets'
  },
  AEBG_THREE_YEAR_PLAN: {
    id: 8,
    name: 'CAEP Three Year Plan'
  },
  SWP_REGIONAL_PREVIEW: {
    id: 9,
    name: 'SWP Regionalshare Plan Preview'
  },
  SWP_LOCAL_PREVIEW: {
    id: 10,
    name: 'SWP Localshare Plan Preview'
  },
  AEBG_PREVIEW: {
    id: 11,
    name: 'CAEP Member or Consortium Plan Preview'
  },
  SIGNATURE_PAGE: {
    id: 12,
    name: 'Signature Page',
  },
  IPLAN_PREVIEW: {
    id: 13,
    name: 'Integrated Planning Preview'
  },
  SUPPORTING_DOCS: {
    id: 14,
    name: 'Supporting Documentation',
  },
  GP_PREVIEW: {
    id: 15,
    name: 'Guided Pathways Preview'
  },
  SWP_FISCAL_REPORT: {
    id: 16,
    name: 'SWP Fiscal Report'
  },
  SHARED_EMAIL: {
    id: 17,
    name: 'SWP Email'
  },
  AEBG_FISCAL_REPORT: {
    id: 18,
    name: 'CAEP Fiscal Report'
  },
  AEBG_PROGRAM_AREA_REPORT: {
    id: 19,
    name: 'CAEP Program Area Report'
  },
  CFAD_AMENDMENT_SNAPSHOT: {
    id: 20,
    name: 'Allocation Amendment'
  },
  SWPK12_LOI_SNAPSHOT: {
    id: 21,
    name: 'K12 SWP Letter of Intent'
  },
  LVG_SNAPSHOT: {
    id: 22,
    name: 'Local Vision Goals'
  },
  SWPK12_APPLICATION_SNAPSHOT: {
    id: 23,
    name: 'K12 SWP Application'
  },
  SWPK12_AGREEMENT_MOU: {
    id: 24,
    name: 'Agreement/MOU'
  },
  SWPK12_SUPPORT: {
    id: 26,
    name: 'Letter of Support'
  },
  SWPK12_COMMITMENT: {
    id: 27,
    name: 'Letter of Commitment'
  },
  CAI_LEA_SPONSORSHIP: {
    id: 44,
    name: 'LEA Sponsorship for Apprenticeship'
  },
  CAI_EMPLOYER_WAGE_AGREEMENT: {
    id: 45,
    name: 'Employer Apprentice Wage Agreement'
  },
  CAI_EMPLOYER_LETTER_OF_SUPPORT: {
    id: 46,
    name: 'Employer Letter of Support'
  },
  NURSING_PLAN: {
    id: 47,
    name: 'Nursing Plan'
  },
  NEP_MANAGEMENT_PLAN: {
    id: 49,
    name: 'Management Plan'
  },
  ORGANIZATIONAL_CHART: {
    id: 50,
    name: 'Organizational Chart'
  },
  NEP_OTHER: {
    id: 51,
    name: 'Other'
  },
  SEP_MID_ALLOCATION_REPORT: {
    id: 52,
    name: 'SEAP Mid-Allocation Report'
  },
  SEP_ALLOCATION_END_REPORT: {
    id: 53,
    name: 'SEAP Allocation-End Report'
  },
  SUB_ALLOCATION: {
    id: 54,
    name: 'Sub-Allocation'
  },
  SUB_ALLOCATION_AMENDMENT: {
    id: 55,
    name: 'Sub-Allocation Amendment'
  },
  SWPK12_WORK_PLAN: {
    id: 56,
    name: 'K12 SWP Application Work Plan'
  },
  SWPK12_EVALUATION: {
    id: 57,
    name: 'High-Quality CTE Program Evaluation'
  },
  SELF_ASSESSMENT: {
    id: 58,
    name: 'Self Assessment'
  },
  LOCAL_NEEDS_ASSESSMENT: {
    id: 59,
    name: 'Local Needs Assessment'
  },
  LOCAL_NEEDS_ASSESSMENT_CERTIFICATION_FORM: {
    id: 62,
    name: 'Local Needs Assessment Certification Form'
  },
  OTHER: {
    id: 63,
    name: 'Other'
  },
  REPORT_SUPPORTING_DOC: {
    id: 64,
    name: 'Report Supporting Documentation'
  },
  LIVING_WAGE_ATTAINMENT: {
    id: 65,
    name: 'Living Wage Attainment',
  },
  LMI_LIBRARY_REPORTS: {
    id: 66,
    name: 'LMI Library Reports Data',
  },
  VALIDATED_EMPLOYER_INPUT: {
    id: 67,
    name: 'Validated Employer Input',
  },
  LOCAL_PROGRAM_REVIEW: {
    id: 68,
    name: 'Local Bi-Annual Program Review Process',
  },
  REGIONAL_CONSORTIUM_ENDORSEMENT: {
    id: 69,
    name: 'Regional Program Consortium Endorsement Process',
  },
  REGIONAL_PLANNING_PROCESS: {
    id: 70,
    name: 'Regional Planning Process',
  },
  REGIONAL_PLAN: {
    id: 71,
    name: 'Regional Plan',
  },
  COMPREHENSIVE_LOCAL_NEEDS_ASSESSMENT: {
    id: 72,
    name: 'Comprehensive Local Needs Assessment'
  },
  LETTER_OF_SUPPORT: {
    id: 73,
    name: 'Letter of Support'
  },
  STATEMENT_OF_ASSURANCE: {
    id: 76,
    name: 'Statement of Assurance'
  },
  INVOICE: {
    id: 77,
    name: 'Invoice'
  },
  INVOICE_SUMMARY: {
    id: 78,
    name: 'Invoice Summary'
  },
  CALPASS_PLUS_MOU: {
    id: 83,
    name: 'Cal-PASS Plus MOU'
  },
  DISTRICT_LOCAL_NEEDS_ASSESSMENT: {
    id: 99,
    name: 'District Comprehensive Local Needs Assessment'
  },
  DISTRICT_CORE_INDICATOR_REPORT: {
    id: 100,
    name: `District Core Indicator Report from previous year (Form 1 Part E-D- District Aggregate Core Indicator Information, found here:
      <a href="https://misweb.cccco.edu/perkinsV/Core_Indicator_Reports/Default.aspx" class="text-link" target="_blank">California Community Colleges Chancellor's Office - Data Mart (cccco.edu)</a>)`
  },
  PROGRAM_IMPROVEMENT_PLAN: {
    id: 101,
    name: 'Program Improvement Plan'
  },
  CORE_INDICATORS: {
    id: 105,
    name: 'Core Indicators'
  },
  HEADCOUNT_FINAL_REPORT: {
    id: 106,
    name: 'Final Report'
  },
  WEDD_MEMO: {
    id: 107,
    name: 'WEDD Memo'
  }
};

export const PROGRAM_DOCUMENT_TYPES = {
  [PROGRAM_KEYS.RCM]: [
    { id: DOCUMENT_TYPES.REGIONAL_PLAN.id, required: true },
    { id: DOCUMENT_TYPES.COMPREHENSIVE_LOCAL_NEEDS_ASSESSMENT.id, required: true },
    { id: DOCUMENT_TYPES.ORGANIZATIONAL_CHART.id, required: true },
    { id: DOCUMENT_TYPES.LETTER_OF_SUPPORT.id, required: true },
    { id: DOCUMENT_TYPES.OTHER.id, required: false }
  ],
  [PROGRAM_KEYS.NEP]: [
    { id: DOCUMENT_TYPES.NEP_MANAGEMENT_PLAN.id, required: true },
    { id: DOCUMENT_TYPES.ORGANIZATIONAL_CHART.id, required: true },
    { id: DOCUMENT_TYPES.NEP_OTHER.id, required: false }
  ]
};

