import { ModelBase } from './model.base';
import { forOwn as _forOwn} from 'lodash';
import { Model } from '@app-ngrx-domains';
import { PROGRAM_KEYS } from '../consts';

export const ALLOCATION_TYPES = {
  initial: 'initial',
  amendment: 'amendment',
  deferment: 'deferment',
  transfer: 'transfer',
}

export const ALLOCATION_BASE_YEARS = {
  [PROGRAM_KEYS.CAEP]: 2017,
  [PROGRAM_KEYS.SWP_L]: 2017,
  [PROGRAM_KEYS.SWP_R]: 2017,
  [PROGRAM_KEYS.SWP_K12]: 2019,
}

export interface IAllocation {
  id?: number;
  amount: number;
  from_institution_id?: number;
  to_institution_id?: number;
  to_institution?: Model.Institution;
  to_proposal_id?: number;
  to_proposal?: { id: number, title: string };
  type?: string;
  title?: string;
  from_fund_id?: number;
  fund_id?: number;
  duration_id?: number;
  year?: Model.Duration;
  created_at?: string;
  applied?: boolean;
}

export interface IAllocations {
  [duration_id: number]: Array<IAllocation>;
}

export interface IAllocationMap {
  [institution_id: number]: number
}

export interface IRemainingAllocation {
  institution_id: number;
  allocationTotal: number;
  expenditureTotal: number;
  remainingAllocation: number;
}

export interface IRemainingAllocations {
  [duration_id: number]: Array<IRemainingAllocation>;
}

export interface IRemainingAllocation {
  institution_id: number;
  allocationTotal: number;
  expenditureTotal: number;
  remainingAllocation: number;
}

export interface IAllocationCarryover {
  id: number;
  fund_id: number;
  institution_id: number;
  amount: number;
  applied: boolean;
  from_allocation_id: number;
  from_allocation?: IAllocation;
}

export interface IAllocationTransfer {
  id: number;
  created_at: string;
  from: string;
  to: string;
  description: string;
  creator_id: number;
  creator?: Model.User;
  amount: number;
}

export interface AllocationFilter {
  direction?: string,
  institution_id?: number,
  fund_id?: number,
  fund_ids?: Array<number>,
  from_fund_id?: number,
  duration_id?: number,
  proposal_id?: number
}

// Used by RCM Budget
export interface IAllocationByFund {
  id: number;
  duration_id: number,
  name: string,
  description: string,
  amount: number,
  plan_length: number,
  match_percent: number,
}

export interface IAllocationByRegion {
  regionId: number;
  region: string;
  total: number;
  offered: boolean;
  accepted: boolean;
}

export class Allocation extends ModelBase implements IAllocation {
  public id: number;
  public amount: number;
  public from_institution_id: number;
  public to_institution_id: number;
  public to_institution: Model.Institution;
  public to_proposal_id: number;
  public to_proposal: { id: number, title: string };
  public type: string;
  public title: string;
  public fund_id: number;
  public duration_id: number;
  public year: Model.Duration;
  public created_at: string;
  public applied: boolean;

  constructor(raw: any) {
    super();
    if (raw) {
      this.id = raw.id;
      this.amount = raw.amount;
      this.from_institution_id = raw.from_institution_id;
      this.to_institution_id = raw.to_institution_id;
      this.to_institution = raw.to_institution;
      this.to_proposal_id = raw.to_proposal_id;
      this.type = raw.type;
      this.title = raw.title;
      this.fund_id = raw.fund_id;
      this.duration_id = raw.duration_id;
      this.year = raw.year;
      this.created_at = raw.created_at;
      this.applied = raw.applied;
    }
  }


  /**
   * Strips allocation model to object meant for service call.
   */
  static serverObject(src: Model.Allocation, forCreate: boolean = true): Model.Allocation {
    return  {
      id: (forCreate || !src.id) ? undefined : src.id,
      amount: src.amount,
      type: src.type,
      title: src.title,
      fund_id: src.fund_id,
      duration_id: src.duration_id,
      from_institution_id: src.from_institution_id,
      to_institution_id: src.to_institution_id,
      from_fund_id: src.from_fund_id ? src.from_fund_id : undefined,
      to_proposal_id: src.to_proposal_id ? src.to_proposal_id : undefined,
      applied: !!src.applied
    };
  }

  /**
   * Returns members' allocation totals for given year.
   *
   * @static
   * @param {number} duration_id
   * @param {{ [institution_id: number]: IAllocations }} membersAllocations
   * @returns {number}
   */
  static getYearTotal(
    duration_id: number,
    membersAllocations: { [institution_id: number]: IAllocations },
    initialOnly = false): number {
    let total = 0;

    _forOwn(membersAllocations, (memberAllocations) => {
      _forOwn(memberAllocations, (allocation, key_fund_duration_id) => {
        if (Number(key_fund_duration_id) === duration_id) {
          total += initialOnly ? this.getInitialAllocation(allocation) : this.getAllocationSum(allocation);
        }
      });
    });
    return total;
  }

  /**
   * Returns the sum of all provided allocation row amounts.
   * @param allocations Array of initial & amended allocations
   */
  static getAllocationSum(allocations: Array<Model.Allocation>, includeDeferred = false): number {
    let total = 0;
    if (allocations && Array.isArray(allocations)) {
      allocations.forEach(allocation => {
        const amount = allocation.amount || 0;
        if (allocation.type === ALLOCATION_TYPES.deferment) {
          if (includeDeferred) {
            total -= amount;
          }
        } else {
          total += amount;
        }
      });
    }
    return total;
  }

  /**
   * Returns initial allocation amount.
   * @param allocations Array of initial & amended allocations
   */
  static getInitialAllocation(allocations: Array<Model.Allocation>): number {
    const initialAlloc = allocations.find(allocation => allocation.type === ALLOCATION_TYPES.initial);
    return initialAlloc ? initialAlloc.amount : 0;
  }

  /**
   * Returns true if there is at least one allocation record & total is > 0.
   * @param allocations Array of initial & amended allocations
   */
  static isAllocated(allocations: Array<Model.Allocation>): boolean {
    return allocations && Array.isArray(allocations) && allocations.length > 0 && Allocation.getAllocationSum(allocations) > 0;
  }

  /**
   * Formats amount to money format.
   *
   * @param {number} amount
   * @returns {string}
   */
  static formatMoney(amount: number): string {
    const str = '$' + (amount || 0);
    let ret = '';
    for (let i = str.length; i > 0; i -= 3) {
      const j = Math.max(i - 3, 0);
      ret = ',' + str.substring(j, i) + ret;
    }
    ret = ret.substr(1);
    if (ret.startsWith('$,')) {
      // remove exterraneous comma
      ret = ret.replace('$,', '$');
    }
    return ret;
  }

  static groupByYear(allocations: Array<Model.Allocation>): { [duratioId: number]: Array<Model.Allocation> } {
    return allocations.reduce((grouped, allocation) => {
      if (!grouped[allocation.duration_id]) {
        grouped[allocation.duration_id] = [allocation];
      } else {
        grouped[allocation.duration_id].push(allocation);
      }
      return grouped;
    }, {});
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Allocation = IAllocation;
    export type Allocations = IAllocations;
    export type AllocationMap = IAllocationMap;
    export type AllocationByFund = IAllocationByFund;
    export type AllocationByRegion = IAllocationByRegion;
    export type RemainingAllocation = IRemainingAllocation;
    export type RemainingAllocations = IRemainingAllocations;
    export type AllocationCarryover = IAllocationCarryover;
    export type AllocationTransfer = IAllocationTransfer;
  }
}
