import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, State } from '@app-ngrx-domains';
import { EnumErrorTypes } from '@app/core/models';
import { get, uniq } from 'lodash';
import { Observable } from 'rxjs';
import { ISearchModalFilter } from '..';

@Component({
  selector: 'search-modal',
  templateUrl: './search-modal.component.html'
})
export class SearchModalComponent {

  @Input() searchType: string;
  @Input() filters: Array<ISearchModalFilter> = [];
  @Input() getResults: (params: { searchText: string, searchLimit?: number }) => Observable<any>;
  filterOptions: ISearchModalFilter = {};
  appliedFilters: ISearchModalFilter = {};

  @Output() add: EventEmitter<string> = new EventEmitter();
  @Output() optionSelected: EventEmitter<string> = new EventEmitter();
  @Output() dismiss: EventEmitter<string> = new EventEmitter();

  searchValue: string;
  searchLimit: number = 200;
  isLoading: boolean;
  allResults: Array<ISearchModalResults> = [];
  results: Array<ISearchModalResults> = [];

  constructor(
    private store: Store<State>
  ) { }

  filterChanged(filter: ISearchModalFilter) {
    if (filter.options.length) {
      this.appliedFilters[filter.field] = filter.options;
    } else {
      delete this.appliedFilters[filter.field];
    }

    this.results = this.allResults.filter(result => {
      return Object.entries(this.appliedFilters).every(([field, options]) => {
        return result.subtitles.find(s => s.field === field && options.includes(s.value));
      });
    });
  }

  search(searchText: string) {
    if (searchText.length > 2) {
      this.searchValue = searchText;
      this.isLoading = true;

      this.getResults({
        searchText,
        searchLimit: this.searchLimit,
      }).subscribe(results => {
        this.appliedFilters = {};
        const filterOptions = {};

        this.allResults = results.map(result => {
          const subtitles = [];

          for (const filter of this.filters) {
            const field = filter.field;
            const value = get(result, field);
            if (value) {
              subtitles.push({ field, value });
              if (!filterOptions[field]) {
                filterOptions[field] = [];
              }
              filterOptions[field].push(value);
            }
          }

          return { title: result.name, subtitles, value: result };
        });

        for (const { field } of this.filters) {
          this.filterOptions[field] = uniq(filterOptions[field]).sort();
        }

        this.results = [...this.allResults];
      }, (err) => {
        this.store.dispatch(Actions.App.setError({
          type: EnumErrorTypes.api,
          location: this.constructor.name,
          show: true,
          raw: err
        }));
      }, () => this.isLoading = false);
    }
  }

  showAddModal() {
    this.add.emit();
    this.exit();
  }

  selectResult(result: any) {
    this.optionSelected.emit(result);
    this.exit();
  }

  exit() {
    this.dismiss.emit();
  }
}

export interface ISearchModalResults {
  title: string,
  subtitles?: Array<{ field: string, value?: string }>,
  value?: any
}