import { Component, OnInit, OnDestroy, Input, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { ACTION_BUTTONS, AREAS, PROGRAM_KEYS, PROPOSAL_TYPES, WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';
import { ApiService, LookupService, PermissionsService, ProgramService } from '@app-services';
import { ProposalBase as Proposal, ProposalBase } from '@app-models';
import { PlanWorkflowComponent } from '@app/RCM/components/workflows/plan-workflow.component';
import { PlanWorkflowValidatorService } from '@app/RCM/validators/plan-workflow-validator.service';

@Component({
  selector: 'project-goals-metrics',
  templateUrl: './goals-metrics.component.html',
})
export class GoalsAndMetricsComponent extends PlanWorkflowComponent implements OnInit, OnDestroy {
  @Input() isPreview = false;
  @Input() guidance: Model.GuidanceWorkflowFilter;

  public canEdit = false;
  public firstTouch = true;
  public title = 'Goals & Metrics';
  public proposal: any;
  public threeYearPlan: Model.EAThreeYearPlan;
  public leadRegionName: string;
  public metricsDefs: Array<any>;
  public selectedMetricsIds: Array<number>;
  public lbActuals: Array<Model.LBMetric>;
  public swpActuals: Array<Model.LBMetric>;
  public planLength = 5;
  public isRCM: boolean;
  public isCAEP: boolean;
  public collectGoals: boolean;
  public targetInstitutionId: number;
  public actualsDurations: Array<Model.Duration> = [];
  public initialized: boolean = false;

  protected destroy$: Subject<boolean> = new Subject();
  protected isPlan: boolean;
  protected currentWorkflowTemplateName: string;
  protected fund: Model.Fund;
  protected institutionIds: Array<number>;
  protected actualsDurationsIds: Array<number>;

  constructor(
    protected store: Store<State>,
    protected apiService: ApiService,
    protected programService: ProgramService,
    protected permissionsService: PermissionsService,
    protected lookupService: LookupService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected workflowValidatorService: PlanWorkflowValidatorService,
  ) {
    super(store, apiService, programService, permissionsService, lookupService, route, router, workflowValidatorService);
  }

  ngOnInit() {
    if (!this.isPreview) {
      this.currentWorkflowStep = WORKFLOW_STEPS.METRICS;
    }

    if (!!this.guidance) {
      // in template mode...prepare to show as guidance template.
      this.setupAsTemplate();
      return;
    }

    combineLatest([
      this.store.select(Queries.CurrentProposal.get),
      this.store.select(Queries.Metrics.getSWPMetrics),
      this.store.select(Queries.Metrics.getLBMetrics),
    ]).pipe(
      withLatestFrom(
        this.store.select(Queries.Workflow.getCurrent),
        this.store.select(Queries.LookupTables.getMetricsDefinitions)
      ),
      filter(([[p, swp_m, lb_m], c_w, m_d]) => p && p.id && !!c_w),
      takeUntil(this.destroy$)
    ).subscribe(([[proposal, swp_metrics, lb_metrics], current_workflow, metrics_defs]) => {
      this.proposal = proposal;
      this.isPlan = Proposal.isPlan(proposal);
      this.fund = this.programService.getProgramById(this.proposal.funds[0].id);
      this.isRCM = this.fund.parent_key === PROGRAM_KEYS.RCM;
      this.isCAEP = this.fund.key === PROGRAM_KEYS.CAEP;
      this.currentWorkflowTemplateName = current_workflow.templateName || current_workflow.name;

      this.initialize();

      this.setMetricsData(metrics_defs, swp_metrics, lb_metrics);
    });
  }

  ngOnDestroy() {
    if (this.isRCM && this.isPlan) {
      super.ngOnDestroy();
    }

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected initialize() {
    if (this.initialized) {
      // all's been setup.
      return;
    }
    this.initialized = true;

    // set up workflow filter so guidance can be retrieved.
    if (!this.guidance) {
      const workflowName = this.currentWorkflowTemplateName === WORKFLOW_TYPES.RCM_PLAN ? WORKFLOW_TYPES.RCM : this.currentWorkflowTemplateName;
      this.guidance = {
        programId: this.fund.id,
        proposalType: PROPOSAL_TYPES.APPLICATION,
        workflowName: workflowName,
        stepName: this.currentWorkflowStep,
      }
    }

    this.collectGoals = this.fund.is_small_program || this.isRCM || this.isCAEP;

    if (this.fund.is_small_program) {
      this.planLength = ProposalBase.getYearLength(this.proposal);
      this.targetInstitutionId = this.proposal.lead_institution.id;
    }

    if (this.isRCM) {
      this.targetInstitutionId = this.proposal.application_region;
      this.leadRegionName = this.lookupService.getInstitutionName(this.proposal.application_region);
      if (this.isPlan) {
        super.ngOnInit();
      }
    }

    if (this.isCAEP) {
      this.title = 'Metrics';
      this.planLength = 3;
      this.targetInstitutionId = this.proposal.lead_institution.id;
    }

    const durationId = this.isCAEP ? this.threeYearPlan.duration_id : this.proposal.duration_id;
    const totalActualsYears = 3;
    for (let year = durationId - totalActualsYears; year <= durationId - 1; year++) {
      const yearObj = this.lookupService.getYear(year);
      this.actualsDurations.push(yearObj);
    }
    this.actualsDurationsIds = this.actualsDurations.map(yearObj => yearObj.id);
    this.institutionIds = [this.targetInstitutionId];
    if (!this.isCAEP) {
      this.store.dispatch(Actions.Metrics.loadLB(this.institutionIds, this.actualsDurationsIds));
      this.store.dispatch(Actions.Metrics.loadSWP(this.institutionIds, this.actualsDurationsIds));
    }

    if (!this.isPreview) {
      // current this as the current step & put up Next button.
      this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));

      // listen for next button.
      this.store.select(Queries.Layout.getEmitState)
        .pipe(takeUntil(this.destroy$))
        .subscribe((emitted) => {
          if (emitted && emitted.id === ACTION_BUTTONS.NEXT) {
            this.store.dispatch(Actions.CurrentWorkflow.gotoNext(this.currentWorkflowStep));
          }
        });

      // get permission
      this.permissionsService.canEditProject(this.proposal, AREAS.PROJECT, this.route.snapshot, { institution_id: this.proposal.lead_institution.id })
        .pipe(takeUntil(this.destroy$))
        .subscribe(canEdit => {
          this.canEdit = canEdit;
        });

      this.firstTouch = !(this.proposal.vision_goals || []).length;
    }
  }

  protected setMetricsData(metricsDefs: Array<any>, swpActuals: Array<Model.LBMetric>, lbActuals: Array<Model.LBMetric>): void {
    this.metricsDefs = metricsDefs;
    this.swpActuals = swpActuals;
    this.lbActuals = lbActuals;

    if (!this.isCAEP) {
      this.selectedMetricsIds = Proposal.selectedMetricsDefinitions(this.proposal);
    }
  }

  get tableTitle() {
    return `${this.leadRegionName ? this.leadRegionName  + ' ' : ''}Student Success Metrics`;
  }

  protected setupAsTemplate() {
    this.fund = this.programService.getProgramById(this.guidance.programId);
    this.initialized = true;
  }

  public trackByColumn(index: number, column) {
    return column.name;
  }

  public trackById(index: number, item: any) {
    return item.id;
  }
}
