import { Component, Input } from '@angular/core';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
})
export class BackButtonComnponent {

  @Input() route: string = '../';
  @Input() queryParams: object = {};
}
