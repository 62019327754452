import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { WORKFLOW_TYPES } from '../consts';

/**
 * Workflow action types
 */
const ACTION_PREFIX = 'WORKFLOW_';
export const WORKFLOW_ACTION_TYPES = {
  SET: `${ACTION_PREFIX}SET`,
  CLEAR: `${ACTION_PREFIX}CLEAR`,
  UPDATE_STEP: `${ACTION_PREFIX}UPDATE_STEP`,
  UPDATE_STEP_SCORE: `${ACTION_PREFIX}UPDATE_STEP_SCORE`,
  UPDATE_STEPS: `${ACTION_PREFIX}UPDATE_STEPS`,
  GOTO_NEXT: `${ACTION_PREFIX}GOTO_NEXT`,
  SET_PROPS: `${ACTION_PREFIX}SET_PROPS`,
  SET_CURRENT_STEP: `${ACTION_PREFIX}SET_CURRENT_STEP`,
  ADD_STEP_TO_SECTION: `${ACTION_PREFIX}ADD_WORKFLOW_TO_SECTION`,
  REMOVE_STEP_FROM_SECTION: `${ACTION_PREFIX}REMOVE_STEP_FROM_SECTION`,
  UPDATE_STEPS_IN_SECTION: `${ACTION_PREFIX}UPDATE_STEPS_IN_SECTION`,
  UPDATE_PARENT_VALIDATION_STATUSES: `${ACTION_PREFIX}UPDATE_PARENT_VALIDATION_STATUSES`,
  SHOW_STEPS: `${ACTION_PREFIX}SHOW_STEPS`,
  SHOW_STEPS_new: `${ACTION_PREFIX}SHOW_STEPS_new`,
  SHOW_ERROR_STEPPER: `${ACTION_PREFIX}SHOW_ERROR_STEPPER`
};

/**
 * Workflow action class.
 */
export class WorkflowActions {

  /**
   * Clears out current workflow.
   */
  clear(workflowType = WORKFLOW_TYPES.CURRENT, remove = false): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.CLEAR,
      payload: { kind: workflowType, remove }
    };
  }

  /**
   * Updates workflow steps of given type, and sets it as currently active
   * workflow.
   */
  update(workflowType: string, workflowUpdates: any, isCurrent = true): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.SET,
      payload: { kind: workflowType, workflow: workflowUpdates, isCurrent }
    };
  }

  /**
   * Updates given workflow type as the current workflow.
   */
  updateCurrent(workflowType: string, show: boolean, currentStep?: string): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.SET,
      payload: { kind: workflowType, workflow: { show, currentStep }, isCurrent: true }
    };
  }

  /**
   * Updates status of one or more steps.
   */
  updateSteps(steps: Array<Model.WorkflowStep>, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEPS,
      payload: { kind: workflowType, steps }
    };
  }

  /**
   * Updates score of the step.
   */
  updateStepScore(step: string, score: number, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP_SCORE,
      payload: { kind: workflowType, step, score, }
    };
  }

  /**
   * Marks step as valid.
   */
  markStepValid(step: string, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP,
      payload: { kind: workflowType, step: step, update: { valid: true } }
    };
  }

  /**
   * Marks step as invalid.
   */
  markStepInvalid(step: string, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP,
      payload: { kind: workflowType, step: step, update: { valid: false } }
    };
  }

  /**
   * Marks step as valid or invalid.
   */
  setValidity(step: string, valid: boolean, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP,
      payload: { kind: workflowType, step: step, update: { valid } }
    };
  }

  setValidLabel(step: string, validLabel: string, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP,
      payload: { kind: workflowType, step: step, update: { valid: !!validLabel ? true : false, validLabel } }
    };
  }

  /**
   * Sets step as first touched.
   */
  setFirstTouch(step: string, enable: boolean, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP,
      payload: { kind: workflowType, step: step, update: { firstTouch: enable } }
    };
  }

  /**
   * Clears step as first touched.
   */
  clearFirstTouch(step: string, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP,
      payload: { kind: workflowType, step: step, update: { firstTouch: false } }
    };
  }

  /**
   * Marks step as hidden.
   */
  markStepHide(step: string, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP,
      payload: { kind: workflowType, step: step, update: { hide: true } }
    };
  }

  /**
   * Marks step as shown.
   */
  markStepShow(step: string, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP,
      payload: { kind: workflowType, step: step, update: { hide: false } }
    };
  }

  /**
   * Marks step as enabled.
   */
  markStepEnabled(step: string, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP,
      payload: { kind: workflowType, step: step, update: { disabled: false } }
    };
  }

  /**
   * Marks step as disabled.
   */
  markStepDisabled(step: string, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEP,
      payload: { kind: workflowType, step: step, update: { disabled: true } }
    };
  }

  /** Based on current step in the workflow, routes user to next step.
   * @param {string} kind
   * @param {string} current step
   * @returns {Action}
   */
  gotoNext(kind: string, currentStep: string): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.GOTO_NEXT,
      payload: { kind, currentStep },
    }
  }

  /**
   * Shows or hides the workflow of given type.
   */
  show(show: boolean, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.SET_PROPS,
      payload: { kind: workflowType, props: { show: show } }
    };
  }

  /**
   * Updates workflow's properties.
   */
  setProps(props: any, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.SET_PROPS,
      payload: { kind: workflowType, props: props }
    };
  }

  /**
   * Makes the step as the current step of current workflow; shows Next button on header if set.
   * @param step - step to set as the current step.
   * @param showNext - shows Next button
   */
  setCurrentStep(step: string, showNext = false): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.SET_CURRENT_STEP,
      payload: { step, showNext },
    }
  }

  /**
   * Shows or hides steps.
   */
  // TODO: Replace all usages of this with new method (this one is confusing)
  showSteps(show: boolean, exclude: Array<string>, excludeHide: Array<string> = [], workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.SHOW_STEPS,
      payload: { show, exclude, excludeHide, workflowType }
    };
  }

  showSteps_new(steps: Array<string>, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.SHOW_STEPS_new,
      payload: { steps, workflowType }
    };
  }

  showErrorStepper(show: boolean): ActionWithPayload<boolean> {
    return {
      type: WORKFLOW_ACTION_TYPES.SHOW_ERROR_STEPPER,
      payload: show
    }
  }

  /**
   * Adds step to workflow section.
   */
  addStepToSection(sectionName: string, newStep: Model.WorkflowStep, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.ADD_STEP_TO_SECTION,
      payload: { kind: workflowType, sectionName, newStep }
    };
  }

  /**
   * Removes step from workflow section.
   */
  removeStepFromSection(sectionName: string, stepName: string, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.REMOVE_STEP_FROM_SECTION,
      payload: { kind: workflowType, sectionName, stepName }
    };
  }

  /**
   * Updates steps in section.
   */
  updateStepsInSection(sectionName: string, steps: Array<Model.WorkflowStep>, workflowType = WORKFLOW_TYPES.CURRENT): ActionWithPayload<any> {
    return {
      type: WORKFLOW_ACTION_TYPES.UPDATE_STEPS_IN_SECTION,
      payload: { kind: workflowType, sectionName, steps }
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Workflow = new WorkflowActions();

/**
 * Adds actions definitions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Workflow: WorkflowActions;
  }
}
