import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Model } from '@app-ngrx-domains';

@Component({
  selector: 'app-budget-summary',
  templateUrl: './budget-summary.component.html'
})
export class BudgetSummaryComponent {
  @Input() summaryEntries: Array<Model.BudgetSummaryEntry>
  @Input() canEdit: boolean; // Show isInvalid
  @Input() summaryTitle: string;
  @Input() template: TemplateRef<any>;
  @Input() bordered = false;
  @Input() spaced = false;
  @Input() isSticky = true;
  @Input() headingLevel: string = 'h2';
  @Input() set headingClass(value: string) {
    this._headingClass = value;
  }
  private _headingClass: string = 'h4';
  get headingClass(): string {
    return `${this._headingClass} margin-bottom-none`;
  }
  @Input() canModify = false;
  @Input() hasUnsavedModifications = false;
  @Input() isModifying = false;
  @Input() maxModifyReached = false;
  @Output() modify: EventEmitter<{ save: boolean, isModifying: boolean, cancel: boolean }> = new EventEmitter();

  // Hacky input to allow an additional header inline with the title. (Needs Design Review)
  @Input() secondaryTitleTemplate: TemplateRef<any>;

  constructor() {}

  emitModify(save: boolean, isModifying: boolean, cancel = false) {
    this.modify.emit({ save, isModifying, cancel });
  }
}
