import { WORKFLOW_STEPS } from '@app-consts';

export const REVIEW_WORKFLOW_CONFIG_v4_DEFAULT_CRITERIA = 'Please provide comments as your reference for the review process. Comments shall be optional for all items, even those requiring a score.';

export const REVIEW_WORKFLOW_CONFIG_v4_TOTAL_SCORE = 110;

export const REVIEW_WORKFLOW_CONFIG_v4 = {
  [WORKFLOW_STEPS.DESCRIPTION]: {
    showStatus: false,
    scoreCard: {
      title: 'Pathway Identification',
      collectScore: false,
      scoringCriteria: REVIEW_WORKFLOW_CONFIG_v4_DEFAULT_CRITERIA,
      totalScore: REVIEW_WORKFLOW_CONFIG_v4_TOTAL_SCORE,
    }
  },
  [WORKFLOW_STEPS.AGENCIES]: {
    showStatus: false,
    scoreCard: {
      title: 'Lead & Partner Agencies',
      collectScore: true,
      scoreCommentsOptional: true,
      score: null,
      minScore: 0,
      maxScore: 20,
      scoringCriteria: 'Score a max of 10 points and add a bonus of 5 points for new CTE programs and/or pathways and 5 points for new K12 SWP applicants.',
      totalScore: REVIEW_WORKFLOW_CONFIG_v4_TOTAL_SCORE,
    }
  },
  [WORKFLOW_STEPS.PARTNERS]: {
    showStatus: false,
    scoreCard: {
      title: 'Higher Ed. & Collaborative Partners',
      collectScore: false,
      scoringCriteria: REVIEW_WORKFLOW_CONFIG_v4_DEFAULT_CRITERIA,
      totalScore: REVIEW_WORKFLOW_CONFIG_v4_TOTAL_SCORE,
    }
  },
  [WORKFLOW_STEPS.OBJECTIVES]: {
    showStatus: false,
    scoreCard: {
      title: 'Problem Statement & Project Objectives',
      collectScore: true,
      scoreCommentsOptional: true,
      score: null,
      minScore: 0,
      maxScore: 40,
      scoringCriteria: REVIEW_WORKFLOW_CONFIG_v4_DEFAULT_CRITERIA,
      totalScore: REVIEW_WORKFLOW_CONFIG_v4_TOTAL_SCORE,
    }
  },
  [WORKFLOW_STEPS.PATHWAY]: {
    showStatus: false,
    scoreCard: {
      title: 'Industry Sectors & Pathways',
      collectScore: false,
      scoringCriteria: REVIEW_WORKFLOW_CONFIG_v4_DEFAULT_CRITERIA,
      totalScore: REVIEW_WORKFLOW_CONFIG_v4_TOTAL_SCORE,
    }
  },
  [WORKFLOW_STEPS.WORKPLAN]: {
    showStatus: false,
    scoreCard: {
      title: 'Work Plan',
      collectScore: true,
      scoreCommentsOptional: true,
      score: null,
      minScore: 0,
      maxScore: 35,
      scoringCriteria: REVIEW_WORKFLOW_CONFIG_v4_DEFAULT_CRITERIA,
      totalScore: REVIEW_WORKFLOW_CONFIG_v4_TOTAL_SCORE,
    },
  },
  [WORKFLOW_STEPS.BUDGET]: {
    showStatus: false,
    scoreCard: {
      title: 'Budget & Match',
      collectScore: true,
      scoreCommentsOptional: true,
      score: null,
      minScore: 0,
      maxScore: 15,
      scoringCriteria: REVIEW_WORKFLOW_CONFIG_v4_DEFAULT_CRITERIA,
      totalScore: REVIEW_WORKFLOW_CONFIG_v4_TOTAL_SCORE,
    }
  },
  [WORKFLOW_STEPS.DOCUMENTS]: {
    showStatus: false,
    scoreCard: {
      title: 'Supporting Documents',
      collectScore: false,
      scoringCriteria: REVIEW_WORKFLOW_CONFIG_v4_DEFAULT_CRITERIA,
      totalScore: REVIEW_WORKFLOW_CONFIG_v4_TOTAL_SCORE,
    }
  },
  [WORKFLOW_STEPS.CONCLUSION]: {
    hide: false,
  }
};
