<ng-container *ngIf="!isPreview else preview">
  <h2 class="header-title h2 mb-l" id="qa_tab_title">Supporting Documents</h2>
  <!-- guidance -->
  <po-guidance-text [workflowFilter]="guidance" fieldName="guidance"></po-guidance-text>

  <div class="card">
    <div class="card-title">
      <h3>Supporting Documents</h3>
      <div class="help-text">
        <p>Upload copies of any documentation that will support your {{proposal?.type || 'proposal'}}.</p>
      </div>

      <ng-container *ngIf="docTypesToShow?.length">
        <p class="bold">Required documents include:</p>
        <ul class="bold list--plain margin-bottom required">
          <ng-container *ngFor="let docTypeId of docTypesToShow">
            <ng-container [ngTemplateOutlet]="getTemplateForDocType(docTypeId)"
                          [ngTemplateOutletContext]="{ docTypeId: docTypeId }">
            </ng-container>
          </ng-container>
        </ul>
      </ng-container>

      <ng-container *ngIf="isRCM">
        <ng-container [ngTemplateOutlet]="additionalHelpText"></ng-container>
      </ng-container>
    </div>

    <div class="card-body">
      <app-document-table
        [fileList]="fileList"
        [enableHideOldFiles]="false"
        [enableHidePastVersions]="false"
        [enableUpload]="canEdit"
        [formatDocTitleFn]="formatDocTitle"
        [columnsToShow]="{year: false, actions: true}"
        (addDocumentClicked)="onClickAddButton()"
        (deleteDocumentClicked)="onDeleteFile($event)"
      ></app-document-table>

      <app-document-upload-modal
        *ngIf="showUploadDocumentModal"
        [documentTypeIds]="docTypesForUpload"
        [creatorUserId]="userId"
        [proposalId]="proposalId"
        (closeSuccess)="onUploadSuccess($event)"
        (closeFail)="onUploadFailOrClose()"
        (closeCancel)="onUploadFailOrClose()"
      ></app-document-upload-modal>
    </div>

  </div>
</ng-container>

<!-- PREVIEW -->
<ng-template #preview>
  <div class="card">
    <div class="card-title">
      <h3 class="h3 mb-l">Supporting Documents</h3>
    </div>

    <app-document-table
      [fileList]="fileList"
      [enableHideOldFiles]="false"
      [enableHidePastVersions]="false"
      [enableUpload]="false"
      [formatDocTitleFn]="formatDocTitle"
      [columnsToShow]="{year: false, actions: false}"
    ></app-document-table>
  </div>
</ng-template>

<ng-template #successIcon let-docTypeId="docTypeId">
  <li>
    <icon iconId="check-circle" class="icon--success icon--vertical-align margin-right-xxs"></icon>
    {{getDocTypeName(docTypeId)}}
  </li>
</ng-template>

<ng-template #alertIcon let-docTypeId="docTypeId">
  <li [attr.data-invalid]="true">
    <icon iconId="alert" class="icon--negative icon--vertical-align margin-right-xxs"></icon>
    {{getDocTypeName(docTypeId)}}
  </li>
</ng-template>

<ng-template #additionalHelpText>
  <p>You may also upload:</p>
  <ul>
    <li>
      Letter of Commitment/Letter of Support (e.g. letters from Higher Education,
      Collaborative, and/or K-12 Partner Agencies indicating support for the application)
    </li>
  </ul>
  <p>
    <em>
      Do not upload any other types of documents, as they will not be reviewed
      or factored in the scoring process.
    </em>
  </p>
</ng-template>
