import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {DATE_TIME_MOMENT_FORMAT_STRINGS, NOT_ENTERED} from '@app-consts';

/**
 * This pipe is to be used when we need to do date formatting.
 * usage goes like {{ date | po_date [:'format string'] }}
 * the format string can be defined in @app-consts or can be
 * a format for Moment js https://momentjs.com/docs/#/displaying/format/
 */
@Pipe({ name: 'po_date' })
export class DateTransform implements PipeTransform {

  transform(unformatted: string, formatName = 'date'): string {
    // no timezone -> parsed as local time, timezone -> parsed as tz into local
    // MAKE SURE YOUR DATE IS SAVED ACCORDING TO HOW YOU WANT IT PARSED
    const date = moment.tz(unformatted, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone);
    if (date.valueOf()) {
      return date.format(getFormatString(formatName));
    } else {
      return 'N/A'
    }
  }
}

/**
 * formatName either can be a defined format by us, or a NG DatePipe predefined format name, (short, medium, etc.)
 * or a literal format string for Moment js https://momentjs.com/docs/#/displaying/format/
 * @param formatName
 */
function getFormatString(formatName: string): string {
  if (formatName === 'NOVA_timezone' || formatName === 'NOVA_defaultYear') {
    formatName = 'date'; // you shouldn't have done that, use normal date format
  }
  const formatString = DATE_TIME_MOMENT_FORMAT_STRINGS[formatName];
  return formatString ? formatString : formatName;
}
