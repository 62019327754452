<div [ngSwitch]="!!canEdit" class="date-picker">
  <label *ngIf="canEdit && showLabel()" for="{{inputId}}" [class.required]="required">
    {{labelText}}
  </label>

  <ng-content select=".help-text"></ng-content>

  <ng-container *ngSwitchCase="true">
    <mat-form-field floatLabel="never">
      <input matInput id="{{inputId}}" class="date-picker" [matDatepicker]="picker"
             [placeholder]="placeHolder"
             [formControl]="inputControl"
             [min]="minDate"
             [max]="maxDate"
             (dateChange)="valueChanged()"
             [required]="required">
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon svgIcon="icon-calendar"></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div class="input-footer">
      <show-errors class="error-text" [control]="inputControl" [showUntouched]="errorsOnUntouched"></show-errors>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="false">
    <p class="label-text" *ngIf="!canEdit && showLabel()">
      {{labelText}}
    </p>

    <div>
      {{getReadOnlyText()}}
    </div>
  </ng-container>
</div>
