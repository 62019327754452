<div *ngIf="!commentsDisabled && isCommentsOpen" id="comments_container_main" class="comments comments__container no-print" [@slideOver]>

  <div class="comments__heading flex--apart">
    <div class="comments-title">
      <icon iconId="message"></icon>
      <div class="comments-title__label">Comments</div>
    </div>
    <button class="close-button action-button--invisible" title="Close Comments" type="button"
      (click)="closeComments()">
      <icon iconId="close"></icon>
    </button>
  </div>

  <div class="comments__body" [ngSwitch]="!!commentThreads?.length">
    <div *ngSwitchCase="true" class="comments__comments">
      <div id="comments-content">
        <comment-card *ngFor="let thread of commentThreads; trackBy: trackById;"
          [currentUser]="currentUser"
          [threadId]="thread.comment?.temp_id || thread.comment?.id"
          [canEdit]="canEdit">
        </comment-card>
      </div>
    </div>
    <div *ngSwitchDefault [ngSwitch]="commentsLoaded" class="comments--empty">
      <div *ngSwitchCase="false" class="text-info">Checking for comments...</div>
      <ng-container *ngSwitchCase="true">
        <p><strong>No comments have been added</strong></p>
        <p>Highlight some text or click on a text field and add a comment</p>
      </ng-container>
    </div>
  </div>
</div>
