import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains'
import { EnumErrorTypes } from '@app-models';

export interface AttributePayload {
  key?: string,
  value?: any,
  storeValue?: any,
  ea?: any,
  parentEffortAreas?: Array<Model.EffortArea>, // List of this ea's parent in decending order (proposal => EA1 => EA2 => EA3)
  proposal_id?: number
};

export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export function toPayload(action: ActionWithPayload<any>): any {
  return action.payload;
}

export function errorPayload(error: any, location: string, show = false): ActionWithPayload<any> {
  if (error.error && (error.status >= 400 && error.status < 500)) {
    // send out failed message that can be corrected
    return Actions.App.showAlert(error.error.message);
  }

  return Actions.App.setError({
    type: EnumErrorTypes.api,
    raw: error,
    location,
    show
  });
}

/**
 * This class returns auto decremented id whenever its requested.
 */
export class NewTempId {
  static autoTempId = 0;

  static id(): number {
    return --NewTempId.autoTempId;
  }

  static isTemp(id: number): boolean {
    return id < 0;
  }
}
