<ng-container [ngSwitch]="!isPreview">
  <!-- Workflow -->
  <ng-container *ngSwitchCase="true">
    <h2 class="header-title h2 mb-l" id="qa_tab_title">Workplan: Outcomes</h2>

    <!-- guidance -->
    <po-guidance-text [workflowFilter]="guidance" fieldName="guidance"></po-guidance-text>

    <ng-container *ngTemplateOutlet="content"></ng-container>

    <div *ngIf="canEdit" class="btn-section btn-section--center">
      <button class="action-button action-button--primary icon--left" type="button" id="add_outcome_button"
        (click)="addOutcome()"
        [disabled]="add_disabled">
        <icon iconId="plus"></icon>
        Add Outcome
      </button>
    </div>
  </ng-container>

  <!-- Preview -->
  <ng-container *ngSwitchCase="false">
    <div class="card">
      <div class="card-title">
        <h3>Workplan: Outcomes</h3>
      </div>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</ng-container>

<!-- Content -->
<ng-template #content>
  <ng-container [ngSwitch]="!!workplanOutcomes?.length">
    <ng-container *ngSwitchCase="true">
      <outcomes-card *ngFor="let outcome of workplanOutcomes; let first = first; let count = index; trackBy: trackById;"
      [workplanOutcome]="outcome"
      [activityOptions]="activityOptions"
      [objectiveOptions]="objectiveOptions"
      [metricsOptions]="metricsOptions"
      [canEdit]="canEdit"
      [canDelete]="canDelete"
      [count]="count"
      [isCAI]="isCAI"
      [version]="version"
      [isPreview]="isPreview"
      [firstTouch]="firstTouch"
      [isFirst]="first">
    </outcomes-card>
  </ng-container>

  <p *ngSwitchCase="false" class="empty-state">No outcomes have been added.</p>
  </ng-container>
</ng-template>
