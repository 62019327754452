import { Model } from '@app-ngrx-domains';
import { ProposalBase } from './proposal-base';
import { TOP_CODES_HEALTH } from '@app-consts';
import { uniq } from 'lodash';

export const SPENDING_YEAR_v1_MAX = 2022;
export const SWP_v2_FAQ_URL = `https://www.cccco.edu/About-Us/Chancellors-Office/Divisions/Workforce-and-Economic-Development/Strong-Workforce-Program/Frequently-Asked-Questions`;
export const SWP_UNSPENT_FUNDS_MEMO = 'https://www.cccco.edu/About-Us/Chancellors-Office/Divisions/Workforce-and-Economic-Development/Strong-Workforce-Memos';
export const SWP_v3_HMI_REPORTING_URL = 'https://ca-hwi.org/sb1348/';

export interface ISWPProposal extends Model.ProposalItem {
  // shared
  // Surveys
  survey_responses: Array<Model.EASurveyResponse>;
  funding_year: number;

  // v2
  // Details
  uses_expired_funds?: boolean; // v1 only
  application_region: number;
  reason_for_continuation: string;
  relates_to_allied_health: boolean;
  includes_training: boolean;
  assurance_ids: Array<Model.AttributeValue>;
  impacted_groups: Array<Model.AttributeValue>;
  // v3 Details
  impacted_students?: number;
  impact_explanation?: string;
  includes_workbased_learning?: boolean;
  workbased_learning_sector?: string;
  apprenticeship_support?: boolean;

  // Employer Partners
  training_offered: string;
  swp_collaborative_partners: Array<Model.EASWPCollaborativePartner>;
  // LMI
  occupational_clusters: Array<Model.EAOccupationalCluster>;
  // Metrics
  vision_goals: Array<Model.EAVisionGoal>;
  target_goals: Array<Model.EATargetGoal>;
  plan_investments: string;
  plan_successes: string;
  plan_changes: string;
  // Objectives
  workplan_objectives: Array<Model.EAWorkplanObjective>;
  // Activities
  workplan_activities: Array<Model.EAWorkplanActivity>;
  // Budget
  plan_budget_items: Array<Model.EAPlanBudgetItem>;
  plan_expenditure_forecasts: Array<Model.EAPlanExpenditureForecast>;
  total_revised_amount?: number; // v2 & beyond
  // Allied Health
  training_sites: Array<Model.EATrainingSite>;
  // v3 Allied Health
  completed_hwi_reporting?: boolean;
  ah_plan_id?: string;
  requires_training_sites?: boolean;

  // Success Story
  success_story: Array<Model.EASuccessStory>;
}

export class ProposalSWP extends ProposalBase {

  /**
   * Returns true if LMI covers health programs.
   */
  static includesLmiHealthPrograms(p: Model.SWPProposal, topCodes: Array<any>): boolean {
    const occupationalClusters = p.occupational_clusters;
    if (!occupationalClusters.length) {
      return false;
    }

    const includes = occupationalClusters.some(oc => {
      return oc.lmi_programs && oc.lmi_programs.length
        && oc.lmi_programs.some(lp => {
          const tc = topCodes.find(c => c.id === lp.top_code_id);
          return TOP_CODES_HEALTH.includes(tc.code);
        });
    });

    return includes;
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type SWPProposal = ISWPProposal;
  }
}
