<div class="bidirectional-control-input">
  {{ rank ? rank : '-' }}
  <div class="columns columns--gap-small">
    <button class="action-button action-button--invisible" (click)="up.emit(id)" [disabled]="!canEdit">
      <icon iconId="arrow-up"></icon>
    </button>
    <button class="action-button action-button--invisible" (click)="down.emit(id)" [disabled]="!canEdit">
      <icon iconId="arrow-down"></icon>
    </button>
  </div>
</div>
