import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { CalendarSpec, CalendarSpecVal } from 'moment';
import {DATE_TIME_MOMENT_FORMAT_STRINGS, NOT_ENTERED} from '@app-consts';

/**
 * This pipe is to be used when we need to do date formatting.
 * usage goes like {{ date | po_date [:'<CalendarDateFormat> object'] }}
 * https://momentjs.com/docs/#/customization/calendar/
 */
@Pipe({ name: 'po_calendar_date' })
export class CalendarDateTransform implements PipeTransform {
  calendarFormat: CalendarDateFormat = {
    lastDay : 'LT [Yesterday]',
    sameDay : '[Today at] lt',
    nextDay : '[Tomorrow at] lt',
    lastWeek : 'l LT',
    nextWeek : 'l LT',
    sameElse : 'dateTime24HoursTZ'
  };

  transform(unformatted: string, calendarFormat: CalendarDateFormat = this.calendarFormat ): string {
    const calendarSpec: CalendarSpec = {};

    for (const key of Object.keys(calendarFormat)) {
      calendarSpec[key] = getFormatString(calendarFormat[key].toString());
    }

    const date = moment(unformatted).calendar(null, calendarSpec);

    if (date.valueOf()) {
      return date
    } else {
      return 'Unknown Date'
    }
  }
}

/**
 * formatName either can be a defined format by us, or a NG DatePipe predefined format name, (short, medium, etc.)
 * or a literal format string for Moment js https://momentjs.com/docs/#/displaying/format/
 * @param formatName
 */
function getFormatString(formatName: string): CalendarSpecVal {
  if (formatName === 'NOVA_timezone' || formatName === 'NOVA_defaultYear') {
    formatName = 'date'; // you shouldn't have done that, use normal date format
  }
  const formatString = DATE_TIME_MOMENT_FORMAT_STRINGS[formatName];
  return formatString ? formatString : formatName;
}

/**
 * Used to create a CalendarSpec object.
 * Values can be either moment format strings or PO defined formats
 *
 * @interface CalendarDateFormat
 * @lastDay {string} format for dates that are yesterday
 * @sameDay {string} format for dates that are today
 * @nextDay {string} format for dates that are tomorrow
 * @lastWeek {string} format for dates that are lastweek
 * @nextWeek {string} format for dates that are nextweek
 * @sameElse {string} format for all other dates
 */

export interface CalendarDateFormat {
  lastDay?: string;
  sameDay?: string;
  nextDay?: string;
  lastWeek?: string;
  nextWeek?: string;
  sameElse?: string;
}

