<div class="filter-section" [ngClass]="{ 'filter-section--open': isOpen }">
  <div class="flex flex--justify-content-between">
    <label for="collapse_filter_{{filter.label}}" class="label-text">{{ filter.label }}</label>

    <p *ngIf="selectedOptions?.length as filterCount" class="text-info margin-bottom-none">
      {{filterCount}} Filter{{filterCount > 1 ? 's' : ''}} Applied
    </p>

    <button id="collapse_filter_{{filter.label}}" class="text-button collapse-button"
      (click)="isOpen = !isOpen">
      <icon iconId="disclosure-down"></icon>
    </button>
  </div>
  <div *ngIf="isOpen" [@collapsible] class="search-results">
    <search-input
      id="modal_search_input_{{ filter.label }}"
      class="margin-top-xs margin-bottom-xs"
      placeholderText="Search {{ filter.label | lowercase }}s"
      (search)="search($event)">
    </search-input>
    <div class="search-results-list" [ngSwitch]="!!results.length">
      <ng-container *ngSwitchCase="true">
        <div class="filter-item" *ngFor="let option of results">
          <input id="filter_section_{{filter.field}}_{{option.value}}"
            class="margin-right-sm"
            type="checkbox"
            [(ngModel)]="option.checked"
            (change)="onFilterChanged()" />
          <label for="filter_section_{{filter.field}}_{{option.value}}">{{ option.value }}</label>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>No matches found.</ng-container>
    </div>
  </div>
</div>