import { Component, OnInit, OnDestroy, Input, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { AREAS, WORKFLOW_STEPS, PROGRAM_KEYS, PROGRAM_DOCUMENT_TYPES, WORKFLOW_TYPES, PROPOSAL_TYPES } from '@app-consts';
import { ApiService, LookupService, PermissionsService, ProgramService } from '@app-services';
import { Proposal } from '@app-models';
import { PlanWorkflowComponent } from '@app/RCM/components/workflows/plan-workflow.component';
import { PlanWorkflowValidatorService } from '@app/RCM/validators/plan-workflow-validator.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
})
export class DocumentsComponent extends PlanWorkflowComponent implements OnInit, OnDestroy {
  @Input() isPreview = false;
  @Input() guidance: Model.GuidanceWorkflowFilter;

  @ViewChild('successIcon', {static: true}) successIcon: TemplateRef<any>;
  @ViewChild('alertIcon', {static: true}) alertIcon: TemplateRef<any>;

  canEdit = false;
  firstTouch = true;

  docTypes: Array<Model.DocumentType> = [];
  docTypesForUpload: Array<number> = [];
  docTypesToShow: Array<number> = [];
  proposalId: number;
  userId: number;

  proposal: Model.ProposalItem;
  fileList: Array<Model.Document> = [];
  showUploadDocumentModal = false;

  // Input values for the DocumentUploadModalComponent
  program: Model.Fund;
  programKey: string;

  private destroy$: Subject<boolean> = new Subject();
  private isPlan: boolean;
  private workflowName: string;
  private initialized = false;

  constructor(
    protected store: Store<State>,
    protected apiService: ApiService,
    protected programService: ProgramService,
    protected permissionsService: PermissionsService,
    protected lookupService: LookupService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected workflowValidatorService: PlanWorkflowValidatorService,
  ) {
    super(store, apiService, programService, permissionsService, lookupService, route, router, workflowValidatorService);
  }

  ngOnInit() {
    if (!!this.guidance) {
      // Guidance template mode
      this.setupAsGuidanceTemplate();
      return;
    }

    this.store.select(Queries.Files.getItems)
    .pipe(
      withLatestFrom(
        this.store.select(Queries.CurrentProposal.get),
        this.store.select(Queries.Auth.getCurrentUser),
        this.store.select(Queries.LookupTables.getDocumentTypes),
        this.store.select(Queries.Workflow.getCurrent)
      ),
      takeUntil(this.destroy$)
    )
    .subscribe((data) => {
      const [files, proposal, user, docTypes, workflow] = data;
      if (!proposal.id) {
        // ignore - in transition.
        return;
      }

      this.proposal = proposal;
      this.programKey = Proposal.getProgramKey(this.proposal);
      this.program = this.programService.getProgramById(this.proposal.funds[0].id);
      this.docTypes = docTypes;
      this.isPlan = Proposal.isPlan(proposal);
      this.workflowName = workflow.name;

      if (!this.isPreview) {
        this.currentWorkflowStep = WORKFLOW_STEPS.DOCUMENTS;
      }

      this.initialize();
      this.firstTouch = !files.length;

      this.fileList = files.filter(f => this.docTypesForUpload.includes(f.document_type_id));
      this.proposalId = proposal.id;
      this.userId = user.id;

    });
  }

  /**
   * Initializes the vars that need to be set up just once.
   * */
  private initialize() {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;

    if (!this.guidance) {
      let workflowName = this.workflowName;
      if (this.workflowName === WORKFLOW_TYPES.RCM_PLAN) {
        workflowName = WORKFLOW_TYPES.RCM;
      }
      this.guidance = {
        programId: this.proposal.funds[0].id,
        proposalType: PROPOSAL_TYPES.APPLICATION,
        workflowName: workflowName,
        stepName: this.currentWorkflowStep,
      }
    }

    let programDocTypes = [];
    if (this.program.is_small_program) {
      this.program.program_settings.supporting_document_types.forEach(doc => {
        programDocTypes.push({ id: doc.document_type.id, required: doc.required });
      });
    } else {
      programDocTypes = PROGRAM_DOCUMENT_TYPES[this.programKey];
    }

    if (this.isRCM && this.isPlan) {
      super.ngOnInit();
    }

    this.docTypesToShow = programDocTypes.filter(type => type.required).map(t => t.id);

    // Configure doc types to allow for upload, other than the once that are machine generated.
    this.docTypesForUpload = this.docTypes.filter(dt => {
      const included = programDocTypes.find(type => type.id === dt.id);
      return included && !!dt.deletable;
    }).map(dt => dt.id);

    if (!this.isPreview) {
      this.fetchPermissions();
      // current this as the current step & put up Next button.
      this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));
    }
  }

  /**
   * Fetches permission(s) then sets up action button(s)
   */
  private fetchPermissions() {
    const extraScope: Model.PermResourceScope = {};
    if (this.proposal.lead_institution && this.proposal.lead_institution.id) {
      extraScope.institution_id = this.proposal.lead_institution.id;
    }
    this.permissionsService.canEditProject(this.proposal, AREAS.PROJECT, this.route.snapshot, extraScope)
      .pipe(takeUntil(this.destroy$))
      .subscribe(canEdit => {
        this.canEdit = canEdit;
      });
  }

  ngOnDestroy() {
    if (this.isRCM && this.isPlan) {
      super.ngOnDestroy();
    }

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ////////// METHODS USED BY TEMPLATE ////////////
  // Title formatter function
  formatDocTitle(file: Model.Document) {
    return file.title || file.filename;
  }

  getDocTypeName(id: number): string {
    const docType = this.docTypes.find(doc => doc.id === id);
    return docType ? docType.name : 'Unknown Document Type';
  }

  getTemplateForDocType(id: number) {
    const exists = this.fileList.find(file => file.document_type_id === id);
    if (exists) {
      return this.successIcon;
    } else {
      return this.alertIcon;
    }
  }

  get isRCM(): boolean {
    return this.programKey === PROGRAM_KEYS.RCM;
  }

  onClickAddButton() {
    this.showUploadDocumentModal = true;
  }

  onUploadSuccess(file: Model.Document) {
    this.store.dispatch(Actions.Files.append(file));
    this.showUploadDocumentModal = false;
  }

  onUploadFailOrClose() {
    this.showUploadDocumentModal = false;
  }

  onDeleteFile(fileId) {
    this.store.dispatch(Actions.Files.delete(fileId));
  }

  setupAsGuidanceTemplate() {
    this.initialized = true;
  }
}
