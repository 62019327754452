import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { LookupService, ProgramService } from '@app-services';
import { ValidatorsEx } from '@app-utilities';
import { EnumErrorTypes, Profile } from '@app-models';
import { PROJECT_ROLES } from '@app/core/consts';

@Component({
  selector: 'app-agency-contacts',
  templateUrl: './agency-contacts.component.html'
})
export class AgencyContactsComponent implements OnInit, OnDestroy {
  @Input() canEdit = false;
  @Input() required = true;
  @Input() fundId: number;
  @Input() proposalId: number;
  @Input() canDelete: boolean;
  @Input() contacts_raw: Model.UserRoleScope[];
  @Input() contactTypes: Array<Model.ContactTableType>;
  @Input() agencyLabel: string;
  @Input() restrictToOneLead = false;
  @Input() versionNumber: number;
  @Input() set selectedInstitution(agency: Model.Institution) {
    this._selectedInstitution = agency;
    this.reinitialize();
  }
  get selectedInstitution() {
    return this._selectedInstitution;
  }
  _selectedInstitution: Model.Institution;
  @Output() removeInstitutionAction: EventEmitter<any> = new EventEmitter();

  requiredContacts: Array<Model.ContactTableType> = [];
  contactOptions: Array<Model.SelectOption>;
  contacts: Array<Model.UserRoleScope> = [];
  profiles: Array<Profile> = [];
  other_contacts: Array<Model.UserRoleScope> = [];
  ownUser: Profile;

  tempContactToDelete: Model.UserRoleScope;
  userOptionsList: Array<Model.SelectOption> = [];

  form: FormGroup;
  formInviteContact: FormGroup;
  formInviteContactConfig: any;
  showAddContact: boolean;
  showInviteContact: boolean;

  programKey: string;
  institutionIdString: string;
  canAddContact = false;
  hasLead = false;
  showLeadAlert = false;
  existingLead: Model.UserRoleScope;
  roleToSave: { user: Model.SelectOption, role_id: number };

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private _fb: FormBuilder,
    private lookupService: LookupService,
    private programService: ProgramService,
    private store: Store<State>,
  ) {
    this.form = this._fb.group({
      user: [undefined, [Validators.required]],
      role_id: [0, [ValidatorsEx.requiredSelection]],
    });

    // initialize invite contact form group
    this.formInviteContact = this._fb.group({
      role_id: [0, [ValidatorsEx.requiredSelection]],
    });
  }

  ngOnInit() {
    this.programKey = this.fundId ? this.programService.getProgramParentKeyById(this.fundId) : undefined;
    this.contactOptions = (this.contactTypes || [])
      .map(ct => ({ value: ct.roleId, label: ct.roleName, extras: ct }))
      .sort((a, b) => a.label > b.label ? 1 : -1);
    this.hasLead = this.contactOptions.length && this.contactTypes.some(ct => ct.isLead);

    // Set up the form config for additional field on the invite-user form
    this.formInviteContactConfig = {
      role_id: {
        label: 'Role / Responsibility',
        type: 'select',
        select_options: this.contactOptions,
        required: true,
        placeholder: `Select Contact's Responsibility`
      }
    };

    this.store.select(Queries.Contacts.get).pipe(
      withLatestFrom(this.store.select(Queries.Auth.getCurrentUser)),
      takeUntil(this.destroy$)
    ).subscribe(([contacts, ownUser]) => {
      this.contacts_raw = contacts;
      this.ownUser = ownUser;
      this.reinitialize();
    });
  }

  private reinitialize() {
    if (!this.proposalId || !this.contactTypes || !this.contacts_raw ) {
      return;
    }

    this.institutionIdString = this.selectedInstitution ? `${this.selectedInstitution.id}` : 'lead';
    this.canAddContact =  !!this.selectedInstitution;

    const agency_id = this.selectedInstitution ? this.selectedInstitution.id : undefined;
    this.other_contacts = agency_id ? this.contacts_raw.filter(contact => contact.institution_id !== agency_id) : [];

    this.contacts = [];
    this.contactTypes.forEach(ct => {
      if (ct.isLead) {
        const matched = this.contacts_raw.filter(contact => ct.roleId === contact.role_id);
        this.contacts.push(...matched);
      } else {
        const matched = this.contacts_raw.filter(contact => ct.roleId === contact.role_id && contact.institution_id && contact.institution_id === agency_id);
        this.contacts.push(...matched);
      }
    });

    this.requiredContacts = this.contactTypes.filter(type => !!type.numberRequired)
      .map(type => ({ ...type, roleName: this.getRoleName({ role_id: type.roleId }) }));
  }

  updateShowLeadAlert() {
    if (!this.restrictToOneLead) {
      return;
    }
    const projectLeadId = PROJECT_ROLES.PROJECT_LEAD.ID;
    const selectedRoleIsLead = this.form.get('role_id').value === projectLeadId;

    if (!selectedRoleIsLead) {
      return;
    }

    const existingLeads = this.contacts.filter(c => c.role_id === projectLeadId);
    this.showLeadAlert = existingLeads.length >= 1;
    this.existingLead = this.showLeadAlert ? existingLeads[0] : undefined;
  }

  checkBeforeAddContact() {
    this.updateShowLeadAlert();
    if (!this.showLeadAlert) {
      this.addContact();
    } else {
      this.roleToSave = this.form.value;
      this.closeContactModals();
    }
  }

  dismissLeadAlert() {
    this.showLeadAlert = false;
    this.roleToSave = undefined;
    this.existingLead = undefined;
  }

  canRemoveContacts(contact: Model.UserRoleScope) {
    const contactType = this.contactTypes.find(ct => ct.roleId === contact.role_id);
    const matched = this.contacts.filter(c => c.role_id === contact.role_id);
    return !!contactType.numberRequired ? matched.length > contactType.numberRequired : true;
  }

  closeContactModals() {
    this.showAddContact = false;
    this.showInviteContact = false;
    this.form.reset();
    this.formInviteContact.reset();
  }

  addContact(userId?: number, roleId?: number) {
    const selected_user_id = userId ? userId : this.form.get('user').value.value;
    const selected_role_id = roleId ? roleId : this.form.get('role_id').value;
    const role_scopes = [];
    const base_role = {
      fund_id: this.fundId,
      proposal_id: this.proposalId,
      user_id: selected_user_id,
      role_id: selected_role_id
    };

    const contactType = this.contactTypes.find(ct => ct.roleId === selected_role_id);
    const contactIsLeadAlternate = contactType.roleId === PROJECT_ROLES.PROJECT_LEAD_ALTERNATE.ID;
    if (contactIsLeadAlternate || contactType.isLead) {
      role_scopes.push(base_role);
    } else {
      role_scopes.push({ ...base_role, institution_id: this.selectedInstitution.id });
      if (contactType.shadowRoleId) {
        const has_alt_contact = this.other_contacts.find(c =>
          (c.role_id === contactType.shadowRoleId && c.user_id === selected_user_id));
        if (!has_alt_contact) {
            role_scopes.push({ ...base_role, role_id: contactType.shadowRoleId });
        }
      }
    }

    role_scopes.forEach(role => this.store.dispatch(
      Actions.Contacts.add(role, (contactType.shadowRoleId && role.role_id === contactType.shadowRoleId) ? undefined : contactType.notify_on_assignment))
    );

    this.closeContactModals();
  }

  // Enforce restrictToOneLead by reassigning existing Project Lead to Alternate Lead
  replaceLeadContact() {
    if (this.existingLead) {
      const alternateLeadRoleId = this.versionNumber >= 4 ? PROJECT_ROLES.PROJECT_LEAD_ALTERNATE.ID : PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID;
      this.removeContact(this.existingLead);
      this.addContact(this.existingLead.user_id, alternateLeadRoleId);
      this.addContact(this.roleToSave.user.value, this.roleToSave.role_id);
      this.dismissLeadAlert();
    }
  }

  alertContactRemoval(contact: Model.UserRoleScope) {
    // if deleting self, show alert if user has no redundant role to provide access to proposal
    if (contact.user_id === this.ownUser.id)  {
      const contactType = this.contactTypes.find(ct => ct.roleId === contact.role_id);
      if (contactType.isLead || contactType.shadowRoleId) {
        const ownContactRoles = this.contacts_raw.filter(c => c.user_id === this.ownUser.id);
        const primaryContactRoles = this.contactTypes.filter(ct => ct.isLead || ct.shadowRoleId).map(ct => ct.roleId);
        const hasPrimaryRoles = ownContactRoles.some(c => primaryContactRoles.includes(c.role_id));
        if (hasPrimaryRoles) {
          // show alert
          this.tempContactToDelete = contact;
          return;
        }
      }
    }

    // delete without prompt
    this.removeContact(contact);
  }

  removeContact(contact: Model.UserRoleScope) {
    this.store.dispatch(Actions.Contacts.delete(contact));

    const contactType = this.contactTypes.find(ct => ct.roleId === contact.role_id);
    if (contactType.shadowRoleId) {
      const has_other_contact = this.other_contacts.find(c => c.role_id === contactType.roleId && c.user_id === contact.user_id);
      if (!has_other_contact) {
        // Also delete the alternate contact
        this.store.dispatch(Actions.Contacts.delete({
          user_id: contact.user_id,
          fund_id: contact.fund_id,
          proposal_id: contact.proposal_id,
          role_id: contactType.shadowRoleId
        }));
      }
    }
    this.dismissDeleteAlert();
  }

  dismissDeleteAlert() {
    this.tempContactToDelete = undefined;
  }

  toggleAddContact() {
    this.showAddContact = !this.showAddContact;
  }

  showInviteModal() {
    this.showAddContact = false;
    this.showInviteContact = true;
  }

  inviteSuccess(user: Model.User) {
    this.form.get('user').setValue({ value: user.id });

    const roleId = this.formInviteContact.get('role_id').value;
    this.form.get('role_id').setValue(roleId);

    this.addContact();
  }

  updateContactsList(filter, limit) {
    this.lookupService.formattedContactList$(filter, limit).subscribe(
      (res) => {
        this.userOptionsList = res;
      },
      (err) => {
        this.store.dispatch(Actions.App.setError({
          type: EnumErrorTypes.api,
          location: this.constructor.name,
          show: true,
          raw: err
        }));
      }
    );
  }

  formatContactName(user: Model.User) {
    return Profile.contactFullName(user) || 'N/A';
  }

  getContactEmail(user: Model.User) {
    return user && user.email_address ? user.email_address : 'N/A';
  }

  getRoleName(contact: Model.UserRoleScope) {
    const contactType = this.contactTypes.find(ct => ct.roleId === contact.role_id);
    return contactType ? contactType.roleName : 'N/A'
  }

  getRequirementString(numberRequired: number) {
    return `(minimum ${numberRequired} ${numberRequired === 1 ? 'contact' : 'contacts'} required)`;
  }

  isRequirementComplete(contactType: Model.ContactTableType) {
    const contactsOfType = this.contacts.filter(contact => contact.role_id === contactType.roleId);
    return contactsOfType.length >= contactType.numberRequired;
  }

  emitRemoveInstitution() {
    this.removeInstitutionAction.emit();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
