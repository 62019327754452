import {AfterContentInit, Component, ChangeDetectorRef, forwardRef, Input, Output, EventEmitter, Injector, ElementRef, HostBinding, OnDestroy} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NgControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { AppUtils } from '@app-utilities';
import { DATE_TIME_MOMENT_FORMAT_STRINGS } from '@app-consts';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const MONTH_DAY_YEAR_FORMATS = {
  parse: {
    dateInput: DATE_TIME_MOMENT_FORMAT_STRINGS.date,
  },
  display: {
    dateInput: DATE_TIME_MOMENT_FORMAT_STRINGS.date,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'po-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_DAY_YEAR_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatePickerComponent), multi: true }
  ],
})

export class DatePickerComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
  @HostBinding('attr.data-invalid') invalidState = false;
  @Input() labelText: string;
  @Input() labelHidden = false;
  @Input() canEdit = true;
  @Input() errorsOnUntouched = false;
  @Input() placeHolder: string =  'mm/dd/yyyy';
  @Input() required: boolean = false;
  @Input() set minDateString(date: string) {
    this._minDateString = date;
    this.minDate = moment.tz(this.minDateString, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone).toDate();
  }
  @Input() set maxDateString(date: string) {
    this._maxDateString = date;
    this.maxDate = moment.tz(this.maxDateString, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone).toDate();
  }

  get minDateString(): string {
    return this._minDateString;
  }
  get maxDateString(): string {
    return this._maxDateString;
  }
  private _minDateString: string = '2007-01-01';
  private _maxDateString: string = '2030-12-31';

  @Output() dateChange: EventEmitter<string | { month: number, day: number }> = new EventEmitter();

  inputControl: FormControl;
  minDate: Date;
  maxDate: Date;
  inputId: string;

  protected lastValue: string;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private el: ElementRef,
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {
    this.inputId = `${this.el.nativeElement.id}_picker_input`;
  }

  ngAfterContentInit() {
    this.inputControl = this.injector.get(NgControl).control as FormControl;

    this.minDate = moment.tz(this.minDateString, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone).toDate();
    this.maxDate = moment.tz(this.maxDateString, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone).toDate();

    if (this.inputControl) {
      this.checkValidation();
      this.inputControl.statusChanges.pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.checkValidation();
      });
    }
  }

  valueChanged() {
    const value = !AppUtils.isNil(this.inputControl.value) ? this.inputControl.value.toISOString() : null;
    if (value !== this.lastValue) {
      this.lastValue = value;

      this.onChange(value);

      if (this.dateChange) {
        this.dateChange.emit(value);
      }
    }
  }

  showLabel() {
    return this.labelText && !this.labelHidden;
  }

  getReadOnlyText() {
    if (this.inputControl) {
      return moment(this.inputControl.value).format(DATE_TIME_MOMENT_FORMAT_STRINGS.date);
    }
  }

  /////////////////////////////////////////////////////////////////////
  // ControlValueAccessor
  /////////////////////////////////////////////////////////////////////
  writeValue(value: string) {
    if (!AppUtils.isNil(value) && this.inputControl) {
      const utcValue = moment.utc(value).toISOString();

      // set the initial value.
      this.lastValue = utcValue;
    }
  }

  onChange = (_: any) => { };
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  onTouched = (_: any) => { };
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (this.inputControl) {
      if (isDisabled) {
        this.inputControl.disable();
      } else {
        this.inputControl.enable();
      }
    }
  }

  private checkValidation() {
    // SetTimeout to avoid expressionChangedAfterChecked error
    setTimeout(() => {
      this.invalidState = !this.inputControl.valid;
      this.cdr.markForCheck();
    }, 0);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
