import { IAuthState, Query, Queries, Model, combineRootFactory } from '@app-ngrx-domains';
import { Profile } from '../models/user';
import { UserMessage } from '../models/user-message';

/**
 * Get the root of auth states.
 */
const fromRoot = combineRootFactory<IAuthState>('Auth');

/**
 * Predefined queries to run against auth states.
 */
export interface IAuthQueries {
  isLoggedIn: Query<boolean>;
  isInProgress: Query<boolean>;
  canSudo: Query<boolean>;
  isSudoLoggedIn: Query<boolean>;
  getCurrentUser: Query<Profile>;
  getCurrentUserId: Query<number>;
  getCurrentUserMessages: Query<Array<Model.UserMessage>>;
  getCurrentUserUnreadMessages: Query<Array<Model.UserMessage>>;
  getLatestUserMention: Query<Model.UserMessage>;
  getErrors: Query<Object>;
  getGuardStates: Query<{ isLoggedIn: boolean, isInProgress: boolean, isWaitingOnUser: boolean }>;
  getFullName: Query<string>;
  getVisibleFunds: Query<Array<number>>;

  shouldWatchRoute: Query<boolean>;
}

Queries.Auth = {
  isLoggedIn: fromRoot(state => state.isLoggedIn),
  isInProgress: fromRoot(state => state.inProgress),
  canSudo: fromRoot(state => state.canSudo),
  isSudoLoggedIn: fromRoot(state => state.isSudoLoggedIn),
  getCurrentUser: fromRoot(state => state.user),
  getCurrentUserId: fromRoot(state => (state.user ? state.user.id : null)),
  getCurrentUserMessages: fromRoot(state => state.userMessages),
  getCurrentUserUnreadMessages: fromRoot(state => state.unreadMessages),
  getLatestUserMention: fromRoot(state => {
    return state.unreadMessages.find(message => message.notification_type === 'mention');
  }),
  getErrors: fromRoot(state => state.error),
  getGuardStates: fromRoot(state => ({
    isLoggedIn: state.isLoggedIn,
    isInProgress: state.inProgress,
    isWaitingOnUser: state.waitingOnUserToLogin,
  })),
  getFullName: fromRoot(state => (state.user ? `${state.user.first_name} ${state.user.last_name}` : '')),
  getVisibleFunds: fromRoot(state => state.visibleFunds),

  shouldWatchRoute: fromRoot(state => state.refreshRouteGuards)
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Auth: Query<IAuthState>;
  }

  interface Queries {
    Auth: IAuthQueries;
  }
}
