import { concat, of, EMPTY } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Root, State, Actions as DomainActions, Model, Queries, IWorkflowState } from '@app-ngrx-domains'
import { toPayload } from '@app-libs';
import { ACTION_BUTTONS, WORKFLOW_TYPES } from '@app-consts';
import { WORKFLOW_ACTION_TYPES } from './workflow.action';

/**
 * Injectable Workflow effects class
 */
@Injectable()
export class WorkflowEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>
  ) {
  }

  /**
   * Based on current step, it routes the user to the next step in the workflow.
   */
  gotoNext$ = createEffect(() => this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.GOTO_NEXT),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Workflow)),
    switchMap(data => {
      const [next, workflowState] = [...data];
      const workflowKind = (next.kind === WORKFLOW_TYPES.CURRENT) ? workflowState.current : next.kind;
      const currentStep = next.currentStep;
      const currentWorkflow = workflowState.workflows[workflowKind];

      const currentIndex = currentWorkflow.steps.findIndex((step: Model.WorkflowStep) => (step.route === currentStep));
      if (currentIndex < 0) {
        // couldn't find the match... ignore.
        return EMPTY;
      }

      let nextIndex = currentIndex;
      for (let i = currentIndex + 1; i < currentWorkflow.steps.length; i++) {
        if (!currentWorkflow.steps[i].hide) {
          if (!!currentWorkflow.steps[i].workflow && !!currentWorkflow.steps[i].workflow.section) {
            // go into 1st step of the section if it's not empty.
            if (!!currentWorkflow.steps[i].steps && !!currentWorkflow.steps[i].steps.length) {
              nextIndex = i;
              break;
            }
          } else {
            nextIndex = i;
            break;
          }
        }
      }

      if (nextIndex <= currentIndex) {
        // there is no next step to go to... ignore.
        return EMPTY;
      }

      // get next step's routing info.
      let nextStep = currentWorkflow.steps[nextIndex].route;
      let nextRouterLink = currentWorkflow.steps[nextIndex].routerLink;
      if (!!currentWorkflow.steps[nextIndex].workflow && !!currentWorkflow.steps[nextIndex].workflow.section) {
        // go to section's first element.
        const sectionStep = currentWorkflow.steps[nextIndex].steps[0];
        nextStep = sectionStep.route;
        nextRouterLink = sectionStep.routerLink;
      }

      const nextUrl = !nextRouterLink
        ? `${currentWorkflow.baseLink}/${nextStep}`
        : `${currentWorkflow.baseLink}/${nextRouterLink}`;
      return of(DomainActions.App.go([nextUrl], undefined, true));
    })
  ));

  /**
   * Sets current step of the current workflow and puts up a
   * Next button if requested.
   */
  setCurrentStep$ = createEffect(() => this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.SET_CURRENT_STEP),
    map(toPayload),
    switchMap(payload => {
      const actions: Action[] = [];
      // set current step.
      actions.push(DomainActions.Workflow.setProps({currentStep: payload.step}));
      // show next button on header.
      if (payload.showNext) {
        actions.push(DomainActions.Layout.appendActions({
          id: ACTION_BUTTONS.NEXT,
          type: 'button',
          title: 'Next',
          route: payload.step,
          class: 'primary',
          footer: true
        }));
      } else {
        actions.push(DomainActions.Layout.appendActions([]));
      }

      // Dispatch actions. These are executed in order.
      return concat([
        ...actions
      ]);
    })
  ));

  /**
   * Shows or hides steps in the workflow.
   */
  showSteps$ = createEffect(() => this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.SHOW_STEPS),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Workflow)),
    switchMap(([req, workflowState]) => {
      const workflowKind = (req.workflowType === WORKFLOW_TYPES.CURRENT) ? workflowState.current : req.workflowType;
      const workflow = workflowState.workflows[workflowKind];

      const steps = [];
      workflow.steps.forEach(s => {
        if (req.exclude.includes(s.route)) {
          if (req.excludeHide.includes(s.route)) {
            // toggle hide this excluded step
            steps.push({
              route: s.route,
              hide: req.show,
            })
          }
        } else {
          steps.push({
            route: s.route,
            hide: !req.show,
          });
        }
      });

      return of(DomainActions.Workflow.updateSteps(steps, req.workflowType));
    })
  ));

  showSteps_new$ = createEffect(() => this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.SHOW_STEPS_new),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Workflow)),
    switchMap(([req, workflowState]) => {
      const { steps, workflowType } = req;
      const workflowKind = (req.workflowType === WORKFLOW_TYPES.CURRENT) ? workflowState.current : workflowType;
      const workflow = workflowState.workflows[workflowKind];

      const updates = workflow.steps.map(step => ({
        route: step.route,
        hide: !steps.includes(step.route)
      }));

      return of (DomainActions.Workflow.updateSteps(updates, workflowType));
  })));

  removeStepFromSection$ = createEffect(() => this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.REMOVE_STEP_FROM_SECTION),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Workflow.getState)),
    switchMap(([payload, workflowState]) => {
      const workflowName = (payload.kind === WORKFLOW_TYPES.CURRENT) ? workflowState.current : payload.kind;
      const workflow = workflowState.workflows[workflowName];
      if (!workflow) { return EMPTY; }

      const sectionStep = workflow.steps.find(step => step.route === payload.sectionName);
      if (!sectionStep) { return EMPTY; }

      const sectionValid = sectionStep.steps.every(step => (step.route === payload.stepName) || step.valid);
      if (sectionValid !== sectionStep.valid) {
        return of(DomainActions.Workflow.updateSteps([{ route: sectionStep.route, valid: sectionValid }], workflow.name));
      }

      return EMPTY;
    })
  ));

  propagateWorkflowValidity(workflow: Model.Workflow, parentWorkflow: Model.Workflow, updatedSteps: Array<Model.WorkflowStep> = []) {
    const parentWorkflowStep = parentWorkflow.steps.find(step => step.route === workflow.parent.route);
    if (!parentWorkflowStep) { return EMPTY; }

    const stepIsValid = (step: Model.WorkflowStep) => {
      return step.hide || !step.showStatus || step.valid;
    };

    const allValid = workflow.steps.every(step => {
      const updates = updatedSteps.find(update => update.route === step.route) || {};
      return stepIsValid({ ...step, ...updates });
    });

    if (parentWorkflowStep.workflow.section) {
      const sectionStep = parentWorkflowStep.steps.find(step => step.workflow && step.workflow.name === workflow.name);

      if (sectionStep) {
        this.store.dispatch(DomainActions.Workflow.updateStepsInSection(parentWorkflowStep.route, [{ route: sectionStep.route, valid: allValid }], parentWorkflow.name));

        const sectionValid = parentWorkflowStep.steps.every(step => {
          return (step.route === sectionStep.route) ? allValid : step.valid;
        });

        if (sectionValid !== parentWorkflowStep.valid) {
          return of(DomainActions.Workflow.updateSteps([{ route: parentWorkflowStep.route, valid: sectionValid }], parentWorkflow.name));
        }
      }
    } else if (parentWorkflowStep.valid !== allValid) {
      return of(DomainActions.Workflow.updateSteps([{ route: parentWorkflowStep.route, valid: allValid }], parentWorkflow.name));
    }

    return EMPTY;
  }

  propagateWorkflowUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.SET),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Workflow.getState)),
    switchMap(([payload, workflowState]) => {
      const workflows = workflowState.workflows;
      const workflowName = (payload.kind === WORKFLOW_TYPES.CURRENT) ? workflowState.current : payload.kind;

      const existingWorkflow = workflows[workflowName] || {};
      const workflow = { ...existingWorkflow, ...payload.workflow };

      if (!workflow.parent) {
        return EMPTY;
      }

      const parentWorkflow = workflows[workflow.parent.name];
      if (!parentWorkflow) {
        return EMPTY;
      }

      const existingSteps = existingWorkflow['steps'] || [];
      const updatedSteps = payload.workflow.steps || [];

      const steps = existingSteps.map(step => {
        const updated = updatedSteps.find(s => s.route === step.route);
        return updated || step;
      });

      updatedSteps.forEach(step => {
        if (!existingSteps.some(s => s.route === step.route)) {
          steps.push(step);
        }
      });

      workflow.steps = steps;

      return this.propagateWorkflowValidity(workflow, parentWorkflow);
    })
  ));

  propagateStepUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.UPDATE_STEPS),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Workflow.getState)),
    switchMap(([payload, workflowState]) => {
      const workflows = workflowState.workflows;
      const workflowName = (payload.kind === WORKFLOW_TYPES.CURRENT) ? workflowState.current : payload.kind;
      const workflow = workflows[workflowName];

      if (!workflow || !workflow.parent) { return EMPTY; }
      const parentWorkflow = workflows[workflow.parent.name];
      if (!parentWorkflow) { return EMPTY; }

      return this.propagateWorkflowValidity(workflow, parentWorkflow, payload.steps);
    })
  ));
}
