import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Actions, Model } from '@app-ngrx-domains';
import { LookupService, ProgramService } from '@app-services';
import { Institution, EnumErrorTypes } from '@app-models';
import { COLLABORATIVE_PARTNER_TYPES, PARTICIPATING_SCHOOL_TYPES, PROGRAM_KEYS, PROJECT_ROLES } from '@app-consts';

@Component({
  selector: 'app-agency-item',
  templateUrl: './agency-item.component.html'
})
export class AgencyItemComponent implements OnInit, OnDestroy {
  @Input() canEdit: boolean;
  @Input() isPreview: boolean;
  @Input() firstTouch: boolean;
  @Input() isLead: boolean;
  @Input() isApplication: boolean;
  @Input() showEmployerAlert: boolean;
  @Input() fundId: number;
  @Input() proposalId: number;
  @Input() contactRoleId: number;
  @Input() count: number;
  @Input() set agencyTypes(types: Array<string>) {
    this._agencyTypes = types || [];
    this.agencyTypeOptions = this._agencyTypes.map(t => ({ value: t, label: t }));
    this.canCreateAgencyOptions = this.agencyTypeOptions.filter(type => PARTICIPATING_SCHOOL_TYPES.includes(type.value) || COLLABORATIVE_PARTNER_TYPES.includes(type.value));
  }
  get agencyTypes(): Array<string> {
    return this._agencyTypes;
  }
  _agencyTypes: Array<string>;

  @Input() set existingAgencyIds(ids: Array<number>) {
    this._existingAgencyIds = ids;
  };
  get existingAgencyIds() {
    return this._existingAgencyIds;
  }
  _existingAgencyIds: Array<number>;

  @Input() set selectedAgency(agency: Model.Institution) {
    this._selectedAgency = agency;
  }
  get selectedAgency() {
    return this._selectedAgency;
  }
  _selectedAgency: Model.Institution;

  @Input() set appContribution(ac: Model.EAApplicationContribution) {
    this._appContribution = ac;
  }
  get appContribution() {
    return this._appContribution;
  }
  _appContribution: Model.EAApplicationContribution;


  @Output() agencySaved: EventEmitter<boolean> = new EventEmitter();

  form: FormGroup;
  institutionsLookupFormatted: Array<Model.SelectOption> = [];
  agencyTypeOptions: Array<Model.SelectOption> = [];
  canCreateAgencyOptions: Array<Model.SelectOption> = [];
  contactTypes: Array<Model.ContactTableType>;

  // application_contribution: Model.EAApplicationContribution;
  filterIds: number[] = [];

  isEmployer = false;
  isCompetitive = false;
  isSmallProgram = false;
  showRemoveAgencyAlert = false;
  showCreateAgency = false;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private _fb: FormBuilder,
    private lookupService: LookupService,
    private programService: ProgramService,
    private store: Store<State>,
  ) {
  }

  ngOnInit() {
    const fund = this.programService.getProgramById(this.fundId);
    this.isSmallProgram = fund.is_small_program;
    this.isCompetitive = fund.program_settings.is_rfa;

    const selectedAgencyOption = this.selectedAgency ? { value: this.selectedAgency.id, label: this.selectedAgency.name } : undefined;
    const agencyType = this.selectedAgency ? this.selectedAgency.type : undefined;
    const isEmployer = this.selectedAgency && this.selectedAgency.is_employer;

    this.form = this._fb.group({
      agencyType: [agencyType, [Validators.required]],
      agency: [selectedAgencyOption, [Validators.required]],
      isEmployer: [isEmployer]
    });

    if (this.contactRoleId) {
      this.contactTypes = [{
        roleId: this.contactRoleId,
        roleName: this.lookupService.getRoleNameForFund(this.contactRoleId, PROGRAM_KEYS.CAI),
        numberRequired: 1,
        isLead: this.isLead,
        shadowRoleId: this.isLead ? undefined : PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID,
      }];
    }
  }

  get selectedAgencyName() {
    return this.selectedAgency ? this.selectedAgency.long_name || this.selectedAgency.name : '';
  }

  get canEditAgency() {
    return this.canEdit && (this.isLead || !this.selectedAgency);
  }

  removeCurrentAgency() {
    this.showRemoveAgencyAlert = false;
    if (this.selectedAgency) {
      this.store.dispatch(Actions.CurrentProposal.removeInstitutionAndRelations(this.selectedAgency.id, this.isLead));
    }
  }

  openAddAgency() {
    this.removeCurrentAgency();
    this.showCreateAgency = true;
  }

  saveAgency() {
    let agency;
    try {
      agency = this.form.get('agency').value.value;
    } catch {
      agency = null;
    }
    if (!this.selectedAgency || this.selectedAgency.id !== agency) {
      if (this.selectedAgency) {
        // remove old & associate new
        this.store.dispatch(Actions.CurrentProposal.removeInstitutionAndRelations(this.selectedAgency.id, this.isLead, agency));
      } else {
        // associate new
        this.store.dispatch(Actions.CurrentProposal.upsertInstitution(agency, {is_lead: this.isLead}));
      }
    }
    this.agencySaved.emit(true);
  }

  toggleIsEmployer() {
    this.isEmployer = this.form.get('isEmployer').value;
    if (this.selectedAgency) {
      this.addOrUpdateAgency(this.selectedAgency);
    }
  }

  deleteAgency() {
    this.removeCurrentAgency();
    this.agencySaved.emit(true);
  }

  addOrUpdateAgency(institution: Model.Institution) {
    this.form.get('agency').setValue({value: institution.id, label: institution.name});
    this.store.dispatch(Actions.CurrentProposal.upsertInstitution(institution.id, {is_lead: this.isLead, is_employer: this.isEmployer}));
    this.agencySaved.emit(true);
  }

  persistAttribute(attribute_name: string) {
    const value = this.form.get(attribute_name).value;
    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({key: attribute_name, value}));
  }

  get isSingleTypeAgency() {
    return this.agencyTypes && this.agencyTypes.length === 1;
  }

  resetSelectedAgency() {
    this.removeCurrentAgency();
    this.form.get('agency').setValue(undefined);
  }

  updateInstitutionsList(filterStr: string, limit = 30) {
    const typeControl = this.form.get('agencyType')
    const typeFilter = typeControl.value
      ? { type: typeControl.value }
      : { types: this.agencyTypes };

    const filters = {
      ...typeFilter,
      match_strings: filterStr,
      limit
    };
    this.lookupService.formattedInstitutionList$(filters)
      .subscribe(
        (res) => {
          this.institutionsLookupFormatted = res;
        },
        (err) => {
          this.store.dispatch(Actions.App.setError({
            type: EnumErrorTypes.api,
            location: this.constructor.name,
            show: false,
            raw: err,
          }));
        }
      );
  }

  get agency_address(): string {
    return this.selectedAgency ? Institution.getFullAddress(this.selectedAgency) : '';
  }

  get agency_website(): string {
    return this.selectedAgency && this.selectedAgency.website || 'N/A';
  }

  get agency_id(): number | string {
    return this.selectedAgency ? this.selectedAgency.id : '';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
