import { NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener, Input, OnInit, OnDestroy } from '@angular/core';
import { CurrencyPipe, DecimalPipe, DatePipe } from '@angular/common';
import { PercentTransform } from '../../pipes'
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[transform]',
  providers: [CurrencyPipe, DecimalPipe, PercentTransform],
})
export class InputTransformerDirective implements OnInit, OnDestroy {

  constructor(private el: ElementRef,
              private control: NgControl,
              private currencyPipe: CurrencyPipe,
              private decimalPipe: DecimalPipe,
              private percentPipe: PercentTransform,
              private datePipe: DatePipe) {
  }

  pipe;
  formattedAmount;
  value;
  private _transform;
  private focused;
  private destroy$: Subject<boolean> = new Subject();

  @Input() pipeArgs = null;
  @Input() integer = false;
  @Input() decimalPlaces: number = 0;
  @Input() defaultToNull: boolean = false;

  @Input('transform') set transform(pipe: string) {
    switch (pipe) {
      case 'currency':
      case 'dollars':
        this.pipe = this.currencyPipe;
        break;
      case 'percent':
        this.pipe = this.percentPipe;
        break;
      case 'date':
        this.pipe = this.datePipe;
        break;
      case 'decimal':
      default:
        this.pipe = this.decimalPipe;
        break;
    }
    this._transform = pipe;
    this.el.nativeElement.classList.add('input--' + pipe);
  };

  get transform() {
    return this._transform;
  }

  ngOnInit() {
    const savedValue = this.el.nativeElement.value;
    if (savedValue) {
      this.transformValue(savedValue);
      this.setFormValues();
    }

    /*
      Subscribe to changes directly to the formControl to update the valueAccessor
      Needed for fiscal-report-card.component and rsi-lea-card.component when values are
      updated on tab change
     */
    this.control.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (this.focused || this.value === event) {
          // Ignore the change, user is either still focused on field or is a stale event
          return;
        }

        this.transformValue(event);
        if (this.formattedAmount) {
          this.control.valueAccessor.writeValue(this.formattedAmount);
        }
    });

  }

  @HostListener('blur', ['$event']) onBlur(event) {
    this.focused = false;
    this.transformValue(event.target.value);
    this.setFormValues();
  }

  @HostListener('focus', ['$event']) onFocus($event) {
    this.focused = true;
    this.untransformValue($event);
  }

  @HostListener('change', ['$event']) onChange(event) {
    this.transformValue(event.target.value);
    this.setFormValues();
  }

  transformValue(value) {
    if (typeof value === 'string') {
      value = value.replace(/[^\d\.-]/g, '');
    }

    const inputValue = this.integer ? Math.round(value) : parseFloat(value).toFixed(this.decimalPlaces);

    if ((value === '' && this.defaultToNull) || isNaN(Number(inputValue))) {
      this.value = null;
      this.formattedAmount = null;
      return;
    }

    this.formattedAmount = this.pipeArgs ? this.pipe.transform(inputValue, ...this.pipeArgs) : this.pipe.transform(inputValue);
    this.value = inputValue;
  }

  untransformValue(event) {
    if (this.value != null) {
      event.target.value = this.value;
      event.target.select(); // highlights input value of target
    }
  }

  setFormValues() {
    if (this.value === null) {
      this.control.control.setValue(this.defaultToNull ? null : undefined);
      this.control.valueAccessor.writeValue(null);
      return;
    }
    this.control.control.setValue(this.value);

    if (this.formattedAmount) {
      this.control.valueAccessor.writeValue(this.formattedAmount);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
