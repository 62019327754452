import { Component, OnInit, OnDestroy } from '@angular/core';
import { Model, State, Queries, Actions } from '@app-ngrx-domains';
import { Store } from '@ngrx/store';
import { takeUntil, withLatestFrom, skipWhile } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { ApiService } from '@app/core/services';
import { slideOutAnimation } from '@app/shared.generic/animations';
import { PROGRAM_KEYS, TASK_TYPES, WORKFLOW_STEPS } from '@app/core/consts';

@Component({
  selector: 'app-project-history',
  templateUrl: './project-history.component.html',
  animations: [slideOutAnimation]
})
export class ProjectHistoryComponent implements OnInit, OnDestroy {

  proposal: Model.ProposalItem;
  displayedTasks: Array<Model.Task> = [];
  tasks: Array<Model.Task> = [];
  files: Array<Model.Document>;
  archives: Array<{ archive_id: number, created_at: string }> = [];
  supportsCompare: boolean;

  private compareSupportedPrograms = [
    PROGRAM_KEYS.SWPL_v2,
    PROGRAM_KEYS.SWPR_v2,
    PROGRAM_KEYS.SWPL_v3,
    PROGRAM_KEYS.SWPR_v3
  ];
  private defaultDisplaySize = 10;
  private ignoreIncompleteTaskTypes = [TASK_TYPES.FISCAL_REPORT_SUBMIT, TASK_TYPES.FISCAL_REPORT_APPROVE];
  private initialized: boolean = false;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private apiService: ApiService,
    private store: Store<State>,
  ) { }

  ngOnInit() {
    this.store.dispatch(Actions.Workflow.setCurrentStep(WORKFLOW_STEPS.HISTORY));
    this.store.dispatch(Actions.Workflow.show(false));
    this.store.dispatch(Actions.Layout.setActions([]));

    this.store.select(Queries.CurrentProposal.get).pipe(
      withLatestFrom(this.store.select(Queries.Files.getItems)),
      skipWhile(([proposal, files]) => !(proposal && proposal.id)),
      takeUntil(this.destroy$)
    ).subscribe(([proposal, files]) => {

      this.proposal = proposal;
      this.files = files;
      if (proposal.creator) {
        this.store.dispatch(Actions.Layout.updateHeader({ user: proposal.creator }));
      }
      this.initialize();
    });
  }

  initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    this.supportsCompare = this.compareSupportedPrograms.includes(this.proposal.funds[0].key);

    forkJoin([
      this.apiService.listTasks({ proposal_id: this.proposal.id }, false),
      this.apiService.listProposalArchives(this.proposal.id)
    ]).subscribe(([tasks, archives]) => {
      this.tasks = tasks.filter(task =>
        (task.completed || !this.ignoreIncompleteTaskTypes.includes(task.task_type)) &&
        !!task.store.message
      ).sort((a, b) => a.id < b.id ? 1 : -1);

      this.archives = archives;
      this.displayedTasks = this.tasks.slice(0, this.defaultDisplaySize);
    });
  }

  filesForTask(taskId: number) {
    return this.files.filter(file => file.creator_task_id === taskId);
  }

  showAll() {
    this.displayedTasks = this.tasks;
  }

  isArchiveAvailable(taskId: number) {
    return this.supportsCompare && this.archives.find(archive => archive.archive_id === taskId);
  }

  get showingAll() {
    return this.displayedTasks.length === this.tasks.length;
  }

  trackById(index: number, task: Model.Task) {
    return task.id;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
