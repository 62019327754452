import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { ACTION_BUTTONS, AREAS, CHAR_LIMITS, METRIC_GROUPS, PROGRAM_KEYS, WORKFLOW_STEPS, WORKFLOW_TYPES, PROPOSAL_TYPES } from '@app-consts';
import { ApiService, LookupService, PermissionsService, ProgramService } from '@app-services';
import { Proposal } from '@app-models';
import { PlanWorkflowComponent } from '@app/RCM/components/workflows/plan-workflow.component';
import { PlanWorkflowValidatorService } from '@app/RCM/validators/plan-workflow-validator.service';
import { sortBy } from 'lodash';

@Component({
  selector: 'project-workplan-objectives',
  templateUrl: './workplan-objectives.component.html'
})
export class WorkplanObjectivesComponent extends PlanWorkflowComponent implements OnInit, OnDestroy {
  @Input() isPreview = false;
  @Input() hideTitle = false;
  @Input() guidance: Model.GuidanceWorkflowFilter;

  canEdit = false;
  firstTouch = true;
  addDisabled = false;
  title = 'Workplan: Objectives';

  proposal = undefined;
  objectiveEAs: Array<Model.EAWorkplanObjective> = [];
  maxObjectiveLength = CHAR_LIMITS.NARRATIVE_MAX;
  metricsOptions: Array<Model.SelectOption> = [];
  allowAddObjective = true;
  initialized = false;

  private destroy$: Subject<boolean> = new Subject();
  private isPlan: boolean;
  private fund: Model.Fund;
  private currentWorkflowTemplateName: string;
  private minObjectives = 4;
  private isRCM: boolean;

  constructor(
    protected store: Store<State>,
    protected apiService: ApiService,
    protected programService: ProgramService,
    protected permissionsService: PermissionsService,
    protected lookupService: LookupService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected workflowValidatorService: PlanWorkflowValidatorService,
  ) {
    super(store, apiService, programService, permissionsService, lookupService, route, router, workflowValidatorService);
  }

  ngOnInit() {

    if (!!this.guidance) {
      // in template mode...prepare to show as guidance template.
      this.setupAsTemplate();
      return;
    }

    this.store.select(Queries.CurrentProposal.get)
      .pipe(
        withLatestFrom(
          this.store.select(Queries.Workflow.getCurrent),
          this.store.select(Queries.LookupTables.getMetricsDefinitionsByGroup),
        ),
        filter(([p, w, m]) => p && p.id && !!w),
        takeUntil(this.destroy$)
      )
      .subscribe(([proposal, current_workflow, metricDefs]) => {

        this.proposal = proposal;
        this.isPlan = Proposal.isPlan(proposal);
        this.fund = this.proposal.funds[0];
        this.isRCM = this.fund.parent_key === PROGRAM_KEYS.RCM;

        if (this.isRCM && !this.isPreview) {
          this.maxObjectiveLength = CHAR_LIMITS.EXTRA_LONG;
        }

        this.currentWorkflowStep = WORKFLOW_STEPS.OBJECTIVES;
        this.currentWorkflowTemplateName = current_workflow.templateName || current_workflow.name;
        this.objectiveEAs = this.proposal.workplan_objectives;

        // disables add button if any objective is still a temporary ea
        this.addDisabled = !(this.objectiveEAs.every(objective => objective.id > 0));

        // initialize one time set up code.
        this.initialize(metricDefs);
      });
  }

  /**
   * Initializes the vars that need to be set up just once.
   * */
  private initialize(metricDefs) {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;
    const fund = this.proposal.funds[0];
    const isCAIv2 = fund.parent_key === PROGRAM_KEYS.CAI && fund.key === PROGRAM_KEYS.RFA_v2;

    if (isCAIv2 || fund.is_small_program ||
        [PROGRAM_KEYS.EWD, PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R, PROGRAM_KEYS.RCM].includes(fund.parent_key)) {
      this.minObjectives = 1;
    }

    if (!this.guidance) {
      const workflowName = this.currentWorkflowTemplateName === WORKFLOW_TYPES.RCM_PLAN ? WORKFLOW_TYPES.RCM : this.currentWorkflowTemplateName;
      this.guidance = {
        programId: fund.id,
        proposalType: PROPOSAL_TYPES.APPLICATION,
        workflowName: workflowName,
        stepName: this.currentWorkflowStep,
      }
    }

    if (this.isRCM && this.isPlan) {
      super.ngOnInit();
    }

    if (isCAIv2) {
      this.metricsOptions = sortBy(metricDefs(METRIC_GROUPS.CAI_V2).map(def => ({ value: def.id, label: def.name })), ['label']);
    }

    if (!this.isPreview) {
      this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));

      // listen for next button.
      this.store.select(Queries.Layout.getEmitState)
        .pipe(takeUntil(this.destroy$))
        .subscribe((emitted) => {
          if (emitted && emitted.id === ACTION_BUTTONS.NEXT && emitted.name === this.currentWorkflowStep) {
            this.store.dispatch(Actions.CurrentWorkflow.gotoNext(this.currentWorkflowStep));
          }
      });

      this.fetchPermissions();

      // determine first touch
      this.firstTouch = !this.objectiveEAs.length;

      this.buildTempObjectives();
    }
  }

  private buildTempObjectives() {
    const tempsToBuild = [];
    const temp = {
      effort_area_type: 'workplan_objectives',
      parent_proposal_id: this.proposal.id,
      title: undefined,
      description: undefined
    };

    for (let i = this.objectiveEAs.length; i < this.minObjectives; i++) {
      tempsToBuild.push({ ...temp });
    }

    if (tempsToBuild.length > 0) {
      this.store.dispatch(Actions.CurrentProposal.createTempEffortAreas({ eas: tempsToBuild }));
    }
  }

  /**
   * Fetches permission(s) then sets up action button(s)
   */
  private fetchPermissions() {
    this.permissionsService.canEditProject(this.proposal, AREAS.PROJECT, this.route.snapshot)
      .pipe(takeUntil(this.destroy$))
      .subscribe(canEdit => {
        this.canEdit = canEdit;
      });
  }

  addObjective() {
    this.store.dispatch(Actions.CurrentProposal.createTempEffortArea({
      ea: {
        effort_area_type: 'workplan_objectives',
        parent_proposal_id: this.proposal.id,
        title: undefined,
        description: undefined
      }
    }));

    this.addDisabled = true;
  }

  trackById(index: number, item: any): number {
    return item.temp_id ? item.temp_id : item.id;
  }

  get canDelete(): boolean {
    return this.objectiveEAs && this.objectiveEAs.length > this.minObjectives;
  }

  private setupAsTemplate() {
    this.fund = this.programService.getProgramById(this.guidance.programId);
    this.initialized = true;
  }

  ngOnDestroy() {
    if (this.isRCM && this.isPlan) {
      super.ngOnDestroy();
    }

    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
