<div class="card" id="qa_bookmarks_list_card">
  <div class="v2-styles card-title card-title--margin">
    BOOKMARKS
  </div>

  <project-filter *ngIf="proposalList && proposalList.length"
    [filters]="filters"
    [rows]="proposalList"
    [projectLongName]="projectLongName"
    [analyticsFundName]="analyticsFundName"
    (filterUpdate)="updateProposalList($event)">
  </project-filter>

  <project-table
    *ngIf="proposalList?.length else empty"
    [projectLongName]="projectLongName"
    [analyticsFundName]="analyticsFundName"
    [rows]="filteredProposals"
    defaultSort="title"
    [showTableCaption]="false"
    [columns]="columns"
    [pageLimit]="20">
  </project-table>
</div>

<ng-template #empty>
  <p *ngIf="!filteredProposals" class="message">Loading Bookmarks... <span class="loader-spinner-inline"></span></p>
  <div *ngIf="filteredProposals?.length === 0" class="empty-state" id="qa_bookmarks_empty_message">
    <p>You don't have any saved bookmarks.</p>
    <p class="no-margin">To add a bookmark, navigate to a project page and click the
      <icon iconId="bookmark-empty" class="icon--inline"></icon>
      bookmark icon in the page header.
    </p>
  </div>
</ng-template>

<ng-template #areaTmpl let-row="row">
  {{ row.area }}
</ng-template>

<ng-template #titleTmpl let-row="row">
  <ng-container [ngSwitch]="row.deleted">
    <a *ngSwitchCase="false" [routerLink]="row.link" [queryParams]="row.queryParams" class="text-link">
      {{ row.title }}
    </a>
    <a *ngSwitchCase="true" (click)="removeDeletedProposal(row.bookmark)" class="text-link">
      {{ row.title }}
    </a>

  </ng-container>
</ng-template>

<ng-template #instTmpl let-row="row">
  <span class="pre-line">{{ row.institution }}</span>
</ng-template>

<ng-template #yearTmpl let-row="row">
  {{ row.project_period || 'N/A'}}
</ng-template>

<ng-template #statusTmpl let-row="row">
  {{ row.project_state }}
</ng-template>

<ng-template #fundTmpl let-row="row">
  {{ row.fundName }}
</ng-template>

<ng-template #actionTmpl let-row="row">
  <button class="action-button action-button--small action-button--tertiary" title="Remove Bookmark"
          (click)="openDeleteBookmark(row.bookmark)">
    <icon iconId="close"></icon>
  </button>
</ng-template>

<alert *ngIf="toDeleteBookmark"
  button-action="Delete"
  button-dismiss="Cancel"
  level="warning"
  (action)="confirmDeleteBookmark()"
  (dismiss)="dismissDeleteBookmarkAlert()">
  <ng-container [ngSwitch]="proposalDeleted">
    <p *ngSwitchCase="true">
      The proposal you're trying to view has been deleted from NOVA. Would you like to remove the bookmark?
    </p>
    <p *ngSwitchCase="false">
      Are you sure you want to remove this bookmark? This will only remove the bookmark from this list and will not delete the original source.
    </p>
  </ng-container>
</alert>
