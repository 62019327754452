import { AfterContentInit, ContentChildren, Directive, forwardRef, OnDestroy, QueryList, Renderer2 } from "@angular/core";
import { AppUtils } from "@app/core/utilities";
import { InputRefDirective } from "../input-ref/input-ref.directive";

@Directive({
  selector: '[multiPaste]'
})
export class MultiPasteDirective implements AfterContentInit, OnDestroy {

  @ContentChildren(forwardRef(() => InputRefDirective), { descendants: true }) multiInputs: QueryList<InputRefDirective>;

  private inputMatrix: InputRefDirective[][] = [];
  private changeEvent: Event;
  private listeners: Array<Function> = [];

  constructor(
    private renderer: Renderer2
  ) {}

  ngAfterContentInit(): void {
    this.changeEvent = new Event('change');

    this.multiInputs.forEach((input, index) => {

      // Build up inputMatrix by row and column
      if (!AppUtils.isNil(input.tableRow) && !AppUtils.isNil(input.tableColumn)) {
        if (!this.inputMatrix[input.tableRow]) {
          this.inputMatrix[input.tableRow] = [];
        }

        this.inputMatrix[input.tableRow][input.tableColumn] = input;
      } else {
        // If rows & columns aren't specified, just push inputs as a single column
        this.inputMatrix.push([input]);
      }

      const listener = this.renderer.listen(input.element, 'paste', (event) => {
        const clipboardData = event.clipboardData;
        const pastedValuesRaw = (clipboardData && clipboardData.getData('text') || '');

        if (pastedValuesRaw.includes('\n') || pastedValuesRaw.includes('\t')) {
          const pastedRows = pastedValuesRaw.split('\n').map(row => row.split('\t'));
          this.handlePaste(pastedRows, input, index);
        }
      });

      this.listeners.push(listener);
    });
  }

  handlePaste(pastedRows: any[][], initialInput: InputRefDirective, inputIndex: number) {
    let inputRow = initialInput.tableRow;
    let inputColumn = initialInput.tableColumn;

    if (inputRow == undefined || inputColumn == undefined) {
      inputRow = inputIndex;
      inputColumn = 0;
    }

    // blur the field we're pasting into
    initialInput.element.blur();

    for (const row of pastedRows) {
      if (inputRow > this.inputMatrix.length || !this.inputMatrix[inputRow]) {
        // Out of rows
        break;
      }

      const inputs = this.inputMatrix[inputRow];
      for (const value of row) {
        if (inputColumn > inputs.length - 1 || !inputs[inputColumn]) {
          // Out of columns
          break;
        }

        const input = this.inputMatrix[inputRow][inputColumn];
        input.control.control.patchValue(value);
        input.element.dispatchEvent(this.changeEvent);

        inputColumn++;
      }

      inputColumn = initialInput.tableColumn || 0;
      inputRow++;
    }
  }

  ngOnDestroy(): void {
    this.listeners.forEach(unlisten => {
      unlisten();
    });
  }
}
