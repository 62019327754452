<full-page-modal *ngIf="showSignupModal"
  [small]="true"
  heading="{{fund.name}} - Signup"
  button-cancel="Remind me later"
  button-action="Submit"
  [button-action-disabled]="signupForm.invalid"
  (cancel)="showSignupModal = false;"
  (dismiss)="showSignupModal = false;"
  (action)="submitSignup()">
  <form [formGroup]="signupForm">
    <h3>Select your project</h3>
    <div class="help-text">
      <p>
        To complete your {{fund.name}} registration, choose your project from the list below.
        By submitting your selection, you will be assigned a permissioned role on the project.
      </p>
    </div>

    <po-select id="project_selection"
      labelText="Project"
      placeholderText="Select your project"
      [options]="proposalOptions"
      [selectOnce]="true">
      <select inputRef class="po-select__select" formControlName="proposal_id" aria-required="true"></select>
    </po-select>
  </form>
</full-page-modal>