import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Model, State, Actions } from '@app-ngrx-domains';
import { LookupService } from '@app-services';
import { EnumErrorTypes, Profile } from '@app-models';
import { AppUtils } from '@app-utilities';
import { cloneDeep } from 'lodash';

export interface IContactItem {
  title: string,
  contactOnly: boolean,
  required: boolean,
  hasDesignee?: boolean,
  readOnly?: boolean,
  canDelete: boolean,
  institution_id?: number,
  pc?: Model.UserRoleScope,
  dc?: Model.UserRoleScope,
}

@Component({
  selector: 'app-project-contact-role-item',
  templateUrl: './contact-role-item.component.html',
})
export class ContactRoleItemComponent implements OnInit, OnDestroy {
  @Input() proposalId?: number;
  @Input() canEdit = true;
  @Input() firstTouch = false;
  @Input() fundKey: string;
  @Input() fundId: number;
  @Input() contact: IContactItem;
  @Input() set contactDetails(user: Model.User) {
    // Init/update selectedContact
    this._contactDetails = user;
    this.selectedContact = (user && user.email_address) ? AppUtils.formatContact(user) : null;
    // When contact data changes, update the form and enabled-ness of the Designee field
    if (this.form && this.form.controls['contactId']) {
      this.form.controls['contactId'].patchValue(this.selectedContact);
      if (this.form.controls['designeeId']) {
        if (this.selectedContact) {
          this.form.controls['designeeId'].enable();
        } else {
          this.form.controls['designeeId'].disable();
        }
      }
    }
  }
  get contactDetails(): Model.User {
    return this._contactDetails;
  }

  @Input() set designeeDetails(user: Model.User) {
    // Init/update selectedDesignee
    this._designeeDetails = user;
    this.selectedDesignee = (user && user.email_address) ? AppUtils.formatContact(user) : null;
    // When designee data changes, update the form
    if (this.form && this.form.controls['designeeId']) {
      this.form.controls['designeeId'].patchValue(this.selectedDesignee);
    }
  }
  get designeeDetails(): Model.User {
    return this._designeeDetails;
  }

  @Input() inviter: string;
  @Input() contactType?: string;
  @Input() contactIndex?: number;
  @Output() associateContact: EventEmitter<any> = new EventEmitter();
  @Output() removeContact: EventEmitter<any> = new EventEmitter();
  contactsLookupFormatted: Array<Model.SelectOption> = [];
  selectedContact: Model.SelectOption; // Values flow in from contactDetails Input()
  selectedDesignee: Model.SelectOption; // Values flow in from designeeDetails Input()
  _contactDetails: Model.User;
  _designeeDetails: Model.User;
  form: FormGroup;
  alertMessage = '';
  showModalInvite: boolean;

  constructor(
    private lookupService: LookupService,
    private _fb: FormBuilder,
    private store: Store<State>,
  ) {
  }

  ngOnInit() {
    // Set up the main Contact & Designee form fields
    const formSettings: any = {
      contactId: [
        this.selectedContact,
        !this.contact.required ? [] : [Validators.required],
      ]
    };
    if (this.contact.hasDesignee) {
      formSettings.designeeId = [
        { value: this.selectedDesignee, disabled: !this.setDesigneeNext }
      ]
    }
    this.form = this._fb.group(formSettings);
  }

  ngOnDestroy() {
  }

  updateContactsList(filter, limit = 50) {
    this.lookupService.formattedContactList$(filter, limit)
      .subscribe(
        (res) => {
          this.contactsLookupFormatted = res;
        },
        (err) => {
          this.store.dispatch(Actions.App.setError({
            type: EnumErrorTypes.api,
            location: this.constructor.name,
            show: true,
            raw: err
          }));
        }
      );
  }

  /**
   * Associates user to given contact.
   * @param user_id
   */
  associateUser(user_id: number, designee = false) {
    // clear alert whenever user changes.
    this.alertMessage = '';
    const contact = cloneDeep((designee ? this.contact.dc : this.contact.pc));
    contact.user_id = user_id;
    this.associateContact.emit(contact);
  }

  removeUser(designee = false) {
    if (designee) {
      this.removeContact.emit(this.contact.dc);
    } else { // Delete the main contact plus any associated designee
      if (this.selectedDesignee) {
        // Delete any designee first so there's no chance it gets stranded as a designee-with-no-main-contact
        this.removeContact.emit(this.contact.dc);
      }
      this.removeContact.emit(this.contact.pc);
    }
  }

  showModal(show: boolean) {
    this.showModalInvite = show;
  }

  // Returns string used for description in invite modal
  modalDescription(): string {
    return this.contact.contactOnly ?
      `Add new user as "${this.contact.title}" for this plan.` :
      `Add & invite a new user as "${this.contact.title}" for this plan.`;
  }

  // Adds the new user as a contact for this proposal
  inviteActions(user: Profile) {
    // clear alert when adding a new user.
    this.alertMessage = '';

    // associate this user as the contact person
    this.associateUser(user.id, this.setDesigneeNext);
    // show confirm message.
    this.setAlertMessage(this.contact.contactOnly
      ? 'The user has been added to NOVA.'
      : 'This user has been added to NOVA, and the invitation has been sent.'
    );
    this.showModal(false);
  }

  /**
   * This flag drives conditional logic in the template, and also determines whether
   * a contact user-role-scope gets saved as a designee.
   * @return {boolean}
   */
  get setDesigneeNext(): boolean {
    return this.selectedContact && this.contact.hasDesignee;
  }

  get showAddContactLink(): boolean {
    return this.canEdit && !this.selectedContact;
  }

  get showAddDesigneeLink(): boolean {
    return this.canEdit && this.setDesigneeNext && !this.selectedDesignee;
  }

  private setAlertMessage(message: string) {
    this.alertMessage = message;
  }
}
