import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AREAS, PROPOSAL_TYPES, WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';
import { Actions, Model, State } from '@app-ngrx-domains';
import { PermissionsService } from '@app-services';
import { ProjectUtilities } from '@app/shared.project';
import { Store } from '@ngrx/store';
import { cloneDeep, last } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'guidances',
  templateUrl: './guidances.component.html'
})
export class GuidancesComponent implements OnDestroy {
  @Input() program: Model.Fund;
  @Input() configName: string;
  @Input() durationId: number;
  @Input() title: string = 'Guidance';
  @Input() required: boolean;
  @Input() firstTouch: boolean;
  @Input() isPreview: boolean;
  @Input() isEditing: boolean;

  @Input() helperText: string = `Enter guidance text for any of the workflow steps in the application to help guide applicants.
    This text will be displayed at the top of each respective workflow steps.`;

  _parentEffortAreas: Array<Model.EffortArea> = [];
  @Input()
  set parentEffortAreas(value) {
    this._parentEffortAreas = value;
    this.updateGuidances();
  }

  get parentEffortAreas() {
    return this._parentEffortAreas;
  }

  canEdit: boolean = false;
  isRevertible: string;
  settings: { id: number, guidances: Array<Model.EAGuidance> };
  guidanceEAs: Array<Model.EAGuidance>;

  private workflow: Model.Workflow;
  private workflowTable: { [name: string]: Model.Workflow } = {};

  private destroy$: Subject<boolean> = new Subject();
  private initialized = false;
  private currentWorkflowStep = WORKFLOW_STEPS.GUIDANCE;

  constructor(
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private store: Store<State>,
  ) {
  }

  updateGuidances() {
    this.settings = last(this.parentEffortAreas);

    this.setConfigWorkflow();

    const processWorkflow = (workflow) => {
      const guidances = [];
      (workflow.steps || []).filter(step => ![WORKFLOW_STEPS.BUDGET_SOURCES, WORKFLOW_STEPS.FORECAST_SOURCES].includes(step.route)).forEach(step => {
        if (step.workflow) {  // step is a subworkflow
          const config = this.workflowTable[step.workflow.templateName];
          const steps = processWorkflow(config);
          guidances.push({
            isWorkflow: true,
            workflowTitle: step.title,
            guidances: steps
          });
        } else if (step.showStatus && !step.hide) {
          const match = this.settings.guidances.find(g => g.proposal_type === PROPOSAL_TYPES.APPLICATION && g.workflow_name === workflow.name && g.workflow_step === step.route);
          if (match) {
            guidances.push(match);
          }
        }
      });

      return guidances;
    };

    if (this.settings && this.settings.guidances) {
      this.guidanceEAs = processWorkflow(this.workflow);
    }

    // initialize one time set up code.
    this.initialize();
  }

  /**
   * Initializes the vars that need to be set up just once.
   * */
  private initialize() {
    if (this.initialized) {
      // all's been setup.
      return;
    }

    this.initialized = true;

    if (!this.isPreview) {
      this.fetchPermissions();

      // set this as the current step & put up Next button.
      this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));
    }

    const tempsToBuild = [];

    // loop through application workflow, and create any temp eas as needed.
    const processWorkflow = (workflow) => {
      (workflow.steps || []).filter(step => ![WORKFLOW_STEPS.BUDGET_SOURCES, WORKFLOW_STEPS.FORECAST_SOURCES].includes(step.route)).forEach(step => {
        if (step.workflow) {
          const workflow = this.workflowTable[step.workflow.templateName];
          processWorkflow(workflow);
        } else {
          buildTempEA(workflow, step);
        }
      });
    }

    const buildTempEA = (workflow, step) => {
      if (step.showStatus && !step.hide) {
        const match = this.settings.guidances.find(g => g.proposal_type === PROPOSAL_TYPES.APPLICATION && g.workflow_name === workflow.name && g.workflow_step === step.route);
        if (!match) {
          // create a temporary one.
          tempsToBuild.push({
            effort_area_type: 'guidances',
            parent_effort_area_id: this.settings.id,
            title: step.title,
            description: undefined,
            proposal_type: PROPOSAL_TYPES.APPLICATION,
            workflow_step: step.route,
            workflow_name: workflow.name,
            order: 0,
            field_name: WORKFLOW_STEPS.GUIDANCE
          })
        }
      }
    }

    processWorkflow(this.workflow);

    if (tempsToBuild.length) {
      this.store.dispatch(Actions.Fund.createTempEffortAreas({ eas: tempsToBuild, parentEffortAreas: this.parentEffortAreas }));
    }
  }

  /**
   * Fetches permission(s) then sets up action button(s)
   */
  private fetchPermissions() {
    this.permissionsService.canEdit(AREAS.FUND_SETTINGS, this.route.snapshot)
      .pipe(takeUntil(this.destroy$))
      .subscribe(canEdit => {
        this.canEdit = canEdit && !this.isPreview;
        this.isRevertible = this.canEdit && this.isEditing ? this.currentWorkflowStep : undefined;
      });
  }

  private setConfigWorkflow() {
    if (this.workflow) {
      // already set.
      return;
    }

    const configMap = cloneDeep(this.route.snapshot.data.config);
    this.workflowTable = configMap['workflows'];

    const config = configMap[this.configName];
    this.workflow = (this.durationId && config[this.durationId]) ? config[this.durationId] : config.default;

    if (this.program.is_small_program) {
      ProjectUtilities.setWorkflowSteps(this.workflow, this.program, true);
    } else {
      ProjectUtilities.addCustomWorkflowSteps(this.workflow, this.program);
    }
  }

  trackByKey(index: number, item: any): string {
    // by project type + workflow step.
    return `${item.project_type}${item.workflow_step}`;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
