<ng-container *ngIf="!!workflowFilter">
  <ng-container [ngSwitch]="isGuidance">
    <!-- is help text -->
    <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="helpText"></ng-container>

    <!-- is guidance text -->
    <ng-container *ngSwitchCase="true">
      <collapsible-card *ngIf="!!workflowFilter.canEdit || !!description" level="info">
        <div class="card-title">
          <h4>Guidance</h4>
        </div>
        <ng-container *ngTemplateOutlet="helpText"></ng-container>
      </collapsible-card>
    </ng-container>
  </ng-container>
</ng-container>

<!-- Template: Help Text -->
<ng-template #helpText let-label="label">
  <ng-container [ngSwitch]="!!workflowFilter.canEdit">
    <!-- can not edit -->
    <ng-container *ngSwitchCase="false">
      <!-- show only when there is data -->
      <div *ngIf="!!description" [innerHTML]="description | safeHtml" class="status-card__content"></div>
    </ng-container>

    <!-- can edit -->
    <div *ngSwitchCase="true" class="card empty-state" [formGroup]="descriptionForm">
      <label>{{isGuidance ? 'Guidance' : 'Helper'}} Text</label>
      <po-html-textarea
        id="qa_guidance_text_{{workflowFilter.stepName}}_{{fieldName}}"
        [enableParagraphFormat]="true"
        [enableCodeView]="true"
        [htmlCharLimit]="descriptionLength"
        [toggleToolbar]="false"
        formControlName="description"
        (blur)="persistDescription()"></po-html-textarea>
    </div>
  </ng-container>
</ng-template>
