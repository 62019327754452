import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { Store } from '@ngrx/store';
import { CHAR_LIMITS } from '@app-consts';
import { IEAPlanBudgetItem } from '@app-models';
import { LookupService } from '@app-services';
import { ValidatorsEx } from '@app-utilities';

@Component({
  selector: 'allocated-budget-item',
  templateUrl: './allocated-budget-item.component.html'
})
export class AllocatedBudgetItemComponent implements OnInit {
  @Input() canEdit = false;
  @Input() canDelete = true;
  @Input() isPreview = false;
  @Input() firstTouch = true;
  @Input() budgetItem: IEAPlanBudgetItem;
  @Input() activityOptions: Array<Model.SelectOption> = [];
  @Input() objectCodeOptions: Array<Model.SelectOption> = [];
  @Input() institutionOptions: Array<Model.SelectOption> = [];
  @Input() fieldFilter: { title: boolean, object_code_id: boolean, institution_id: boolean, related: boolean, description: boolean, showMatch: boolean } = { title: true, object_code_id: true, institution_id: true, related: true, description: true, showMatch: true };

  form: FormGroup;
  showDeleteWarning: boolean;

  CHAR_LIMIT_MIN = CHAR_LIMITS.NARRATIVE_MIN;
  CHAR_LIMIT_TITLE = CHAR_LIMITS.SHORT;
  CHAR_LIMIT_DESC = CHAR_LIMITS.COMMENTS;

  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
    private lookupService: LookupService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    if (this.budgetItem) {
      const fields: any = {};

      if (this.fieldFilter.title) {
        fields.title = [this.budgetItem.title, [Validators.required, Validators.minLength(this.CHAR_LIMIT_MIN), Validators.maxLength(this.CHAR_LIMIT_TITLE)]];
      }
      if (this.fieldFilter.description) {
        fields.description = [this.budgetItem.description, [Validators.required, Validators.minLength(this.CHAR_LIMIT_MIN), Validators.maxLength(this.CHAR_LIMIT_DESC)]];
      }
      if (this.fieldFilter.institution_id) {
        const institution_id = this.checkIfOptionExists('institution_id', this.institutionOptions);
        fields.institution_id = [institution_id, [Validators.required]];
      }
      if (this.fieldFilter.object_code_id) {
        const object_code_id = this.checkIfOptionExists('object_code_id', this.objectCodeOptions);
        fields.object_code_id = [object_code_id, [Validators.required]];
      }
      if (this.fieldFilter.related) {
        const related_activities = this.checkIfOptionExists('related_activities', this.activityOptions);
        fields.related_activities = [related_activities, [Validators.required]];
      }
      if (this.fieldFilter.showMatch) {
        fields.amounts = this._fb.group({
          grant_funds_amount: [this.budgetItem.grant_funds_amount],
          monetary_match_amount: [this.budgetItem.monetary_match_amount]
        }, { validator: ValidatorsEx.requireOne(true) })
      } else {
        fields.amounts = this._fb.group({
          grant_funds_amount: [this.budgetItem.grant_funds_amount]
        })
      }

      this.form = this._fb.group(fields);
    }
  }

  checkIfOptionExists(controlName: string, options: Array<Model.SelectOption>) {
    let value;
    if (this.budgetItem[controlName]) {
      if (Array.isArray(this.budgetItem[controlName])) {
        value = this.budgetItem[controlName].map(item => item.value);
        this.budgetItem[controlName].forEach(item => {
          const exists = options.find(option => option.value === item.value);
          if (!exists) {
            value = value.filter(v => item.value !== v);
            this.store.dispatch(Actions.CurrentProposal.deleteMultiAttribute({ key: controlName, value: item.value, ea: this.budgetItem }));
          }
        });
      } else {
        value = this.budgetItem[controlName];
        const exists = options.find(option => option.value === value);
        if (!exists) {
          // This option doesn't exist anymore - delete the selection
          this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: controlName, value: null, ea: this.budgetItem }));
          return undefined;
        }
      }
    }
    return value;
  }

  updateAttribute(formControlName: string) {
    const value = this.form.get(formControlName).value;
    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: formControlName, value, ea: this.budgetItem }));
  }

  updateAmount(formControlName: string) {
    const value = this.form.get('amounts').value[formControlName];
    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: formControlName, value, ea: this.budgetItem }));
  }

  addRelatedActivity(activityId: number) {
    this.store.dispatch(Actions.CurrentProposal.addMultiAttribute({ key: 'related_activities', value: activityId, ea: this.budgetItem }));
  }

  removeRelatedActivity(activityId: number) {
    this.store.dispatch(Actions.CurrentProposal.deleteMultiAttribute({ key: 'related_activities', value: activityId, ea: this.budgetItem }));
  }

  toggleDeleteWarning() {
    this.showDeleteWarning = !this.showDeleteWarning;
  }

  deleteBudgetItem() {
    this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({ ea: this.budgetItem }));
    this.toggleDeleteWarning();
  }

  get itemId() {
    return this.budgetItem.id;
  }

  get displayTitle() {
    return this.budgetItem.title || 'New Budget Item';
  }

  get yearName() {
    return this.lookupService.getYearNameByDuration(this.budgetItem.duration_id);
  }

  get budgetTotal() {
    const controls = this.form.get('amounts').value;
    return Object.values(controls).reduce((total: number, current) => {
      return total + (Number(current) || 0);
    }, 0);
  }

  get showCard() {
    return this.form && this.activityOptions && this.institutionOptions && this.objectCodeOptions;
  }
}
