import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Actions, Model } from '@app-ngrx-domains';
import { COMMENT_ID_CLASSES } from '@app/core/consts';
import { NewTempId } from '@app/libs';
import { CalendarDateFormat } from '@app/shared.generic/pipes';
import { Store } from '@ngrx/store';
import { pick } from 'lodash';
import { Subject } from 'rxjs';
import { FROALA_HIGHLIGHT_CLASS } from '../../../../consts';
import { OptionsMenuComponent } from '@app/shared.generic/components';

@Component({
  selector: 'comment-item',
  templateUrl: './comment-item.component.html'
})
export class CommentItemComponent implements OnInit, OnDestroy {
  @Input() canEdit: boolean;
  @Input() item: Model.Comment;
  @Input() resource: any;
  @Input() creator: Model.User;
  @Input() isSelected: boolean;
  @Input() isCreator: boolean;
  @Input() parent: Model.Comment;
  @Input() mouseover: boolean;
  @Output() onResolve: EventEmitter<null> = new EventEmitter();

  isEditing: boolean;
  inputInFocus: boolean;
  showDeleteAlert: boolean;

  @ViewChild('optionsMenu') optionsMenuEl: OptionsMenuComponent;
  @ViewChild('commentInput', { read: ElementRef}) commentInputEl: ElementRef;
  @ViewChild('cancelButton') cancelBtnEl: ElementRef;
  @ViewChild('actionButton') actionBtnEl: ElementRef;

  calendarFormat: CalendarDateFormat = {
    lastDay : 'LT [Yesterday]',
    sameDay : 'LT [Today]',
    nextDay : '[Tomorrow]',
    lastWeek : 'l LT',
    nextWeek : 'l LT',
    sameElse : 'l LT'
  };

  form: FormGroup;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private _fb: FormBuilder,
    private store: Store
  ) { }

  ngOnInit() {
    this.form = this._fb.group({
      comment: this.item?.comment
    });
  }

  ngAfterViewInit() {
    // Auto-focus on new threads
    if (!this.isReply && this.item.draft) {
      setTimeout(() => {
        this.commentInputEl.nativeElement.querySelector('.fr-element').focus();
      });
    }
  }

  save(draft: boolean = false) {
    console.log('saving', this.form.value, this.item);

    this.updateComment({
      ...pick(this.item, ['id', 'temp_id', 'parent_id', 'parent_temp_id']),
      ...this.form.value,
      type: 'comment',
      draft
    }, (draft !== this.parent.draft && !draft && !this.isReply) ? this.removeDraftClass : null);

    this.inputInFocus = false;

    if (!draft) {
      this.isEditing = false;
    }
  }

  updateComment(updates: any, callback?: () => void) {
    if (this.form.value.comment) {
      this.store.dispatch(Actions.Comments.updateComment(this.parent.id, this.resource, updates, callback));
    }
  }

  delete() {
    if ((!NewTempId.isTemp(this.item.id) || !this.isReply) // If this comment isn't temporary, or it's a temporary parent comment
      && (this.item.draft || !this.isEditing)) {  // and it's a draft or not being edited,
      // Fully delete the comment
      this.store.dispatch(Actions.Comments.deleteComment(this.parent.id, this.resource, this.item, this.removeHighlights));
    } else {
      // Otherwise, just clear/reset the form
      this.form.reset({ comment: this.item.comment });
    }

    this.inputInFocus = false;
    this.isEditing = false;
    this.showDeleteAlert = false;
  }

  edit() {
    this.isEditing = true;
  }

  removeHighlights = () => {
    // Text areas never use temp IDs
    if (NewTempId.isTemp(this.parent.id)) {
      return;
    }

    const element = document.getElementById(this.resource.element_id);
    if (element && element.tagName === 'PO-HTML-TEXTAREA') {
      const span = element.querySelector(`span.${COMMENT_ID_CLASSES.COMMENT_ID}--${this.parent.id}`);
      if (!span) {
        return;
      }
      // Remove the "span" element containing the comment styling classes
      const innerHTML = document.createTextNode(span.innerHTML);
      span.parentNode.replaceChild(innerHTML, span);

      const froalaEl = element.querySelector('.fr-element') as HTMLElement;
      if (froalaEl) {
        // Blurring triggers both the blur and change events on po-html-textareas.
        // TODO: Find a less hacky way to do this?
        froalaEl.focus();
        froalaEl.blur();
      }
    }
  }

  removeDraftClass = () => {
    // Text areas never use temp IDs
    if (NewTempId.isTemp(this.parent.id)) {
      return;
    }

    const element = document.getElementById(this.resource.element_id);
    if (element && element.tagName === 'PO-HTML-TEXTAREA') {
      const span = element.querySelector(`span.${COMMENT_ID_CLASSES.COMMENT_ID}--${this.parent.id}`);
      if (!span) {
        return;
      }

      // Remove the draft class
      span.classList.replace(COMMENT_ID_CLASSES.DRAFT, FROALA_HIGHLIGHT_CLASS);

      const froalaEl = element.querySelector('.fr-element') as HTMLElement;
      if (froalaEl) {
        // Blurring triggers both the blur and change events on po-html-textareas.
        // TODO: Find a less hacky way to do this?
        froalaEl.focus();
        froalaEl.blur();
      }
    }
  }

  onInputFocus() {
    console.log('focusing in');
    this.inputInFocus = true;
  }

  onInputBlur(event) {
    const buttonInPath = [this.cancelBtnEl.nativeElement, this.actionBtnEl.nativeElement].includes(event.originalEvent.relatedTarget);

    if (!buttonInPath) {
      // If draft and not an empty reply, save on blur
      if (this.item.draft && !(this.isReply && !this.form.value.comment)) {
        this.save(true);
      } else {
        this.inputInFocus = false;
      }
    } else {
    }
  }

  get isReply(): boolean {
    return !!this.item.parent_id;
  }

  get isTemp() {
    return NewTempId.isTemp(this.item.id);
  }

  get showButtons() {
    // TODO: clean this up
    if ((!this.isReply && this.item.draft) || (this.form.value.comment && this.item?.draft) || this.isEditing || (this.isSelected && this.isReply && this.inputInFocus)) {
      return true;
    }

    return false;
  }

  get showOptionsMenu() {
    return (this.optionsMenuEl?.showOptions || this.mouseover) // Show menu if its open or if card is hovered over
      && this.canEdit && this.isCreator && !this.showButtons
      && !this.parent.resolved;
  }

  get actionLabel() {
    if (this.isTemp || this.item.draft) {
      if (this.isReply) {
        return 'Reply';
      } else {
        return 'Comment';
      }
    } else {
      return 'Save';
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
