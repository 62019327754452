import { Model } from '@app-ngrx-domains';
import { DEFAULT_STEP_OPTIONS, WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';

export const DISTRICT_ALLOCATION_END_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.SEP_ALLOCATION_END,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.EXPENDITURES,
      title: 'Allocations & Expenditures',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    }
  ]
};

export const DISTRICT_TER_REPORTING_PERIOD = 'SEA District Term-End Expenditures Report';
export const DESELECT_LOV: Array<Model.SelectOption> = [
  { label: 'Cohort/group no longer exists at college', value: 'no_longer_exists' },
  { label: 'Little to no progress made since last report', value: 'no_longer_progressing' },
  { label: 'No longer implementing this activity/strategy', value: 'no_longer_implementing' },
]
