import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Actions, Model, Queries, State } from '@app-ngrx-domains';
import { TASK_TYPES } from '@app/core/consts';
import { EnumErrorTypes, Profile } from '@app/core/models';
import { ApiService, ProgramService } from '@app/core/services';
import { ValidatorsEx } from '@app/core/utilities';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { uniq } from 'lodash';

@Component({
  selector: 'user-program-signup-v2',
  templateUrl: './user-program-signup-v2.component.html'
})
export class UserProgramSignupv2Component implements OnInit, OnDestroy {

  @Input() proposals: Array<Model.ProposalItem>;

  proposalOptions: Array<Model.SelectOption>;
  fund: Model.Fund;
  signupTask: Model.Task;
  signupForm: FormGroup;
  showSignupModal: boolean;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<State>,
    private apiService: ApiService,
    private programService: ProgramService,
    private _fb: FormBuilder
  ) {}

  ngOnInit() {
    const fundIds = uniq(this.proposals.map(proposal => proposal.fund_ids[0]));
    this.store.select(Queries.Auth.getCurrentUser).pipe(
      skipWhile(user => !user),
      takeUntil(this.destroy$)
    ).subscribe((user: Profile) => {
      if (user && user.tasks) {
        this.signupTask = user.tasks.find(task => task.task_type === TASK_TYPES.GENERIC_SIGNUP && !task.completed && fundIds.includes(task.fund_id));
        if (!this.signupTask) {
          return;
        }

        this.fund = this.programService.getProgramById(this.signupTask.fund_id);
        this.proposalOptions = this.proposals.filter(proposal => proposal.fund_ids.includes(this.fund.id))
          .map(proposal => ({ value: proposal.id, label: proposal.title }));

        if (!this.proposalOptions) {
          return;
        }

        this.signupForm = this._fb.group({
          proposal_id: [undefined, [ValidatorsEx.requiredSelection]]
        });

        this.showSignupModal = true;
      }
    });
  }

  submitSignup() {
    this.store.dispatch(Actions.Layout.showBusySpinner(true));
    this.showSignupModal = false;

    const { proposal_id } = this.signupForm.value;
    this.apiService.completeTask({ ...this.signupTask, proposal_id }).subscribe(() => {
      this.store.dispatch(Actions.Auth.login());
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      this.store.dispatch(Actions.Layout.updateHeaderMessage('success'));
    }, err => {
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      this.store.dispatch(Actions.App.setError({
        location: this.constructor.name,
        type: EnumErrorTypes.api,
        raw: err,
        show: false,
        message: 'There was an error completing your registration. Please try again.'
      }));
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
