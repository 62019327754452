import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { State, Actions } from '@app-ngrx-domains';
import { ApiService, ProgramService } from '@app-services';
import { Store } from '@ngrx/store';
import { AREA_NAMES } from '@app-consts';
import { Fund, Utilities } from '@app-models';

@Component({
  selector: 'analytics',
  templateUrl: './analytics.component.html',
})

export class AnalyticsComponent implements OnInit {
  // Variables shared with the view
  lookerUrl: string;
  isSmallProgram: boolean;

  // Private resources attached to `this`
  constructor(
    protected route: ActivatedRoute,
    protected apiService: ApiService,
    protected store: Store<State>,
    protected router: Router,
    protected programService: ProgramService
  ) {}

  ngOnInit() {
    const programKey = Utilities.programKeyFromRoute(this.router.url);
    const program = this.programService.getParentProgramByKey(programKey);
    const routeData = this.route.snapshot.data;
    const resource = {
      fund_id: program.id,
      area_name: AREA_NAMES[routeData.areaName != null ? routeData.areaName : 'analytics']
    };

    this.store.dispatch(Actions.Layout.setHeader({
      ...State.Layout.header,
      programTitle: Fund.getHeaderName(program),
      routeTitle: routeData.title != null ? routeData.title : 'Analytics',
      title: 'Analytics Dashboard',
      resource
    }));

    let lookerObjectRouteData = routeData.lookerObject;
    this.isSmallProgram = routeData.isSmallProgram;

    // Apply filter to looker dashboards on program key
    this.isSmallProgram ? lookerObjectRouteData += `?KEY=${programKey}` : null;

    const lookerObject = Array.isArray(lookerObjectRouteData)
      ? lookerObjectRouteData.find(obj => obj.program === programKey)?.route
      : lookerObjectRouteData;

    this.apiService.get_looker_url(lookerObject).subscribe(res => {
      this.lookerUrl = `https://${res.url}`;
    });
  }
}
