<div *ngIf="mentionNotification && mentionBody" [@slideInOut] class="status-card status-card--small status-card--info">
  <icon iconId="message" [notification]="true" class="icon--double margin-right-sm"></icon>

  <div class="status-card__body margin-left-sm padding-right">
    <span class="bold margin-right-sm">{{mentionHeader}}</span>
    <span [innerHTML]="mentionBody | safeHtml"></span>
  </div>

  <button class="action-button action-button--secondary action-button--small margin-right-sm"
    (click)="dismissNotification()">
    Dismiss
  </button>

  <a *ngIf="commentResourceId" class="action-button action-button--primary action-button--small"
    (click)="dismissNotification()"
    routerLink="/redirect" [queryParams]="{ resourceId: commentResourceId }">
    Go to Comment
  </a>
</div>

<div class="app-dashboard">
  <app-action-items *ngIf="!hideEventFeatures"></app-action-items>
  <app-calendar *ngIf="!hideEventFeatures" class="calendar"></app-calendar>
  <app-bookmarks></app-bookmarks>
</div>
