import { ILayoutState, State } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { LAYOUT_ACTION_TYPES } from './layout.action';

export function LayoutReducer(state: ILayoutState = State.Layout, action: ActionWithPayload<any>): ILayoutState {

  switch (action.type) {

    case LAYOUT_ACTION_TYPES.CLEAR_HEADER: {
      return {
        ...state,
        header: { ...State.Layout.header },
      };
    }

    case LAYOUT_ACTION_TYPES.CLEAR_HEADER_STATUS: {
      return {
        ...state,
        header: {
          ...state.header,
          status: State.Layout.header.status,
        },
      };
    }

    case LAYOUT_ACTION_TYPES.CLEAR_HEADER_RESOURCE: {
      return {
        ...state,
        header: {
          ...state.header,
          resource: State.Layout.header.resource
        }
      }
    }

    case LAYOUT_ACTION_TYPES.EMIT_ACTION: {
      return {
        ...state,
        header: {
          ...state.header,
          emit: action.payload,
        },
      };
    }

    case LAYOUT_ACTION_TYPES.SET_ACTIONS: {
      return {
        ...state,
        header: {
          ...state.header,
          actions: action.payload,
          emit: null,
        },
      };
    }

    case LAYOUT_ACTION_TYPES.UPDATE_ACTIONS: {
      const toUpdate = _.isArray(action.payload) ? action.payload : [action.payload];
      const reduced = state.header.actions.map(item => {
        const foundIndex = _.findIndex(toUpdate, { id: item.id });
        if (foundIndex >= 0) {
          return {
            ...item,
            ...toUpdate[foundIndex],
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        header: {
          ...state.header,
          actions: reduced,
          emit: null,
        },
      };
    }

    case LAYOUT_ACTION_TYPES.APPEND_ACTIONS: {
      const toAppend = _.isArray(action.payload) ? action.payload : [action.payload];
      const toKeep = state.header.actions.filter(i => i.keep);
      const reduced = [
        ...toKeep,
        ...toAppend,
      ]

      return {
        ...state,
        header: {
          ...state.header,
          actions: reduced,
          emit: null
        },
      };
    }

    case LAYOUT_ACTION_TYPES.SET_BOOKMARK: {
      return {
        ...state,
        bookmark: {
          show: action.payload
        }
      };
    }

    case LAYOUT_ACTION_TYPES.SET_HEADER: {
      return {
        ...state,
        header: { ...action.payload },
      };
    }

    case LAYOUT_ACTION_TYPES.SET_HEADER_STATUS: {
      return {
        ...state,
        header: {
          ...state.header,
          status: action.payload.text,
        },
      };
    }

    case LAYOUT_ACTION_TYPES.SET_HEADER_TITLES: {
      return {
        ...state,
        header: {
          ...state.header,
          ...action.payload,
        },
      };
    }

    case LAYOUT_ACTION_TYPES.SET_PAGE: {
      return {
        ...state,
        page: {
          ...state.page,
          ...action.payload,
        },
      };
    }

    case LAYOUT_ACTION_TYPES.SHOW_BUSY_SPINNER: {
      return {
        ...state,
        spinner: {
          ...state.spinner,
          show: action.payload,
        },
      };
    }

    case LAYOUT_ACTION_TYPES.UPDATE_HEADER_MESSAGE: {
      return {
        ...state,
        headerMessage: {
          type: action.payload.type ? action.payload.type : '',
          message: action.payload.message ? action.payload.message : ''
        }
      };
    }

    case LAYOUT_ACTION_TYPES.UPDATE_NAV: {
      return {
        ...state,
        nav: {
          ...state.nav,
          ...action.payload,
        },
      };
    }

    case LAYOUT_ACTION_TYPES.SHOW_LOGO: {
      return {
        ...state,
        logo: {
          ...action.payload,
        }
      };
    }

    case LAYOUT_ACTION_TYPES.SET_ADDITIONAL_HEADER: {
      const { headerName, template, data } = action.payload;
      let headers = [...state.additionalHeaders];
      const index = headers.findIndex(h => h.headerName === headerName);

      if (index < 0) {
        headers.push(action.payload);
      } else {
        const header = { ...headers[index] };
        header.template = template;

        if (data != null) {
          header.data = data;
        }

        headers = [
          ...state.additionalHeaders.slice(0, index),
          header,
          ...state.additionalHeaders.slice(index + 1),
        ];
      }

      return {
        ...state,
        additionalHeaders: headers
      };
    }

    case LAYOUT_ACTION_TYPES.UPDATE_ADDITIONAL_HEADER: {
      const { headerName, data } = action.payload;
      let headers = [...state.additionalHeaders];
      const index = headers.findIndex(h => h.headerName === headerName);

      if (index < 0) {
        headers.push(action.payload);
      } else {
        headers = [
          ...state.additionalHeaders.slice(0, index),
          { ...headers[index], data },
          ...state.additionalHeaders.slice(index + 1),
        ];
      }

      return {
        ...state,
        additionalHeaders: headers
      };
    }

    case LAYOUT_ACTION_TYPES.REMOVE_ADDITIONAL_HEADER: {
      const { headerName } = action.payload;

      return {
        ...state,
        additionalHeaders: state.additionalHeaders.filter(h => h.headerName !== headerName)
      };
    }

    case LAYOUT_ACTION_TYPES.SET_COMMENTS_RESOURCE: {
      return {
        ...state,
        comments: {
          ...state.comments,
          resource: action.payload.resource
        }
      };
    }

    case LAYOUT_ACTION_TYPES.SET_COMMENTS_OPEN: {
      return {
        ...state,
        comments: {
          ...state.comments,
          isOpen: action.payload.isOpen,
        },
      };
    }

    case LAYOUT_ACTION_TYPES.SET_COMMENTS_TOTAL_COMMENTS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          totalComments: action.payload.totalComments,
        },
      };
    }

    default:
      return state;
  }

}
