import {take, skipWhile, withLatestFrom, filter, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Model, Queries, Actions } from '@app-ngrx-domains';
import { ProgramService } from '@app-services';
import { ROUTER_LINKS, PROGRAM_KEYS } from '@app-consts';
import { EnumErrorTypes, Utilities } from '@app-models';

@Injectable()
export class FundVersionGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private programService: ProgramService,
    private store: Store<State>,
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.verifyFund(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.verifyFund(route, state);
  }

  verifyFund(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.logger.debug(`[fund-version-guard][${state.url}] checking for relevant fund...`);

    return new Observable((subscriber) => {
      this.getFundId(route).subscribe(async fundId => {
        const fundStatus = await this.programService.verifyFundVersion(fundId);
        if (fundStatus === EnumErrorTypes.user) {
          this.logger.debug(`[project-guard][${state.url}] fund has been refreshed - reloading route`);
          this.store.dispatch(Actions.App.go([state.url.split('?')[0]], { queryParams: state.root.queryParams }));
          subscriber.next(false);
          subscriber.complete();
          return;
        } else if (fundStatus === EnumErrorTypes.core) {
          this.logger.debug(`[project-guard][${state.url}] fund is currently locked - exiting route`);
          this.store.dispatch(Actions.App.showAlert(`Changes are currently being applied to this program. Please allow a few minutes for changes to complete.`));

          const routeParams: { [name: string]: number } = {};
          Utilities.getRouteConfig(route, routeParams);

          const rerouteUrl = route.data?.reroutePath && Utilities.buildRerouteUrl(route.data?.reroutePath, routeParams) || ROUTER_LINKS.HOME;
          this.router.navigate([rerouteUrl]);

          subscriber.next(false);
          subscriber.complete();
          return;
        } else {
          this.store.dispatch(Actions.Auth.setRouteWatch(true));

          subscriber.next(true);
          subscriber.complete();
        }
      });
    });
  }

  getFundId(route: ActivatedRouteSnapshot): Observable<number> {
    let fundId = Number(route.params['fundId']);
      if (!isNaN(fundId)) {
        this.logger.debug(`[fund-version-guard] found fundId in route param: ${fundId}`);
        return of(fundId);
      } else {
        const proposalId = Number(route.params['proposalId']);
        if (!isNaN(proposalId)) {
          this.logger.debug(`[fund-version-guard] found proposalId in route param: ${proposalId}`);
          return this.store.select(Queries.CurrentProposal.getProposal).pipe(
            take(1),
            mergeMap((proposal) => {
            return of(proposal.funds[0].id);
          }));
        } else {
          this.logger.error(`[fund-version-guard] couldn't determine fundId from route params`);
          return EMPTY;
        }
      }
  }
}

