import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '@app-services';
import { Profile } from '@app-models';
import { Store } from '@ngrx/store';
import { State, Actions } from '@app-ngrx-domains';
import { ValidatorsEx } from '@app-utilities';
import { PROJECT_ROLES } from '@app/core/consts';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
})
export class InviteUserComponent implements OnInit, OnDestroy {

  @Input() description = 'Invite a new user to NOVA'; // Replaces description text above form
  @Input() cancelButton = 'Cancel'; // Replaces text in the secondary button
  @Input() additionalForm; // Form controller to append additional form elements to invite form
  @Input() formConfig; // Config for additionalForm: ex: { middle_name:{label: 'Middle', type: 'text', placeholder: 'Stephen', required: false} }
  @Input() contactOnly = true; // Add user as a contactOnly - Do not send email notification
  @Input() associateFunds: Array<number> = []; // Array of fund_ids to associate the user with upon creation
  @Input() fundKey: string;
  @Input() proposalId: number;
  @Input() roleId: number;
  @Input() institutionId: number;

  @Output() closeAction: EventEmitter<null> = new EventEmitter();
  @Output() cancelAction: EventEmitter<null> = new EventEmitter();
  @Output() inviteSuccess: EventEmitter<Profile> = new EventEmitter();


  formInviteUser: FormGroup;
  errorMessage: string;
  formKeys = [];

  constructor(
    private apiService: ApiService,
    private store: Store<State>,
    private _fb: FormBuilder,
  ) { }

  ngOnInit() {

    // initialize invite user form group
    this.formInviteUser = this._fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, ValidatorsEx.email]],
    });

    if (this.additionalForm) {
      this.formKeys = this.getKeys(this.additionalForm.controls);
    }
  }

  getKeys(object: any) {
    return Object.keys(object);
  }

  formsValid(): boolean {
    return this.additionalForm ?
      this.additionalForm.valid && this.formInviteUser.valid
      : this.formInviteUser.valid;
  }

  ngOnDestroy() {

  }

  /**
   * Invites a new user to NOVA
   */

  inviteUser() {
    const profile = new Profile();
    profile.last_name = this.formInviteUser.get('last_name').value;
    profile.first_name = this.formInviteUser.get('first_name').value;
    profile.email_address = this.formInviteUser.get('email').value.toLowerCase();

    // start busy spinner...
    this.store.dispatch(Actions.Layout.showBusySpinner(true));
    this.apiService.inviteUser(profile.serverObject(), this.fundKey, this.proposalId, this.institutionId, this.getRoleName(this.roleId))
      .subscribe(
        (user: Profile) => {
          this.inviteSuccess.emit(user);
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
        },
        (err) => {
          // stop busy spinner...
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          // error occurred... let the user know.
          if (!err || !err.error) {
            this.errorMessage = `An unknown error occurred while adding user '${profile.email_address}' to NOVA. Please try again.`;
          } else {
            this.errorMessage = err.error.code === 409
              ? `The user you’re trying to add is already in the NOVA database. Please return to the previous page and try your search again.`
              : `Error occurred while adding user '${profile.email_address}' to NOVA: ${err.error.message}.  Please try again.`
          }
        });
    }

  getRoleName(roleId: number): string {
    const keys = Object.keys(PROJECT_ROLES)
    let role;
    for (const key of keys) {
      role = PROJECT_ROLES[key];
      if (role.ID === roleId && role.ALIASES) {
        return role.ALIASES[this.fundKey] ? role.ALIASES[this.fundKey] : role.ALIASES['DEFAULT'];
      }
    }
    return '';
  }

}
