
import { debounceTime, withLatestFrom } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Model, State, Queries } from '@app-ngrx-domains';
import { environment } from '../../../../environments/environment'
import { ApiService, TitleService } from '../../services';
import { Utilities, ResourceNote, Resource, IResourceNote, Fund } from '../../models';
import { ROUTER_LINKS, TIMEOUT_DELAYS } from '../../consts';
import { Observable, Subject } from 'rxjs';
import { AppUtils } from '@app/core/utilities';

@Component({
  selector: 'content-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() printMode = false;

  public showDashboardFilters: boolean;
  public headerInfo: Model.HeaderLayout = { ...State.Layout.header };
  public headerMessage: Model.StatusMessage;
  public isBookmarkable: boolean;
  public isProfile: boolean;
  public activeBookmark: boolean;
  public userData: Model.User;
  public userActionsSubtitle;
  public logo = environment.logos.header;
  public profileName$: Observable<string>;
  public loggedIn$: Observable<boolean>;
  public grantTitle: { term: string, name: string, id?: string };
  public unreadMessages: Array<Model.UserMessage> = [];

  public additionalHeaders: Array<Model.AdditionalHeader> = [];
  private bookmark: Model.ResourceNote;
  private maxUnreadMessages: number = 6;

  timeStamp = () => Utilities.convertDateToString(null, true);

  constructor(
    private apiService: ApiService,
    private store: Store<State>,
    public router: Router,
    private titleService: TitleService
  ) {
    this.profileName$ = this.store.select(Queries.Auth.getFullName);
    this.loggedIn$ = this.store.select(Queries.Auth.isLoggedIn);

    this.showDashboardFilters = AppUtils.showWIPFeature('calendar');
  }

  ngOnInit() {
    this.store.select(Queries.Layout.getHeader).subscribe((header) => {
        this.headerInfo = header;
        this.updateBrowserTitle();
        this.grantTitle = undefined;
        this.isProfile = !!this.headerInfo.isProfile;

        const grant = this.headerInfo.grant;
        if (grant) {
          this.grantTitle = {
            term: Fund.getGrantTerm(grant),
            name: Fund.getHeaderName(grant),
            id: grant.program_settings?.grant_id
          };
        }

        this.userData = this.headerInfo.user;
        if (!!this.userData) {
          const subTitle = this.headerInfo.sub_title;
          const userName = `${this.userData.first_name} ${this.userData.last_name}`;
          const parts = subTitle && subTitle.split(userName);
          this.userActionsSubtitle = {
            prefix: parts && parts[0],
            suffix: parts && parts[1],
            userName
          };
        }
      },
      this.handleError);

    this.store.select(Queries.Layout.getBookmarkResource).pipe(
      debounceTime(250),
      withLatestFrom(this.store.select(Queries.Auth.getCurrentUserId))
    ).subscribe(([resource, userId]) => {
      this.activeBookmark = false;
      this.isBookmarkable = !!resource;
      if (this.isBookmarkable && userId) {
        this.initializeBookmarkData();
      }
    });

    this.store.select(Queries.Layout.getHeaderMessage)
      .subscribe((message: Model.StatusMessage) => {
      this.headerMessage = message;
      if (message.type === 'fail' || message.type === 'success') {
        this.clearHeaderMessage();
      }
    });

    this.store.select(Queries.Auth.getCurrentUserUnreadMessages)
      .subscribe(messages => {
        this.unreadMessages = messages.slice(0, this.maxUnreadMessages);
      });

    this.store.select(Queries.Layout.getAdditionalHeaders)
      .subscribe((headers) => {
        this.additionalHeaders = headers;
    });
  }

  get headerString() {
    let str = '';
    if (this.headerInfo.programTitle) {
      str += ` ${this.headerInfo.programTitle}`;
    }
    if (this.headerInfo.routeTitle) {
      str += ` ${this.headerInfo.routeTitle}`;
    }
    if (this.headerInfo.title) {
      str += ` ${this.headerInfo.title}`;
    }
    if (!this.headerInfo.programTitle && !this.headerInfo.routeTitle && !this.headerInfo.title) {
      str += ' Invest & Plan for Student Success';
    }
    return str;
  }

  get subTitleCase() {
    if (!this.headerInfo.subTitleHref && this.userData) {
      return 'withUserData';
    } else if (!!this.headerInfo.subTitleHref) {
      return 'withHref';
    }
  }

  updateBrowserTitle() {
    let str = 'NOVA: ';
    str += this.headerString;
    this.titleService.setTitle(str);
  }

  showHeaderLink() {
    const routes = ['/help', '/passwordReset', '/forgotPassword', '/sign-up', ROUTER_LINKS.MAINTENANCE];
    return routes.reduce((show, route) => show || this.router.url.startsWith(route), false);
  }

  showHeaderMessage() {
    return this.headerMessage.type && this.headerMessage.type.length;
  }

  private clearHeaderMessage() {
    setTimeout(() => {
      this.store.dispatch(Actions.Layout.updateHeaderMessage());
    }, TIMEOUT_DELAYS.MEDIUM);
  }

  addBookmark() {
    this.apiService.createBookmark(this.headerInfo.resource).subscribe(() => {
      this.activeBookmark = true;
    }, this.handleError);
  }

  handleError = Utilities.setCallbackError(this.store, this.constructor.name);

  initializeBookmarkData() {
    const note_info = this.headerInfo.resource;
    delete note_info.id;

    if (note_info) {
      this.apiService.getBookmarks(note_info)
        .subscribe((response: Array<IResourceNote>) => {
          const bookmark = response.find(r => Resource.compareResponse(note_info, r));
          if (bookmark) {
            this.activeBookmark = !bookmark.deleted;
          }
        }, this.handleError)
    }
  }

  removeBookmark() {
    this.apiService.deleteBookmark(this.headerInfo.resource).subscribe(() => {
      this.activeBookmark = false;
    }, this.handleError);
  }

  trackByHeaderName(index: number, item: any): number {
    return item.headerName;
  }

  logout() {
    this.store.dispatch(Actions.Auth.logout());
    this.router.navigate(['/login']);
  }
}
