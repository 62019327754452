<div class="budget-summary" [stickyCard]="isSticky" [ngClass]="{ 'budget-summary--bordered': bordered, 'margin-bottom': spaced }">
  <ng-container *ngTemplateOutlet="header"></ng-container>
  <ng-container *ngIf="secondaryTitleTemplate" [ngTemplateOutlet]="secondaryTitleTemplate"></ng-container>

  <div *ngIf="summaryEntries" class="budget-summary__grid">
    <ng-container *ngFor="let entry of summaryEntries">
      <div [class]="entry.class" [ngClass]="{ 'budget-summary__invalid': canEdit && entry.isInvalid() }">
        <p class="budget-summary__status-title">{{entry.title}}</p>
        <span *ngIf="entry.isSet(); else loadingAmount" class="budget-summary__status-text" [ngClass]="{'budget-summary__alert': entry.amountErrors && entry.isInvalid()}">
          {{ entry.isNotCurrency ? entry.amount() : (+entry.amount() | currency:'USD':'symbol':'1.0-1')}}
          <span *ngIf="entry.helpText">{{entry.helpText()}}</span>
          <span *ngIf="canEdit && entry.isInvalid() && entry.alertText()" class="budget-summary__alert" [class.margin-left-xs]="entry.helpText?.length" [attr.data-invalid]="true">
            {{entry.alertText()}}
          </span>
        </span>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="template" [ngTemplateOutlet]="template"></ng-container>
</div>

<ng-template #header>
  <div class="budget-summary__header">
    <ng-container [ngSwitch]="headingLevel">
      <h2 *ngSwitchCase="'h2'" class="{{headingClass}}">{{summaryTitle}}</h2>
      <h3 *ngSwitchCase="'h3'" class="{{headingClass}}">{{summaryTitle}}</h3>
      <h4 *ngSwitchCase="'h4'" class="{{headingClass}}">{{summaryTitle}}</h4>
    </ng-container>
    <ng-container *ngTemplateOutlet="modifyButtons"></ng-container>
    <ng-container *ngTemplateOutlet="maxReached"></ng-container>
  </div>
</ng-template>

<ng-template #modifyButtons>
  <div *ngIf="canModify">
    <button *ngIf="!isModifying" type="button"
      class="action-button action-button--primary action-button--small"
      (click)="emitModify(false, true)"
      [disabled]="!hasUnsavedModifications && maxModifyReached">
      Modify Budget
    </button>
    <ng-container *ngIf="isModifying">
      <button type="button"
        class="action-button action-button--secondary action-button--small margin-right-sm"
        (click)="emitModify(false, false, true)">
        Cancel
      </button>
      <ng-container *ngIf="hasUnsavedModifications">
        <button type="button"
          class="action-button action-button--secondary action-button--small margin-right-sm"
          (click)="emitModify(false, false)">
          Discard Changes
        </button>
        <button type="button"
          class="action-button action-button--primary action-button--small"
          (click)="emitModify(true, true)">
          Review &amp; Save Changes
        </button>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #maxReached>
  <p *ngIf="!isModifying && !hasUnsavedModifications && maxModifyReached" class="error-text no-margin">
    Maximum budget adjustments have already been made. You must decertify this plan to make additional changes.
  </p>
</ng-template>

<ng-template #loadingAmount>
  <p class="message">Loading... <span class="loading-spinner-inline"></span></p>
</ng-template>