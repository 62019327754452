import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actions, State, Queries, Model } from '@app-ngrx-domains';
import { Subject, forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { LookupService, ProgramService, ApiService } from '@app/core/services';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Profile } from '@app/core/models';
import { PhoneTransform, PhoneExtensionTransform } from '@app/shared.generic/pipes';
import { collapsibleCardAnimation, fadeOutAnimation } from '@app-generic/animations';
import { sortBy, uniq } from 'lodash';
import { PROGRAM_KEYS, PROJECT_ROLES, SYSTEM_ROLES } from '@app/core/consts';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'proposal-contact-table',
  templateUrl: './contact-table.component.html',
  animations: [collapsibleCardAnimation, fadeOutAnimation]
})
export class ContactTableComponent implements OnInit, OnDestroy {

  @Input() canEdit: boolean = false;
  @Input() isPreview: boolean = false;
  @Input() headerText: string = 'Contacts';
  @Input() collapsible: boolean = true; // Disabled if 'cardless' is true
  @Input() startCollapsed: boolean = false;
  @Input() contactTypes: Array<Model.ContactTableType> = [];
  @Input() program: Model.Fund;
  @Input() guidance: Model.GuidanceWorkflowFilter;
  @Input() modalSmall: boolean = true;
  @Input() showSystemHelperText: boolean = true;
  @Input() cardless: boolean = false; // Render content without a surrounding card
  @Input() showOptionalHelperText: boolean = false;

  @Output() isValid: EventEmitter<boolean> = new EventEmitter();

  form: FormGroup;
  contacts: Array<Model.UserRoleScope> = [];
  systemRoles: Array<Model.SystemContactItem> = [];
  institutions: Array<Model.SelectOption> = [];
  filteredInstitutions: Array<Model.SelectOption> = [];
  proposal: any;
  parentProgram: Model.Fund;

  requiredContacts: Array<Model.ContactTableType & {institutionId?: number}> = [];
  optionalContacts: Array<Model.ContactTableType & {institutionId?: number}> = [];
  roleData: Model.RolePermissions;
  roleOptions: Array<Model.SelectOption> = [];
  addContactModalStep: number;

  contactToRemove: Model.UserRoleScope;
  systemRoleToRemove: { user: Model.User, roleType: Model.ContactTableType };
  collapsed: boolean;

  private destroy$: Subject<boolean> = new Subject();
  private initialized: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private store: Store<State>,
    private apiService: ApiService,
    private lookupService: LookupService,
    private programService: ProgramService,
    private phoneTransform: PhoneTransform,
    private phoneExtTransform: PhoneExtensionTransform
  ) { }

  ngOnInit() {
    if (this.guidance && this.guidance.isTemplate) {
      this.setupAsTemplate();
      return;
    }

    const forPrint = this.route.snapshot.queryParams['forPrint'];
    this.collapsed = !forPrint && this.startCollapsed;

    this.store.select(Queries.Contacts.get).pipe(
      withLatestFrom(
        this.store.select(Queries.CurrentProposal.get)
      ),
      takeUntil(this.destroy$)
    ).subscribe(([contacts, proposal]) => {
      const filteredContacts = contacts.filter(contact =>
        contact.role_id !== PROJECT_ROLES.PROJECT_REVIEWER.ID &&
        (this.program.is_small_program ? true : this.contactRoleIds.includes(contact.role_id)));
      this.contacts = filteredContacts;
      this.proposal = proposal;
      this.initialize();
      this.updateValidity();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    this.parentProgram = !!this.program.parent_key ? this.programService.getParentProgramByKey(this.program.parent_key) : this.program;

    this.institutions = (this.proposal.institutions || []).map((i: Model.Institution) => {
      return { label: i.name, value: i.id }
    });
    this.filteredInstitutions = this.institutions;

    this.setupRequiredAndOptionalContacts();
    // this.setupSystemRoles();

    this.store.select(Queries.Institution.getInstitutionalContacts)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.setupSystemRoles())

    this.form = this._fb.group({
      role: [undefined, [Validators.required]],
      user: [undefined, [Validators.required]],
      institution: [undefined, []] // TODO: Conditional validator based on role selection
    });
  }

  private setupAsTemplate() {
    this.setupRequiredAndOptionalContacts();
    this.setupSystemRoles();
  }

  private setupRequiredAndOptionalContacts() {
    this.requiredContacts = [];
    this.optionalContacts = [];

    let budgetedInstitutions = [];
    const isSWPR = this.proposal.funds[0].parent_key === PROGRAM_KEYS.SWP_R;
    if (isSWPR) {
      budgetedInstitutions = uniq(this.proposal.plan_budget_items.map(i => i.institution_id));
    }

    this.contactTypes.filter(cType => !cType.isSystemRole || cType.allowAssignment).forEach(cType => {
      const roleData = this.lookupService.getRole(cType.roleId);
      this.roleOptions.push({ value: cType.roleId, label: cType.roleName, extras: roleData });

      if (cType.numberRequired) {
        // institutions are specified, append name of institution to role to specify role per institution is required
        if (cType.institutions && cType.institutions.length) {
          cType.institutions.forEach(inst => {
            const institutionObj = this.getInstitution(inst);
            const contact = {
              roleId: cType.roleId,
              roleName: cType.roleName + `: ${!!institutionObj ? institutionObj.name : 'unknown'}`,
              institutionId: inst,
              numberRequired: cType.numberRequired
            }

            // Do not require fiscal contact if SWP-R participating institution has no budget
            if (isSWPR && cType.roleId === PROJECT_ROLES.INSTITUTION_PROJECT_FISCAL_REPORTER.ID && !budgetedInstitutions.includes(inst)) {
              this.optionalContacts.push(contact);
            } else {
              this.requiredContacts.push(contact);
            }
          });
        } else {
          this.requiredContacts.push(cType);
        }
      } else if (this.showOptionalHelperText) {
        if (cType.institutions && cType.institutions.length) {
          cType.institutions.forEach(inst => {
            const institutionObj = this.getInstitution(inst);
            this.optionalContacts.push({
              roleId: cType.roleId,
              roleName: cType.roleName + `: ${!!institutionObj ? institutionObj.name : 'unknown'}`,
              institutionId: inst,
              numberRequired: cType.numberRequired
            });
          });
        } else {
          this.optionalContacts.push(cType);
        }
      }
    });

    this.requiredContacts = sortBy(this.requiredContacts, 'sortOrder');
    this.optionalContacts = sortBy(this.optionalContacts, 'sortOrder');
  }

  private setupSystemRoles() {
    this.systemRoles = [];
    this.contactTypes.filter(cType => cType.isSystemRole).forEach(cType => {
      this.systemRoles.push({ ...cType, users: [] });

      let skipLookup = false;
      const lookupPayload = { parentKey: this.parentProgram.key, roleId: cType.roleId, sectorId: undefined, institutionId: undefined };

      if (cType.roleId === SYSTEM_ROLES.SECTOR_FUND_MONITOR.ID) {
        if (!!this.proposal && this.proposal.sector_id) {
          lookupPayload.sectorId = this.proposal.sector_id;
        } else {
          skipLookup = true;
        }
      } else {
        const role = this.lookupService.getRole(cType.roleId);
        if (role.institution) {
          if (this.proposal && this.proposal.lead_institution?.id) {
            lookupPayload.institutionId = this.proposal.lead_institution.id;
          } else {
            skipLookup = true;
          }
        }
      }

      if (!skipLookup) {
        this.lookupService.getProgramUsersByRole$(lookupPayload).subscribe(users => {
          const index = this.systemRoles.findIndex(item => item.roleName === cType.roleName);
          this.systemRoles[index].users = users;
        });
      }
    });
  }

  private getInstitution(institution_id: number): Model.Institution {
    let inst = this.proposal.institutions.find(i => i.id === institution_id);
    if (!inst) {
      inst = this.lookupService.getInstitution(institution_id);
    }
    return inst;
  }

  updateRoleData() {
    const roleId = this.form.get('role').value;
    this.roleData = this.roleOptions.find(option => option.value === roleId).extras;

    const contactInfo = this.contactTypes.find(c => c.roleId === roleId);

    // set institution options to ones provided if applicable
    if (contactInfo.institutionOptions) {
      this.filteredInstitutions = contactInfo.institutionOptions.map(iO => {
        const institutionObj = this.getInstitution(iO);
        return institutionObj ? { value: iO, label: institutionObj.name } : { value: iO, label: 'unknown' }
      });
    } else {
      this.filteredInstitutions = this.institutions;
    }
  }

  setAddModalStep(step?: number) {
    this.addContactModalStep = step;

    if (step === 2) {
      this.updateRoleData();
    }

    if (!this.addContactModalStep) {
      this.form.reset();
      this.roleData = null;
    }
  }

  openAddSystemRole(roleId: number) {
    this.form.get('role').setValue(roleId);
    this.updateRoleData();
    this.addContactModalStep = 2;
  }

  assignRoles(roleScopes: Array<Model.UserRoleScope>) {
    const systemRoles = [];

    roleScopes.forEach(role => {
      const contactInfo = this.contactTypes.find(c => c.roleId === role.role_id);

      if (contactInfo.isSystemRole) {
        // Assign system role
        if (role.institution_id) {
          this.store.dispatch(Actions.Institution.addRoleScopes([{ ...role, fund_id: this.parentProgram.id }], contactInfo.notify_on_assignment))
        } else {
          systemRoles.push(this.apiService.addUserRoleScope({ ...role, fund_id: this.parentProgram.id }, contactInfo.notify_on_assignment));
        }
      } else {
        // Add contact on proposal
        this.store.dispatch(Actions.Contacts.add({ ...role, proposal_id: this.proposal.id }, contactInfo.notify_on_assignment));
      }
    });

    if (!!systemRoles.length) {
      forkJoin(systemRoles).subscribe(() => {
        // Refetch system role users
        this.setupSystemRoles();
      });
    }

    this.setAddModalStep();
  }

  get showModalStep1() {
    return this.addContactModalStep === 1;
  }

  get showModalStep2() {
    return this.addContactModalStep === 2;
  }

  getUserFullName(user: Model.User) {
    return Profile.contactFullName(user);
  }

  contactRoleName(contact: Model.UserRoleScope) {
    const role = this.roleOptions.find(option => option.value === contact.role_id);
    return role ? role.label : this.lookupService.getRoleNameForFund(contact.role_id, this.program.parent_key) || 'Unknown Role';
  }

  contactInstitutionName(contact: Model.UserRoleScope) {
    if (contact.institution) {
      return contact.institution.name;
    } else if (contact.institution_id) {
      const knownInst = this.institutions.find(inst => inst.value === contact.institution_id);
      return knownInst ? knownInst.label : this.lookupService.getInstitutionName(contact.institution_id);
    } else {
      return 'n/a';
    }
  }

  contactEmail(contact: Model.UserRoleScope) {
    return Profile.contactEmail(contact.user);
  }

  contactPhone(contact: Model.UserRoleScope) {
    const user = contact.user;
    if (user && user.phone) {
      return this.phoneTransform.transform(user.phone) + user.phone_extension
        ? this.phoneTransform.transform(user.phone) + ' ' + this.phoneExtTransform.transform(user.phone_extension) : '';
    }
  }

  removeContactAlert(contact?: Model.UserRoleScope) {
    this.contactToRemove = contact;
  }

  removeContact() {
    if (this.contactToRemove) {
      this.store.dispatch(Actions.Contacts.delete(this.contactToRemove));
    }
    this.removeContactAlert()
  }

  removeSystemRoleAlert(user: Model.User, roleType: Model.ContactTableType) {
    this.systemRoleToRemove = { user, roleType };
  }

  removeSystemRole() {
    const { roleType, user } = this.systemRoleToRemove;
    const scope = { fund_id: this.parentProgram.id, role_id: roleType.roleId, user_id: user.id };
    const roleData = this.lookupService.getRole(roleType.roleId);

    if (roleData.institution) {
      scope['institution_id'] = this.proposal.lead_institution.id;
    }
    if (roleData.sector) {
      scope['sector_id'] = this.proposal.sector_id;
    }

    this.systemRoleToRemove = null;
    this.apiService.removeUserRoleScope(scope).subscribe(() => {
      if (roleData.institution) {
        this.store.dispatch(Actions.Institution.get(this.proposal.lead_institution.id));
      }

      this.setupSystemRoles();
    });
  }

  get contactRoleIds() {
    return this.contactTypes.map(type => type.roleId);
  }

  updateValidity() {
    this.isValid.emit(this.requiredContacts.every(contactType => this.contactRequirementComplete(contactType)));
  }

  getContactRequirementString(contactType: Model.ContactTableType) {
    const numberRequired = contactType.numberRequired === 1 ? '1 contact' : `${contactType.numberRequired} contacts`;
    return `(minimum ${numberRequired} required)`;
  }

  contactRequirementComplete(contactType: Model.ContactTableType & {institutionId?: number}) {
    let contactsOfType = this.contacts.filter(contact => contact.role_id === contactType.roleId);

    // if contact type has institutionId set, do a secondary filter on institution id
    if (contactType.institutionId) {
      contactsOfType = contactsOfType.filter(contact => contact.institution_id === contactType.institutionId);
    }
    return contactsOfType.length >= contactType.numberRequired;
  }

  canDeleteContact(contact: Model.UserRoleScope) {
    const contactType = this.contactTypes.find(cT => cT.roleId === contact.role_id);
    if (!contactType) {
      // Can delete if contact type is no longer valid
      return true;
    } else if (!contactType.minToDelete) {
      return this.canEdit;
    } else {
      const roleCount = this.contacts.filter(c => c.role_id === contact.role_id).length;
      return this.canEdit && roleCount > contactType.numberRequired;
    }
  }

  getContactSortOrder(roleId: number) {
    const contactType =  this.contactTypes.find(type => type.roleId === roleId);
    return contactType ? contactType.sortOrder : undefined;
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }
}
