import { IFundState, State } from '@app-ngrx-domains';
import { ActionWithPayload, AttributeReducer } from '@app-libs';
import { FUND_ACTION_PREFIX, FUND_ACTION_TYPES } from './fund.action';
import { uniqBy } from 'lodash';

export function FundReducer(state: IFundState = State.Fund, action: ActionWithPayload<any>): IFundState {
  switch (action.type) {
    ///////////////////////////////////////////////////////////////////////
    // Attribute Reducers
    ///////////////////////////////////////////////////////////////////////
    case FUND_ACTION_TYPES.UPSERT_ATTRIBUTE_SUCCESS:
    case FUND_ACTION_TYPES.UPSERT_ATTRIBUTES_SUCCESS:
    case FUND_ACTION_TYPES.DELETE_ATTRIBUTE_SUCCESS:
    case FUND_ACTION_TYPES.DELETE_ATTRIBUTES_SUCCESS:
    case FUND_ACTION_TYPES.CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS:
    case FUND_ACTION_TYPES.CREATE_EFFORT_AREA_SUCCESS:
    case FUND_ACTION_TYPES.CREATE_MULTI_EFFORT_AREAS_SUCCESS:
    case FUND_ACTION_TYPES.UPDATE_EFFORT_AREA_SUCCESS:
    case FUND_ACTION_TYPES.DELETE_EFFORT_AREA_SUCCESS:
    case FUND_ACTION_TYPES.DELETE_ITEM_SUCCESS:
    case FUND_ACTION_TYPES.DELETE_MULTI_EFFORT_AREAS_SUCCESS:
    {
      const actionType = action.type.replace(FUND_ACTION_PREFIX, '');
      return AttributeReducer<IFundState>(actionType, state, action);
    }

    ///////////////////////////////////////////////////////////////////////
    // Local Reducers
    ///////////////////////////////////////////////////////////////////////
    case FUND_ACTION_TYPES.GET: {
      return {
        ...State.Fund,
        is_loading: true,
      };
    }

    case FUND_ACTION_TYPES.GET_SUCCESS: {
      return action.payload;
    }

    case FUND_ACTION_TYPES.GET_FAIL: {
      return {
        ...State.Fund,
        is_loading: false,
        error: action.payload
      };
    }

    case FUND_ACTION_TYPES.APPEND_FILE: {
      return {
        ...state,
        files: [...state.files, action.payload],
      };
    }

    case FUND_ACTION_TYPES.DELETE_FILE_SUCCESS: {
      const newState = state.files.filter(file => file.id !== action.payload);
      return {
        ...state,
        files: newState
      };
    }

    case FUND_ACTION_TYPES.REFRESH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };

    case FUND_ACTION_TYPES.TASK_UPDATE_SUCCESS: {
      let reducedTasks = [ ...state.data.tasks, action.payload];

      reducedTasks = uniqBy(reducedTasks.reverse(), task => [task.task_type, task.proposal_id,
        task.institution_id, task.duration_id].join());

      return {
        ...state,
        data: {
          ...state.data,
          tasks: reducedTasks
        }
      }
    }

    ///////////////////////////////////////////////////////////////////////
    // no-op
    ///////////////////////////////////////////////////////////////////////
    default:
      return state;
  }
};
