<ng-container *ngIf="initialized" [ngSwitch]="!isPreview">
  <!-- Edit -->
  <ng-container *ngSwitchCase="true">
    <ng-container *ngTemplateOutlet="activitiesSummary"></ng-container>
    <h2 class="header-title h2 mb-l" id="qa_tab_title">{{title}}</h2>

    <!-- guidance -->
    <po-guidance-text [workflowFilter]="guidance" fieldName="guidance"></po-guidance-text>

    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>

  <!-- Preview -->
  <ng-container *ngSwitchCase="false">
    <div class="card">
      <div class="card-title">
        <h3>{{title}}</h3>
      </div>
      <ng-container *ngTemplateOutlet="activitiesSummary"></ng-container>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</ng-container>

<!-- Content -->
<ng-template #content>
  <ng-container *ngIf="initialized && activityEAs?.length; else empty;">
    <activities-card *ngFor="let activity of activityEAs; let first = first; let count = index; trackBy: trackById"
      [activityEA]="activity"
      [canEdit]="canEdit"
      [count]="count"
      [canDelete]="canDelete"
      [preventDeleteReason]="shouldPreventDelete(activity.id)"
      [firstTouch]="firstTouch"
      [isFirst]="first"
      [isPrimary]="false"
      [isPreview]="isPreview"
      [objectives]="objectives"
      [threeYearPlan]="threeYearPlan"
      [parentEffortAreas]="parentEffortAreas">
    </activities-card>

    <div *ngIf="canEdit" class="btn-section btn-section--center">
      <button type="button" (click)="addActivity(true)" class="action-button action-button--primary icon--left" [disabled]="addDisabled">
        <icon iconId="plus"></icon>
        Add Activity
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #empty>
  <div [ngClass]="{'card': !isPreview }">
    <p class="empty-state">No activities have been added.</p>
  </div>
</ng-template>

<ng-template #activitiesSummary>
  <app-budget-summary *ngIf="!!summaryEntries.length"
    summaryTitle="Number of Activities per Objective"
    [summaryEntries]="summaryEntries"
    [isSticky]="!isPreview"
    [bordered]="isPreview"
    [spaced]="isPreview"
    [canEdit]="canEdit">
  </app-budget-summary>
</ng-template>
