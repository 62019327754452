<div [ngClass]="{'card': showCard}">
  <div [ngClass]="{'card-title': showCard}">
    <ng-container *ngTemplateOutlet="cardHeading"></ng-container>
    <div *ngIf="!!helpText" class="help-text">
      <p>* {{helpText}}</p>
    </div>
  </div>

  <div [ngClass]="{'card-body': showCard}">
    <div class="table-overflow">
      <div class="table-overflow__inside--60-rem" id="qa_targets_actuals_table">
        <form [formGroup]="percentsForm" autocomplete="off" [ngClass]="{'first-touch': firstTouch}">
          <table class="po-table po-table--fixed po-table--sticky-header datatable">
            <thead>
              <tr>
                <th scope="col" *ngFor="let column of columns; trackBy: trackByColumn"
                    [class]="column.headerCssClass ? column.headerCssClass : ''">
                  <ng-container [ngTemplateOutlet]="tableHeaderTmpl"
                                [ngTemplateOutletContext]="{ column: column }">
                  </ng-container>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container [ngSwitch]="!!rows.length">
                <tr *ngSwitchCase="false">
                  <td class="empty-state" scope="col" [attr.colspan]="columns.length">
                    No metrics have been selected.
                  </td>
                </tr>
                <ng-container *ngSwitchCase="true">
                  <tr *ngFor="let row of rows; let rowIndex = index; trackBy: trackById">
                    <th scope="row">{{ row.metricSet }}</th>
                    <td>{{ getIndicator(row.metric.id) }}{{ row.metric.name }}</td>
                    <ng-container *ngFor="let cell of row.values; let cellIndex = index; trackBy: trackById">
                      <td [class]="row.cellCssClass ? row.cellCssClass : ''">
                        <ng-container [ngTemplateOutlet]="cell.isTarget ? inputTmpl : valueTmpl"
                                      [ngTemplateOutletContext]="{ cell: cell, row: row }">
                        </ng-container>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #cardHeading>
  <ng-container [ngSwitch]="headingLevel">
    <h3 *ngSwitchCase="'h3'">{{title}}</h3>
    <h4 *ngSwitchCase="'h4'">{{title}}</h4>
  </ng-container>
</ng-template>

<ng-template #tableHeaderTmpl let-column="column">
  {{ column.name }}
</ng-template>

<ng-template #inputTmpl let-cell="cell" let-row="row">
  <ng-container [formGroup]="percentsForm">
    <input-field labelText="Percentage for {{row.metric.name}}" [labelHidden]="true" [canEdit]="canEdit" class="align-data--right">
      <input [formControlName]="cell.id"
        type="text"
        [id]="cell.id"
        [placeholder]="units[row.units].symbol"
        [transform]="units[row.units].transform"
        [pipeArgs]="units[row.units].pipeArgs"
        [decimalPlaces]="units[row.units].decimalPlaces"
        (change)="persistTarget(cell.id, row.metric.id, cell)"
        inputRef />
    </input-field>
  </ng-container>
</ng-template>

<ng-template #valueTmpl let-cell="cell">
  {{ cell.value }}
</ng-template>

