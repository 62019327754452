<div *ngIf="budgetForm" class="card" id="qa_budget_card{{count}}">

  <div class="card-title">
    <div class=" columns columns--2">
      <h3 class="h3">{{getBudgetItemTitle()}}{{fundYearName ? ' ' + fundYearName : ''}}</h3>
    </div>
    <p>Budget items may contain clusters of related expenditures. This should be a high level summary of the budget that indicates how funds will be utilized to accomplish the plan objectives.</p>
  </div>

  <form [formGroup]="budgetForm" autocomplete="off" [ngClass]="{'first-touch': firstTouch}">
    <!-- AEBG/SWP budget style -->
    <div class="card-section-wrapper" *ngIf="isSwpAebgBudgetItem()">
      <div class="card-section">

        <input-field labelText="Budget Item Title" [showCounter]="true">
          <input type="text"
                 inputRef
                 id="qa_budgetitem_title_text{{count}}"
                 [maxlength]="100"
                 formControlName="title"
                 (blur)="updateBudgetItem()" aria-required="true" required>
        </input-field>

        <po-select *ngIf="budget.isSWPRegionalShare()"
          [options]="institutions"
          [id]="'qa_budgetitem_institution_dropdown' + count"
          [selectMultiple]="false"
          [selectOnce]="true"
          labelText="Institution"
          (optionSelected)="updateBudgetItem()"
          (optionRemoved)="updateBudgetItem()">
          <select inputRef class="po-select__select"  formControlName="institution_id" aria-required="true">
          </select>
        </po-select>

        <div class="mb-l" *ngIf="!budget.isSWPRegionalShare()">
          <dl class="dl-horizontal">
            <dt>{{institutionLabel}}</dt>
            <dd><strong>{{ leadInstitutionName }}</strong></dd>
          </dl>
        </div>

        <div class="error-text" *ngIf="shouldShowErrorAlert()" level="error">
          <p>Please specify a lead institution on the Description tab.</p>
        </div>

        <input-field labelText="Brief Description of Expenditure" [showCounter]="true">
          <textarea #description
                    inputRef
                    formControlName="description"
                    (blur)="updateBudgetItem()"
                    id="qa_budgetitem_description_textarea{{count}}"
                    [maxlength]="descrLengthLimit" [attr.aria-required]="!budget.isAEBG()">
          </textarea>
        </input-field>
      </div>

      <div class="card-section">
        <po-select
              [options]="fundObjectCodes"
              id="qa_budgetitem_objcode_dropdown{{count}}"
              labelText="Expenditure Type"
              [selectOnce]="true"
              (optionSelected)="updateBudgetItem()">
          <select inputRef
                  class="po-select__select"
                  formControlName="object_code_id" aria-required="true">
          </select>
        </po-select>

        <input-field labelText="{{fundYearName}} Amount">
          <input type="text"
                 inputRef
                 id="qa_budgetitem_amount_number{{count}}"
                 integerCurrency
                 placeholder="$"
                 formControlName="amount"
                 (blur)="updateBudgetItem()"
                 aria-required="true"/>
        </input-field>

        <div *ngIf="budget.isAEBG()">
          <!--
          <input type="checkbox"
                 id="qa_budgetitem_aebg_consortium_expense_number{{count}}"
                 [disabled]="budgetForm.get('object_code_id').value === OBJECT_CODES_INDIRECT_COSTS"
                 [checked]="budget.isConsortiumExpense"
                 (change)="updateConsortiumExpense()"/>
          <label for="qa_budgetitem_aebg_consortium_expense_number{{count}}">Consortium Fiscal/Admin Expense</label>
          -->
          <radio-check labelText="Consortium Fiscal/Admin Expense">
            <input id="qa_budgetitem_aebg_consortium_expense_number{{count}}" class="checkbox" type="checkbox"
                inputRef
                formControlName="consortium_expense"
                [checked]="budget.isConsortiumExpense"
                (change)="updateConsortiumExpense()"/>
          </radio-check>
        </div>

        <app-budget-forecast
          *ngIf="isAebgV1BudgetItem || budget.isSWP()"
          [canEdit]="canEdit"
          [firstTouch]="firstTouch"
          [durationString]="fundYearName"
          [helpText]="forecastHelpText"
          [count]="count"
          [totalAmount]="getBudgetTotal()"
          [quarter_1]="budget.getQuarter(1)"
          [quarter_2]="budget.getQuarter(2)"
          [quarter_3]="budget.getQuarter(3)"
          [quarter_4]="budget.getQuarter(4)"
          [fixedQ4]="!budget.isAEBG()"
          (updateQuarter)="updateForecast($event)">
        </app-budget-forecast>
      </div>
    </div>

    <!-- iPlan budget style -->
    <div class="card-section-wrapper" *ngIf="budget.isIPlan()">
      <div class="card-section">
        <po-select
          [options]="fundObjectCodes"
          [selectMultiple]="false"
          [selectOnce]="true"
          labelText="Expenditure Type"
          id="qa_budgetitem_objcode_dropdown{{count}}"
          (optionSelected)="updateBudgetItem()">
          <select inputRef class="po-select__select" formControlName="object_code_id" aria-required="true"></select>
        </po-select>
      </div>

      <div class="card-section">
        <po-select
          [options]="budgetTypes"
          [selectMultiple]="false"
          [selectOnce]="true"
          labelText="Fund Type"
          id="qa_budgetitem_fund_dropdown{{count}}"
          (optionSelected)="updateBudgetItem()">
          <select inputRef class="po-select__select" formControlName="fund_id" aria-required="true"></select>
        </po-select>

        <input-field labelText="Amount">
          <input inputRef
                 type="text"
                 id="qa_budgetitem_amount_number{{count}}"
                 integerCurrency
                 placeholder="$"
                 formControlName="amount"
                 (blur)="updateBudgetItem()" aria-required="true">
        </input-field>
      </div>
    </div>

    <!-- GP budget style -->
    <div *ngIf="budget.isGP()">
      <div class="card-section-wrapper" >

        <div class="card-section">
          <po-select
                [options]="fundObjectCodes"
                labelText="Expenditure Type"
                [selectMultiple]="false"
                [selectOnce]="true"
                id="qa_budgetitem_objcode_dropdown{{count}}"
                (optionSelected)="updateBudgetItem()">
            <select inputRef class="po-select__select" formControlName="object_code_id" aria-required="true"></select>
          </po-select>
        </div>

        <div class="card-section">
          <input-field labelText="Anticipated Amount">
            <input inputRef
                   type="text"
                   integerCurrency
                   placeholder="$"
                   id="qa_budgetitem_amount_number{{count}}"
                   formControlName="amount"
                   (blur)="updateBudgetItem()" aria-required="true">
          </input-field>
        </div>

        <div class="card-section">
          <div class="c-field ">
            <p for="" class="c-field__label"><strong>% of Allocation</strong></p>
            <div>{{+getPercentOfAllocation() | number: '1.0-2'}}%</div>
          </div>
        </div>
      </div>

      <div class="card-section-wrapper">
        <div class="card-section">
          <input-field labelText="Brief Description of Expenditure" [showCounter]="true">
            <textarea #description
                      inputRef
                      formControlName="description"
                      (blur)="updateBudgetItem()"
                      id="qa_budgetitem_description_textarea{{count}}"
                      maxlength="{{descrLengthLimit}}" aria-required="true">
            </textarea>
          </input-field>
        </div>
      </div>
    </div>

    <!-- Delete/dupe buttons -->
    <div class="card-footer card-footer--right">
      <div class="inline-button-group__container inline-button-group__container--right">
       <button class="inline-button icon--left action-button action-button--secondary action-button--small js-delete" type="button"
         [attr.id]="'qa_budgetitem_delete_icon'+count"
         (click)="deleteBudgetItem()">
         <icon iconId="delete"></icon>
         Delete
       </button>
       <button class="inline-button icon--left action-button action-button--secondary action-button--small js-dupe" type="button"
         [attr.id]="'qa_budgetitem_dupe'+count"
         (click)="dupeBudgetItem()">
         <icon iconId="plus"></icon>
         Duplicate
       </button>
     </div>
    </div>
  </form>
</div>
