import { concat, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { State, Queries, Actions as DomainActions } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { FUND_TYPES } from '@app-consts';
import { CFAD_AMENDMENT_ACTION_TYPES } from './cfad-amendment.action';

/**
 * Container class for allocation effects calls.
 */
@Injectable()
export class CfadAmendmentEffects {

  /**
   * Creates an instance of effects class.
   * @param {Actions} actions$
   * @param {ApiService} service
   * @param {Store} store
   */
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>,
  ) {
  }

   updateAmendmentChanges$ = createEffect(() => this.actions$.pipe(
    ofType(CFAD_AMENDMENT_ACTION_TYPES.UPDATE_AMENDMENT_CHANGES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CfadAmendment.getCurrentAmendment)),
    mergeMap(([changes, cfad_amendment]) => {
      const task_id = cfad_amendment.client_state.task_id;
      const duration_id = cfad_amendment.duration_id;

      const client_state = {
        ...cfad_amendment.client_state,
        changes
      };
      delete client_state.task_id;

      return this.apiService.updateTask(task_id, client_state).pipe(
        mergeMap(res => of(DomainActions.CfadAmendment.updateTaskSuccess(duration_id, res)))
      );
    }),
    catchError(err => of(DomainActions.CfadAmendment.serviceFail(err)))
  ));

   updateAllocations$ = createEffect(() => this.actions$.pipe(
    ofType(CFAD_AMENDMENT_ACTION_TYPES.UPDATE_ALLOCATIONS),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CfadAmendment.getCurrentAmendment)),
    mergeMap(([{allocations, markWorkflowStep}, cfad_amendment]) => {
      const taskId = cfad_amendment.client_state.task_id;
      const yearDurationId = cfad_amendment.duration_id;
      // update amendments
      let client_state: any = {
        ...cfad_amendment.client_state,
        changes: {
          ...cfad_amendment.client_state.changes,
          allocations
        }
      };
      delete client_state.task_id;
      // mark workflow step dirty if needed.
      if (markWorkflowStep) {
        client_state = {
          ...client_state,
          ['state']: {
            ...client_state['state'],
            ...markWorkflowStep,
          }
        };
      }

      return this.apiService.updateTask(taskId, client_state).pipe(mergeMap(
        res => {
          return of(DomainActions.CfadAmendment.updateTaskSuccess(yearDurationId, res));
        }
      ));
    }),
    catchError((error) => of(DomainActions.CfadAmendment.serviceFail(error)))
  ));

   updateState$ = createEffect(() => this.actions$.pipe(
    ofType(CFAD_AMENDMENT_ACTION_TYPES.UPDATE_STATE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CfadAmendment.getCurrentAmendment)),
    mergeMap(([changes, cfadAmendment]) => {
      const taskId = cfadAmendment.client_state.task_id;
      const yearDurationId = cfadAmendment.duration_id;
      const client_state = {
        ...cfadAmendment.client_state,
        ['state']: {
          ...cfadAmendment.client_state['state'],
          ...changes,
        }
      };
      delete client_state.task_id;

      return this.apiService.updateTask(taskId, client_state).pipe(mergeMap(
        res => {
          return of(DomainActions.CfadAmendment.updateTaskSuccess(yearDurationId, res));
        }
      ));
    }),
    catchError((error) => of(DomainActions.CfadAmendment.serviceFail(error)))
  ));

   refresh$ = createEffect(() => this.actions$.pipe(
    ofType(CFAD_AMENDMENT_ACTION_TYPES.REFRESH),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CfadAmendment.getCurrentAmendment)),
    mergeMap(([req, cfad_amendment]) => {
      return forkJoin(
        this.apiService.getCfad(cfad_amendment.cfad_id),
        this.apiService.getAllocations({
          direction: 'from',
          institution_id: cfad_amendment.institution_id,
          fund_id: FUND_TYPES.AEBG,
          duration_id: cfad_amendment.duration_id
        })).pipe(
          mergeMap(data => {
            const [cfad, memberAllocations] = data;

            // bundle actions.
            const actions: Action[] = [];
            // refresh cfad.
            actions.push(DomainActions.CfadAmendment.refreshSuccess(cfad));
            actions.push(DomainActions.Layout.showBusySpinner(false));
            if (req.gotoUrl) {
              // goto requested route.
              actions.push(DomainActions.App.go([req.gotoUrl]));
            }
            // Dispatch actions. These are executed in order.
            return concat([
              ...actions
            ]);
          }
        ));
    }),
    catchError((error) => {
      // stop the spinner.. encountered an error.
      this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
      return of(DomainActions.CfadAmendment.serviceFail(error));
    })
  ));

   refreshReset$ = createEffect(() => this.actions$.pipe(
    ofType(CFAD_AMENDMENT_ACTION_TYPES.REFRESH_RESET),
    map(toPayload),
    mergeMap(req => {
      return this.apiService.getCfad(req.cfad_id).pipe(
        mergeMap(res => {
          // bundle actions.
          const actions: Action[] = [];
          // refresh cfad.
          actions.push(DomainActions.CfadAmendment.refreshSuccess(res));
          actions.push(DomainActions.Layout.showBusySpinner(false));
          // goto requested route.
          actions.push(DomainActions.App.go([req.gotoUrl]));

          // Dispatch actions. These are executed in order.
          return concat([
            ...actions
          ]);
        }
      ));
    }),
    catchError((error) => {
      // stop the spinner.. encountered an error.
      this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
      return of(DomainActions.CfadAmendment.serviceFail(error));
    })
  ));
}
