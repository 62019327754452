import { ActionWithPayload } from './ngrx-action';
import { ReducerUtils } from './attribute-reducer.utils';

export function AttributeReducer<T>(action_type: string, state: any, action: ActionWithPayload<any>): T {
  let reducerFunction;

  switch (action_type) {

    case 'UPSERT_ATTRIBUTE_SUCCESS': {
      const { key, value, ea, parentEffortAreas } = action.payload;
      reducerFunction = (root) => upsertAttributeReducer(root, key, value, ea, parentEffortAreas);
      break;
    };

    case 'UPSERT_ATTRIBUTES_SUCCESS': {
      reducerFunction = (root) => {
        return action.payload.updates.reduce((result, update) => {
          const { deleted, key, value, ea, parentEffortAreas } = update;
          if (deleted) {
            result = deleteAttributeReducer(result, key, value, ea, parentEffortAreas);
          } else {
            result = upsertAttributeReducer(result, key, value, ea, parentEffortAreas);
          }
          return result;
        }, root);
      }
      break;
    };

    case 'DELETE_ATTRIBUTE_SUCCESS': {
      const { key, value, ea, parentEffortAreas } = action.payload;
      reducerFunction = (root) => deleteAttributeReducer(root, key, value, ea, parentEffortAreas);
      break;
    }

    case 'DELETE_ATTRIBUTES_SUCCESS': {
      reducerFunction = (root) => {
        return action.payload.deletes.reduce((result, attrib) => {
          const { key, value, ea, parentEffortAreas } = attrib;
          return deleteAttributeReducer(result, key, value, ea, parentEffortAreas);
        }, root);
      }
      break;
    }

    case 'CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS': {
      reducerFunction = (root) => {
        const effortArea = { ...action.payload.ea, ...action.payload.newEa, newId: action.payload.newEa.id, id: action.payload.ea.id };
        const parentEAs = ReducerUtils.getFreshParents(root, action.payload.parentEffortAreas);
        return ReducerUtils.storeEffortAreas(root, [effortArea], parentEAs);
      }
      break;
    }

    case 'CREATE_EFFORT_AREA_SUCCESS': {
      reducerFunction = (root) => {
        const parentEAs = ReducerUtils.getFreshParents(root, action.payload.parentEffortAreas);
        return ReducerUtils.storeEffortAreas(root, [action.payload.ea], parentEAs);
      }
      break;
    }

    case 'CREATE_MULTI_EFFORT_AREAS_SUCCESS': {
      reducerFunction = (root) => {
        const parentEAs = ReducerUtils.getFreshParents(root, action.payload.parentEffortAreas);
        return ReducerUtils.storeEffortAreas(root, action.payload.eas, parentEAs);
      };
      break;
    }

    case 'UPDATE_EFFORT_AREA_SUCCESS': {
      reducerFunction = (root) => {
        const ea = { ...action.payload.ea, newId: action.payload.newId };
        const parentEAs = ReducerUtils.getFreshParents(root, action.payload.parentEffortAreas);
        return ReducerUtils.storeEffortAreas(root, [ea], parentEAs);
      }
      break;
    }

    case 'DELETE_EFFORT_AREA_SUCCESS': {
      reducerFunction = (root) => {
        const parentEAs = ReducerUtils.getFreshParents(root, action.payload.parentEffortAreas);
        return ReducerUtils.removeEffortAreas(root, [action.payload.ea], parentEAs);
      }
      break;
    }

    case 'DELETE_MULTI_EFFORT_AREAS_SUCCESS': {
      reducerFunction = (root) => {
        const stockParentEAs = ReducerUtils.getFreshParents(root, action.payload.parentEffortAreas);

        if (action.payload.effortAreaType) {
          // If effortAreaType is included, then they share parent EAs so we need to remove the EAs all at once
          return ReducerUtils.removeEffortAreas(root, action.payload.eas, stockParentEAs, action.payload.effortAreaType);
        }
        return action.payload.eas.reduce((result, ea) => {
          const parentEAs = ea.parentEffortAreas ? ReducerUtils.getFreshParents(root, ea.parentEffortAreas) : undefined;
          return ReducerUtils.removeEffortAreas(result, [ea], parentEAs || stockParentEAs);
        }, root);
      };
      break;
    }

    case 'APPLY_PENDING_MULTI_EFFORT_AREAS_SUCCESS': {
      reducerFunction = (root) => {
        const parentEAs = ReducerUtils.getFreshParents(root, action.payload.parentEffortAreas);
        return ReducerUtils.applyPendingEffortAreas(root, action.payload.effortAreaType, action.payload.eas, parentEAs);
      };
      break;
    }
  } // switch(type)

  if (reducerFunction) {
    // Perform Reducer Function
    const multiId = action.payload.multi_data_id;
    if (multiId) {
      const newItems = state.items.map(item => {
        return item.id === multiId ? reducerFunction(item) : item;
      });

      return {
        ...state,
        items: newItems
      };
    } else {
      let key = 'data';
      if (state.institution) {
        key = 'institution';  // Institution stores its data under "institution" instead of "data" unfortunately
      }
      const newData = reducerFunction(state[key]);
      return {
        ...state,
        [key]: newData || state[key] // safeguard against a broken reducer function
      };
    }
  } else {
    return state;
  }
}

function upsertAttributeReducer(root, key, value, ea, parentEffortAreas) {
  const parentEAs = ReducerUtils.getFreshParents(root, parentEffortAreas);
  let returnObj;

  if (ea) {
    // get fresh copy of EA to ensure _updated field is present if applicable
    const freshEA = ReducerUtils.findEffortArea(root, ea, parentEAs);

    returnObj = {
      ...freshEA,
      [key]: ReducerUtils.upsertAttribute(freshEA[key], value)
    };
  } else {
    returnObj = {
      ...root,
      [key]: ReducerUtils.upsertAttribute(root[key], value)
    };
  }

  returnObj = ReducerUtils.upsertUpdatedAttribute(returnObj, key, value);

  return ea ? ReducerUtils.storeEffortAreas(root, [returnObj], parentEAs) : returnObj;
}

function deleteAttributeReducer(root, key, value, ea, parentEffortAreas) {
  const parentEAs = ReducerUtils.getFreshParents(root, parentEffortAreas);

  if (ea) {
    // get fresh copy of EA to ensure _updated field is present if applicable
    const freshEA = ReducerUtils.findEffortArea(root, ea, parentEAs);

    const effortArea = {
      ...freshEA,
      [key]: ReducerUtils.removeAttribute(freshEA[key], value)
    }

    return ReducerUtils.storeEffortAreas(root, [effortArea], parentEAs);
  } else {
    return {
      ...root,
      [key]: ReducerUtils.removeAttribute(root[key], value)
    }
  }
}
