<ng-container *ngIf="contacts?.length; else emptyContacts">
  <div class="contacts" [class.contacts--wide]="wide">
    <div *ngFor="let contact of contacts; let i = index" class="contacts__contact">
      <h4 class="contacts__contact__heading">{{getRoleName(contact)}}</h4>
      <p *ngIf="contact.user">
        <strong>
          <app-user-actions
              [count]="i"
              [menuText]="getUserFullName(contact.user)"
              [userData]="contact.user"
              class="contacts__contact__options">
            </app-user-actions>
        </strong><br>
        <ng-container *ngIf="contact.user.title">{{contact.user.title}}<br></ng-container>
        <ng-container *ngIf="contact.user.email_address">
          <a href="mailto:{{contact.user.email_address}}" class="text-link" id="qa_contact_email{{i}}">
            {{contact.user.email_address}}</a><br>
        </ng-container>
        <ng-container *ngIf="contact.user.phone">
          {{ contact.user.phone | phone }} {{ contact.user.phone_extension | phone_extension }}
        </ng-container>
      </p>
    </div>
  </div>
</ng-container>

<ng-template #emptyContacts>
  <p *ngIf="contacts?.length === 0" class="empty-state">No contacts have been assigned.</p>
</ng-template>