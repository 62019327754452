import { State } from '@app-ngrx-domains';

State.Fund = {
  data: {
    id: null,
    name: '',
    key: '',
  },
  files: [],
  is_loading: true,
  error: null,
};

declare module '@app-ngrx-domains' {
  export interface IFundState {
    data: Model.Fund;
    files: Array<Model.Document>;
    is_loading: boolean;
    error: any;
  }

  interface State {
    Fund: IFundState
  }
}
