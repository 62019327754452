<form [formGroup]="form" autocomplete="off"  [ngClass]="{'first-touch': firstTouch}">
  <input-group [headingText]="headingText" [control]="form" [required]="isRequired && !isPreview" [errorsOnUntouched]="!firstTouch">
    <div *ngIf="helpText && canEdit" class="help-text">
      <p>{{ helpText }}</p>
    </div>

    <div class="table-overflow">
      <div class="table-overflow__inside--30-rem">
        <table class="po-table po-table--fixed">
          <thead>
            <tr class="align-headings--center">
              <th></th>
              <th scope="col">Q1</th>
              <th scope="col">Q2</th>
              <th scope="col">Q3</th>
              <th scope="col">Q4</th>
            </tr>
          </thead>
          <tbody>
            <tr class="align-data--right">
              <th scope="row">Percentage</th>
              <td *ngFor="let quarter of quarters; let i = index;">
                <input-field labelText="Quarter {{i + 1}} forecast" [labelHidden]="true" [canEdit]="edittableQuarter(quarter)" class="align-right">
                  <input inputRef id="qa_budget_quarter{{i + 1}}_input{{count}}"
                    placeholder="-"
                    [formControlName]="quarter"
                    type="text" min="0" max="100"
                    transform="percent"
                    (change)="emitUpdate(quarter)"
                    [attr.aria-required]="isRequired"
                    [attr.aria-invalid]="isControlInvalid(quarter)"/>
                </input-field>
              </td>
            </tr>

            <tr class="align-data--right">
              <th scope="row">Dollars</th>
              <td *ngFor="let quarter of quarters">
                {{+getDollarAmount(quarter) | currency:'USD':'symbol':'1.0-0'}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </input-group>
</form>