import { Model } from '@app-ngrx-domains';
import { WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';

// #####################  SWP_K12 v2 Application Workflow  ###################
export const WORKFLOW_CONFIG_v2: Model.Workflow = {
  name: WORKFLOW_TYPES.SWP_K12_v2,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.DESCRIPTION,
      title: 'Pathway Identification',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.AGENCIES,
      title: 'Lead & Partner Agencies',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.PARTNERS,
      title: 'Higher Ed. & Collaborative Partners',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.OBJECTIVES,
      title: 'Problem Statement & Project Objectives',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.PATHWAY,
      title: 'Industry Sectors & Pathways',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.WORKPLAN,
      title: 'Work Plan',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.BUDGET,
      title: 'Budget & Match',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.DOCUMENTS,
      title: 'Supporting Documents',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.CONCLUSION,
      title: 'Review Summary & Assessment',
      showStatus: true,
      hide: true,
    },
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      showStatus: true,
      hide: true,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      showStatus: false,
    },
  ]
};

export const TAPPC_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.SWP_K12_TAPPC,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.DETAILS,
      title: 'Details',
      showStatus: true,
      valid: false
    },
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      showStatus: true,
      valid: false
    },
    {
      route: WORKFLOW_STEPS.PARTNERS,
      title: 'Collaborative Partners',
      showStatus: true,
      valid: false
    },
    {
      route: WORKFLOW_STEPS.OBJECTIVES,
      title: 'Work Plan: Objectives',
      showStatus: true,
      valid: false
    },
    {
      route: WORKFLOW_STEPS.ACTIVITIES,
      title: 'Work Plan: Activities & Outcomes',
      showStatus: true,
      valid: false
    },
    {
      route: WORKFLOW_STEPS.BUDGET,
      title: 'Budget',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.FORECAST,
      title: 'Forecast',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.DOCUMENTS,
      title: 'Supporting Documents',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.SUCCESS,
      title: 'Success Story',
      showStatus: true,
      valid: false
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      showStatus: false,
    }
  ]
};

export const APPLICATION_WORKFLOW_CONFIG_TABLE = {
  [WORKFLOW_TYPES.SWP_K12_v2]: WORKFLOW_CONFIG_v2,
  [WORKFLOW_TYPES.SWP_K12_TAPPC]: TAPPC_WORKFLOW_CONFIG
};
