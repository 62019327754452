import { Component, Input, Output, EventEmitter, Optional, ElementRef, AfterViewInit } from '@angular/core';
import { PopupAnchorDirective } from '@app/shared.generic/directives';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements AfterViewInit {
  @Input() level: string; // success | info | warning | error
  // tslint:disable-next-line:no-input-rename
  @Input('button-dismiss') buttonDismiss: string;
  // tslint:disable-next-line:no-input-rename
  @Input('button-action') buttonAction: string;
  // tslint:disable-next-line:no-input-rename
  @Input('return-value') returnValue: any;
  @Input() defaultCancel: boolean;
  @Output() dismiss: EventEmitter<string> = new EventEmitter();
  @Output() action: EventEmitter<string> = new EventEmitter();

  constructor(@Optional() private popupAnchor: PopupAnchorDirective, private element: ElementRef) { }

  // Always append alerts to content-body so that it always renders over everything else
  ngAfterViewInit() {
    if (this.popupAnchor) {
      this.popupAnchor.appendPopup(this.element.nativeElement);
    }
  }

  closeAlert(result: string) {
    this.dismiss.emit(this.returnValue);
  }

  doAction(action: string) {
    this.action.emit(this.returnValue);
  }
}
