<ng-container *ngIf="initialized" [ngSwitch]="!isPreview">
  <!-- Edit -->
  <ng-container *ngSwitchCase="true">
    <h2 class="header-title h2 mb-l" id="qa_tab_title">{{title}}</h2>

    <!-- guidance -->
    <po-guidance-text [workflowFilter]="guidance" fieldName="guidance"></po-guidance-text>

    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>

  <!-- Preview -->
  <ng-container *ngSwitchCase="false">
    <div class="card">
      <div class="card-title">
        <h3>{{title}}</h3>
      </div>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</ng-container>

<!-- Content -->
<ng-template #content>
  <ng-container *ngIf="objectiveEAs && objectiveEAs.length; else empty">
    <objectives-card *ngFor="let objective of objectiveEAs; let first = first; let count = index; trackBy: trackById"
      [canEdit]="canEdit"
      [canDelete]="canDelete"
      [firstTouch]="firstTouch"
      [isFirst]="first"
      [isPrimary]="false"
      [isPreview]="isPreview"
      [descriptionLength]="maxObjectiveLength"
      [count]="count"
      [metricsOptions]="metricsOptions"
      [objectiveEA]="objective"
      [showTitleInPreview]="false">
    </objectives-card>

    <div *ngIf="canEdit && allowAddObjective" class="btn-section btn-section--center">
      <button type="button" (click)="addObjective()" class="action-button action-button--primary icon--left" [disabled]="addDisabled">
        <icon iconId="plus"></icon>
        Add Objective
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #empty>
  <div [ngClass]="{'card': !isPreview }">
    <p class="empty-state">No objectives have been added.</p>
  </div>
</ng-template>