import { Model } from '@app-ngrx-domains';
import { AREA_NAMES, AREAS, PROGRAM_KEYS, STATES } from '../consts';
import { Utilities } from './utilities';

export interface IFund {
  id?: number;
  name: string;
  long_name?: string;
  short_name?: string;
  acronym?: string;
  description?: string;
  duration?: number;
  program_area_fund?: boolean;
  key: string;
  parent_key?: string;
  deleted?: boolean;
  carryover_of?: number;
  program_settings?: Model.EAProgramSettings;
  program_settings_pending?: Model.EAProgramSettings;
  survey_templates?: Array<Model.EASurveyTemplate>;
  states?: { [key: string]: Array<Model.ResourceStateExtended>};
  is_small_program?: boolean;
  tasks?: Array<Model.Task>;
  files?: Array<Model.Document>;
}

export interface IFundingSourceSettings {
  id?: number;
  fund_name?: string;
  fund_id?: number;
  start_year?: number;
  start_quarter?: number;
  quarterly_plan_length?: number;
  fiscal_reporting_cadence?: string;
  include_final_report?: boolean;
  enable_carryover_management?: boolean;
}

export class Fund {

  ///////////////////////////////////////////////////////////////
  // Miscellaneous
  ///////////////////////////////////////////////////////////////
  static blendedGrantSettings(fund: IFund): boolean {
    return [PROGRAM_KEYS.SWPL_v2, PROGRAM_KEYS.SWPR_v2, PROGRAM_KEYS.SWPL_v3, PROGRAM_KEYS.SWPR_v3].includes(fund.key);
  }

  static formatTitleHeader(fund: IFund, type: string): string {
    return fund.name ? fund.name : `New ${type}`;
  }

  static getGrantRouteSegment(fund: IFund): string {
    if (Fund.blendedGrantSettings(fund)) {
      return ''; // no grant management, so no segment
    } else if ([PROGRAM_KEYS.SWP_K12_TAP, PROGRAM_KEYS.SWP_K12_PC].includes(fund.key)) {
      return '/grants/tap-pc';
    } else if (fund.is_small_program && ![PROGRAM_KEYS.SWP_K12, PROGRAM_KEYS.PERKINS].includes(fund.parent_key)) {
      return '/funds';
    } else {
      return `/${[PROGRAM_KEYS.PERKINS_1C, PROGRAM_KEYS.PERKINS_1B, PROGRAM_KEYS.PERKINS_RESERVE, PROGRAM_KEYS.PERKINS_NTCT].includes(fund.key) ? fund.key : 'grants'}`;
    }
  }

  static getGrantTerm(fund: IFund, plural?: boolean): string {
    let grantTerm = 'Grant';
    if (fund.is_small_program && ![PROGRAM_KEYS.PERKINS, PROGRAM_KEYS.SWP_K12].includes(fund.parent_key)) {
      grantTerm = 'Fund';
    }

    if (plural) {
      grantTerm += 's';
    }

    return grantTerm;
  }

  static getShortestName(fund: IFund) {
    return fund ? fund.acronym || fund.short_name || fund.name : 'Unknown Program';
  }

  static getShortName(fund: IFund) {
    return fund ? fund.short_name || fund.name : 'Unknown Program';
  }

  static getHeaderName(fund: IFund) {
    return fund ? fund.long_name || fund.name : 'Unknown Program';
  }

  static getTitle(fund: IFund, includeGrantId?: boolean): string {
    return fund.program_settings.grant_id && !!includeGrantId
      ? `${fund.name} (${this.getGrantTerm(fund)} Id: ${fund.program_settings.grant_id})`
      : fund.name;
  }

  ///////////////////////////////////////////////////////////////
  // Resource State functions
  ///////////////////////////////////////////////////////////////
  static getAreaState(fund: IFund, area_name: string, year?: number): string {
    const areaStates = fund ? fund.states[area_name] : [];
    let singleAreaState: Model.ResourceState;

    // case where state is an array statuses
    if (areaStates && areaStates.length > 0) {
      if (year) {
        singleAreaState = fund.states[area_name].find(s => s.duration_id === year);
      } else {
        singleAreaState = fund.states[area_name][0];
      }
    }

    const state = singleAreaState;
    return state ? state.state_name : null;
  }

  static hasAreaState(fund: IFund, area_name: string): boolean {
    return !!Fund.getAreaState(fund, area_name);
  }

  static getAreaStateByDuration(fund: IFund, duration_id: number, area_name: string): string {
    const areaStates = (fund.states[area_name] || []) as Array<Model.ResourceStateExtended>;
    const state = areaStates.find(r => r.duration_id === duration_id);
    return state ? state.state_name : null;
  }

  static getAreaStateByDurationInstitution(fund: IFund, duration_id: number, institution_id: number, area_name: string): string {
    const areaStates = (fund.states[area_name] || []) as Array<Model.ResourceStateExtended>;
    const state = areaStates.find(r => r.duration_id === duration_id && r.institution_id === institution_id);
    return state ? state.state_name : null;
  }

  ///////////////////////////////////////////////////////////////
  // Program State
  ///////////////////////////////////////////////////////////////
  static programIsActive(fund: IFund): boolean {
    return Fund.getAreaState(fund, AREA_NAMES.PROGRAM) === STATES.ACTIVE.name;
  }

  ///////////////////////////////////////////////////////////////
  // Program Settings State
  ///////////////////////////////////////////////////////////////
  static getProgramSettingsState(fund: IFund): string {
    return Fund.getAreaState(fund, AREAS.PROGRAM_SETTINGS);
  }

  static hasStartedProgramSettings(fund: IFund): boolean {
    return !!Fund.getAreaState(fund, AREAS.PROGRAM_SETTINGS);
  }

  static programSettingsIsDraft(fund: IFund): boolean {
    return Fund.getAreaState(fund, AREAS.PROGRAM_SETTINGS) === STATES.DRAFT.name;
  }

  static programSettingsIsEditing(fund: IFund): boolean {
    return Fund.getAreaState(fund, AREAS.PROGRAM_SETTINGS) === STATES.EDITED.name;
  }

  static programSettingsEditable(fund: IFund): boolean {
    const state = Fund.getAreaState(fund, AREAS.PROGRAM_SETTINGS);
    return [STATES.DRAFT.name, STATES.EDITED.name].includes(state);
  }

  static programSettingsIsApproved(fund: IFund): boolean {
    return Fund.getAreaState(fund, AREAS.PROGRAM_SETTINGS) === STATES.APPROVED.name;
  }

  ///////////////////////////////////////////////////////////////
  // Application Window State
  ///////////////////////////////////////////////////////////////
  static hasStartedApplicationWindow(fund: IFund, duration_id: number): boolean {
    return !!Fund.getAreaStateByDuration(fund, duration_id, AREAS.APPLICATION_WINDOW);
  }

  static applicationWindowIsOpen(fund: IFund, duration_id: number): boolean {
    return Fund.getAreaStateByDuration(fund, duration_id, AREAS.APPLICATION_WINDOW) === STATES.OPEN.name;
  }

  static applicationWindowIsClosed(fund: IFund, duration_id: number): boolean {
    return Fund.getAreaStateByDurationInstitution(fund, duration_id, null, AREAS.APPLICATION_WINDOW) === STATES.CLOSED.name;
  }

  ///////////////////////////////////////////////////////////////
  // Review Window State
  ///////////////////////////////////////////////////////////////
  static hasStartedReviewWindow(fund: IFund, duration_id: number): boolean {
    return !!Fund.getAreaStateByDuration(fund, duration_id, AREAS.REVIEW_WINDOW);
  }

  static reviewWindowIsOpen(fund: IFund, duration_id: number, institution_id?: number): boolean {
    if (institution_id) {
      // look for exact match
      return Fund.getAreaStateByDurationInstitution(fund, duration_id, institution_id, AREAS.REVIEW_WINDOW) === STATES.OPEN.name;
    } else {
      // see if one is open.
      const areaStates = (fund.states[AREAS.REVIEW_WINDOW] || []) as Array<Model.ResourceStateExtended>;
      return areaStates.length && areaStates.some(r => r.duration_id === duration_id && r.state_name === STATES.OPEN.name);
    }
  }

  static reviewWindowIsClosed(fund: IFund, duration_id: number, institution_id?: number): boolean {
    if (institution_id) {
      // look for exact match
      return Fund.getAreaStateByDurationInstitution(fund, duration_id, institution_id, AREAS.REVIEW_WINDOW) === STATES.CLOSED.name;
    } else {
      // all must be closed.
      const areaStates = (fund.states[AREAS.REVIEW_WINDOW] || []) as Array<Model.ResourceStateExtended>;
      return areaStates.length && areaStates.every(r => r.duration_id === duration_id && r.state_name === STATES.CLOSED.name);
    }
  }

  ///////////////////////////////////////////////////////////////
  // Settings By Year State
  ///////////////////////////////////////////////////////////////
  static getSettingsByYearState(fund: IFund, year: number): string {
    return Fund.getAreaState(fund, AREAS.SETTINGS_BY_YEAR, year);
  }

  static hasStartedSettingsByYear(fund: IFund, year: number): boolean {
    return !!Fund.getAreaState(fund, AREAS.SETTINGS_BY_YEAR, year);
  }

  static settingByYearIsDraft(fund: IFund, year: number): boolean {
    return Fund.getAreaState(fund, AREAS.SETTINGS_BY_YEAR, year) === STATES.DRAFT.name;
  }

  static settingByYearIsApproved(fund: IFund, year: number): boolean {
    return Fund.getAreaState(fund, AREAS.SETTINGS_BY_YEAR, year) === STATES.APPROVED.name;
  }

  ///////////////////////////////////////////////////////////////
  // Headcount Certification State
  ///////////////////////////////////////////////////////////////

  static hasStartedHeadcount(fund: IFund): boolean {
    return !!Fund.getAreaState(fund, AREAS.HEADCOUNT)
  }

  static headcountIsCertified(fund: IFund): boolean {
    return Fund.getAreaState(fund, AREAS.HEADCOUNT) === STATES.CERTIFIED.name;
  }

  ///////////////////////////////////////////////////////////////
  // Survey
  ///////////////////////////////////////////////////////////////

  static getSurveyTemplateIdForReportingPeriod(fund: IFund, reportingPeriodName: string) {
    const reportingPeriod = fund.program_settings.reporting_periods.find(rp => rp.title === reportingPeriodName);
    return reportingPeriod && reportingPeriod.survey_template_id;
  }

  static hasSurveyForReportingPeriod(fund: IFund, reportingPeriodName: string): boolean {
    const reportingPeriod = fund.program_settings.reporting_periods.find(rp => rp.title === reportingPeriodName);
    return !!(reportingPeriod && reportingPeriod.survey_template_id)
  }

  static getSurveyForReportingPeriod(fund: IFund, reportingPeriodName: string): Model.EASurveyTemplate {
    const reportingPeriod = fund.program_settings.reporting_periods.find(rp => rp.title === reportingPeriodName);
    if (!reportingPeriod || !reportingPeriod.survey_template_id) {
      return null;
    }

    return fund.survey_templates.find(survey => survey.id === reportingPeriod.survey_template_id);
  }

}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Fund = IFund;
    export type FundingSource = Fund & { amountAvailable?: number };
    export type FundingSourceSettings = IFundingSourceSettings;
  }
}
