
<div [ngSwitch]="!!canEdit" class="date-picker">
  <label *ngIf="canEdit && showLabel()" for="{{inputId}}" [class.required]="required">
    {{labelText}}
  </label>

  <ng-content select=".help-text"></ng-content>

  <ng-container *ngSwitchCase="true">
    <mat-form-field floatLabel="never">
      <input
        matInput
        class="date-picker" [matDatepicker]="picker"
        id="{{inputId}}"
        [placeholder]="placeHolder"
        [formControl]="inputControl"
        [min]="minDate"
        [max]="maxDate"
        (dateChange)="inputChange($event)"
        [required]="required">
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon svgIcon="icon-calendar"> </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker
        #picker
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, picker)">
      </mat-datepicker>
    </mat-form-field>

    <div class="input-footer">
      <show-errors class="error-text" [control]="inputControl" [showUntouched]="errorsOnUntouched"></show-errors>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="false">
    <p class="label-text" *ngIf="!canEdit && showLabel()">
      {{labelText}}
    </p>

    <div>
      {{getReadOnlyText()}}
    </div>
  </ng-container>
</div>
