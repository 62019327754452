import { Model } from '@app-ngrx-domains';
import { Validate } from '@app-utilities';
import { EFFORT_AREA_RANGES, Proposal, IEARCContributorSnapshots} from '@app-models';
import { METRIC_GROUPS, PROGRAM_DOCUMENT_TYPES, PROJECT_ROLES } from '@app-consts';
import { ProgramService } from '@app/core/services';

export function validateDetails(p: Model.RCMProposal, programSettings: Model.EAProgramSettings): boolean {
  // validate title
  if (!Validate.hasMinimumChars(p.title)) {
    return false;
  }

  // validate assurances
  const selectedAssuranceIds = p.assurance_ids.map(attr => attr.value);
  if (!programSettings.assurances.every(assurance => selectedAssuranceIds.includes(assurance.id) )) {
    return false;
  }

  return true;
}

export function validateQualifications(p: Model.RCMProposal): boolean {

  if (!Validate.htmlHasMinimumChars(p.previous_experience)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.understanding_and_capability)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.ability_to_facilitate)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.operational_capability)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.financial_capability)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.previous_outcomes)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.case_studies_description)) {
    return false;
  }

  return true;
}

export function validateContacts(p: Model.RCMProposal, contacts: Array<Model.UserRoleScope>): boolean {
  if (!contacts && !contacts.length) {
    return false;
  }
  if (!contacts.every((c => c.user_id > 0))) {
    return false;
  }

  const roles: Array<number> = [
    PROJECT_ROLES.PROJECT_LEAD.ID,
    PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID,
  ];

  return roles.every(r => {
    return contacts.some(c => c.role_id === r);
  })
}

export function validateProblemStatement(p: Model.RCMProposal): boolean {
  if (!Validate.htmlHasMinimumChars(p.region_description)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.workforce_description)) {
    return false;
  }

  return true;
}

export function validatePrioritySectors(p: Model.RCMProposal): boolean {
  const prioritySectors = p.priority_sectors;

  if (!prioritySectors || prioritySectors.length < 3) {
    return false;
  }

  let valid = true;
  let o = 0;

  while (valid && o < prioritySectors.length) {
    const prioritySector = prioritySectors[o++];

    if (!prioritySector.priority_sector) {
      valid = false;
      break;
    }

    if (!prioritySector.top_code_ids.length) {
      valid = false;
      break;
    }

    if (!prioritySector.soc_code_ids.length) {
      valid = false;
      break;
    }

    if (!Validate.htmlHasMinimumChars(prioritySector.description)) {
      valid = false;
      break;
    }
  }

  return valid;
}

export function validateMetrics(p: Model.RCMProposal, md: Array<any>): boolean {
  const selectedGoals = p.vision_goals.filter(goal => goal.selected);

  // at least one goal must be selected
  if (!selectedGoals.length) {
      return false;
  }

  // of those selected goals, at least one metric must be selected from either group
  const validMetricDefsIds = md.filter(m => {
    return m.group === METRIC_GROUPS.DEFAULT || (m.group === METRIC_GROUPS.SWP && m.area === 'swp-v2')
  }).map(def => def.id);

  const hasMetricsFromEachGroup = selectedGoals.every(goal => {
    if (!goal.success_metrics.length) {
      return false;
    }
    const selectedIds = goal.success_metrics.map(metric => metric.value);
    return selectedIds.some(id => validMetricDefsIds.includes(id));
  });

  if (!hasMetricsFromEachGroup) {
    return false;
  }

  // check targets
  const selectedMetricIds = Proposal.selectedMetricsDefinitions(p);
  const durationIds = [p.duration_id];
  const planLength = 5;
  for (let i = 1; i < planLength; i++) {
    durationIds.push(p.duration_id + i);
  }

  for (const duration_id of durationIds) {
    for (const id of selectedMetricIds) {
      const target = p.target_goals.find(tg => {
        return tg.institution_id === p.application_region
          && tg.duration_id === duration_id
          && tg.metric_definition_id === id;
      });
      if (!target || Validate.isUntouched(target.target_amount)) {
        return false;
      }
    }
  }

  return true;
}

export function validateResponseToProblem(p: Model.RCMProposal): boolean {
  if (!Validate.htmlHasMinimumChars(p.regional_collaboration)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.resource_distribution)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.governance_structure)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.engagement_plan)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.collaboration_objectives)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.vision_goals_alignment)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.centers_of_excellence_guide)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.employer_relationships)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.anticipated_barriers)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.continuous_improvement)) {
    return false;
  }

  if (!Validate.htmlHasMinimumChars(p.qualifications_and_experience)) {
    return false;
  }

  return true;
}

export function validateWorkplanObjectives(p: Model.RCMProposal): boolean {
  const objectives = p.workplan_objectives;

  if ( !objectives || objectives.length < 1) {
    return false;
  }

  let valid = true;
  let o = 0;

  while (valid && o < objectives.length) {
    const objective = objectives[o++];
    if (!Validate.hasMinimumChars(objective.title)) {
      valid = false;
      break;
    }

    if (!objective.objective_type) {
      valid = false;
      break;
    }

    if (!Validate.htmlHasMinimumChars(objective.description)) {
      valid = false;
      break;
    }
  }

  return valid;
}

export function validateWorkplanActivities(p: Model.RCMProposal): boolean {
  const activities = p.workplan_activities;

  if ( !activities || activities.length < 1) {
    return false;
  }

  let valid = true;
  let a = 0;

  while (valid && a < activities.length) {
    const activity = activities[a++];
    if (!Validate.hasMinimumChars(activity.title)) {
      valid = false;
      break;
    }

    if (!Validate.htmlHasMinimumChars(activity.description)) {
      valid = false;
      break;
    }

    if (Validate.isUntouched(activity.objective_ea_id)) {
      valid = false;
      break;
    }

    if (activity.responsible_user_ids.length < 1) {
      valid = false;
      break;
    }

    if (activity.vision_goal_metrics.length < 1) {
      valid = false;
      break;
    }

    if (Validate.isUntouched(activity.duration_id)) {
      valid = false;
      break;
    }

  }

  return valid;
}

export function validateBudgets(p: Model.RCMProposal, programSettings: Model.EAProgramSettings, programService: ProgramService): boolean {
  return p['allocation_years'].filter(year => p.allocations.some(a => a.duration_id === year.duration_id)).every(year => {
    return validateBudget(p, programSettings, year.duration_id, programService);
  });
}

export function validateBudget(p: Model.RCMProposal, programSettings: Model.EAProgramSettings, allocationYear?: number, programService?: ProgramService): boolean {
  if (!Validate.htmlHasMinimumChars(p.budget_narrative)) {
    return false;
  }
  let budgetItems = p.plan_budget_items;

  if (!budgetItems || budgetItems.length < 1) {
    return false;
  }

  if (Proposal.isPlan(p)) {
    const allocYear = p['allocation_years'].find((year) => {
      return year.duration_id === allocationYear;
    });
    budgetItems = budgetItems.filter((item) => {
      return item.parent_effort_area_id === allocYear.id;
    });
  }

  if (!budgetItems || budgetItems.length < 1) {
    return false;
  }

  const contributorSettings = [];
  const getContributorSettings = allocation => {
    if (!contributorSettings.some(setting => setting.fund_id === allocation.fund_id)) {
      const contributor = programService.getProgramById(allocation.fund_id);
      if (contributor) {
        const settings = contributor.program_settings.rc_contributor_settings.find(settings => {
          return settings.duration_id === allocation.duration_id;
        }) || {};

        contributorSettings.push({ ...settings, fund_id: allocation.fund_id });
      }
    }

    return contributorSettings.find(setting => setting.fund_id === allocation.fund_id);
  };

  const allocationsReceived: Array<Model.Allocation | IEARCContributorSnapshots> = Proposal.isPlan(p)
    ? (p.allocations ? p.allocations.filter(allocation => {
        return allocation.duration_id === allocationYear;
      }).map(allocation => {
        const settings = getContributorSettings(allocation);

        return {
          ...allocation,
          match_percent: settings ? settings.match_percent : 0
        }
      }) : [])
    : programSettings.rc_contributor_snapshots.filter(snapshot => {
        return snapshot.institution_id === p.application_region;
      });

  // Check that match requirements are met
  const matchRequired = allocationsReceived.reduce(
    (sum: number, allocation: Model.Allocation | IEARCContributorSnapshots) => {
      const percent = allocation['match_percent'];
      const amount = Proposal.isPlan(p) ? allocation['amount'] : allocation['allocation_amount']
      const value = Number((amount * (percent / 100)).toFixed(0));
      return sum += value;
    }, 0);

  if (matchRequired) {
    const totalMatchEntered = budgetItems.reduce((total, item) => total + (item['monetary_match_amount'] || 0), 0);
    if (matchRequired - totalMatchEntered > 0) {
      return false;
    }
  }

  // Add up the allocations by fund
  const allocationsByFund = [];
  allocationsReceived.forEach((allocation: Model.Allocation | IEARCContributorSnapshots) => {
    const fundId = allocation.fund_id;
    const amount = (Proposal.isPlan(p) ? allocation['amount'] : allocation['allocation_amount']) || 0;
    const fundAmount = allocationsByFund.find(a => a.fund_id === fundId);

    if (!fundAmount) {
      allocationsByFund.push({
        fund_id: fundId,
        amount
      });
    } else {
      fundAmount.amount += amount;
    }
  });

  // Check that budget is completely spent
  return allocationsByFund.every((allocation: Model.Allocation | IEARCContributorSnapshots) => {
    const totalBudgetAmount = budgetItems.filter(item => {
      return item['fund_id'] == allocation['fund_id'];
    }).reduce((total, item) => total + (item.direct_amount || 0), 0);

    return allocation['amount'] - totalBudgetAmount === 0;
  });
}

export function validateForecasts(p: Model.RCMProposal, programSettings: Model.EAProgramSettings, programService: ProgramService): boolean {
  return p['allocation_years'].filter(year => p.allocations.some(a => a.duration_id === year.duration_id)).every(year => {
    return validateForecast(p, programSettings, year.duration_id, programService);
  });
}

export function validateForecast(p: Model.RCMProposal, programSettings: Model.EAProgramSettings, allocationYear?: number, programService?: ProgramService): boolean {
  let budgetItems = p.plan_budget_items;
  let forecastItems = p.plan_expenditure_forecasts;

  if (!validateBudget(p, programSettings, allocationYear, programService)) {
    return false;
  }

  if (!budgetItems || budgetItems.length < 1) {
    return false;
  }

  if (Proposal.isPlan(p)) {
    const allocYear = p['allocation_years'].find((year) => {
      return year.duration_id === allocationYear;
    });
    budgetItems = budgetItems.filter((item) => {
      return item.parent_effort_area_id === allocYear.id;
    });
    forecastItems = forecastItems.filter((item) => {
      return item.parent_effort_area_id === allocYear.id;
    });
  }

  const requiredForecasts = [];
  budgetItems.forEach(budgetItem => {
    if (!requiredForecasts.find(forecast => {
      return forecast.fund_id === budgetItem.fund_id && forecast.duration_id === budgetItem.duration_id;
    })) {
      requiredForecasts.push({ fund_id: budgetItem.fund_id, duration_id: budgetItem.duration_id });
    }
  });

  const quarters = ['quarter_1', 'quarter_2', 'quarter_3'];
  if (!requiredForecasts.every(required => {
    const forecast = forecastItems.find(f => {
      return f.fund_id === required.fund_id && f.duration_id === required.duration_id;
    });

    if (!forecast) {
      return false;
    }

    if (quarters.some(quarter => Validate.isUntouched(forecast[quarter]))) {
      return false;
    }

    let isValid = true;
    quarters.map(q => forecast[q]).reduce((last, curr) => {
      if (last > curr) {
        isValid = false;
      }
      return curr;
    }, 0);

    return isValid;
  })) {
    return false;
  }

  return true;
}

export function validateDocuments(p: Model.RCMProposal, files: Array<Model.Document>): boolean {
  const supportingDocuments = files.filter(f => !f.effort_area_id);
  const programKey = Proposal.getProgramKey(p);
  const programDocTypes = PROGRAM_DOCUMENT_TYPES[programKey];
  const requiredDocuments = programDocTypes.reduce((docs, type) => {
    if (type.required) {
      docs.push(type.id);
    }
    return docs;
  }, []);

  return requiredDocuments.every(id => supportingDocuments.find(f => f.document_type_id === id));
}
