import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State, Actions, Model } from '@app-ngrx-domains';
import { CHAR_LIMITS } from '@app-consts';
import { Utilities } from '@app-models';
import { ValidatorsEx } from '@app-utilities';

@Component({
  selector: 'guidance-card',
  templateUrl: './guidance-card.component.html'
})
export class GuidanceCardComponent implements OnInit {
  @Input() canEdit = false;
  @Input() isRevertible: string;
  @Input() firstTouch = true;
  @Input() isPreview = false;
  @Input() required = false;
  @Input() guidanceEA: Model.EAGuidance;
  @Input() parentEffortAreas: Array<Model.EffortArea>;

  form: FormGroup;
  narrativeLength = CHAR_LIMITS.EXTRA_LONG;

  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
  ) {
  }

  ngOnInit() {
    // special case for response to need and budget requiring a longer description
    if (this.guidanceEA.title === 'Response to Need') {
      this.narrativeLength = CHAR_LIMITS.EXTRA_LONG;
    }

    const validators = [
      ...(this.required ? [ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN)] : []),
      ValidatorsEx.htmlCharMaximum(this.narrativeLength)
    ];

    this.form = this._fb.group({
      description: [this.guidanceEA.description, validators],
    });
  }

  get guidanceTitle(): string {
    return this.guidanceEA ? this.guidanceEA.title : '';
  }

  get guidanceStep(): string {
    return this.guidanceEA ? this.guidanceEA.workflow_step : '';
  }

  persistAttribute(attributeName: string) {
    const value = this.form.get(attributeName).value;
    const prevValue = this.guidanceEA[attributeName];
    if (value !== null && value !== prevValue) {
      if (Utilities.isTempId(this.guidanceEA.id)) {

        const ea = {
          ...this.guidanceEA,
          [attributeName]: value,
        };

        this.store.dispatch(Actions.Fund.createEffortArea({ ea, hasAttributes: false, parentEffortAreas: this.parentEffortAreas }));
      } else {
        this.store.dispatch(Actions.Fund.upsertAttribute({ key: attributeName, value, ea: this.guidanceEA, parentEffortAreas: this.parentEffortAreas }));
      }
    }
  }
}
