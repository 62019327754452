<div class="card card--contact">
  <div class="card-header">
    <div class="card-title">
      <h3 class="h3">{{type.title}}</h3>
    </div>
  </div>

  <form [formGroup]="approvingAuthorityForm">
    <input-group headingText="{{headerText}}" [formGroup]="approvingAuthorityForm"
      [errorsOnUntouched]="true"
      (change)="updateApproverInstitution()"
    >
    <div *ngFor="let signer of availableSAs">
      <div *ngIf="canEdit || (signer && signer.institution.id === context.approver_institution_id)">
        <radio-check labelText="{{formatSigningAuthorityLabel(signer)}}" [canEdit]="canEdit">
          <input inputRef id="qa_signer_{{signer.institution.type}}_radio" type="radio"
            value="{{signer.institution.id}}" formControlName="approverInstitutionSelection"
            [checked]="signer.institution.id === context.approver_institution_id">
        </radio-check>

        <ul *ngIf="canEdit || (signer && signer.institution.id === context.approver_institution_id)">
          <li *ngFor="let user of signer.users">
            {{formatSigningAuthorityUsers(user)}}
            <a href="mailto:{{user.email_address}}">({{user.email_address}})</a>
          </li>
        </ul>
      </div>
    </div>
    
    </input-group>

  </form>

</div>