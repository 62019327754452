import { ModelBase } from './model.base';

export interface IResourceNote {
  // note fields
  id?: number;
  type: string;
  draft?: boolean;
  deleted?: boolean;
  resolved?: boolean;
  creator_id?: number;
  comment?: string;
  score?: number;
  parent_id?: number;
  resource_id?: number;
  classes?: object;
}

export class ResourceNote extends ModelBase implements IResourceNote {
  public id?: number;
  public type: string;
  public draft?: boolean;
  public deleted?: boolean;
  public resolved?: boolean;
  public creator_id?: number;
  public comment?: string;
  public score?: number;
  public parent_id?: number;
  public resource_id?: number;
  public classes?: object;

  constructor(raw?: any) {
    super();

    if (raw) {
      this.id = raw.id;
      this.type = raw.type;
      this.draft = raw.draft;
      this.deleted = raw.deleted;
      this.resolved = raw.resolved;
      this.creator_id = raw.creator_id;
      this.comment = raw.comment;
      this.score = raw.score;
      this.parent_id = raw.parent_id;
      this.resource_id = raw.resource_id;
      this.classes = raw.location;
    }
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ResourceNote = IResourceNote;
  }
}
