<hr *ngIf="isPreview && !isFirst">

<div [ngClass]="{'card': !isPreview }">
  <div *ngIf="!isPreview" class="card-title">
    <h3>{{cardTitle}}</h3>
  </div>

  <form [formGroup]="form" [ngClass]="{'first-touch': firstTouch}" autocomplete="off" class="columns columns--2">
    <input-field labelText="Outcome Name" [canEdit]="canEdit">
      <input inputRef id="{{count}}_name_input" type="text"
        formControlName="title"
        [maxLength]="titleMax"
        (change)="persistAttribute('title')"
        aria-required="true" required>
    </input-field>

    <ng-container [ngSwitch]="isCAI">
      <po-select *ngSwitchCase="true" id="{{count}}_objective_select"
        labelText="Select the Objective that Applies to this Outcome"
        [canEdit]="canEdit"
        [options]="objectiveOptions"
        [selectOnce]="true"
        (optionSelected)="persistAttribute('objective_ea_id')"
        (optionRemoved)="persistAttribute('objective_ea_id')">
        <select inputRef class="po-select__select" formControlName="objective_ea_id" aria-required="true" required></select>
      </po-select>
      <po-select *ngSwitchCase="false" id="{{count}}_activity_select"
        labelText="Activity that Applies to this Outcome"
        [canEdit]="canEdit"
        [options]="activityOptions"
        [selectOnce]="true"
        (optionSelected)="persistAttribute('activity_ea_id')"
        (optionRemoved)="persistAttribute('activity_ea_id')">
        <select inputRef class="po-select__select" formControlName="activity_ea_id" aria-required="true" required></select>
      </po-select>
    </ng-container>

    <input-field labelText="Description of Outcome" [canEdit]="canEdit" class="column-start-1 column-end-3">
      <div class="help-text">
        <p>
          {{
            useVisionGoal
              ? 'Description of projected outcomes must be quantifiable. Examples: Fill in [___] with specific entries such as number of workers, types of skills, competencies, technology, etc.'
              : 'Describe the outcome in qualitative and quantitative terms.'
          }}
        </p>
      </div>
      <po-html-textarea inputRef formControlName="description" id="{{count}}_description_input"
        [htmlCharLimit]="narrativeMax"
        (change)="persistAttribute('description')"
        aria-required="true" required></po-html-textarea>
    </input-field>

    <ng-container *ngIf="showSuccessMetrics">
      <po-select id="{{count}}_metrics_select"
        [labelText]="useVisionGoal
          ? 'Student Success Metrics and Performance Outcomes'
          : 'Select Student Success Metrics that Apply to this Outcome'"
        [canEdit]="canEdit"
        [options]="metricsOptions"
        [selectMultiple]="true"
        (optionSelected)="addSuccessMetric($event)"
        (optionRemoved)="removeSuccessMetric($event)">
        <div *ngIf="version !== 'v1'" class="help-text">
          <p>
            Select the Student Success Metrics and Performance Outcomes that align with the projected Outcome.
          </p>
        </div>
        <select inputRef class="po-select__select" formControlName="success_metrics" aria-required="true" required></select>
      </po-select>
  
      <input-field labelText="Description of Metric(s)" [canEdit]="canEdit">
        <div class="help-text">
          <p>Describe how the Student Success Metric(s) relate to the projected Outcome.</p>
        </div>
        <po-html-textarea inputRef formControlName="metrics_description" id="{{count}}_metrics_description"
          [htmlCharLimit]="narrativeMax"
          (change)="persistAttribute('metrics_description')"
          aria-required="true" required></po-html-textarea>
      </input-field>
    </ng-container>
  </form>

  <div *ngIf="allowDelete" class="align-right">
    <button class="action-button action-button--secondary action-button--small icon--left js-delete"
      (click)="toggleShowWarning()" id="{{count}}_delete_button">
      <icon iconId="delete"></icon>
      Delete
    </button>
  </div>
</div>

<alert *ngIf="showDeleteWarning"
  button-action="Delete"
  button-dismiss="Cancel"
  level="warning"
  (action)="delete()"
  (dismiss)="toggleShowWarning()">
  <p>Are you sure you want to delete this outcome?</p>
</alert>
