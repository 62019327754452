import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload, AttributeActions } from '@app-libs';

export const FUND_ACTION_PREFIX = 'FUND_';
export const FUND_ACTION_TYPES = {
  // ********** Action Types Managed by Parent/AttributeActions Class ********
  UPSERT_ATTRIBUTE: `${FUND_ACTION_PREFIX}UPSERT_ATTRIBUTE`,
  UPSERT_ATTRIBUTES: `${FUND_ACTION_PREFIX}UPSERT_ATTRIBUTES`,
  DELETE_ATTRIBUTES: `${FUND_ACTION_PREFIX}DELETE_ATTRIBUTES`,
  ADD_MULTI_ATTRIBUTE: `${FUND_ACTION_PREFIX}ADD_MULTI_ATTRIBUTE`,
  DELETE_MULTI_ATTRIBUTE: `${FUND_ACTION_PREFIX}DELETE_MULTI_ATTRIBUTE`,
  CREATE_ATTRIBUTE_SUCCESS: `${FUND_ACTION_PREFIX}CREATE_ATTRIBUTE_SUCCESS`,
  UPSERT_ATTRIBUTE_SUCCESS: `${FUND_ACTION_PREFIX}UPSERT_ATTRIBUTE_SUCCESS`,
  UPSERT_ATTRIBUTES_SUCCESS: `${FUND_ACTION_PREFIX}UPSERT_ATTRIBUTES_SUCCESS`,
  DELETE_ATTRIBUTE_SUCCESS: `${FUND_ACTION_PREFIX}DELETE_ATTRIBUTE_SUCCESS`,
  DELETE_ATTRIBUTES_SUCCESS: `${FUND_ACTION_PREFIX}DELETE_ATTRIBUTES_SUCCESS`,
  CREATE_EFFORT_AREA: `${FUND_ACTION_PREFIX}CREATE_EFFORT_AREA`,
  CREATE_EFFORT_AREA_SUCCESS: `${FUND_ACTION_PREFIX}CREATE_EFFORT_AREA_SUCCESS`,
  CREATE_MULTI_EFFORT_AREAS: `${FUND_ACTION_PREFIX}CREATE_MULTI_EFFORT_AREAS`,
  CREATE_MULTI_EFFORT_AREAS_SUCCESS: `${FUND_ACTION_PREFIX}CREATE_MULTI_EFFORT_AREAS_SUCCESS`,
  UPDATE_EFFORT_AREA: `${FUND_ACTION_PREFIX}UPDATE_EFFORT_AREA`,
  UPDATE_EFFORT_AREA_SUCCESS: `${FUND_ACTION_PREFIX}UPDATE_EFFORT_AREA_SUCCESS`,
  DELETE_EFFORT_AREA: `${FUND_ACTION_PREFIX}DELETE_EFFORT_AREA`,
  DELETE_EFFORT_AREA_SUCCESS: `${FUND_ACTION_PREFIX}DELETE_EFFORT_AREA_SUCCESS`,
  CREATE_ATTRIBUTE_EFFORT_AREA: `${FUND_ACTION_PREFIX}CREATE_ATTRIBUTE_EFFORT_AREA`,
  CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS: `${FUND_ACTION_PREFIX}CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS`,
  DELETE_ITEM_SUCCESS: `${FUND_ACTION_PREFIX}DELETE_ITEM_SUCCESS`,
  CLONE_EFFORT_AREAS: `${FUND_ACTION_PREFIX}CLONE_EFFORT_AREAS`,
  DELETE_MULTI_EFFORT_AREAS: `${FUND_ACTION_PREFIX}DELETE_MULTI_EFFORT_AREAS`,
  DELETE_MULTI_EFFORT_AREAS_SUCCESS: `${FUND_ACTION_PREFIX}DELETE_MULTI_EFFORT_AREAS_SUCCESS`,
  // ********** Action Types Managed by Parent/AttributeActions Class ********

  GET: `${FUND_ACTION_PREFIX}GET`,
  GET_SUCCESS: `${FUND_ACTION_PREFIX}GET_SUCCESS`,
  GET_FAIL: `${FUND_ACTION_PREFIX}GET_FAIL`,
  NOOP: `${FUND_ACTION_PREFIX}NOOP`,
  CREATE_TASK: `${FUND_ACTION_PREFIX}CREATE_TASK`,
  COMPLETE_TASK: `${FUND_ACTION_PREFIX}COMPLETE_TASK`,
  UNDO_TASK: `${FUND_ACTION_PREFIX}UNDO_TASK`,
  REFRESH_SUCCESS: `${FUND_ACTION_PREFIX}REFRESH_SUCCESS`,
  APPEND_FILE: `${FUND_ACTION_PREFIX}APPEND_FILE`,
  DELETE_FILE: `${FUND_ACTION_PREFIX}DELETE_FILE`,
  DELETE_FILE_SUCCESS: `${FUND_ACTION_PREFIX}DELETE_FILE_SUCCESS`,
  UPDATE_CLIENT_STATE: `${FUND_ACTION_PREFIX}UPDATE_CLIENT_STATE`,
  TASK_UPDATE_SUCCESS: `${FUND_ACTION_PREFIX}TASK_UPDATE_SUCCESS`
};

export class FundActions extends AttributeActions {

  constructor() {
    super(FUND_ACTION_PREFIX);
  }

  get(fund_id: number): ActionWithPayload<any> {
    return {
      type: FUND_ACTION_TYPES.GET,
      payload: fund_id
    }
  }

  getSuccess(response: any): ActionWithPayload<any> {
    const fund = response[0];

    const state = {
      data: fund,
      is_loading: false,
      files: fund.program_settings.files,
      error: null,
    };

    return {
      type: FUND_ACTION_TYPES.GET_SUCCESS,
      payload: state
    };
  }

  getFail(error: any): ActionWithPayload<any> {
    return {
      type: FUND_ACTION_TYPES.GET_FAIL,
      payload: error
    };
  }

   appendFile(file: Model.Document): ActionWithPayload<any> {
    return {
      type: FUND_ACTION_TYPES.APPEND_FILE,
      payload: file
    };
  };

  deleteFile(id: number): ActionWithPayload<any> {
    return {
      type: FUND_ACTION_TYPES.DELETE_FILE,
      payload: id
    }
  };

  deleteFileSuccess(id: number): ActionWithPayload<any> {
    return {
      type: FUND_ACTION_TYPES.DELETE_FILE_SUCCESS,
      payload: id
    }
  };

  createTask(task: any, goto?: {url: string, extras?: any}): ActionWithPayload<any> {
    return {
      type: FUND_ACTION_TYPES.CREATE_TASK,
      payload: { task, goto }
    };
  }

  completeTask(task: any, goto?: {url: string, extras?: any}): ActionWithPayload<any> {
    return {
      type: FUND_ACTION_TYPES.COMPLETE_TASK,
      payload: { task, goto }
    };
  }

  undoTask(task: any, goto?: {url: string, extras?: any}): ActionWithPayload<any> {
    return {
      type: FUND_ACTION_TYPES.UNDO_TASK,
      payload: { task, goto }
    };
  }

  refreshTaskSuccess(response: any): ActionWithPayload<any> {
    const fund = response[0];

    const refresh = {
      states: fund.states,
      tasks: fund.tasks,
      program_settings_pending: fund.program_settings_pending,
      survey_templates: fund.survey_templates
    };

    return {
      type: FUND_ACTION_TYPES.REFRESH_SUCCESS,
      payload: refresh,
    };
  }

  updateClientState(state: any): ActionWithPayload<any> {
    return {
      type: FUND_ACTION_TYPES.UPDATE_CLIENT_STATE,
      payload: state,
    };
  }

  taskUpdateSuccess(task: any): ActionWithPayload<any> {
    return {
      type: FUND_ACTION_TYPES.TASK_UPDATE_SUCCESS,
      payload: task
    };
  }
}

Actions.Fund = new FundActions();

declare module '@app-ngrx-domains' {
  interface Actions {
    Fund: FundActions
  }
}
