<nav class="workflow-steps__group no-print" id="qa_workflow_nav" *ngIf="!!workflow">

  <a class="workflow-steps__back"
    tabIndex=0
     *ngIf="!!workflow.parent"
    [routerLink]="[workflow.parent.backRoute ? workflow.parent.backRoute : workflow.baseLink]">
    <icon iconId="arrow-back"></icon>
    {{workflow.parent.title || 'Workflow'}}
  </a>
  <h4 class="h4 workflow-steps__heading">{{!!workflow.parent ? workflow.title : 'Workflow'}}</h4>

  <ul class="workflow-steps__menu" role="tree">
    <ng-container *ngFor="let step of workflow.steps; trackBy: trackByName">
      <ng-container *ngIf="!step.hide" [ngSwitch]="!!step.workflow && !!step.workflow.section">
        <ng-container *ngSwitchCase="false"
                      [ngTemplateOutletContext]="{step: step}"
                      [ngTemplateOutlet]="workflowStep"></ng-container>

        <ng-container *ngSwitchCase="true">
          <li>
            <div class="workflow-steps__subsection">
              <h5 class="workflow-steps__subheading">{{step.title}}</h5>
            </div>
            <ul class="workflow-steps__menu" role="group">
              <ng-container [ngSwitch]="!!step.steps && !!step.steps.length">
                <li *ngSwitchCase="false"
                    class="workflow-steps__step workflow-steps__step--empty">
                  {{step.workflow.empty}}
                </li>
                <ng-container *ngSwitchCase="true">
                  <ng-container *ngFor="let sectionStep of step.steps; trackBy: trackByName"
                                [ngTemplateOutletContext]="{step: sectionStep}"
                                [ngTemplateOutlet]="workflowStep"></ng-container>
                </ng-container>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>

  <ng-template #workflowStep let-step="step">
    <li *ngIf="!!step.separator" class="workflow-steps__step workflow-steps__step--separator"></li>
    <li class="workflow-steps__step" data-qa="nav-workflow-step-item">
      <a app-nav-workflow-step
        class="workflow-steps__link"
        role="treeitem"
        tabIndex=0
        [step]="step"
        [ngClass]="{disabled: step.disabled}"
        [routerLink]="[stepRouterLink(step, workflow.baseLink)]"
        [routerLinkActive]="['active']">
      </a>
    </li>
  </ng-template>
</nav>
