import { Component, OnInit, Input } from '@angular/core';
import { LookupService, ApiService } from '@app/core/services';
import { Store } from '@ngrx/store';
import { Profile } from '@app/core/models';
import { INSTITUTION_TYPES } from '@app/core/consts';
import { Model, Actions, State } from '@app-ngrx-domains';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'sa-contact-type',
  templateUrl: './signing-authority.component.html'
})
export class SAuthorityComponent implements OnInit {
  // Constructor is just super.constructor;

  @Input() context: Model.ContactContext;
  @Input() index: number = 0;
  useDesignee = false;
  @Input() currentWorkflowStep: string;
  @Input() set canEdit(canEdit) {
    this._canEdit = canEdit;
    if (this.approvingAuthorityForm) {
      const action = canEdit ? 'enable' : 'disable';
      this.approvingAuthorityForm[action]();
    }
  }
  get canEdit() {
    return this._canEdit;
  }
  private _canEdit = false;
  @Input() type: Model.EAContactType;

  availableRSA: { institution: Model.Institution, users: Array<Model.User> };
  availableDSA: { institution: Model.Institution, users: Array<Model.User> };
  availableCSA: { institution: Model.Institution, users: Array<Model.User> };
  approvingAuthorityForm: FormGroup;
  hasUnassignedLeadInstitution: boolean;
  headerText = 'header text'
  title = 'card title'
  private isStepDirty = false;

  constructor(
    private lookupService: LookupService,
    private apiService: ApiService,
    private store: Store<State>,
    private formBuilder: FormBuilder,
    ) {
      this.approvingAuthorityForm = this.formBuilder.group({
        approverInstitutionSelection: [{
          value: null,
          disabled: this.canEdit
        }, [Validators.required]]
      });
  }

  ngOnInit() {

    if (this.context.lead_institution_id) {
      this.getSigningAuthorities()
    }
  }

  get availableSAs() {

    const available = [];
    [this.availableRSA, this.availableDSA, this.availableCSA].forEach(sa => {
      if (sa) {
        available.push(sa);
      }
    });
    return available;
  }

  getSigningAuthorities() {
    this.apiService.getProposalSigningAuthorities(this.context.proposal_id)
      .subscribe((signers: Array<{ institution, users }>) => {
        signers.forEach(signer => {
          switch (signer.institution.type) {
            case INSTITUTION_TYPES.COLLEGE: this.availableCSA = signer;
              break;
            case INSTITUTION_TYPES.CCD: this.availableDSA = signer;
              break;
            case INSTITUTION_TYPES.RC: this.availableRSA = signer;
          }
        });
        let current_value = null;
        if (this.context.approver_institution_id) {
          this.availableSAs.forEach(sa => {
            if (sa.institution.id === this.context.approver_institution_id) {
              current_value = sa.institution.id;
            }
          });
        }
        this.approvingAuthorityForm.get('approverInstitutionSelection').setValue(current_value);
        if (current_value === null && this.context.approver_institution_id) {
          this.updateApproverInstitution();
        }
      })
  }

  formatSigningAuthorityLabel(signer) {
    let signer_type;
    switch (signer.institution.type) {
      case INSTITUTION_TYPES.COLLEGE: signer_type = 'College';
        break;
      case INSTITUTION_TYPES.CCD: signer_type = 'District';
        break;
      case INSTITUTION_TYPES.RC: signer_type = 'Regional';
    }
    return `<strong>${signer_type} Signing Authority - ${signer.institution.name}</strong>`
  }

  formatSigningAuthorityUsers(user) {
    return Profile.contactFullName(user);
  }

  updateApproverInstitution() {
    let value = this.approvingAuthorityForm.get('approverInstitutionSelection').value;
    value = value !== null ? Number(value) : undefined;
    this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: 'approver_institution_id', value }))
    this.markDirty()
  }

  markDirty() {
    if (!this.isStepDirty) {
      this.isStepDirty = true;
      this.store.dispatch(Actions.CurrentProposal.setStateDirty(this.currentWorkflowStep));
      this.store.dispatch(Actions.Workflow.clearFirstTouch(this.currentWorkflowStep));
    }
  }
}