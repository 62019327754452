import { AfterContentInit, ComponentRef, ContentChild, ContentChildren, Directive, ElementRef, forwardRef, Injector, Input, OnDestroy, Optional, QueryList, Renderer2, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { InlineAlertComponent } from '../components/inline-alert/inline-alert.component';
import { InputRefDirective } from './input-ref/input-ref.directive';
import { Logger } from '../../app-logger';
import { Store } from '@ngrx/store';
import { Queries, State } from '@app-ngrx-domains';
import { POSelectComponent } from '../components';
import { differenceBy } from 'lodash';
import { IconComponent } from '../components/icon/icon.component';
import { InputFieldComponent } from '../components/input-field/input-field.component';

@Directive({
  selector: '[revertibleField]'
})
export class RevertibleFieldDirective implements AfterContentInit, OnDestroy {
  @Input() set revertibleField(workflowStep: string) {
    this.workflowStep = workflowStep;
    if (this.initialized) {
      this.watchForChanges();
    }
  }

  @Input() modifiedFieldName: string; // Overrides the default attributeName (from input's formControlName)
  @Input() inputFilter: string; // Filters multi-inputs by a matching value in a "data-fieldName" attribute
  @Input() alertOnly: boolean;
  @Input() revertOnly: boolean;
  @Input() shortInput: boolean;  // Set to true if using input-field and the inputRef is wrapped in a column div
  @ContentChild(InputRefDirective, { static: true }) input: InputRefDirective;
  @ContentChildren(forwardRef(() => InputRefDirective), { descendants: true }) multiInputs: QueryList<InputRefDirective>;

  private workflowStep: string;
  private attributeName: string | number;
  private originalValue: any;
  private changeEvent: Event;
  private poSelect: POSelectComponent;
  private inputField: InputFieldComponent;
  private checkboxes: Array<InputRefDirective>;
  private radios: Array<InputRefDirective>;
  private alertComponent: ComponentRef<InlineAlertComponent>;
  private alertContainer: { parent: ParentNode, div: HTMLDivElement, inputRef: HTMLInputElement };
  private revertButton: { div: HTMLDivElement, unsubscribe: Function };
  private initialized: boolean;
  private unsubscribe$: Subject<boolean> = new Subject();

  constructor(
    @Optional() poSelect: POSelectComponent,
    @Optional() inputField: InputFieldComponent,
    private injector: Injector,
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private store: Store<State>
  ) {
    if (poSelect) {
      this.poSelect = poSelect;
    }

    if (inputField) {
      this.inputField = inputField;
    }
  }

  ngAfterContentInit() {
    this.changeEvent = new Event('change');

    if (this.multiInputs) {
      const multiFilter = (inputType: string) => (input: InputRefDirective) => {
        return (input.element.type === inputType) && (!this.inputFilter || input.element.dataset.fieldName === this.inputFilter);
      };
      this.checkboxes = this.multiInputs.filter(multiFilter('checkbox'));
      this.radios = this.multiInputs.filter(multiFilter('radio'));
    }

    if (!this.checkboxes?.length && !this.radios?.length) {
      if (this.input && this.input.control.name) {
        // Standard, single-value input
        this.attributeName = this.input.control.name;
      } else if (!this.alertOnly) {
        Logger.error('An input is required for the revertibleField directive.' + this.modifiedFieldName);
        return;
      }
    }

    if (this.modifiedFieldName) {
      this.attributeName = this.modifiedFieldName;
    }

    this.initialized = true;
    if (this.workflowStep) {
      this.watchForChanges();
    }
  }

  watchForChanges() {
    this.unsubscribe$.next(true);

    this.store.select(Queries.Workflow.getModifiedFields(this.workflowStep)).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((modifiedFields) => {
      if (modifiedFields && modifiedFields[this.attributeName]) {
        const fieldInfo = modifiedFields[this.attributeName];
        this.originalValue = fieldInfo.originalValue;

        if (this.revertOnly) {
          this.showRevertButton();
        } else {
          this.showAlert(fieldInfo.description);
        }
      } else {
        this.clearAlert();
      }
    });
  }

  showRevertButton() {
    if (this.revertButton) { return; }
    const div = document.createElement('div');
    const button = document.createElement('button');
    const icon = this.viewContainerRef.createComponent(IconComponent);
    icon.instance.iconId = 'revert';

    this.renderer.addClass(button, 'action-button');
    this.renderer.addClass(button, 'action-button--tertiary');
    this.renderer.addClass(div, 'revert-button-container');
    this.renderer.setAttribute(div, 'title', 'Revert');

    const host = this.el.nativeElement;
    button.appendChild(icon.location.nativeElement);
    div.appendChild(button);
    host.appendChild(div);

    const unsubscribe = this.renderer.listen(button, 'click', () => {
      if (this.poSelect) {
        if (Array.isArray(this.originalValue)) {
          const currentSelections = this.poSelect.selectedOptions$.value;
          const originalSelections = this.originalValue;
          const valuesToRemove = differenceBy(currentSelections, originalSelections, 'value');
          const valuesToAdd = differenceBy(this.originalValue, currentSelections, 'value');

          valuesToRemove.forEach(value => { this.poSelect.remove(value) });
          valuesToAdd.forEach(value => { this.poSelect.select(value) });
        } else {
          this.poSelect.select({ value: this.originalValue });
        }
      } else {
        this.input.control.control.patchValue(this.originalValue);
        this.input.element.dispatchEvent(this.changeEvent);
      }
    });

    this.revertButton = { div, unsubscribe };
  }

  showAlert(alertText: string) {
    const elementText = alertText || 'This value has been modified.';
    if (!this.alertComponent) {
      // Build & display the alert below the input
      const alert = document.createElement('p');
      alert.innerHTML = elementText;

      const host = this.el.nativeElement;
      const componentRef = this.viewContainerRef.createComponent(InlineAlertComponent, {
        injector: this.injector,
        projectableNodes: [[alert]]
      });
      componentRef.instance.level = 'info';
      componentRef.instance.smaller = true;
      componentRef.instance.noIcon = true;

      if (!this.alertOnly) {
        componentRef.instance.actionLabel = 'Revert';
      }

      const componentEl = componentRef.location.nativeElement;
      if (host.localName === 'table') {
        host.parentNode.insertBefore(componentEl, host.nextSibling);
      } else {
        this.renderer.addClass(componentEl.firstChild, 'margin-top-xxs');

        const inputRef = this.inputField?.input?.element;
        const inputParent = inputRef?.parentNode;

        /**
         * If shortInput is true, then that means the input element is wrapped in a column div.
         * To make the alert match the width of the input, wrap them both in a div.
        */
        if (host.localName === 'input-field' && inputParent && this.shortInput) {
          const inputContainer = document.createElement('div');
          inputParent.appendChild(inputContainer)
          inputContainer.appendChild(inputRef);
          inputContainer.appendChild(componentEl);

          // Wrapping these elements messes with the margins, so add a negative margin to offset that
          this.renderer.setStyle(inputContainer, 'margin-bottom', '-1.5rem');

          this.alertContainer = {
            parent: inputParent,
            div: inputContainer,
            inputRef
          };
        } else {
          host.appendChild(componentEl);
        }
      }
      this.alertComponent = componentRef;

      this.alertComponent.instance.action.pipe(
        takeWhile(() => !!this.alertComponent)
      ).subscribe(() => {
        if (this.poSelect) {
          if (Array.isArray(this.originalValue)) {
            const currentSelections = this.poSelect.selectedOptions$.value;
            const originalSelections = this.originalValue;
            const valuesToRemove = differenceBy(currentSelections, originalSelections, 'value');
            const valuesToAdd = differenceBy(this.originalValue, currentSelections, 'value');

            valuesToRemove.forEach(value => { this.poSelect.remove(value) });
            valuesToAdd.forEach(value => { this.poSelect.select(value) });
          } else {
            this.poSelect.select({ value: this.originalValue });
          }
        } else if (this.checkboxes?.length) {
          const selectedValues = Array.isArray(this.originalValue) ? this.originalValue : [];
          this.checkboxes.forEach(input => {
            const checked = selectedValues.includes(input.control.name);
            if (input.element.checked !== checked) {
              input.element.checked = checked;
              input.element.dispatchEvent(this.changeEvent);
            }
          });
        } else if (this.radios?.length) {
          /** NOTES:
           * Emitting the "change" event on a radio input sets the formControl to the value of that radio option
           * Boolean value radios are considered "unset" so we can't read their values through element.value
           * So as a workaround, we can set the formControl to the value that we want, then search for the "checked" input to emit the change event
          */
          const radioOption = this.radios[0];
          radioOption.control.control.setValue(this.originalValue);
          const activeOption = this.radios.find(radio => radio.element.checked);
          if (activeOption) {
            activeOption.element.dispatchEvent(this.changeEvent);
          }
        } else {
          this.input.control.control.patchValue(this.originalValue);
          this.input.element.dispatchEvent(this.changeEvent);
        }
      });
    } else {
      this.alertComponent.instance.content.nativeElement.innerHTML = elementText;
    }
  }

  clearAlert() {
    if (this.alertContainer) {
      this.renderer.appendChild(this.alertContainer.parent, this.alertContainer.inputRef);
      this.renderer.removeChild(this.alertContainer.parent, this.alertContainer.div);
      this.alertContainer = undefined;
    }

    if (this.alertComponent) {
      this.alertComponent.destroy();
      this.alertComponent = undefined;
    }

    if (this.revertButton) {
      this.revertButton.unsubscribe();
      this.renderer.removeChild(this.el.nativeElement, this.revertButton.div);
      this.revertButton = undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
