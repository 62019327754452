import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replaceBr' })
export class ReplaceBrPipe implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return value;
    }

    // replace non-printable chars first
    const transformed = value.replace(/[^ -~]+/g, ' ');
    // then replace <br> with \n
    return transformed.replace(/<br\s*[\/]?>/gi, '\n');
  }
}
