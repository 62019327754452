import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { State, Actions as DomainActions } from '@app-ngrx-domains'
import { toPayload } from '@app-libs';
import { APP_ACTION_TYPES } from './app.action';
import { CORE_ROUTES } from '../consts';

/**
 * Injectable Workflow effects class
 */
@Injectable()
export class AppEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<State>
  ) {
  }

  /**
   * Routes to requested path.
   */
  
  public go$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(APP_ACTION_TYPES.NAVIGATE_GO),
    map(toPayload),
    tap(payload => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      (payload.skipRefresh
        ? Promise.resolve()
        : this.router.navigateByUrl(CORE_ROUTES.REDIRECT, { skipLocationChange: true })
      ).then(() => {
        this.router.navigate(payload.path, payload.extras).then(() => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
        });
      });
    })
  ), {dispatch: false});
}
