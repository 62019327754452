import { Model } from '@app-ngrx-domains';
import { WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';

export const SUB_APPLICATIONS_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS,
  templateName: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS,
  title: 'CCD / College',
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.PROGRAMS,
      title: 'Programs',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.CORE,
      title: 'Core Indicators',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.TOP,
      title: 'TOP Codes',
      showStatus: true,
      valid: true,
      workflow: {
        name: `${WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_TOP_CODES}/:institutionId/:codeId`,
        templateName: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_TOP_CODES,
        static: false,
        route: 'topcodes/:codeId',
        section: true,
        empty: 'Top Codes will appear here when added.'
      }
    },
    {
      route: WORKFLOW_STEPS.ACROSS,
      title: 'Across CTE',
      showStatus: true,
      valid: true,
      workflow: {
        name: `${WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ACROSS_CTE}/:institutionId/:codeId`,
        templateName: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ACROSS_CTE,
        static: false,
        route: 'acrosscte/:codeId',
        section: true,
        empty: 'Across CTE will appear here when added.'
      }
    },
    {
      route: WORKFLOW_STEPS.ADMIN,
      title: 'Admin',
      showStatus: true,
      valid: true,
      workflow: {
        name: `${WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ADMIN}/:institutionId/:codeId`,
        templateName: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ADMIN,
        static: false,
        route: 'admin/:codeId',
        section: true,
        empty: 'Admin will appear here when added.'
      }
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      showStatus: false,
      separator: true,
    }
  ]
};

export const SUB_APPLICATIONS_TOP_CODES_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_TOP_CODES,
  templateName: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_TOP_CODES,
  title: 'Top Codes',
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.CORE,
      title: 'Core Indicators',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.PROGRAM,
      title: 'Program Info',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.BUDGET,
      title: 'Budget',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview',
      showStatus: false,
    },
  ]
};

export const SUB_APPLICATIONS_ACROSS_CTE_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ACROSS_CTE,
  templateName: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ACROSS_CTE,
  title: 'Across CTE',
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.PROGRAM,
      title: 'Program Info',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.BUDGET,
      title: 'Budget',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview',
      showStatus: false,
    },
  ]
};

export const SUB_APPLICATIONS_ADMIN_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ADMIN,
  templateName: WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ADMIN,
  title: 'Admin',
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.BUDGET,
      title: 'Budget',
      showStatus: true,
      valid: false,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview',
      showStatus: false,
    },
  ]
};
