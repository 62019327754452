import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { Profile, EnumErrorTypes, IUser } from '../models';
import { ROUTER_LINKS } from '../consts';
import { NavigationExtras } from '@angular/router';
import { UserMessage } from '../models/user-message';
import { AppUtils } from '../utilities';

/**
 * Private variables
 */
const AUTH_TOKEN_NAME = 'auth_token';

/**
 * Authorization Action types
 */
const ACTION_PREFIX = 'AUTH_';
export const AUTH_ACTION_TYPES = {
  LOGIN: `${ACTION_PREFIX}LOGIN`,
  LOGIN_SUCCESS: `${ACTION_PREFIX}LOGIN_SUCCESS`,
  LOGIN_FAILED: `${ACTION_PREFIX}LOGIN_FAILED`,
  REFRESH: `${ACTION_PREFIX}REFRESH`,
  REFRESH_SUCCESS: `${ACTION_PREFIX}REFRESH_SUCCESS`,
  REFRESH_FAILED: `${ACTION_PREFIX}REFRESH_FAILED`,
  LOGIN_CHECK: `${ACTION_PREFIX}LOGIN_CHECK`,
  LOGIN_CHECK_OK: `${ACTION_PREFIX}LOGIN_CHECK_OK`,
  LOGIN_CHECK_FAILED: `${ACTION_PREFIX}LOGIN_CHECK_FAILED`,
  LOGIN_WAITING_ON_USER: `${ACTION_PREFIX}LOGIN_WAITING_ON_USER`,
  LOGOUT: `${ACTION_PREFIX}LOGOUT`,
  LOGOUT_SUCCESS: `${ACTION_PREFIX}LOGOUT_SUCCESS`,
  UPDATE_USER: `${ACTION_PREFIX}UPDATE_USER`,
  CRUD_USER_SUCCESS: `${ACTION_PREFIX}CRUD_USER_SUCCESS`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  SUDO_LOGIN: `${ACTION_PREFIX}SUDO_LOGIN`,
  SET_SUDO_PERMISSION: `${ACTION_PREFIX}SET_SUDO_PERMISSION`,
  SET_SUDO_LOGIN: `${ACTION_PREFIX}SET_SUDO_LOGIN`,
  REFRESH_USER_MESSAGES: `${ACTION_PREFIX}REFRESH_USER_MESSAGES`,
  REFRESH_USER_MESSAGES_SUCCESS: `${ACTION_PREFIX}REFRESH_USER_MESSAGES_SUCCESS`,
  MARK_USER_MESSAGES: `${ACTION_PREFIX}MARK_USER_MESSAGES`,
  MARK_USER_MESSAGES_SUCCESS: `${ACTION_PREFIX}MARK_USER_MESSAGES_SUCCESS`,

  SET_ROUTE_WATCHER: `${ACTION_PREFIX}SET_ROUTE_WATCHER`
};

/**
 * Authentication action class.
 */
export class AuthActions {

  setRouteWatch(watch: boolean): ActionWithPayload<any> {
    return {
      type: AUTH_ACTION_TYPES.SET_ROUTE_WATCHER,
      payload: { watch }
    };
  }

  /**
   * Login action
   *
   * @param {any} email
   * @param {any} password
   * @returns {Action}
   */
  login(email?: string, password?: string): ActionWithPayload<any> {
    // send out logging started message
    return {
      type: AUTH_ACTION_TYPES.LOGIN,
      payload: { email: email, password: password }
    };
  }

  /**
   * Login success action.
   * An auth token returned from the Service. Decode it (to get user profile), and if valid, store it.
   * @param {any} user record, including an auth_code property
   * @returns {Action}
   */
  loginSuccess(user: any): ActionWithPayload<any> {
    const decodedToken = AppUtils.jwtDecodeToken(user && user.session_token);
    if (decodedToken) {
      localStorage.setItem(AUTH_TOKEN_NAME, user.session_token);
      return {
        type: AUTH_ACTION_TYPES.LOGIN_SUCCESS,
        payload: { user: new Profile(user), funds: user.visible_funds }
      };
    } else {
      return Actions.App.setError({
        type: EnumErrorTypes.api,
        location: AUTH_ACTION_TYPES.LOGIN_FAILED,
        show: true,
        message: `Invalid response from login request: ${JSON.stringify(user)}`,
      });
    }

  }

  refresh(goto?: {url: string, extras?: any}): ActionWithPayload<any> {
    return {
      type: AUTH_ACTION_TYPES.REFRESH,
      payload: { goto }
    }
  }

  refreshSuccess(user): ActionWithPayload<any> {
    const decodedToken = AppUtils.jwtDecodeToken(user && user.session_token);

    if (decodedToken) {
      localStorage.setItem(AUTH_TOKEN_NAME, user.session_token);
      return {
        type: AUTH_ACTION_TYPES.REFRESH_SUCCESS,
        payload: user.visible_funds
      }
    } else {
      return Actions.App.setError({
        type: EnumErrorTypes.api,
        location: AUTH_ACTION_TYPES.REFRESH_FAILED,
        show: true,
        raw: `Invalid response from refresh request: ${user}`,
      });
    }
  }

  refreshFail(errResponse: HttpErrorResponse): ActionWithPayload<any> {
    localStorage.removeItem(AUTH_TOKEN_NAME);

    // Handle this error globally
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: AUTH_ACTION_TYPES.REFRESH_FAILED,
      message: `There was an error refreshing your session: ${errResponse.error.message}.\n Please re-enter your credentials.`,
      show: false,
      raw: errResponse,
    });
  }

  /**
   * Login after successfully creating or updating the user.
   *
   * @param {any} user
   * @returns {Action}
   */
  loginOnDemand(user: any): ActionWithPayload<any> {
    const decodedToken = AppUtils.jwtDecodeToken(user && user.session_token);
    if (decodedToken) {
      localStorage.setItem(AUTH_TOKEN_NAME, user.session_token);
      return {
        type: AUTH_ACTION_TYPES.LOGIN_SUCCESS,
        payload: new Profile(user)
      }
    } else {
      return Actions.App.setError({
        type: EnumErrorTypes.api,
        location: AUTH_ACTION_TYPES.LOGIN_FAILED,
        show: true,
        raw: `Auth token is invalid: ${user.session_token}`,
      });
    }

  }

  /**
   * Login failed action.
   *
   * @param {HttpErrorResponse} errResponse - error response
   * @returns {Action}
   */
  loginFail(errResponse: HttpErrorResponse): ActionWithPayload<any> {
    // login attempt failed... remove auth token from storage
    localStorage.removeItem(AUTH_TOKEN_NAME);

    if (errResponse.error && (errResponse.status >= 400 && errResponse.status < 500)) {
      // send out failed message that can be corrected
      return {
        type: AUTH_ACTION_TYPES.LOGIN_FAILED,
        payload: { message: errResponse.error.message }
      }
    }

    // Default case: handle this error globally
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: AUTH_ACTION_TYPES.LOGIN_FAILED,
      show: true,
      raw: errResponse,
      message:  errResponse.error.message,
    });
  }

  /**
   * Checks if user is still logged in.
   *
   * @returns {Action}
   */
  loginCheck(): Action {
    const token = localStorage.getItem(AUTH_TOKEN_NAME);
    const decodedToken = AppUtils.jwtDecodeToken(token);
    if (decodedToken) {
      return {
        type: AUTH_ACTION_TYPES.LOGIN_CHECK_OK,
      }
    } else {
      return {
        type: AUTH_ACTION_TYPES.LOGIN_CHECK_FAILED,
      }
    }
  }

  /**
   * Sets or clears whether we're waiting on user to login.
   *
   * @param {boolean} waiting
   * @returns {Action}
   */
  loginWaitingOnUser(waiting: boolean): ActionWithPayload<any> {
    return {
      type: AUTH_ACTION_TYPES.LOGIN_WAITING_ON_USER,
      payload: waiting
    };
  }

  /**
   * Log out action.
   *
   * @returns {Action}
   */
  logout(routerCommands?: Array<any>, routerOptions?: NavigationExtras): ActionWithPayload<any> {
    // send out logged out message
    return {
      type: AUTH_ACTION_TYPES.LOGOUT,
      payload: { routerCommands: routerCommands || [ROUTER_LINKS.LOGIN], routerOptions: routerOptions || {} }
    };
  }

  logoutSuccess(): Action {
    return {
      type: AUTH_ACTION_TYPES.LOGOUT_SUCCESS
    };
  }

  /**
   * Starts updating the user, and logs the user in if requested.
   *
   * @param {Profile} profile
   * @param {boolean} loginAfter?
   * @returns {Action}
   */
  updateUser(profile: IUser, loginAfter?: boolean): ActionWithPayload<any> {
    return {
      type: AUTH_ACTION_TYPES.UPDATE_USER,
      payload: { profile: profile, loginAfter: loginAfter }
    };
  }

  /**
   * Successfully obtained the user after the db operation.
   *
   * @param {any} response
   * @returns {Action}
   */
  crudUserSuccess(response: any): ActionWithPayload<any> {
    return {
      type: AUTH_ACTION_TYPES.CRUD_USER_SUCCESS,
      payload: new Profile(response)
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: AUTH_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  sudoLogin(userId?: number, redirect?: string): ActionWithPayload<any> {
    return {
      type: AUTH_ACTION_TYPES.SUDO_LOGIN,
      payload: { userId, redirect }
    };
  }

  setSudoPermission(canSudo: boolean): ActionWithPayload<boolean> {
    return {
      type: AUTH_ACTION_TYPES.SET_SUDO_PERMISSION,
      payload: canSudo
    };
  }

  /**
   * About to sudo in as another user.
   */
  setSudoLogin(sudo: boolean): ActionWithPayload<boolean> {
    return {
      type: AUTH_ACTION_TYPES.SET_SUDO_LOGIN,
      payload: sudo,
    };
  }

  /**
   * Fetches logged in user's messages.
   */
  refreshUserMessages(messageType: string = 'unread'): ActionWithPayload<any> {
    return {
      type: AUTH_ACTION_TYPES.REFRESH_USER_MESSAGES,
      payload: messageType
    };
  }

  /**
   * Successfully fetched logged in user's messages.
   */
  refreshUserMessagesSuccess(messageType: string, response: Array<any>): ActionWithPayload<any> {
    const messages = [];
    response.forEach(msg => {
      if (msg.message_text) {
        msg.message = {
          body: msg.message_text,
          subject: msg.message_title
        };
        messages.push((new UserMessage(msg)).iObject<Model.UserMessage>());
      } else if (msg.message) {
        // filter out emails without a template
        messages.push((new UserMessage(msg)).iObject<Model.UserMessage>());
      }
    });

    return {
      type: AUTH_ACTION_TYPES.REFRESH_USER_MESSAGES_SUCCESS,
      payload: { messageType, messages },
    };
  }

  /**
   * Marks one or more user messages.
   * @param mark
   */

  markUserMessages(mark: {markType: string, value: boolean, messages?: Array<Model.UserMessage>}): ActionWithPayload<any> {
    return {
      type: AUTH_ACTION_TYPES.MARK_USER_MESSAGES,
      payload: mark,
    };
  }

  /**
   * Successfully marked one ore more user messages.
   * @param mark
   */
  markUserMessagesSuccess(mark: {markType: string, messages?: Array<Model.UserMessage>}): ActionWithPayload<any> {
    return {
      type: AUTH_ACTION_TYPES.MARK_USER_MESSAGES_SUCCESS,
      payload: mark
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Auth = new AuthActions();

/**
 * Adds authorization actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Auth: AuthActions;
  }
}
