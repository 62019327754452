<app-project-contact-role-item *ngFor="let c of contacts; let i = index"
  [proposalId]="context.proposal_id"
  contactIndex="{{contactIndex}}_{{i}}"
  [contactType]="typeName"
  [canEdit]="canEdit"
  [firstTouch]="firstTouch"
  [fundKey]="context.fund_key"
  [fundId]="context.fund_id"
  [contact]="c"
  [contactDetails]="c.pc.user"
  [designeeDetails]="c.dc.user"
  [inviter]="context.inviter"
  (associateContact)="onAssociateContact($event, i)"
  (removeContact)="onRemoveContact($event)">
</app-project-contact-role-item>

<div class="btn-section btn-section--center" *ngIf="type.multi">
  <button type="button" class="action-button action-button--primary center icon--left js-add-new-contact"
  (click)="addOldContact()" id="qa_add_alternate_contact_button">
    <icon iconId="plus"></icon>
    Add {{title}}
  </button>
</div>