<form [formGroup]="form" autocomplete="off" [ngClass]="{'first-touch': !isRequired}">
    <div class="card">
      <div class="card-title">
        <h3 *ngIf="!isRequired">Story: {{successStory.title || "Title"}}</h3>
        <h3 [ngClass]="{required: canEdit}" *ngIf="isRequired">Story: {{successStory.title || "Title"}}</h3>
      </div>

      <input-field labelText="Title" [canEdit]="canEdit">
        <input inputRef id="qa_success_{{successStory.id}}_title" type="text" formControlName="title" (blur)="persistValue('title')" [attr.aria-required]="isRequired" [required]="isRequired && !canEdit"/>
      </input-field>

      <label for="contactsList" [attr.aria-required]="isRequired && canEdit" [ngClass]="{required: isRequired && canEdit}">Follow-up Contact Persons(s)</label>
      <div id="qa_successs_{{successStory.id}}_contacts">
        <p class="empty-state" *ngIf="!contactsList.length" [attr.data-invalid]="isRequired ? true : false">
          No contacts assigned
        </p>
        <table class="po-table po-table--fixed po-table--sticky-header datatable" *ngIf="contactsList.length">
          <thead>
          <tr>
            <th scope="col" class="col-16-rem">Name</th>
            <th scope="col">Title</th>
            <th scope="col">Email</th>
            <th scope="col" class="col-10-rem">Phone</th>
            <th scope="col" class="align-center col-4-rem" *ngIf="canEdit">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let contact of contactsList; let i=index">
                <td>
                  <app-user-actions
                    [count]="i"
                    [menuText]="contact.first_name + ' ' + contact.last_name"
                    [userData]="contact"
                    class="contacts__contact__options">
                  </app-user-actions>
                </td>
                <td>{{contact.title}}</td>
                <td>{{contact.email_address}}</td>
                <td>{{contact.phone | phone}}</td>
                <td class="align-center" *ngIf="canEdit">
                  <button (click)="deleteUser(contact.id)"
                    class="action-button action-button--secondary icon--left"
                    type="button">
                    <icon iconId="delete"></icon>
                  </button>
                </td>
          </tr>
          </tbody>
        </table>
        <div class="btn-section btn-section--center">
          <button class="action-button icon--left action-button--small action-button--secondary secondary"
            (click)="newContact()" *ngIf="canEdit">
            <icon iconId="plus"></icon>
            Add Responsible Person
          </button>
        </div>

        <div *ngIf="showAddContact">
          <p><a (click)="showInviteModal=true" class="text-link">Can't find the contact you're looking for?</a></p>
          <form [formGroup]="contactForm">
              <po-select
                id="qa_contacts_responsible_person_select"
                [canEdit]="canEdit"
                labelText="Contact Name"
                [options]="contactsLookupFormatted"
                [isSearch]="true"
                [selectMultiple]="false"
                [disabledOptions]="disabledOptions"
                placeholderText="Enter name or email"
                (search)="updateContactsList($event, 50)"
                (optionSelected)="confirmContactModal()">
                <select inputRef class="po-select__select" formControlName="contactId" aria-required="true"></select>
              </po-select>
          </form>
        </div>
      </div>

      <input-field [showCounter]="false" labelText="Barrier(s)" [canEdit]="canEdit">
        <po-html-textarea inputRef id="qa_successs_{{successStory.id}}_challenge" formControlName="challenge"
          [htmlCharLimit]="med_text_length"
          (change)="persistValue('challenge')"
          [attr.aria-required]="isRequired"
          [required]="isRequired && canEdit"
        ></po-html-textarea>
      </input-field>

      <input-field [showCounter]="false" labelText="Success Story" [canEdit]="canEdit">
        <po-html-textarea inputRef id="successs_{{successStory.id}}_story" formControlName="description"
          [htmlCharLimit]="long_text_length"
          (change)="persistValue('description')"
          [attr.aria-required]="isRequired"
          [required]="isRequired && canEdit"
        ></po-html-textarea>
      </input-field>

      <input-field [showCounter]="false" labelText="Outcomes" [canEdit]="canEdit">
        <po-html-textarea inputRef id="successs_{{successStory.id}}_outcomes" formControlName="outcomes"
          [htmlCharLimit]="med_text_length"
          (change)="persistValue('outcomes')"
          [attr.aria-required]="isRequired"
          [required]="isRequired && canEdit"
        ></po-html-textarea>
      </input-field>

      <ng-container *ngIf="collectGoals">
        <input-group headingText="Vision for Success Goals"
          [attr.aria-required]="isRequired && canEdit"
          formGroupName="goals" [control]="form.get('goals')"
          [required]="isRequired && canEdit">
          <div class="help-text" *ngIf="canEdit">
            Please select the goals that apply to this success story.
          </div>
          <radio-check *ngFor="let goal of goalsList" [labelText]="goal.value"  [canEdit]="canEdit" readOnlyType="checked-unchecked"> <!-- type: { control: number, value: string } -->
            <input inputRef type="checkbox" class="checkbox" [formControlName]="goal.id" (change)="clickVFSGoal(goal)" id="qa_successs_{{successStory.id}}_goal_{{goal.id}}">
          </radio-check>
        </input-group>
      </ng-container>

      <div class="card-footer card-footer--right">
        <button class="action-button action-button--small action-button--secondary secondary icon--left"
          (click)="toggleDeleteWarning()" *ngIf="canDelete && canEdit">
          <icon iconId="delete" data-qa="delete-icon"></icon>Remove
        </button>
      </div>
    </div>
  </form>

  <app-invite-user *ngIf="showInviteModal"
    [fundKey]="programKey"
    [proposalId]="proposal.id"
    (cancelAction)="showInviteModal=false"
    (closeAction)="showInviteModal=false"
    (inviteSuccess)="inviteSuccess($event)"
    [description]="'Invite a new user to NOVA and add them as a contact for this success story.'">
  </app-invite-user>

  <alert *ngIf="showDeleteWarning"
    button-action="Delete"
    button-dismiss="Cancel"
    level="warning"
    (action)="removeClicked()"
    (dismiss)="toggleDeleteWarning()">
    <ng-container [ngSwitch]="!!successStory.title">
      <p *ngSwitchCase="true">
        Are you sure you want to delete the story <strong>{{successStory.title}}</strong>?
      </p>
      <p *ngSwitchCase="false">
        Are you sure you want to delete this story?
      </p>
    </ng-container>
</alert>
