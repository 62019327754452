import { Model } from '@app-ngrx-domains';
import { DEFAULT_STEP_OPTIONS, WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';

export const RSI_WORKFLOW_CONFIG_v1: Model.Workflow = {
  name: WORKFLOW_TYPES.RSI,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.ALLOCATIONS,
      title: 'Prior Year Allocations',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.HOURLY_RATE,
      title: 'Hourly Base Rate',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.COLLEGES,
      title: 'Colleges',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.LEAS,
      title: 'LEAs',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    },
  ]
};

export const RSI_WORKFLOW_CONFIG_v2: Model.Workflow = {
  name: WORKFLOW_TYPES.RSI,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.ALLOCATIONS,
      title: 'Prior Year Allocations',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.HOURLY_RATE,
      title: 'Hourly Base Rate',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.COLLEGES,
      title: 'Colleges',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.LEAS,
      title: 'LEAs',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.GUIDANCE,
      title: 'Guidance',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview & Submit',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    },
  ]
};
