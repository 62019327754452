import { AfterContentInit, OnDestroy, Component, Input, HostBinding, ChangeDetectorRef } from '@angular/core';
import { AbstractControlDirective } from '@angular/forms';
import { Logger } from '../../../app-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommentService } from '@app/core/services';
import { ElementRef } from '@angular/core';
import { State, Queries } from '@app-ngrx-domains';
import { Store } from '@ngrx/store';
import { InputRefDirective } from '@app/shared.generic/directives';
import { ContentChild, ContentChildren, forwardRef, QueryList } from '@angular/core';
@Component({
  selector: 'input-group',
  templateUrl: './input-group.component.html'
})
export class InputGroupComponent implements AfterContentInit, OnDestroy {

  @HostBinding('attr.data-invalid') invalidState = false;
  @Input() headingText: string;
  @Input() errorsOnUntouched = false;
  @Input() legendHidden = false;
  @Input() required = false;
  @Input() control: AbstractControlDirective;
  @Input() inline: boolean = false;

  @ContentChild(InputRefDirective, { static: true }) input: InputRefDirective;
  @ContentChildren(forwardRef(() => InputRefDirective), { descendants: true }) multiInputs: QueryList<InputRefDirective>;
  private checkboxes: Array<InputRefDirective>;
  private radios: Array<InputRefDirective>;
  inputGroupId: string = undefined;

  invalid: boolean = false;

  private destroy$: Subject<boolean> = new Subject();
  private hostElement: HTMLInputElement;

  isCommentsOpen: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private commentService: CommentService,
    private store: Store,
  ) {
    this.hostElement = this.el.nativeElement
  }

  ngAfterContentInit() {
    if (!this.headingText) {
      Logger.error('Legend text required on input group component:');
      Logger.log(this.control);
    }

    if (this.control) {
      this.checkValidation();
      this.control.statusChanges.pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.checkValidation();
      });
    }

    this.store.select(Queries.Layout.isCommentsOpen).pipe(
      takeUntil(this.destroy$)
    ).subscribe(isCommentsOpen => {
      this.isCommentsOpen = !!isCommentsOpen;
    });

    this.setInputGroupId();
  }

  isRequired() {
    return this.required;
  }

  showErrors() {
    return this.control && this.required;
  }

  private checkValidation() {
    // SetTimeout to avoid expressionChangedAfterChecked error
    setTimeout(() => {
      this.invalidState = !!this.control.errors;
      this.cdr.markForCheck();
    }, 0);
  }

  private setInputGroupId() {
    if (this.hostElement.id) {
      this.inputGroupId = this.hostElement.id
    } else if (this.input && this.input.element.id) {
      // Standard, single-value input
      this.inputGroupId = this.input.element.id + '_input_group';
    } else if (this.multiInputs) {
      for (const inputRef of this.multiInputs) {
        if (inputRef.element.id) {
          this.inputGroupId = inputRef.element.id + '_input_group';
          break;
        }
      }
    }
  }

  /**
   *
   */
  handleFocusIn() {
    if (this.isCommentsOpen) {
      this.commentService.setShowCommentWidget({id: this.inputGroupId, type: 'input-group', labelText: this.headingText});
    }
  }

  /**
   *
   */
  handleFocusOut() {
    if (this.isCommentsOpen) {
      this.commentService.setHideCommentWidget({id: this.inputGroupId, type: 'input-group', labelText: this.headingText});
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
