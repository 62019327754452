import { Type } from '@angular/core';
import { ModelBase } from './model.base';
import { Model } from '@app-ngrx-domains';
import { WORKFLOW_STEPS } from '../consts';

/**
 * Workflow steps state data model
 */
export interface IWorkflowStep {
  route: string;
  title?: string;
  description?: string;
  valid?: boolean;
  validLabel?: string;
  modifiedFields?: Model.ModifiedFields | Array<Model.ModifiedFields>;
  showStatus?: boolean;
  firstTouch?: boolean;
  hide?: boolean;
  edited?: boolean;
  disabled?: boolean;
  optional?: boolean;
  separator?: boolean;
  routerLink?: string;
  validator?(data: any, extras?: any): boolean;
  summaryBuilder?(data: any, extras?: any): Model.ModifiedFields | Array<Model.ModifiedFields>;
  scoreCard?: Model.ConfigScoreCard;
  component?: Type<any>;
  subScores?: Array<Model.ConfigScoreCard>;
  workflow?: INestedWorkflow;
  steps?: Array<IWorkflowStep>;
  custom?: boolean;
  versionRestricted?: boolean;  // Only show this step for specific versions
  extras?: any;
}

export interface IModifiedFields {
  [fieldName: string]: {
    label: string,
    originalValue?: any,
    description?: string,
    requireUnsubmit?: boolean,
    hideFromSummary?: boolean
  }
}

export interface IWorkflow {
  name: string;
  baseLink: string;
  show: boolean,
  steps: Array<IWorkflowStep>,
  currentStep?: string;
  isReview?: boolean;
  title?: string;
  templateName?: string;
  parent?: {
    name: string,
    title: string,
    route: string,
    backRoute?: string
  }
}

export interface IWorkflowValidator {
  validate(step: string): void,
}

export interface IWorkflowComponent {
  path: string;
  component: Type<any>;
}

export interface INestedWorkflow {
  name: string;
  templateName: string;
  static: boolean;
  route?: string;
  section?: boolean;
  empty?: string;
}

export interface IWorkflowStatuses {
  [workflowName: string]: Array<IWorkflowStep>;
}

export class Workflow extends ModelBase implements IWorkflow {
  public name: string;
  public baseLink: string;
  public show: boolean;
  public steps: Array<IWorkflowStep>;
  public currentStep: string;

  constructor(raw: any) {
    super();
    if (raw) {
      this.baseLink = raw.baseLink;
      this.show = raw.show;
      this.steps = raw.steps || [];
      this.currentStep = raw.currentStep;
    }
  }

  /**
   * Returns true if workflow steps are all valid.
   * @static
   * @param {IWorkflow} workflow
   * @returns {boolean}
   */
  static isValid(workflow: IWorkflow): boolean {
    if (!workflow || workflow.steps.length <= 0) {
      // no steps to validate.
      return false;
    }

    const valid = workflow.steps.every(step => step.hide || !step.showStatus || step.valid);
    return valid;
  }

  /**
   * Returns score card config settings from the workflow steps.
   * @param workflow_steps
   * @param step_name
   */
  static getScoreCardConfig(workflow_steps: Array<IWorkflowStep>, step_name: string): Model.ConfigScoreCard {
    const match = (workflow_steps || []).find(step => step.route === step_name);
    if (match) {
      return match.scoreCard;
    }
  }

  /**
   * Returns list of steps that are reviewable.
   * @param workflow_steps
   */
  static getReviewableSteps(workflow_steps: Array<IWorkflowStep>): Array<string> {
    const steps = [];
    (workflow_steps || []).forEach(step => {
      if (!step.hide && ![WORKFLOW_STEPS.CONCLUSION, WORKFLOW_STEPS.PREVIEW].includes(step.route)) {
        steps.push(step.route);
      }
    });

    return steps;
  }

  /**
   * Returns sub-section scoring config from the workflow steps.
   * @param workflow_steps
   * @param step_name
   */
  static getSubScoringConfig(workflow_steps: Array<IWorkflowStep>, step_name: string): Array<Model.ConfigScoreCard> {
    const match = (workflow_steps || []).find(step => step.route === step_name);
    if (match) {
      return match.subScores;
    }
  }

  /**
   * Returns true if step belonging to the workflow.
   * @param workflow
   * @param stepName
   */
  static stepExists(workflow: IWorkflow, stepName: string): boolean {
    if (!!workflow && workflow.steps.length > 0) {
      const step = workflow.steps.find(s => s.route === stepName);
      return !!step;
    }
  }

  /**
   * Returns the workflow step's title.
   * @param workflow
   * @param stepName
   */
  static getStepTitle(workflow: IWorkflow, stepName: string): string {
    if (!!workflow && workflow.steps.length > 0) {
      const step = workflow.steps.find(s => s.route === stepName);
      return `${workflow.title}: ${!!step && !!step.title ? step.title : stepName}`;
    }
  }

  /**
   * Returns workflow step's full route link.
   * @param workflow
   * @param stepName
   */
  static getStepLink(workflow: IWorkflow, stepName: string): string {
    if (!!workflow && workflow.steps.length > 0) {
      const step = workflow.steps.find(s => s.route === stepName);
      return !!step ? `${workflow.baseLink}/${step.routerLink}` : undefined;
    }
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ModifiedFields = IModifiedFields;
    export type WorkflowStep = IWorkflowStep;
    export type Workflow = IWorkflow;
    export type WorkflowValidator = IWorkflowValidator;
    export type WorkflowComponent = IWorkflowComponent;
    export type NestedWorkflow = INestedWorkflow;
    export type WorkflowStatuses = IWorkflowStatuses;
  }
}
