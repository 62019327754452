<div #dropdownHeader class="options-menu append-to-parent">
  <button type="button" id="{{ buttonId }}"
    class="action-button action-button--small action-button--secondary options-menu__trigger margin-left-xxs {{ buttonClass }}"
    (click)="toggleOptions()"
    (keydown)="handleKeydown($event)">
    <ng-content select="[slot=label]"></ng-content>
    <icon [iconId]="iconId" class="icon--more"></icon>
  </button>
  <ul #dropdownMenu *ngIf="showOptions" class="options-menu__menu {{ listClass }}" id="{{ menuId }}"
    (click)="toggleOptions()">
    <ng-content></ng-content>
  </ul>
</div>