<a routerLink="../" class="margin-bottom action-button action-button--secondary action-button--small icon--left">
  <icon iconId="arrow-back"></icon>
  Back to Project
</a>

<div class="card">
  <div class="card-title">
    <h3>Project Timeline</h3>
  </div>

  <ul *ngIf="tasks?.length; else loading" class="history-list">
    <li *ngFor="let task of displayedTasks; trackBy: trackById; let i=index" class="history-list__item">
      <app-project-history-item @slideInOut
        [count]="i"
        [task]="task"
        [files]="filesForTask(task.id)"
        [showCompareLink]="isArchiveAvailable(task.id)">
      </app-project-history-item>
    </li>
  </ul>

  <div class="card-footer card-footer--center">
    <button *ngIf="!showingAll" (click)="showAll()"
      class="action-button action-button--primary action-button--small">
      Show More
    </button>
  </div>
</div>

<!-- TODO: Make this look better -->
<ng-template #loading>
  <p class="message">Loading... <span class="loader-spinner-inline"></span></p>
</ng-template>