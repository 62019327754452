import base from './base.json';

export const environment = {
  ...base,
  name: 'prod',
  production: true,
  apiHostUrl: 'https://service.nova.cccco.edu',
  logger: { level: 'ERROR' },
  froalaKey: 'qc1H2pB1C4B2D6C7E6owvutwA2F-11F-11iaG3C2A5A4C4E3A2D4E2I2==', // v3.0
  wip_features: {
    grant_edits: true,
    calendar: true,
    google_calendar: false,
    k12_fiscal_report_restriction: false,
    swp_fiscal_report_restriction: false,
  },
  disableSentry: false,
  helpdeskApiUrl: 'https://helpdesk-api.nova.cccco.edu'
};
