<div class="card card--elevated" [ngClass]="{ 'sticky-card sticky-card--no-overlay sticky-card--top-pad': sticky }">
  <div *ngIf="header" class="card-title">
    <h4>{{header}}</h4>
    <div *ngIf="helpText" class="help-text">
      <p [innerHtml]="helpText"></p>
    </div>
  </div>

  <ol *ngIf="data?.length; else empty" cdkDropList cdkDropListLockAxis="y"
    [cdkDropListDisabled]="dragDropDisabled(data)"
    (cdkDropListDropped)="reorderItems(data, $event)"
    [cdkDropListSortPredicate]="isMoveable"
    class="list--plain tree-view">

    <form [formGroup]="form">
      <radio-check *ngIf="canEdit" labelText="Accessibility Mode">
        <input inputRef id="reorderList_accessibility_mode_check" type="checkbox" class="checkbox" formControlName="accessibleMode" (change)="toggleAccessibilityMode()">
      </radio-check>
    </form>

    <ng-container *ngFor="let row of data; let index = index; let last = last">
      <div cdkDrag [cdkDragData]="row" [cdkDragDisabled]="row.locked">
        <li class="flex flex--align-items-center text--small">
          <ng-container *ngIf="canEdit && data.length > 1">
            <ng-container *ngIf="useAccessibilityMode" [ngTemplateOutlet]="nudgeButtons" [ngTemplateOutletContext]="{ items: data, index: index, row: row }"></ng-container>
            <icon *ngIf="!useAccessibilityMode && !row.locked" title="Drag" cdkDragHandle iconId="dragdrop" class="icon--vertical-align margin-right-sm"></icon>
          </ng-container>
          <span class="h5 margin-bottom-none margin-right-xs">{{row.title}}</span>{{row.subTitle}}
        </li>

        <div *ngFor="let subSection of (row.subSections || []);" class="tree-view__subitems">
          <span class="bold">{{subSection.sectionTitle}}</span>
          <ol cdkDropList cdkDropListLockAxis="y" [cdkDropListDisabled]="dragDropDisabled(subSection.items)" (cdkDropListDropped)="reorderItems(subSection.items, $event, subSection.sectionId)" class="list--plain card list-filter__container padding--sm">
            <ng-container *ngFor="let item of subSection.items; let subIndex = index let subLast = last">
              <li cdkDrag class="flex flex--align-items-center text--small">
                <ng-container *ngIf="canEdit && subSection.items.length > 1">
                  <ng-container *ngIf="useAccessibilityMode" [ngTemplateOutlet]="nudgeButtons" [ngTemplateOutletContext]="{ items: subSection.items, index: subIndex, row: row, subSectionId: subSection.sectionId }"></ng-container>
                  <icon *ngIf="!useAccessibilityMode" title="Drag" cdkDragHandle iconId="dragdrop" class="icon--vertical-align margin-right-sm"></icon>
                </ng-container>
                <span class="h5 margin-bottom-none margin-right-xs">{{item.title}}</span>{{item.subTitle}}
              </li>
              <hr *ngIf="!subLast" class="margin-xs">
            </ng-container>
          </ol>
        </div>
      </div>

      <hr *ngIf="!last" class="margin-xs">
    </ng-container>
  </ol>
</div>

<ng-template #nudgeButtons let-items="items" let-index="index" let-row="row" let-subSectionId="subSectionId">
  <div *ngIf="!row.locked">
    <div class="columns columns--gap-none no-margin">
      <button *ngIf="index > 0 && !items[index - 1].locked" tabindex=0
        class="action-button action-button--tertiary margin-none margin-right-xs padding-none btn--small"
        (click)="reorderItems(items, { previousIndex: index, currentIndex: index - 1 }, subSectionId)">
        <icon iconId="arrow-up" class="icon--small"></icon>
      </button>
      <button *ngIf="index < items.length - 1 && !items[index + 1].locked" tabindex=0
        class="action-button action-button--tertiary margin-none margin-right-xs padding-none btn--small"
        (click)="reorderItems(items, { previousIndex: index, currentIndex: index + 1 }, subSectionId)">
        <icon iconId="arrow-down" class="icon--small"></icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #empty>
  <p class="empty-state">Empty</p>
</ng-template>