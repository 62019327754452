import { Component, OnChanges, Input, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from '@app-services';
import { Model } from '@app-ngrx-domains';
import { Profile } from '@app-models';


@Component({
  selector: 'app-non-task-approval-card',
  templateUrl: './non-task-approval-card.component.html'
})
export class NonTaskApprovalCardComponent implements OnChanges {
  @ViewChild('rejectButtonTmpl', {static: true}) rejectButtonTmpl: TemplateRef<any>;
  @ViewChild('completeButtonTmpl', {static: true}) completeButtonTmpl: TemplateRef<any>;

  @Input() headerText: string;
  @Input() users: Model.User[];
  @Input() currentUser: Model.User;
  @Input() performedBy: any;
  @Input() approvalDate: string = ''; // Date of when approve action was taken

  @Input() allowed = false; // Should be based on the permissions of the current user
  @Input() isSubmittal = false; // Change display logic to use 'Submit' verbs rather than 'Approve'
  @Input() useCertify = false; // Use the word 'Certify' instead of 'Approve' (GP)
  @Input() hideReminder = false; // Hide the 'Send Reminder' button
  @Input() completed = false;
  @Input() reminderOptions: Model.ProposalReminder; // reminder options to use to send out reminder.

  @Output() approve: EventEmitter<any> = new EventEmitter<any>();
  @Output() reject: EventEmitter<any> = new EventEmitter<any>();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @Input() rejectButtonText: string = 'Reject'; // To just replace the text of the 'Reject' button
  @Input() rejectButton: TemplateRef<any>; // To replace the text and/or functionality of the button
  @Input() completeButton: TemplateRef<any>; // To replace the text and/or functionality of the button
  @Input() replaceStates: any;
  @Input() notifySuccess: boolean;

  states: { [name: string]: { class: string, action?: string, done?: string, await?: string } } = {
    submitted: { class: 'certification-card--approved', action: 'Submit', done: 'Submitted', await: 'Submittal' },
    approved:  { class: 'certification-card--approved', action: 'Approve', done: 'Approved', await: 'Approval' },
    rejected:  { class: 'certification-card--rejected', action: 'Reject', done: 'Rejected' },
    waiting:   { class: 'certification-card--waiting' },
    disabled:  { class: 'certification-card--disabled' }
  };

  cardState = {
    text_value: '',
    class_value: this.states.disabled.class
  };

  private reminderButtonStatus: string;
  private performedByText = '';
  private initialized = false;

  constructor(
    private apiService: ApiService
  ) { }

  oneTimeInit() {
    // Replace templates if needed.
    if (!this.rejectButton) {
      // assign default template.
      this.rejectButton = this.rejectButtonTmpl;
    }
    if (!this.completeButton) {
      // assign default template.
      this.completeButton = this.completeButtonTmpl;
    }

    // Update texts.
    if (this.useCertify) {
      this.states.approved = {
        ...this.states.approved,
        action: 'Certify',
        done: 'Certified',
        await: 'Certification'
      };
    }

    if (this.replaceStates) {
      Object.keys(this.replaceStates).forEach(name => {
        if (this.states[name]) {
          this.states[name] = {
            ...this.states[name],
            ...this.replaceStates[name],
          }
        }
      })
    }
  }

  ngOnChanges() {
    this.initialize();
  }

  initialize() {
    if (this.completed && this.performedBy) {
      if (!this.performedBy.first_name && !this.performedBy.last_name) {
        this.getUserById();
      } else {
        this.performedByText = ` by ${this.performedBy.first_name} ${this.performedBy.last_name}`;
      }
    }

    if (!this.initialized) {
      this.oneTimeInit();
      this.initialized = true;
    }

    this.setCardState();
  }

  async getUserById() {
    this.performedBy = await this.apiService.getProfileById(this.performedBy).toPromise();
    this.performedByText = ` by ${this.performedBy.first_name} ${this.performedBy.last_name}`;
    this.setCardState();
  }

  setCardState() {
    if (this.completed) {
      this.cardState.class_value = this.states.approved.class;
      this.cardState.text_value = (this.isSubmittal
        ? this.states.submitted.done
        : this.states.approved.done) + this.performedByText;
    } else {
      this.cardState.class_value = this.states.waiting.class;
      this.cardState.text_value = this.awaitingText;
    }
  }

  get awaitingText() {
    return (this.isSubmittal)
      ? `Awaiting ${this.states.submitted.await}`
      : `Awaiting ${this.states.approved.await}`;
  }

  getUserFullName(user: Model.User) {
    return Profile.contactFullName(user);
  }

  get completeVerb() {
    return this.isSubmittal ? this.states.submitted.action : this.states.approved.action;
  }

  completeTask(rejected: boolean) {
    rejected ? this.reject.emit() : this.approve.emit();
  }

  get canApprove(): boolean {
    return this.allowed && (!!this.users && !!this.users.find(u => this.currentUser.id === u.id));
  }

  get canRemind() {
    return !this.completed
      && !this.hideReminder
      && this.users && this.users.length && !this.users.find(u => this.currentUser.id === u.id);
  }

  sendReminder() {
    this.notify.emit();
  }

  get reminderText() {
    switch (this.reminderButtonStatus) {
      case 'loading': return 'Sending Reminder...';
      case 'success': return 'Reminder Sent';
      case 'fail': return 'Failure, please try again';
      default: return 'Send Reminder';
    }
  }

  get isSending() {
    return this.reminderButtonStatus === 'loading';
  }

  get isSuccess() {
    return this.reminderButtonStatus === 'success';
  }

  get isFailure() {
    return this.reminderButtonStatus === 'fail';
  }
}
