import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-budget-forecast',
  templateUrl: 'budget-forecast.component.html'
})
export class BudgetForecastComponent implements OnInit {
  @Input() canEdit = false;
  @Input() firstTouch = false;
  @Input() isPreview = false;
  @Input() isRequired = true;
  @Input() fixedQ4 = false; // Locks Q4 amount to 100%
  @Input() durationString: string; // Used in the label
  @Input() count = ''; // To create unique ids
  @Input() helpText = `Forecast the year-to-date spending by entering the cumulative percentages for each quarter.
    For example: 25% for Q1, 50% for Q2, 75% for Q3, and 100% for Q4.`;
  @Input() quarter_1: number;
  @Input() quarter_2: number;
  @Input() quarter_3: number;
  @Input() quarter_4: number;
  @Input() set totalAmount(amount: number) {
    this._totalAmount = amount;
  }
  get totalAmount() {
    return this._totalAmount;
  }

  private _totalAmount: number;
  private _canEdit: boolean;

  @Output() updateQuarter: EventEmitter<{ quarter: string, value: number}> = new EventEmitter();

  form: FormGroup;
  quarters = ['quarter_1', 'quarter_2', 'quarter_3', 'quarter_4'];
  cumulativeValidator = (group) => {
    let err = null;
    this.quarters.map(q => group.controls[q].value).reduce((last, curr) => {
      const value = Number(curr);
      if (last > value) {
        err = { validationError: 'Enter forecast amounts as cumulative percentages.' };
      }
      return value;
    }, 0);
    return err;
  }

  constructor(
    private _fb: FormBuilder
  ) {}

  ngOnInit() {
    const validators = [Validators.min(0), Validators.max(100)];
    if (this.isRequired) {
      validators.push(Validators.required);
    }

    const formControls = this.quarters.reduce((controls, quarter) => {
      controls[quarter] = this.isFixedQuarter(quarter)
        ? [this[quarter] || 100]
        : [this[quarter], validators];
      return controls;
    }, {});

    this.form = this._fb.group(formControls, { validator: this.cumulativeValidator });

    if (this.fixedQ4 && this.quarter_4 !== 100) {
      this.emitUpdate('quarter_4');
    }
  }

  emitUpdate(quarter: string) {
    const value = this.form.get(quarter).value;
    this.updateQuarter.emit({ quarter, value });
  }

  isControlInvalid(quarter: string) {
    return this.form.controls[quarter].invalid;
  }

  getDollarAmount(quarter: string) {
    const percentage = this.form.get(quarter).value / 100;
    return this.totalAmount * percentage || 0;
  }

  edittableQuarter(quarter: string) {
    if (this.isFixedQuarter(quarter)) {
      return false;
    } else {
      return this.canEdit;
    }
  }

  private isFixedQuarter(quarter: string) {
    return quarter === 'quarter_4' && this.fixedQ4;
  }

  get headingText() {
    return `${this.durationString ? this.durationString + ' ' : ''} Cumulative Quarterly Expenditure Forecast`;
  }
}
