<div *ngIf="!hideCard" class="certification-card">
  <header class="certification-card__header">
    <h4 class="certification-card__title">{{headerText}}</h4>
  </header>
  <div class="certification-card__main">
    <div *ngFor="let user of users;let i=index" class="mb-s">
      <ul class="certification-card__contact-info">
        <li class="certification-card__contact-info-item certification-card__contact-name">
          <app-user-actions
            [count]="i"
            [menuText]="getUserFullName(user)"
            [userData]="user"
            class="contacts__contact__options">
          </app-user-actions>
          <span *ngIf="user.designee"> (Designee)</span>
        </li>
        <li *ngIf="user.title" class="certification-card__contact-info-item certification-card__contact-title">{{user.title}}</li>
        <li *ngIf="user.email_address" class="certification-card__contact-info-item">
          <a href="mailto:{{user.email_address}}" class="text-link" data-qa="qa_sep_certifier_email">{{user.email_address}}</a>
        </li>
        <li *ngIf="user.phone" class="certification-card__contact-info-item">
          {{ user.phone | phone }} {{ user.phone_extension | phone_extension }}
        </li>
      </ul>
    </div>
    <p *ngIf="!users?.length" class="empty-state">No users have been assigned to this role.</p>
  </div>

  <footer class="certification-card__footer" [ngClass]="cardState.class_value">
    <ng-container *ngIf="cardState.footerTemplate" [ngTemplateOutlet]="cardState.footerTemplate"></ng-container>
    <p *ngIf="!completed" class="certification-card__approval-status" data-qa="qa_approval_state">{{ cardState.text_value }}</p>
  </footer>
</div>

<ng-template #completedTemplate>
  <div class="certification-card__approved-heading certification-card__approval-heading">
    {{ cardState.text_value }}
  </div>

  <p class="certification-card__approval-status" data-qa="qa_approval_date">
    {{ approvalDate | po_date:'dateTime12HoursTZ' }}
  </p>
</ng-template>

<ng-template #actionButtonsTemplate>
  <ng-container [ngTemplateOutlet]="rejectButton"></ng-container>
  <ng-container [ngTemplateOutlet]="completeButton"></ng-container>
</ng-template>

<ng-template #rejectButtonTemplate>
  <ng-container [ngTemplateOutlet]="rejectButton"></ng-container>
</ng-template>

<ng-template #reminderButtonTemplate>
  <button *ngIf="canRemind" class="action-button action-button--secondary action-button--loader certification-card__approval-button no-print"
    [disabled]="isSending" type="button" (click)="sendReminder()">
    {{reminderText}}
    <div *ngIf="isSending" class="loader-spinner-inline" [style.display]="'inline-block'"></div>
    <icon *ngIf="isFailure" iconId="alert" class="icon--block icon--right icon--negative"></icon>
  </button>

  <div *ngIf="isSuccess" class="status-message__message">
    <p class="status-message margin-sm" id="qa_status_message">{{reminderText}}
      <icon iconId="checkmark" class="icon--block icon--right icon--positive"></icon>
    </p>
  </div>
</ng-template>

<ng-template #rejectButtonTmpl>
  <button *ngIf="!isSubmittal" (click)="completeTask(true)" type="button" class="action-button action-button--secondary action-button--loader certification-card__approval-button" [disabled]="disableButton">
    {{ rejectButtonText }}
  </button>
</ng-template>

<ng-template #completeButtonTmpl>
  <button type="button" (click)="completeTask(false)" class="action-button action-button--primary action-button--loader certification-card__approval-button" [disabled]="disableButton">
    {{ completeVerb }}
  </button>
</ng-template>
