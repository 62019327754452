
import { trigger, style, transition, animate, group, query, animateChild } from '@angular/animations';

// Note: This is an array, so don't forget to use the spread operator (...) each time you use it
export const expandableTableAnimations = [
  // Need to animate row and cells at the same time
  trigger('collapsibleRow', [
    transition(':enter', [
      group([
        style({ height: 0, opacity: 0, 'overflow-y': 'hidden' }),
        animate('100ms ease-out', style({ height: '*', opacity: 1 })),
        query('@collapsibleCell', animateChild())
      ])
    ]),
    transition(':leave', [
      group([
        style({ height: '*', opacity: 1, 'overflow-y': 'hidden' }),
        animate('100ms ease-in', style({ height: 0, opacity: 0 })),
        query('@collapsibleCell', animateChild())
      ])
    ])
  ]),
  // Animations have to be added in a div within td because you can't change the height of tr and td elements
  trigger('collapsibleCell', [
    transition(':enter', [
      style({ height: 0, opacity: 0, 'overflow-y': 'hidden' }),
      animate('100ms ease-out', style({ height: '*', opacity: 1 }))
    ]),
    transition(':leave', [
      style({ height: '*', opacity: 1, 'overflow-y': 'hidden' }),
      animate('100ms ease-in', style({ height: 0, opacity: 0 }))
    ])
  ])
]