import { concat, EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { State, Queries, Actions as DomainActions } from '@app-ngrx-domains';
import * as Sentry from '@sentry/browser';

import { AREAS, CORE_ROUTES, ROUTER_LINKS } from '../consts';
import { toPayload } from '@app-libs';
import { ApiService } from '../services/api.service';
import { AUTH_ACTION_TYPES } from './auth.action';
import { AppUtils } from '../utilities';
import { PermissionsService } from '../services';
import { FundVersionGuard } from '../guards';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private permissionsService: PermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>,
    private verifyFund: FundVersionGuard
  ) { }

  /**
   * Listens for login action, then executes the related actions.
   */
   login$ = createEffect(() => this.actions$.pipe( // listen for the login action
    ofType(AUTH_ACTION_TYPES.LOGIN),
    map(toPayload),
    switchMap(payload => {
      // ask backend to authenticate user
      const queryParams = this.route.snapshot.queryParams;

      if (queryParams && queryParams.session) {
        const session_token = atob(decodeURI(queryParams.session))
        localStorage.setItem('auth_token', session_token)
      }

      if (!(payload.email && payload.password) && !localStorage.getItem('auth_token')) {
        return of(DomainActions.Auth.loginFail(new HttpErrorResponse({
          statusText: 'No credentials provided',
          error: { message: 'No credentials provided' }
        })));
      }

      return this.apiService.doLogin(payload.email, payload.password).pipe(
        mergeMap(user => {
          // Set user info with Sentry
          Sentry.configureScope((scope) => {
            scope.setUser({ id: user.id, email: user.email_address });
          });

          const observables = [];

          const decoded = AppUtils.jwtDecodeToken(user.session_token);
          if (decoded && decoded.type === 'sudo-session') {
            observables.push(DomainActions.Auth.setSudoLogin(true));
          }

          return concat([
            ...observables,
            DomainActions.Auth.loginSuccess(user),
            DomainActions.Auth.refreshUserMessages(),
          ]);
        }),
        // If request fails, dispatch failed action
        catchError(error => of(DomainActions.Auth.loginFail(error))
      ))
    })
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.LOGIN_SUCCESS),
    map(toPayload),
    switchMap(payload => {
      return this.permissionsService.canSudo(AREAS.USER_ADMIN, ROUTER_LINKS.ADMIN).pipe(
        mergeMap(canSudo => {
          return of(DomainActions.Auth.setSudoPermission(canSudo));
        })
      )
    })
  ));

  sudoLogin$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.SUDO_LOGIN),
    map(toPayload),
    switchMap(({ userId, redirect }) => {
      if (userId) {
        return this.apiService.sudoLoginUser(userId).pipe(
          mergeMap(sudoToken => {
            // Suspend current auth_token
            const authToken = localStorage.getItem('auth_token');
            localStorage.setItem('suspended_token', authToken);
            localStorage.removeItem('auth_token');

            // set sudo auth_token & refresh the app to login
            localStorage.setItem('auth_token', atob(decodeURI(sudoToken)));

            if (redirect) {
              this.router.navigate([redirect]).then(() => {
                window.location.reload();
              });
            } else {
              window.location.reload();
            }

            return EMPTY;
          }),
          catchError(error => {
            this.store.dispatch(DomainActions.App.showAlert('Failed to sudo login as user'));
            return of(DomainActions.Auth.loginFail(error));
          })
        );
      } else {
        localStorage.removeItem('auth_token');
        const authToken = localStorage.getItem('suspended_token');
        if (authToken) {
          // Reinstate the suspended_token
          localStorage.setItem('auth_token', authToken);
        }
      }

      if (redirect) {
        this.router.navigate([redirect]).then(() => {
          window.location.reload();
        })
      } else {
        window.location.reload();
      }

      return EMPTY;
    })
  ));

  /**
   * Listens for logout action
   */
  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.LOGOUT),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Auth.isSudoLoggedIn)),
    mergeMap(([payload, isSudo]) => {
      const auth_token = localStorage.getItem('suspended_token');
      if (isSudo && auth_token) {
        // Reinstate the suspended_token
        this.store.dispatch(DomainActions.Auth.setSudoLogin(false));
        localStorage.setItem('auth_token', auth_token);
        localStorage.removeItem('suspended_token');
        this.router.navigate([ROUTER_LINKS.ADMIN]);
        return of(DomainActions.Auth.login());
      } else {
        // remove both auth tokens from storage.
        localStorage.removeItem('auth_token');
        localStorage.removeItem('suspended_token');
        this.router.navigate(payload.routerCommands, payload.routerOptions);
        return of(DomainActions.Auth.logoutSuccess());
      }
    }),
    catchError(error => of(DomainActions.Auth.loginFail(error)))
  ));

  /**
   * Listens for refresh action
   */
  refresh$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.REFRESH),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Auth.shouldWatchRoute)),
    switchMap(([payload, shouldWatchRoute]) =>
      this.apiService.doLogin().pipe(
        mergeMap((response: any) => {
          if (shouldWatchRoute) {
            this.router.navigateByUrl(this.router.url, { replaceUrl: false, state: { skipScrollReset: true } });
          }

          const observables = [DomainActions.Auth.refreshSuccess(response)];
          if (this.router.url.split('?')[0] !== '/' + CORE_ROUTES.NOTIFICATIONS) {
            observables.push(DomainActions.Auth.refreshUserMessages());
          }

          if (payload.goto) {
            observables.push(DomainActions.App.go([payload.goto.url], payload.goto.extras));
          }
          return concat(observables);
        }),
          catchError(error => of(DomainActions.Auth.refreshFail(error))
        ))
    )
  ));

  /**
   * Listens for user update action.
   */
  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.UPDATE_USER),
    map(toPayload),
    mergeMap(data => {
      const profile = data.profile;
      const loginAfter = data.loginAfter;
      return this.apiService.updateProfile(profile).pipe(
        // send out success action
        map((response) => (loginAfter) ? DomainActions.Auth.loginOnDemand(response) : DomainActions.Auth.crudUserSuccess(response)),
        // unhandled exception occurred.
        catchError((error) => of(DomainActions.Auth.serviceFail(error))));
    })
  ));

  /**
   * Fetch user notifications/emails.
   */
  refreshUserMessages$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.REFRESH_USER_MESSAGES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Auth.getCurrentUserId)),
    switchMap((data) => {
      const [messageType, userId] = data;
      if (userId) {
        return this.apiService.getUserMessages(userId, messageType, 50).pipe(
          // send out success action
          map((response) => DomainActions.Auth.refreshUserMessagesSuccess(messageType, response)),
          // unhandled exception occurred.
          catchError((error) => of(DomainActions.Auth.serviceFail(error))));
      } else {
        return EMPTY;
      }
      return EMPTY;
    })
  ));

  /**
   * Listens for user update action.
   */
  markUserMessages$ = createEffect(() => this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.MARK_USER_MESSAGES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Auth.getCurrentUserId)),
    mergeMap(([mark, userId]) => {
      const messageIds = mark.messages.map(m => m.id);
      return this.apiService.markUserMessages(userId, mark.value, mark.markType, messageIds).pipe(
        // send out success action
        map((response) => DomainActions.Auth.markUserMessagesSuccess(mark)),
        // unhandled exception occurred.
        catchError((error) => of(DomainActions.Auth.serviceFail(error))));
    })
  ));
}
