<div *ngIf="parentComment" id="comment_thread_container--{{threadId}}" class="comment-thread__container"
  [ngClass]="{ 'comment-thread__container--resolved': parentComment.resolved, 'comment-thread__container--selected': isSelected }">
  <div *ngIf="parentComment.resolved" class="flex flex--justify-content-between">
    <div class="flex flex--align-items-center">
      <icon iconId="checkmark" class="icon--positive margin-right-xxs"></icon>
      Resolved at {{ parentComment.updated_at | po_calendar_date : calendarFormat }}
    </div>
    <options-menu #optionsMenu *ngIf="optionsMenuEl?.showOptions || mouseover" iconId="more">
      <options-menu-item optionText="Re&#8209;open" (doEmit)="showResolveAlert = true;"></options-menu-item>
    </options-menu>
  </div>
  <comment-item *ngFor="let comment of comments; trackBy: trackById"
    [item]="comment"
    [parent]="parentComment"
    [resource]="resource"
    [creator]="comment.creator || currentUser"
    [isCreator]="comment.creator_id == null || comment.creator_id == currentUser.id"
    [isSelected]="isSelected"
    [canEdit]="canEdit"
    [mouseover]="mouseover"
    (onResolve)="showResolveAlert = true;">
  </comment-item>
</div>

<alert *ngIf="showResolveAlert"
  [button-action]="parentComment.resolved ? 'Re-open' : 'Resolve'"
  button-dismiss="Cancel"
  level="warning"
  (action)="resolve()"
  (dismiss)="showResolveAlert = false;">
  <ng-container [ngSwitch]="!!parentComment.resolved">
    <p *ngSwitchCase="false">
      Are you sure you want to mark this thread as resolved?
    </p>
    <p *ngSwitchCase="true">
      Are you sure you want to re-open this thread?
    </p>
  </ng-container>
</alert>