export const INSTITUTION_TYPES = {
  STATE: 'State',
  RC: 'Region',
  CCD: 'District',
  COLLEGE: 'College',
  CONSORTIUM: 'Consortium',
  COE: 'County Office of Education (COE)',
  ROP: 'Regional Occupation Center/Program (ROC/P)',
  ELEMENTARY_DISTRICT: 'Elementary School District',
  UNIFIED: 'Unified School District',
  HIGH_SCHOOL_DISTRICT: 'High School District',
  CHARTER: 'Charter',
  C4CU: '4-Year College/University',
  ADULT_SCHOOL: 'Adult School',
  HIGH_SCHOOL: 'High School',
  MIDDLE_SCHOOL: 'Middle School',
  ELEMENTARY_HIGH: 'Elementary/High School Combination',
  ELEMENTARY: 'Elementary School',
  PRESCHOOL: 'Preschool',

  // Non-Educational Institutions
  BUSINESS: 'Business',
  CITY: 'City',
  COMMUNITY_ORG: 'Community Organization',
  INDUSTRY: 'Industry',
  PHILANTHROPIC_ORG: 'Philanthropic Organization',
  WORKFORCE_DEV_BOARD: 'Workforce Development Board',
  EMPLOYER: 'Employer',
  JOINT_POWERS_AUTHORITY: 'Joint Powers Authority (JPA)',
  VENDOR_NON_PROFIT: 'Vendor/Non-Profit',
  OTHER: 'Other', // None of the above

  // K12 Tap & Coordinator
  SWPK_TAP: 'K14 TAP',
  SWPK_PC: 'K12 Coordinator',
};

export const CHANCELLORS_OFFICE = {
  ID: 788
};

export const EDUCATION_CENTER = {
  ID: 36
};

export const LEA_TYPES = [
  INSTITUTION_TYPES.CHARTER,
  INSTITUTION_TYPES.COE,
  INSTITUTION_TYPES.ELEMENTARY_DISTRICT,
  INSTITUTION_TYPES.HIGH_SCHOOL_DISTRICT,
  INSTITUTION_TYPES.UNIFIED,
  INSTITUTION_TYPES.ROP
];

export const PARTICIPATING_SCHOOL_TYPES = [
  ...LEA_TYPES,
  INSTITUTION_TYPES.ADULT_SCHOOL,
  INSTITUTION_TYPES.HIGH_SCHOOL,
  INSTITUTION_TYPES.MIDDLE_SCHOOL,
  INSTITUTION_TYPES.ELEMENTARY_HIGH,
  INSTITUTION_TYPES.ELEMENTARY,
  INSTITUTION_TYPES.PRESCHOOL
];

export const COLLABORATIVE_PARTNER_TYPES = [
  INSTITUTION_TYPES.BUSINESS,
  INSTITUTION_TYPES.COMMUNITY_ORG,
  INSTITUTION_TYPES.INDUSTRY,
  INSTITUTION_TYPES.PHILANTHROPIC_ORG,
  INSTITUTION_TYPES.WORKFORCE_DEV_BOARD,
  INSTITUTION_TYPES.OTHER
];

export const HIGHER_ED_PARTNER_TYPES = [
  INSTITUTION_TYPES.COLLEGE,
  INSTITUTION_TYPES.CCD,
  INSTITUTION_TYPES.C4CU
];

export const NON_EDUCATIONAL_TYPES = [
  INSTITUTION_TYPES.BUSINESS,
  INSTITUTION_TYPES.CITY,
  INSTITUTION_TYPES.COMMUNITY_ORG,
  INSTITUTION_TYPES.INDUSTRY,
  INSTITUTION_TYPES.PHILANTHROPIC_ORG,
  INSTITUTION_TYPES.WORKFORCE_DEV_BOARD,
  INSTITUTION_TYPES.EMPLOYER,
  INSTITUTION_TYPES.VENDOR_NON_PROFIT,
  INSTITUTION_TYPES.OTHER
];

export const INSTITUTION_TYPE_OPTIONS = [
  { label: 'Charter Schools', value: INSTITUTION_TYPES.CHARTER },
  { label: 'Colleges', value: INSTITUTION_TYPES.COLLEGE },
  { label: 'Community College Districts (CCDs)', value: INSTITUTION_TYPES.CCD },
  { label: 'Adult Education Consortia', value: INSTITUTION_TYPES.CONSORTIUM },
  { label: 'County Offices of Education (COEs)', value: INSTITUTION_TYPES.COE },
  { label: 'Elementary School Districts', value: INSTITUTION_TYPES.ELEMENTARY_DISTRICT },
  { label: 'High School Districts', value: INSTITUTION_TYPES.HIGH_SCHOOL_DISTRICT },
  { label: 'Regional Occupation Centers/Programs', value: INSTITUTION_TYPES.ROP },
  { label: 'Regions', value: INSTITUTION_TYPES.RC },
  { label: 'Unified School Districts', value: INSTITUTION_TYPES.UNIFIED },
  { label: 'Other/Non-Educational', value: INSTITUTION_TYPES.OTHER }
];

export const MEMBER_INSTITUTION_TYPES = [
  { value: INSTITUTION_TYPES.CCD, label: INSTITUTION_TYPES.CCD, canCreate: false },
  { value: INSTITUTION_TYPES.COLLEGE, label: INSTITUTION_TYPES.COLLEGE, canCreate: false },
  { value: INSTITUTION_TYPES.COE, label: INSTITUTION_TYPES.COE, canCreate: true },
  { value: INSTITUTION_TYPES.ROP, label: INSTITUTION_TYPES.ROP, canCreate: true },
  { value: INSTITUTION_TYPES.ELEMENTARY_DISTRICT, label: INSTITUTION_TYPES.ELEMENTARY_DISTRICT, canCreate: true },
  { value: INSTITUTION_TYPES.UNIFIED, label: INSTITUTION_TYPES.UNIFIED, canCreate: true },
  { value: INSTITUTION_TYPES.HIGH_SCHOOL_DISTRICT, label: INSTITUTION_TYPES.HIGH_SCHOOL_DISTRICT, canCreate: true },
  { value: INSTITUTION_TYPES.WORKFORCE_DEV_BOARD, label: INSTITUTION_TYPES.WORKFORCE_DEV_BOARD, canCreate: true },
  { value: INSTITUTION_TYPES.JOINT_POWERS_AUTHORITY, label: INSTITUTION_TYPES.JOINT_POWERS_AUTHORITY, canCreate: false },
  { value: INSTITUTION_TYPES.OTHER, label: INSTITUTION_TYPES.OTHER, canCreate: true },
];

export const CREATABLE_INSTITUTION_TYPES = [
  INSTITUTION_TYPES.CHARTER,
  INSTITUTION_TYPES.COE,
  INSTITUTION_TYPES.ELEMENTARY_DISTRICT,
  INSTITUTION_TYPES.HIGH_SCHOOL_DISTRICT,
  INSTITUTION_TYPES.UNIFIED,
  INSTITUTION_TYPES.ROP,
  INSTITUTION_TYPES.ADULT_SCHOOL,
  INSTITUTION_TYPES.HIGH_SCHOOL,
  INSTITUTION_TYPES.MIDDLE_SCHOOL,
  INSTITUTION_TYPES.ELEMENTARY_HIGH,
  INSTITUTION_TYPES.ELEMENTARY,
  INSTITUTION_TYPES.PRESCHOOL,
  INSTITUTION_TYPES.BUSINESS,
  INSTITUTION_TYPES.COMMUNITY_ORG,
  INSTITUTION_TYPES.INDUSTRY,
  INSTITUTION_TYPES.PHILANTHROPIC_ORG,
  INSTITUTION_TYPES.WORKFORCE_DEV_BOARD,
  INSTITUTION_TYPES.OTHER,
  INSTITUTION_TYPES.VENDOR_NON_PROFIT,
];
