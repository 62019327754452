<po-select [id]="id" class="no-margin"
  [labelText]="labelText"
  [labelHidden]="labelHidden"
  [placeholderText]="placeholderText"
  [canEdit]="canEdit"
  [selectOnce]="selectOnce"
  [selectMultiple]="selectMultiple"
  [options]="timeOptions"
  [disabledOptions]="disabledOptions"
  (optionSelected)="selectOption($event)"
  (optionRemoved)="removeOption($event)"
  (search)="doSearch($event)"
  [inline]="inline"
  [emptyOption]="emptyOption"
  [emptyText]="emptyText"
  [emptyIcon]="emptyIcon"
  [showEmpty]="showEmpty">

  <select inputRef class="po-select__select" [formControl]="formCtrl"></select>

</po-select>