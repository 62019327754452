<h2 class="header-title h2 mb-l" id="qa_tab_title">
  {{pageTitle}}
</h2>

<!-- ##################   SUMMARY: GP   ####################-->
<ng-container *ngIf="isGP">
  <p><strong>Total Allocation Amount:</strong> ${{+summary['total_allocation'] | number: '1.0-0'}}</p>
  <p><strong>Total Budget Amount:</strong> ${{+summary['total_budget'] | number: '1.0-0'}}</p>
  <p><strong>Percentage of Allocation:</strong> {{+summary['total_allocation_percent'] | number: '1.0-2'}}% </p>
</ng-container>

<!-- ##################   Budget: Main Body  ####################-->
<app-budget-item  *ngFor="let item of filteredBudgetItems; let i=index; trackBy: trackById"
                  [budgetItem]="item"
                  [count]="i"
                  [canEdit]="canEdit"
                  [firstTouch]="firstTouch"
                  [fundType]="projectType"
                  [fundYearDurationId]="fundYearDurationId"
                  [institutions]="institutions"
                  [leadInstitutionId]="leadInstitutionId"
                  [leadInstitutionName]="leadInstitutionName"
                  [objectCodes]="availableObjectCodes"
                  [totalAllocation]="totalAllocation"
                  (delete)="deleteBudgetItem($event)"
                  (dupe)="dupeBudgetItem($event)"
                  (touch)="itemTouched()">
</app-budget-item>

<div class="btn-section btn-section--center">
  <div class="inline-button-group__container">
    <button *ngIf="canCopyBudgetItems()" type="button" id="qa_budget_newitem_icon" class="action-button action-button--secondary icon--left"
      (click)="copyBudgetItemsFromPreviousYear()">
      <icon iconId="plus"></icon>
      Copy From Previous Year
    </button>
    <button type="button" id="qa_budget_newitem_icon" class="action-button action-button--primary icon--left"
      (click)="addBudgetItem(true)">
      <icon iconId="plus"></icon>
      New Budget Item
    </button>
  </div>
</div>

<!-- ##################   SUMMARY: iPlan   ####################-->
<div *ngIf="isIPlan" class="card">
  <div class="card-title">
    <h3 class="h3 mb-l">Program Totals</h3>
  </div>
  <div class="table-overflow">
    <div class="table-overflow__inside--30-rem">
      <table class="po-table po-table--fixed">
        <thead>
          <tr>
            <th scope="col">Funds</th>
            <th scope="col" class="col-7-rem align-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr class="align-data--right">
            <th scope="row">{{summary[FUND_TYPES.BSI].label}}</th>
            <td>${{+summary[FUND_TYPES.BSI].amount | number: '1.0'}}</td>
          </tr>
          <tr class="align-data--right">
            <th scope="row">{{summary[FUND_TYPES.SE].label}}</th>
            <td>${{+summary[FUND_TYPES.SE].amount | number: '1.0'}}</td>
          </tr>
          <tr class="align-data--right">
              <th scope="row">{{summary[FUND_TYPES.SSSP_C].label}}</th>
            <td>${{+summary[FUND_TYPES.SSSP_C].amount | number: '1.0'}}</td>
          </tr>
          <tr class="align-data--right">
            <th scope="row">{{summary[FUND_TYPES.SSSP].label}}</th>
            <td>${{+summary[FUND_TYPES.SSSP].amount | number: '1.0'}}</td>
          </tr>
          <tr class="align-data--right">
            <th scope="row">{{summary[FUND_TYPES.SSSP_CM].label}}</th>
            <td>${{+summary[FUND_TYPES.SSSP_CM].amount | number: '1.0'}}</td>
          </tr>
          <tr class="align-data--right">
            <th scope="row">{{summary[FUND_TYPES.SSSP_M].label}}</th>
            <td>${{+summary[FUND_TYPES.SSSP_M].amount | number: '1.0'}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="align-data--right">
            <th scope="row">{{summary['sub_total'].label}}</th>
            <td>${{+summary['sub_total'].amount | number: '1.0'}}</td>
          </tr>
          <tr class="align-data--right">
            <th scope="row">{{summary['sub_total_match'].label}}</th>
            <td scope="row">${{+summary['sub_total_match'].amount | number: '1.0'}}</td>
          </tr>
          <tr class="align-data--right">
            <th scope="row">{{summary['total'].label}}</th>
            <td>${{+summary['total'].amount | number: '1.0'}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <p class="margin-bottom-xs">
    SSSP Credit Match Status:
    <strong>
      <span id="qa_budget_credit_label" [ngClass]="{'error-text': summary[FUND_TYPES.SSSP_CM].status == 'mismatch'}">{{summary[FUND_TYPES.SSSP_CM].status}}</span>
    </strong>
  </p>

  <p class="margin-bottom-xs">
    SSSP Noncredit Match Status:
    <strong>
      <span id="qa_budget_noncredit_label" [ngClass]="{'error-text': summary[FUND_TYPES.SSSP_M].status == 'mismatch'}">{{summary[FUND_TYPES.SSSP_M].status}}</span>
    </strong>
  </p>
</div>
