import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, Actions, Model } from '@app-ngrx-domains';
import { ApiService, LookupService } from '@app-services';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ValidatorsEx } from '@app-utilities';
import { EnumErrorTypes } from '@app-models';

@Component({
  selector: 'app-test-email-modal',
  templateUrl: './test-email-modal.component.html',
})
export class TestEmailModalComponent implements OnInit {
  @Input() templateId: number;
  @Output() closeAction: EventEmitter<null> = new EventEmitter();

  emailTestForm: FormGroup;

  previewPage: string;
  contactList: Array<Model.SelectOption> = [];

  statusMessage: Model.StatusMessage = {
    message: undefined,
    type: undefined
  };

  constructor(
    private _fb: FormBuilder,
    private apiService: ApiService,
    private lookupService: LookupService,
    private store: Store<State>
  ) {
    this.emailTestForm = this._fb.group({
      recipients: [[], [ValidatorsEx.requiredSelection]]
    });
  }

  ngOnInit() {
    this.apiService.previewMessage(this.templateId).subscribe(
      res => {
        this.previewPage = res;
      },
      err => {
        this.store.dispatch(Actions.App.setError({
          type: EnumErrorTypes.api,
          location: this.constructor.name,
          show: true,
          raw: err,
          message: 'Failed to get preview page.'
        }));
      }
    );
  }

  public get canCloseModal(): boolean {
    return this.statusMessage.type !== 'loading';
  }

  public get canSendTestEmail(): boolean {
    return this.emailTestForm.valid && (this.statusMessage.type !== 'loading');
  }

  public sendTestEmailClicked() {
    this.statusMessage.message = 'Saving test email...';
    this.statusMessage.type = 'loading';

    // get test recipients.
    const contacts = this.emailTestForm.get('recipients').value;
    const recipients = {
      user_ids: contacts.map(c => c.value),
    };

    this.statusMessage.message = 'Sending test email';
    this.statusMessage.type = 'loading';

    this.apiService.sendTestMessage(this.templateId, recipients).subscribe(
      (res) => {
        // successfully sent the test message.
        this.statusMessage.message = 'Email(s) sent successfully.';
        this.statusMessage.type = 'success';
      },
      (err) => {
        this.statusMessage.message = 'Failed to send the test email';
        this.statusMessage.type = 'fail';
        this.store.dispatch(Actions.App.setError({
          type: EnumErrorTypes.api,
          location: this.constructor.name,
          show: false,
          raw: err,
          message: 'Failed to send the test email.',
        }));
      }
    );
  }

  public getContactList(filter: string, limit: number) {
    this.lookupService.formattedContactList$(filter, limit)
      .subscribe(
        (res) => {
          this.contactList = res;
        },
        (err) => {
          this.store.dispatch(Actions.App.setError({
            type: EnumErrorTypes.api,
            location: this.constructor.name,
            show: true,
            raw: err
          }));
        }
      );
  }
}
