<div class="card">
  <h1 id="h.vg4rof709e3m"><span>Privacy policy</span></h1>
  <p><span>We respect your privacy and are committed to protecting it through our compliance with this
      privacy policy (&ldquo;Policy&rdquo;). This Policy describes the types of information we may collect from you or
      that you may provide (&ldquo;Personal Information&rdquo;) on the</span><span><a
        href="https://www.google.com/url?q=http://nova.cccco.edu&amp;sa=D&amp;source=editors&amp;ust=1669755257294853&amp;usg=AOvVaw1vSAlk-0qJlj9_8fUK9JKH">&nbsp;</a></span><span><a
        href="https://www.google.com/url?q=http://nova.cccco.edu&amp;sa=D&amp;source=editors&amp;ust=1669755257295095&amp;usg=AOvVaw3SF9FQCb-A36YR_dDLNrjT">nova.cccco.edu</a></span><span>&nbsp;website
      (&ldquo;Website&rdquo; or &ldquo;Service&rdquo;) and any of its related products and
      services (collectively, &ldquo;Services&rdquo;), and our practices for collecting, using, maintaining, protecting,
      and disclosing that Personal Information. It also describes the choices available to you regarding our use of your
      Personal Information and how you can access and update it.</span></p>
  <p><span>This Policy is a legally binding agreement between you (&ldquo;User&rdquo;, &ldquo;you&rdquo; or
      &ldquo;your&rdquo;) and productOps, Inc. (&ldquo;productOps, Inc.&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or
      &ldquo;our&rdquo;). If you are entering into this agreement on behalf of a business or other legal entity, you
      represent that you have the authority to bind such entity to this agreement, in which case the terms
      &ldquo;User&rdquo;, &ldquo;you&rdquo; or &ldquo;your&rdquo; shall refer to such entity. If you do not have such
      authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not
      access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you
      have read, understood, and agree to be bound by the terms of this Policy. This Policy does not apply to the
      practices of companies that we do not own or control, or to individuals that we do not employ or manage.</span>
  </p>
  <h2 id="h.q1r0blyzgxa5"><span>Automatic collection of information</span></h2>
  <p><span>When you open the Website, our servers automatically record information that your
      browser sends. This data may include information such as your device&rsquo;s IP address, browser type, and
      version, operating system type and version, language preferences or the webpage you were visiting before you came
      to the Website and Services, pages of the Website and Services that you visit, the time spent on those pages,
      information you search for on the Website, access times and dates, and other statistics.</span></p>
  <p><span>Information collected automatically is used only to identify potential cases of abuse
      and establish statistical information regarding the usage and traffic of the Website and Services. This
      statistical information is not otherwise aggregated in such a way that would identify any particular User of the
      system.</span></p>
  <h2 id="h.sirhszqshhdk"><span>Collection of personal information</span></h2>
  <p><span>You can access and use the Website and Services without telling us who you are or
      revealing any information by which someone could identify you as a specific, identifiable individual. If, however,
      you wish to use some of the features offered on the Website, you may be asked to provide certain Personal
      Information (for example, your name and e-mail address).</span></p>
  <p><span>We receive and store any information you knowingly provide to us when you create an
      account, or fill any forms on the Website. When required, this information may include the following:</span></p>
  <ul>
    <li><span>Account details (such as user name, unique user ID, password, etc)</span></li>
    <li><span>Contact information (such as email address, phone number, etc)</span>
    </li>
  </ul>
  <p><span>You can choose not to provide us with your Personal Information, but then you may not
      be able to take advantage of some of the features on the Website. Users who are uncertain about what information
      is mandatory are welcome to contact us.</span></p>
  <h2 id="h.1gxppfd10va8"><span>Privacy of children</span></h2>
  <p><span>We do not knowingly collect any Personal Information from children under the age of 18.
      If you are under the age of 18, please do not submit any Personal Information through the Website and Services. If
      you have reason to believe that a child under the age of 18 has provided Personal Information to us through the
      Website and Services, please contact us to request that we delete that child&rsquo;s Personal Information from our
      Services.</span></p>
  <p><span>We encourage parents and legal guardians to monitor their children&rsquo;s Internet
      usage and to help enforce this Policy by instructing their children never to provide Personal Information through
      the Website and Services without their permission. We also ask that all parents and legal guardians overseeing the
      care of children take the necessary precautions to ensure that their children are instructed to never give out
      Personal Information when online without their permission.</span></p>
  <h2 id="h.qwmcg54z265t"><span>Use and processing of collected information</span></h2>
  <p><span>We act as a data controller and a data processor when handling Personal Information,
      unless we have entered into a data processing agreement with you in which case you would be the data controller
      and we would be the data processor.</span></p>
  <p><span>Our role may also differ depending on the specific situation involving Personal
      Information. We act in the capacity of a data controller when we ask you to submit your Personal Information that
      is necessary to ensure your access and use of the Website and Services. In such instances, we are a data
      controller because we determine the purposes and means of the processing of Personal Information.</span></p>
  <p><span>We act in the capacity of a data processor in situations when you submit Personal
      Information through the Website and Services. We do not own, control, or make decisions about the submitted
      Personal Information, and such Personal Information is processed only in accordance with your instructions. In
      such instances, the User providing Personal Information acts as a data controller.</span></p>
  <p><span>In order to make the Website and Services available to you, or to meet a legal
      obligation, we may need to collect and use certain Personal Information. If you do not provide the information
      that we request, we may not be able to provide you with the requested products or services. Any of the information
      we collect from you may be used for the following purposes:</span></p>
  <ul>
    <li><span>Create and manage user accounts</span></li>
    <li><span>Run and operate the Website and Services</span></li>
  </ul>
  <p><span>Processing your Personal Information depends on how you interact with the Website and
      Services, where you are located in the world and if one of the following applies: (i) you have given your consent
      for one or more specific purposes; this, however, does not apply, whenever the processing of Personal Information
      is subject to California Consumer Privacy Act; (ii) provision of information is necessary for the performance of
      an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for
      compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried
      out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for
      the purposes of the legitimate interests pursued by us or by a third party.</span></p>
  <p><span>Note that under some legislations we may be allowed to process information until you
      object to such processing by opting out, without having to rely on consent or any other of the legal bases. In any
      case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular
      whether the provision of Personal Information is a statutory or contractual requirement, or a requirement
      necessary to enter into a contract.</span></p>
  <h2 id="h.fivs0r3w55y9"><span>Managing information</span></h2>
  <p><span>You are able to delete certain Personal Information we have about you. The Personal
      Information you can delete may change as the Website and Services change. When you delete Personal Information,
      however, we may maintain a copy of the unrevised Personal Information in our records for the duration necessary to
      comply with our obligations to our affiliates and partners, and for the purposes described below.</span></p>
  <h2 id="h.3k02votu0ovu"><span>Disclosure of information</span></h2>
  <p><span>Depending on the requested Services or as necessary to complete any transaction or
      provide any Service you have requested, we may share your information with our affiliates, contracted companies,
      and service providers (collectively, &ldquo;Service Providers&rdquo;) we rely upon to assist in the operation of
      the Website and Services available to you and whose privacy policies are consistent with ours or who agree to
      abide by our policies with respect to Personal Information. We will not share any personally identifiable
      information with third parties and will not share any information with unaffiliated third parties.</span></p>
  <p><span>Service Providers are not authorized to use or disclose your information except as
      necessary to perform services on our behalf or comply with legal requirements. Service Providers are given the
      information they need only in order to perform their designated functions, and we do not authorize them to use or
      disclose any of the provided information for their own marketing or other purposes.</span></p>
  <h2 id="h.z4uz1rkv9k8g"><span>Retention of information</span></h2>
  <p><span>We will retain and use your Personal Information for the period necessary as long as
      your user account remains active, to enforce our agreements, resolve disputes, and unless a longer retention
      period is required or permitted by law.</span></p>
  <p><span>We may use any aggregated data derived from or incorporating your Personal Information
      after you update or delete it, but not in a manner that would identify you personally. Once the retention period
      expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to
      rectification, and the right to data portability cannot be enforced after the expiration of the retention
      period.</span></p>
  <h2 id="h.9fqzqlogcpl2"><span>California privacy rights</span></h2>
  <p><span>Consumers residing in California are afforded certain additional rights with respect to
      their Personal Information under the California Consumer Privacy Act (&ldquo;CCPA&rdquo;). If you are a California
      resident, this section applies to you.</span></p>
  <p><span>In addition to the rights as explained in this Policy, California residents who provide
      Personal Information as defined in the statute to obtain Services for personal, family, or household use are
      entitled to request and obtain from us, once a calendar year, information about the categories and specific pieces
      of Personal Information we have collected and disclosed.</span></p>
  <p><span>Furthermore, California residents have the right to request deletion of their Personal
      Information or opt-out of the sale of their Personal Information which may include selling, disclosing, or
      transferring Personal Information to another business or a third party for monetary or other valuable
      consideration. To do so, simply contact us. We will not discriminate against you if you exercise your rights under
      the CCPA.</span></p>
  <h2 id="h.n7v0q5qxewp"><span>How to exercise your rights</span></h2>
  <p><span>Any requests to exercise your rights can be directed to us through the contact details
      provided in this document. Please note that we may ask you to verify your identity before responding to such
      requests. Your request must provide sufficient information that allows us to verify that you are the person you
      are claiming to be or that you are the authorized representative of such person. If we receive your request from
      an authorized representative, we may request evidence that you have provided such an authorized representative
      with power of attorney or that the authorized representative otherwise has valid written authority to submit
      requests on your behalf.</span></p>
  <p><span>You must include sufficient details to allow us to properly understand the request and
      respond to it. We cannot respond to your request or provide you with Personal Information unless we first verify
      your identity or authority to make such a request and confirm that the Personal Information relates to you.</span>
  </p>
  <h2 id="h.w8vry2e4te6z"><span>Data analytics</span></h2>
  <p><span>Our Website and Services may use third-party analytics tools that use cookies, web
      beacons, or other similar information-gathering technologies to collect standard internet activity and usage
      information. The information gathered is used to compile statistical reports on User activity such as how often
      Users visit our Website and Services, what pages they visit and for how long, etc. We use the information obtained
      from these analytics tools to monitor the performance and improve our Website and Services. We do not use
      third-party analytics tools to track or to collect any personally identifiable information of our Users and we
      will not associate any information gathered from the statistical reports with any individual User.</span></p>
  <h2 id="h.yxkj6t5l7r81"><span>Do Not Track signals</span></h2>
  <p><span>Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do
      not want to have your online activity tracked. Tracking is not the same as using or collecting information in
      connection with a website. For these purposes, tracking refers to collecting personally identifiable information
      from consumers who use or visit a website or online service as they move across different websites over time. How
      browsers communicate the Do Not Track signal is not yet uniform. As a result, the Website and Services are not yet
      set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more
      detail throughout this Policy, we limit our use and collection of your Personal Information. For a description of
      Do Not Track protocols for browsers and mobile devices or to learn more about the choices available to you,
      visit</span><span><a
        href="https://www.google.com/url?q=https://www.internetcookies.com&amp;sa=D&amp;source=editors&amp;ust=1669755257298912&amp;usg=AOvVaw1fZc65qqTM7PuC0RW8d8Fz">&nbsp;</a></span><span><a
        href="https://www.google.com/url?q=https://www.internetcookies.com&amp;sa=D&amp;source=editors&amp;ust=1669755257299157&amp;usg=AOvVaw39X6xBt7M5Jb7CK4S3vqjm">internetcookies.com</a></span>
  </p>
  <h2 id="h.obwingld9kj"><span>Links to other resources</span></h2>
  <p><span>The Website and Services contain links to other resources that are not owned or
      controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or
      third parties. We encourage you to be aware when you leave the Website and Services and to read the privacy
      statements of each and every resource that may collect Personal Information.</span></p>
  <h2 id="h.w9u81fvo88s4"><span>Information security</span></h2>
  <p><span>We secure information you provide on computer servers in a controlled, secure
      environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative,
      technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and
      disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or
      wireless network can be guaranteed.</span></p>
  <p><span>Therefore, while we strive to protect your Personal Information, you acknowledge that
      (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security,
      integrity, and privacy of any and all information and data exchanged between you and the Website and Services
      cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third
      party, despite best efforts.</span></p>
  <p><span>As the security of Personal Information depends in part on the security of the device
      you use to communicate with us and the security you use to protect your credentials, please take appropriate
      measures to protect this information.</span></p>
  <h2 id="h.9cbx51ubfvfu"><span>Data breach</span></h2>
  <p><span>In the event we become aware that the security of the Website and Services has been
      compromised or Users&rsquo; Personal Information has been disclosed to unrelated third parties as a result of
      external activity, including, but not limited to, security attacks or fraud, we reserve the right to take
      reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as
      notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make
      reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the
      User as a result of the breach or if notice is otherwise required by law. When we do, we will post a notice on the
      Website.</span></p>
  <h2 id="h.c4ysj05kk3gz"><span>Changes and amendments</span></h2>
  <p><span>We reserve the right to modify this Policy or its terms related to the Website and
      Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We
      may also provide notice to you in other ways at our discretion, such as through the contact information you have
      provided.</span></p>
  <p><span>An updated version of this Policy will be effective immediately upon the posting of the
      revised Policy unless otherwise specified. Your continued use of the Website and Services after the effective date
      of the revised Policy (or such other act specified at that time) will constitute your consent to those changes.
      However, we will not, without your consent, use your Personal Information in a manner materially different than
      what was stated at the time your Personal Information was collected.</span></p>
  <h2 id="h.nijvoaj399x8"><span>Acceptance of this policy</span></h2>
  <p><span>You acknowledge that you have read this Policy and agree to all its terms and
      conditions. By accessing and using the Website and Services and submitting your information you agree to be bound
      by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use
      the Website and Services.</span></p>
  <h2 id="h.n1vocnt1lzsi"><span>Contacting us</span></h2>
  <p><span>If you have any questions regarding the information we may hold about you or if you
      wish to exercise your rights, you may use the following data subject request form to submit your request:</span>
  </p>
  <p><span><a href="https://app.websitepolicies.com/dsar/view/t9zhkcoi">Submit a data access request</a></span></p>
  <p><span>If you have any other questions, concerns, or complaints regarding this Policy, we
      encourage you to contact us using the details below:</span></p>
  <p><span>dataqueries@productops.com</span></p>
  <p><span>110 Cooper St. Suite 201, Santa Cruz, CA 95060</span></p>
  <p><span>We will attempt to resolve complaints and disputes and make every reasonable effort to
      honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by
      applicable data protection laws.</span></p>
  <p><span>This document was last updated on November 16, 2022</span></p>
</div>