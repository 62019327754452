<div class="card">
  <div class="v2-styles card-header">
    <div class="card-title">
      CALENDAR
    </div>
    <div class="flex flex--align-items-center">
      <button class="action-button action-button--small icon--left" (click)="showSyncModal = true;">
        <icon iconId="sync" class="sync-icon"></icon>
        Sync Calendars
      </button>
    </div>
  </div>
  <div class="flex flex--align-items-center">
    <button title="Today" type="button" (click)="goToToday()"
      class="action-button action-button--tertiary action-button--small">
      Today
    </button>

    <div class="inline-block inline-button-group__container margin-left-sm">
      <button class="action-button action-button--small action-button--tertiary inline-button"
        title="Previous Month"
        (click)="goToPreviousMonth()">
        <icon iconId="disclosure-left"></icon>
      </button>

      <button class="action-button action-button--small action-button--tertiary inline-button"
        title="Next Month"
        (click)="goToNextMonth()">
        <icon iconId="disclosure"></icon>
      </button>
    </div>

    <div class="calendar__header-title margin-left-sm">{{ monthLabel }}</div>
  </div>

  <div class="calendar-week-header margin-top-sm">
    <div>SUN</div>
    <div>MON</div>
    <div>TUES</div>
    <div>WED</div>
    <div>THU</div>
    <div>FRI</div>
    <div>SAT</div>
  </div>

  <div class="calendar-table">
    <ng-container *ngFor="let week of weeks">
      <ng-container *ngFor="let day of week">
        <div class="calendar__day" [ngClass]="{ 'calendar__day--disabled': day.disabled }">
          <div class="calendar__date">
            <div [class.today]="day.isToday">{{ day.date }}</div>
          </div>
          <div class="calendar__event-container" *ngIf="day.events.length">
            <div *ngFor="let event of day.displayedEvents"
              class="calendar__event"
              [style.border-color]="event.color"
              (click)="openEventPopup($event, day, event)"
              tabIndex="0">
                <div class="calendar__event__background" [style.background-color]="event.color">&nbsp;</div>
                <div class="calendar__event__title">{{ event.title }}: {{ event.name }}</div>
            </div>
            <div *ngIf="day.events.length > maxDisplayedEvents" class="calendar__event__more" (click)="openEventListPopup($event, day)">
              {{ day.events.length - maxDisplayedEvents }} more...
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div #eventPopup *ngIf="eventPopupOpen" class="calendar__popup calendar__popup--event" (click)="$event.stopPropagation()">
    <header>
      <div>
        <ng-container [ngSwitch]="!!displayedEvent.url">
          <a *ngSwitchCase="true" [routerLink]="displayedEvent.url" class="calendar__header-title">{{ displayedEvent?.title }}: {{ displayedEvent?.name }}</a>
          <div *ngSwitchCase="false" class="calendar__header-title">{{ displayedEvent?.title }}: {{ displayedEvent?.name }}</div>
        </ng-container>
        <div>{{ displayedEvent?.event_time | date: 'longDate' }}</div>
      </div>
      <div>
        <button
          id="qa_event_modal_dismiss_button"
          type="button"
          class="action-button close-button"
          (click)="closeEventPopup($event)">
          <icon iconId="close"></icon>
        </button>
      </div>
    </header>
    <hr>
    <div class="margin-bottom">
      <div>
        <label>Program: </label>
        <span>{{ displayedEvent?.fund?.short_name || displayedEvent?.fund?.name }}</span>
        <span class="calendar__event-color-icon" [style.background-color]="displayedEvent.color"></span>
      </div>
      <div class="flex">
        <label>Event Type: </label>
        <span>{{ displayedEvent?.event_type }}</span>
      </div>
      <div>
        <label>Event Name: </label>
        <span>{{ displayedEvent?.name }}</span>
      </div>
      <div>
        <label>Repeat: </label>
        <span>{{ displayedEvent?.repeats_annually ? 'Every year' : 'Never' }}</span>
      </div>
      <div>
        <label>Visibility: </label>
        <span>{{ displayedEvent?.is_private ? 'Private' : 'Public - visible to everyone' }}</span>
      </div>
    </div>
  </div>

  <div #eventListPopup *ngIf="eventListPopupOpen" class="calendar__popup calendar__popup--list" (click)="$event.stopPropagation()">
    <div class="text-center margin-bottom">
      <div>{{ displayedDay?.dayOfWeek.label | uppercase }}</div>
      <h3 class="calendar__header-title">{{ displayedDay?.date }}</h3>
      <button
        id="qa_event_list_modal_dismiss_button"
        type="button"
        class="action-button close-button"
        (click)="closeAllPopups()">
        <icon iconId="close"></icon>
      </button>
    </div>
    <div class="calendar__event-container">
      <div *ngFor="let event of displayedDay?.events"
        class="calendar__event"
        [style.border-color]="event.color"
        (click)="openEventPopup($event, displayedDay, event, true)"
        tabIndex="0">
          <div class="calendar__event__background" [style.background-color]="event.color">&nbsp;</div>
          <div class="calendar__event__title">{{ event.title }}: {{ event.name }}</div>
      </div>
    </div>
  </div>
</div>

<full-page-modal
  *ngIf="showSyncModal"
  small="true"
  button-cancel="Close"
  heading="Third-Party Calendar Management"
  (cancel)="toggleSyncModal()"
  (dismiss)="toggleSyncModal()">

  <div>
    <p>Select a third-party calendar client below or download the .ics file to integrate your NOVA calendar events into your external calendar.</p>
    <!-- TODO: Enable this when/if we support syncing with filters -->
    <!-- <radio-check labelText="Sync all Programs, Institutions, and Events">
      <input inputRef id="qa_sync_all_calendar_checkbox"
        class="checkbox" type="checkbox"
        [(ngModel)]="syncAll"/>
    </radio-check> -->

    <hr>

    <div class="columns columns--2">
      <ng-container *ngFor="let client of calendarClients">
        <div class="flex flex--align-items-center">
          <img [src]="'assets/images/calendar_' + client.value + '_logo.svg'" class="calendar__third-party-icon">
          {{ client.label }}
        </div>
        <div class="text-right" [ngSwitch]="!!syncStatuses[client.value]">
          <po-status-message *ngSwitchCase="true" [statusMessage]="syncStatuses[client.value]"></po-status-message>

          <button *ngSwitchDefault
            id="qa_calendar_modal_sync_button_{{client.value}}"
            type="button"
            title="Sync to {{client.value}} calendar"
            attr.aria-label="Sync to {{client.value}} calendar"
            class="action-button action-button--primary sync-calendar"
            (click)="syncCalendar(client.value)">
            Sync
          </button>
        </div>
      </ng-container>

      <div class="flex flex--align-items-center">
        <img src="assets/images/calendar.svg" class="calendar__third-party-icon">
        Download ICS File
      </div>
      <div class="text-right" [ngSwitch]="!!syncStatuses['ics']">
        <po-status-message *ngSwitchCase="true" [statusMessage]="syncStatuses['ics']"></po-status-message>

        <button *ngSwitchDefault id="qa_calendar_modal_ics_download"
          type="button"
          title="Download ICS File"
          aria-label="Download ICS File"
          class="action-button action-button--primary sync-calendar"
          (click)="downloadIcs()">
          <icon iconId="file-download"></icon>
        </button>
      </div>
    </div>
  </div>
</full-page-modal>

