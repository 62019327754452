<dl>
  <dt>
    <span class="history-list__item-title">{{title}}{{ isFirst ? ' (Current)' : '' }}</span>
    <app-user-actions *ngIf="performer"
                      [count]="count"
                      [menuText]="performer"
                      [useMailLink]="true"
                      [userData]="user"
                      class="history-list__item-name">
    </app-user-actions>

    <!-- <span *ngIf="status" class="history-list__item-status">{{status}}</span> -->
    <div *ngIf="date" class="history-list__item-date">{{date | po_date:'dateTimeLong12HoursTZ'}}</div>
  </dt>
  <dd>
    <p *ngIf="comment" [innerHTML]="comment | safeHtml"></p>


    <ng-container [ngSwitch]="!!(isFirst && itemUrl)">
      <div *ngSwitchCase="true" class="history-list__link-item">
        <a class="history-list__link" routerLink="{{itemUrl}}" target="_blank">
          <icon iconId="view"></icon>
          View Fund
        </a>
      </div>
      <ng-container *ngSwitchCase="false">
        <ul *ngIf="files?.length || showCompareLink" class="history-list__links">
          <li *ngIf="!!pdfFile" class="history-list__link-item">
            <a class="history-list__link" [href]="pdfFile.url" target="_blank">
              <icon iconId="file-pdf"></icon>
              View PDF
            </a>
          </li>
          <li *ngIf="!!htmlFile" class="history-list__link-item">
            <a class="history-list__link" [href]="htmlFile.url" target="_blank">
              <icon iconId="file-html"></icon>
              View HTML
            </a>
          </li>
          <li *ngIf="showCompareLink" class="history-list__link-item">
            <a class="history-list__link" routerLink="../compare-changes" [queryParams]="{ from: task.id }">
              <icon iconId="history"></icon>
              Compare Changes
            </a>
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </dd>
</dl>