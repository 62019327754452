import { IFundState, Query, Queries, Model, combineRootFactory } from '@app-ngrx-domains';

const fromRoot = combineRootFactory<IFundState>('Fund');

export interface IFundQueries {
  get: Query<Model.Fund>;
  getId: Query<number>;
  getFiles: Query<Array<Model.Document>>;
  getLoadingStatus: Query<{ is_loading: boolean, error: any }>;
}

Queries.Fund = {
  get: fromRoot(state => state.data),
  getId: fromRoot(state => state.data ? state.data.id : undefined),
  getFiles: fromRoot(state => state.files),
  getLoadingStatus: fromRoot(state => ({
    is_loading: state.is_loading,
    error: state.error
  })),
};

declare module '@app-ngrx-domains' {
  interface Root {
    Fund: Query<IFundState>;
  }

  interface Queries {
    Fund: IFundQueries;
  }
}
