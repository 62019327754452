
export const inKindMatchSources = [
  'Person hours',
  'Use of equipment',
  'Use of facilities',
  'Other'
];

export const adaMaxRequestTiers = [
  { min: 0, max: 140, maxRequest: 250000 },
  { min: 141, max: 550, maxRequest: 500000 },
  { min: 551, max: 10000, maxRequest: 1000000 },
  { min: 10001, maxRequest: 2000000 }
]

export const leaDistrictSubstring = '0000000';