<ng-container [ngSwitch]="!isPreview">
  <!-- Edit -->
  <ng-container *ngSwitchCase="true">
    <h2 class="header-title h2 mb-l" id="qa_tab_title">Student Success Stories (Optional)</h2>

    <!-- guidance -->
    <ng-container [ngSwitch]="supportsGuidance">
      <po-guidance-text *ngSwitchCase="true" [workflowFilter]="guidance" fieldName="guidance"></po-guidance-text>
      <div class="card" *ngSwitchCase="false">
        <div class="help-text">
          <p><b>Optional:</b> Please share a success story for collaborative purposes and to help establish best practices. Other NOVA users will be able to see this information as part of your submitted self-assessment, however the Chancellor’s Office will not share the information with outside parties without first obtaining your college’s consent.</p>
          <p>Note: All fields are required should you choose to include a success story.</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>

  <!-- Preview -->
  <ng-container *ngSwitchCase="false">
    <div class="card">
      <div class="card-title">
        <h3>Success Story (Optional)</h3>
      </div>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</ng-container>

<!-- Content -->
<ng-template #content>
  <project-success-card *ngFor="let story of successStoryItems; let i = index; trackBy: trackById"
    [successStory]="story"
    [canEdit]="canEdit"
    [canDelete]="canDelete"
    [proposal]="proposal"
    (updateResponsible)="checkUser($event)">
  </project-success-card>
  <div *ngIf="canEdit" class="btn-section btn-section--center">
    <button type="button" (click)="newSuccessStory()"
    class="action-button action-button--primary icon--left" [disabled]="addDisabled">
    <icon iconId="plus" data-qa="icon-plus"></icon> Add Success Story
  </button>
  </div>
</ng-template>
