<div *ngIf="inDiv">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-container *ngIf="!inDiv" [ngTemplateOutlet]="content"></ng-container>

<ng-template #content>
  <dt class="project-card__status-title">{{term}}</dt>
  <dd class="project-card__status-text">{{description}}
    <ng-content></ng-content>
  </dd>
</ng-template>