import { of, concat } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { IWorkplanState, State, Root, Actions as DomainActions } from '@app-ngrx-domains';
import { forOwn, identity, omit, pickBy } from 'lodash';
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { WORKPLAN_ACTION_TYPES } from './workplan.action';

@Injectable()
export class WorkplanEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {
  }

  /**
   * Adds workplan item.
   */
   addItem$ = createEffect(() => this.actions$.pipe(
    ofType(WORKPLAN_ACTION_TYPES.ADD_ITEM),
    map(toPayload),
    mergeMap(req => {
      // persist changes
      const workplan = { ...req.workplan };
      const institution_id = workplan.institution_id;
      if (institution_id) {
        delete workplan.institution_id;
      }

      return this.apiService.createWorkplanItem(workplan).pipe(
        mergeMap(wpResponse => {
          if (institution_id) {
            // associate institution.
            return this.apiService.addWorkplanCollege(wpResponse.id, institution_id, false).pipe(map(wpInstResponse => {
              wpResponse.institutions.push(wpInstResponse.institution);
              return DomainActions.Workplan.addItemSuccess(wpResponse);
            }));
          } else {
            // bundle actions.
            const actions: Action[] = [];
            // send out added action.
            actions.push(DomainActions.Workplan.addItemSuccess(wpResponse));
            return concat([
              ...actions
            ]);
          }
        }),
        // unhandled exception occurred.
        catchError((error) => of(DomainActions.Workplan.addItemFail(error)))
      );
    })
  ));

  /**
   * Deletes workplan item.
   */
   deleteItem$ = createEffect(() => this.actions$.pipe(
    ofType(WORKPLAN_ACTION_TYPES.DELETE_ITEM),
    map(toPayload),
    mergeMap(itemId => {
      // persist changes
      return this.apiService.deleteWorkplanItem(itemId).pipe(
        // send out deleted action
        map((res) => DomainActions.Workplan.deleteItemSuccess(itemId)),
        catchError((error) => of(DomainActions.Workplan.deleteItemFail(error)))
      );
    })
  ));

  /**
   * Duplicates workplan item.
   */
   duplicateItem$ = createEffect(() => this.actions$.pipe(
    ofType(WORKPLAN_ACTION_TYPES.DUPE_ITEM),
    map(toPayload),
    mergeMap((req: any) => {
      // remove id & arrays
      let workplan = omit(req.item, ['id', 'strategies', 'institutions', 'institution_ids']);
      // remove undefined & nulls
      workplan = pickBy(workplan, identity);
      return this.apiService.createWorkplanItem(workplan).pipe(
        map((response) => DomainActions.Workplan.addItemSuccess(response)),
        // unhandled exception occurred.
        catchError((error) => of(DomainActions.Workplan.addItemFail(error)))
      );
    })
  ));

  /**
   * Updates one or more fields in workplan item.
   */
   updatWorkplanItemField$ = createEffect(() => this.actions$.pipe(
    ofType(WORKPLAN_ACTION_TYPES.UPDATE_ITEM),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Workplan), (payload: any, wp: IWorkplanState) => ({ ...payload, wp: wp })),
    mergeMap(options => {

      /**
       * Returns true if fields to be updated have changed.
       * @param fields
       */
      const hasChanged = (fields): boolean => {
        if (!item) {
          // no item to compare.
          return true;
        }

        // now loop through the fields and see if the values are different
        let changed = false;
        forOwn(fields, (value, key) => {
          if (item[key] !== value) {
            changed = true;
            // done...
            return false;
          }
        });

        return changed;
      };

      // get the item that's about to be updated
      const item = options.wp.items.filter((it, index) => { return it.id === options.itemId; })[0];
      // and persist change if the values have changed.
      if (hasChanged(options.fields)) {
        // persist changes.
        return this.apiService.updateWorkplan(options.itemId, options.fields).pipe(
          // send out updated action.
          map((response) => DomainActions.Workplan.updateItemSuccess(response)),
          // unhandled exception occurred.
          catchError((error) => of(DomainActions.Workplan.updateItemFail(error))));
      } else {
        return of(DomainActions.Workplan.updateItemNoOp());
      }
    })
  ));

  /**
   * Updates field in workplan item - adds college item.
   */
   addItemCollege$ = createEffect(() => this.actions$.pipe(
    ofType(WORKPLAN_ACTION_TYPES.ADD_ITEM_COLLEGE),
    map(toPayload),
    mergeMap(options => {
      // persist changes
      return this.apiService.addWorkplanCollege(options.itemId, options.institution_id, options.is_lead).pipe(
        // send out add college action.
        map((response) => {
          return DomainActions.Workplan.addItemCollegeSuccess(options.itemId, options.institution_id, response);
        }),
        // unhandled exception occurred.
        catchError((error) => of(DomainActions.Workplan.addItemCollegeFail(error)))
      );
    })
  ));

  /**
   * Updates field in workplan item - removes college item.
   */
   removeItemCollege$ = createEffect(() => this.actions$.pipe(
    ofType(WORKPLAN_ACTION_TYPES.REMOVE_ITEM_COLLEGE),
    map(toPayload),
    mergeMap(options =>
      // persist changes
      this.apiService.deleteWorkplanCollege(options.itemId, options.institution_id).pipe(
        // send out remove college action.
        map(() => DomainActions.Workplan.removeItemCollegeSuccess(options.itemId, options.institution_id)),
        catchError((error) => of(DomainActions.Workplan.removeItemCollegeFail(error)))
    ))
  ));

  /**
   * Updates field in workplan item - adds annual plan strategy.
   */
   addItemStrategy$ = createEffect(() => this.actions$.pipe(
    ofType(WORKPLAN_ACTION_TYPES.ADD_ITEM_STRATEGY),
    map(toPayload),
    mergeMap(options => {
      // persist changes
      return this.apiService.updateWorkplanStrategy(options.itemId, options.strategy_id).pipe(
        // send out add strategy action.
        map((response) => DomainActions.Workplan.addItemStrategySuccess(options.itemId, options.strategy_id, response)),
        catchError((error) => of(DomainActions.Workplan.serviceFail(error)))
      );
    })
  ));

  /**
   * Updates field in workplan item - removes annual plan strategy.
   */
   removeItemStrategy$ = createEffect(() => this.actions$.pipe(
    ofType(WORKPLAN_ACTION_TYPES.REMOVE_ITEM_STRATEGY),
    map(toPayload),
    mergeMap(options =>
      // persist changes
      this.apiService.deleteWorkplanStrategy(options.itemId, options.strategy_id).pipe(
        // send out remove college action.
        map(() => DomainActions.Workplan.removeItemStrategySuccess(options.itemId, options.strategy_id)),
        catchError((error) => of(DomainActions.Workplan.serviceFail(error)))
    ))
  ));
}
