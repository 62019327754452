import { NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener, OnInit, Input, OnDestroy } from '@angular/core';
import { Utilities } from '@app-models';
import { CurrencyPipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[integerCurrency]'
})
export class IntegerCurrencyTransformerDirective implements OnInit, OnDestroy {

  @Input() negCurrency?: boolean; // Can be negative currency
  @Input() defaultToNull?: boolean = true;

  private value: number;
  private focused: boolean;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private control: NgControl,
    private el: ElementRef,
    private currencyPipe: CurrencyPipe
  ) {}

  @HostListener('change', ['$event']) onChange(event) {
    this.transformValue(event.target.value);
    this.setFormValues();
  }

  @HostListener('focus', ['$event']) onFocus(event) {
    this.focused = true;
    event.target.select();
  }

  @HostListener('blur', ['$event']) onBlur(event) {
    this.focused = false;
  }

  ngOnInit() {
    const parent = this.el.nativeElement;

    const savedValue = parent.value;
    if (savedValue) {
      this.transformValue(savedValue);
      this.setFormValues();
    }

    /*
      Subscribe to changes directly to the formControl to update the valueAccessor
      Needed for fiscal-report-card.component and rsi-lea-card.component when values are
      updated on tab change
     */
    this.control.control.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(event => {
      if (this.focused || this.value === event) {
        // Ignore the change, user is either still focused on field or is a stale event
        return;
      }

      this.transformValue(event);
      if (this.formatted) {
        this.control.valueAccessor.writeValue(this.formatted);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  transformValue(value: number | string) {
    if (typeof value === 'string') {
      value = value.replace(/[$,]/g, '');
    }

    if (Utilities.isNil(value) || value === '') {
      this.value = this.defaultToNull ? null : 0;
    } else {
      if (typeof value === 'string') {
        value = !!this.negCurrency
          ? value.replace(/[^.\-0-9]/g, '')
          : value.replace(/[^.0-9]/g, '');
      }
      const roundedValue = Math.floor(Number(value));
      if (roundedValue || roundedValue === 0) {
        this.value = roundedValue;
      } else {
        this.value = this.defaultToNull ? null : 0;
      }
    }
  }

  get formatted() {
    return this.value === null ? null : this.currencyPipe.transform(this.value, 'USD', 'symbol', '1.0-0');
  }

  setFormValues() {
    this.control.control.setValue(this.value);
    this.control.valueAccessor.writeValue(this.formatted);
  }
}
