import { of } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IProposalsState, Root, State, Model } from '@app-ngrx-domains'
import { toPayload } from '@app-libs';
import { AttributesService } from '@app-services';
import { PROPOSALS_ACTION_PREFIX, PROPOSALS_ACTION_TYPES } from './proposals.action';

/**
 * Injectable Proposals effects class
 */
@Injectable()
export class ProposalsEffects {

  constructor(
    private actions$: Actions,
    private attributesService: AttributesService,
    private store: Store<State>
  ) {
  }

  private getProposalToEffect(req: any, proposals: IProposalsState): Model.ProposalBase {
    // there has to be at least one item to effect.
    if (proposals.items.length > 0) {
      let targetProposalId = (req.proposal_id) ? req.proposal_id : proposals.current_item_id;
      if (!targetProposalId) {
        // pick the first item.
        targetProposalId = proposals.items[0].id;
      }
      return proposals.items.find(p => p.id === targetProposalId);
    }
  }

  /**
   * Updates proposal attribute.
   */
   upsertAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(PROPOSALS_ACTION_TYPES.UPSERT_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Proposals)),
    mergeMap(([req, proposals]) => {
      const proposal = this.getProposalToEffect(req, proposals);
      if (!!proposal) {
        return this.attributesService.upsertAttribute$(PROPOSALS_ACTION_PREFIX, req, proposal, proposal.id);
      } else {
        return of(this.attributesService.throwErrorMessage('bad argument', ProposalsEffects.name));
      }
    })
  ));

  /**
   * Adds proposal multi attribute.
   */
   addMultiAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(PROPOSALS_ACTION_TYPES.ADD_MULTI_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Proposals)),
    mergeMap(([req, proposals]) => {
      const proposal = this.getProposalToEffect(req, proposals);
      if (!!proposal) {
        return this.attributesService.addMultiAttribute$(PROPOSALS_ACTION_PREFIX, req, proposal, proposal.id);
      } else {
        return of(this.attributesService.throwErrorMessage('bad argument', ProposalsEffects.name));
      }
    })
  ));

  /**
   * Deletes proposal multi attribute.
   */
   deleteMultiAttribute$ = createEffect(() => this.actions$.pipe(
    ofType(PROPOSALS_ACTION_TYPES.DELETE_MULTI_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Proposals)),
    mergeMap(([req, proposals]) => {
      const proposal = this.getProposalToEffect(req, proposals);
      if (!!proposal) {
        return this.attributesService.deleteMultiAttribute$(PROPOSALS_ACTION_PREFIX, req, proposal, proposal.id);
      } else {
        return of(this.attributesService.throwErrorMessage('bad argument', ProposalsEffects.name));
      }
    })
  ));

  /**
   * Creates effort area & an attribute.
   */
   creatAttributeEffortArea$ = createEffect(() => this.actions$.pipe(
    ofType(PROPOSALS_ACTION_TYPES.CREATE_ATTRIBUTE_EFFORT_AREA),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Proposals)),
    mergeMap(([req, proposals]) => {
      const proposal = this.getProposalToEffect(req, proposals);
      if (!!proposal) {
        return this.attributesService.creatAttributeEffortArea$(PROPOSALS_ACTION_PREFIX, req, proposal.id);
      } else {
        return of(this.attributesService.throwErrorMessage('bad argument', ProposalsEffects.name));
      }
    })
  ));

  /**
   * Create effort area.
   */
   createEffortArea$ = createEffect(() => this.actions$.pipe(
    ofType(PROPOSALS_ACTION_TYPES.CREATE_EFFORT_AREA),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Proposals)),
    mergeMap(([req, proposals]) => {
      const proposal = this.getProposalToEffect(req, proposals);
      if (!!proposal) {
        return this.attributesService.createEffortArea$(PROPOSALS_ACTION_PREFIX, req, proposal.id);
      } else {
        return of(this.attributesService.throwErrorMessage('bad argument', ProposalsEffects.name));
      }
    })
  ));

   createMultiEffortAreas$ = createEffect(() => this.actions$.pipe(
    ofType(PROPOSALS_ACTION_TYPES.CREATE_MULTI_EFFORT_AREAS),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Proposals)),
    mergeMap(([req, proposals]) => {
      const proposal = this.getProposalToEffect(req, proposals);
      if (!!proposal) {
        return this.attributesService.createMultiEffortAreas$(PROPOSALS_ACTION_PREFIX, req, proposal.id);
      } else {
        return of(this.attributesService.throwErrorMessage('bad argument', ProposalsEffects.name));
      }
    })
  ));

  /**
   * Updates effort area.
   */
   updateEffortArea$ = createEffect(() => this.actions$.pipe(
    ofType(PROPOSALS_ACTION_TYPES.UPDATE_EFFORT_AREA),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Proposals)),
    mergeMap(([req, proposals]) => {
      const proposal = this.getProposalToEffect(req, proposals);
      if (!!proposal) {
        return this.attributesService.updateEffortArea$(PROPOSALS_ACTION_PREFIX, req, proposal.id);
      } else {
        return of(this.attributesService.throwErrorMessage('bad argument', ProposalsEffects.name));
      }
    })
  ));

  /**
   * Deletes effort area.
   */
   deleteEffortArea$ = createEffect(() => this.actions$.pipe(
    ofType(PROPOSALS_ACTION_TYPES.DELETE_EFFORT_AREA),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Proposals)),
    mergeMap(([req, proposals]) => {
      const proposal = this.getProposalToEffect(req, proposals);
      if (!!proposal) {
        return this.attributesService.deleteEffortArea$(PROPOSALS_ACTION_PREFIX, req, proposal.id);
      } else {
        return of(this.attributesService.throwErrorMessage('bad argument', ProposalsEffects.name));
      }
    })
  ));

  /**
   * Deletes list of effort areas.
   */
   deleteMultiEffortAreas$ = createEffect(() => this.actions$.pipe(
    ofType(PROPOSALS_ACTION_TYPES.DELETE_MULTI_EFFORT_AREAS),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Proposals)),
    mergeMap(([req, proposals]) => {
      const proposal = this.getProposalToEffect(req, proposals);
      if (!!proposal) {
        return this.attributesService.deleteMultiEffortAreas$(PROPOSALS_ACTION_PREFIX, req, proposal.id);
      } else {
        return of(this.attributesService.throwErrorMessage('bad argument', ProposalsEffects.name));
      }
    })
  ));
}
