import { AfterViewInit, Component, forwardRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Utilities } from '@app-models';

@Component({
  selector: 'po-time-picker',
  templateUrl: './time-picker.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimePickerComponent), multi: true }
  ],
})

export class TimePickerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() required: boolean = false; // picker is required
  @Output() timeChange: EventEmitter<string> = new EventEmitter();

  inputControl = new FormControl();
  private destroy$: Subject<boolean> = new Subject();
  private lastValue: string;

  constructor() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  ngAfterViewInit() {
    this.inputControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(formValue => {
      const value = !Utilities.isNil(formValue) ? Utilities.convertTime(formValue, true) : null;
      if (value !== this.lastValue) {
        this.lastValue = value;
        this.onChange(value);
        if (this.timeChange) {
          this.timeChange.emit(value);
        }
      }
    });
  }

  /////////////////////////////////////////////////////////////////////
  // ControlValueAccessor
  /////////////////////////////////////////////////////////////////////
  writeValue(value: string) {
    if (!Utilities.isNil(value)) {
      // set the initial value.
      this.lastValue = Utilities.convertTime(value);
      this.inputControl.setValue(this.lastValue);
    }
  }

  onChange = (_: any) => { };
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  onTouched = (_: any) => { };
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
  }
}
