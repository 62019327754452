import {AfterContentInit, Component, forwardRef, Input, Output, EventEmitter, ElementRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AppUtils } from '@app-utilities';
import { DATE_TIME_MOMENT_FORMAT_STRINGS } from '@app-consts';
import * as moment from 'moment';

const MONTH_YEAR_FORMATS = {
  parse: {
    dateInput: DATE_TIME_MOMENT_FORMAT_STRINGS.monthYear,
  },
  display: {
    dateInput: DATE_TIME_MOMENT_FORMAT_STRINGS.monthYear,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'po-month-year-picker',
  templateUrl: './month-year-picker.component.html',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MonthYearPickerComponent), multi: true }
  ],
})

export class MonthYearPickerComponent implements ControlValueAccessor, AfterContentInit {
  @Input() labelText: string;
  @Input() labelHidden = false;
  @Input() canEdit = true;
  @Input() errorsOnUntouched = false;
  @Input() placeHolder: string = 'mm/yyyy';
  @Input() required: boolean = false;
  @Input() minDateString: string = '2007-01-01';
  @Input() maxDateString: string = '2030-12-31';
  @Output() dateChange: EventEmitter<string> = new EventEmitter();

  inputControl = new FormControl();
  minDate: Date;
  maxDate: Date;

  inputId: string;

  constructor(private el: ElementRef) {
    this.inputId = `${this.el.nativeElement.id}_picker_input`;
  }

  ngAfterContentInit() {
    this.minDate = moment.tz(this.minDateString, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone).toDate();
    this.maxDate = moment.tz(this.maxDateString, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone).toDate();
  }

  inputChange(event: MatDatepickerInputEvent<Date>) {
    this.inputControl.setValue(event.value);
    this.valueChanged();
  }

  chosenYearHandler(normalizedYear: Date) {
    const ctrlValue = this.inputControl.value;
    if (ctrlValue) {
      ctrlValue.year(moment(normalizedYear).year());
      this.inputControl.setValue(ctrlValue);
    } else {
      this.inputControl.setValue(normalizedYear);
    }
  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    const ctrlValue = this.inputControl.value;
    if (ctrlValue) {
      ctrlValue.month(moment(normalizedMonth).month());
      this.inputControl.setValue(ctrlValue);
    } else {
      this.inputControl.setValue(normalizedMonth);
    }

    this.valueChanged();
    datepicker.close();
  }

  private lastValue: string;
  private valueChanged() {
    const value = !AppUtils.isNil(this.inputControl.value) ? this.inputControl.value.toISOString() : null;

    if (value !== this.lastValue) {
      this.lastValue = value;
      this.onChange(value);
      if (this.dateChange) {
        this.dateChange.emit(value);
      }
    }
  }

  showLabel() {
    return this.labelText && !this.labelHidden;
  }

  getReadOnlyText() {
    return moment(this.inputControl.value).format(DATE_TIME_MOMENT_FORMAT_STRINGS.monthYear);
  }

  /////////////////////////////////////////////////////////////////////
  // ControlValueAccessor
  /////////////////////////////////////////////////////////////////////
  writeValue(value: string) {
    if (!AppUtils.isNil(value)) {
      // set the initial value.
      this.lastValue = value;
      this.inputControl.setValue(moment(value));
    }
  }

  onChange = (_: any) => { };
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  onTouched = (_: any) => { };
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
  }
}
