import { Directive, HostBinding, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[stickyCard]',
})
export class StickyCardDirective {

  @HostBinding('class.sticky-card') @Input('stickyCard') apply = true;
  containerClass = 'content-body__container--has-sticky-card';

  constructor(
    private renderer: Renderer2,
  ) { }

  ngAfterViewInit(): void {
    if (this.apply) {
      this.setContainerClass(true);
    }
  }

  ngOnDestroy() {
    if (this.apply) {
      this.setContainerClass(false);
    }
  }

  setContainerClass(addClass) {
    const container = document.querySelector('.content-body__container');
    if (!container) {
      return;
    }

    if (addClass) {
      this.renderer.addClass(container, this.containerClass);
    } else {
      this.renderer.removeClass(container, this.containerClass);
    }
  }

}
