import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AREAS, PROGRAM_KEYS, WORKFLOW_STEPS, PROJECT_ROLES, PROPOSAL_TYPES } from '@app/core/consts';
import { PermissionsService, ProgramService } from '@app/core/services';
import { Actions, Model, Queries, State } from '@app-ngrx-domains';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ProposalBase as Proposal } from '@app-models';


@Component({
  selector: 'project-success',
  templateUrl: './success.component.html'
})
export class SuccessComponent implements OnInit, OnDestroy {
  @Input() isPreview: boolean = false;
  @Input() hideTitle: boolean = false;
  @Input() guidance: Model.GuidanceWorkflowFilter;

  public canEdit = false;
  public canDelete = true;
  public firstTouch = false;
  public addDisabled = false;

  public proposal: Model.ProposalBase;
  public contacts: Array<Model.UserRoleScope>;
  public successStoryItems: Array<Model.EASuccessStory> = [];
  public programKey: string;
  public supportsGuidance: boolean = false;


  private destroy$ = new Subject();
  private initialized = false;
  private currentWorkflowStep = WORKFLOW_STEPS.SUCCESS;
  private currentWorkflowTemplateName: string;


  constructor(
    private permissionsService: PermissionsService,
    private programService: ProgramService,
    private route: ActivatedRoute,
    private store: Store<State>
  ) {}

  ngOnInit() {
    if (!!this.guidance) {
      // in template mode...prepare to show as guidance template.
      this.setupAsTemplate();
      return;
    }

    combineLatest([
      this.store.select(Queries.CurrentProposal.get),
      this.store.select(Queries.Contacts.get)
    ]).pipe(
      withLatestFrom(this.store.select(Queries.Workflow.getCurrent)),
      filter(([[p, c], w]) => p && p.id && !!c && !!w),
      takeUntil(this.destroy$)
    ).subscribe(([[proposal, contacts], current_workflow]) => {

      this.proposal = proposal;
      this.currentWorkflowTemplateName = current_workflow.templateName || current_workflow.name;
      this.contacts = contacts;
      this.successStoryItems = proposal.success_story;
      this.addDisabled = !(this.successStoryItems.every(story => story.id > 0));

      if (this.successStoryItems.length < 1) {
        this.newSuccessStory();
      } else {
        // this.canDelete = this.successStoryItems.length > 1; // allow any story to be removable
        this.initialize();
      }
    });
  }

  private initialize() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    this.programKey = this.proposal.funds[0].parent_key || this.proposal.funds[0].key;
    this.supportsGuidance = ![PROGRAM_KEYS.GP].includes(this.programKey);

    // set up workflow filter so guidance can be retrieved.
    if (!this.guidance) {
      this.guidance = {
        programId: this.proposal.funds[0].id,
        proposalType: PROPOSAL_TYPES.APPLICATION,
        workflowName: this.currentWorkflowTemplateName,
        stepName: this.currentWorkflowStep,
      }
    }

    if (!this.isPreview) {
      this.store.dispatch(Actions.Workflow.setCurrentStep(this.currentWorkflowStep, true));
      this.fetchPermissions();
    }
  }

  private setupAsTemplate() {
    this.supportsGuidance = true;
  }

  private fetchPermissions() {
    this.permissionsService.canEditProject(this.proposal, AREAS.PROJECT, this.route.snapshot)
      .pipe(takeUntil(this.destroy$))
      .subscribe(allowed => {
        const isDraft = Proposal.projectIsDraft(this.proposal);
        const isCertified = Proposal.projectIsCertified(this.proposal);
        this.canEdit = allowed && (isDraft || isCertified);
      });
  }

  public newSuccessStory() {
    this.store.dispatch(Actions.CurrentProposal.createTempEffortArea({
      ea: {
        effort_area_type: 'success_story',
        parent_proposal_id: this.proposal.id,
        title: undefined,
        description: undefined,
        challenge: undefined,
        outcomes: undefined,
        contacts: [],
        goal_types: [],
      }
    }));
    this.addDisabled = true;
  }

  public removeStoryItem(story: Model.EASuccessStory) {
    if (this.successStoryItems.length === 1) {
      this.newSuccessStory();
    }
    this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({ ea: story }))
  }

  /**
   * Call to add/delete responsible person roles on success story
   * @param user_id user to check
   */
  public checkUser(update: {user_id: number, deleted: boolean, ea_id: number}) {
    const {user_id, deleted, ea_id} = update;
    const scope = {
      fund_id: this.proposal.fund_ids[0],
      user_id: user_id,
      role_id: PROJECT_ROLES.RESPONSIBLE_PERSON.ID,
      proposal_id: this.proposal.id,
    }
    if (deleted && !this.successStoryItems.some(story => story.id !== ea_id && story.contacts.some(c => c.value === user_id))) {
      this.store.dispatch(Actions.Contacts.delete(scope));
    }

    if (!deleted && !this.contacts.some(urs => urs.user_id === user_id && urs.role_id === PROJECT_ROLES.RESPONSIBLE_PERSON.ID)) {
      this.store.dispatch(Actions.Contacts.add(scope));
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public trackById(_: number, item: any): number {
    return item.temp_id ? item.temp_id : item.id;
  }
}
