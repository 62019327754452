import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class DashboardFilterService {

  private filterSource$: BehaviorSubject<DashboardFilterEvent> = new BehaviorSubject(null);
  public filter$: Observable<DashboardFilterEvent> = this.filterSource$.asObservable();

  constructor() { }

  public applyFilter(filterEvent: DashboardFilterEvent) {
    this.filterSource$.next(filterEvent);
  }
}

export interface DashboardFilterEvent {
  program_ids?: Array<number>;
  institution_ids?: Array<number>;
  event_types?: Array<string>;
  custom_events?: Array<string>;
  system_events?: Array<string>;
  event_names?: Array<string>;
}
