<fieldset class="fieldset" [class.flex]="!!inline">
  <ng-container [ngSwitch]="isCommentsOpen && !!inputGroupId">
    <ng-container *ngSwitchCase="true">
      <legend class="legend" *ngIf="headingText" [innerHtml]="headingText | safeHtml" [class.required]="isRequired()" [class.screen-reader-text]="legendHidden"
      tabindex=0 [id]="inputGroupId" (focus)="handleFocusIn();" (focusout)="handleFocusOut()"></legend>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <legend class="legend" *ngIf="headingText" [innerHtml]="headingText | safeHtml" [class.required]="isRequired()" [class.screen-reader-text]="legendHidden"></legend>
    </ng-container>
  </ng-container>

  <ng-content select=".help-text"></ng-content>

  <ng-content></ng-content>

  <show-errors *ngIf="showErrors()" [control]="control" [showUntouched]="errorsOnUntouched" class="error-text"></show-errors>
</fieldset>
