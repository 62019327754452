<ng-container [ngSwitch]="!isPreview">

  <!-- Editable Form -->
  <ng-container *ngSwitchCase="true">
    <!-- Lead Agencies -->
    <ng-container *ngIf="showLeadAgency">
      <h2 class="header-title h2 mb-l" id="qa_tab_title">Lead Institution & Partners</h2>
      <po-guidance-text [workflowFilter]="guidance"></po-guidance-text>

      <div class="card">
        <div class="card-title">
          <h3>Lead Institution</h3>
          <div class="help-text" *ngIf="canEdit">
            <p>
              Select your Local Education Agency from the existing list of LEAs in NOVA.
            </p>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="leadAgencyTemplate"></ng-container>
      </div>
    </ng-container>

    <!-- Partner Agencies -->
    <h2 class="header-title h2 mb-l" id="qa_partners_section_title">{{title}}</h2>
    <po-guidance-text *ngIf="!showLeadAgency" [workflowFilter]="guidance"></po-guidance-text>

    <div *ngFor="let agency of partnerAgencies; let i = index; trackBy: trackById" class="card">
      <div class="card-title">
        <h3>Partner/Employer</h3>
      </div>

      <app-agency-item
        [canEdit]="canEdit"
        [isPreview]="isPreview"
        [isLead]="false"
        [isApplication]="isApplication"
        [showEmployerAlert]="noEmployerSelected"
        [selectedAgency]="agency"
        [appContribution]="getAgencyAppContribution(agency)"
        [existingAgencyIds]="getExistingAgencyIds()"
        [agencyTypes]="partnerAgencyTypes"
        [proposalId]="proposalId"
        [fundId]="fund.id"
        [contactRoleId]="contactRole"
        [firstTouch]="firstTouch"
        [count]="i+1">
      </app-agency-item>
    </div>

    <ng-container *ngTemplateOutlet="agencyPlaceholder"></ng-container>

    <ng-container *ngIf="!placeholderActive && !partnerAgencies?.length" [ngTemplateOutlet]="noPartners"></ng-container>

    <div *ngIf="canEdit" class="btn-section btn-section--center">
      <button type="button" (click)="addPartnerAgency()" class="action-button action-button--primary icon--left" [disabled]="placeholderActive">
        <icon iconId="plus"></icon>
        Add Partner Agency
      </button>
    </div>
  </ng-container>

  <!-- Preview -->
  <ng-container *ngSwitchCase="false">
    <div *ngIf="showLeadAgency || partnerAgencies?.length" class="card">
      <ng-container *ngIf="showLeadAgency">
        <h3>Lead Institution</h3>
        <ng-container *ngTemplateOutlet="leadAgencyTemplate"></ng-container>
        <hr>
      </ng-container>

      <div *ngFor="let agency of partnerAgencies; let i=index; let first = first; trackBy: trackById;">
        <hr *ngIf="!first">
        <h3>Partner/Employer</h3>
        <app-agency-item
          [canEdit]="canEdit"
          [isPreview]="isPreview"
          [isLead]="false"
          [isApplication]="isApplication"
          [selectedAgency]="agency"
          [appContribution]="getAgencyAppContribution(agency)"
          [agencyTypes]="partnerAgencyTypes"
          [contactRoleId]="contactRole"
          [proposalId]="proposalId"
          [fundId]="fund.id"
          [firstTouch]="firstTouch"
          [count]="i+1">
        </app-agency-item>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- Lead Agency Template -->
<ng-template #leadAgencyTemplate>
  <app-agency-item
    [canEdit]="canEdit"
    [isPreview]="isPreview"
    [isLead]="true"
    [isApplication]="isApplication"
    [selectedAgency]="leadAgency"
    [appContribution]="getAgencyAppContribution(leadAgency)"
    [existingAgencyIds]="getExistingAgencyIds()"
    [agencyTypes]="leadAgencyTypes"
    [contactRoleId]="leadContactRole"
    [proposalId]="proposalId"
    [fundId]="fund.id"
    [firstTouch]="firstTouch"
    [count]="666">
  </app-agency-item>
</ng-template>

<!-- Partner Agency Place Holder Template -->
<ng-template #agencyPlaceholder>
  <div *ngIf="placeholderActive" class="card">
    <div class="card-title">
      <h3>Partner/Employer Agency</h3>
      <div class="help-text" *ngIf="canEdit">
        <p>
          Select your partner from the existing list of businesses, community/philanthropic organizations,
          industries, and workforce development boards in NOVA.
        </p>
      </div>
    </div>

    <app-agency-item
      [canEdit]="canEdit"
      [isPreview]="isPreview"
      [isLead]="false"
      [isApplication]="isApplication"
      [selectedAgency]="null"
      [existingAgencyIds]="getExistingAgencyIds()"
      [agencyTypes]="partnerAgencyTypes"
      [contactRoleId]="contactRole"
      [proposalId]="proposalId"
      [fundId]="fund.id"
      [firstTouch]="firstTouch"
      (agencySaved)="placeholderActive=false">
    </app-agency-item>
  </div>
</ng-template>

<ng-template #noPartners>
  <p class="empty-state">No Partner Agencies have been added.</p>
</ng-template>
