
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Model, State } from '@app-ngrx-domains';
import { CHAR_LIMITS, EVENT_NAMES } from '@app/core/consts';
import { DashboardFilterService } from '@app/core/services/dashboard-filter.service';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { Utilities } from '../../models';
import { ApiService, LookupService, ProgramService } from '../../services';
import { sortBy, pull, uniq, flatten, cloneDeep } from 'lodash';
import { forkJoin, fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
})
export class DashboardFilterComponent implements OnInit, OnDestroy {

  public filterOpen: boolean = false;
  public filterForm: FormGroup;
  public applyFilterDisabled: boolean;

  public programOptions: Array<Model.SelectOption> = [];
  public institutionOptions: Array<Model.SelectOption> = [];
  public eventTypeOptions: Array<Model.SelectOption> = [];
  public systemEventOptions: Array<Model.SelectOption> = [];
  public customEventOptions: Array<Model.SelectOption> = [];

  public filterList: Array<Model.SelectOption & { filterType: string }> = [];
  public hiddenFilterList: Array<Model.SelectOption & { filterType: string }> = [];
  public moreFiltersOpen: boolean = false;
  public emptyFilter;
  public currentFilter;
  public hiddenFilterCount: number = 0;

  public dashboardList = [];
  public dashboardOptions = [];
  public dashboards = {};
  public dashboardForm: FormGroup;
  public nameLength = CHAR_LIMITS.SHORT;
  public showSaveDashboardModal: boolean = false;
  public showManageDashboardsModal: boolean = false;

  public currentDashboardForm: FormGroup;
  public modalDashboardForm: FormGroup;

  public showDeleteDashboardAlert: boolean = false;
  public dashboardToDelete;

  private systemEvents = Object.values(EVENT_NAMES);
  private eventMappings = {
    [EVENT_NAMES.DUE_DATE]: 'Submit',
    [EVENT_NAMES.CERITIFICATION_DATE]: 'Certify'
  };

  private filterKey: 'savedFilters/dashboard';

  private destroy$: Subject<boolean> = new Subject();

  @ViewChild('filterToggle') filterToggle: ElementRef;

  constructor(
    private apiService: ApiService,
    private store: Store<State>,
    public router: Router,
    private _fb: FormBuilder,
    private lookupService: LookupService,
    private programService: ProgramService,
    private filterService: DashboardFilterService
  ) { }

  ngOnInit() {
    const options = this.systemEvents.map(name => this.getSystemEventOption(name));
    this.systemEventOptions = sortBy(options, ['label']);

    this.filterForm = this._fb.group({
      program_ids: [[]],
      institution_ids: [[]],
      event_types: [[]],
      system_events: [[]],
      custom_events: [[]]
    });

    this.emptyFilter = cloneDeep(this.filterForm.value);

    this.dashboardForm = this.createDashboardForm();
    this.modalDashboardForm = this.createDashboardForm();

    forkJoin([
      this.programService.smallPrograms.pipe(filter(sp => !!sp), take(1)),
      this.apiService.getEventNames(),
      this.apiService.getEventTypes(),
      this.apiService.getDashboards()
    ]).subscribe(([smallPrograms, eventNames, eventTypes, dashboards]) => {
      let parentPrograms = this.programService.parentPrograms;
      let programs = [...parentPrograms, ...smallPrograms].sort((a, b) => a.short_name > b.short_name ? 1 : -1);
      this.programOptions = programs.map(p => ({ value: p.id, label: p.short_name }));

      this.customEventOptions = eventNames
        .filter(e => !this.systemEvents.includes(e))
        .map(e => ({ value: e, label: e }));

      this.eventTypeOptions = eventTypes.map(e => ({ value: e, label: e }));

      this.dashboardList = dashboards;

      const institutionIds = uniq(flatten(dashboards.map(d => d.institution_ids)));

      this.apiService.listInstitutions({ institution_ids: institutionIds }).subscribe(institutionList => {
        const institutions = institutionList.reduce((obj, inst) => {
          obj[inst.id] = inst;
          return obj;
        }, {});

        dashboards.forEach(dashboard => {
          this.dashboardOptions.push({
            label: dashboard.name,
            value: dashboard.id
          });

          const customEvents = [];
          const systemEvents = [];
          dashboard.event_names.forEach(eventName => {
            this.systemEvents.includes(eventName) ? systemEvents.push(eventName) : customEvents.push(eventName);
          });

          const filter = {
            program_ids: dashboard.program_ids,
            institution_ids: dashboard.institution_ids.map(id => ({ label: institutions[id].name, value: id })),
            event_types: dashboard.event_types,
            custom_events: customEvents,
            system_events: systemEvents
          };

          dashboard.filter = filter;
          dashboard.filterList = this.getFilterList(filter);

          this.dashboards[dashboard.id] = dashboard;
        });

        this.initFilters();
      });
    })
  }

  initFilters() {
    const savedFilters = localStorage.getItem(this.filterKey);
    if (savedFilters) {
      const { dashboardId, filter } = JSON.parse(savedFilters);

      if (dashboardId) {
        this.dashboardForm.get('dashboard_id').setValue(dashboardId);
        this.selectDashboard();
      } else if (filter) {
        this.filterForm.setValue(filter);
        this.setFilter();
      }
    } else {
      this.filterService.applyFilter(null);
    }
  }

  ngAfterViewInit() {
    // Update hidden filter count when resizing window
    fromEvent(window, 'resize').pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe(() => this.updateHiddenFilters());
  }

  // Close all popups when clicking outside of them
  @HostListener('document:click', ['$event']) onClick() {
    this.closeFilterDropdowns();
  }

  filterRemoved(filter: Model.SelectOption & { filterType: string }) {
    this.dashboardForm.reset();

    const value = filter.value;
    const filterType = filter.filterType;
    const filters = this.currentFilter[filterType];
    if (filterType === 'institution_ids') {
      this.currentFilter[filterType] = filters.filter(f => f.value !== value);
    } else {
      pull(filters, value);
    }
    pull(this.filterList, filter);
    pull(this.hiddenFilterList, filter);
    if (!this.hiddenFilterList.length && this.moreFiltersOpen) {
      this.moreFiltersOpen = false;
    }

    this.applyFilter(this.currentFilter);

    this.updateHiddenFilters();
  }

  getFilterList(filter) {
    const filterList = [];
    Object.entries(filter).forEach(([key, values]: any) => {
      switch (key) {
        case 'program_ids':
          for (let programId of values) {
            let option = this.programOptions.find(o => o.value === programId);
            filterList.push({
              ...option,
              filterType: key
            });
          }
          break;

        case 'institution_ids':
          values.forEach(option => {
            filterList.push({
              ...option,
              filterType: key
            });
          });
          break;

        case 'system_events':
          for (let value of values) {
            filterList.push({
              ...this.getSystemEventOption(value),
              filterType: key
            });
          }
          break;

        default:
          for (let value of values) {
            filterList.push({
              label: value,
              value,
              filterType: key
            });
          }
      }
    });

    return filterList;
  }

  setFilter() {
    const filter = this.filterForm.value;
    this.filterList = this.getFilterList(filter);
    this.currentFilter = filter;

    this.closeFilterDropdowns();
    this.dashboardForm.reset();

    this.applyFilter(filter);
    this.updateHiddenFilters();
  }

  applyFilter(filter) {
    this.filterService.applyFilter(this.getParams(filter));
    this.saveFilters();
  }

  clearFilter() {
    this.closeFilterDropdowns();
    this.currentFilter = null;
    this.filterService.applyFilter(null);
    this.filterList = [];
    this.hiddenFilterList = [];
    this.updateHiddenFilters();
    this.dashboardForm.reset();
    localStorage.removeItem(this.filterKey);
  }

  saveFilters() {
    const filters = {
      dashboardId: this.dashboardForm.get('dashboard_id').value,
      filter: this.currentFilter
    };
    localStorage.setItem(this.filterKey, JSON.stringify(filters));
  }

  toggleFilterDropdown(event?) {
    if (event) {
      event.stopPropagation();
    }

    this.filterOpen = !this.filterOpen;

    if (this.filterOpen && this.currentFilter) {
      this.filterForm.setValue(this.currentFilter);
    }
  }

  closeFilterDropdowns() {
    this.moreFiltersOpen = false;
    this.filterOpen = false;
    this.resetFilterDropdown();
  }

  resetFilterDropdown() {
    this.filterForm.reset(cloneDeep(this.emptyFilter));
  }

  toggleMoreFilters(event) {
    event.stopPropagation();
    this.moreFiltersOpen = !this.moreFiltersOpen;
  }

  updateHiddenFilters() {
    // Calculate how many filters are hidden by the overflow
    const updateFilters = () => {
      this.hiddenFilterCount = 0;
      const filterRect = this.filterToggle.nativeElement.getBoundingClientRect();
      const filterItems = document.querySelectorAll('.filter-list > .filter-item');
      filterItems.forEach(item => {
        const itemRect = item.getBoundingClientRect();
        if (itemRect.y > filterRect.y) {
          this.hiddenFilterCount++;
        }
      })
    };

    setTimeout(() => {
      const previousCount = this.hiddenFilterCount;
      updateFilters();

      setTimeout(() => {
        // The "X+ more" button showing up changes the available width so calculate the
        // hidden filters again if that happens
        if (!previousCount && this.hiddenFilterCount > 0) {
          updateFilters();
        }

        this.hiddenFilterList = this.filterList.slice(-this.hiddenFilterCount);
      });
    });
  }

  openSaveDashboardModal(dashboardForm: FormGroup, dashboardId?: number) {
    this.currentDashboardForm = dashboardForm;
    if (dashboardId) {
      dashboardForm.get('dashboard_id').setValue(dashboardId);
    }
    this.showSaveDashboardModal = true;
  }

  closeSaveDashboardModal(save?: boolean) {
    this.showSaveDashboardModal = false;
    if (save) {

      const name = this.currentDashboardForm.get('name').value;
      const dashboardIdCtrl = this.currentDashboardForm.get('dashboard_id');
      const currentDashboardId = dashboardIdCtrl.value;

      if (currentDashboardId) {
        this.apiService.updateDashboard(currentDashboardId, { name }).subscribe(() => {
          this.dashboards[currentDashboardId].name = name;
          const option = this.dashboardOptions.find(o => o.value === currentDashboardId);
          option.label = name;
          dashboardIdCtrl.updateValueAndValidity();
        });
      } else {
        const filter = this.getParams(this.currentFilter);
        this.apiService.createDashboard({
          name,
          program_ids: filter.program_ids,
          institution_ids: filter.institution_ids,
          event_types: filter.event_types,
          event_names: [...filter.system_events, ...filter.custom_events]
        }).subscribe(dashboard => {
          const dashboardId = dashboard.id;

          this.dashboardOptions.push({
            label: dashboard.name,
            value: dashboardId
          });

          dashboard.filter = cloneDeep(this.currentFilter);
          dashboard.filterList = cloneDeep(this.filterList);

          this.dashboardList.push(dashboard);
          this.dashboards[dashboardId] = dashboard;

          dashboardIdCtrl.setValue(dashboardId);
        });
      }
    }

    this.dashboardForm.get('name').reset();
    this.modalDashboardForm.reset();
    this.currentDashboardForm = null;
  }

  toggleManageDashboardsModal() {
    this.showManageDashboardsModal = !this.showManageDashboardsModal;
  }

  openDeleteDashboardAlert(dashboard?) {
    this.dashboardToDelete = dashboard || this.dashboards[this.dashboardForm.get('dashboard_id').value];
    this.showDeleteDashboardAlert = true;
  }

  closeDeleteDashboardAlert(confirm?: boolean) {
    if (confirm) {
      const dashboardId = this.dashboardToDelete.id;
      this.apiService.deleteDashboard(dashboardId).subscribe(() => {
        this.dashboardOptions = this.dashboardOptions.filter(o => o.value !== dashboardId);
        this.dashboardList = this.dashboardList.filter(d => d.id !== dashboardId);
        delete this.dashboards[dashboardId];

        if (this.dashboardForm.get('dashboard_id').value === dashboardId) {
          this.dashboardForm.reset();
        }
      });
    }

    this.showDeleteDashboardAlert = false;
    this.dashboardToDelete = null;
  }

  viewDashboard(dashboardId) {
    this.dashboardForm.get('dashboard_id').setValue(dashboardId);
    this.selectDashboard();
    this.showManageDashboardsModal = false;
  }

  selectDashboard() {
    const dashboard = this.dashboards[this.dashboardForm.get('dashboard_id').value];
    this.applyFilter(dashboard.filter);
    this.currentFilter = cloneDeep(dashboard.filter);
    this.filterList = cloneDeep(dashboard.filterList);
    this.updateHiddenFilters();
  }

  validateUniqueDashboardNames() {
    const result = (c: FormControl): ValidationErrors | null => {
      if (c) {
        const name = c.value;
        if (this.dashboardOptions.some(o => o.label === name)) {
          return { validationError: 'This dashboard name is already in use.' };
        }
      }
      return null;
    }
    return result;
  }

  updateInstitutionOptions(filterStr: string) {
    const filters = {
      match_strings: filterStr,
      limit: 30
    };

    this.lookupService.formattedInstitutionList$(filters).subscribe(res => {
      this.institutionOptions = res;
    }, Utilities.setCallbackError(this.store, this.constructor.name));
  }

  getParams(filter) {
    return {
      ...filter,
      institution_ids: filter.institution_ids.map(inst => inst.value)
    };
  }

  getSystemEventOption(name: string) {
    let label = name;
      if (this.eventMappings[name]) {
        label += ` (${this.eventMappings[name]})`;
      }
      return ({ value: name, label });
  }

  createDashboardForm() {
    return this._fb.group({
      dashboard_id: [],
      name: [undefined, [Validators.required,  Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN), this.validateUniqueDashboardNames()]]
    });
  }

  get hasFilters() {
    if (!this.filterForm) {
      return false;
    }
    const filter = this.filterForm.value;
    return Object.keys(filter).some(key => filter[key].length);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
